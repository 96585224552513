import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import {
  Alert,
  Button,
  LoadingIndicator,
  PageContent,
  TabButton,
} from "../../../components";
import useOffsetPagination from "../../../hooks/useOffsetPagination";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { usePageTracker, useSendGAEvent } from "../../../utils/googleAnalytics";
import { AddCallGuideModal, DuplicateCallGuidesModal } from "../../components";
import {
  CallGuideListItemFragment,
  CandidateListItemFragment,
  useCandidateRediscoveryProspectsQuery,
  usePositionCallGuidesLazyQuery,
  usePositionCandidatesLazyQuery,
  usePositionInfoQuery,
  useUpdatePositionMutation,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useFeatureFlagValue from "../../graphql/hooks/useFeatureFlagValue";
import useCurrentUser from "../../hooks/useCurrentUser";
import DefaultLayout from "../../layouts/DefaultLayout";
import Forbidden from "../forbidden/Forbidden";
import PositionCallGuides from "./PositionCallGuides";
import PositionCandidates from "./PositionCandidates";
import PositionHeader from "./PositionHeader";
import ProspectsAlpha from "./ProspectsAlpha";

const PositionPage: React.FC = () => {
  usePageTracker("position");
  const guideStageAssignment = useFeatureFlag("guide-stage-assignment");
  const currentUser = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { percentHeight } = useWindowDimensions();
  const sendGAEvent = useSendGAEvent();

  const {
    isOpen: addGuideIsOpen,
    onOpen: addGuideOnOpen,
    onClose: addGuideOnClose,
  } = useDisclosure();

  const {
    isOpen: duplicateGuidesIsOpen,
    onOpen: duplicateGuidesOnOpen,
    onClose: duplicateGuidesOnClose,
  } = useDisclosure();

  const [totalPositionCandidatesCount, setTotalPositionCandidatesCount] =
    useState("");
  const [totalPositionCallGuidesCount, setTotalPositionCallGuidesCount] =
    useState("");

  const { positionId } = useParams() as { positionId: string };

  const { value: flagValue } = useFeatureFlagValue<{
    enabled_positions: string[];
  }>("candidate-rediscovery:alpha");
  const prospectsEnabledPositions = flagValue?.enabled_positions || [];
  const showProspectsTab =
    useFeatureFlag("candidate-rediscovery:alpha") &&
    prospectsEnabledPositions.includes(positionId);

  const {
    loading: positionLoading,
    error: positionError,
    data: positionData,
  } = usePositionInfoQuery({
    variables: { id: positionId },
  });

  let tabIndex = 0;
  if (location.pathname === `/position/${positionId}/guides`) {
    tabIndex = 1;
  } else if (location.pathname === `/position/${positionId}/prospects`) {
    tabIndex = 2;
  }

  const { scoringEnabled } = currentUser.organization;

  // candidates
  const [
    getPositionCandidates,
    { loading: positionCandidatesLoading, error: positionCandidatesError },
  ] = usePositionCandidatesLazyQuery({
    variables: { id: positionId, includeScores: scoringEnabled },
    fetchPolicy: "network-only",
    onCompleted: ({ position }) => {
      if (position?.paginatedCandidates?.results) {
        const candidatesList = position?.paginatedCandidates?.results;
        setPositionCandidates(candidatesList);
        setNumberOfPositionCandidates(
          position?.paginatedCandidates?.pageInfo?.totalPages || 0
        );
        setTotalPositionCandidatesCount(
          position?.paginatedCandidates?.pageInfo?.totalRows?.toString() || ""
        );
      }
    },
  });

  const {
    listItems: positionCandidates,
    setListItems: setPositionCandidates,
    setNumberOfPages: setNumberOfPositionCandidates,
    pageOptions: positionCandidatesPageOptions,
    didFetchComplete: didPositionCandidatesFetchComplete,
  } = useOffsetPagination<CandidateListItemFragment>(
    (opts) =>
      getPositionCandidates({
        ...opts,
        variables: {
          id: positionId,
          ...opts?.variables,
        },
      }),
    {
      initialSortBy: { id: "lastCall.createdAt", desc: true },
      ignoreURLParams: tabIndex !== 0,
    }
  );

  // call guides
  const [
    getPositionCallGuides,
    { loading: positionCallGuidesLoading, error: positionCallGuidesError },
  ] = usePositionCallGuidesLazyQuery({
    variables: { id: positionId },
    fetchPolicy: "network-only",
    onCompleted: ({ position }) => {
      if (position?.paginatedCallGuides?.results) {
        const candidatesList = position?.paginatedCallGuides?.results;
        setPositionCallGuides(candidatesList);
        setNumberOfPositionCallGuides(
          position?.paginatedCallGuides?.pageInfo?.totalPages || 0
        );
        setTotalPositionCallGuidesCount(
          position?.paginatedCallGuides?.pageInfo?.totalRows?.toString() || ""
        );
      }
    },
  });

  const {
    listItems: positionCallGuides,
    setListItems: setPositionCallGuides,
    setNumberOfPages: setNumberOfPositionCallGuides,
    pageOptions: positionCallGuidesPageOptions,
    didFetchComplete: didPositionCallGuidesFetchComplete,
  } = useOffsetPagination<CallGuideListItemFragment>(
    (opts) =>
      getPositionCallGuides({
        ...opts,
        variables: {
          id: positionId,
          ...opts?.variables,
        },
      }),
    {
      initialSortBy: { id: "updatedAt", desc: true },
      ignoreURLParams: tabIndex !== 1,
    }
  );

  const { loading: prospectsLoading, data: positionProspects } =
    useCandidateRediscoveryProspectsQuery({
      variables: { positionId },
      fetchPolicy: "network-only",
      skip: !showProspectsTab,
    });

  const prospects =
    positionProspects?.candidateRediscoveryProspects?.prospects || [];
  const totalProspectCount =
    positionProspects?.candidateRediscoveryProspects?.prospects?.length || 0;

  const [updatePosition, { loading: updateLoading, error: updateError }] =
    useUpdatePositionMutation();

  const handleChangeTab = (index: number): void => {
    // set search terms
    if (index === 0) {
      navigate(
        { pathname: `/position/${positionId}/candidates` },
        { replace: true }
      );
    } else if (index === 1) {
      navigate(
        { pathname: `/position/${positionId}/guides` },
        { replace: true }
      );
    } else if (index === 2) {
      navigate(
        { pathname: `/position/${positionId}/prospects` },
        { replace: true }
      );
    }
  };

  if (positionLoading) {
    return (
      <DefaultLayout>
        <LoadingIndicator mt={percentHeight(25)} />
      </DefaultLayout>
    );
  }

  if (positionError) {
    if (
      positionError.graphQLErrors.some(
        (e: any) => e.extensions?.code === "FORBIDDEN"
      )
    ) {
      return (
        <Forbidden forbiddenFrom="position">
          You can request access from the Hiring Team Admin.
        </Forbidden>
      );
    }
    // eslint-disable-next-line no-console
    console.error(positionError);
    return <Alert status="error" description="Error loading position" reload />;
  }

  const position = positionData?.position;
  if (!position) {
    return <Navigate to="/not-found" />;
  }

  const canEdit = !(position.greenhouseLink || position.leverPostingId);

  const handleSubmitTitle = (newValue: string): void => {
    const title = newValue.trim();
    if (title) {
      sendGAEvent("update", "positions", "title");
      updatePosition({
        variables: { id: position?.id, title },
      });
    }
  };

  const handleSaveCallGuide = (callGuide: CallGuideListItemFragment): void => {
    sendGAEvent("create", "call_guides");
    navigate(`/guide/${callGuide.id}`);
  };

  const positionCallGuideIds = positionCallGuides.map((guide) => guide.id);

  return (
    <>
      <AddCallGuideModal
        defaultPositionId={positionId}
        isOpen={addGuideIsOpen}
        onClose={addGuideOnClose}
        onSave={handleSaveCallGuide}
      />
      <DuplicateCallGuidesModal
        callGuideIds={positionCallGuideIds}
        fromPositionId={positionId}
        isOpen={duplicateGuidesIsOpen}
        onClose={duplicateGuidesOnClose}
      />
      <DefaultLayout>
        {updateError && (
          <Alert status="error" description="Error updating position" />
        )}
        <PageContent>
          <PositionHeader
            position={position}
            canEdit={canEdit}
            loading={updateLoading}
            handleSubmitTitle={handleSubmitTitle}
          />
          <Tabs
            index={tabIndex}
            onChange={handleChangeTab}
            variant="unstyled"
            isLazy
          >
            <Flex flexDirection="column">
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                mb="3"
                rowGap="2"
              >
                <Flex maxW="100%" px={1} mr="2" overflow="auto">
                  <TabList flexWrap="wrap" columnGap="4" rowGap="2">
                    <Tab
                      _focus={{ boxShadow: "none" }}
                      _hover={{ background: "none" }}
                      _active={{ background: "none" }}
                      py="1"
                      px="0"
                      whiteSpace="nowrap"
                    >
                      <TabButton
                        label="Candidates"
                        count={totalPositionCandidatesCount}
                        isSelected={tabIndex === 0}
                      />
                    </Tab>
                    <Tab
                      _focus={{ boxShadow: "none" }}
                      _hover={{ background: "none" }}
                      _active={{ background: "none" }}
                      py="1"
                      px="0"
                      whiteSpace="nowrap"
                    >
                      <TabButton
                        label="Interview Guides"
                        count={totalPositionCallGuidesCount}
                        isSelected={tabIndex === 1}
                      />
                    </Tab>
                    {showProspectsTab && (
                      <Tab
                        _focus={{ boxShadow: "none" }}
                        _hover={{ background: "none" }}
                        _active={{ background: "none" }}
                        py="1"
                        px="0"
                        whiteSpace="nowrap"
                      >
                        <TabButton
                          label="Prospects"
                          count={totalProspectCount.toString()}
                          isSelected={tabIndex === 2}
                          hasBetaTag
                        />
                      </Tab>
                    )}
                  </TabList>
                </Flex>
                <span>
                  {guideStageAssignment &&
                    positionCallGuides?.length > 0 &&
                    currentUser.userRole?.canCreatePosition &&
                    tabIndex === 1 && (
                      <Button
                        size="sm"
                        mr={4}
                        ml={{ base: 0, md: "auto" }}
                        onClick={() => duplicateGuidesOnOpen()}
                      >
                        Copy all guides to another position
                      </Button>
                    )}
                  {currentUser.userRole?.canCreatePosition && tabIndex === 1 && (
                    <Button
                      size="sm"
                      ml={{ base: 0, md: "auto" }}
                      onClick={() => addGuideOnOpen()}
                    >
                      New interview guide
                    </Button>
                  )}
                </span>
              </Flex>
              <TabPanels>
                <TabPanel>
                  {positionCandidatesError && (
                    <Alert
                      status="error"
                      title="Error loading candidates"
                      description={positionCandidatesError.message}
                    />
                  )}
                  {!positionCandidatesError &&
                    !didPositionCandidatesFetchComplete && <LoadingIndicator />}
                  {!positionCandidatesError &&
                  didPositionCandidatesFetchComplete ? (
                    <PositionCandidates
                      pageOptions={positionCandidatesPageOptions}
                      candidates={positionCandidates}
                      loading={positionCandidatesLoading}
                      error={positionCandidatesError}
                      sortBy={positionCandidatesPageOptions.sortBy}
                      positionId={positionId}
                    />
                  ) : null}
                </TabPanel>
                <TabPanel>
                  <Box>
                    {positionCallGuidesError && (
                      <Alert
                        status="error"
                        title="Error loading call guides"
                        description={positionCallGuidesError.message}
                      />
                    )}
                    {!positionCallGuidesError &&
                      !didPositionCallGuidesFetchComplete && (
                        <LoadingIndicator />
                      )}
                    {!positionCallGuidesError &&
                    didPositionCallGuidesFetchComplete ? (
                      <PositionCallGuides
                        pageOptions={positionCallGuidesPageOptions}
                        callGuides={positionCallGuides}
                        loading={positionCallGuidesLoading}
                        error={positionCallGuidesError}
                        sortBy={positionCallGuidesPageOptions.sortBy}
                      />
                    ) : null}
                  </Box>
                </TabPanel>
                {showProspectsTab && (
                  <TabPanel>
                    {prospectsLoading ? (
                      <LoadingIndicator />
                    ) : (
                      <ProspectsAlpha prospects={prospects} />
                    )}
                  </TabPanel>
                )}
              </TabPanels>
            </Flex>
          </Tabs>
        </PageContent>
      </DefaultLayout>
    </>
  );
};

export default PositionPage;
