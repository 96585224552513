import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  ListItem,
  Switch,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { MdCheck } from "react-icons/md";

import {
  Button,
  errorToast,
  Link,
  RouterLink,
  SettingsNote,
  SettingsPageContainer,
  successToast,
  useToast,
} from "../../../../components";
import { useOrgAccessMap } from "../../../../hooks/useOrgFeatureAccess";
import { copy } from "../../../../utils/clipboard";
import {
  FeatureName,
  useOrganizationDataRedactionQuery,
  useUpdateOrganizationComplianceSettingsMutation,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import {
  ControlledCheckbox,
  ControlledSwitch,
  FieldValues,
} from "./ControlledFields";

const ComplianceSettings: React.FC = () => {
  const currentUser = useCurrentUser();
  const {
    dataRedactionPredictionEnabled,
    candidateOptOutUrl,
    playCallDisclaimer,
    allowCandidateOptOut,
    allowInterviewerOptOut,
  } = currentUser.organization;
  const accessMap = useOrgAccessMap(currentUser);

  const toast = useToast();
  const [updateComplianceSettings] =
    useUpdateOrganizationComplianceSettingsMutation({
      onCompleted: () => successToast(toast, "Settings updated"),
      onError: () =>
        errorToast(toast, "There was a problem updating your settings"),
    });

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyLink = (): void => {
    copy(candidateOptOutUrl);
    setIsLinkCopied(true);
  };

  const { register, reset, watch, control } = useForm<FieldValues>({
    defaultValues: {
      playCallDisclaimer,
      allowCandidateOptOut,
      allowInterviewerOptOut,
    },
  });

  const [watchFamily, watchRace, watchSex, watchCompensation] = watch([
    "redactEeocFamilyEnabled",
    "redactEeocRaceEnabled",
    "redactEeocSexEnabled",
    "redactCompensationEnabled",
  ]);

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (type === "change" && name) {
        updateComplianceSettings({
          variables: {
            ...(values as FieldValues),
          },
        });
      }
    });
    return () => subscription.unsubscribe(); // Unsubscribe on cleanup
  }, [watch, updateComplianceSettings]);

  useOrganizationDataRedactionQuery({
    onError: () =>
      errorToast(toast, "There was a problem loading your settings"),
    onCompleted(data) {
      const organization = data?.currentUser?.organization;
      if (organization !== undefined) {
        reset({
          redactEeocFamilyEnabled: organization.redactEeocFamilyEnabled,
          redactEeocRaceEnabled: organization.redactEeocRaceEnabled,
          redactEeocSexEnabled: organization.redactEeocSexEnabled,
          redactEeocHighRiskQuestionsEnabled:
            organization.redactEeocHighRiskQuestionsEnabled,
          redactCompensationEnabled: organization.redactCompensationEnabled,
          dataRedactionEnabled: organization.dataRedactionEnabled,
          playCallDisclaimer,
          allowCandidateOptOut,
          allowInterviewerOptOut,
        });
      }
    },
  });

  const enabledCandidateTopics = useMemo(() => {
    const descriptions = [];
    if (watchFamily) {
      descriptions.push(
        <Text as="span" fontWeight="600">
          family status and pregnancy
        </Text>
      );
    }
    if (watchSex) {
      descriptions.push(
        <Text as="span" fontWeight="600">
          sexual orientation and gender
        </Text>
      );
    }
    if (watchRace) {
      descriptions.push(
        <Text as="span" fontWeight="600">
          race
        </Text>
      );
    }
    if (watchCompensation) {
      descriptions.push(
        <Text as="span" fontWeight="600">
          compensation
        </Text>
      );
    }
    if (!descriptions.length) {
      return <> about any enabled topics </>;
    }
    if (descriptions.length === 1) {
      return <> about {descriptions[0]}</>;
    }
    if (descriptions.length === 2) {
      return (
        <>
          {" "}
          about {descriptions[0]} and {descriptions[1]}{" "}
        </>
      );
    }
    if (descriptions.length === 3) {
      return (
        <>
          {" "}
          about {descriptions[0]}, {descriptions[1]}, and {descriptions[2]}{" "}
        </>
      );
    }
    if (descriptions.length === 4) {
      return (
        <>
          {" "}
          about {descriptions[0]}, {descriptions[1]}, {descriptions[2]}, and{" "}
          {descriptions[3]}{" "}
        </>
      );
    }
  }, [watchFamily, watchRace, watchSex, watchCompensation]);

  const showCandidateOptOut = accessMap?.get(
    FeatureName.CandidateOptOut
  )?.isVisible;
  const showInterviewerOptOut = accessMap?.get(
    FeatureName.InterviewerOptOut
  )?.isVisible;
  const showRecordingDisclaimer = accessMap?.get(
    FeatureName.RecordingDisclaimer
  )?.isVisible;

  const showComplianceCheck = accessMap?.get(
    FeatureName.ComplianceCheck
  )?.isVisible;

  return (
    <form autoComplete="on">
      {(showRecordingDisclaimer ||
        showCandidateOptOut ||
        showInterviewerOptOut) && (
        <SettingsPageContainer heading="Compliance">
          <VStack spacing="8" align="start">
            {showRecordingDisclaimer && (
              <FormControl id="playCallDisclaimer">
                <Checkbox
                  {...register("playCallDisclaimer")}
                  defaultChecked={playCallDisclaimer}
                >
                  Call Recording Disclaimer
                </Checkbox>
                <FormHelperText>
                  This plays a recording disclaimer when candidates answer
                  BrightHire calls. If you&apos;re using BrightHire with Zoom,
                  please refer to Zoom&apos;s Documentation to configure a
                  recording disclaimer.
                </FormHelperText>
                <SettingsNote mt="3">
                  If you&apos;re using BrightHire with Zoom, please refer to{" "}
                  <Link
                    href="https://support.zoom.us/hc/en-us/articles/360026909191-Enabling-the-recording-disclaimer"
                    target="_blank"
                  >
                    {"Zoom's Documentation "}
                  </Link>
                  to configure a recording disclaimer.
                </SettingsNote>
              </FormControl>
            )}
            {showCandidateOptOut && (
              <>
                <FormControl id="allowCandidateOptOut">
                  <Checkbox
                    {...register("allowCandidateOptOut")}
                    defaultChecked={allowCandidateOptOut}
                  >
                    Allow BrightHire to Send Candidate Opt-out Emails
                  </Checkbox>
                  <FormHelperText>
                    This sends an email to candidates when their first interview
                    scheduled with BrightHire is set to be captured. The email
                    informs them about the use of BrightHire and provides an
                    option to opt-out of BrightHire for all their upcoming
                    interviews. You can preview the email before sending it.
                    <Link
                      href="https://help.brighthire.ai/en/articles/5403608-candidate-opt-out-of-recording"
                      target="_blank"
                    >
                      {" Preview Email"}
                    </Link>
                  </FormHelperText>
                </FormControl>
                <Flex
                  alignItems="baseline"
                  justifyContent="space-between"
                  background="gray.50"
                  px="4"
                  py="5"
                  borderRadius="md"
                  alignSelf="stretch"
                >
                  <Text fontSize="sm">
                    Want to share your opt-out link directly?
                  </Text>
                  <Button
                    variant="outline"
                    size="xs"
                    onClick={copyLink}
                    leftIcon={isLinkCopied ? <MdCheck /> : undefined}
                    w="174px"
                  >
                    {isLinkCopied ? "Copied" : "Copy general opt-out link"}
                  </Button>
                </Flex>
              </>
            )}
            {showInterviewerOptOut && (
              <FormControl id="allowInterviewerOptOut">
                <Checkbox
                  {...register("allowInterviewerOptOut")}
                  defaultChecked={allowInterviewerOptOut}
                >
                  Allow Interviewer Opt-out
                </Checkbox>
                <FormHelperText>
                  This adds an ‘opt-out of recording’ link below each interview
                  for interviewers in your organization. This opt-out link will
                  appear in their ‘daily upcoming interviews’ email.
                </FormHelperText>
              </FormControl>
            )}
          </VStack>
          {showComplianceCheck && <Divider color="gray.200" mt="8" mb="7" />}
        </SettingsPageContainer>
      )}
      {showComplianceCheck && (
        <SettingsPageContainer
          heading="Compliance Check"
          subHeading="BrightHire helps surface compliance risks in your organization's interview conversations. Enabling Compliance Check processes all interviews to detect:"
        >
          <Box mb="6" mt="4" maxW="xl" fontSize="medium">
            <UnorderedList mb={4} mt={2}>
              <ListItem>
                <Text as="span" fontWeight="semibold">
                  Candidate statements
                </Text>{" "}
                revealing protected class information
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="semibold">
                  Interviewer questions
                </Text>{" "}
                that are a high risk for being non-compliant with EEOC guidance
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="semibold">
                  Compensation mentions
                </Text>{" "}
                in conversations about salary, benefits, or other financial
                incentives
              </ListItem>
            </UnorderedList>
            If Compliance Check is enabled, all flagged statements from
            interviews will be captured in the Compliance Check log.
          </Box>
          <SettingsNote>
            <Box mb="1.5">
              Compliance Check is only available for English-language
              transcripts.
            </Box>
            <Box mb="1.5">
              When Compliance Check is enabled, all future interviews will be
              processed.
            </Box>
            This feature is not retroactive and will not process interviews that
            occurred in the past. Our compliance check technology is
            state-of-the-art, but it&apos;s not perfect.
          </SettingsNote>

          <Text mt="4">
            <Link
              href="http://help.brighthire.ai/en/articles/8732434-compliance-check"
              target="_blank"
            >
              Learn more about Compliance Check here.
            </Link>
          </Text>

          <Box mt="8" mb="7">
            <Flex alignItems="center">
              <Switch
                defaultChecked={dataRedactionPredictionEnabled}
                onChange={(e) => {
                  updateComplianceSettings({
                    variables: {
                      playCallDisclaimer,
                      allowCandidateOptOut,
                      allowInterviewerOptOut,
                      dataRedactionPredictionEnabled: e.target.checked,
                    },
                  });
                }}
              />
              <Flex ml={3} fontWeight="400" color="gray.900" fontSize="md">
                Enable Compliance Check
              </Flex>
            </Flex>

            {dataRedactionPredictionEnabled && (
              <RouterLink
                variant="primaryButton"
                to="/compliance-check/log"
                mr={6}
                mt={6}
              >
                View Compliance Check log
              </RouterLink>
            )}
          </Box>

          {dataRedactionPredictionEnabled && (
            <>
              <Divider mb="6" />
              <SettingsPageContainer heading="Compliance Check topics">
                <Box mb="9">
                  <VStack align="baseline" spacing="7" pl="3.5">
                    <ControlledCheckbox
                      name="redactEeocFamilyEnabled"
                      control={control}
                    >
                      Family status and pregnancy
                    </ControlledCheckbox>

                    <ControlledCheckbox
                      name="redactEeocSexEnabled"
                      control={control}
                    >
                      Sexual orientation and gender
                    </ControlledCheckbox>

                    <ControlledCheckbox
                      name="redactEeocRaceEnabled"
                      control={control}
                    >
                      Race
                    </ControlledCheckbox>

                    <ControlledCheckbox
                      name="redactEeocHighRiskQuestionsEnabled"
                      control={control}
                    >
                      High-risk interviewer questions
                    </ControlledCheckbox>
                    <ControlledCheckbox
                      name="redactCompensationEnabled"
                      control={control}
                    >
                      Compensation
                    </ControlledCheckbox>
                  </VStack>
                  <Flex direction="column" bg="gray.50" p="6" mt="9">
                    <Text mb="6" fontWeight="semibold">
                      Redaction
                    </Text>
                    <Text fontSize="sm" maxW="xl" lineHeight="5">
                      If Redaction is enabled, Candidate statements
                      {enabledCandidateTopics} are also redacted from interview
                      recordings and transcripts, to prevent bias in hiring team
                      decision making. Admins and Recruiters with the{" "}
                      <RouterLink
                        variant="link"
                        to="/settings/users/roles/user"
                      >
                        View Redacted Interview Content
                      </RouterLink>{" "}
                      permission will still have visibility of any redacted
                      content to ensure accuracy.
                    </Text>
                    <Flex alignItems="center" mt={6}>
                      <ControlledSwitch
                        name="dataRedactionEnabled"
                        control={control}
                      >
                        Enable Redaction
                      </ControlledSwitch>
                    </Flex>
                  </Flex>
                </Box>
              </SettingsPageContainer>
            </>
          )}
        </SettingsPageContainer>
      )}
    </form>
  );
};

export default ComplianceSettings;
