import {
  Center,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";

import {
  Alert,
  Button,
  LoadingIndicator,
  MarkdownOutput,
} from "../../../components";
import { TaskRunFragment, TaskRunStatus } from "../../graphql";
import CoachingUserSelect from "./CoachingUserSelect";

type CoachingDemoFormProps = {
  loading: boolean;
  error: Error | undefined;
  taskRun?: TaskRunFragment | null;
  initialInstructions: string;
  interviewerId: string | undefined;
  onSelectInterviewer: (userId: string | undefined) => void;
  onRunCoaching: (customInstructions: string) => void;
};

const CoachingDemoForm: React.FC<CoachingDemoFormProps> = ({
  loading,
  error,
  taskRun,
  initialInstructions,
  interviewerId,
  onSelectInterviewer,
  onRunCoaching,
}) => {
  const [customInstructions, setCustomInstructions] =
    useState<string>(initialInstructions);

  const getMarkdown = (taskRun: TaskRunFragment): string => {
    if (!taskRun) {
      return "";
    }
    try {
      const output = JSON.parse(taskRun.output);
      return output?.preview_markdown || "";
    } catch (e) {
      return "";
    }
  };

  const showLoader =
    taskRun &&
    (!taskRun?.status || taskRun.status === TaskRunStatus.InProgress);

  return (
    <Center maxW="1200px" mx="auto" mt={10} mb={4} alignItems="flex-start">
      <Flex direction="column" alignItems="start" minW="500px" mr="8">
        <FormControl mb="4">
          <FormLabel fontWeight="bold">Interviewer</FormLabel>
          <CoachingUserSelect
            onChange={onSelectInterviewer}
            isDisabled={!!showLoader}
          />
        </FormControl>
        <FormControl mb="4">
          <FormLabel fontWeight="bold">Custom Instructions</FormLabel>
          <Textarea
            minH="300px"
            value={customInstructions}
            onChange={(e) => setCustomInstructions(e.target.value)}
            placeholder="Enter custom instructions here..."
            isDisabled={!!showLoader}
          />
        </FormControl>
        <Button
          variant="prismPrimary"
          mb={4}
          onClick={() => onRunCoaching(customInstructions)}
          isDisabled={!!showLoader || !interviewerId}
        >
          Run Coaching
        </Button>
      </Flex>
      <Flex direction="column" alignItems="start" flex="1">
        <FormLabel fontWeight="bold">Results</FormLabel>
        <Center w="100%">
          {showLoader && (
            <Flex flexDir="column">
              <LoadingIndicator my="4" />
              <Text color="gray.500" fontSize="17px" fontWeight="500">
                {taskRun?.statusMessage || "Loading..."}
              </Text>
            </Flex>
          )}
          {!loading && error && (
            <Alert
              status="error"
              title="Error"
              description="An error occurred while fetching the data."
            />
          )}
          {!loading &&
            !error &&
            taskRun?.status &&
            [TaskRunStatus.Completed, TaskRunStatus.CompletedPreview].includes(
              taskRun.status
            ) && (
              <MarkdownOutput
                markdown={getMarkdown(taskRun)}
                onClickTimestamp={() => null}
                w="100%"
                bg="gray.50"
              />
            )}
        </Center>
      </Flex>
    </Center>
  );
};

export default CoachingDemoForm;
