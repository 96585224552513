import { useMemo, useState } from "react";

import {
  dateRange6MonthsAgo,
  SelectDateRangeState,
} from "../../../../components/SelectDateRange/SelectDateRange";
import {
  useEnumSearchParam,
  useSelectDateRangeParams,
  useStringArrayParam,
} from "../../../../hooks/useSearchParam";
import { formatDateRange } from "../../../../utils/datetime";
import {
  AnalyticsDimension,
  AnalyticsTopicSpeakerType,
} from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import { MultiSelect, SingleSelect } from "../types";
import useAnalyticsContext from "../useAnalyticsContext";
import { isDimensionSupportedForOrg } from "../utils";
import { DefaultTopic } from "./types";
import { SPEAKER_OPTIONS } from "./utils";

export type TopicTrendsConfig = {
  primaryDimension: SingleSelect<AnalyticsDimension>;
  secondaryDimension: SingleSelect<AnalyticsDimension>;
  positions: MultiSelect;
  locations: MultiSelect;
  interviewers: MultiSelect;
  departments: MultiSelect;
  stages: MultiSelect;
  dateRange: {
    value: SelectDateRangeState;
    displayValue: string;
    setValue(value: SelectDateRangeState | undefined): void;
  };
  topic: {
    value: string;
    setValue(value: string): void;
  };
  speakerType: SingleSelect<AnalyticsTopicSpeakerType>;
  filters: {
    positions: string[];
    locations: string[];
    interviewers: string[];
    departments: string[];
    stages: string[];
  };
  enabledFilters: {
    positions: boolean;
    locations: boolean;
    interviewers: boolean;
    departments: boolean;
    stages: boolean;
  };
};

/**
 * Manages the state of user-selectable analytics configurations such as
 * filters, dimensions, and chart settings.
 *
 * Only manages the config, but not the query execution.
 */
const useTopicTrendsConfig = (
  defaultDateRange = dateRange6MonthsAgo
): TopicTrendsConfig => {
  const [positions, setPositions] = useStringArrayParam("positions");
  const [locations, setLocations] = useStringArrayParam("locations");
  const [interviewers, setInterviewers] = useStringArrayParam("interviewers");
  const [interviewerLabels, setInterviewerLabels] = useState<
    string[] | undefined
  >(undefined);
  const [departments, setDepartments] = useStringArrayParam("departments");
  const [stages, setStages] = useStringArrayParam("stages");
  const [dateRange, setDateRange] = useSelectDateRangeParams(
    "dateRange",
    defaultDateRange()
  );
  const [speakerType, setSpeakerType] =
    useEnumSearchParam<AnalyticsTopicSpeakerType>(
      "speaker",
      AnalyticsTopicSpeakerType.All
    );

  const [topic, setTopic] = useEnumSearchParam<string>(
    "topic",
    DefaultTopic.Ai
  );

  const filters = {
    positions: positions || [],
    locations: locations || [],
    interviewers: interviewers || [],
    departments: departments || [],
    stages: stages || [],
  };

  const displayDateRangeValue = useMemo(() => {
    if (dateRange) {
      return formatDateRange(dateRange.start, dateRange.end);
    }
    return "";
  }, [dateRange]);

  const { atsDataState } = useAnalyticsContext();
  const stageFilterOn = useFeatureFlag("analytics:canva-stage-filters");
  const stageSupported =
    stageFilterOn ||
    isDimensionSupportedForOrg(AnalyticsDimension.JobStage, atsDataState);
  const locationSupported = isDimensionSupportedForOrg(
    AnalyticsDimension.Location,
    atsDataState
  );

  return {
    primaryDimension: {
      value: AnalyticsDimension.None,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setValue: () => {},
      options: [],
    },
    secondaryDimension: {
      value: AnalyticsDimension.None,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setValue: () => {},
      options: [],
    },
    positions: {
      values: positions,
      setValues: setPositions,
    },
    locations: {
      values: locations,
      setValues: setLocations,
    },
    interviewers: {
      values: interviewers,
      setValues: setInterviewers,
      labels: interviewerLabels,
      setLabels: setInterviewerLabels,
    },
    departments: {
      values: departments,
      setValues: setDepartments,
    },
    stages: {
      values: stages,
      setValues: setStages,
    },
    dateRange: {
      // Default is defined inline because it dynamically calculates a range
      value: dateRange,
      displayValue: displayDateRangeValue,
      setValue: setDateRange,
    },
    topic: {
      value: topic || DefaultTopic.Ai,
      setValue: setTopic,
    },
    speakerType: {
      value:
        (speakerType as AnalyticsTopicSpeakerType) ||
        AnalyticsTopicSpeakerType.All,
      setValue: setSpeakerType,
      options: SPEAKER_OPTIONS,
    },
    filters,
    enabledFilters: {
      positions: true,
      locations: locationSupported,
      interviewers: true,
      departments: true,
      stages: stageSupported,
    },
  };
};

export default useTopicTrendsConfig;
