/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Box,
  Flex,
  Heading,
  HStack,
  ListItem,
  Tag,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import { Link } from "../../../components";
import BhLinkedIn from "../../../components/Icons/BhLinkedIn";
import { CandidateRediscoveryProspect } from "../../graphql";

interface InfoBoxProps {
  label: string;
  children: React.ReactNode;
}

const InfoBox: React.FC<InfoBoxProps> = ({ label, children }) => (
  <HStack spacing={0} width="100%" fontSize="12px">
    <Text flex={1} maxWidth="125px" mr={2}>
      {label}:
    </Text>
    <Text flex={1} color="gray.800">
      {children}
    </Text>
  </HStack>
);

interface ProspectCardProps {
  index: number;
  prospect: CandidateRediscoveryProspect;
}

const ProspectCard: React.FC<ProspectCardProps> = ({ index, prospect }) => {
  const formatDate = (timestamp: string): string => {
    return new Date(timestamp).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <Box width="780px">
      {/* Header */}
      <Flex
        justify="space-between"
        align="center"
        color="white"
        bgColor="blue.700"
        borderTopLeftRadius="12px"
        borderTopRightRadius="12px"
        border="1px solid"
        borderColor="blue.700"
        px={4}
        py={3}
      >
        <HStack>
          <Text
            borderRadius="100%"
            border="1px solid white"
            width="24px"
            height="24px"
            textAlign="center"
            lineHeight="22px"
            fontSize="sm"
            mr={2}
          >
            {index + 1}
          </Text>
          <Heading fontWeight="600" fontSize="md" mb={0} pr={5}>
            <Link
              variant="white"
              target="_blank"
              href={`/candidate/${prospect.candidateId}`}
            >
              {prospect.candidateName}
            </Link>
          </Heading>
        </HStack>
        {prospect.linkedinUrl && (
          <Link
            href={prospect.linkedinUrl}
            isExternal
            width={6}
            height={6}
            position="relative"
            top="-2px"
          >
            <BhLinkedIn color="white" boxSize={5} />
          </Link>
        )}
      </Flex>

      <Box
        borderColor="gray.200"
        borderWidth="1px"
        borderTopWidth="0"
        borderBottomLeftRadius="12px"
        borderBottomRightRadius="12px"
      >
        {/* TLDR Section */}
        <VStack align="stretch" spacing={3} p={4}>
          <UnorderedList color="gray.800" fontSize="sm">
            {prospect?.output?.tldr?.map((item, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={`tldr-${prospect.candidateId}-${idx}`}>
                {item}
              </ListItem>
            ))}
          </UnorderedList>
        </VStack>

        {/* Application Info */}
        {prospect.hasAtsData && (
          <VStack
            spacing={1}
            mx={4}
            px={4}
            py={3}
            bgColor="gray.50"
            borderRadius="12px"
            color="gray.600"
            fontWeight="400"
            fontSize="sm"
          >
            {prospect.lastAppliedDate && (
              <InfoBox label="Last applied date">
                {formatDate(prospect.lastAppliedDate)}
              </InfoBox>
            )}
            {prospect.lastAppliedPosition && (
              <InfoBox label="Last applied position">
                <Link href={`/position/${prospect.lastAppliedPositionId}`}>
                  {prospect.lastAppliedPosition}
                </Link>
              </InfoBox>
            )}
            {prospect.lastInterviewStage && (
              <InfoBox label="Last interview stage">
                {prospect.lastInterviewStage}
              </InfoBox>
            )}
            {prospect.rejectionReason && (
              <InfoBox label="Rejection reason">
                {prospect.rejectionReason}
              </InfoBox>
            )}
          </VStack>
        )}
        {/* Categories */}
        <VStack align="stretch" spacing={4} p={4} pb={6} mt={1}>
          {prospect.output?.categories?.map((category) => (
            <HStack
              key={`category-${category?.categoryName}-${prospect.candidateId}`}
              alignItems="flex-start"
            >
              <Flex width="240px" pr={4}>
                <Tag
                  size="sm"
                  color="green.800"
                  bgColor="green.100"
                  border="1px solid"
                  borderColor="green.200"
                  mt={0.5}
                >
                  {category?.categoryName}
                </Tag>
              </Flex>
              <VStack flex={2} align="stretch" spacing={1}>
                <Text color="gray.800" fontSize="sm" fontWeight={600}>
                  {category?.tldr}
                </Text>
                <UnorderedList
                  color="gray.600"
                  fontSize="sm"
                  fontWeight={400}
                  lineHeight="1.5"
                  pl={3}
                >
                  {category?.bullets?.map((item, bulletIdx) => (
                    <ListItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${category?.categoryName}-${prospect.candidateId}-bullet-${bulletIdx}`}
                      fontSize="sm"
                    >
                      {item}
                    </ListItem>
                  ))}
                </UnorderedList>
              </VStack>
            </HStack>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default ProspectCard;
