import {
  Box,
  Flex,
  HStack,
  Icon,
  SlideFade,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { HiArrowDownTray } from "react-icons/hi2";
import { Navigate } from "react-router-dom";

import { IconButton, LoadingIndicator } from "../../../../components";
import { formatISODate } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { snakeCaseToSentenceCase } from "../../../../utils/string";
import {
  CandidateQuestionTopic,
  useAnalyticsCandidateQuestionKeyThemesQuery,
  useAnalyticsCandidateQuestionTopicCountsQuery,
  useAnalyticsCandidateQuestionTopicExamplesCsvLazyQuery,
  useAnalyticsCandidateQuestionTopicExamplesQuery,
  UserRoleName,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AnalyticsDateSelect from "../AnalyticsDateSelect";
import AnalyticsFilters from "../AnalyticsFilters";
import AnalyticsInfoAlert from "../AnalyticsInfoAlert";
import AnalyticsInfoModal from "../AnalyticsInfoModal";
import AnalyticsKeyThemes from "../AnalyticsKeyThemes";
import { ReportContainer, ResultHeader } from "../AnalyticsReport";
import AnalyticsReportHero from "../AnalyticsReportHero";
import AnalyticsReportTableSkeleton from "../AnalyticsReportTableSkeleton";
import AnalyticsBarChart, {
  AnalyticsBarChartData,
} from "../bar-chart/AnalyticsBarChart";
import LabeledChartSelect from "../LabeledChartSelect";
import { CommonQueryVariables } from "../types";
import useAnalyticsContext from "../useAnalyticsContext";
import AnalyticsCandidateQuestionTopicsTable from "./AnalyticsCandidateQuestionsTable";
import useCandidateQuestionsConfig from "./useCandidateQuestionsConfig";

const AnalyticsCandidateQuestions: React.FC = () => {
  const sendGAEvent = useSendGAEvent();
  const queryConfig = useCandidateQuestionsConfig();
  const currentUser = useCurrentUser();
  const { selectedOrgId } = useAnalyticsContext();
  const isAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;

  const queryVariables: CommonQueryVariables = {
    dateRangeStart: formatISODate(queryConfig.dateRange.value.start),
    dateRangeEnd: formatISODate(queryConfig.dateRange.value.end),
    organizationId: selectedOrgId,
    ...queryConfig.filters,
  };

  const { data: chartResult, loading: chartLoading } =
    useAnalyticsCandidateQuestionTopicCountsQuery({
      variables: { ...queryVariables, requestedOrganizationId: selectedOrgId },
    });
  const chartData = chartResult?.candidateQuestionTopicCounts?.counts;
  const callCount = chartResult?.candidateQuestionTopicCounts?.totalCalls || 0;
  const candidateCount =
    chartResult?.candidateQuestionTopicCounts?.totalCandidates || 0;
  const sampleSize = useMemo(() => {
    return `Sample: ${callCount} interviews with ${candidateCount} candidates.`;
  }, [callCount, candidateCount]);

  const chartCounts = useMemo(() => {
    if (!chartData) return [];
    const mappedTopics = chartData
      .map((o) => {
        const { topic, count } = o;
        if (typeof count !== "number") return null;
        return {
          key: topic,
          label: snakeCaseToSentenceCase(topic),
          value: Number(count.toFixed(1)),
        };
      })
      .filter(Boolean) as AnalyticsBarChartData;
    return mappedTopics;
  }, [chartData]);

  const questionTopicSelectOptions = useMemo(() => {
    const options = chartCounts
      .filter((count) => count.value > 0)
      .map((count) => ({
        value: count.key,
        label: count.label,
      }));
    options.unshift({ value: "ALL", label: "All categories" });
    return options;
  }, [chartCounts]);

  const { loading: tableLoading, data: tableResult } =
    useAnalyticsCandidateQuestionTopicExamplesQuery({
      variables: {
        ...queryVariables,
        requestedOrganizationId: selectedOrgId,
        ...(queryConfig.questionTopic.value !== "ALL" && {
          questionTopic: queryConfig.questionTopic.value,
        }),
      },
      skip: !queryConfig.questionTopic.value,
    });
  const tableData = tableResult?.candidateQuestionTopicExamples?.data || [];
  const tableReady = !tableLoading && queryConfig.questionTopic.value;

  const { data: summaryResult, loading: summaryLoading } =
    useAnalyticsCandidateQuestionKeyThemesQuery({
      variables: {
        predictionIds: tableData.map((row) => row.exampleId),
        requestedOrganizationId: selectedOrgId,
        questionTopic: queryConfig.questionTopic
          .value as CandidateQuestionTopic,
      },
      skip: !tableData.length || queryConfig.questionTopic.value === "ALL",
    });
  const summary = summaryResult?.candidateQuestionKeyThemes?.data || [];

  const [fetchCsv, { loading: csvLoading }] =
    useAnalyticsCandidateQuestionTopicExamplesCsvLazyQuery({
      fetchPolicy: "network-only",
      onCompleted(data) {
        if (data?.candidateQuestionTopicExamplesCsv?.url) {
          const url = data?.candidateQuestionTopicExamplesCsv?.url;
          const downloadLink = document.createElement("a");
          downloadLink.style.display = "none";
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      },
    });

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  const showSummarySection =
    queryConfig.questionTopic.value !== "ALL" && !tableLoading;
  const noChartData =
    !chartLoading && chartCounts?.every((count) => count.value === 0);

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
        py="4"
        px="8"
      >
        <Flex flexDir="row" alignItems="baseline">
          <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
            Candidate Question Trends
          </Text>
          <AnalyticsInfoModal dangerousHTMLString="The Candidate Questions report analyzes interview transcripts to identify and categorize questions asked by candidates during interviews. The bar chart shows the percentage of candidates who asked questions in each category, while the examples section displays specific questions and their categories." />
        </Flex>
        <Flex ml="auto">
          <Flex minW="148px">
            <AnalyticsDateSelect
              state={queryConfig.dateRange.value}
              onSelect={queryConfig.dateRange.setValue}
            />
          </Flex>
        </Flex>
      </Flex>
      <Box px="8" borderTop="1px" borderBottom="1px" borderColor="gray.200">
        <Flex flex="1" flexDir="row" alignItems="center" py="5">
          <Text fontSize="sm" color="gray.600" mr="2">
            Filters
          </Text>
          <HStack flex={1} spacing={4}>
            <AnalyticsFilters
              queryConfig={queryConfig}
              queryVariables={queryVariables}
            />
          </HStack>
        </Flex>
      </Box>
      <Box mt="6" px="8">
        {queryConfig.dateRange.value.start < new Date("2024-05-01") && (
          <AnalyticsInfoAlert status="info" mt="-3" mb="4">
            Data is unavailable for candidate question topics before May 1st,
            2024.
          </AnalyticsInfoAlert>
        )}
        <ReportContainer>
          <ResultHeader
            headerText="Question Category Breakdown"
            captionText={
              chartLoading
                ? undefined
                : `Question categories are calculated as the percentage of candidates who asked them. ${sampleSize}`
            }
          >
            <Text
              color="gray.600"
              fontWeight="400"
              fontSize="sm"
              whiteSpace="nowrap"
            >
              {queryConfig.dateRange.displayValue}
            </Text>
          </ResultHeader>
          <Box>
            {chartLoading && <LoadingIndicator />}
            {noChartData && <AnalyticsReportHero image />}
            {!noChartData && <AnalyticsBarChart data={chartCounts} />}
          </Box>
        </ReportContainer>
      </Box>
      <Box px="8" mt="6" pb="8">
        <ReportContainer>
          <Flex flexDir="row" justifyContent="space-between" alignItems="start">
            <Flex flexDir="row" alignItems="center" mr="4">
              <Text fontSize="lg" fontWeight="600" color="gray.800" mr="4">
                Question Category Examples
              </Text>
              <LabeledChartSelect
                data-testid="analytics-candidate-question-trends--topic-menu"
                flexDir="row"
                alignItems="center"
                label=""
                placeholder="All categories"
                singleSelect={{
                  ...queryConfig.questionTopic,
                  options: questionTopicSelectOptions,
                  disabled: chartLoading,
                }}
                customSelectStyles={{
                  container: (provided: any) => ({
                    ...provided,
                    width: "250px",
                  }),
                }}
              />
              <SlideFade
                in={queryConfig.questionTopic.value === "ALL"}
                offsetY="20px"
              >
                <Flex
                  ml="4"
                  bg="blue.50"
                  p="3"
                  py="2"
                  borderRadius="8px"
                  alignItems="center"
                >
                  <Tag
                    as={Box}
                    position="relative"
                    size="sm"
                    textTransform="uppercase"
                    bg="blue.500"
                    color="white"
                    mr="2"
                    px="1"
                    py="2px"
                    minHeight="default"
                    fontSize="9px"
                    borderRadius="2px"
                    flexShrink="0"
                  >
                    {"Tip"}
                  </Tag>
                  <Text fontSize="sm">
                    Select a specific category to see key themes for that
                    category
                  </Text>
                </Flex>
              </SlideFade>
            </Flex>
            <Tooltip label="Download full CSV results">
              <IconButton
                aria-label="Download full CSV results"
                icon={<Icon as={HiArrowDownTray} boxSize="5" />}
                isLoading={csvLoading || tableLoading}
                variant="ghost"
                onClick={() => {
                  sendGAEvent(
                    "download_candidate_question_topic_results",
                    "analytics"
                  );
                  fetchCsv({
                    variables: {
                      ...queryVariables,
                      requestedOrganizationId: selectedOrgId,
                      ...(queryConfig.questionTopic.value !== "ALL" && {
                        questionTopic: queryConfig.questionTopic.value,
                      }),
                    },
                  });
                }}
                onFocus={(e) => e.preventDefault()}
                hidden={!tableLoading && !tableData.length}
              />
            </Tooltip>
          </Flex>
          <Box mt="3" width="100%">
            {showSummarySection && (
              <AnalyticsKeyThemes
                summaryLoading={summaryLoading}
                summary={summary}
              />
            )}
            {(chartLoading || tableLoading) && <AnalyticsReportTableSkeleton />}
            {tableReady && (
              <AnalyticsCandidateQuestionTopicsTable
                tableData={tableData}
                currentTopic={queryConfig.questionTopic.value}
              />
            )}
          </Box>
        </ReportContainer>
      </Box>
    </>
  );
};

export default AnalyticsCandidateQuestions;
