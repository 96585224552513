import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `BigInt` scalar type represents non-fractional whole numeric values.
   * `BigInt` is not constrained to 32-bit like the `Int` type and thus is a less
   * compatible type.
   */
  BigInt: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
};

export enum Ats {
  Ashby = "ASHBY",
  Bullhorn = "BULLHORN",
  Greenhouse = "GREENHOUSE",
  Icims = "ICIMS",
  Infor = "INFOR",
  Lever = "LEVER",
  Merge = "MERGE",
  MergeWorkday = "MERGE_WORKDAY",
  Smartrecruiters = "SMARTRECRUITERS",
  Talemetry = "TALEMETRY",
  Workable = "WORKABLE",
}

export type Activity = {
  __typename?: "Activity";
  action: ActivityType;
  call?: Maybe<Call>;
  callId?: Maybe<Scalars["UUID"]>;
  callNote?: Maybe<CallNote>;
  callNoteId?: Maybe<Scalars["UUID"]>;
  callShare?: Maybe<CallShare>;
  callShareId?: Maybe<Scalars["UUID"]>;
  candidateId?: Maybe<Scalars["UUID"]>;
  clip?: Maybe<Clip>;
  clipId?: Maybe<Scalars["UUID"]>;
  clipShare?: Maybe<ClipShare>;
  clipShareId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  externalCallShareId?: Maybe<Scalars["UUID"]>;
  externalClipShareId?: Maybe<Scalars["UUID"]>;
  externalPlaylistShareId?: Maybe<Scalars["UUID"]>;
  externalSubject?: Maybe<ExternalUser>;
  externalSubjectId?: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  jobDescriptionId?: Maybe<Scalars["UUID"]>;
  jobDescriptionShareId?: Maybe<Scalars["UUID"]>;
  playlistShare?: Maybe<PlaylistShare>;
  playlistShareId?: Maybe<Scalars["UUID"]>;
  positionId?: Maybe<Scalars["UUID"]>;
  subject?: Maybe<User>;
  subjectId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["DateTime"];
};

export type ActivityFeed = {
  __typename?: "ActivityFeed";
  activity: Activity;
  activityId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  owner: User;
  ownerId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export enum ActivityType {
  CalendarSyncNudge = "CALENDAR_SYNC_NUDGE",
  CallNoteComment = "CALL_NOTE_COMMENT",
  CallNoteMention = "CALL_NOTE_MENTION",
  CallNoteReply = "CALL_NOTE_REPLY",
  CallShare = "CALL_SHARE",
  CallTranscriptionComplete = "CALL_TRANSCRIPTION_COMPLETE",
  CallView = "CALL_VIEW",
  ClipShare = "CLIP_SHARE",
  ExternalCallView = "EXTERNAL_CALL_VIEW",
  ExternalClipView = "EXTERNAL_CLIP_VIEW",
  JobDescriptionShare = "JOB_DESCRIPTION_SHARE",
  PlaylistShare = "PLAYLIST_SHARE",
  SignupNudgeAboutInterview = "SIGNUP_NUDGE_ABOUT_INTERVIEW",
  ViewCandidateNudge = "VIEW_CANDIDATE_NUDGE",
}

export type AddAlertView = {
  __typename?: "AddAlertView";
  alertView?: Maybe<AlertView>;
  success: Scalars["Boolean"];
};

export type AddBrightHireToInterviews = {
  __typename?: "AddBrightHireToInterviews";
  errors?: Maybe<Array<AddBrightHireToInterviewsError>>;
  scheduledInterviews?: Maybe<Array<ScheduledInterview>>;
  source: Scalars["String"];
};

export type AddBrightHireToInterviewsError = {
  __typename?: "AddBrightHireToInterviewsError";
  id: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
};

export type AddBullhornCredentials = {
  __typename?: "AddBullhornCredentials";
  authUrl?: Maybe<Scalars["String"]>;
  currentUser: User;
};

export type AddCall = {
  __typename?: "AddCall";
  call: Call;
};

export type AddCallGuide = {
  __typename?: "AddCallGuide";
  callGuide: CallGuide;
};

export type AddCallGuideCue = {
  __typename?: "AddCallGuideCue";
  callGuideCue: CallGuideCue;
};

export type AddCallNote = {
  __typename?: "AddCallNote";
  callNote: CallNote;
};

export type AddCallNoteHighlightFeedback = {
  __typename?: "AddCallNoteHighlightFeedback";
  callNote: CallNote;
};

export type AddCallNoteReply = {
  __typename?: "AddCallNoteReply";
  callNote: CallNote;
};

export type AddCandidateAlertUserSubscriptions = {
  __typename?: "AddCandidateAlertUserSubscriptions";
  subscriptions: CandidateAlertUserSubscriptions;
};

export type AddClient = {
  __typename?: "AddClient";
  client: Client;
};

export type AddClipsToTrainingProgram = {
  __typename?: "AddClipsToTrainingProgram";
  trainingProgram: TrainingProgram;
};

export type AddCompetency = {
  __typename?: "AddCompetency";
  competency: OrganizationCompetency;
};

/**
 * Creates and starts an in-person call.
 *
 * Pass `scheduled_interview_id` when a scheduled interview exists,
 * and it will be converted to an in-person scheduled interview.
 *
 * Otherwise, pass data from the NewInPersonCallForm to fill out the details of
 * the call.
 */
export type AddInPersonCall = {
  __typename?: "AddInPersonCall";
  call: Call;
  recordingUploadUrl: Scalars["String"];
};

export type AddInterviewAssistantOpens = {
  __typename?: "AddInterviewAssistantOpens";
  success: Scalars["Boolean"];
};

export type AddInterviewStage = {
  __typename?: "AddInterviewStage";
  interviewStage: InterviewStage;
};

export type AddNewCallNote = {
  __typename?: "AddNewCallNote";
  callNote: CallNote;
};

export type AddPosition = {
  __typename?: "AddPosition";
  position: Position;
};

export type AddTrainingProgramQuestion = {
  __typename?: "AddTrainingProgramQuestion";
  trainingProgram: TrainingProgram;
};

export type AddTrainingProgramTrainees = {
  __typename?: "AddTrainingProgramTrainees";
  trainingProgram: TrainingProgram;
};

export type AiNoteHighlight = CallHighlight & {
  __typename?: "AiNoteHighlight";
  description: Scalars["String"];
  endTime?: Maybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  shortDescription: Scalars["String"];
  startTime: Scalars["Int"];
  tags?: Maybe<Array<Scalars["String"]>>;
  text: Scalars["String"];
  thumbnailImageScaleX?: Maybe<Scalars["Float"]>;
  thumbnailImageUrl?: Maybe<Scalars["String"]>;
};

export type AiNotesFormat = {
  __typename?: "AiNotesFormat";
  customTemplateId?: Maybe<Scalars["ID"]>;
  customTemplateType?: Maybe<CustomTemplateCustomType>;
  format: CallAiSummaryFormat;
};

export enum AiNotesProcessingStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type AiQuestionNote = {
  __typename?: "AiQuestionNote";
  aiQuestion: Scalars["String"];
  callAiNoteAnswerItems: Array<AiQuestionNoteAnswerItem>;
  id: Scalars["ID"];
  isAtsSingleLineInput: Scalars["Boolean"];
  scorecardQuestion?: Maybe<Scalars["String"]>;
  startTime: Scalars["Float"];
};

export type AiQuestionNoteAnswerItem = {
  __typename?: "AiQuestionNoteAnswerItem";
  currentText: Scalars["String"];
  id: Scalars["ID"];
};

export type AiSummaryTemplateSectionInput = {
  prompt: Array<Scalars["String"]>;
  title: Scalars["String"];
};

export enum AiTopic {
  CandidateMotivation = "CANDIDATE_MOTIVATION",
  CandidateQuestions = "CANDIDATE_QUESTIONS",
  Compensation = "COMPENSATION",
  NextSteps = "NEXT_STEPS",
}

export type Alert = {
  __typename?: "Alert";
  aggregation: Scalars["String"];
  aggregationIds: Array<Scalars["String"]>;
  alertOrder: Scalars["Int"];
  alertType: Scalars["String"];
  alertValence: Scalars["String"];
  alertWeight: Scalars["Float"];
  category: Scalars["String"];
  id: Scalars["ID"];
  isNew: Scalars["Boolean"];
  message: Scalars["String"];
  organizationId: Scalars["String"];
  segment: Scalars["String"];
};

export type AlertValues = {
  __typename?: "AlertValues";
  alerts?: Maybe<Array<Alert>>;
  id: Scalars["ID"];
};

export type AlertView = {
  __typename?: "AlertView";
  aggregation: AnalyticsAlertAggregation;
  aggregationIds: Array<Maybe<Scalars["String"]>>;
  category: AnalyticsAlertCategory;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  segment: AnalyticsAlertSegment;
  type: AnalyticsAlertType;
  updatedAt: Scalars["DateTime"];
  userId: Scalars["UUID"];
  valence: AnalyticsAlertValence;
};

export enum AnalyticsAlertAggregation {
  Candidate = "CANDIDATE",
  Department = "DEPARTMENT",
  Position = "POSITION",
}

export enum AnalyticsAlertCategory {
  CandidateExperience = "CANDIDATE_EXPERIENCE",
  Dei = "DEI",
  TalentOperations = "TALENT_OPERATIONS",
}

export enum AnalyticsAlertSegment {
  All = "ALL",
  Gender = "GENDER",
}

export enum AnalyticsAlertType {
  CandidateTalkTime = "CANDIDATE_TALK_TIME",
  CountInterviewerQuestions = "COUNT_INTERVIEWER_QUESTIONS",
  GenderSkewedPanel = "GENDER_SKEWED_PANEL",
  IsLateStart = "IS_LATE_START",
  SubmissionDelay = "SUBMISSION_DELAY",
  SubmissionIsPositive = "SUBMISSION_IS_POSITIVE",
  SubmissionRate = "SUBMISSION_RATE",
}

export enum AnalyticsAlertValence {
  Negative = "NEGATIVE",
  Positive = "POSITIVE",
}

export type AnalyticsCustomTopic = {
  __typename?: "AnalyticsCustomTopic";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  keywords: Scalars["JSONString"];
  matchRule: CustomTopicMatchRule;
  name: Scalars["String"];
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export enum AnalyticsDimension {
  ApplicationStatus = "APPLICATION_STATUS",
  Department = "DEPARTMENT",
  Gender = "GENDER",
  Interviewer = "INTERVIEWER",
  JobStage = "JOB_STAGE",
  Location = "LOCATION",
  None = "NONE",
  Performance = "PERFORMANCE",
  Position = "POSITION",
  Time = "TIME",
}

export type AnalyticsQualityReportConfig = {
  __typename?: "AnalyticsQualityReportConfig";
  agendaSetEnabled: Scalars["Boolean"];
  candidateQuestionOpportunityEnabled: Scalars["Boolean"];
  candidateTalkRatioEnabled: Scalars["Boolean"];
  feedbackSubmissionRateEnabled: Scalars["Boolean"];
  feedbackSubmissionTimeEnabled: Scalars["Boolean"];
  interactivityEnabled: Scalars["Boolean"];
  longestMonologueEnabled: Scalars["Boolean"];
  onTimeStartsEnabled: Scalars["Boolean"];
  problematicQuestionsEnabled: Scalars["Boolean"];
  questionsAskedEnabled: Scalars["Boolean"];
  speakingRateEnabled: Scalars["Boolean"];
};

export enum AnalyticsTopicSpeakerType {
  All = "ALL",
  Candidate = "CANDIDATE",
  Interviewer = "INTERVIEWER",
}

export type AnswerItem = {
  __typename?: "AnswerItem";
  edited: Scalars["Boolean"];
  id: Scalars["String"];
  text: Scalars["String"];
};

export type AnswerTrainingProgramQuestions = {
  __typename?: "AnswerTrainingProgramQuestions";
  success: Scalars["Boolean"];
};

export type Application = {
  __typename?: "Application";
  atsStageName?: Maybe<Scalars["String"]>;
  atsStatus?: Maybe<Scalars["String"]>;
  atsStatusReason?: Maybe<Scalars["String"]>;
  candidateId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  customAtsId?: Maybe<Scalars["String"]>;
  greenhouseId?: Maybe<Scalars["Float"]>;
  icimsId?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  leverId?: Maybe<Scalars["String"]>;
  mergeId?: Maybe<Scalars["String"]>;
  mergeRemoteId?: Maybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  positionId: Scalars["UUID"];
  redactedAt?: Maybe<Scalars["DateTime"]>;
  skipRedaction: Scalars["Boolean"];
  smartrecruitersId?: Maybe<Scalars["String"]>;
  smartrecruitersLink?: Maybe<Scalars["String"]>;
  status: ApplicationStatus;
  statusUpdatedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
};

export enum ApplicationStatus {
  Active = "ACTIVE",
  Hired = "HIRED",
  Rejected = "REJECTED",
}

export type AshbyCandidateParams = {
  __typename?: "AshbyCandidateParams";
  params?: Maybe<CallParams>;
};

export enum AtsDataState {
  AtsNotSupported = "ATS_NOT_SUPPORTED",
  DataAvailable = "DATA_AVAILABLE",
  DataNotAvailable = "DATA_NOT_AVAILABLE",
}

export type Attendee = {
  __typename?: "Attendee";
  comment?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  organizer: Scalars["Boolean"];
  responseStatus: ResponseStatus;
};

export type AuditLog = {
  __typename?: "AuditLog";
  createdAt: Scalars["DateTime"];
  details: Scalars["JSONString"];
  externalUserId?: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  ipAddress?: Maybe<Scalars["String"]>;
  ipAddressCountry?: Maybe<Scalars["String"]>;
  isSystem: Scalars["Boolean"];
  name: Scalars["String"];
  organizationId: Scalars["UUID"];
  user: User;
  userAgent?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["UUID"]>;
};

export type AuthorizeZoom = {
  __typename?: "AuthorizeZoom";
  organization: Organization;
};

export type AuthorizeZoomBot = {
  __typename?: "AuthorizeZoomBot";
  organization: Organization;
};

export enum AutoEnableOrganizationType {
  AllOrgs = "ALL_ORGS",
  EnterpriseOrgs = "ENTERPRISE_ORGS",
  FreemiumOrgs = "FREEMIUM_ORGS",
}

export type AutoGenerateCandidateSummaryIfMissing = {
  __typename?: "AutoGenerateCandidateSummaryIfMissing";
  created: Scalars["Boolean"];
};

export type BenchmarkValue = {
  __typename?: "BenchmarkValue";
  id: Scalars["ID"];
  lowerBound: Scalars["Float"];
  metric: MetricName;
  upperBound: Scalars["Float"];
};

export type BenchmarkValues = {
  __typename?: "BenchmarkValues";
  data: Array<BenchmarkValue>;
  id: Scalars["ID"];
};

export type BotMeeting = {
  __typename?: "BotMeeting";
  calendarEventId?: Maybe<Scalars["UUID"]>;
  call?: Maybe<Call>;
  callId?: Maybe<Scalars["UUID"]>;
  canBeImported?: Maybe<Scalars["Boolean"]>;
  canNotBeImportedReason?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  emailEventId?: Maybe<Scalars["UUID"]>;
  endedReason?: Maybe<BotMeetingEndedReason>;
  errorType?: Maybe<BotMeetingErrorType>;
  exclude: Scalars["Boolean"];
  excludeReason?: Maybe<ExcludeReason>;
  id: Scalars["UUID"];
  importMeeting: Scalars["Boolean"];
  importReason: InterviewImportDecisionReason;
  joinTime?: Maybe<Scalars["DateTime"]>;
  leftTime?: Maybe<Scalars["DateTime"]>;
  meetingPassword?: Maybe<Scalars["String"]>;
  meetingUrl?: Maybe<Scalars["String"]>;
  nextVirtualOnsiteMeetingId?: Maybe<Scalars["UUID"]>;
  organizationId: Scalars["UUID"];
  participants: Array<BotMeetingParticipant>;
  platform: BotMeetingPlatform;
  platformMeetingId?: Maybe<Scalars["String"]>;
  platformMeetingInstanceId?: Maybe<Scalars["String"]>;
  previousRunningMeetingId?: Maybe<Scalars["UUID"]>;
  recallRecordingId?: Maybe<Scalars["String"]>;
  recordingEndTime?: Maybe<Scalars["DateTime"]>;
  recordingStartTime?: Maybe<Scalars["DateTime"]>;
  recordingStarted: Scalars["Boolean"];
  scheduledEndTime: Scalars["DateTime"];
  scheduledInterview?: Maybe<ScheduledInterview>;
  scheduledStartTime: Scalars["DateTime"];
  status: BotMeetingStatus;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  virtualOnsite?: Maybe<VirtualOnsite>;
  virtualOnsiteId?: Maybe<Scalars["UUID"]>;
  waitingRoomJoinTime?: Maybe<Scalars["DateTime"]>;
  zoomAppAutoOpenEnabled: Scalars["Boolean"];
};

export enum BotMeetingEndedReason {
  AdminForceLeave = "ADMIN_FORCE_LEAVE",
  BotDestroyed = "BOT_DESTROYED",
  BotEntryDenied = "BOT_ENTRY_DENIED",
  BotKickedOut = "BOT_KICKED_OUT",
  CancelRecordingLinkClicked = "CANCEL_RECORDING_LINK_CLICKED",
  CandidateOptedOut = "CANDIDATE_OPTED_OUT",
  EndedByHost = "ENDED_BY_HOST",
  EveryoneLeft = "EVERYONE_LEFT",
  ExcludedUserJoined = "EXCLUDED_USER_JOINED",
  IdleTimeout = "IDLE_TIMEOUT",
  InterviewerDisabledRecording = "INTERVIEWER_DISABLED_RECORDING",
  InterviewerOptedOut = "INTERVIEWER_OPTED_OUT",
  InMeetingNotRecordingTimeout = "IN_MEETING_NOT_RECORDING_TIMEOUT",
  InMeetingRecordingTimeout = "IN_MEETING_RECORDING_TIMEOUT",
  MaxDurationExceeded = "MAX_DURATION_EXCEEDED",
  MaxParticipantsExceeded = "MAX_PARTICIPANTS_EXCEEDED",
  NooneJoined = "NOONE_JOINED",
  OnlyBotsInMeeting = "ONLY_BOTS_IN_MEETING",
  RecordingPermissionDenied = "RECORDING_PERMISSION_DENIED",
  Unknown = "UNKNOWN",
  UpdateVirtualOnsiteMeeting = "UPDATE_VIRTUAL_ONSITE_MEETING",
  VirtualOnsiteSplit = "VIRTUAL_ONSITE_SPLIT",
  WaitingRoomTimeout = "WAITING_ROOM_TIMEOUT",
}

export enum BotMeetingErrorType {
  BotErrored = "BOT_ERRORED",
  BotKickedFromCall = "BOT_KICKED_FROM_CALL",
  DuplicateBot = "DUPLICATE_BOT",
  GoogleMeetBotBlocked = "GOOGLE_MEET_BOT_BLOCKED",
  GoogleMeetInternalError = "GOOGLE_MEET_INTERNAL_ERROR",
  GoogleMeetLoginNotAvailable = "GOOGLE_MEET_LOGIN_NOT_AVAILABLE",
  GoogleMeetMeetingRoomNotReady = "GOOGLE_MEET_MEETING_ROOM_NOT_READY",
  GoogleMeetSignInCaptchaFailed = "GOOGLE_MEET_SIGN_IN_CAPTCHA_FAILED",
  GoogleMeetSignInFailed = "GOOGLE_MEET_SIGN_IN_FAILED",
  GoogleMeetSignInMissingLoginCredentials = "GOOGLE_MEET_SIGN_IN_MISSING_LOGIN_CREDENTIALS",
  GoogleMeetSignInMissingRecoveryCredentials = "GOOGLE_MEET_SIGN_IN_MISSING_RECOVERY_CREDENTIALS",
  GoogleMeetSsoSignInFailed = "GOOGLE_MEET_SSO_SIGN_IN_FAILED",
  GoogleMeetSsoSignInMissingLoginCredentials = "GOOGLE_MEET_SSO_SIGN_IN_MISSING_LOGIN_CREDENTIALS",
  GoogleMeetSsoSignInMissingTotpSecret = "GOOGLE_MEET_SSO_SIGN_IN_MISSING_TOTP_SECRET",
  GoogleMeetVideoError = "GOOGLE_MEET_VIDEO_ERROR",
  MeetingEnded = "MEETING_ENDED",
  MeetingFull = "MEETING_FULL",
  MeetingLinkExpired = "MEETING_LINK_EXPIRED",
  MeetingLinkInvalid = "MEETING_LINK_INVALID",
  MeetingLocked = "MEETING_LOCKED",
  MeetingNotFound = "MEETING_NOT_FOUND",
  MeetingNotStarted = "MEETING_NOT_STARTED",
  MeetingPasswordIncorrect = "MEETING_PASSWORD_INCORRECT",
  MeetingRequiresSignIn = "MEETING_REQUIRES_SIGN_IN",
  MicrosoftTeamsBotNotInvited = "MICROSOFT_TEAMS_BOT_NOT_INVITED",
  MicrosoftTeamsCallDropped = "MICROSOFT_TEAMS_CALL_DROPPED",
  MicrosoftTeamsCaptchaDetected = "MICROSOFT_TEAMS_CAPTCHA_DETECTED",
  MicrosoftTeamsInternalError = "MICROSOFT_TEAMS_INTERNAL_ERROR",
  MicrosoftTeamsSignInCredentialsMissing = "MICROSOFT_TEAMS_SIGN_IN_CREDENTIALS_MISSING",
  MicrosoftTeamsSignInFailed = "MICROSOFT_TEAMS_SIGN_IN_FAILED",
  NotetakerCantJoinCall = "NOTETAKER_CANT_JOIN_CALL",
  NotetakerSubscriptionError = "NOTETAKER_SUBSCRIPTION_ERROR",
  NotetakerUploadFailed = "NOTETAKER_UPLOAD_FAILED",
  Stuck = "STUCK",
  Unknown = "UNKNOWN",
  ZoomAccountBlocked = "ZOOM_ACCOUNT_BLOCKED",
  ZoomCaptchaRequired = "ZOOM_CAPTCHA_REQUIRED",
  ZoomConnectionFailed = "ZOOM_CONNECTION_FAILED",
  ZoomEmailBlockedByAdmin = "ZOOM_EMAIL_BLOCKED_BY_ADMIN",
  ZoomEmailRequired = "ZOOM_EMAIL_REQUIRED",
  ZoomErrorMultipleDeviceJoin = "ZOOM_ERROR_MULTIPLE_DEVICE_JOIN",
  ZoomInternalError = "ZOOM_INTERNAL_ERROR",
  ZoomInvalidJoinToken = "ZOOM_INVALID_JOIN_TOKEN",
  ZoomInvalidSignature = "ZOOM_INVALID_SIGNATURE",
  ZoomJoinTimeout = "ZOOM_JOIN_TIMEOUT",
  ZoomMeetingHostInactive = "ZOOM_MEETING_HOST_INACTIVE",
  ZoomMeetingNotAccessible = "ZOOM_MEETING_NOT_ACCESSIBLE",
  ZoomRegistrationRequired = "ZOOM_REGISTRATION_REQUIRED",
  ZoomSdkAppNotPublished = "ZOOM_SDK_APP_NOT_PUBLISHED",
  ZoomSdkCredentialsMissing = "ZOOM_SDK_CREDENTIALS_MISSING",
  ZoomSdkUpdateRequired = "ZOOM_SDK_UPDATE_REQUIRED",
  ZoomWebDisallowed = "ZOOM_WEB_DISALLOWED",
}

export type BotMeetingParticipant = {
  __typename?: "BotMeetingParticipant";
  botMeetingId: Scalars["UUID"];
  callSpeakerOptionId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  identificationMethod?: Maybe<MeetingParticipantIdentificationMethod>;
  identificationSource?: Maybe<MeetingParticipantIdentificationSource>;
  identifiedAs?: Maybe<Scalars["String"]>;
  identifiedDuringInterview?: Maybe<Scalars["Boolean"]>;
  inMeeting: Scalars["Boolean"];
  isCandidate: Scalars["Boolean"];
  name: Scalars["String"];
  recallParticipantId?: Maybe<Scalars["Float"]>;
  scheduledInterviewerId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["DateTime"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["UUID"]>;
  zoomAppChatMessageSent: Scalars["Boolean"];
  zoomConfUserId?: Maybe<Scalars["String"]>;
  zoomUserId?: Maybe<Scalars["Float"]>;
};

export enum BotMeetingPlatform {
  GoogleMeet = "GOOGLE_MEET",
  MsTeams = "MS_TEAMS",
  Zoom = "ZOOM",
}

export enum BotMeetingStatus {
  Ended = "ENDED",
  Error = "ERROR",
  InMeetingNotRecording = "IN_MEETING_NOT_RECORDING",
  InMeetingRecording = "IN_MEETING_RECORDING",
  InWaitingRoom = "IN_WAITING_ROOM",
  Joining = "JOINING",
  Pending = "PENDING",
}

export type BrowserExtensionData = {
  __typename?: "BrowserExtensionData";
  ats?: Maybe<Ats>;
  calls: Array<Call>;
  candidate?: Maybe<Candidate>;
  intent?: Maybe<BrowserExtensionIntent>;
  position?: Maybe<Position>;
  tabUrl?: Maybe<Scalars["String"]>;
};

export type BrowserExtensionDataCallsArgs = {
  tabUrl: Scalars["String"];
};

export type BrowserExtensionDataCandidateArgs = {
  email?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  tabUrl: Scalars["String"];
};

export type BrowserExtensionDataPositionArgs = {
  tabUrl: Scalars["String"];
};

export enum BrowserExtensionIntent {
  Application = "APPLICATION",
  Candidate = "CANDIDATE",
  Interview = "INTERVIEW",
}

export type BullhornAuth = {
  __typename?: "BullhornAuth";
  authUrl?: Maybe<Scalars["String"]>;
  isAuthorized: Scalars["Boolean"];
};

export type BullhornCallParams = {
  __typename?: "BullhornCallParams";
  bullhornCallParams?: Maybe<CallParams>;
};

export enum BullhornEntity {
  Candidate = "CANDIDATE",
  Contact = "CONTACT",
}

export type Calendar = {
  __typename?: "Calendar";
  canSetVideoMeetingImportStrategy: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  events: Array<Event>;
  id: Scalars["UUID"];
  msftSubscriptionId?: Maybe<Scalars["UUID"]>;
  name: Scalars["String"];
  owner: User;
  ownerId: Scalars["UUID"];
  primary?: Maybe<Scalars["Boolean"]>;
  resourceId: Scalars["String"];
  timeZone?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  videoMeetingImportStrategy: Scalars["String"];
};

export type Call = {
  __typename?: "Call";
  adjustedCallInteractivity?: Maybe<Scalars["Float"]>;
  aiNotesCustomFormat: AiNotesFormat;
  aiNotesFormat?: Maybe<CallAiSummaryFormat>;
  aiNotesLoading: Scalars["Boolean"];
  aiNotesProcessingCompletedAt?: Maybe<Scalars["DateTime"]>;
  aiNotesProcessingStartedAt?: Maybe<Scalars["DateTime"]>;
  aiNotesProcessingStatus?: Maybe<AiNotesProcessingStatus>;
  aiNotesSupportedFormats: Array<CallAiSummaryFormat>;
  aiScorecardCustomSummary?: Maybe<Scalars["String"]>;
  aiScorecardQuestionNotes: Array<AiQuestionNote>;
  aiTopicsProcessingCompletedAt?: Maybe<Scalars["DateTime"]>;
  aiTopicsProcessingStartedAt?: Maybe<Scalars["DateTime"]>;
  aiTopicsStatuses?: Maybe<Array<CallAiTopicStatus>>;
  ashbyFeedbackLink?: Maybe<Scalars["String"]>;
  ashbyFeedbackLinkWithId?: Maybe<Scalars["String"]>;
  atsRequisitionId?: Maybe<Scalars["String"]>;
  autoSummary?: Maybe<Scalars["String"]>;
  autofillAvailable: Scalars["Boolean"];
  averageScore?: Maybe<Scalars["Float"]>;
  bullhornCommentAction?: Maybe<Scalars["String"]>;
  callClips: Array<Clip>;
  callGuide?: Maybe<CallGuide>;
  callInteractivity?: Maybe<Scalars["Float"]>;
  callInteractivityScaled?: Maybe<Scalars["Int"]>;
  callShares: Array<CallShare>;
  canDelete: Scalars["Boolean"];
  canEdit: Scalars["Boolean"];
  canEditNotes: Scalars["Boolean"];
  canShare: Scalars["Boolean"];
  canShareExternal: Scalars["Boolean"];
  canViewOtherScores: Scalars["Boolean"];
  candidate?: Maybe<Candidate>;
  candidateId?: Maybe<Scalars["UUID"]>;
  candidateQuestionOpportunity?: Maybe<Scalars["Boolean"]>;
  candidateQuestionOpportunityModelVersion?: Maybe<Scalars["String"]>;
  carrierName?: Maybe<Scalars["String"]>;
  carrierType?: Maybe<Scalars["String"]>;
  chaptersProcessingCompletedAt?: Maybe<Scalars["DateTime"]>;
  chaptersProcessingStartedAt?: Maybe<Scalars["DateTime"]>;
  chaptersProcessingStatus?: Maybe<ChaptersProcessingStatus>;
  clipsCount: Scalars["Int"];
  communicationType?: Maybe<CommunicationType>;
  compensationExtractionCompletedAt?: Maybe<Scalars["DateTime"]>;
  compensationExtractionModelVersion?: Maybe<Scalars["String"]>;
  compensationExtractionStartedAt?: Maybe<Scalars["DateTime"]>;
  compensationExtractionStatus?: Maybe<CompensationExtractionStatus>;
  completedGreenhouseScorecard?: Maybe<GreenhouseScorecard>;
  coverImageUrl?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  currentUserScorecard?: Maybe<Scorecard>;
  customAtsUrl?: Maybe<Scalars["String"]>;
  customTemplateSummarySent: Scalars["Boolean"];
  draftScorecard?: Maybe<GreenhouseScorecardV3>;
  duration?: Maybe<Scalars["Int"]>;
  endTime?: Maybe<Scalars["DateTime"]>;
  excludeFromAnalytics: Scalars["Boolean"];
  externalCallShares: Array<ExternalCallShare>;
  externalViewers: Array<ExternalUser>;
  generalNotes: Array<CallNote>;
  greenhouseScorecardLink?: Maybe<Scalars["String"]>;
  greenhouseScorecardLinkWithId?: Maybe<Scalars["String"]>;
  greenhouseStageName?: Maybe<Scalars["String"]>;
  greenhouseUserRating?: Maybe<Scalars["String"]>;
  hasPendingScores?: Maybe<Scalars["Boolean"]>;
  highlights: Array<CallHighlight>;
  iaVersion: Scalars["Float"];
  id: Scalars["UUID"];
  interviewerIds: Array<Scalars["String"]>;
  interviewerLongestMonologue?: Maybe<Scalars["Int"]>;
  interviewerTalkTime?: Maybe<Scalars["Int"]>;
  interviewerWordsPerMinute?: Maybe<Scalars["Float"]>;
  interviewerWordsPerMinuteScaled?: Maybe<Scalars["Int"]>;
  interviewers: Array<User>;
  interviewersInCallIds: Array<Scalars["String"]>;
  isAgendaSet?: Maybe<Scalars["Boolean"]>;
  isAgendaSetModelVersion?: Maybe<Scalars["String"]>;
  isInterviewer: Scalars["Boolean"];
  isProcessing: Scalars["Boolean"];
  lastIndexAt?: Maybe<Scalars["DateTime"]>;
  leverInterviewLink?: Maybe<Scalars["String"]>;
  leverInterviewLinkWithId?: Maybe<Scalars["String"]>;
  leverStageName?: Maybe<Scalars["String"]>;
  meetingUrl?: Maybe<Scalars["String"]>;
  myScore?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  needsCurrentUserScore?: Maybe<Scalars["Boolean"]>;
  noRecordingReason?: Maybe<NoRecordingReason>;
  notes: Array<CallNote>;
  notesScorecards?: Maybe<Array<NotesScorecard>>;
  organizationId: Scalars["UUID"];
  otherUsersScorecards?: Maybe<Array<Scorecard>>;
  phoneNumber?: Maybe<Scalars["String"]>;
  position?: Maybe<Position>;
  positionId?: Maybe<Scalars["UUID"]>;
  questions: Array<CallNote>;
  rating: Scalars["Float"];
  recordingDisabled: Scalars["Boolean"];
  recordingPauseInProgress: Scalars["Boolean"];
  recordingPaused: Scalars["Boolean"];
  recordingProcessingCompletedAt?: Maybe<Scalars["DateTime"]>;
  recordingProcessingStartedAt?: Maybe<Scalars["DateTime"]>;
  recordingProcessingStatus?: Maybe<CallRecordingProcessingStatus>;
  recordings: Array<Recording>;
  redactedAt?: Maybe<Scalars["DateTime"]>;
  requestCandidateInformation: Scalars["Boolean"];
  scheduledEndTime?: Maybe<Scalars["DateTime"]>;
  scheduledInterview?: Maybe<ScheduledInterview>;
  scheduledInterviewId?: Maybe<Scalars["UUID"]>;
  scorecard?: Maybe<Scorecard>;
  scorecards?: Maybe<Array<Scorecard>>;
  scoringEnabled: Scalars["Boolean"];
  scoringReminderEmailSent: Scalars["Boolean"];
  shareableUsers: Array<User>;
  skipRedaction: Scalars["Boolean"];
  slackSummarySent: Scalars["Boolean"];
  smartrecruitersInterviewLink?: Maybe<Scalars["String"]>;
  speakerOptions: Array<CallSpeakerOption>;
  speakers: Array<CallSpeaker>;
  startTime?: Maybe<Scalars["DateTime"]>;
  status: CallStatus;
  streamableAudio?: Maybe<CallMedia>;
  streamableVideo?: Maybe<CallMedia>;
  summarySent: Scalars["Boolean"];
  tagsProcessingCompletedAt?: Maybe<Scalars["DateTime"]>;
  tagsProcessingStartedAt?: Maybe<Scalars["DateTime"]>;
  tagsProcessingStatus?: Maybe<TagsProcessingStatus>;
  thumbnailImageScaleX?: Maybe<Scalars["Float"]>;
  thumbnailImageUrl?: Maybe<Scalars["String"]>;
  thumbnailsProcessingCompletedAt?: Maybe<Scalars["DateTime"]>;
  thumbnailsProcessingStartedAt?: Maybe<Scalars["DateTime"]>;
  thumbnailsProcessingStatus?: Maybe<CallThumbnailsProcessingStatus>;
  totalTalkTime?: Maybe<Scalars["Int"]>;
  trackerSegments: Array<TrackerSegment>;
  trackers?: Maybe<Array<TrackerGroup>>;
  trainingProgramCount: Scalars["Int"];
  transcript: Array<TranscriptSegment>;
  transcriptLanguageCode?: Maybe<Scalars["String"]>;
  transcriptionRetries: Scalars["Int"];
  transcriptionStatus?: Maybe<TranscriptionStatus>;
  twilioCallSid?: Maybe<Scalars["String"]>;
  twilioCandidateAnsweredByType?: Maybe<Scalars["String"]>;
  twilioRecordingSid?: Maybe<Scalars["String"]>;
  type: CallType;
  updatedAt: Scalars["DateTime"];
  useSafeNumber: Scalars["Boolean"];
  viewers: Array<User>;
  virtualOnsite?: Maybe<VirtualOnsite>;
  virtualOnsiteId?: Maybe<Scalars["UUID"]>;
  visibility: CallVisibility;
  visibleTo: PaginatedRecordingVisibleTo;
  visibleVisibilityLevels: Array<CallVisibility>;
};

export type CallAiScorecardQuestionNotesArgs = {
  scorecardItems?: InputMaybe<Array<ExtensionScorecardItem>>;
  tabUrl?: InputMaybe<Scalars["String"]>;
};

export type CallGeneralNotesArgs = {
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type CallHighlightsArgs = {
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type CallNotesArgs = {
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type CallQuestionsArgs = {
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type CallStreamableAudioArgs = {
  redacted?: InputMaybe<Scalars["Boolean"]>;
};

export type CallStreamableVideoArgs = {
  redacted?: InputMaybe<Scalars["Boolean"]>;
};

export type CallTrackerSegmentsArgs = {
  trackerGroupId?: InputMaybe<Scalars["String"]>;
  trackerKeywordId?: InputMaybe<Scalars["String"]>;
};

export type CallVisibleToArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type CallAiNotes = {
  __typename?: "CallAiNotes";
  aiNotesProcessingStatus?: Maybe<AiNotesProcessingStatus>;
  callId: Scalars["String"];
  questionsAndAnswers: Array<QuestionAndAnswer>;
};

export type CallAiSummary = {
  __typename?: "CallAiSummary";
  callGuideId?: Maybe<Scalars["UUID"]>;
  callId: Scalars["UUID"];
  candidateSummaryTemplateId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  customTemplateId?: Maybe<Scalars["UUID"]>;
  customTopics?: Maybe<Array<CallAiSummaryCustomTopic>>;
  format: CallAiSummaryFormat;
  headers?: Maybe<Array<CallAiSummaryHeader>>;
  id: Scalars["UUID"];
  modelVersion: Scalars["String"];
  organizationId: Scalars["UUID"];
  outputText?: Maybe<Scalars["String"]>;
  processingCompletedAt?: Maybe<Scalars["DateTime"]>;
  processingStartedAt?: Maybe<Scalars["DateTime"]>;
  status: CallAiSummaryProcessingStatus;
  targetSpeakerTags: Array<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
};

export type CallAiSummaryCustomTopic = {
  __typename?: "CallAiSummaryCustomTopic";
  questions: Array<Scalars["String"]>;
  title: Scalars["String"];
};

export type CallAiSummaryCustomTopicInput = {
  questions: Array<Scalars["String"]>;
  title: Scalars["String"];
};

export enum CallAiSummaryFormat {
  CandidateSummaryCustom = "CANDIDATE_SUMMARY_CUSTOM",
  Cs = "CS",
  Custom = "CUSTOM",
  Debrief = "DEBRIEF",
  Intake = "INTAKE",
  ProductDemoFeedback = "PRODUCT_DEMO_FEEDBACK",
  Qanda = "QANDA",
  Sales = "SALES",
  Scorecard = "SCORECARD",
  Tldr = "TLDR",
  Writeup = "WRITEUP",
}

export type CallAiSummaryHeader = {
  __typename?: "CallAiSummaryHeader";
  callAiSummaryId: Scalars["UUID"];
  callGuideItemId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  notes?: Maybe<Array<CallAiSummaryNote>>;
  organizationId: Scalars["UUID"];
  position: Scalars["Int"];
  text: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type CallAiSummaryNote = {
  __typename?: "CallAiSummaryNote";
  callAiSummaryHeaderId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  position: Scalars["Int"];
  startTime: Scalars["Int"];
  startTimes: Array<Maybe<Scalars["Int"]>>;
  tag?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  variation1?: Maybe<Scalars["String"]>;
  variation2?: Maybe<Scalars["String"]>;
};

export enum CallAiSummaryProcessingStatus {
  Completed = "COMPLETED",
  Dispatched = "DISPATCHED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type CallAiSummaryTemplate = {
  __typename?: "CallAiSummaryTemplate";
  canDelete: Scalars["Boolean"];
  canEdit: Scalars["Boolean"];
  canMakePublic: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["UUID"];
  creator: User;
  customPrompt?: Maybe<Scalars["String"]>;
  customType?: Maybe<CustomTemplateCustomType>;
  defaultFor: CallAiSummaryTemplateDefaultFor;
  defaultForKeywords: Array<Scalars["String"]>;
  defaultForMeetingType?: Maybe<CustomTemplateType>;
  defaultForPersona?: Maybe<UserPersona>;
  id: Scalars["UUID"];
  isCreatorDefault: Scalars["Boolean"];
  isCurrentUserDefault: Scalars["Boolean"];
  model?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  organizationId: Scalars["UUID"];
  sections: Array<CallAiSummaryTemplateSection>;
  updatedAt: Scalars["DateTime"];
  visibility: CallAiSummaryTemplateVisibility;
};

export enum CallAiSummaryTemplateDefaultFor {
  All = "ALL",
  Keywords = "KEYWORDS",
  MeetingType = "MEETING_TYPE",
  None = "NONE",
  Persona = "PERSONA",
}

export type CallAiSummaryTemplateSection = {
  __typename?: "CallAiSummaryTemplateSection";
  callAiSummaryTemplateId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  position: Scalars["Int"];
  prompt: Array<Scalars["String"]>;
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export enum CallAiSummaryTemplateVisibility {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type CallAiTagsStatus = {
  __typename?: "CallAiTagsStatus";
  callId: Scalars["String"];
  canGenerateTags?: Maybe<Scalars["Boolean"]>;
  tagsProcessingStatus?: Maybe<TagsProcessingStatus>;
};

export type CallAiTopicStatus = {
  __typename?: "CallAiTopicStatus";
  id: Scalars["ID"];
  status: AiNotesProcessingStatus;
  topic: AiTopic;
};

export type CallAiTopics = {
  __typename?: "CallAiTopics";
  aiTopicsStatuses?: Maybe<Array<CallAiTopicStatus>>;
  callId: Scalars["String"];
  topics: Array<TopicSummaries>;
};

export type CallChapters = {
  __typename?: "CallChapters";
  callId: Scalars["String"];
  chapters: Array<Chapter>;
};

export type CallGuide = {
  __typename?: "CallGuide";
  ashbyFeedbackFormId?: Maybe<Scalars["String"]>;
  assignedUsers: Array<CallGuideUser>;
  atsId?: Maybe<Scalars["String"]>;
  calls: Array<Call>;
  canEdit: Scalars["Boolean"];
  canManageCallGuideAssignments: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars["UUID"]>;
  cues: Array<CallGuideCue>;
  customAtsId?: Maybe<Scalars["String"]>;
  greenhouseId?: Maybe<Scalars["Float"]>;
  id: Scalars["UUID"];
  interviewStage?: Maybe<InterviewStage>;
  interviewStageId?: Maybe<Scalars["UUID"]>;
  isTemplate: Scalars["Boolean"];
  leverFeedbackTemplateId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  organization: Organization;
  organizationId: Scalars["UUID"];
  overallScoringEnabled: Scalars["Boolean"];
  position?: Maybe<Position>;
  positionId?: Maybe<Scalars["UUID"]>;
  smartrecruitersCriteriaId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  visibility: CallGuideVisibility;
};

export type CallGuideCue = {
  __typename?: "CallGuideCue";
  callGuide: CallGuide;
  callGuideId: Scalars["UUID"];
  childItems: Array<CallGuideItemChild>;
  competencyId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  cue: Scalars["String"];
  customAtsId?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  greenhouseQuestionId?: Maybe<Scalars["Float"]>;
  id: Scalars["UUID"];
  isHtmlType: Scalars["Boolean"];
  leverFieldId?: Maybe<Scalars["String"]>;
  overriddenCue?: Maybe<Scalars["String"]>;
  position: Scalars["Float"];
  scoringEnabled: Scalars["Boolean"];
  type: GuideItemType;
  updatedAt: Scalars["DateTime"];
};

export type CallGuideItemChild = {
  __typename?: "CallGuideItemChild";
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  id: Scalars["UUID"];
  parentCallGuideItemId: Scalars["UUID"];
  position: Scalars["Float"];
  updatedAt: Scalars["DateTime"];
};

export type CallGuideItemChildInput = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  position?: InputMaybe<Scalars["Int"]>;
};

export type CallGuideItemInput = {
  childItems: Array<CallGuideItemChildInput>;
  competencyId?: InputMaybe<Scalars["ID"]>;
  cue?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  isHtmlType?: InputMaybe<Scalars["Boolean"]>;
  position?: InputMaybe<Scalars["Int"]>;
  scoringEnabled?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<GuideItemType>;
};

export type CallGuideUser = {
  __typename?: "CallGuideUser";
  callGuide: CallGuide;
  callGuideId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  dateAssigned: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  user: User;
  userId: Scalars["UUID"];
};

export enum CallGuideVisibility {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

/** An item for the "Highlights" tab of the candidate page. */
export type CallHighlight = {
  description: Scalars["String"];
  endTime?: Maybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  shortDescription: Scalars["String"];
  startTime: Scalars["Int"];
  text: Scalars["String"];
  thumbnailImageScaleX?: Maybe<Scalars["Float"]>;
  thumbnailImageUrl?: Maybe<Scalars["String"]>;
};

export type CallMedia = {
  __typename?: "CallMedia";
  expiration: Scalars["DateTime"];
  height?: Maybe<Scalars["Int"]>;
  url: Scalars["String"];
  width?: Maybe<Scalars["Int"]>;
};

export type CallNote = {
  __typename?: "CallNote";
  call: Call;
  callId: Scalars["UUID"];
  canAutofill: Scalars["Boolean"];
  canDelete: Scalars["Boolean"];
  clipId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  cueId?: Maybe<Scalars["ID"]>;
  description?: Maybe<Scalars["String"]>;
  guideItemId?: Maybe<Scalars["UUID"]>;
  highlightEndTime?: Maybe<Scalars["Float"]>;
  highlightStartTime?: Maybe<Scalars["Float"]>;
  id: Scalars["UUID"];
  isAtsSingleLineInput: Scalars["Boolean"];
  isEdited: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  leverFieldType?: Maybe<LeverFeedbackTemplateFieldTypes>;
  modelName?: Maybe<Scalars["String"]>;
  parentCueId?: Maybe<Scalars["ID"]>;
  parentGuideItemId?: Maybe<Scalars["UUID"]>;
  parentNoteId?: Maybe<Scalars["UUID"]>;
  position?: Maybe<Scalars["Float"]>;
  questionId?: Maybe<Scalars["UUID"]>;
  questionNotes: Array<CallNote>;
  replies: Array<CallNote>;
  score?: Maybe<Scalars["Float"]>;
  scorecardQuestionId?: Maybe<Scalars["UUID"]>;
  speakerTag?: Maybe<Scalars["Int"]>;
  text: Scalars["String"];
  thumbnailImageScaleX?: Maybe<Scalars["Float"]>;
  thumbnailImageUrl?: Maybe<Scalars["String"]>;
  thumbnailsProcessingCompletedAt?: Maybe<Scalars["DateTime"]>;
  thumbnailsProcessingStartedAt?: Maybe<Scalars["DateTime"]>;
  thumbnailsProcessingStatus?: Maybe<CallNoteThumbnailsProcessingStatus>;
  time: Scalars["Int"];
  type: CallNoteType;
  updatedAt: Scalars["DateTime"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["UUID"]>;
  visibility: CallNoteVisibility;
};

export type CallNoteQuestionNotesArgs = {
  clipId?: InputMaybe<Scalars["ID"]>;
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
  forAutofill?: InputMaybe<Scalars["Boolean"]>;
};

export type CallNoteRepliesArgs = {
  clipId?: InputMaybe<Scalars["ID"]>;
};

export type CallNoteHighlight = CallHighlight & {
  __typename?: "CallNoteHighlight";
  call: Call;
  callId: Scalars["UUID"];
  canDelete: Scalars["Boolean"];
  clipId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  cueId?: Maybe<Scalars["ID"]>;
  description: Scalars["String"];
  endTime?: Maybe<Scalars["Int"]>;
  guideItemId?: Maybe<Scalars["UUID"]>;
  highlightEndTime?: Maybe<Scalars["Float"]>;
  highlightStartTime?: Maybe<Scalars["Float"]>;
  id: Scalars["UUID"];
  isEdited: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  leverFieldType?: Maybe<LeverFeedbackTemplateFieldTypes>;
  modelName?: Maybe<Scalars["String"]>;
  parentCueId?: Maybe<Scalars["ID"]>;
  parentGuideItemId?: Maybe<Scalars["UUID"]>;
  parentNoteId?: Maybe<Scalars["UUID"]>;
  position?: Maybe<Scalars["Float"]>;
  questionId?: Maybe<Scalars["UUID"]>;
  questionNotes: Array<CallNote>;
  replies: Array<CallNote>;
  score?: Maybe<Scalars["Float"]>;
  scorecardQuestionId?: Maybe<Scalars["UUID"]>;
  shortDescription: Scalars["String"];
  speakerTag?: Maybe<Scalars["Int"]>;
  startTime: Scalars["Int"];
  text: Scalars["String"];
  thumbnailImageScaleX?: Maybe<Scalars["Float"]>;
  thumbnailImageUrl?: Maybe<Scalars["String"]>;
  thumbnailsProcessingCompletedAt?: Maybe<Scalars["DateTime"]>;
  thumbnailsProcessingStartedAt?: Maybe<Scalars["DateTime"]>;
  thumbnailsProcessingStatus?: Maybe<CallNoteThumbnailsProcessingStatus>;
  time: Scalars["Int"];
  type: CallNoteType;
  updatedAt: Scalars["DateTime"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["UUID"]>;
  visibility: CallNoteVisibility;
};

export type CallNoteHighlightQuestionNotesArgs = {
  clipId?: InputMaybe<Scalars["ID"]>;
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
  forAutofill?: InputMaybe<Scalars["Boolean"]>;
};

export type CallNoteHighlightRepliesArgs = {
  clipId?: InputMaybe<Scalars["ID"]>;
};

export enum CallNoteThumbnailsProcessingStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export enum CallNoteType {
  Comment = "COMMENT",
  Cue = "CUE",
  Flag = "FLAG",
  HighlightV2 = "HIGHLIGHT_V2",
  Note = "NOTE",
  Question = "QUESTION",
  Star = "STAR",
  ThumbsDown = "THUMBS_DOWN",
  ThumbsUp = "THUMBS_UP",
}

export enum CallNoteVisibility {
  CallOwner = "CALL_OWNER",
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type CallParams = {
  __typename?: "CallParams";
  atsRequisitionId?: Maybe<Scalars["String"]>;
  callGuideId?: Maybe<Scalars["ID"]>;
  candidateEmail?: Maybe<Scalars["String"]>;
  candidateFirstName?: Maybe<Scalars["String"]>;
  candidateId?: Maybe<Scalars["ID"]>;
  candidateLastName?: Maybe<Scalars["String"]>;
  greenhouseScorecardLink?: Maybe<Scalars["String"]>;
  leverInterviewLink?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["ID"]>;
  scheduledInterviewId?: Maybe<Scalars["ID"]>;
};

export type CallQuestionAnswer = {
  __typename?: "CallQuestionAnswer";
  answer: Scalars["String"];
  question: Scalars["String"];
  questionId: Scalars["ID"];
};

export enum CallRecordingProcessingStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type CallShare = {
  __typename?: "CallShare";
  approved: Scalars["Boolean"];
  approvedBy: User;
  approvedById: Scalars["UUID"];
  call: Call;
  callId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  message?: Maybe<Scalars["String"]>;
  sharedBy: User;
  sharedById: Scalars["UUID"];
  sharedTo: User;
  sharedToId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export type CallSpeaker = {
  __typename?: "CallSpeaker";
  callId: Scalars["UUID"];
  callSpeakerOptionId?: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  identified: Scalars["Boolean"];
  isCandidate: Scalars["Boolean"];
  isInterviewer?: Maybe<Scalars["Boolean"]>;
  label: Scalars["String"];
  longestMonologue?: Maybe<Scalars["Int"]>;
  speakerTag: Scalars["Int"];
  talkTime?: Maybe<Scalars["Int"]>;
  talkTimePercentage: Scalars["Float"];
  wordsPerMinute?: Maybe<Scalars["Float"]>;
  wordsPerMinuteScaled?: Maybe<Scalars["Int"]>;
};

export type CallSpeakerOption = {
  __typename?: "CallSpeakerOption";
  callId: Scalars["UUID"];
  defaultLabel: Scalars["String"];
  id: Scalars["UUID"];
  interviewerId?: Maybe<Scalars["UUID"]>;
  isCandidate: Scalars["Boolean"];
  label: Scalars["String"];
  profilePicUrl?: Maybe<Scalars["String"]>;
  scheduledInterviewerId?: Maybe<Scalars["UUID"]>;
};

export enum CallStatus {
  Busy = "BUSY",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Disclaimer = "DISCLAIMER",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  NoAnswer = "NO_ANSWER",
  Pending = "PENDING",
}

export type CallThumbnail = {
  __typename?: "CallThumbnail";
  callId: Scalars["ID"];
  thumbnailUrl?: Maybe<Scalars["String"]>;
};

export enum CallThumbnailsProcessingStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type CallTraining = {
  __typename?: "CallTraining";
  canSubmit: Scalars["Boolean"];
  deletedQuestions: Array<CallQuestionAnswer>;
  lastSubmittedAt?: Maybe<Scalars["String"]>;
  questions: Array<CallQuestionAnswer>;
  trainingProgramId: Scalars["ID"];
  trainingProgramItemId: Scalars["ID"];
  trainingProgramName: Scalars["String"];
};

export enum CallType {
  Browser = "BROWSER",
  GoogleMeet = "GOOGLE_MEET",
  Incoming = "INCOMING",
  InPersonAudio = "IN_PERSON_AUDIO",
  Outgoing = "OUTGOING",
  TeamsNotetaker = "TEAMS_NOTETAKER",
  UploadAudio = "UPLOAD_AUDIO",
  UploadVideo = "UPLOAD_VIDEO",
  Zoom = "ZOOM",
  ZoomNotetaker = "ZOOM_NOTETAKER",
}

export enum CallVisibility {
  Organization = "ORGANIZATION",
  Private = "PRIVATE",
  Public = "PUBLIC",
  Restricted = "RESTRICTED",
}

export type CancelRecording = {
  __typename?: "CancelRecording";
  success: Scalars["Boolean"];
};

export type Candidate = {
  __typename?: "Candidate";
  applications: Array<Application>;
  bullhornEntityType?: Maybe<BullhornEntity>;
  bullhornId?: Maybe<Scalars["Int"]>;
  callCount?: Maybe<Scalars["Int"]>;
  callNotesByPosition: Array<CallNote>;
  calls: Array<Call>;
  callsByPosition: Array<Call>;
  canEdit: Scalars["Boolean"];
  candidateLinks: Array<CandidateLink>;
  createdAt: Scalars["DateTime"];
  customAtsId?: Maybe<Scalars["String"]>;
  customAtsUrl?: Maybe<Scalars["String"]>;
  defaultEmail?: Maybe<Scalars["String"]>;
  defaultPhoneNumber?: Maybe<Scalars["String"]>;
  defaultPhoneNumberObj?: Maybe<CandidatePhoneNumber>;
  emails: Array<Scalars["String"]>;
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  greenhouseId?: Maybe<Scalars["Float"]>;
  greenhouseLink?: Maybe<Scalars["String"]>;
  icimsId?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  inforId?: Maybe<Scalars["String"]>;
  internalUserId?: Maybe<Scalars["UUID"]>;
  lastCall?: Maybe<Call>;
  lastCallByInterviewerPosition?: Maybe<Call>;
  lastLeverOpportunityId?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  lastPositionAverageScore?: Maybe<Scalars["Float"]>;
  leverContactId?: Maybe<Scalars["String"]>;
  mergeId?: Maybe<Scalars["String"]>;
  mergeRemoteId?: Maybe<Scalars["String"]>;
  mergeType?: Maybe<Ats>;
  optOutEmailSent: Scalars["Boolean"];
  organizationId: Scalars["UUID"];
  pastRecordingDisabledScheduledInterviews: Array<ScheduledInterview>;
  phoneNumbers: Array<CandidatePhoneNumber>;
  positionAverageScore?: Maybe<Scalars["Float"]>;
  positions: Array<Position>;
  rating?: Maybe<Scalars["Float"]>;
  ratingBreakdown?: Maybe<Array<Maybe<CandidateRatingBreakdown>>>;
  readOnly: Scalars["Boolean"];
  recordingDisabled: Scalars["Boolean"];
  redactedAt?: Maybe<Scalars["DateTime"]>;
  scheduledInterviews: Array<ScheduledInterview>;
  scheduledInterviewsByPosition: Array<ScheduledInterview>;
  scorecardsByPosition?: Maybe<Array<Scorecard>>;
  skipRedaction: Scalars["Boolean"];
  smartrecruitersId?: Maybe<Scalars["String"]>;
  smartrecruitersLink?: Maybe<Scalars["String"]>;
  talemetryId?: Maybe<Scalars["String"]>;
  thumbnail?: Maybe<Thumbnail>;
  type?: Maybe<CandidateType>;
  updatedAt: Scalars["DateTime"];
  validCallsCount?: Maybe<Scalars["Int"]>;
  zoomId?: Maybe<Scalars["String"]>;
};

export type CandidateCallNotesByPositionArgs = {
  positionId?: InputMaybe<Scalars["ID"]>;
};

export type CandidateCallsByPositionArgs = {
  positionId?: InputMaybe<Scalars["ID"]>;
};

export type CandidateLastCallByInterviewerPositionArgs = {
  positionId: Scalars["ID"];
};

export type CandidateScheduledInterviewsByPositionArgs = {
  positionId?: InputMaybe<Scalars["ID"]>;
};

export type CandidateScorecardsByPositionArgs = {
  positionId?: InputMaybe<Scalars["ID"]>;
};

export type CandidateAlert = {
  __typename?: "CandidateAlert";
  call: Call;
  callId: Scalars["UUID"];
  candidate: Candidate;
  candidateId: Scalars["UUID"];
  category: CandidateAlertCategory;
  classification: Scalars["JSONString"];
  createdAt: Scalars["DateTime"];
  evidence: Scalars["JSONString"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  position?: Maybe<Position>;
  positionId?: Maybe<Scalars["UUID"]>;
  startTime: Scalars["Int"];
  text: Scalars["String"];
  type: CandidateAlertType;
  updatedAt: Scalars["DateTime"];
};

export enum CandidateAlertAccuracyFeedback {
  Bad = "BAD",
  Good = "GOOD",
}

export type CandidateAlertCandidateSubscription = {
  __typename?: "CandidateAlertCandidateSubscription";
  candidateId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  mutedAt?: Maybe<Scalars["DateTime"]>;
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  userId: Scalars["UUID"];
};

export enum CandidateAlertCategory {
  ActionItem = "ACTION_ITEM",
  Compensation = "COMPENSATION",
  Competition = "COMPETITION",
  OtherConcerns = "OTHER_CONCERNS",
}

export type CandidateAlertFeed = {
  __typename?: "CandidateAlertFeed";
  accuracyFeedback?: Maybe<CandidateAlertAccuracyFeedback>;
  alert: CandidateAlert;
  candidateAlertId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  readAt?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
  userId: Scalars["UUID"];
};

export enum CandidateAlertFeedFilter {
  ActionItem = "ACTION_ITEM",
  All = "ALL",
  ClosingRisk = "CLOSING_RISK",
  MyInterviews = "MY_INTERVIEWS",
  Read = "READ",
  Unread = "UNREAD",
}

export type CandidateAlertPositionSubscription = {
  __typename?: "CandidateAlertPositionSubscription";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  mutedAt?: Maybe<Scalars["DateTime"]>;
  organizationId: Scalars["UUID"];
  positionId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  userId: Scalars["UUID"];
};

export enum CandidateAlertType {
  ClosingRisk = "CLOSING_RISK",
  FollowUp = "FOLLOW_UP",
}

export type CandidateAlertUserSubscriptions = {
  __typename?: "CandidateAlertUserSubscriptions";
  candidateSubscriptions: Array<CandidateAlertCandidateSubscription>;
  id: Scalars["ID"];
  positionSubscriptions: Array<CandidateAlertPositionSubscription>;
};

export type CandidateAskResponse = {
  __typename?: "CandidateAskResponse";
  id?: Maybe<Scalars["ID"]>;
  response?: Maybe<Scalars["String"]>;
};

export type CandidateChapters = {
  __typename?: "CandidateChapters";
  candidateChaptersStatus?: Maybe<ChaptersProcessingStatus>;
  chapters: Array<Chapter>;
};

export type CandidateEmailOptOut = {
  __typename?: "CandidateEmailOptOut";
  organizationName: Scalars["String"];
  recordingDisabled: Scalars["Boolean"];
  verificationCode: Scalars["String"];
  videoUrls?: Maybe<Array<Scalars["String"]>>;
};

export type CandidateKeyTheme = {
  __typename?: "CandidateKeyTheme";
  id: Scalars["ID"];
  summary: Scalars["String"];
  themeName: Scalars["String"];
};

export type CandidateKeyThemeResult = {
  __typename?: "CandidateKeyThemeResult";
  data: Array<CandidateKeyTheme>;
  id: Scalars["ID"];
};

export type CandidateLink = {
  __typename?: "CandidateLink";
  atsType?: Maybe<Scalars["String"]>;
  candidate?: Maybe<Candidate>;
  candidateId: Scalars["UUID"];
  id: Scalars["UUID"];
  link: Scalars["String"];
  source: Scalars["String"];
  type: CandidateLinkType;
};

export enum CandidateLinkType {
  Angellist = "ANGELLIST",
  Github = "GITHUB",
  Instagram = "INSTAGRAM",
  Linkedin = "LINKEDIN",
  Medium = "MEDIUM",
  Other = "OTHER",
  Twitter = "TWITTER",
}

export type CandidateMotivationCount = {
  __typename?: "CandidateMotivationCount";
  count: Scalars["Float"];
  topic: CandidateMotivationTopic;
};

export type CandidateMotivationExample = {
  __typename?: "CandidateMotivationExample";
  callId: Scalars["String"];
  callName?: Maybe<Scalars["String"]>;
  candidateFullName: Scalars["String"];
  candidateId: Scalars["String"];
  exampleId: Scalars["ID"];
  exampleStartTime: Scalars["Int"];
  motivationTopics: Scalars["String"];
  positionId?: Maybe<Scalars["String"]>;
  positionName?: Maybe<Scalars["String"]>;
  startTime: Scalars["DateTime"];
  summary: Scalars["String"];
};

export type CandidateMotivationExamplesResult = {
  __typename?: "CandidateMotivationExamplesResult";
  data: Array<CandidateMotivationExample>;
  id: Scalars["ID"];
};

export enum CandidateMotivationTopic {
  CompanyAndTeamLeadership = "COMPANY_AND_TEAM_LEADERSHIP",
  CompanyCultureAndReputation = "COMPANY_CULTURE_AND_REPUTATION",
  CompanyGrowth = "COMPANY_GROWTH",
  CompanyMissionAndValues = "COMPANY_MISSION_AND_VALUES",
  CompanyStability = "COMPANY_STABILITY",
  Compensation = "COMPENSATION",
  InnovationAndProductEnthusiasm = "INNOVATION_AND_PRODUCT_ENTHUSIASM",
  OpportunityForImpact = "OPPORTUNITY_FOR_IMPACT",
  Other = "OTHER",
  RoleAlignmentAndGrowth = "ROLE_ALIGNMENT_AND_GROWTH",
  RoleLogistics = "ROLE_LOGISTICS",
}

export type CandidateMotivationsCountsResult = {
  __typename?: "CandidateMotivationsCountsResult";
  counts: Array<CandidateMotivationCount>;
  id: Scalars["ID"];
  totalCalls: Scalars["Int"];
  totalCandidates: Scalars["Int"];
};

export type CandidatePhoneNumber = {
  __typename?: "CandidatePhoneNumber";
  atsType?: Maybe<Scalars["String"]>;
  candidate?: Maybe<Candidate>;
  candidateId: Scalars["UUID"];
  id: Scalars["UUID"];
  phoneNumber: Scalars["String"];
  type: CandidatePhoneType;
};

export enum CandidatePhoneType {
  Home = "HOME",
  Mobile = "MOBILE",
  Other = "OTHER",
  Skype = "SKYPE",
  Work = "WORK",
}

export enum CandidateQuestionTopic {
  CareerGrowth = "CAREER_GROWTH",
  ClarificationsDuringScreening = "CLARIFICATIONS_DURING_SCREENING",
  CompanyCultureAndValues = "COMPANY_CULTURE_AND_VALUES",
  CompanyPerformance = "COMPANY_PERFORMANCE",
  CompanyProduct = "COMPANY_PRODUCT",
  CompanyStrategy = "COMPANY_STRATEGY",
  CompensationAndBenefits = "COMPENSATION_AND_BENEFITS",
  DailyToolsAndSystems = "DAILY_TOOLS_AND_SYSTEMS",
  InterviewProcess = "INTERVIEW_PROCESS",
  Other = "OTHER",
  PositionLogistics = "POSITION_LOGISTICS",
  PositionResponsibilities = "POSITION_RESPONSIBILITIES",
  TeamCulture = "TEAM_CULTURE",
  TeamPriorities = "TEAM_PRIORITIES",
  TeamStructure = "TEAM_STRUCTURE",
}

export type CandidateQuestionTopicCount = {
  __typename?: "CandidateQuestionTopicCount";
  count: Scalars["Float"];
  topic: CandidateQuestionTopic;
};

export type CandidateQuestionTopicCountsResult = {
  __typename?: "CandidateQuestionTopicCountsResult";
  counts: Array<CandidateQuestionTopicCount>;
  id: Scalars["ID"];
  totalCalls: Scalars["Int"];
  totalCandidates: Scalars["Int"];
};

export type CandidateQuestionTopicExample = {
  __typename?: "CandidateQuestionTopicExample";
  callId: Scalars["String"];
  callName?: Maybe<Scalars["String"]>;
  candidateFullName: Scalars["String"];
  candidateId: Scalars["String"];
  exampleId: Scalars["ID"];
  exampleStartTime: Scalars["Int"];
  exampleText: Scalars["String"];
  exampleTopic: Scalars["String"];
  positionId?: Maybe<Scalars["String"]>;
  positionName?: Maybe<Scalars["String"]>;
  startTime: Scalars["DateTime"];
};

export type CandidateQuestionTopicExamplesResult = {
  __typename?: "CandidateQuestionTopicExamplesResult";
  data: Array<CandidateQuestionTopicExample>;
  id: Scalars["ID"];
};

export type CandidateRatingBreakdown = {
  __typename?: "CandidateRatingBreakdown";
  count: Scalars["Int"];
  rating: Scalars["Int"];
};

export enum CandidateRedactionSortField {
  Candidate = "CANDIDATE",
  HiredDate = "HIRED_DATE",
  Interviews = "INTERVIEWS",
  LastInterviewDate = "LAST_INTERVIEW_DATE",
  RejectedDate = "REJECTED_DATE",
}

export enum CandidateRedactionStatus {
  Hired = "HIRED",
  HiredOrRejected = "HIRED_OR_REJECTED",
  Rejected = "REJECTED",
}

export type CandidateRediscoveryProspect = {
  __typename?: "CandidateRediscoveryProspect";
  candidateId: Scalars["ID"];
  candidateName: Scalars["String"];
  countSkillsMet: Scalars["Int"];
  hasAtsData: Scalars["Boolean"];
  lastAppliedDate?: Maybe<Scalars["String"]>;
  lastAppliedPosition?: Maybe<Scalars["String"]>;
  lastAppliedPositionId?: Maybe<Scalars["ID"]>;
  lastInterviewStage?: Maybe<Scalars["String"]>;
  linkedinUrl?: Maybe<Scalars["String"]>;
  output: ProspectOutput;
  rejectionReason?: Maybe<Scalars["String"]>;
};

export type CandidateRediscoveryProspects = {
  __typename?: "CandidateRediscoveryProspects";
  prospects: Array<CandidateRediscoveryProspect>;
};

export type CandidateSummary = {
  __typename?: "CandidateSummary";
  calls: Array<Call>;
  candidateId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  modelVersion: Scalars["String"];
  notes: Array<CandidateSummaryNote>;
  organizationId: Scalars["UUID"];
  positionId?: Maybe<Scalars["UUID"]>;
  processingCompletedAt?: Maybe<Scalars["DateTime"]>;
  processingStartedAt?: Maybe<Scalars["DateTime"]>;
  status: CandidateSummaryProcessingStatus;
  templateId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["DateTime"];
};

export type CandidateSummaryCallCountResponse = {
  __typename?: "CandidateSummaryCallCountResponse";
  count: Scalars["Int"];
};

export type CandidateSummaryCitation = {
  __typename?: "CandidateSummaryCitation";
  callId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  noteId: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  time: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

export type CandidateSummaryNote = {
  __typename?: "CandidateSummaryNote";
  citations: Array<CandidateSummaryCitation>;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  position: Scalars["Int"];
  subtopic?: Maybe<Scalars["String"]>;
  summaryId: Scalars["UUID"];
  text: Scalars["String"];
  topic?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export enum CandidateSummaryNoteAccuracyFeedback {
  Bad = "BAD",
  Good = "GOOD",
}

export type CandidateSummaryNoteFeedback = {
  __typename?: "CandidateSummaryNoteFeedback";
  accuracyFeedback: CandidateSummaryNoteAccuracyFeedback;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  noteId: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  userId: Scalars["UUID"];
};

export type CandidateSummaryPositionPermissions = {
  __typename?: "CandidateSummaryPositionPermissions";
  canEditTemplates?: Maybe<Scalars["Boolean"]>;
  canViewSummaries?: Maybe<Scalars["Boolean"]>;
};

export enum CandidateSummaryProcessingStatus {
  Completed = "COMPLETED",
  Dispatched = "DISPATCHED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type CandidateSummaryTemplate = {
  __typename?: "CandidateSummaryTemplate";
  atsJobPostingId?: Maybe<Scalars["String"]>;
  callAiSummaries: Array<CallAiSummary>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<Scalars["UUID"]>;
  creator?: Maybe<User>;
  editedByUserAt?: Maybe<Scalars["DateTime"]>;
  editedByUserId?: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  isOrgDefault: Scalars["Boolean"];
  isPositionDefault?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  organization: Organization;
  organizationId: Scalars["UUID"];
  positions: Array<Position>;
  processingCompletedAt?: Maybe<Scalars["DateTime"]>;
  processingStartedAt?: Maybe<Scalars["DateTime"]>;
  processingStatus?: Maybe<CandidateSummaryTemplateStatus>;
  sections: Array<CandidateSummaryTemplateSection>;
  updatedAt: Scalars["DateTime"];
};

export type CandidateSummaryTemplateIsPositionDefaultArgs = {
  positionId?: InputMaybe<Scalars["ID"]>;
};

export type CandidateSummaryTemplateSection = {
  __typename?: "CandidateSummaryTemplateSection";
  candidateSummaryTemplateId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  position: Scalars["Int"];
  prompt: Array<Maybe<Scalars["String"]>>;
  subsections: Array<CandidateSummaryTemplateSubsection>;
  template: CandidateSummaryTemplate;
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type CandidateSummaryTemplateSectionInput = {
  position: Scalars["Int"];
  prompt: Array<InputMaybe<Scalars["String"]>>;
  subsections: Array<CandidateSummaryTemplateSubsectionInput>;
  title: Scalars["String"];
};

export enum CandidateSummaryTemplateStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type CandidateSummaryTemplateSubsection = {
  __typename?: "CandidateSummaryTemplateSubsection";
  candidateSummaryTemplateSectionId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  position: Scalars["Int"];
  prompt: Array<Maybe<Scalars["String"]>>;
  section: CandidateSummaryTemplateSection;
  syntheticPrompt?: Maybe<Array<Maybe<Scalars["String"]>>>;
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type CandidateSummaryTemplateSubsectionInput = {
  position: Scalars["Int"];
  prompt: Array<InputMaybe<Scalars["String"]>>;
  title: Scalars["String"];
};

export enum CandidateType {
  External = "EXTERNAL",
  Internal = "INTERNAL",
}

export type CategoryOutput = {
  __typename?: "CategoryOutput";
  bullets: Array<Maybe<Scalars["String"]>>;
  categoryName: Scalars["String"];
  indicesSkillsMet: Array<Maybe<Scalars["Int"]>>;
  tldr: Scalars["String"];
};

export type Chapter = {
  __typename?: "Chapter";
  answerEmbedding?: Maybe<Array<Scalars["Float"]>>;
  callId: Scalars["String"];
  callInterviewers?: Maybe<Array<User>>;
  callName?: Maybe<Scalars["String"]>;
  callTime?: Maybe<Scalars["DateTime"]>;
  chapterTitleEmbedding?: Maybe<Array<Scalars["Float"]>>;
  embedding?: Maybe<Array<Scalars["Float"]>>;
  endTime: Scalars["Int"];
  id: Scalars["String"];
  questionAndAnswer: QuestionAndAnswer;
  questionEmbedding?: Maybe<Array<Scalars["Float"]>>;
  startTime: Scalars["Int"];
  streamableAudio?: Maybe<CallMedia>;
  streamableVideo?: Maybe<CallMedia>;
  tags?: Maybe<Array<Scalars["String"]>>;
  text: Scalars["String"];
  thumbnailUrl?: Maybe<Scalars["String"]>;
};

export enum ChaptersProcessingStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type Client = {
  __typename?: "Client";
  autoJoinAllNewPositions: Scalars["Boolean"];
  bullhornId?: Maybe<Scalars["Int"]>;
  clientMemberships?: Maybe<Array<ClientMembership>>;
  clientMembershipsCount?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  customAtsId?: Maybe<Scalars["String"]>;
  greenhouseId?: Maybe<Scalars["Float"]>;
  icimsId?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  isLever: Scalars["Boolean"];
  mergeId?: Maybe<Scalars["String"]>;
  mergeRemoteId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  organizationId: Scalars["UUID"];
  positions: Array<Position>;
  positionsCount?: Maybe<Scalars["Int"]>;
  smartrecruitersId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type ClientMembership = {
  __typename?: "ClientMembership";
  clientId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  grantedById?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  positionRoleId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  user: User;
  userId: Scalars["UUID"];
};

export type Clip = {
  __typename?: "Clip";
  call: Call;
  callEndTime?: Maybe<Scalars["DateTime"]>;
  callId: Scalars["UUID"];
  callName?: Maybe<Scalars["String"]>;
  callPhoneNumber?: Maybe<Scalars["String"]>;
  callRecordingDisabled: Scalars["Boolean"];
  callSpeakerOptions: Array<CallSpeakerOption>;
  callSpeakers: Array<CallSpeaker>;
  callStartTime?: Maybe<Scalars["DateTime"]>;
  callType: CallType;
  canDelete: Scalars["Boolean"];
  canEdit: Scalars["Boolean"];
  canEditNotes: Scalars["Boolean"];
  canShare: Scalars["Boolean"];
  canShareExternal: Scalars["Boolean"];
  candidate?: Maybe<Candidate>;
  clipShares: Array<ClipShare>;
  createdAt: Scalars["DateTime"];
  displayName: Scalars["String"];
  duration: Scalars["Float"];
  durationFormatted: Scalars["String"];
  endTime: Scalars["Int"];
  externalClipShares: Array<ExternalClipShare>;
  externalViewers: Array<ExternalUser>;
  failureReason?: Maybe<Scalars["String"]>;
  generalNotes: Array<CallNote>;
  greenhouseScorecardLinkWithId?: Maybe<Scalars["String"]>;
  hasTraining: Scalars["Boolean"];
  iaVersion: Scalars["Float"];
  id: Scalars["UUID"];
  interviewerIds: Array<Scalars["String"]>;
  interviewers: Array<User>;
  isInterviewer: Scalars["Boolean"];
  leverInterviewLinkWithId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  notes: Array<CallNote>;
  organizationId: Scalars["UUID"];
  position?: Maybe<Position>;
  questions: Array<CallNote>;
  rating: Scalars["Int"];
  recordingReady: Scalars["Boolean"];
  recordingRetries: Scalars["Int"];
  recordingStatus: ClipRecordingStatus;
  shareableUsers: Array<User>;
  skipRedaction: Scalars["Boolean"];
  startTime: Scalars["Int"];
  streamableAudio?: Maybe<CallMedia>;
  streamableVideo?: Maybe<CallMedia>;
  thumbnailImageUrl?: Maybe<Scalars["String"]>;
  thumbnailsProcessingCompletedAt?: Maybe<Scalars["DateTime"]>;
  thumbnailsProcessingStartedAt?: Maybe<Scalars["DateTime"]>;
  thumbnailsProcessingStatus?: Maybe<CallThumbnailsProcessingStatus>;
  trackerSegments: Array<TrackerSegment>;
  trackers?: Maybe<Array<TrackerGroup>>;
  trainingProgramCount: Scalars["Int"];
  transcript: Array<TranscriptSegment>;
  updatedAt: Scalars["DateTime"];
  user: User;
  userId: Scalars["UUID"];
  viewers: Array<User>;
  visibility: CallVisibility;
  visibleTo: PaginatedRecordingVisibleTo;
  visibleVisibilityLevels: Array<CallVisibility>;
};

export type ClipGeneralNotesArgs = {
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type ClipNotesArgs = {
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type ClipQuestionsArgs = {
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type ClipTrackerSegmentsArgs = {
  trackerGroupId?: InputMaybe<Scalars["String"]>;
  trackerKeywordId?: InputMaybe<Scalars["String"]>;
};

export type ClipVisibleToArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type ClipCall = {
  __typename?: "ClipCall";
  clip: Clip;
};

export enum ClipRecordingStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type ClipShare = {
  __typename?: "ClipShare";
  clip: Clip;
  clipId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  message?: Maybe<Scalars["String"]>;
  sharedBy: User;
  sharedById: Scalars["UUID"];
  sharedTo: User;
  sharedToId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export enum CommunicationType {
  InPerson = "IN_PERSON",
  Phone = "PHONE",
  Video = "VIDEO",
}

export enum CompensationExtractionCategory {
  CandidateCurrent = "CANDIDATE_CURRENT",
  CandidateExpected = "CANDIDATE_EXPECTED",
  InterviewerStated = "INTERVIEWER_STATED",
}

export enum CompensationExtractionCategoryType {
  Candidate = "CANDIDATE",
  Interviewer = "INTERVIEWER",
}

export enum CompensationExtractionRateType {
  Annual = "ANNUAL",
  Hourly = "HOURLY",
}

export enum CompensationExtractionStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type CompensationTrendsDataPoint = {
  __typename?: "CompensationTrendsDataPoint";
  callId: Scalars["String"];
  callName?: Maybe<Scalars["String"]>;
  category: CompensationExtractionCategory;
  currency?: Maybe<Scalars["String"]>;
  exactAmount?: Maybe<Scalars["Float"]>;
  firstSegmentStartTime?: Maybe<Scalars["Int"]>;
  formattedValue: Scalars["Float"];
  maxAmount?: Maybe<Scalars["Float"]>;
  minAmount?: Maybe<Scalars["Float"]>;
  rate?: Maybe<Scalars["String"]>;
  startTime: Scalars["DateTime"];
};

export type CompensationTrendsExample = {
  __typename?: "CompensationTrendsExample";
  callId: Scalars["String"];
  callName?: Maybe<Scalars["String"]>;
  candidateFullName: Scalars["String"];
  candidateId: Scalars["String"];
  category: CompensationExtractionCategory;
  compensationExtractionId: Scalars["String"];
  currency?: Maybe<Scalars["String"]>;
  exactAmount?: Maybe<Scalars["Float"]>;
  firstSegmentStartTime: Scalars["Int"];
  maxAmount?: Maybe<Scalars["Float"]>;
  minAmount?: Maybe<Scalars["Float"]>;
  positionId?: Maybe<Scalars["String"]>;
  positionName?: Maybe<Scalars["String"]>;
  rate?: Maybe<Scalars["String"]>;
  startTime: Scalars["DateTime"];
  summary: Scalars["String"];
};

export type CompensationTrendsExamplesResult = {
  __typename?: "CompensationTrendsExamplesResult";
  data: Array<CompensationTrendsExample>;
  id: Scalars["ID"];
  totalCallCount: Scalars["Int"];
  totalCandidateCount: Scalars["Int"];
};

export type CompensationTrendsResult = {
  __typename?: "CompensationTrendsResult";
  currencyOptions: Array<GenericSelectOption>;
  data: Array<CompensationTrendsDataPoint>;
  id: Scalars["ID"];
  totalCallCount: Scalars["Int"];
  totalCandidateCount: Scalars["Int"];
};

export type CompetencyRatingDescriptions = {
  __typename?: "CompetencyRatingDescriptions";
  fiveStar: Scalars["String"];
  fourStar: Scalars["String"];
  oneStar: Scalars["String"];
  threeStar: Scalars["String"];
  twoStar: Scalars["String"];
};

export type CreateAiNoteTags = {
  __typename?: "CreateAiNoteTags";
  success: Scalars["Boolean"];
};

export type CreateAiNotes = {
  __typename?: "CreateAiNotes";
  success: Scalars["Boolean"];
};

export type CreateAiSummaryTemplate = {
  __typename?: "CreateAiSummaryTemplate";
  template?: Maybe<CallAiSummaryTemplate>;
  validationError?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type CreateAiTopics = {
  __typename?: "CreateAiTopics";
  success: Scalars["Boolean"];
};

export type CreateAndSharePlaylist = {
  __typename?: "CreateAndSharePlaylist";
  playlist: Playlist;
};

export type CreateAndSharePlaylistExternally = {
  __typename?: "CreateAndSharePlaylistExternally";
  playlist: Playlist;
};

export type CreateCustomTopic = {
  __typename?: "CreateCustomTopic";
  topic: AnalyticsCustomTopic;
};

export type CreateEventImportKeyword = {
  __typename?: "CreateEventImportKeyword";
  eventImportKeywords: Array<EventImportKeyword>;
};

export type CreateExcludeStage = {
  __typename?: "CreateExcludeStage";
  excludeStage: ExcludeStage;
};

export type CreateLeverOauthRequestUrl = {
  __typename?: "CreateLeverOauthRequestUrl";
  leverOauthUrl: Scalars["String"];
};

export type CreateMergeLinkToken = {
  __typename?: "CreateMergeLinkToken";
  linkToken: Scalars["String"];
};

export type CreateOrUpdateExcludedUser = {
  __typename?: "CreateOrUpdateExcludedUser";
  excludedUser: User;
};

export type CreateOrUpdateTaskDefinition = {
  __typename?: "CreateOrUpdateTaskDefinition";
  taskDefinition: TaskDefinition;
};

export type CreatePlaylist = {
  __typename?: "CreatePlaylist";
  playlist: Playlist;
};

export type CreateScheduledInterview = {
  __typename?: "CreateScheduledInterview";
  scheduledInterview?: Maybe<ScheduledInterview>;
};

export type CreateScheduledInterviewZoomNotetakerMeeting = {
  __typename?: "CreateScheduledInterviewZoomNotetakerMeeting";
  meeting?: Maybe<ZoomNotetakerMeeting>;
};

export type CreateSkillReport = {
  __typename?: "CreateSkillReport";
  skillReport: SkillReport;
};

export type CreateSmartrecruitersOauthRequestUrl = {
  __typename?: "CreateSmartrecruitersOauthRequestUrl";
  oauthUrl: Scalars["String"];
};

export type CreateTrainingProgram = {
  __typename?: "CreateTrainingProgram";
  trainingProgram: TrainingProgram;
};

export type CreateTrainingProgramItem = {
  __typename?: "CreateTrainingProgramItem";
  trainingProgram: TrainingProgram;
};

export type Csv = {
  __typename?: "Csv";
  url?: Maybe<Scalars["String"]>;
};

export type CursorPaginationDetails = {
  after?: InputMaybe<PaginationDelimiter>;
  before?: InputMaybe<PaginationDelimiter>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type CursorPaginationInfo = {
  __typename?: "CursorPaginationInfo";
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPreviousPage?: Maybe<Scalars["Boolean"]>;
  nextPageStartId?: Maybe<Scalars["ID"]>;
  nextPageStartTimestamp?: Maybe<Scalars["DateTime"]>;
};

export enum CustomTemplateCustomType {
  CustomPrompt = "CUSTOM_PROMPT",
  Sections = "SECTIONS",
}

export type CustomTemplateSection = {
  order?: InputMaybe<Scalars["Int"]>;
  sectionHeader?: InputMaybe<Scalars["String"]>;
  sectionText?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<JobDescriptionTemplateSectionType>;
};

export enum CustomTemplateType {
  Debrief = "DEBRIEF",
  Intake = "INTAKE",
  Interview = "INTERVIEW",
}

export enum CustomTopicMatchRule {
  All = "ALL",
  Any = "ANY",
}

export type DataPoint = {
  __typename?: "DataPoint";
  avgPerformance?: Maybe<Scalars["Float"]>;
  countDataPoints?: Maybe<Scalars["Float"]>;
  dataId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  label?: Maybe<Scalars["String"]>;
  segment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type DeauthorizeLever = {
  __typename?: "DeauthorizeLever";
  success: Scalars["Boolean"];
};

export type DeauthorizeMerge = {
  __typename?: "DeauthorizeMerge";
  success: Scalars["Boolean"];
};

export type DeauthorizeSmartrecruiters = {
  __typename?: "DeauthorizeSmartrecruiters";
  success: Scalars["Boolean"];
};

export type DeleteAiSummaryCustomTopic = {
  __typename?: "DeleteAiSummaryCustomTopic";
  summary: CallAiSummary;
};

export type DeleteAiSummaryTemplate = {
  __typename?: "DeleteAiSummaryTemplate";
  template?: Maybe<CallAiSummaryTemplate>;
};

export type DeleteAllTrainingProgramTrainees = {
  __typename?: "DeleteAllTrainingProgramTrainees";
  trainingProgram: TrainingProgram;
};

export type DeleteApiToken = {
  __typename?: "DeleteApiToken";
  setup: RestApiSetup;
};

export type DeleteCalendarOrgSettings = {
  __typename?: "DeleteCalendarOrgSettings";
  currentUser: User;
};

export type DeleteCallGuide = {
  __typename?: "DeleteCallGuide";
  callGuide: CallGuide;
};

export type DeleteCallGuideCue = {
  __typename?: "DeleteCallGuideCue";
  callGuideCue: CallGuideCue;
};

export type DeleteCallNote = {
  __typename?: "DeleteCallNote";
  callNote: CallNote;
};

export type DeleteClip = {
  __typename?: "DeleteClip";
  clip: Clip;
};

export type DeleteCustomTopic = {
  __typename?: "DeleteCustomTopic";
  success: Scalars["Boolean"];
};

export type DeleteEventImportKeyword = {
  __typename?: "DeleteEventImportKeyword";
  success: Scalars["Boolean"];
};

export type DeleteExcludeStage = {
  __typename?: "DeleteExcludeStage";
  excludeStage: ExcludeStage;
};

export type DeletePlaylist = {
  __typename?: "DeletePlaylist";
  playlistId: Scalars["ID"];
};

export type DeleteScheduledInterview = {
  __typename?: "DeleteScheduledInterview";
  scheduledInterview: ScheduledInterview;
};

export type DeleteSkillReport = {
  __typename?: "DeleteSkillReport";
  success: Scalars["Boolean"];
};

export type DeleteTrainingProgram = {
  __typename?: "DeleteTrainingProgram";
  trainingProgramId: Scalars["ID"];
};

export type DeleteTrainingProgramItem = {
  __typename?: "DeleteTrainingProgramItem";
  trainingProgram: TrainingProgram;
};

export type DeleteTrainingProgramQuestion = {
  __typename?: "DeleteTrainingProgramQuestion";
  trainingProgram: TrainingProgram;
};

export type DeleteTrainingProgramTrainee = {
  __typename?: "DeleteTrainingProgramTrainee";
  deletedTraineeId: Scalars["String"];
  trainingProgram: TrainingProgram;
};

export type DeleteUsers = {
  __typename?: "DeleteUsers";
  success: Scalars["Boolean"];
};

export type DeleteWebhook = {
  __typename?: "DeleteWebhook";
  setup: RestApiSetup;
};

export type DescribeTrainingProgramItem = {
  __typename?: "DescribeTrainingProgramItem";
  trainingProgram: TrainingProgram;
};

export type DuplicateAiSummaryTemplate = {
  __typename?: "DuplicateAiSummaryTemplate";
  template?: Maybe<CallAiSummaryTemplate>;
};

export type DuplicateCallGuide = {
  __typename?: "DuplicateCallGuide";
  callGuide: CallGuide;
};

export type DuplicateCallGuidesToPosition = {
  __typename?: "DuplicateCallGuidesToPosition";
  position: Position;
};

export type DuplicateSkillReport = {
  __typename?: "DuplicateSkillReport";
  skillReport: SkillReport;
};

export type EditCustomTopic = {
  __typename?: "EditCustomTopic";
  topic: AnalyticsCustomTopic;
};

export type EditSkillReport = {
  __typename?: "EditSkillReport";
  skillReport: SkillReport;
};

export type EnableTrainingProgramAssessment = {
  __typename?: "EnableTrainingProgramAssessment";
  trainingProgram: TrainingProgram;
};

export type EndCall = {
  __typename?: "EndCall";
  call: Call;
};

export type EndInPersonCall = {
  __typename?: "EndInPersonCall";
  call: Call;
};

export type Event = {
  __typename?: "Event";
  attendees: Array<Attendee>;
  calendar: Calendar;
  calendarId: Scalars["UUID"];
  conferenceData: Scalars["JSONString"];
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  end: Scalars["DateTime"];
  extendedProperties: Scalars["JSONString"];
  htmlLink: Scalars["String"];
  id: Scalars["UUID"];
  importExcludeDecision?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<Scalars["String"]>;
  resourceId: Scalars["String"];
  seriesMasterId?: Maybe<Scalars["String"]>;
  start: Scalars["DateTime"];
  summary?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type EventImportKeyword = {
  __typename?: "EventImportKeyword";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  keywordText: Scalars["String"];
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  userId: Scalars["UUID"];
};

export enum ExcludeReason {
  CandidateOptOut = "CANDIDATE_OPT_OUT",
  GlobalExclude = "GLOBAL_EXCLUDE",
  InterviewerOptOut = "INTERVIEWER_OPT_OUT",
}

export type ExcludeStage = {
  __typename?: "ExcludeStage";
  createdAt: Scalars["DateTime"];
  excludeText: Scalars["String"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export type ExtensionScorecardItem = {
  key: Scalars["String"];
  text?: InputMaybe<Scalars["String"]>;
};

export type ExternalCall = {
  __typename?: "ExternalCall";
  aiNotes?: Maybe<ExternalCallAiNotes>;
  audioOnlyViewing: Scalars["Boolean"];
  canEdit: Scalars["Boolean"];
  candidate?: Maybe<Candidate>;
  duration: Scalars["Float"];
  durationFormatted: Scalars["String"];
  endTime?: Maybe<Scalars["DateTime"]>;
  iaVersion: Scalars["Float"];
  id: Scalars["ID"];
  interviewers: Array<User>;
  isProcessing: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  poiSegments: Array<TrackerSegment>;
  pointsOfInterest: Array<TrackerGroup>;
  position?: Maybe<Position>;
  speakers: Array<CallSpeaker>;
  startTime?: Maybe<Scalars["DateTime"]>;
  streamableAudio?: Maybe<CallMedia>;
  streamableVideo?: Maybe<CallMedia>;
  transcript: Array<TranscriptSegment>;
  transcriptLanguageCode?: Maybe<Scalars["String"]>;
  type: CallType;
};

export type ExternalCallAiNotesArgs = {
  shareId?: InputMaybe<Scalars["ID"]>;
};

export type ExternalCallPoiSegmentsArgs = {
  trackerGroupId?: InputMaybe<Scalars["String"]>;
  trackerKeywordId?: InputMaybe<Scalars["String"]>;
};

export type ExternalCallAiNote = {
  __typename?: "ExternalCallAiNote";
  id: Scalars["ID"];
  startTimes?: Maybe<Array<Scalars["Int"]>>;
  text: Scalars["String"];
};

export type ExternalCallAiNoteHeader = {
  __typename?: "ExternalCallAiNoteHeader";
  id: Scalars["ID"];
  notes: Array<ExternalCallAiNote>;
  startTime?: Maybe<Scalars["Int"]>;
  text: Scalars["String"];
};

export type ExternalCallAiNotes = {
  __typename?: "ExternalCallAiNotes";
  headers: Array<ExternalCallAiNoteHeader>;
  id: Scalars["ID"];
  status?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type ExternalCallShare = {
  __typename?: "ExternalCallShare";
  aiNoteTemplate?: Maybe<Scalars["String"]>;
  callId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  expirationDate: Scalars["DateTime"];
  id: Scalars["UUID"];
  isExpired: Scalars["Boolean"];
  message?: Maybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  shareDaysRemaining: Scalars["Float"];
  sharedByUserId: Scalars["UUID"];
  sharedToEmail: Scalars["String"];
  sharedToExternalUser: ExternalUser;
  sharedToExternalUserId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export type ExternalClip = {
  __typename?: "ExternalClip";
  audioOnlyViewing: Scalars["Boolean"];
  canEdit: Scalars["Boolean"];
  candidate?: Maybe<Candidate>;
  duration: Scalars["Float"];
  durationFormatted: Scalars["String"];
  endTime?: Maybe<Scalars["DateTime"]>;
  iaVersion: Scalars["Float"];
  id: Scalars["ID"];
  interviewers: Array<User>;
  isProcessing: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  poiSegments: Array<TrackerSegment>;
  pointsOfInterest: Array<TrackerGroup>;
  position?: Maybe<Position>;
  speakers: Array<CallSpeaker>;
  streamableAudio?: Maybe<CallMedia>;
  streamableVideo?: Maybe<CallMedia>;
  transcript: Array<TranscriptSegment>;
  type: CallType;
};

export type ExternalClipPoiSegmentsArgs = {
  trackerGroupId?: InputMaybe<Scalars["String"]>;
  trackerKeywordId?: InputMaybe<Scalars["String"]>;
};

export type ExternalClipShare = {
  __typename?: "ExternalClipShare";
  clipId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  expirationDate: Scalars["DateTime"];
  id: Scalars["UUID"];
  isExpired: Scalars["Boolean"];
  message?: Maybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  shareDaysRemaining: Scalars["Float"];
  sharedByUserId: Scalars["UUID"];
  sharedToEmail: Scalars["String"];
  sharedToExternalUser: ExternalUser;
  sharedToExternalUserId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export type ExternalPlaylistShare = {
  __typename?: "ExternalPlaylistShare";
  createdAt: Scalars["DateTime"];
  expirationDate: Scalars["DateTime"];
  fromCallId: Scalars["UUID"];
  id: Scalars["UUID"];
  isExpired: Scalars["Boolean"];
  message?: Maybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  playlist: Playlist;
  playlistId: Scalars["UUID"];
  shareDaysRemaining: Scalars["Float"];
  sharedByUserId: Scalars["UUID"];
  sharedToExternalUser: ExternalUser;
  sharedToExternalUserId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export type ExternalRecording = ExternalCall | ExternalClip;

export type ExternalShareView = {
  __typename?: "ExternalShareView";
  externalRecording: ExternalRecording;
  id: Scalars["ID"];
  playlistNavigationInfo?: Maybe<PlaylistNavigationInfo>;
  shareDaysRemaining: Scalars["Float"];
  sharedByEmail: Scalars["String"];
};

export type ExternalUser = {
  __typename?: "ExternalUser";
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  emailInsensitive?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export type ExternalUserRequestAccessCode = {
  __typename?: "ExternalUserRequestAccessCode";
  verificationSent: Scalars["Boolean"];
};

export type ExternalUserRequestShareRenewal = {
  __typename?: "ExternalUserRequestShareRenewal";
  requestSent: Scalars["Boolean"];
};

export type ExternalUserViewCall = {
  __typename?: "ExternalUserViewCall";
  callViewed: Scalars["Boolean"];
  firstExternalView: Scalars["Boolean"];
};

export type ExternalUserViewClip = {
  __typename?: "ExternalUserViewClip";
  clipViewed: Scalars["Boolean"];
  firstExternalView: Scalars["Boolean"];
};

export type FeatureFlag = {
  __typename?: "FeatureFlag";
  autoEnabledForNewOrgs: Scalars["Boolean"];
  autoEnabledForOrgType?: Maybe<AutoEnableOrganizationType>;
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  strategy: FeatureFlagStrategy;
  updatedAt: Scalars["DateTime"];
  value?: Maybe<Scalars["JSONString"]>;
};

export enum FeatureFlagStrategy {
  Default = "DEFAULT",
  OrgOrUser = "ORG_OR_USER",
}

/** Features that only certain organizations should have access to. */
export enum FeatureName {
  AdvancedInsights = "ADVANCED_INSIGHTS",
  AiChapterTitles = "AI_CHAPTER_TITLES",
  AiNotesTemplate = "AI_NOTES_TEMPLATE",
  AiNotesTopics = "AI_NOTES_TOPICS",
  AiSummary = "AI_SUMMARY",
  AtsUserLevelImport = "ATS_USER_LEVEL_IMPORT",
  AutoInviteInterviewers = "AUTO_INVITE_INTERVIEWERS",
  Bullhorn = "BULLHORN",
  CandidateAiSummary = "CANDIDATE_AI_SUMMARY",
  CandidateAlerts = "CANDIDATE_ALERTS",
  CandidateAsk = "CANDIDATE_ASK",
  CandidateOptIn = "CANDIDATE_OPT_IN",
  CandidateOptOut = "CANDIDATE_OPT_OUT",
  ComplianceCheck = "COMPLIANCE_CHECK",
  DataRetention = "DATA_RETENTION",
  Eeoc = "EEOC",
  EmailAuth = "EMAIL_AUTH",
  GoogleAuth = "GOOGLE_AUTH",
  GoogleMeet = "GOOGLE_MEET",
  Greenhouse = "GREENHOUSE",
  Icims = "ICIMS",
  Insights = "INSIGHTS",
  InternationalCalling = "INTERNATIONAL_CALLING",
  InterviewerOptOut = "INTERVIEWER_OPT_OUT",
  InterviewAsk = "INTERVIEW_ASK",
  InPersonInterviews = "IN_PERSON_INTERVIEWS",
  JoinByPosition = "JOIN_BY_POSITION",
  Lever = "LEVER",
  LlmCallMetrics = "LLM_CALL_METRICS",
  ManualScheduling = "MANUAL_SCHEDULING",
  Merge = "MERGE",
  MsftAuth = "MSFT_AUTH",
  MsTeams = "MS_TEAMS",
  MultiLanguageTranscription = "MULTI_LANGUAGE_TRANSCRIPTION",
  OktaAuth = "OKTA_AUTH",
  PhoneAuth = "PHONE_AUTH",
  Plan = "PLAN",
  RecordingDisclaimer = "RECORDING_DISCLAIMER",
  RestApi = "REST_API",
  Scoring = "SCORING",
  SelfSignUp = "SELF_SIGN_UP",
  Sense = "SENSE",
  Smartrecruiters = "SMARTRECRUITERS",
  Training = "TRAINING",
  VirtualOnsiteSplitting = "VIRTUAL_ONSITE_SPLITTING",
  Zoom = "ZOOM",
  ZoomNative = "ZOOM_NATIVE",
}

export enum FeatureSettingLevel {
  Default = "DEFAULT",
  Group = "GROUP",
  Organization = "ORGANIZATION",
}

export type FilterValue = {
  __typename?: "FilterValue";
  count?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  label?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type FilterValues = {
  __typename?: "FilterValues";
  id: Scalars["ID"];
  values: Array<FilterValue>;
};

export type GenerateCandidateSummary = {
  __typename?: "GenerateCandidateSummary";
  summary: CandidateSummary;
};

export type GenerateGreenhouseWebhookSecret = {
  __typename?: "GenerateGreenhouseWebhookSecret";
  webhookSecretKey: Scalars["String"];
};

export type GenerateToken = {
  __typename?: "GenerateToken";
  setup: SingleSignOnSetup;
};

export type GeneratedSkill = {
  __typename?: "GeneratedSkill";
  description: Scalars["String"];
  name: Scalars["String"];
};

export type GenericSelectOption = {
  __typename?: "GenericSelectOption";
  label: Scalars["String"];
  value: Scalars["String"];
};

export type GoogleMeetMeeting = {
  __typename?: "GoogleMeetMeeting";
  autoRecordingSetting?: Maybe<Scalars["String"]>;
  calendarEventId?: Maybe<Scalars["UUID"]>;
  call?: Maybe<Call>;
  callId?: Maybe<Scalars["UUID"]>;
  canBeImported?: Maybe<Scalars["Boolean"]>;
  canNotBeImportedReason?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  emailEventId?: Maybe<Scalars["UUID"]>;
  endedReason?: Maybe<BotMeetingEndedReason>;
  errorType?: Maybe<BotMeetingErrorType>;
  exclude: Scalars["Boolean"];
  excludeReason?: Maybe<ExcludeReason>;
  id: Scalars["String"];
  importMeeting: Scalars["Boolean"];
  importReason: InterviewImportDecisionReason;
  joinTime?: Maybe<Scalars["DateTime"]>;
  leftTime?: Maybe<Scalars["DateTime"]>;
  meetingPassword?: Maybe<Scalars["String"]>;
  meetingUrl?: Maybe<Scalars["String"]>;
  nextVirtualOnsiteMeetingId?: Maybe<Scalars["UUID"]>;
  organizationId: Scalars["UUID"];
  participants: Array<GoogleMeetMeetingParticipant>;
  platform: BotMeetingPlatform;
  platformMeetingId?: Maybe<Scalars["String"]>;
  platformMeetingInstanceId?: Maybe<Scalars["String"]>;
  previousRunningMeetingId?: Maybe<Scalars["UUID"]>;
  recallRecordingId?: Maybe<Scalars["String"]>;
  recordingEndTime?: Maybe<Scalars["DateTime"]>;
  recordingStartTime?: Maybe<Scalars["DateTime"]>;
  recordingStarted: Scalars["Boolean"];
  scheduledEndTime: Scalars["DateTime"];
  scheduledStartTime: Scalars["DateTime"];
  status: BotMeetingStatus;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  virtualOnsite?: Maybe<VirtualOnsite>;
  virtualOnsiteId?: Maybe<Scalars["UUID"]>;
  waitingRoomJoinTime?: Maybe<Scalars["DateTime"]>;
};

export type GoogleMeetMeetingParticipant = {
  __typename?: "GoogleMeetMeetingParticipant";
  botMeetingId: Scalars["UUID"];
  callSpeakerOptionId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  identificationMethod?: Maybe<MeetingParticipantIdentificationMethod>;
  identificationSource?: Maybe<MeetingParticipantIdentificationSource>;
  identifiedAs?: Maybe<Scalars["String"]>;
  identifiedDuringInterview?: Maybe<Scalars["Boolean"]>;
  inMeeting: Scalars["Boolean"];
  isCandidate: Scalars["Boolean"];
  name: Scalars["String"];
  profilePicUrl?: Maybe<Scalars["String"]>;
  recallParticipantId?: Maybe<Scalars["Float"]>;
  scheduledInterviewerId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["DateTime"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["UUID"]>;
  zoomAppChatMessageSent: Scalars["Boolean"];
  zoomConfUserId?: Maybe<Scalars["String"]>;
  zoomUserId?: Maybe<Scalars["Float"]>;
};

export type GreenhouseCallParams = {
  __typename?: "GreenhouseCallParams";
  params?: Maybe<CallParams>;
};

export type GreenhouseCandidateParams = {
  __typename?: "GreenhouseCandidateParams";
  params?: Maybe<CallParams>;
};

export type GreenhouseFreemiumSync = {
  __typename?: "GreenhouseFreemiumSync";
  processed: Scalars["Boolean"];
};

export type GreenhousePermissionStatus = {
  __typename?: "GreenhousePermissionStatus";
  isGranted: Scalars["Boolean"];
  permission: Scalars["String"];
};

export type GreenhousePhoneNumberParams = {
  __typename?: "GreenhousePhoneNumberParams";
  params?: Maybe<CallParams>;
};

export type GreenhouseScorecard = {
  __typename?: "GreenhouseScorecard";
  createdAt: Scalars["DateTime"];
  greenhouseId: Scalars["Float"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  redactedAt?: Maybe<Scalars["DateTime"]>;
  scorecard: Scalars["JSONString"];
  updatedAt: Scalars["DateTime"];
};

export type GreenhouseScorecardV3 = {
  __typename?: "GreenhouseScorecardV3";
  failureReason?: Maybe<GreenhouseScorecardV3FailureReason>;
  id: Scalars["UUID"];
  processingStatus: GreenhouseScorecardV3ProcessingStatus;
};

export enum GreenhouseScorecardV3FailureReason {
  MissingAiNotes = "MISSING_AI_NOTES",
  MissingApiPermissions = "MISSING_API_PERMISSIONS",
  MissingScorecardQuestions = "MISSING_SCORECARD_QUESTIONS",
  ScorecardAlreadySubmitted = "SCORECARD_ALREADY_SUBMITTED",
  Unknown = "UNKNOWN",
  UserEditingScorecard = "USER_EDITING_SCORECARD",
}

export enum GreenhouseScorecardV3ProcessingStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
}

export type GreenhouseSetup = {
  __typename?: "GreenhouseSetup";
  adminUserId: Scalars["BigInt"];
  apiKey: Scalars["String"];
  atsOptInEnabled: Scalars["Boolean"];
  enabled: Scalars["Boolean"];
  greenhouseConsentKey?: Maybe<Scalars["String"]>;
  host: Scalars["String"];
  id: Scalars["ID"];
  invalidReason?: Maybe<Scalars["String"]>;
  permissionsStatus: Array<GreenhousePermissionStatus>;
  syncCallGuidesFromAts: Scalars["Boolean"];
  valid: Scalars["Boolean"];
  webhookSecretKey?: Maybe<Scalars["String"]>;
};

export type GreenhouseSync = {
  __typename?: "GreenhouseSync";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  lastSyncAt: Scalars["DateTime"];
  organizationId: Scalars["UUID"];
  status?: Maybe<GreenhouseSyncStatus>;
  type: GreenhouseSyncType;
  updatedAt: Scalars["DateTime"];
};

export enum GreenhouseSyncStatus {
  Failed = "FAILED",
  Finished = "FINISHED",
  Running = "RUNNING",
}

export enum GreenhouseSyncType {
  Application = "APPLICATION",
  CallGuide = "CALL_GUIDE",
  Candidate = "CANDIDATE",
  FreemiumSignUp = "FREEMIUM_SIGN_UP",
  JobPost = "JOB_POST",
  JobStage = "JOB_STAGE",
  Position = "POSITION",
  ScheduledInterview = "SCHEDULED_INTERVIEW",
  Scorecard = "SCORECARD",
  User = "USER",
}

export type GreenhouseUser = {
  __typename?: "GreenhouseUser";
  disabled: Scalars["Boolean"];
  email: Scalars["String"];
  greenhouseId: Scalars["BigInt"];
  id: Scalars["ID"];
  siteAdmin: Scalars["Boolean"];
};

export enum GuideItemType {
  Competency = "COMPETENCY",
  Question = "QUESTION",
  Text = "TEXT",
}

export type IcimsCandidateParams = {
  __typename?: "IcimsCandidateParams";
  params?: Maybe<CallParams>;
};

export type IcimsSetup = {
  __typename?: "IcimsSetup";
  clientId?: Maybe<Scalars["String"]>;
  clientSecret?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  tokenUrl?: Maybe<Scalars["String"]>;
  valid: Scalars["Boolean"];
};

export type ImportGoogleMeetMeeting = {
  __typename?: "ImportGoogleMeetMeeting";
  meeting?: Maybe<GoogleMeetMeeting>;
};

export type ImportTeamsNotetakerMeeting = {
  __typename?: "ImportTeamsNotetakerMeeting";
  meeting?: Maybe<TeamsNotetakerMeeting>;
};

export type ImportZoomMeeting = {
  __typename?: "ImportZoomMeeting";
  meeting?: Maybe<ZoomMeeting>;
  notetakerMeeting?: Maybe<ZoomNotetakerMeeting>;
};

export type InforCandidateParams = {
  __typename?: "InforCandidateParams";
  params?: Maybe<CallParams>;
};

export type InstallMergeIntegration = {
  __typename?: "InstallMergeIntegration";
  setup: MergeSetup;
};

export enum InstallZoomNotetakerAppJobProcessingStatus {
  Completed = "COMPLETED",
  Dispatched = "DISPATCHED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export enum InternalUserRoleName {
  BhAdminBasic = "BH_ADMIN_BASIC",
  BhAdminSuper = "BH_ADMIN_SUPER",
  BhAdminUser = "BH_ADMIN_USER",
  BhAdminUserOutsideUs = "BH_ADMIN_USER_OUTSIDE_US",
}

export type InternationalPhoneVoipNumbers = {
  __typename?: "InternationalPhoneVoipNumbers";
  countryCode: Scalars["String"];
  orgPhoneNumber: Scalars["String"];
};

export enum InterviewAssistantEntryPoint {
  Banner = "BANNER",
  CandidatePage = "CANDIDATE_PAGE",
  ChromeExtension = "CHROME_EXTENSION",
  HomePage = "HOME_PAGE",
  LiveOnBrighthire = "LIVE_ON_BRIGHTHIRE",
  ScheduledInterviewEmail = "SCHEDULED_INTERVIEW_EMAIL",
  ScheduledInterviewSlack = "SCHEDULED_INTERVIEW_SLACK",
  ZoomApp = "ZOOM_APP",
}

export enum InterviewImportDecisionReason {
  Acuity = "ACUITY",
  AdminForceImport = "ADMIN_FORCE_IMPORT",
  AdminForcePrevent = "ADMIN_FORCE_PREVENT",
  ApiOverride = "API_OVERRIDE",
  ApiOverrideTurnedOff = "API_OVERRIDE_TURNED_OFF",
  Calendar = "CALENDAR",
  CalendarKeyword = "CALENDAR_KEYWORD",
  CalendarKeywordTurnedOff = "CALENDAR_KEYWORD_TURNED_OFF",
  Email = "EMAIL",
  Exclude = "EXCLUDE",
  ExcludeStage = "EXCLUDE_STAGE",
  HomePage = "HOME_PAGE",
  InterviewAssistant = "INTERVIEW_ASSISTANT",
  InPersonInterview = "IN_PERSON_INTERVIEW",
  Manual = "MANUAL",
  NoActiveBhUser = "NO_ACTIVE_BH_USER",
  NoBhUser = "NO_BH_USER",
  NoDecision = "NO_DECISION",
  NoWhitelistUser = "NO_WHITELIST_USER",
  OptOutCandidate = "OPT_OUT_CANDIDATE",
  OptOutInterviewer = "OPT_OUT_INTERVIEWER",
  Position = "POSITION",
  PositionTurnedOff = "POSITION_TURNED_OFF",
  SomeoneOptedOut = "SOMEONE_OPTED_OUT",
  Undo = "UNDO",
  Unknown = "UNKNOWN",
  UserAllMyInterviews = "USER_ALL_MY_INTERVIEWS",
  UserLevelImportRuleTurnedOff = "USER_LEVEL_IMPORT_RULE_TURNED_OFF",
  UserOnlyInterviewerMyInterviews = "USER_ONLY_INTERVIEWER_MY_INTERVIEWS",
  VirtualOnsiteSplit = "VIRTUAL_ONSITE_SPLIT",
}

export type InterviewStage = {
  __typename?: "InterviewStage";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type InterviewTotals = {
  __typename?: "InterviewTotals";
  totalCandidates?: Maybe<Scalars["Int"]>;
  totalDuration?: Maybe<Scalars["Int"]>;
  totalInterviewers?: Maybe<Scalars["Int"]>;
  totalInterviews?: Maybe<Scalars["Int"]>;
};

export type InterviewTotalsResult = {
  __typename?: "InterviewTotalsResult";
  id: Scalars["ID"];
  values: InterviewTotals;
};

export enum InterviewType {
  GoogleMeet = "GOOGLE_MEET",
  InPerson = "IN_PERSON",
  Phone = "PHONE",
  Teams = "TEAMS",
  Zoom = "ZOOM",
}

export type InviteUser = {
  email: Scalars["String"];
  planUserRoleId?: InputMaybe<Scalars["ID"]>;
  userRoleId: Scalars["ID"];
};

export type InviteUsers = {
  __typename?: "InviteUsers";
  users: Array<Maybe<User>>;
};

export type JobDescriptionTemplate = {
  __typename?: "JobDescriptionTemplate";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  sections: Array<JobDescriptionTemplateSection>;
  updatedAt: Scalars["DateTime"];
};

export type JobDescriptionTemplateSection = {
  __typename?: "JobDescriptionTemplateSection";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  jobDescriptionTemplateId: Scalars["UUID"];
  order: Scalars["Float"];
  organizationId: Scalars["UUID"];
  sectionHeader: Scalars["String"];
  sectionText: Scalars["String"];
  type: JobDescriptionTemplateSectionType;
  updatedAt: Scalars["DateTime"];
};

export enum JobDescriptionTemplateSectionType {
  AboutRole = "ABOUT_ROLE",
  Custom = "CUSTOM",
  Requirements = "REQUIREMENTS",
  Responsibilities = "RESPONSIBILITIES",
}

/** Join an in progress meeting that's part of a Virtual Onsite */
export type JoinZoomNotetakerMeeting = {
  __typename?: "JoinZoomNotetakerMeeting";
  meeting: ZoomNotetakerMeeting;
};

export type KeepCall = {
  __typename?: "KeepCall";
  call: Call;
  success: Scalars["Boolean"];
};

export type KeepCandidate = {
  __typename?: "KeepCandidate";
  candidate: Candidate;
  success: Scalars["Boolean"];
};

export type LaunchTrainingProgram = {
  __typename?: "LaunchTrainingProgram";
  trainingProgram: TrainingProgram;
};

export type LeverAuthOnRedirect = {
  __typename?: "LeverAuthOnRedirect";
  leverOauthSetup: LeverOauthSetup;
};

export type LeverCallParams = {
  __typename?: "LeverCallParams";
  callParams?: Maybe<CallParams>;
};

export type LeverCandidateParams = {
  __typename?: "LeverCandidateParams";
  callParams?: Maybe<CallParams>;
};

export enum LeverFeedbackTemplateFieldTypes {
  Code = "code",
  Date = "date",
  Dropdown = "dropdown",
  MultipleChoice = "multiple_choice",
  MultipleSelect = "multiple_select",
  Score = "score",
  ScoreSystem = "score_system",
  Scorecard = "scorecard",
  Text = "text",
  Textarea = "textarea",
  YesNo = "yes_no",
}

export type LeverOauthSetup = {
  __typename?: "LeverOauthSetup";
  createSecretNotes: Scalars["Boolean"];
  id: Scalars["ID"];
  syncCallGuidesFromAts: Scalars["Boolean"];
  useSandbox: Scalars["Boolean"];
  valid: Scalars["Boolean"];
};

export type LeverSetup = {
  __typename?: "LeverSetup";
  apiKey: Scalars["String"];
  createSecretNotes: Scalars["Boolean"];
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  signatureToken?: Maybe<Scalars["String"]>;
  signatureTokenObj?: Maybe<Scalars["String"]>;
  syncCallGuidesFromAts: Scalars["Boolean"];
  useSandbox: Scalars["Boolean"];
  valid: Scalars["Boolean"];
};

export type LeverStage = {
  __typename?: "LeverStage";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  leverId?: Maybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  text?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type MakeOutgoingCall = {
  __typename?: "MakeOutgoingCall";
  call: Call;
};

export type MarkCandidateAlertFeedRead = {
  __typename?: "MarkCandidateAlertFeedRead";
  currentUser: User;
  markedIds: Array<Scalars["ID"]>;
  readAt: Scalars["DateTime"];
};

export type MarkScorecardQuestion = {
  __typename?: "MarkScorecardQuestion";
  scorecardItem: ScorecardItem;
};

export type MarkTrainingProgramTraineeComplete = {
  __typename?: "MarkTrainingProgramTraineeComplete";
  trainingProgramTrainee: TrainingProgramTrainee;
};

export enum MeetingParticipantIdentificationMethod {
  Elimination = "ELIMINATION",
  Email = "EMAIL",
  ExactMatch = "EXACT_MATCH",
  FuzzyMatch = "FUZZY_MATCH",
  PartialMatch = "PARTIAL_MATCH",
  PhoneNumber = "PHONE_NUMBER",
  ProfilePicUrl = "PROFILE_PIC_URL",
  ZoomDisplayName = "ZOOM_DISPLAY_NAME",
  ZoomName = "ZOOM_NAME",
  ZoomUserId = "ZOOM_USER_ID",
}

export enum MeetingParticipantIdentificationSource {
  ApprovedInterviewer = "APPROVED_INTERVIEWER",
  Candidate = "CANDIDATE",
  IaOpen = "IA_OPEN",
  OrgUser = "ORG_USER",
  RelatedMeetingScheduledInterviewer = "RELATED_MEETING_SCHEDULED_INTERVIEWER",
  ScheduledInterviewer = "SCHEDULED_INTERVIEWER",
}

export type Membership = {
  __typename?: "Membership";
  atsControlled: Scalars["Boolean"];
  clientMembershipControlled: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  grantedById?: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  position: Position;
  positionId: Scalars["UUID"];
  role: PositionRole;
  roleId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  user: User;
  userId: Scalars["UUID"];
};

export type MergeSetup = {
  __typename?: "MergeSetup";
  category?: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  id: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  valid: Scalars["Boolean"];
};

export enum MetricName {
  AgendaSet = "AGENDA_SET",
  AlignmentRate = "ALIGNMENT_RATE",
  AtsTotalInterviews = "ATS_TOTAL_INTERVIEWS",
  CandidateQuestionOpportunity = "CANDIDATE_QUESTION_OPPORTUNITY",
  CandidateTalkRatio = "CANDIDATE_TALK_RATIO",
  CareLikeFamilyCoverage = "CARE_LIKE_FAMILY_COVERAGE",
  CompensationCoverage = "COMPENSATION_COVERAGE",
  Interactivity = "INTERACTIVITY",
  LongestMonologue = "LONGEST_MONOLOGUE",
  OnTimeInterviews = "ON_TIME_INTERVIEWS",
  PassRate = "PASS_RATE",
  ProblematicQuestions = "PROBLEMATIC_QUESTIONS",
  QuestionsAsked = "QUESTIONS_ASKED",
  QuestionTrends = "QUESTION_TRENDS",
  Rating = "RATING",
  Report = "REPORT",
  ScorecardCompletionRate = "SCORECARD_COMPLETION_RATE",
  ScorecardCompletionTime = "SCORECARD_COMPLETION_TIME",
  Sentiment = "SENTIMENT",
  SpeakingRate = "SPEAKING_RATE",
  StrongRatingPercent = "STRONG_RATING_PERCENT",
  TopicTrends = "TOPIC_TRENDS",
  TotalInterviews = "TOTAL_INTERVIEWS",
  TotalInterviewTime = "TOTAL_INTERVIEW_TIME",
  WeeklyInterviews = "WEEKLY_INTERVIEWS",
  WeeklyInterviewTime = "WEEKLY_INTERVIEW_TIME",
  WorkLocationCoverage = "WORK_LOCATION_COVERAGE",
}

export type Mutation = {
  __typename?: "Mutation";
  addAlertView?: Maybe<AddAlertView>;
  addBrightHireToInterviews?: Maybe<AddBrightHireToInterviews>;
  addBullhornCredentials?: Maybe<AddBullhornCredentials>;
  addCall?: Maybe<AddCall>;
  addCallGuide?: Maybe<AddCallGuide>;
  addCallGuideCue?: Maybe<AddCallGuideCue>;
  addCallNote?: Maybe<AddCallNote>;
  addCallNoteHighlightFeedback?: Maybe<AddCallNoteHighlightFeedback>;
  addCallNoteReply?: Maybe<AddCallNoteReply>;
  addCandidateAlertUserSubscriptions?: Maybe<AddCandidateAlertUserSubscriptions>;
  addClient?: Maybe<AddClient>;
  addClipsToTrainingProgram?: Maybe<AddClipsToTrainingProgram>;
  addCompetency?: Maybe<AddCompetency>;
  /**
   * Creates and starts an in-person call.
   *
   * Pass `scheduled_interview_id` when a scheduled interview exists,
   * and it will be converted to an in-person scheduled interview.
   *
   * Otherwise, pass data from the NewInPersonCallForm to fill out the details of
   * the call.
   */
  addInPersonCall?: Maybe<AddInPersonCall>;
  addInterviewAssistantOpens: AddInterviewAssistantOpens;
  addInterviewStage?: Maybe<AddInterviewStage>;
  addNewCallNote?: Maybe<AddNewCallNote>;
  addPosition?: Maybe<AddPosition>;
  addTrainingProgramQuestion?: Maybe<AddTrainingProgramQuestion>;
  addTrainingProgramTrainees?: Maybe<AddTrainingProgramTrainees>;
  answerTrainingProgramQuestions?: Maybe<AnswerTrainingProgramQuestions>;
  ashbyCandidateParams?: Maybe<AshbyCandidateParams>;
  authorizeZoom?: Maybe<AuthorizeZoom>;
  authorizeZoomBot?: Maybe<AuthorizeZoomBot>;
  autoGenerateCandidateSummaryIfMissing?: Maybe<AutoGenerateCandidateSummaryIfMissing>;
  bullhornCallParams?: Maybe<BullhornCallParams>;
  cancelRecording: CancelRecording;
  clipCall?: Maybe<ClipCall>;
  createAiNoteTags?: Maybe<CreateAiNoteTags>;
  createAiNotes?: Maybe<CreateAiNotes>;
  createAiSummaryTemplate?: Maybe<CreateAiSummaryTemplate>;
  createAiTopics?: Maybe<CreateAiTopics>;
  createAndSharePlaylist?: Maybe<CreateAndSharePlaylist>;
  createAndSharePlaylistExternally?: Maybe<CreateAndSharePlaylistExternally>;
  createCustomTopic?: Maybe<CreateCustomTopic>;
  createEventImportKeyword?: Maybe<CreateEventImportKeyword>;
  createExcludeStage?: Maybe<CreateExcludeStage>;
  createLeverOauthRequestUrl?: Maybe<CreateLeverOauthRequestUrl>;
  createMergeLinkToken?: Maybe<CreateMergeLinkToken>;
  createOrUpdateExcludedUser?: Maybe<CreateOrUpdateExcludedUser>;
  createOrUpdateTaskDefinition?: Maybe<CreateOrUpdateTaskDefinition>;
  createPlaylist?: Maybe<CreatePlaylist>;
  createScheduledInterview?: Maybe<CreateScheduledInterview>;
  createScheduledInterviewZoomNotetakerMeeting?: Maybe<CreateScheduledInterviewZoomNotetakerMeeting>;
  createSkillReport?: Maybe<CreateSkillReport>;
  createSmartrecruitersOauthRequestUrl?: Maybe<CreateSmartrecruitersOauthRequestUrl>;
  createTrainingProgram?: Maybe<CreateTrainingProgram>;
  createTrainingProgramItem?: Maybe<CreateTrainingProgramItem>;
  deauthorizeLever?: Maybe<DeauthorizeLever>;
  deauthorizeMerge?: Maybe<DeauthorizeMerge>;
  deauthorizeSmartrecruiters?: Maybe<DeauthorizeSmartrecruiters>;
  deleteAiSummaryCustomTopic?: Maybe<DeleteAiSummaryCustomTopic>;
  deleteAiSummaryTemplate?: Maybe<DeleteAiSummaryTemplate>;
  deleteAllTrainingProgramTrainees?: Maybe<DeleteAllTrainingProgramTrainees>;
  deleteApiToken?: Maybe<DeleteApiToken>;
  deleteCalendarOrgSettings?: Maybe<DeleteCalendarOrgSettings>;
  deleteCallGuide?: Maybe<DeleteCallGuide>;
  deleteCallGuideCue?: Maybe<DeleteCallGuideCue>;
  deleteCallNote?: Maybe<DeleteCallNote>;
  deleteClip?: Maybe<DeleteClip>;
  deleteCustomTopic?: Maybe<DeleteCustomTopic>;
  deleteEventImportKeyword?: Maybe<DeleteEventImportKeyword>;
  deleteExcludeStage?: Maybe<DeleteExcludeStage>;
  deletePlaylist?: Maybe<DeletePlaylist>;
  deleteScheduledInterview?: Maybe<DeleteScheduledInterview>;
  deleteSkillReport?: Maybe<DeleteSkillReport>;
  deleteTrainingProgram?: Maybe<DeleteTrainingProgram>;
  deleteTrainingProgramItem?: Maybe<DeleteTrainingProgramItem>;
  deleteTrainingProgramQuestion?: Maybe<DeleteTrainingProgramQuestion>;
  deleteTrainingProgramTrainee?: Maybe<DeleteTrainingProgramTrainee>;
  deleteUsers?: Maybe<DeleteUsers>;
  deleteWebhook?: Maybe<DeleteWebhook>;
  describeTrainingProgramItem?: Maybe<DescribeTrainingProgramItem>;
  duplicateAiSummaryTemplate?: Maybe<DuplicateAiSummaryTemplate>;
  duplicateCallGuide?: Maybe<DuplicateCallGuide>;
  duplicateCallGuidesToPosition?: Maybe<DuplicateCallGuidesToPosition>;
  duplicateSkillReport?: Maybe<DuplicateSkillReport>;
  editCustomTopic?: Maybe<EditCustomTopic>;
  editSkillReport?: Maybe<EditSkillReport>;
  enableTrainingProgramAssessment?: Maybe<EnableTrainingProgramAssessment>;
  endCall?: Maybe<EndCall>;
  endInPersonCall?: Maybe<EndInPersonCall>;
  externalUserRequestAccessCode?: Maybe<ExternalUserRequestAccessCode>;
  externalUserRequestShareRenewal?: Maybe<ExternalUserRequestShareRenewal>;
  externalUserViewCall?: Maybe<ExternalUserViewCall>;
  externalUserViewClip?: Maybe<ExternalUserViewClip>;
  generateCandidateSummary?: Maybe<GenerateCandidateSummary>;
  generateGreenhouseWebhookSecret?: Maybe<GenerateGreenhouseWebhookSecret>;
  generateToken?: Maybe<GenerateToken>;
  greenhouseCallParams?: Maybe<GreenhouseCallParams>;
  greenhouseCandidateParams?: Maybe<GreenhouseCandidateParams>;
  greenhouseFreemiumSync?: Maybe<GreenhouseFreemiumSync>;
  greenhousePhoneNumberParams?: Maybe<GreenhousePhoneNumberParams>;
  icimsCandidateParams?: Maybe<IcimsCandidateParams>;
  importGoogleMeetMeeting?: Maybe<ImportGoogleMeetMeeting>;
  importTeamsNotetakerMeeting?: Maybe<ImportTeamsNotetakerMeeting>;
  importZoomMeeting?: Maybe<ImportZoomMeeting>;
  inforCandidateParams?: Maybe<InforCandidateParams>;
  installMergeIntegration?: Maybe<InstallMergeIntegration>;
  inviteUsers?: Maybe<InviteUsers>;
  /** Join an in progress meeting that's part of a Virtual Onsite */
  joinZoomNotetakerMeeting?: Maybe<JoinZoomNotetakerMeeting>;
  keepCall?: Maybe<KeepCall>;
  keepCandidate?: Maybe<KeepCandidate>;
  launchTrainingProgram?: Maybe<LaunchTrainingProgram>;
  leverAuthOnRedirect?: Maybe<LeverAuthOnRedirect>;
  leverCallParams?: Maybe<LeverCallParams>;
  leverCandidateParams?: Maybe<LeverCandidateParams>;
  makeOutgoingCall?: Maybe<MakeOutgoingCall>;
  markCandidateAlertFeedRead?: Maybe<MarkCandidateAlertFeedRead>;
  markScorecardQuestion?: Maybe<MarkScorecardQuestion>;
  markTrainingProgramTraineeComplete?: Maybe<MarkTrainingProgramTraineeComplete>;
  muteCandidateAlertUserSubscriptions?: Maybe<MuteCandidateAlertUserSubscriptions>;
  nameTrainingProgram?: Maybe<NameTrainingProgram>;
  outlookWebAppCallParams?: Maybe<OutlookWebAppCallParams>;
  overrideCallGuideCue?: Maybe<OverrideCallGuideCue>;
  pauseCallRecording?: Maybe<PauseCallRecording>;
  previewTaskRun?: Maybe<PreviewTaskRun>;
  processInPersonRecording?: Maybe<ProcessInPersonRecording>;
  reTranscribeCall?: Maybe<ReTranscribeCall>;
  reactivateUser?: Maybe<ReactivateUser>;
  redactCall?: Maybe<RedactCall>;
  redactCandidate?: Maybe<RedactCandidate>;
  redactScheduledCalls?: Maybe<RedactScheduledCalls>;
  redactScheduledCandidates?: Maybe<RedactScheduledCandidates>;
  redial?: Maybe<Redial>;
  removeBullhornCredentials?: Maybe<RemoveBullhornCredentials>;
  removeCallGuideAssignedUser?: Maybe<RemoveCallGuideAssignedUser>;
  removeClipFromPlaylist?: Maybe<RemoveClipFromPlaylist>;
  removeClipShare?: Maybe<RemoveClipShare>;
  removeExternalCallShare?: Maybe<RemoveExternalCallShare>;
  removeExternalClipShare?: Maybe<RemoveExternalClipShare>;
  removeExternalPlaylistShare?: Maybe<RemoveExternalPlaylistShare>;
  removePlaylistShare?: Maybe<RemovePlaylistShare>;
  removeShare?: Maybe<RemoveShare>;
  renamePlaylist?: Maybe<RenamePlaylist>;
  renewExternalCallShare?: Maybe<RenewExternalCallShare>;
  renewExternalClipShare?: Maybe<RenewExternalClipShare>;
  renewExternalPlaylistShare?: Maybe<RenewExternalPlaylistShare>;
  reorderPlaylistClips?: Maybe<ReorderPlaylistClips>;
  reorderTrainingProgramItems?: Maybe<ReorderTrainingProgramItems>;
  reorderTrainingProgramQuestions?: Maybe<ReorderTrainingProgramQuestions>;
  /** User is requesting to enable data redaction for their organization */
  requestOrganizationDataRedactionAccess?: Maybe<RequestOrganizationDataRedactionAccess>;
  /** User is requesting to upgrade their organization with a specific feature */
  requestUpgrade?: Maybe<RequestUpgrade>;
  resetAnalyticsQualityReportConfig?: Maybe<ResetAnalyticsQualityReportConfig>;
  resetTrainingProgramQuestions?: Maybe<ResetTrainingProgramQuestions>;
  revokeUserCalendars?: Maybe<RevokeUserCalendars>;
  runSkillReport?: Maybe<RunSkillReport>;
  scheduledInterviewerOptOut: ScheduledInterviewerOptOut;
  sendBeforeCallTextMessage: SendBeforeCallTextMessage;
  sendCallBackTextMessage: SendCallBackTextMessage;
  sendCandidateEmailVerification: SendCandidateEmailVerification;
  /**
   * Resends an existing invitation by the current user if one exists, otherwise creates
   * and sends a new one.
   */
  sendInvite?: Maybe<SendInvite>;
  setClientMemberships?: Maybe<SetClientMemberships>;
  setMemberships?: Maybe<SetMemberships>;
  setPlanUserRoles?: Maybe<SetPlanUserRoles>;
  setUserRoles?: Maybe<SetUserRoles>;
  setupGreenhouse?: Maybe<SetupGreenhouse>;
  setupGreenhouseWizard?: Maybe<SetupGreenhouseWizard>;
  setupIcims?: Maybe<SetupIcims>;
  setupLever?: Maybe<SetupLever>;
  setupSense?: Maybe<SetupSense>;
  setupSingleSignOn?: Maybe<SetupSingleSignOn>;
  shareAnalyticsReport?: Maybe<ShareAnalyticsReport>;
  shareCall?: Maybe<ShareCall>;
  shareCallExternally?: Maybe<ShareCallExternally>;
  shareClip?: Maybe<ShareClip>;
  shareClipExternally?: Maybe<ShareClipExternally>;
  sharePlaylist?: Maybe<SharePlaylist>;
  sharePlaylistExternally?: Maybe<SharePlaylistExternally>;
  signInByEmail?: Maybe<SignInByEmail>;
  signInByPhone?: Maybe<SignInByPhone>;
  signOut?: Maybe<SignOut>;
  smartrecruitersAuthOnRedirect?: Maybe<SmartrecruitersAuthOnRedirect>;
  /**
   * Start a meeting that's part of a Virtual Onsite.
   * If the onsite is in progress, split the current meeting.
   */
  startVirtualOnsiteBotMeeting?: Maybe<StartVirtualOnsiteBotMeeting>;
  talemetryCandidateParams?: Maybe<TalemetryCandidateParams>;
  unMarkScorecardQuestion?: Maybe<UnmarkScorecardQuestion>;
  unimpersonate?: Maybe<Unimpersonate>;
  unkeepCall?: Maybe<UnkeepCall>;
  unkeepCandidate?: Maybe<UnkeepCandidate>;
  updateAiAnswer?: Maybe<UpdateAiAnswer>;
  updateAiQuestion?: Maybe<UpdateAiQuestion>;
  updateAiSummaryTemplate?: Maybe<UpdateAiSummaryTemplate>;
  updateAnalyticsQualityReportConfig?: Maybe<UpdateAnalyticsQualityReportConfig>;
  updateApiToken?: Maybe<UpdateApiToken>;
  updateAutoJoinAllNewPositions?: Maybe<UpdateAutoJoinAllNewPositions>;
  updateAutoJoinExcludedUsers?: Maybe<UpdateAutoJoinExcludedUsers>;
  updateCalendarOrgSettings?: Maybe<UpdateCalendarOrgSettings>;
  updateCalendarSettings?: Maybe<UpdateCalendarSettings>;
  updateCall?: Maybe<UpdateCall>;
  updateCallAiSummaryFormat?: Maybe<UpdateCallAiSummaryFormat>;
  updateCallCandidate?: Maybe<UpdateCallCandidate>;
  updateCallGuide?: Maybe<UpdateCallGuide>;
  updateCallGuideAssignedUsers?: Maybe<UpdateCallGuideAssignedUsers>;
  updateCallGuideCue?: Maybe<UpdateCallGuideCue>;
  updateCallGuides?: Maybe<UpdateCallGuides>;
  updateCallNote?: Maybe<UpdateCallNote>;
  updateCallPosition?: Maybe<UpdateCallPosition>;
  updateCallSpeaker?: Maybe<UpdateCallSpeaker>;
  updateCandidate?: Maybe<UpdateCandidate>;
  updateCandidateAlertAccuracyFeedback?: Maybe<UpdateCandidateAlertAccuracyFeedback>;
  updateCandidateEmailOptOut: UpdateCandidateEmailOptOut;
  updateCandidateOptOut: UpdateCandidateOptOut;
  updateCandidateSummaryTemplate?: Maybe<UpdateCandidateSummaryTemplate>;
  updateClient?: Maybe<UpdateClient>;
  updateClip?: Maybe<UpdateClip>;
  updateCurrentUser?: Maybe<UpdateCurrentUser>;
  updateCurrentUserOnboardingStep?: Maybe<UpdateCurrentUserOnboardingStep>;
  updateCurrentUserShowActionItems?: Maybe<UpdateCurrentUserShowActionItems>;
  updateCurrentUserSubscriptionsSettings?: Maybe<UpdateCurrentUserSubscriptionSettings>;
  updateCurrentUserViewedTranscriptionWarning?: Maybe<UpdateCurrentUserViewedTranscriptionWarning>;
  updateInterviewOptIn: UpdateInterviewOptIn;
  updateLeverOauthIntegrationSettings?: Maybe<UpdateLeverOauthIntegrationSettings>;
  updateMyInterviewRecordingOptions?: Maybe<UpdateMyInterviewRecordingOptions>;
  updateOptInSetup: UpdateOptInSetup;
  updateOrganization?: Maybe<UpdateOrganization>;
  updateOrganizationAdvancedConfigurations?: Maybe<UpdateOrganizationAdvancedConfigurations>;
  updateOrganizationAiSettings?: Maybe<UpdateOrganizationAiSettings>;
  updateOrganizationAtsSettings?: Maybe<UpdateOrganizationAtsSettings>;
  updateOrganizationBeta?: Maybe<UpdateOrganizationBeta>;
  /** Settings around automatic data redaction of EEOC-protected information */
  updateOrganizationComplianceSettings?: Maybe<UpdateOrganizationComplianceSettings>;
  updateOrganizationDataRetention?: Maybe<UpdateOrganizationDataRetention>;
  updateOrganizationGenderLabels?: Maybe<UpdateOrganizationGenderLabels>;
  updateOrganizationGoogleMeet?: Maybe<UpdateOrganizationGoogleMeet>;
  updateOrganizationInterviewPlanSettings?: Maybe<UpdateOrganizationInterviewPlanSettings>;
  updateOrganizationName?: Maybe<UpdateOrganizationName>;
  updateOrganizationScoring?: Maybe<UpdateOrganizationScoring>;
  updateOrganizationTeamsNotetaker?: Maybe<UpdateOrganizationTeamsNotetaker>;
  updateOrganizationVideoSettings?: Maybe<UpdateOrganizationVideoSettings>;
  updateOrganizationZoomNotetaker?: Maybe<UpdateOrganizationZoomNotetaker>;
  updateOrganizationZoomSettings?: Maybe<UpdateOrganizationZoomSettings>;
  updatePlanJobDescriptionSettings?: Maybe<UpdatePlanJobDescriptionSettings>;
  updatePosition?: Maybe<UpdatePosition>;
  updatePositionsAutoJoin?: Maybe<UpdatePositionsAutoJoin>;
  updateRolePolicies?: Maybe<UpdateRolePolicies>;
  updateScheduledInterview?: Maybe<UpdateScheduledInterview>;
  updateScorecard?: Maybe<UpdateScorecard>;
  updateScorecardCompetency?: Maybe<UpdateScorecardCompetency>;
  updateSummaryNoteFeedback?: Maybe<UpdateSummaryNoteFeedback>;
  updateTextMessageTemplate: UpdateTextMessageTemplate;
  updateTrainingProgramQuestion?: Maybe<UpdateTrainingProgramQuestion>;
  updateUserRole?: Maybe<UpdateUserRole>;
  updateWebhook?: Maybe<UpdateWebhook>;
  updateZoomNotetakerMeetingId?: Maybe<UpdateZoomNotetakerMeetingId>;
  verifyEmailSignIn?: Maybe<VerifyEmailSignIn>;
  verifyExternalUserAccessCode?: Maybe<VerifyExternalUserAccessCode>;
  /** https://support.twilio.com/hc/en-us/articles/223180048 */
  verifyPhoneNumber?: Maybe<VerifyPhoneNumber>;
  verifyPhoneSignIn?: Maybe<VerifyPhoneSignIn>;
  viewCall?: Maybe<ViewCall>;
  viewCallScorecard?: Maybe<ViewCallScorecard>;
  viewCandidate?: Maybe<ViewCandidate>;
  viewClip?: Maybe<ViewClip>;
  viewHighlight?: Maybe<ViewHighlight>;
  viewRedactionPrediction?: Maybe<ViewRedactionPrediction>;
};

export type MutationAddAlertViewArgs = {
  aggregation: Scalars["String"];
  aggregationIds: Array<InputMaybe<Scalars["String"]>>;
  category: Scalars["String"];
  organizationId: Scalars["ID"];
  segment: Scalars["String"];
  type: Scalars["String"];
  valence: Scalars["String"];
};

export type MutationAddBrightHireToInterviewsArgs = {
  scheduledInterviewIds: Array<Scalars["ID"]>;
  source: Scalars["String"];
};

export type MutationAddBullhornCredentialsArgs = {
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
  password: Scalars["String"];
  username: Scalars["String"];
};

export type MutationAddCallArgs = {
  atsRequisitionId?: InputMaybe<Scalars["String"]>;
  bullhornCommentAction?: InputMaybe<Scalars["String"]>;
  callGuideId?: InputMaybe<Scalars["ID"]>;
  callType: Scalars["String"];
  candidateEmail?: InputMaybe<Scalars["String"]>;
  candidateFirstName?: InputMaybe<Scalars["String"]>;
  candidateId?: InputMaybe<Scalars["ID"]>;
  candidateLastName?: InputMaybe<Scalars["String"]>;
  greenhouseScorecardLink?: InputMaybe<Scalars["String"]>;
  iaVersion?: InputMaybe<Scalars["Float"]>;
  leverInterviewLink?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  positionId?: InputMaybe<Scalars["ID"]>;
  scheduledInterviewId?: InputMaybe<Scalars["ID"]>;
  smartrecruitersInterviewLink?: InputMaybe<Scalars["String"]>;
};

export type MutationAddCallGuideArgs = {
  assignedUserIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  interviewStageId?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  positionId?: InputMaybe<Scalars["ID"]>;
};

export type MutationAddCallGuideCueArgs = {
  callGuideId: Scalars["ID"];
  cue: Scalars["String"];
  description: Scalars["String"];
};

export type MutationAddCallNoteArgs = {
  callId: Scalars["ID"];
  clipId?: InputMaybe<Scalars["ID"]>;
  cueId?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  markQuestion?: InputMaybe<Scalars["Boolean"]>;
  parentCueId?: InputMaybe<Scalars["ID"]>;
  questionId?: InputMaybe<Scalars["ID"]>;
  text: Scalars["String"];
  time: Scalars["Float"];
  type: CallNoteType;
  visibility: CallNoteVisibility;
};

export type MutationAddCallNoteHighlightFeedbackArgs = {
  id: Scalars["ID"];
  response: Scalars["String"];
};

export type MutationAddCallNoteReplyArgs = {
  parentCallNoteId: Scalars["ID"];
  text: Scalars["String"];
  visibility: CallNoteVisibility;
};

export type MutationAddCandidateAlertUserSubscriptionsArgs = {
  candidateIds: Array<Scalars["ID"]>;
  positionIds: Array<Scalars["ID"]>;
};

export type MutationAddClientArgs = {
  name: Scalars["String"];
};

export type MutationAddClipsToTrainingProgramArgs = {
  callId: Scalars["ID"];
  callTimestamp?: InputMaybe<Scalars["Int"]>;
  clipIds: Array<InputMaybe<Scalars["ID"]>>;
  description?: InputMaybe<Scalars["String"]>;
  trainingProgramId: Scalars["ID"];
};

export type MutationAddCompetencyArgs = {
  callGuideId?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
};

export type MutationAddInPersonCallArgs = {
  callGuideId?: InputMaybe<Scalars["ID"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  candidateFirstName?: InputMaybe<Scalars["String"]>;
  candidateId?: InputMaybe<Scalars["ID"]>;
  candidateLastName?: InputMaybe<Scalars["String"]>;
  iaVersion?: InputMaybe<Scalars["Float"]>;
  interviewerIds?: InputMaybe<Array<Scalars["ID"]>>;
  name?: InputMaybe<Scalars["String"]>;
  positionId?: InputMaybe<Scalars["ID"]>;
  recordingContentType: Scalars["String"];
  scheduledInterviewId?: InputMaybe<Scalars["ID"]>;
};

export type MutationAddInterviewAssistantOpensArgs = {
  callId: Scalars["ID"];
  entryPoint?: InputMaybe<InterviewAssistantEntryPoint>;
};

export type MutationAddInterviewStageArgs = {
  title: Scalars["String"];
};

export type MutationAddNewCallNoteArgs = {
  callId: Scalars["ID"];
  clipId?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  guideItemId?: InputMaybe<Scalars["ID"]>;
  markQuestion?: InputMaybe<Scalars["Boolean"]>;
  parentGuideItemId?: InputMaybe<Scalars["ID"]>;
  questionId?: InputMaybe<Scalars["ID"]>;
  scorecardQuestionId?: InputMaybe<Scalars["ID"]>;
  text: Scalars["String"];
  time: Scalars["Float"];
  type: CallNoteType;
  visibility: CallNoteVisibility;
};

export type MutationAddPositionArgs = {
  clientId: Scalars["ID"];
  title: Scalars["String"];
};

export type MutationAddTrainingProgramQuestionArgs = {
  order: Scalars["Int"];
  question: Scalars["String"];
  trainingProgramId: Scalars["ID"];
};

export type MutationAddTrainingProgramTraineesArgs = {
  trainingProgramId: Scalars["ID"];
  userIds: Array<InputMaybe<Scalars["ID"]>>;
};

export type MutationAnswerTrainingProgramQuestionsArgs = {
  answers: Array<InputMaybe<TrainingProgramAnswerInput>>;
};

export type MutationAshbyCandidateParamsArgs = {
  applicationId?: InputMaybe<Scalars["String"]>;
  candidateId: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type MutationAuthorizeZoomArgs = {
  authCode: Scalars["String"];
};

export type MutationAuthorizeZoomBotArgs = {
  authCode: Scalars["String"];
};

export type MutationAutoGenerateCandidateSummaryIfMissingArgs = {
  candidateId: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
  templateId: Scalars["ID"];
};

export type MutationBullhornCallParamsArgs = {
  candidateId: Scalars["String"];
  entityType?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type MutationCancelRecordingArgs = {
  slug: Scalars["String"];
};

export type MutationClipCallArgs = {
  callId: Scalars["ID"];
  clipEnd: Scalars["Float"];
  clipStart: Scalars["Float"];
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateAiNoteTagsArgs = {
  callId: Scalars["ID"];
};

export type MutationCreateAiNotesArgs = {
  callId: Scalars["ID"];
};

export type MutationCreateAiSummaryTemplateArgs = {
  customPrompt?: InputMaybe<Scalars["String"]>;
  customType?: InputMaybe<CustomTemplateCustomType>;
  defaultFor?: InputMaybe<CallAiSummaryTemplateDefaultFor>;
  defaultForKeywords?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  defaultForMeetingType?: InputMaybe<CustomTemplateType>;
  defaultForPersona?: InputMaybe<UserPersona>;
  isCreatorDefault?: InputMaybe<Scalars["Boolean"]>;
  isCurrentUserDefault?: InputMaybe<Scalars["Boolean"]>;
  model?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  sections?: InputMaybe<Array<AiSummaryTemplateSectionInput>>;
  visibility?: InputMaybe<CallAiSummaryTemplateVisibility>;
};

export type MutationCreateAiTopicsArgs = {
  callId: Scalars["ID"];
};

export type MutationCreateAndSharePlaylistArgs = {
  clipIds: Array<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  shareMessage?: InputMaybe<Scalars["String"]>;
  shareToUserIds: Array<Scalars["ID"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateAndSharePlaylistExternallyArgs = {
  clipIds: Array<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  shareMessage?: InputMaybe<Scalars["String"]>;
  shareToEmails: Array<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateCustomTopicArgs = {
  keywords: Array<InputMaybe<Scalars["String"]>>;
  matchRule: CustomTopicMatchRule;
  name: Scalars["String"];
};

export type MutationCreateEventImportKeywordArgs = {
  keywords: Array<Scalars["String"]>;
};

export type MutationCreateExcludeStageArgs = {
  excludeText: Scalars["String"];
};

export type MutationCreateOrUpdateExcludedUserArgs = {
  email: Scalars["String"];
};

export type MutationCreateOrUpdateTaskDefinitionArgs = {
  configurationJson: Scalars["JSONString"];
  id?: InputMaybe<Scalars["ID"]>;
  type: TaskDefinitionType;
};

export type MutationCreatePlaylistArgs = {
  clipIds: Array<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateScheduledInterviewArgs = {
  scheduledInterviewInput: ScheduledInterviewInput;
};

export type MutationCreateScheduledInterviewZoomNotetakerMeetingArgs = {
  scheduledInterviewInput: ScheduledInterviewInput;
};

export type MutationCreateSkillReportArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  interviewStageFilters: Scalars["JSONString"];
  minimumInterviewCountFilter: Scalars["Int"];
  positionIds: Array<Scalars["ID"]>;
  skills: Array<SkillReportSkillInput>;
  source: SkillReportSkillsSource;
  title: Scalars["String"];
};

export type MutationCreateTrainingProgramArgs = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type MutationCreateTrainingProgramItemArgs = {
  callId: Scalars["ID"];
  callTimestamp?: InputMaybe<Scalars["Int"]>;
  clipId?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  trainingProgramId: Scalars["ID"];
};

export type MutationDeauthorizeMergeArgs = {
  accountId: Scalars["String"];
};

export type MutationDeleteAiSummaryCustomTopicArgs = {
  customTopicNoteId: Scalars["ID"];
};

export type MutationDeleteAiSummaryTemplateArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteAllTrainingProgramTraineesArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteApiTokenArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCalendarOrgSettingsArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCallGuideArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCallGuideCueArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCallNoteArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteClipArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCustomTopicArgs = {
  topicId: Scalars["ID"];
};

export type MutationDeleteEventImportKeywordArgs = {
  keywords: Array<Scalars["String"]>;
};

export type MutationDeleteExcludeStageArgs = {
  id: Scalars["ID"];
};

export type MutationDeletePlaylistArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteScheduledInterviewArgs = {
  scheduledInterviewId: Scalars["ID"];
};

export type MutationDeleteSkillReportArgs = {
  reportId: Scalars["ID"];
};

export type MutationDeleteTrainingProgramArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteTrainingProgramItemArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteTrainingProgramQuestionArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteTrainingProgramTraineeArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteUsersArgs = {
  ids: Array<InputMaybe<Scalars["ID"]>>;
};

export type MutationDeleteWebhookArgs = {
  id: Scalars["ID"];
};

export type MutationDescribeTrainingProgramItemArgs = {
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type MutationDuplicateAiSummaryTemplateArgs = {
  id: Scalars["ID"];
};

export type MutationDuplicateCallGuideArgs = {
  id: Scalars["ID"];
};

export type MutationDuplicateCallGuidesToPositionArgs = {
  callGuideIds: Array<InputMaybe<Scalars["String"]>>;
  fromPositionId: Scalars["String"];
  toPositionId: Scalars["String"];
};

export type MutationDuplicateSkillReportArgs = {
  reportId: Scalars["ID"];
};

export type MutationEditCustomTopicArgs = {
  keywords: Array<InputMaybe<Scalars["String"]>>;
  matchRule: CustomTopicMatchRule;
  name: Scalars["String"];
  topicId: Scalars["ID"];
};

export type MutationEditSkillReportArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  interviewStageFilters: Scalars["JSONString"];
  minimumInterviewCountFilter: Scalars["Int"];
  positionIds: Array<Scalars["ID"]>;
  reportId: Scalars["ID"];
  skills: Array<SkillReportSkillInput>;
  source: SkillReportSkillsSource;
  title: Scalars["String"];
};

export type MutationEnableTrainingProgramAssessmentArgs = {
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
};

export type MutationEndCallArgs = {
  callId: Scalars["ID"];
};

export type MutationEndInPersonCallArgs = {
  callId: Scalars["ID"];
};

export type MutationExternalUserRequestAccessCodeArgs = {
  externalShareId: Scalars["ID"];
};

export type MutationExternalUserRequestShareRenewalArgs = {
  externalShareId: Scalars["ID"];
};

export type MutationExternalUserViewCallArgs = {
  externalShareId: Scalars["ID"];
};

export type MutationExternalUserViewClipArgs = {
  externalShareId: Scalars["ID"];
};

export type MutationGenerateCandidateSummaryArgs = {
  candidateId: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
  templateId: Scalars["ID"];
};

export type MutationGreenhouseCallParamsArgs = {
  applicationId?: InputMaybe<Scalars["String"]>;
  interviewKitId?: InputMaybe<Scalars["String"]>;
  scorecardUrl?: InputMaybe<Scalars["String"]>;
};

export type MutationGreenhouseCandidateParamsArgs = {
  applicationId?: InputMaybe<Scalars["String"]>;
  candidateId: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type MutationGreenhousePhoneNumberParamsArgs = {
  phoneNumber: Scalars["String"];
  scorecardUrl?: InputMaybe<Scalars["String"]>;
};

export type MutationIcimsCandidateParamsArgs = {
  atsCandidateId?: InputMaybe<Scalars["String"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  candidateFullName?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
};

export type MutationImportGoogleMeetMeetingArgs = {
  entryPoint?: InputMaybe<InterviewAssistantEntryPoint>;
  forceImport?: InputMaybe<Scalars["Boolean"]>;
  meetingId?: InputMaybe<Scalars["String"]>;
  meetingUrl: Scalars["String"];
};

export type MutationImportTeamsNotetakerMeetingArgs = {
  entryPoint?: InputMaybe<InterviewAssistantEntryPoint>;
  forceImport?: InputMaybe<Scalars["Boolean"]>;
  meetingId?: InputMaybe<Scalars["String"]>;
  meetingUrl: Scalars["String"];
};

export type MutationImportZoomMeetingArgs = {
  entryPoint?: InputMaybe<InterviewAssistantEntryPoint>;
  forceImport?: InputMaybe<Scalars["Boolean"]>;
  meetingId: Scalars["Float"];
};

export type MutationInforCandidateParamsArgs = {
  atsCandidateId?: InputMaybe<Scalars["String"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  candidateFullName?: InputMaybe<Scalars["String"]>;
  candidateType?: InputMaybe<Scalars["String"]>;
  jobCode?: InputMaybe<Scalars["String"]>;
  jobCodeId?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  requisitionId?: InputMaybe<Scalars["String"]>;
};

export type MutationInstallMergeIntegrationArgs = {
  publicToken: Scalars["String"];
};

export type MutationInviteUsersArgs = {
  users: Array<InputMaybe<InviteUser>>;
};

export type MutationJoinZoomNotetakerMeetingArgs = {
  zoomNotetakerMeetingId: Scalars["ID"];
};

export type MutationKeepCallArgs = {
  id: Scalars["ID"];
};

export type MutationKeepCandidateArgs = {
  id: Scalars["ID"];
};

export type MutationLaunchTrainingProgramArgs = {
  id: Scalars["ID"];
};

export type MutationLeverAuthOnRedirectArgs = {
  leverCode?: InputMaybe<Scalars["String"]>;
  leverStateToken: Scalars["String"];
  responseError?: InputMaybe<Scalars["String"]>;
  responseErrorDescription?: InputMaybe<Scalars["String"]>;
};

export type MutationLeverCallParamsArgs = {
  interviewId: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type MutationLeverCandidateParamsArgs = {
  opportunityId: Scalars["String"];
};

export type MutationMakeOutgoingCallArgs = {
  callId: Scalars["ID"];
};

export type MutationMarkCandidateAlertFeedReadArgs = {
  alertFilters?: InputMaybe<Array<CandidateAlertFeedFilter>>;
  candidateAlertFeedIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export type MutationMarkScorecardQuestionArgs = {
  markedTime: Scalars["Float"];
  scorecardQuestionId: Scalars["ID"];
};

export type MutationMarkTrainingProgramTraineeCompleteArgs = {
  complete: Scalars["Boolean"];
  id: Scalars["ID"];
};

export type MutationMuteCandidateAlertUserSubscriptionsArgs = {
  candidateIds: Array<Scalars["ID"]>;
  positionIds: Array<Scalars["ID"]>;
};

export type MutationNameTrainingProgramArgs = {
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationOutlookWebAppCallParamsArgs = {
  eventTitle?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
};

export type MutationOverrideCallGuideCueArgs = {
  cue?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type MutationPauseCallRecordingArgs = {
  callId: Scalars["ID"];
  pauseRecording: Scalars["Boolean"];
};

export type MutationPreviewTaskRunArgs = {
  id: Scalars["ID"];
  taskInputJson: Scalars["JSONString"];
};

export type MutationProcessInPersonRecordingArgs = {
  bitRate?: InputMaybe<Scalars["Int"]>;
  callId: Scalars["ID"];
};

export type MutationReTranscribeCallArgs = {
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
};

export type MutationReactivateUserArgs = {
  id: Scalars["ID"];
};

export type MutationRedactCallArgs = {
  id: Scalars["ID"];
};

export type MutationRedactCandidateArgs = {
  id: Scalars["ID"];
};

export type MutationRedactScheduledCandidatesArgs = {
  candidateStatus?: InputMaybe<CandidateRedactionStatus>;
};

export type MutationRedialArgs = {
  callId: Scalars["ID"];
};

export type MutationRemoveCallGuideAssignedUserArgs = {
  callGuideId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationRemoveClipFromPlaylistArgs = {
  clipId: Scalars["ID"];
  playlistId: Scalars["ID"];
};

export type MutationRemoveClipShareArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveExternalCallShareArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveExternalClipShareArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveExternalPlaylistShareArgs = {
  id: Scalars["ID"];
};

export type MutationRemovePlaylistShareArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveShareArgs = {
  id: Scalars["ID"];
};

export type MutationRenamePlaylistArgs = {
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationRenewExternalCallShareArgs = {
  id: Scalars["ID"];
};

export type MutationRenewExternalClipShareArgs = {
  id: Scalars["ID"];
};

export type MutationRenewExternalPlaylistShareArgs = {
  id: Scalars["ID"];
};

export type MutationReorderPlaylistClipsArgs = {
  clipIds: Array<Scalars["ID"]>;
  playlistId: Scalars["ID"];
};

export type MutationReorderTrainingProgramItemsArgs = {
  trainingProgramId: Scalars["ID"];
  trainingProgramItemIds: Array<InputMaybe<Scalars["ID"]>>;
};

export type MutationReorderTrainingProgramQuestionsArgs = {
  trainingProgramId: Scalars["ID"];
  trainingProgramQuestionIds: Array<InputMaybe<Scalars["ID"]>>;
};

export type MutationRequestUpgradeArgs = {
  featureName: Scalars["String"];
};

export type MutationResetTrainingProgramQuestionsArgs = {
  trainingProgramId: Scalars["ID"];
};

export type MutationRevokeUserCalendarsArgs = {
  id: Scalars["ID"];
};

export type MutationRunSkillReportArgs = {
  regenerate?: InputMaybe<Scalars["Boolean"]>;
  reportId: Scalars["ID"];
};

export type MutationScheduledInterviewerOptOutArgs = {
  id: Scalars["ID"];
  recordingDisabled: Scalars["Boolean"];
};

export type MutationSendBeforeCallTextMessageArgs = {
  body: Scalars["String"];
  candidateFirstName?: InputMaybe<Scalars["String"]>;
  candidateLastName?: InputMaybe<Scalars["String"]>;
  candidatePhone: Scalars["String"];
};

export type MutationSendCallBackTextMessageArgs = {
  body: Scalars["String"];
  callId: Scalars["ID"];
};

export type MutationSendCandidateEmailVerificationArgs = {
  email: Scalars["String"];
  orgId: Scalars["ID"];
};

export type MutationSendInviteArgs = {
  userId: Scalars["ID"];
};

export type MutationSetClientMembershipsArgs = {
  users: Array<SetClientMembershipsInput>;
};

export type MutationSetMembershipsArgs = {
  users: Array<SetMembershipsInput>;
};

export type MutationSetPlanUserRolesArgs = {
  users: Array<SetPlanUserRolesInput>;
};

export type MutationSetUserRolesArgs = {
  users: Array<SetUserRolesInput>;
};

export type MutationSetupGreenhouseArgs = {
  adminUserId: Scalars["BigInt"];
  apiKey?: InputMaybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  host?: InputMaybe<Scalars["String"]>;
  syncCallGuidesFromAts: Scalars["Boolean"];
};

export type MutationSetupGreenhouseWizardArgs = {
  adminUserId?: InputMaybe<Scalars["BigInt"]>;
  apiKey?: InputMaybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  host?: InputMaybe<Scalars["String"]>;
};

export type MutationSetupIcimsArgs = {
  clientId?: InputMaybe<Scalars["String"]>;
  clientSecret?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  tokenUrl?: InputMaybe<Scalars["String"]>;
};

export type MutationSetupLeverArgs = {
  apiKey?: InputMaybe<Scalars["String"]>;
  createSecretNotes: Scalars["Boolean"];
  enabled?: InputMaybe<Scalars["Boolean"]>;
  signatureTokenObj?: InputMaybe<Scalars["String"]>;
  syncCallGuidesFromAts: Scalars["Boolean"];
};

export type MutationSetupSenseArgs = {
  apiKey?: InputMaybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
};

export type MutationSetupSingleSignOnArgs = {
  metadataUrl: Scalars["String"];
  oktaDomain: Scalars["String"];
};

export type MutationShareAnalyticsReportArgs = {
  message: Scalars["String"];
  shareToUserIds: Array<InputMaybe<Scalars["ID"]>>;
  url: Scalars["String"];
};

export type MutationShareCallArgs = {
  id: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
  shareToUserIds: Array<Scalars["ID"]>;
};

export type MutationShareCallExternallyArgs = {
  callId: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
  shareToEmails: Array<Scalars["String"]>;
};

export type MutationShareClipArgs = {
  clipId: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
  shareToUserIds: Array<Scalars["ID"]>;
};

export type MutationShareClipExternallyArgs = {
  clipId: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
  shareToEmails: Array<Scalars["String"]>;
};

export type MutationSharePlaylistArgs = {
  message?: InputMaybe<Scalars["String"]>;
  playlistId: Scalars["ID"];
  shareToUserIds: Array<Scalars["ID"]>;
};

export type MutationSharePlaylistExternallyArgs = {
  playlistId: Scalars["ID"];
  shareMessage?: InputMaybe<Scalars["String"]>;
  shareToEmails: Array<Scalars["String"]>;
};

export type MutationSignInByEmailArgs = {
  email: Scalars["String"];
};

export type MutationSignInByPhoneArgs = {
  channel: Scalars["String"];
  phoneNumber: Scalars["String"];
  phoneNumberExtension?: InputMaybe<Scalars["String"]>;
};

export type MutationSmartrecruitersAuthOnRedirectArgs = {
  code?: InputMaybe<Scalars["String"]>;
  error?: InputMaybe<Scalars["String"]>;
  errorDescription?: InputMaybe<Scalars["String"]>;
  state: Scalars["String"];
};

export type MutationStartVirtualOnsiteBotMeetingArgs = {
  addApprovedInterviewer?: InputMaybe<Scalars["Boolean"]>;
  botMeetingId: Scalars["ID"];
};

export type MutationTalemetryCandidateParamsArgs = {
  atsCandidateId?: InputMaybe<Scalars["String"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  candidateFullName?: InputMaybe<Scalars["String"]>;
  jobOpeningId?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  talemetryCandidateId?: InputMaybe<Scalars["String"]>;
};

export type MutationUnMarkScorecardQuestionArgs = {
  scorecardQuestionId: Scalars["ID"];
};

export type MutationUnkeepCallArgs = {
  id: Scalars["ID"];
};

export type MutationUnkeepCandidateArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateAiAnswerArgs = {
  answerItemId: Scalars["ID"];
  text?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateAiQuestionArgs = {
  callAiNoteId: Scalars["ID"];
  text?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateAiSummaryTemplateArgs = {
  customPrompt?: InputMaybe<Scalars["String"]>;
  customType?: InputMaybe<CustomTemplateCustomType>;
  defaultFor?: InputMaybe<CallAiSummaryTemplateDefaultFor>;
  defaultForKeywords?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  defaultForMeetingType?: InputMaybe<CustomTemplateType>;
  defaultForPersona?: InputMaybe<UserPersona>;
  id: Scalars["ID"];
  isCreatorDefault?: InputMaybe<Scalars["Boolean"]>;
  isCurrentUserDefault?: InputMaybe<Scalars["Boolean"]>;
  model?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  sections?: InputMaybe<Array<InputMaybe<AiSummaryTemplateSectionInput>>>;
  visibility?: InputMaybe<CallAiSummaryTemplateVisibility>;
};

export type MutationUpdateAnalyticsQualityReportConfigArgs = {
  agendaSetEnabled: Scalars["Boolean"];
  candidateQuestionOpportunityEnabled: Scalars["Boolean"];
  candidateTalkRatioEnabled: Scalars["Boolean"];
  feedbackSubmissionRateEnabled: Scalars["Boolean"];
  feedbackSubmissionTimeEnabled: Scalars["Boolean"];
  interactivityEnabled: Scalars["Boolean"];
  longestMonologueEnabled: Scalars["Boolean"];
  onTimeStartsEnabled: Scalars["Boolean"];
  problematicQuestionsEnabled: Scalars["Boolean"];
  questionsAskedEnabled: Scalars["Boolean"];
  speakingRateEnabled: Scalars["Boolean"];
};

export type MutationUpdateApiTokenArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
};

export type MutationUpdateAutoJoinAllNewPositionsArgs = {
  allPositionGroups: Scalars["Boolean"];
  enable: Scalars["Boolean"];
  positionGroupId?: InputMaybe<Scalars["ID"]>;
};

export type MutationUpdateAutoJoinExcludedUsersArgs = {
  exclude: Scalars["Boolean"];
  userIds: Array<Scalars["ID"]>;
};

export type MutationUpdateCalendarOrgSettingsArgs = {
  id: Scalars["ID"];
  shouldImportVideoMeeting: Scalars["String"];
};

export type MutationUpdateCalendarSettingsArgs = {
  id: Scalars["ID"];
  shouldImportVideoMeeting: Scalars["String"];
};

export type MutationUpdateCallArgs = {
  bullhornCommentAction?: InputMaybe<Scalars["String"]>;
  callGuideId?: InputMaybe<Scalars["ID"]>;
  callGuideRemoved?: InputMaybe<Scalars["Boolean"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  candidateFirstName?: InputMaybe<Scalars["String"]>;
  candidateId?: InputMaybe<Scalars["String"]>;
  candidateLastName?: InputMaybe<Scalars["String"]>;
  iaVersion?: InputMaybe<Scalars["Float"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  positionId?: InputMaybe<Scalars["ID"]>;
  rating?: InputMaybe<Scalars["Int"]>;
  recordingDisabled?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<Scalars["String"]>;
  visibility?: InputMaybe<CallVisibility>;
};

export type MutationUpdateCallAiSummaryFormatArgs = {
  callId: Scalars["ID"];
  customTemplateId?: InputMaybe<Scalars["ID"]>;
  customTopics?: InputMaybe<Array<InputMaybe<CallAiSummaryCustomTopicInput>>>;
  forceRegenerate?: InputMaybe<Scalars["Boolean"]>;
  format?: InputMaybe<CallAiSummaryFormat>;
  targetSpeakerTags?: InputMaybe<Array<Scalars["Int"]>>;
};

export type MutationUpdateCallCandidateArgs = {
  callId: Scalars["ID"];
  candidateId?: InputMaybe<Scalars["ID"]>;
};

export type MutationUpdateCallGuideArgs = {
  assignedUserIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  guideItems?: InputMaybe<Array<CallGuideItemInput>>;
  id: Scalars["ID"];
  interviewStageId?: InputMaybe<Scalars["ID"]>;
  isTemplate?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  overallScoringEnabled?: InputMaybe<Scalars["Boolean"]>;
  positionId?: InputMaybe<Scalars["ID"]>;
  visibility?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateCallGuideAssignedUsersArgs = {
  assignedUserIds: Array<InputMaybe<Scalars["ID"]>>;
  id: Scalars["ID"];
};

export type MutationUpdateCallGuideCueArgs = {
  cue?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  index?: InputMaybe<Scalars["Int"]>;
};

export type MutationUpdateCallGuidesArgs = {
  assignedUserIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids: Array<InputMaybe<Scalars["ID"]>>;
  positionId?: InputMaybe<Scalars["ID"]>;
};

export type MutationUpdateCallNoteArgs = {
  clipId?: InputMaybe<Scalars["ID"]>;
  id: Scalars["ID"];
  text?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<CallNoteType>;
  visibility?: InputMaybe<CallNoteVisibility>;
};

export type MutationUpdateCallPositionArgs = {
  id: Scalars["ID"];
  positionId: Scalars["String"];
};

export type MutationUpdateCallSpeakerArgs = {
  callId: Scalars["ID"];
  speakerOptionId: Scalars["String"];
  speakerTag: Scalars["Int"];
};

export type MutationUpdateCandidateArgs = {
  email?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
  phoneType?: InputMaybe<CandidatePhoneType>;
};

export type MutationUpdateCandidateAlertAccuracyFeedbackArgs = {
  candidateAlertFeedIds: Array<Scalars["ID"]>;
  feedback: CandidateAlertAccuracyFeedback;
};

export type MutationUpdateCandidateEmailOptOutArgs = {
  orgId: Scalars["ID"];
  recordingDisabled: Scalars["Boolean"];
  verificationCode: Scalars["String"];
};

export type MutationUpdateCandidateOptOutArgs = {
  id: Scalars["ID"];
  recordingDisabled: Scalars["Boolean"];
};

export type MutationUpdateCandidateSummaryTemplateArgs = {
  asNewTemplate?: InputMaybe<Scalars["Boolean"]>;
  isDefault: Scalars["Boolean"];
  newTemplateName?: InputMaybe<Scalars["String"]>;
  positionId?: InputMaybe<Scalars["ID"]>;
  sections: Array<InputMaybe<CandidateSummaryTemplateSectionInput>>;
  templateId: Scalars["ID"];
};

export type MutationUpdateClientArgs = {
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationUpdateClipArgs = {
  clipId: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  visibility?: InputMaybe<CallVisibility>;
};

export type MutationUpdateCurrentUserArgs = {
  autofillGreenhouseScorecard?: InputMaybe<Scalars["Boolean"]>;
  browserExtensionVersion?: InputMaybe<Scalars["String"]>;
  calendarSyncSkipped?: InputMaybe<Scalars["Boolean"]>;
  defaultAiNoteFormat?: InputMaybe<Scalars["String"]>;
  emailAliases?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  maskPersonalPhoneNumber?: InputMaybe<Scalars["Boolean"]>;
  persona?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  phoneNumberExtension?: InputMaybe<Scalars["String"]>;
  phoneNumberSkipped?: InputMaybe<Scalars["Boolean"]>;
  showJoinWithBrighthire?: InputMaybe<Scalars["String"]>;
  timezoneName?: InputMaybe<Scalars["String"]>;
  verifyPhoneNumber?: InputMaybe<Scalars["Boolean"]>;
  zoomAppAutoOpenEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateCurrentUserOnboardingStepArgs = {
  steps: Array<UpdateOnboardingSteps>;
};

export type MutationUpdateCurrentUserShowActionItemsArgs = {
  show: Scalars["Boolean"];
};

export type MutationUpdateCurrentUserSubscriptionsSettingsArgs = {
  callNoteSubscription: UserSubscriptionSettings;
  callShareSubscription: UserSubscriptionSettings;
  interviewPrepSubscription: UserSubscriptionSettings;
  interviewRecapSubscription: UserSubscriptionSettings;
  monthlyAnalyticsSubscription: UserSubscriptionSettings;
  morningDigestSubscription: UserSubscriptionSettings;
  weeklyRecapSubscription: UserSubscriptionSettings;
};

export type MutationUpdateCurrentUserViewedTranscriptionWarningArgs = {
  hasViewedTranscriptionWarning?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateInterviewOptInArgs = {
  interviewer?: InputMaybe<Scalars["String"]>;
  isInterviewer: Scalars["Boolean"];
  location: Scalars["String"];
  optIn: Scalars["Boolean"];
  organizationId: Scalars["String"];
};

export type MutationUpdateLeverOauthIntegrationSettingsArgs = {
  createSecretNotes: Scalars["Boolean"];
  syncGuides: Scalars["Boolean"];
  useSandbox: Scalars["Boolean"];
};

export type MutationUpdateMyInterviewRecordingOptionsArgs = {
  importRule?: InputMaybe<UserInterviewImportRule>;
};

export type MutationUpdateOptInSetupArgs = {
  alwaysCandidate: Scalars["Boolean"];
  heroImageUrl?: InputMaybe<Scalars["String"]>;
  learnMoreLink?: InputMaybe<Scalars["String"]>;
  whoIsPageText: Scalars["String"];
  yesNoPageText?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateOrganizationArgs = {
  greenhouseScorecardAutoDraftOnboardingRule?: InputMaybe<OrgScorecardAutoDraftOnboardingRule>;
  greenhouseScorecardAutoDraftRule?: InputMaybe<OrgScorecardAutoDraftRule>;
};

export type MutationUpdateOrganizationAdvancedConfigurationsArgs = {
  allowInPersonMeetings: Scalars["Boolean"];
  allowManualScheduledInterviews: Scalars["Boolean"];
  allowUserLevelImportRules: Scalars["Boolean"];
  assignGuidesToInterviewersEnabled: Scalars["Boolean"];
  audioOnlyViewing: Scalars["Boolean"];
  externalShareDefaultDurationDays: Scalars["Float"];
  hideOtherInterviewerNotesInExtension: Scalars["Boolean"];
  includeTimestampsInNoteOutputs: Scalars["Boolean"];
  playInPersonRecordingDisclaimer: Scalars["Boolean"];
  textBeforeCallEnabled: Scalars["Boolean"];
};

export type MutationUpdateOrganizationAiSettingsArgs = {
  allowAiCustomTemplates: Scalars["Boolean"];
  askEnabled: Scalars["Boolean"];
  pasteAiNotesInScorecard: Scalars["Boolean"];
};

export type MutationUpdateOrganizationAtsSettingsArgs = {
  postNotesToAts: Scalars["Boolean"];
};

export type MutationUpdateOrganizationBetaArgs = {
  allowPhoneSignIn: Scalars["Boolean"];
  allowSelfSignUp: Scalars["Boolean"];
  emailDomains: Array<Scalars["String"]>;
  inviteScheduledInterviewers: Scalars["Boolean"];
  requireActiveUserToImport?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateOrganizationComplianceSettingsArgs = {
  allowCandidateOptOut: Scalars["Boolean"];
  allowInterviewerOptOut: Scalars["Boolean"];
  dataRedactionEnabled?: InputMaybe<Scalars["Boolean"]>;
  dataRedactionPredictionEnabled?: InputMaybe<Scalars["Boolean"]>;
  playCallDisclaimer: Scalars["Boolean"];
  redactCompensationEnabled?: InputMaybe<Scalars["Boolean"]>;
  redactEeocFamilyEnabled?: InputMaybe<Scalars["Boolean"]>;
  redactEeocHighRiskQuestionsEnabled?: InputMaybe<Scalars["Boolean"]>;
  redactEeocRaceEnabled?: InputMaybe<Scalars["Boolean"]>;
  redactEeocSexEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateOrganizationDataRetentionArgs = {
  enableAutomaticRetentionDeletion?: InputMaybe<Scalars["Boolean"]>;
  enableRedactionForAshby?: InputMaybe<Scalars["Boolean"]>;
  enableRedactionForGreenhouse?: InputMaybe<Scalars["Boolean"]>;
  enableRedactionForLever?: InputMaybe<Scalars["Boolean"]>;
  enableRedactionForSmartrecruiters?: InputMaybe<Scalars["Boolean"]>;
  enableRetentionSinceCandidateHired?: InputMaybe<Scalars["Boolean"]>;
  enableRetentionSinceCandidateRejected?: InputMaybe<Scalars["Boolean"]>;
  enableRetentionSinceInterview: Scalars["Boolean"];
  enableRetentionSinceLastInterview: Scalars["Boolean"];
  preventRedactionForTraining: Scalars["Boolean"];
  retentionDaysSinceCandidateHired?: InputMaybe<Scalars["Int"]>;
  retentionDaysSinceCandidateRejected?: InputMaybe<Scalars["Int"]>;
  retentionDaysSinceInterview: Scalars["Int"];
  retentionDaysSinceLastInterview: Scalars["Int"];
  retentionEmailList: Array<InputMaybe<Scalars["String"]>>;
  retentionEmailSendTime: Scalars["String"];
  retentionEmailSendTimezone: Scalars["String"];
  retentionNotificationDays: Array<InputMaybe<Scalars["String"]>>;
};

export type MutationUpdateOrganizationGenderLabelsArgs = {
  genderLabelFemale: Scalars["String"];
  genderLabelMale: Scalars["String"];
};

export type MutationUpdateOrganizationGoogleMeetArgs = {
  disclaimer?: InputMaybe<Scalars["String"]>;
  disclaimerType: NotetakerRecorderDisclaimerType;
  displayImage?: InputMaybe<Scalars["String"]>;
  displayImageFilename?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  virtualOnsiteEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateOrganizationInterviewPlanSettingsArgs = {
  createGuidesFromInterviewPlansEnabled: Scalars["Boolean"];
};

export type MutationUpdateOrganizationNameArgs = {
  name: Scalars["String"];
};

export type MutationUpdateOrganizationScoringArgs = {
  fiveStarCompetencyRating: Scalars["String"];
  fiveStarRating: Scalars["String"];
  fourStarCompetencyRating: Scalars["String"];
  fourStarRating: Scalars["String"];
  guideCompetencyScoringEnabledByDefault: Scalars["Boolean"];
  guideOverallScoringEnabledByDefault: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  oneStarCompetencyRating: Scalars["String"];
  oneStarRating: Scalars["String"];
  threeStarCompetencyRating: Scalars["String"];
  threeStarRating: Scalars["String"];
  twoStarCompetencyRating: Scalars["String"];
  twoStarRating: Scalars["String"];
};

export type MutationUpdateOrganizationTeamsNotetakerArgs = {
  disclaimer?: InputMaybe<Scalars["String"]>;
  disclaimerType: NotetakerRecorderDisclaimerType;
  displayImage?: InputMaybe<Scalars["String"]>;
  displayImageFilename?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  virtualOnsiteEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateOrganizationVideoSettingsArgs = {
  maximumCallDurationForNotetakerToRejoin?: InputMaybe<Scalars["Int"]>;
};

export type MutationUpdateOrganizationZoomNotetakerArgs = {
  disclaimer?: InputMaybe<Scalars["String"]>;
  disclaimerType: NotetakerRecorderDisclaimerType;
  displayImage?: InputMaybe<Scalars["String"]>;
  displayImageFilename?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  sendCancelRecordingChatMessage: Scalars["Boolean"];
  virtualOnsiteEnabled?: InputMaybe<Scalars["Boolean"]>;
  zoomAppAutoOpenRule: OrgZoomAppAutoOpenRule;
};

export type MutationUpdateOrganizationZoomSettingsArgs = {
  automaticallyDeleteZoomRecordings: Scalars["Boolean"];
  zoomAppAutoOpenRule: OrgZoomAppAutoOpenRule;
};

export type MutationUpdatePlanJobDescriptionSettingsArgs = {
  allowPlanJobDescriptionToneOverride: Scalars["Boolean"];
  customTemplateId: Scalars["ID"];
  defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
  sections: Array<InputMaybe<CustomTemplateSection>>;
};

export type MutationUpdatePositionArgs = {
  clientId?: InputMaybe<Scalars["ID"]>;
  id: Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
  videos?: InputMaybe<Array<VideoInput>>;
};

export type MutationUpdatePositionsAutoJoinArgs = {
  autoJoinInterview: Scalars["Boolean"];
  positionIds: Array<Scalars["ID"]>;
};

export type MutationUpdateRolePoliciesArgs = {
  basicCanSeeOthersNotes: Scalars["Boolean"];
  defaultHiringTeamRole: Scalars["ID"];
  defaultPlanRole?: InputMaybe<Scalars["ID"]>;
  defaultPositionCreateRole: Scalars["ID"];
  defaultRecruiterCallVisibility: CallVisibility;
  defaultScheduledInterviewerInviteRole: Scalars["ID"];
  defaultSelfSignUpRole: Scalars["ID"];
  hiringManagerCanCreateTrainings: Scalars["Boolean"];
  hiringManagerCanSeeOthersNotes: Scalars["Boolean"];
  hiringManagerCanSeePrivateCalls: Scalars["Boolean"];
  hiringManagerCanShareRecordingsExternally: Scalars["Boolean"];
  interviewerCanSeeOthersNotes: Scalars["Boolean"];
  memberCanSeeOthersNotes: Scalars["Boolean"];
  memberCanSeePublicCalls: Scalars["Boolean"];
  recruiterCanConfigureInterviewerQualityReport: Scalars["Boolean"];
  recruiterCanCreatePositions: Scalars["Boolean"];
  recruiterCanCreateTrainings: Scalars["Boolean"];
  recruiterCanDownloadRecordings: Scalars["Boolean"];
  recruiterCanManageAllTrainings: Scalars["Boolean"];
  recruiterCanManageCustomTemplates: Scalars["Boolean"];
  recruiterCanManageHiringTeams: Scalars["Boolean"];
  recruiterCanManageRedactions: Scalars["Boolean"];
  recruiterCanSeeOthersNotes: Scalars["Boolean"];
  recruiterCanSeePublicCalls: Scalars["Boolean"];
  recruiterCanSeeRestrictedCalls: Scalars["Boolean"];
  recruiterCanSeeScores: Scalars["Boolean"];
  recruiterCanShareRecordingsExternally: Scalars["Boolean"];
  recruiterCanViewInterviewerQualityReport: Scalars["Boolean"];
  siteAdminCanDownloadRecordings: Scalars["Boolean"];
};

export type MutationUpdateScheduledInterviewArgs = {
  callGuideId?: InputMaybe<Scalars["ID"]>;
  durationMinutes: Scalars["Int"];
  googleMeetMeetingUrl?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  interviewerIds: Array<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  positionId?: InputMaybe<Scalars["ID"]>;
  scheduledStartTime: Scalars["DateTime"];
  teamsMeetingUrl?: InputMaybe<Scalars["String"]>;
  timezoneName: Scalars["String"];
  zoomJoinUrl?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateScorecardArgs = {
  overallNotes?: InputMaybe<Scalars["String"]>;
  overallScore: Scalars["Int"];
  scorecardId: Scalars["ID"];
};

export type MutationUpdateScorecardCompetencyArgs = {
  notes?: InputMaybe<Scalars["String"]>;
  score: Scalars["Int"];
  scorecardCompetencyId: Scalars["ID"];
};

export type MutationUpdateSummaryNoteFeedbackArgs = {
  feedback: CandidateSummaryNoteAccuracyFeedback;
  noteId: Scalars["ID"];
};

export type MutationUpdateTextMessageTemplateArgs = {
  content: Scalars["String"];
  templateType: TextMessageTemplateType;
};

export type MutationUpdateTrainingProgramQuestionArgs = {
  id: Scalars["ID"];
  question: Scalars["String"];
};

export type MutationUpdateUserRoleArgs = {
  id: Scalars["ID"];
  userRoleId: Scalars["ID"];
};

export type MutationUpdateWebhookArgs = {
  eventCandidateUpdated: Scalars["Boolean"];
  eventInterviewCompleted: Scalars["Boolean"];
  id?: InputMaybe<Scalars["ID"]>;
  webhookUrl: Scalars["String"];
};

export type MutationUpdateZoomNotetakerMeetingIdArgs = {
  meetingId: Scalars["Float"];
  meetingUuid: Scalars["String"];
};

export type MutationVerifyEmailSignInArgs = {
  email: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type MutationVerifyExternalUserAccessCodeArgs = {
  externalShareId: Scalars["ID"];
  verificationCode: Scalars["String"];
};

export type MutationVerifyPhoneNumberArgs = {
  phoneNumber: Scalars["String"];
  phoneNumberExtension?: InputMaybe<Scalars["String"]>;
};

export type MutationVerifyPhoneSignInArgs = {
  phoneNumber: Scalars["String"];
  phoneNumberExtension?: InputMaybe<Scalars["String"]>;
  verificationCode: Scalars["String"];
};

export type MutationViewCallArgs = {
  callId: Scalars["ID"];
};

export type MutationViewCallScorecardArgs = {
  callId: Scalars["ID"];
  scorecardId: Scalars["ID"];
};

export type MutationViewCandidateArgs = {
  candidateId: Scalars["ID"];
};

export type MutationViewClipArgs = {
  clipId: Scalars["ID"];
  playlistId?: InputMaybe<Scalars["ID"]>;
};

export type MutationViewHighlightArgs = {
  callId: Scalars["ID"];
  highlightId: Scalars["ID"];
  highlightType: Scalars["String"];
};

export type MutationViewRedactionPredictionArgs = {
  callId: Scalars["ID"];
  predictionId: Scalars["ID"];
};

export type MuteCandidateAlertUserSubscriptions = {
  __typename?: "MuteCandidateAlertUserSubscriptions";
  subscriptions: CandidateAlertUserSubscriptions;
};

export type NameTrainingProgram = {
  __typename?: "NameTrainingProgram";
  trainingProgram: TrainingProgram;
};

export enum NoRecordingReason {
  CallNotCompleted = "CALL_NOT_COMPLETED",
  MaxParticipants = "MAX_PARTICIPANTS",
  MinDuration = "MIN_DURATION",
  NoMeeting = "NO_MEETING",
  RecordingDisabled = "RECORDING_DISABLED",
  RecordingNotStarted = "RECORDING_NOT_STARTED",
}

export type NotesScorecard = {
  __typename?: "NotesScorecard";
  id?: Maybe<Scalars["ID"]>;
  items: Array<NotesScorecardItem>;
};

export type NotesScorecardItem = {
  __typename?: "NotesScorecardItem";
  childItems: Array<NotesScorecardItem>;
  id: Scalars["ID"];
  isHtmlType?: Maybe<Scalars["Boolean"]>;
  itemText: Scalars["String"];
  marked?: Maybe<Scalars["Boolean"]>;
  markedTime?: Maybe<Scalars["Float"]>;
  parentCompetencyId?: Maybe<Scalars["ID"]>;
  position: Scalars["Int"];
  questionNotes: Array<CallNote>;
  type?: Maybe<GuideItemType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type NotesScorecardItemQuestionNotesArgs = {
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type NotetakerMeeting = {
  __typename?: "NotetakerMeeting";
  callId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  meetingUrl: Scalars["String"];
  type: NotetakerType;
};

export enum NotetakerRecorderDisclaimerType {
  Default = "DEFAULT",
  Native = "NATIVE",
  None = "NONE",
  Organization = "ORGANIZATION",
}

export enum NotetakerType {
  GoogleMeet = "GOOGLE_MEET",
  Phone = "PHONE",
  Teams = "TEAMS",
  Zoom = "ZOOM",
}

export type OffsetPaginationDetails = {
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  page: Scalars["Int"];
  searchTerm?: InputMaybe<Scalars["String"]>;
};

export type OffsetPaginationInfo = {
  __typename?: "OffsetPaginationInfo";
  totalPages?: Maybe<Scalars["Int"]>;
  totalRows?: Maybe<Scalars["Int"]>;
};

export enum OnboardingStep {
  BrowserExtension = "BROWSER_EXTENSION",
  CalendarSync = "CALENDAR_SYNC",
  ConfirmOrgName = "CONFIRM_ORG_NAME",
  ConnectGreenhouse = "CONNECT_GREENHOUSE",
  GreenhouseAutoDraft = "GREENHOUSE_AUTO_DRAFT",
  InstallZoomApp = "INSTALL_ZOOM_APP",
  Name = "NAME",
  Persona = "PERSONA",
  PhoneNumber = "PHONE_NUMBER",
  SelectCalendarEvents = "SELECT_CALENDAR_EVENTS",
  SelectGreenhouseInterviews = "SELECT_GREENHOUSE_INTERVIEWS",
  SelectInterviewPlatform = "SELECT_INTERVIEW_PLATFORM",
}

export enum OnboardingStepRule {
  Hidden = "HIDDEN",
  Optional = "OPTIONAL",
  Required = "REQUIRED",
  RequiredByOneAdmin = "REQUIRED_BY_ONE_ADMIN",
}

export enum OnboardingStepStatus {
  Completed = "COMPLETED",
  Skipped = "SKIPPED",
  Started = "STARTED",
}

export type OptInResult = {
  __typename?: "OptInResult";
  alwaysCandidate?: Maybe<Scalars["Boolean"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  exists: Scalars["Boolean"];
  heroImageUrl?: Maybe<Scalars["String"]>;
  learnMoreLink?: Maybe<Scalars["String"]>;
  whoIsPageText?: Maybe<Scalars["String"]>;
  willRecord: Scalars["Boolean"];
  yesNoPageText?: Maybe<Scalars["String"]>;
};

export type OptInSetup = {
  __typename?: "OptInSetup";
  alwaysCandidate: Scalars["Boolean"];
  heroImageUrl: Scalars["String"];
  id: Scalars["String"];
  learnMoreLink: Scalars["String"];
  optInUrlStub: Scalars["String"];
  whoIsPageText: Scalars["String"];
  yesNoPageText: Scalars["String"];
};

/** Org call metrics */
export type OrgCallingMetrics = {
  __typename?: "OrgCallingMetrics";
  candidateLongestMonologue?: Maybe<Scalars["Float"]>;
  candidateWordsPerMinute?: Maybe<Scalars["Float"]>;
  numDials: Scalars["Int"];
  numSignificantCalls: Scalars["Int"];
  totalDuration: Scalars["Float"];
  weeklyCalls: Scalars["Float"];
  weeklyDuration: Scalars["Float"];
};

export enum OrgScorecardAutoDraftOnboardingRule {
  Hide = "HIDE",
  Show = "SHOW",
}

export enum OrgScorecardAutoDraftRule {
  DefaultDisabled = "DEFAULT_DISABLED",
  DefaultEnabled = "DEFAULT_ENABLED",
  Disabled = "DISABLED",
  ForcedEnabled = "FORCED_ENABLED",
}

export enum OrgZoomAppAutoOpenRule {
  DefaultDisabled = "DEFAULT_DISABLED",
  DefaultEnabled = "DEFAULT_ENABLED",
  Disabled = "DISABLED",
  ForcedEnabled = "FORCED_ENABLED",
}

export type Organization = {
  __typename?: "Organization";
  allowAiCustomTemplates: Scalars["Boolean"];
  allowAiNotes: Scalars["Boolean"];
  allowCandidateOptOut: Scalars["Boolean"];
  allowEmailSignIn: Scalars["Boolean"];
  allowInPersonMeetings: Scalars["Boolean"];
  allowInterviewerOptOut: Scalars["Boolean"];
  allowManualScheduledInterviews: Scalars["Boolean"];
  allowOauthSignIn: Scalars["Boolean"];
  allowPhoneSignIn: Scalars["Boolean"];
  allowPlanJobDescriptionToneOverride: Scalars["Boolean"];
  allowSelfSignUp: Scalars["Boolean"];
  allowUserLevelImportRules: Scalars["Boolean"];
  allowsGreenhouseScorecardAutoDraft: Scalars["Boolean"];
  allowsZoomAppAutoOpen: Scalars["Boolean"];
  ashbyHiringManagerRoleId?: Maybe<Scalars["UUID"]>;
  ashbyRecruiterCoordinatorRoleId?: Maybe<Scalars["UUID"]>;
  ashbyRecruiterRoleId?: Maybe<Scalars["UUID"]>;
  ashbySourcerRoleId?: Maybe<Scalars["UUID"]>;
  askEnabled: Scalars["Boolean"];
  assignGuidesToInterviewersEnabled: Scalars["Boolean"];
  ats?: Maybe<Ats>;
  atsOptInEnabled: Scalars["Boolean"];
  audioOnlyViewing: Scalars["Boolean"];
  autoJoinAllNewPositions: Scalars["Boolean"];
  automaticallyDeleteZoomRecordings: Scalars["Boolean"];
  callAiSummaryTemplates: PaginatedCallAiSummaryTemplates;
  callDisclaimerExplicitConsent: Scalars["Boolean"];
  callDisclaimerExplicitConsentRecordingFilename?: Maybe<Scalars["String"]>;
  callDisclaimerExplicitConsentText?: Maybe<Scalars["String"]>;
  callDisclaimerRecordingFilename?: Maybe<Scalars["String"]>;
  callDisclaimerText?: Maybe<Scalars["String"]>;
  callDropdownNames?: Maybe<Array<Scalars["String"]>>;
  callGuides: Array<CallGuide>;
  callNameFieldIsDropdown: Scalars["Boolean"];
  callingMetrics: OrgCallingMetrics;
  calls: Array<Call>;
  candidateOptOutUrl: Scalars["String"];
  candidates: Array<Candidate>;
  clients: Array<Client>;
  clientsPag?: Maybe<PaginatedClients>;
  competencies: Array<OrganizationCompetency>;
  competencyRatingDescriptions: CompetencyRatingDescriptions;
  connectCallPrompt?: Maybe<Scalars["String"]>;
  createGuidesFromInterviewPlansEnabled: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  customRecallTimeoutNooneJoined?: Maybe<Scalars["Int"]>;
  customRecallTimeoutWaitingRoom?: Maybe<Scalars["Int"]>;
  customTopics: Array<AnalyticsCustomTopic>;
  dataRedactionEnabled: Scalars["Boolean"];
  dataRedactionPredictionEnabled: Scalars["Boolean"];
  defaultCallAiSummaryTemplateId?: Maybe<Scalars["UUID"]>;
  defaultCandidateSummaryTemplateId?: Maybe<Scalars["UUID"]>;
  defaultHiringTeamRoleId?: Maybe<Scalars["UUID"]>;
  defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
  defaultPlanRoleId?: Maybe<Scalars["UUID"]>;
  defaultPositionCreateRoleId?: Maybe<Scalars["UUID"]>;
  defaultRecruiterCallVisibility: CallVisibility;
  defaultScheduledInterviewerInviteRoleId?: Maybe<Scalars["UUID"]>;
  defaultSelfSignUpRoleId?: Maybe<Scalars["UUID"]>;
  deprecatedPhoneNumber?: Maybe<Scalars["String"]>;
  disableAnalyticsGenderSegmentation: Scalars["Boolean"];
  doNotRetainRecordings: Scalars["Boolean"];
  emailDomains?: Maybe<Array<Scalars["String"]>>;
  enableAtsRbacSync: Scalars["Boolean"];
  enableAutomaticRetentionDeletion: Scalars["Boolean"];
  enableRedactionForAshby: Scalars["Boolean"];
  enableRedactionForGreenhouse: Scalars["Boolean"];
  enableRedactionForLever: Scalars["Boolean"];
  enableRedactionForSmartrecruiters: Scalars["Boolean"];
  enableRetentionSinceCandidateHired: Scalars["Boolean"];
  enableRetentionSinceCandidateRejected: Scalars["Boolean"];
  enableRetentionSinceInterview: Scalars["Boolean"];
  enableRetentionSinceLastInterview: Scalars["Boolean"];
  externalSessionDuration: Scalars["Float"];
  externalShareDefaultDurationDays: Scalars["Float"];
  featureAccess?: Maybe<Array<OrganizationFeatureAccess>>;
  filledSeatsInfo: Array<Scalars["String"]>;
  freemium: Scalars["Boolean"];
  freemiumSeatCount: Scalars["Int"];
  freemiumSeatCountOverride?: Maybe<Scalars["Int"]>;
  genderLabelFemale?: Maybe<Scalars["String"]>;
  genderLabelMale?: Maybe<Scalars["String"]>;
  /** Full bucket path for the google_meet_display_image_filename */
  googleMeetDisplayImageBucketPath?: Maybe<Scalars["String"]>;
  googleMeetDisplayImageFilename?: Maybe<Scalars["String"]>;
  googleMeetDisplayName?: Maybe<Scalars["String"]>;
  googleMeetEnabled: Scalars["Boolean"];
  googleMeetRecordingDisclaimer?: Maybe<Scalars["String"]>;
  googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
  greenhouseConsentCustomField?: Maybe<Scalars["String"]>;
  greenhouseConsentCustomOptInOptions: Scalars["JSONString"];
  greenhouseCoordinatorRoleId?: Maybe<Scalars["UUID"]>;
  greenhouseHiringManagerRoleId?: Maybe<Scalars["UUID"]>;
  greenhouseRecruiterRoleId?: Maybe<Scalars["UUID"]>;
  greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
  greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
  guideCompetencyScoringEnabledByDefault: Scalars["Boolean"];
  guideOverallScoringEnabledByDefault: Scalars["Boolean"];
  hideOtherInterviewerNotesInExtension: Scalars["Boolean"];
  hideTimestampsInNoteOutputs?: Maybe<Scalars["Boolean"]>;
  icimsAccessToken?: Maybe<Scalars["JSONString"]>;
  icimsAccessTokenUrl?: Maybe<Scalars["String"]>;
  icimsClientId?: Maybe<Scalars["JSONString"]>;
  icimsClientSecret?: Maybe<Scalars["JSONString"]>;
  icimsCustomerId?: Maybe<Scalars["String"]>;
  icimsEnabled: Scalars["Boolean"];
  icimsTokenExpiresAt?: Maybe<Scalars["DateTime"]>;
  icimsTokenType?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  inactivityTimeoutDuration?: Maybe<Scalars["Float"]>;
  includeTimestampsInNoteOutputs: Scalars["Boolean"];
  incomingCallRecruiterNotFoundMessage?: Maybe<Scalars["String"]>;
  individualVoipNumbersEnabled: Scalars["Boolean"];
  internationalCallingEnabled: Scalars["Boolean"];
  internationalPhoneVoipNumbers?: Maybe<Array<InternationalPhoneVoipNumbers>>;
  interviewEnabled: Scalars["Boolean"];
  interviewStages: PaginatedStages;
  interviewTypes: Array<InterviewType>;
  inviteScheduledInterviewers: Scalars["Boolean"];
  inviteScheduledInterviewersAsShadowUsers: Scalars["Boolean"];
  isBullhornAuthorized: Scalars["Boolean"];
  isZoomAuthorized: Scalars["Boolean"];
  isZoomBotAuthorized: Scalars["Boolean"];
  isZoomDeauthorizeRunning: Scalars["Boolean"];
  leverAccessToken?: Maybe<Scalars["JSONString"]>;
  leverCreateSecretNotes: Scalars["Boolean"];
  leverHiringManagerRoleId?: Maybe<Scalars["UUID"]>;
  leverOwnerRoleId?: Maybe<Scalars["UUID"]>;
  leverRefreshToken?: Maybe<Scalars["JSONString"]>;
  leverSignatureToken?: Maybe<Scalars["JSONString"]>;
  leverStateToken?: Maybe<Scalars["JSONString"]>;
  leverTokenExpiresAt?: Maybe<Scalars["DateTime"]>;
  leverUseSandbox: Scalars["Boolean"];
  maskPersonalPhoneNumber: Scalars["Boolean"];
  maximumCallDurationForNotetakerToRejoin?: Maybe<Scalars["Int"]>;
  metadataUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notetakerRecorderDisclaimerDefault: Scalars["String"];
  oktaDomain?: Maybe<Scalars["String"]>;
  organizationRetentionUser: Array<OrganizationRetentionUser>;
  parseCandidateInfoFromEventBody: Scalars["Boolean"];
  pasteAiNotesInScorecard: Scalars["Boolean"];
  phoneNumber?: Maybe<Scalars["String"]>;
  planEnabled: Scalars["Boolean"];
  planUserRoles: Array<PlanUserRole>;
  playCallDisclaimer: Scalars["Boolean"];
  playInPersonRecordingDisclaimer: Scalars["Boolean"];
  positionRoles: Array<PositionRole>;
  positions: PaginatedPositions;
  postNotesToAts: Scalars["Boolean"];
  preventOutsideUsAccess: Scalars["Boolean"];
  preventRedactionForTraining: Scalars["Boolean"];
  primaryAreaCode?: Maybe<Scalars["String"]>;
  qualityReportConfig: AnalyticsQualityReportConfig;
  ratingDescriptions: RatingDescriptions;
  recallSilenceDetectionTimeout?: Maybe<Scalars["Int"]>;
  recallZoomOauthCredentialId?: Maybe<Scalars["UUID"]>;
  recallZoomOauthCredentialInstallFinishedAt?: Maybe<Scalars["DateTime"]>;
  recallZoomOauthCredentialInstallStartedAt?: Maybe<Scalars["DateTime"]>;
  recallZoomOauthCredentialInstallStatus?: Maybe<InstallZoomNotetakerAppJobProcessingStatus>;
  recordingAllowed: Scalars["Boolean"];
  redactCompensationEnabled: Scalars["Boolean"];
  redactEeocFamilyEnabled: Scalars["Boolean"];
  redactEeocHighRiskQuestionsEnabled: Scalars["Boolean"];
  redactEeocRaceEnabled: Scalars["Boolean"];
  redactEeocSexEnabled: Scalars["Boolean"];
  redactedAt?: Maybe<Scalars["DateTime"]>;
  redactionPredictions: PaginatedRedactionPredictions;
  replaceGenderedPronouns: Scalars["Boolean"];
  requireActiveUserToImport: Scalars["Boolean"];
  requireSamlRoleMappingForSelfSignUp: Scalars["Boolean"];
  requireWhitelistUserToImport: Scalars["Boolean"];
  requiresGreenhouseScorecardAutoDraft: Scalars["Boolean"];
  requiresZoomAppAutoOpen: Scalars["Boolean"];
  restrictShowJoinWithBrighthireOnSignup: Scalars["Boolean"];
  retentionDaysSinceCandidateHired: Scalars["Float"];
  retentionDaysSinceCandidateRejected: Scalars["Float"];
  retentionDaysSinceInterview: Scalars["Float"];
  retentionDaysSinceLastInterview: Scalars["Float"];
  retentionEmailSendTime: Scalars["Time"];
  retentionEmailSendTimezone: Scalars["String"];
  retentionNotificationDays?: Maybe<Array<Maybe<Scalars["String"]>>>;
  samlRoleMapping: Scalars["JSONString"];
  scoringEnabled: Scalars["Boolean"];
  secondaryAreaCodes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sendCancelRecordingChatMessage: Scalars["Boolean"];
  sendInterviewSignUpNudges: Scalars["Boolean"];
  sendInvitationsForSsoCreatedUsers: Scalars["Boolean"];
  sendSignUpNudges: Scalars["Boolean"];
  senseApiKey?: Maybe<Scalars["JSONString"]>;
  senseEnabled: Scalars["Boolean"];
  sessionDuration: Scalars["Float"];
  showStarRatings?: Maybe<Scalars["Boolean"]>;
  skillReports: Array<SkillReport>;
  slackAppInstalled: Scalars["Boolean"];
  slackId?: Maybe<Scalars["String"]>;
  smartrecruitersAccessToken?: Maybe<Scalars["JSONString"]>;
  smartrecruitersCoordinatorRoleId?: Maybe<Scalars["UUID"]>;
  smartrecruitersEnabled: Scalars["Boolean"];
  smartrecruitersExecutiveRoleId?: Maybe<Scalars["UUID"]>;
  smartrecruitersHiringManagerRoleId?: Maybe<Scalars["UUID"]>;
  smartrecruitersRecruiterRoleId?: Maybe<Scalars["UUID"]>;
  smartrecruitersRefreshToken?: Maybe<Scalars["JSONString"]>;
  smartrecruitersStateToken?: Maybe<Scalars["JSONString"]>;
  smartrecruitersTokenExpiresAt?: Maybe<Scalars["DateTime"]>;
  smartrecruitersTokenScope?: Maybe<Array<Maybe<Scalars["String"]>>>;
  syncCallGuidesFromAts: Scalars["Boolean"];
  syncCandidateOffersFromAts: Scalars["Boolean"];
  talkTimePercentage?: Maybe<Scalars["Float"]>;
  teamsNotetakerDisclaimer?: Maybe<Scalars["String"]>;
  teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
  /** Full bucket path for the teams_notetaker_display_image_filename */
  teamsNotetakerDisplayImageBucketPath?: Maybe<Scalars["String"]>;
  teamsNotetakerDisplayImageFilename?: Maybe<Scalars["String"]>;
  teamsNotetakerDisplayName?: Maybe<Scalars["String"]>;
  teamsNotetakerEnabled: Scalars["Boolean"];
  textBeforeCallEnabled: Scalars["Boolean"];
  turnOnIncludeTitleExcludeByStage: Scalars["Boolean"];
  twilioBusinessProfileSid?: Maybe<Scalars["String"]>;
  twilioTrustProductCnamSid?: Maybe<Scalars["String"]>;
  twilioTrustProductShakenStirSid?: Maybe<Scalars["String"]>;
  twilioTrustProductVoiceIntegritySid?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  userAutoJoinExclusions: Array<User>;
  userRoles: Array<UserRole>;
  users: Array<User>;
  usersWithGuideAssignments: Array<User>;
  virtualOnsiteEnabledGoogleMeet: Scalars["Boolean"];
  virtualOnsiteEnabledMsTeams: Scalars["Boolean"];
  virtualOnsiteEnabledZoom: Scalars["Boolean"];
  zoomAccountId?: Maybe<Scalars["String"]>;
  zoomAppAutoOpenRule?: Maybe<OrgZoomAppAutoOpenRule>;
  zoomNotetakerDisclaimer?: Maybe<Scalars["String"]>;
  zoomNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
  /** Full bucket path for the zoom_notetaker_display_image */
  zoomNotetakerDisplayImageBucketPath?: Maybe<Scalars["String"]>;
  zoomNotetakerDisplayImageFilename?: Maybe<Scalars["String"]>;
  zoomNotetakerDisplayName?: Maybe<Scalars["String"]>;
  zoomNotetakerEnabled: Scalars["Boolean"];
  zoomSettings: Scalars["JSONString"];
  zoomUseZoomNative: Scalars["Boolean"];
};

export type OrganizationCallAiSummaryTemplatesArgs = {
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
  pagination?: InputMaybe<OffsetPaginationDetails>;
  sharedOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type OrganizationCallGuidesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
  showCurrentUserPrivateGuides?: InputMaybe<Scalars["Boolean"]>;
};

export type OrganizationCallingMetricsArgs = {
  end?: InputMaybe<Scalars["Date"]>;
  start: Scalars["Date"];
};

export type OrganizationClientsPagArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type OrganizationInterviewStagesArgs = {
  atsOnly?: InputMaybe<Scalars["Boolean"]>;
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type OrganizationPositionsArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]>;
  atsOnly?: InputMaybe<Scalars["Boolean"]>;
  dateRangeEnd?: InputMaybe<Scalars["String"]>;
  dateRangeStart?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type OrganizationRedactionPredictionsArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
  topic?: InputMaybe<Scalars["String"]>;
};

export type OrganizationUsersArgs = {
  canViewAnalyticsReport?: InputMaybe<Scalars["Boolean"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  loginPermitted?: InputMaybe<Scalars["Boolean"]>;
  query?: InputMaybe<Scalars["String"]>;
  signUpCompleted?: InputMaybe<Scalars["Boolean"]>;
};

export type OrganizationUsersWithGuideAssignmentsArgs = {
  query?: InputMaybe<Scalars["String"]>;
};

export type OrganizationApiToken = {
  __typename?: "OrganizationApiToken";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  tokenPrefix?: Maybe<Scalars["String"]>;
};

export type OrganizationCompetency = {
  __typename?: "OrganizationCompetency";
  createdAt: Scalars["DateTime"];
  creatorId?: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  isMigrated: Scalars["Boolean"];
  name: Scalars["String"];
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export type OrganizationFeatureAccess = {
  __typename?: "OrganizationFeatureAccess";
  id: Scalars["ID"];
  isVisible: Scalars["Boolean"];
  marketingRedirect: Scalars["Boolean"];
  name: FeatureName;
  settingLevel: FeatureSettingLevel;
  showCta: Scalars["Boolean"];
};

export type OrganizationListItem = {
  __typename?: "OrganizationListItem";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type OrganizationLocalRecordingFalseUser = {
  __typename?: "OrganizationLocalRecordingFalseUser";
  email: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type OrganizationName = {
  __typename?: "OrganizationName";
  name: Scalars["String"];
};

export type OrganizationRetentionUser = {
  __typename?: "OrganizationRetentionUser";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  userId: Scalars["UUID"];
};

export type OutlookWebAppCallParams = {
  __typename?: "OutlookWebAppCallParams";
  params?: Maybe<CallParams>;
};

export type OverrideCallGuideCue = {
  __typename?: "OverrideCallGuideCue";
  callGuideCue: CallGuideCue;
};

export type PaginatedActivityFeed = {
  __typename?: "PaginatedActivityFeed";
  pageInfo?: Maybe<CursorPaginationInfo>;
  results?: Maybe<Array<ActivityFeed>>;
};

export type PaginatedCallAiSummaryTemplates = {
  __typename?: "PaginatedCallAiSummaryTemplates";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results?: Maybe<Array<CallAiSummaryTemplate>>;
};

export type PaginatedCallGuides = {
  __typename?: "PaginatedCallGuides";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results: Array<CallGuide>;
};

export type PaginatedCallShares = {
  __typename?: "PaginatedCallShares";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results?: Maybe<Array<CallShare>>;
};

export type PaginatedCalls = {
  __typename?: "PaginatedCalls";
  pageInfo?: Maybe<CursorPaginationInfo>;
  results?: Maybe<Array<Call>>;
};

export type PaginatedCandidateAlert = {
  __typename?: "PaginatedCandidateAlert";
  id: Scalars["ID"];
  pageInfo: CursorPaginationInfo;
  results: Array<CandidateAlert>;
};

export type PaginatedCandidateAlertFeed = {
  __typename?: "PaginatedCandidateAlertFeed";
  id: Scalars["ID"];
  pageInfo: CursorPaginationInfo;
  results: Array<CandidateAlertFeed>;
};

export type PaginatedCandidates = {
  __typename?: "PaginatedCandidates";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results?: Maybe<Array<Candidate>>;
};

export type PaginatedClients = {
  __typename?: "PaginatedClients";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results?: Maybe<Array<Client>>;
};

export type PaginatedClipShares = {
  __typename?: "PaginatedClipShares";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results?: Maybe<Array<ClipShare>>;
};

export type PaginatedClips = {
  __typename?: "PaginatedClips";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results?: Maybe<Array<Clip>>;
};

export type PaginatedPlaylistShares = {
  __typename?: "PaginatedPlaylistShares";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results?: Maybe<Array<PlaylistShare>>;
};

export type PaginatedPlaylists = {
  __typename?: "PaginatedPlaylists";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results?: Maybe<Array<Playlist>>;
};

export type PaginatedPositions = {
  __typename?: "PaginatedPositions";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results?: Maybe<Array<Position>>;
};

export type PaginatedRecordingVisibleTo = {
  __typename?: "PaginatedRecordingVisibleTo";
  resultCount: Scalars["Int"];
  results: Array<RecordingVisibleTo>;
};

export type PaginatedRedactionPredictions = {
  __typename?: "PaginatedRedactionPredictions";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results?: Maybe<Array<RedactionPrediction>>;
  topicFilters?: Maybe<Array<RedactionTopicFilter>>;
};

export type PaginatedScheduledInterviews = {
  __typename?: "PaginatedScheduledInterviews";
  pageInfo?: Maybe<CursorPaginationInfo>;
  results?: Maybe<Array<ScheduledInterview>>;
};

export type PaginatedStages = {
  __typename?: "PaginatedStages";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  results?: Maybe<Array<InterviewStage>>;
};

export type PaginationDelimiter = {
  id: Scalars["ID"];
  timestamp: Scalars["DateTime"];
};

export type PauseCallRecording = {
  __typename?: "PauseCallRecording";
  call: Call;
};

export enum PlanJobDescriptionTone {
  Friendly = "FRIENDLY",
  Fun = "FUN",
  Inspirational = "INSPIRATIONAL",
  Professional = "PROFESSIONAL",
  Visionary = "VISIONARY",
}

export type PlanUserRole = {
  __typename?: "PlanUserRole";
  canManageOrganizationSettings?: Maybe<Scalars["Boolean"]>;
  canManageUserRoles?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["DateTime"];
  formattedName?: Maybe<Scalars["String"]>;
  formattedPermissions?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: PlanUserRoleName;
  organizationId: Scalars["UUID"];
  permissions: Array<PlanUserRolePermission>;
  updatedAt: Scalars["DateTime"];
};

export enum PlanUserRoleName {
  Admin = "ADMIN",
  Editor = "EDITOR",
}

export enum PlanUserRolePermission {
  CreateJobDescription = "CREATE_JOB_DESCRIPTION",
  ManageOrganizationSettings = "MANAGE_ORGANIZATION_SETTINGS",
  ManageUserRoles = "MANAGE_USER_ROLES",
  ViewAllJobDescriptions = "VIEW_ALL_JOB_DESCRIPTIONS",
}

export type Playlist = {
  __typename?: "Playlist";
  call?: Maybe<Call>;
  clipCount: Scalars["Int"];
  clips: Array<Clip>;
  createdAt: Scalars["DateTime"];
  createdBy: User;
  createdById: Scalars["UUID"];
  description?: Maybe<Scalars["String"]>;
  duration: Scalars["Int"];
  externalShares: Array<ExternalPlaylistShare>;
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  shares: Array<PlaylistShare>;
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type PlaylistNavigationInfo = {
  __typename?: "PlaylistNavigationInfo";
  currentClipIdx?: Maybe<Scalars["Int"]>;
  nextClipUrl?: Maybe<Scalars["String"]>;
  playlist: Playlist;
  previousClipUrl?: Maybe<Scalars["String"]>;
};

export type PlaylistShare = {
  __typename?: "PlaylistShare";
  absoluteUrl: Scalars["String"];
  createdAt: Scalars["DateTime"];
  firstClip: Clip;
  fromCallId: Scalars["UUID"];
  id: Scalars["UUID"];
  message?: Maybe<Scalars["String"]>;
  playlist: Playlist;
  playlistId: Scalars["UUID"];
  relativeUrl: Scalars["String"];
  sharedById: Scalars["UUID"];
  sharedByUser: User;
  sharedToId: Scalars["UUID"];
  sharedToUser: User;
  updatedAt: Scalars["DateTime"];
};

export type Position = {
  __typename?: "Position";
  atsStatus?: Maybe<Scalars["String"]>;
  autoJoinInterview: Scalars["Boolean"];
  bullhornId?: Maybe<Scalars["Int"]>;
  callGuides: Array<CallGuide>;
  callGuidesCount?: Maybe<Scalars["Int"]>;
  calls: Array<Call>;
  candidateSummaryTemplateId?: Maybe<Scalars["ID"]>;
  candidates: Array<Candidate>;
  candidatesCount?: Maybe<Scalars["Int"]>;
  client: Client;
  clientId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  customAtsId?: Maybe<Scalars["String"]>;
  customAtsStatus?: Maybe<Scalars["String"]>;
  customAtsUrl?: Maybe<Scalars["String"]>;
  displayTitle: Scalars["String"];
  greenhouseId?: Maybe<Scalars["Float"]>;
  greenhouseLink?: Maybe<Scalars["String"]>;
  greenhouseStatus?: Maybe<Scalars["String"]>;
  hasJobPostingDescription: Scalars["Boolean"];
  icimsId?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  inforJobCode?: Maybe<Scalars["String"]>;
  lastCallAt?: Maybe<Scalars["DateTime"]>;
  leverPostingId?: Maybe<Scalars["String"]>;
  leverState?: Maybe<Scalars["String"]>;
  locations?: Maybe<Array<Maybe<Scalars["String"]>>>;
  memberships: Array<Membership>;
  membershipsCount?: Maybe<Scalars["Int"]>;
  mergeId?: Maybe<Scalars["String"]>;
  mergeRemoteId?: Maybe<Scalars["String"]>;
  mergeStatus?: Maybe<Scalars["String"]>;
  mergeType?: Maybe<Ats>;
  organizationId: Scalars["UUID"];
  paginatedCallGuides: PaginatedCallGuides;
  paginatedCandidates: PaginatedCandidates;
  pitch?: Maybe<Scalars["String"]>;
  requisitionId?: Maybe<Scalars["String"]>;
  smartrecruitersId?: Maybe<Scalars["String"]>;
  smartrecruitersLink?: Maybe<Scalars["String"]>;
  smartrecruitersStatus?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  titleInsensitive?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  videos: Array<Video>;
};

export type PositionPaginatedCallGuidesArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type PositionPaginatedCandidatesArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type PositionJobDescriptionSkills = {
  __typename?: "PositionJobDescriptionSkills";
  skills: Array<GeneratedSkill>;
};

export type PositionRole = {
  __typename?: "PositionRole";
  canAccessTeamPrivateCalls?: Maybe<Scalars["Boolean"]>;
  canAccessTeamPublicCalls?: Maybe<Scalars["Boolean"]>;
  canAccessTeamRestrictedCalls?: Maybe<Scalars["Boolean"]>;
  canCreateTrainings?: Maybe<Scalars["Boolean"]>;
  canManageHiringTeam?: Maybe<Scalars["Boolean"]>;
  canViewInsights?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["DateTime"];
  formattedName?: Maybe<Scalars["String"]>;
  formattedPermissions?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: PositionRoleName;
  organizationId: Scalars["UUID"];
  permissions: Array<PositionRolePermission>;
  updatedAt: Scalars["DateTime"];
};

export enum PositionRoleName {
  HiringTeamAdmin = "HIRING_TEAM_ADMIN",
  Interviewer = "INTERVIEWER",
  Member = "MEMBER",
}

export enum PositionRolePermission {
  AccessOthersNotes = "ACCESS_OTHERS_NOTES",
  AccessTeamPrivateCalls = "ACCESS_TEAM_PRIVATE_CALLS",
  AccessTeamPublicCalls = "ACCESS_TEAM_PUBLIC_CALLS",
  AccessTeamRestrictedCalls = "ACCESS_TEAM_RESTRICTED_CALLS",
  CreateTrainings = "CREATE_TRAININGS",
  ManageAllScores = "MANAGE_ALL_SCORES",
  ManageHiringTeam = "MANAGE_HIRING_TEAM",
  ShareRecordingsExternally = "SHARE_RECORDINGS_EXTERNALLY",
  SubscribesToTeamAlerts = "SUBSCRIBES_TO_TEAM_ALERTS",
}

export type PreviewTaskRun = {
  __typename?: "PreviewTaskRun";
  taskRun: TaskRun;
};

export type ProcessInPersonRecording = {
  __typename?: "ProcessInPersonRecording";
  success: Scalars["Boolean"];
};

export type ProspectOutput = {
  __typename?: "ProspectOutput";
  categories: Array<Maybe<CategoryOutput>>;
  tldr: Array<Maybe<Scalars["String"]>>;
};

export type Query = {
  __typename?: "Query";
  activityFeed: PaginatedActivityFeed;
  aiSummaryTemplate?: Maybe<CallAiSummaryTemplate>;
  alerts: AlertValues;
  analyticsData?: Maybe<QueryResult>;
  analyticsDataCsv?: Maybe<Csv>;
  application?: Maybe<Application>;
  atsDataState: AtsDataState;
  auditLog: Array<AuditLog>;
  auditLogItemExists?: Maybe<Scalars["Boolean"]>;
  badUsers?: Maybe<Array<OrganizationLocalRecordingFalseUser>>;
  benchmarks: BenchmarkValues;
  botMeetingScheduledInterview?: Maybe<ScheduledInterview>;
  browserExtensionCalls: Array<Call>;
  browserExtensionData?: Maybe<BrowserExtensionData>;
  bullhornAuth?: Maybe<BullhornAuth>;
  bullhornCommentActions: Array<Scalars["String"]>;
  call?: Maybe<Call>;
  callAiNotes?: Maybe<CallAiNotes>;
  callAiSummary?: Maybe<CallAiSummary>;
  callAiTopics?: Maybe<CallAiTopics>;
  callChapters?: Maybe<CallChapters>;
  callGuide?: Maybe<CallGuide>;
  callInProgress?: Maybe<Call>;
  callRecordingDownloadUrl?: Maybe<Scalars["String"]>;
  callThumbnails: Array<CallThumbnail>;
  callToken: Scalars["String"];
  callsAiTagsStatus: Array<Maybe<CallAiTagsStatus>>;
  callsById: Array<Call>;
  callsToRedact?: Maybe<RedactCallQueryResponse>;
  candidate?: Maybe<Candidate>;
  candidateAlert: PaginatedCandidateAlert;
  candidateAlertFeed: PaginatedCandidateAlertFeed;
  candidateAlertUserSubscriptions: CandidateAlertUserSubscriptions;
  candidateAsk: CandidateAskResponse;
  candidateByPhone?: Maybe<Candidate>;
  candidateEmailOptOut?: Maybe<CandidateEmailOptOut>;
  candidateMotivationCounts?: Maybe<CandidateMotivationsCountsResult>;
  candidateMotivationExamples?: Maybe<CandidateMotivationExamplesResult>;
  candidateMotivationExamplesCsv?: Maybe<Csv>;
  candidateMotivationKeyThemes?: Maybe<CandidateKeyThemeResult>;
  candidatePositionChapters: CandidateChapters;
  candidateQuestionKeyThemes?: Maybe<CandidateKeyThemeResult>;
  candidateQuestionTopicCounts?: Maybe<CandidateQuestionTopicCountsResult>;
  candidateQuestionTopicExamples?: Maybe<CandidateQuestionTopicExamplesResult>;
  candidateQuestionTopicExamplesCsv?: Maybe<Csv>;
  candidateRediscoveryProspects?: Maybe<CandidateRediscoveryProspects>;
  candidateSummary?: Maybe<CandidateSummary>;
  candidateSummaryCallCount?: Maybe<CandidateSummaryCallCountResponse>;
  candidateSummaryPositionPermissions?: Maybe<CandidateSummaryPositionPermissions>;
  candidateSummaryTemplate?: Maybe<CandidateSummaryTemplate>;
  candidateSummaryTemplates?: Maybe<Array<CandidateSummaryTemplate>>;
  candidates: PaginatedCandidates;
  candidatesBySearchString?: Maybe<Array<Candidate>>;
  candidatesToRedact?: Maybe<RedactCandidateQueryResponse>;
  client?: Maybe<Client>;
  clip?: Maybe<Clip>;
  clusteredData?: Maybe<QueryResult>;
  compensationTrends?: Maybe<CompensationTrendsResult>;
  compensationTrendsExamples?: Maybe<CompensationTrendsExamplesResult>;
  compensationTrendsExamplesCsv?: Maybe<Csv>;
  currentExternalUser?: Maybe<ExternalUser>;
  currentUser?: Maybe<User>;
  customTopicCounts?: Maybe<TopicCountsResult>;
  customTopicOccurrences?: Maybe<TopicOccurrencesResult>;
  customTopicOccurrencesCsv?: Maybe<Csv>;
  excludeStages: Array<ExcludeStage>;
  externalShareAiNotes?: Maybe<ExternalCallAiNotes>;
  externalShareView?: Maybe<ExternalShareView>;
  featureFlag?: Maybe<FeatureFlag>;
  filterValues: FilterValues;
  findGreenhouseUser?: Maybe<GreenhouseUser>;
  greenhouseCurrentUser?: Maybe<GreenhouseUser>;
  greenhouseFreemiumSyncStatus?: Maybe<GreenhouseSync>;
  greenhouseSetup?: Maybe<GreenhouseSetup>;
  icimsSetup?: Maybe<IcimsSetup>;
  internalOrganizationsList: Array<OrganizationListItem>;
  interviewOptIn: OptInResult;
  interviewStageFilterValues: FilterValues;
  leverOauthSetup?: Maybe<LeverOauthSetup>;
  leverSetup?: Maybe<LeverSetup>;
  mergeSetup: Array<MergeSetup>;
  optInSetup: OptInSetup;
  orgInterviewTotals: InterviewTotalsResult;
  organizationName?: Maybe<OrganizationName>;
  planCustomTemplate: JobDescriptionTemplate;
  playlist?: Maybe<Playlist>;
  playlistNavigationInfo?: Maybe<PlaylistNavigationInfo>;
  position?: Maybe<Position>;
  positionJobDescriptionSkills?: Maybe<PositionJobDescriptionSkills>;
  previewCallsToRedact?: Maybe<RedactCallQueryResponse>;
  previewCandidatesToRedact?: Maybe<RedactCandidateQueryResponse>;
  primaryCalendarEvents: Array<Event>;
  qualityReport: ReportQueryResult;
  qualityReportCsv?: Maybe<Csv>;
  qualityReportTrends: ReportTrendsQueryResult;
  restApiSetup?: Maybe<RestApiSetup>;
  rolePolicies: RolePolicies;
  scheduledInterviewer?: Maybe<ScheduledInterviewer>;
  searchApiKey: Scalars["String"];
  senseSetup?: Maybe<SenseConfiguration>;
  singleSignOnSetup?: Maybe<SingleSignOnSetup>;
  skillDescription?: Maybe<SkillDescriptionQueryResult>;
  skillReport?: Maybe<SkillReport>;
  skillReportCsv?: Maybe<Csv>;
  smartrecruitersSetup?: Maybe<SmartrecruitersSetup>;
  supportedTranscriptLanguages?: Maybe<SupportedTranscriptLanguage>;
  taskDefinitions?: Maybe<Array<Maybe<TaskDefinition>>>;
  taskRun?: Maybe<TaskRun>;
  textMessage?: Maybe<TextMessage>;
  textMessageTemplate?: Maybe<TextMessageTemplate>;
  textMessageTemplates: Array<TextMessageTemplate>;
  timezones: Array<Timezone>;
  traineeCount: Scalars["Int"];
  trainingProgram?: Maybe<TrainingProgram>;
  trainingProgramItemPreview: TrainingProgramItemPreview;
  trainingProgramManagementListItems: Array<TrainingProgramManagementListItem>;
  trainingProgramTraineeListItems: Array<TrainingProgramTraineeListItem>;
  trainingProgramTrainees?: Maybe<Array<TrainingProgramTrainee>>;
  trainingPrograms: Array<TrainingProgram>;
  user?: Maybe<User>;
  userInterviewTotals: InterviewTotalsResult;
  users: Array<User>;
  virtualOnsite: VirtualOnsite;
  visibilityLevelDescriptions: Array<VisibilityLevelDescription>;
  zoomBotInstallStatus?: Maybe<InstallZoomNotetakerAppJobProcessingStatus>;
  zoomJoinUrlByUuid?: Maybe<Scalars["String"]>;
};

export type QueryActivityFeedArgs = {
  pagination?: InputMaybe<CursorPaginationDetails>;
  types?: InputMaybe<Array<ActivityType>>;
};

export type QueryAiSummaryTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryAlertsArgs = {
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
};

export type QueryAnalyticsDataArgs = {
  dateRangeEnd?: InputMaybe<Scalars["String"]>;
  dateRangeStart?: InputMaybe<Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]>>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  metric: MetricName;
  organizationId?: InputMaybe<Scalars["String"]>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  primaryDimension: AnalyticsDimension;
  secondaryDimension: AnalyticsDimension;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryAnalyticsDataCsvArgs = {
  dateRangeEnd?: InputMaybe<Scalars["String"]>;
  dateRangeStart?: InputMaybe<Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]>>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  metric: MetricName;
  organizationId?: InputMaybe<Scalars["String"]>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryApplicationArgs = {
  candidateId: Scalars["ID"];
  positionId: Scalars["ID"];
};

export type QueryAuditLogItemExistsArgs = {
  id: Scalars["ID"];
  name: Scalars["String"];
  property: Scalars["String"];
};

export type QueryBotMeetingScheduledInterviewArgs = {
  botMeetingId?: InputMaybe<Scalars["String"]>;
  meetingUrl?: InputMaybe<Scalars["String"]>;
  zoomMeetingId?: InputMaybe<Scalars["Float"]>;
};

export type QueryBrowserExtensionCallsArgs = {
  tabUrl: Scalars["String"];
};

export type QueryBrowserExtensionDataArgs = {
  email?: InputMaybe<Scalars["String"]>;
  intent?: InputMaybe<BrowserExtensionIntent>;
  phone?: InputMaybe<Scalars["String"]>;
  tabUrl: Scalars["String"];
};

export type QueryCallArgs = {
  clipId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  twilioCallSid?: InputMaybe<Scalars["String"]>;
};

export type QueryCallAiNotesArgs = {
  callId: Scalars["ID"];
};

export type QueryCallAiSummaryArgs = {
  callId: Scalars["ID"];
  customTemplateId?: InputMaybe<Scalars["ID"]>;
  format: CallAiSummaryFormat;
};

export type QueryCallAiTopicsArgs = {
  callId: Scalars["ID"];
};

export type QueryCallChaptersArgs = {
  callId: Scalars["ID"];
};

export type QueryCallGuideArgs = {
  id: Scalars["ID"];
};

export type QueryCallRecordingDownloadUrlArgs = {
  callId: Scalars["ID"];
};

export type QueryCallThumbnailsArgs = {
  callIds: Array<Scalars["ID"]>;
};

export type QueryCallsAiTagsStatusArgs = {
  backfill: Scalars["Boolean"];
  callIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export type QueryCallsByIdArgs = {
  callIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export type QueryCallsToRedactArgs = {
  count: Scalars["Int"];
};

export type QueryCandidateArgs = {
  id: Scalars["ID"];
};

export type QueryCandidateAlertArgs = {
  candidateId: Scalars["ID"];
  pagination?: InputMaybe<CursorPaginationDetails>;
};

export type QueryCandidateAlertFeedArgs = {
  alertFilter?: InputMaybe<CandidateAlertFeedFilter>;
  alertFilters?: InputMaybe<Array<CandidateAlertFeedFilter>>;
  pagination?: InputMaybe<CursorPaginationDetails>;
};

export type QueryCandidateAskArgs = {
  candidateId: Scalars["ID"];
  text: Scalars["String"];
};

export type QueryCandidateByPhoneArgs = {
  phoneNumber: Scalars["String"];
};

export type QueryCandidateEmailOptOutArgs = {
  orgId: Scalars["ID"];
  verificationCode: Scalars["String"];
};

export type QueryCandidateMotivationCountsArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCandidateMotivationExamplesArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  motivationTopic?: InputMaybe<CandidateMotivationTopic>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCandidateMotivationExamplesCsvArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  motivationTopic?: InputMaybe<CandidateMotivationTopic>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCandidateMotivationKeyThemesArgs = {
  motivationTopic?: InputMaybe<CandidateMotivationTopic>;
  predictionIds: Array<Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
};

export type QueryCandidatePositionChaptersArgs = {
  candidateId: Scalars["ID"];
  positionId: Scalars["ID"];
};

export type QueryCandidateQuestionKeyThemesArgs = {
  predictionIds?: InputMaybe<Array<Scalars["String"]>>;
  questionTopic?: InputMaybe<CandidateQuestionTopic>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
};

export type QueryCandidateQuestionTopicCountsArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCandidateQuestionTopicExamplesArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  questionTopic?: InputMaybe<CandidateQuestionTopic>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCandidateQuestionTopicExamplesCsvArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  questionTopic?: InputMaybe<CandidateQuestionTopic>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCandidateRediscoveryProspectsArgs = {
  positionId: Scalars["ID"];
};

export type QueryCandidateSummaryArgs = {
  id: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
  templateId?: InputMaybe<Scalars["ID"]>;
};

export type QueryCandidateSummaryCallCountArgs = {
  candidateId: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
};

export type QueryCandidateSummaryPositionPermissionsArgs = {
  positionId?: InputMaybe<Scalars["ID"]>;
};

export type QueryCandidateSummaryTemplateArgs = {
  positionId?: InputMaybe<Scalars["ID"]>;
  templateId: Scalars["ID"];
};

export type QueryCandidateSummaryTemplatesArgs = {
  candidateId: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
};

export type QueryCandidatesArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type QueryCandidatesBySearchStringArgs = {
  searchString: Scalars["String"];
};

export type QueryCandidatesToRedactArgs = {
  candidateStatus?: InputMaybe<CandidateRedactionStatus>;
  page?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<CandidateRedactionSortField>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type QueryClientArgs = {
  id: Scalars["ID"];
};

export type QueryClipArgs = {
  clipId?: InputMaybe<Scalars["ID"]>;
};

export type QueryClusteredDataArgs = {
  countBy: Scalars["String"];
  dateRangeEnd?: InputMaybe<Scalars["String"]>;
  dateRangeStart?: InputMaybe<Scalars["String"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  positionDisplayTitle?: InputMaybe<Scalars["String"]>;
  topicsOrQuestions: Scalars["String"];
};

export type QueryCompensationTrendsArgs = {
  currency?: InputMaybe<Scalars["String"]>;
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  rate?: InputMaybe<CompensationExtractionRateType>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
};

export type QueryCompensationTrendsExamplesArgs = {
  category?: InputMaybe<CompensationExtractionCategoryType>;
  currency?: InputMaybe<Scalars["String"]>;
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
};

export type QueryCompensationTrendsExamplesCsvArgs = {
  category?: InputMaybe<CompensationExtractionCategoryType>;
  currency?: InputMaybe<Scalars["String"]>;
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
};

export type QueryCustomTopicCountsArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  keywords: Array<Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  matchRule: CustomTopicMatchRule;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  speakerType: AnalyticsTopicSpeakerType;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCustomTopicOccurrencesArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  keywords: Array<Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  matchRule: CustomTopicMatchRule;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  speakerType: AnalyticsTopicSpeakerType;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCustomTopicOccurrencesCsvArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  keywords: Array<Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  matchRule: CustomTopicMatchRule;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  speakerType: AnalyticsTopicSpeakerType;
  stages?: InputMaybe<Array<Scalars["String"]>>;
  topicName: Scalars["String"];
};

export type QueryExternalShareAiNotesArgs = {
  id: Scalars["ID"];
};

export type QueryExternalShareViewArgs = {
  id: Scalars["ID"];
  index?: InputMaybe<Scalars["Int"]>;
  shareType: Scalars["String"];
};

export type QueryFeatureFlagArgs = {
  featureFlag: Scalars["String"];
};

export type QueryFilterValuesArgs = {
  dateRangeEnd?: InputMaybe<Scalars["String"]>;
  dateRangeStart?: InputMaybe<Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]>>;
  forCompensation?: InputMaybe<Scalars["Boolean"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  metric?: InputMaybe<MetricName>;
  organizationId?: InputMaybe<Scalars["String"]>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  primaryDimension: AnalyticsDimension;
  secondaryDimension?: InputMaybe<AnalyticsDimension>;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryFindGreenhouseUserArgs = {
  email: Scalars["String"];
};

export type QueryGreenhouseSetupArgs = {
  verifyAdminUserId?: InputMaybe<Scalars["Boolean"]>;
  verifyApiPermissions?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryInterviewOptInArgs = {
  location: Scalars["String"];
  organizationId: Scalars["String"];
};

export type QueryInterviewStageFilterValuesArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  positionIds: Array<Scalars["String"]>;
};

export type QueryOrgInterviewTotalsArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
};

export type QueryOrganizationNameArgs = {
  orgIdSlug: Scalars["ID"];
};

export type QueryPlaylistArgs = {
  id: Scalars["ID"];
};

export type QueryPlaylistNavigationInfoArgs = {
  callId: Scalars["ID"];
  clipId: Scalars["ID"];
  playlistId: Scalars["ID"];
};

export type QueryPositionArgs = {
  id: Scalars["ID"];
};

export type QueryPositionJobDescriptionSkillsArgs = {
  positionId: Scalars["ID"];
};

export type QueryPreviewCallsToRedactArgs = {
  enableRetentionSinceInterview: Scalars["Boolean"];
  preventRedactionForTraining: Scalars["Boolean"];
  retentionDaysSinceInterview: Scalars["Int"];
};

export type QueryPreviewCandidatesToRedactArgs = {
  enableRetentionSinceCandidateHired?: InputMaybe<Scalars["Boolean"]>;
  enableRetentionSinceCandidateRejected?: InputMaybe<Scalars["Boolean"]>;
  enableRetentionSinceLastInterview: Scalars["Boolean"];
  preventRedactionForTraining: Scalars["Boolean"];
  retentionDaysSinceCandidateHired?: InputMaybe<Scalars["Int"]>;
  retentionDaysSinceCandidateRejected?: InputMaybe<Scalars["Int"]>;
  retentionDaysSinceLastInterview: Scalars["Int"];
};

export type QueryPrimaryCalendarEventsArgs = {
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startBefore?: InputMaybe<Scalars["DateTime"]>;
};

export type QueryQualityReportArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  includeRawResults?: InputMaybe<Scalars["Boolean"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  primaryDimension: AnalyticsDimension;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  secondaryDimension: AnalyticsDimension;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryQualityReportCsvArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  includeRawResults?: InputMaybe<Scalars["Boolean"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  primaryDimension: AnalyticsDimension;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  secondaryDimension: AnalyticsDimension;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryQualityReportTrendsArgs = {
  dateRangeEnd: Scalars["String"];
  dateRangeStart: Scalars["String"];
  departments?: InputMaybe<Array<Scalars["String"]>>;
  includeRawResults?: InputMaybe<Scalars["Boolean"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  positions?: InputMaybe<Array<Scalars["String"]>>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryScheduledInterviewerArgs = {
  id: Scalars["ID"];
};

export type QuerySkillDescriptionArgs = {
  skillName: Scalars["String"];
};

export type QuerySkillReportArgs = {
  id: Scalars["ID"];
};

export type QuerySkillReportCsvArgs = {
  id: Scalars["ID"];
};

export type QueryTaskDefinitionsArgs = {
  type: TaskDefinitionType;
};

export type QueryTaskRunArgs = {
  id: Scalars["ID"];
};

export type QueryTextMessageArgs = {
  messageSid: Scalars["ID"];
};

export type QueryTextMessageTemplateArgs = {
  templateType: TextMessageTemplateType;
};

export type QueryTrainingProgramArgs = {
  id: Scalars["ID"];
};

export type QueryTrainingProgramItemPreviewArgs = {
  callId: Scalars["ID"];
  clipId?: InputMaybe<Scalars["ID"]>;
};

export type QueryTrainingProgramManagementListItemsArgs = {
  callId?: InputMaybe<Scalars["ID"]>;
  clipId?: InputMaybe<Scalars["ID"]>;
  excludedCallId?: InputMaybe<Scalars["ID"]>;
  excludedClipId?: InputMaybe<Scalars["ID"]>;
};

export type QueryTrainingProgramTraineesArgs = {
  trainingProgramId?: InputMaybe<Scalars["ID"]>;
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

export type QueryUserInterviewTotalsArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryUsersArgs = {
  query?: InputMaybe<Scalars["String"]>;
};

export type QueryVirtualOnsiteArgs = {
  id: Scalars["ID"];
};

export type QueryZoomJoinUrlByUuidArgs = {
  uuid: Scalars["ID"];
};

export type QueryResult = {
  __typename?: "QueryResult";
  data: Array<DataPoint>;
  id: Scalars["ID"];
};

export type QuestionAndAnswer = {
  __typename?: "QuestionAndAnswer";
  answer: Scalars["String"];
  answerItems: Array<AnswerItem>;
  chapterTitle?: Maybe<Scalars["String"]>;
  edited?: Maybe<Scalars["Boolean"]>;
  endTime: Scalars["Int"];
  error?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isCandidate: Scalars["Boolean"];
  matchReason: Scalars["String"];
  originalAnswer?: Maybe<Scalars["String"]>;
  originalQuestion?: Maybe<Scalars["String"]>;
  question: Scalars["String"];
  segmentIndex: Scalars["Int"];
  startTime: Scalars["Int"];
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  thumbnailImageUrl?: Maybe<Scalars["String"]>;
};

export type RatingDescriptions = {
  __typename?: "RatingDescriptions";
  fiveStar: Scalars["String"];
  fourStar: Scalars["String"];
  oneStar: Scalars["String"];
  threeStar: Scalars["String"];
  twoStar: Scalars["String"];
};

export type ReTranscribeCall = {
  __typename?: "ReTranscribeCall";
  call: Call;
};

export type ReactivateUser = {
  __typename?: "ReactivateUser";
  user: User;
};

export type Recording = {
  __typename?: "Recording";
  audioChannels: Scalars["Int"];
  bitRate?: Maybe<Scalars["Float"]>;
  blobName: Scalars["String"];
  bucketName: Scalars["String"];
  callId: Scalars["UUID"];
  clipId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  endTime: Scalars["DateTime"];
  format?: Maybe<RecordingFormat>;
  height?: Maybe<Scalars["Float"]>;
  id: Scalars["UUID"];
  isRedacted: Scalars["Boolean"];
  size?: Maybe<Scalars["Float"]>;
  sourceUrl?: Maybe<Scalars["String"]>;
  startTime: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  uploaded: Scalars["Boolean"];
  width?: Maybe<Scalars["Float"]>;
};

export enum RecordingFormat {
  M4A = "M4A",
  Mp3 = "MP3",
  Mp4 = "MP4",
  Wav = "WAV",
  Weba = "WEBA",
}

export type RecordingVisibleTo = {
  __typename?: "RecordingVisibleTo";
  id: Scalars["ID"];
  role: Scalars["String"];
  user: User;
};

export type RedactCall = {
  __typename?: "RedactCall";
  success: Scalars["Boolean"];
};

export type RedactCallQueryResponse = {
  __typename?: "RedactCallQueryResponse";
  calls?: Maybe<Array<Maybe<Call>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type RedactCandidate = {
  __typename?: "RedactCandidate";
  success: Scalars["Boolean"];
};

export type RedactCandidateItem = {
  __typename?: "RedactCandidateItem";
  firstName?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  hiredAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  lastInterviewAt?: Maybe<Scalars["DateTime"]>;
  lastName?: Maybe<Scalars["String"]>;
  numInterviews?: Maybe<Scalars["Int"]>;
  rejectedAt?: Maybe<Scalars["DateTime"]>;
};

export type RedactCandidateQueryResponse = {
  __typename?: "RedactCandidateQueryResponse";
  candidates?: Maybe<Array<RedactCandidateItem>>;
  numPages?: Maybe<Scalars["Int"]>;
  totalCandidateCount?: Maybe<Scalars["Int"]>;
};

export type RedactScheduledCalls = {
  __typename?: "RedactScheduledCalls";
  success: Scalars["Boolean"];
};

export type RedactScheduledCandidates = {
  __typename?: "RedactScheduledCandidates";
  success: Scalars["Boolean"];
};

export enum RedactionModelTopic {
  Compensation = "COMPENSATION",
  EeocAge = "EEOC_AGE",
  EeocDisability = "EEOC_DISABILITY",
  EeocFamilyStatus = "EEOC_FAMILY_STATUS",
  EeocGeneticInformation = "EEOC_GENETIC_INFORMATION",
  EeocHighRiskQuestions = "EEOC_HIGH_RISK_QUESTIONS",
  EeocRace = "EEOC_RACE",
  EeocReligion = "EEOC_RELIGION",
  EeocSexualOrientationGender = "EEOC_SEXUAL_ORIENTATION_GENDER",
}

export type RedactionPrediction = {
  __typename?: "RedactionPrediction";
  call?: Maybe<Call>;
  createdAt: Scalars["DateTime"];
  endTime: Scalars["Float"];
  friendlyTopic: Scalars["String"];
  id: Scalars["UUID"];
  modelTopic: RedactionModelTopic;
  organizationId: Scalars["UUID"];
  score: Scalars["Float"];
  speaker?: Maybe<CallSpeaker>;
  startTime: Scalars["Float"];
  text: Scalars["String"];
  transcriptId: Scalars["UUID"];
  transcriptSegmentId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  viewed: Scalars["Boolean"];
  viewedByUserId?: Maybe<Scalars["UUID"]>;
};

export type RedactionTopicFilter = {
  __typename?: "RedactionTopicFilter";
  label: Scalars["String"];
  value: Scalars["String"];
};

export type Redial = {
  __typename?: "Redial";
  call: Call;
};

export type RemoveBullhornCredentials = {
  __typename?: "RemoveBullhornCredentials";
  currentUser: User;
};

export type RemoveCallGuideAssignedUser = {
  __typename?: "RemoveCallGuideAssignedUser";
  callGuide: CallGuide;
};

export type RemoveClipFromPlaylist = {
  __typename?: "RemoveClipFromPlaylist";
  playlist: Playlist;
};

export type RemoveClipShare = {
  __typename?: "RemoveClipShare";
  clip: Clip;
};

export type RemoveExternalCallShare = {
  __typename?: "RemoveExternalCallShare";
  call: Call;
};

export type RemoveExternalClipShare = {
  __typename?: "RemoveExternalClipShare";
  clip: Clip;
};

export type RemoveExternalPlaylistShare = {
  __typename?: "RemoveExternalPlaylistShare";
  playlistShare: ExternalPlaylistShare;
};

export type RemovePlaylistShare = {
  __typename?: "RemovePlaylistShare";
  playlistShare: PlaylistShare;
};

export type RemoveShare = {
  __typename?: "RemoveShare";
  call: Call;
};

export type RenamePlaylist = {
  __typename?: "RenamePlaylist";
  playlist: Playlist;
};

export type RenewExternalCallShare = {
  __typename?: "RenewExternalCallShare";
  call: Call;
};

export type RenewExternalClipShare = {
  __typename?: "RenewExternalClipShare";
  clip: Clip;
};

export type RenewExternalPlaylistShare = {
  __typename?: "RenewExternalPlaylistShare";
  externalPlaylistShare: ExternalPlaylistShare;
};

export type ReorderPlaylistClips = {
  __typename?: "ReorderPlaylistClips";
  playlist: Playlist;
};

export type ReorderTrainingProgramItems = {
  __typename?: "ReorderTrainingProgramItems";
  trainingProgram: TrainingProgram;
};

export type ReorderTrainingProgramQuestions = {
  __typename?: "ReorderTrainingProgramQuestions";
  trainingProgram: TrainingProgram;
};

export type ReportConfig = {
  __typename?: "ReportConfig";
  enabled: Scalars["Boolean"];
  name: Scalars["String"];
};

export type ReportDataPoint = {
  __typename?: "ReportDataPoint";
  candidateQuestionOpportunity?: Maybe<Scalars["Float"]>;
  candidateQuestionOpportunityRaw?: Maybe<Scalars["Float"]>;
  feedbackSubmissionRate?: Maybe<Scalars["Float"]>;
  feedbackSubmissionRateRaw?: Maybe<Scalars["Float"]>;
  feedbackSubmissionTime?: Maybe<Scalars["Float"]>;
  feedbackSubmissionTimeRaw?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  interactivity?: Maybe<Scalars["Float"]>;
  interactivityRaw?: Maybe<Scalars["Float"]>;
  interviewerIds?: Maybe<Array<Scalars["String"]>>;
  isAgendaSet?: Maybe<Scalars["Float"]>;
  isAgendaSetRaw?: Maybe<Scalars["Float"]>;
  longestMonologue?: Maybe<Scalars["Float"]>;
  longestMonologueRaw?: Maybe<Scalars["Float"]>;
  onTimeStarts?: Maybe<Scalars["Float"]>;
  onTimeStartsRaw?: Maybe<Scalars["Float"]>;
  organizationId?: Maybe<Scalars["String"]>;
  problematicQuestions?: Maybe<Scalars["Float"]>;
  problematicQuestionsRaw?: Maybe<Scalars["Float"]>;
  questionsAsked?: Maybe<Scalars["Float"]>;
  questionsAskedRaw?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
  speakingRate?: Maybe<Scalars["Float"]>;
  speakingRateRaw?: Maybe<Scalars["Float"]>;
  talkRatio?: Maybe<Scalars["Float"]>;
  talkRatioRaw?: Maybe<Scalars["Float"]>;
  totalCalls?: Maybe<Scalars["Int"]>;
  xLabel?: Maybe<Scalars["String"]>;
};

export type ReportQueryResult = {
  __typename?: "ReportQueryResult";
  config: Array<ReportConfig>;
  data: Array<ReportDataPoint>;
  id: Scalars["ID"];
};

export type ReportTrendsQueryResult = {
  __typename?: "ReportTrendsQueryResult";
  bucketInterval: Scalars["String"];
  bucketSize: Scalars["Int"];
  comparisonData?: Maybe<Array<ReportDataPoint>>;
  config: Array<ReportConfig>;
  data: Array<ReportDataPoint>;
  id: Scalars["ID"];
};

/** User is requesting to enable data redaction for their organization */
export type RequestOrganizationDataRedactionAccess = {
  __typename?: "RequestOrganizationDataRedactionAccess";
  success?: Maybe<Scalars["Boolean"]>;
};

/** User is requesting to upgrade their organization with a specific feature */
export type RequestUpgrade = {
  __typename?: "RequestUpgrade";
  success?: Maybe<Scalars["Boolean"]>;
};

export type ResetAnalyticsQualityReportConfig = {
  __typename?: "ResetAnalyticsQualityReportConfig";
  reportConfig: AnalyticsQualityReportConfig;
};

export type ResetTrainingProgramQuestions = {
  __typename?: "ResetTrainingProgramQuestions";
  trainingProgram: TrainingProgram;
};

export enum ResponseStatus {
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  NeedsAction = "NEEDS_ACTION",
  Tentative = "TENTATIVE",
}

export type RestApiSetup = {
  __typename?: "RestApiSetup";
  apiTokens: Array<OrganizationApiToken>;
  webhooks: Array<WebhookConfiguration>;
};

export type RevokeUserCalendars = {
  __typename?: "RevokeUserCalendars";
  user: User;
};

export type RolePolicies = {
  __typename?: "RolePolicies";
  basicCanSeeOthersNotes: Scalars["Boolean"];
  defaultHiringTeamRole: Scalars["ID"];
  defaultPlanRole?: Maybe<Scalars["ID"]>;
  defaultPositionCreateRole: Scalars["ID"];
  defaultRecruiterCallVisibility: CallVisibility;
  defaultScheduledInterviewerInviteRole: Scalars["ID"];
  defaultSelfSignUpRole: Scalars["ID"];
  hiringManagerCanCreateTrainings: Scalars["Boolean"];
  hiringManagerCanSeeOthersNotes: Scalars["Boolean"];
  hiringManagerCanSeePrivateCalls: Scalars["Boolean"];
  hiringManagerCanShareRecordingsExternally: Scalars["Boolean"];
  interviewerCanSeeOthersNotes: Scalars["Boolean"];
  memberCanSeeOthersNotes: Scalars["Boolean"];
  memberCanSeePublicCalls: Scalars["Boolean"];
  recruiterCanConfigureInterviewerQualityReport: Scalars["Boolean"];
  recruiterCanCreatePositions: Scalars["Boolean"];
  recruiterCanCreateTrainings: Scalars["Boolean"];
  recruiterCanDownloadRecordings: Scalars["Boolean"];
  recruiterCanManageAllTrainings: Scalars["Boolean"];
  recruiterCanManageCustomTemplates: Scalars["Boolean"];
  recruiterCanManageHiringTeams: Scalars["Boolean"];
  recruiterCanManageRedactions: Scalars["Boolean"];
  recruiterCanSeeOthersNotes: Scalars["Boolean"];
  recruiterCanSeePublicCalls: Scalars["Boolean"];
  recruiterCanSeeRestrictedCalls: Scalars["Boolean"];
  recruiterCanSeeScores: Scalars["Boolean"];
  recruiterCanShareRecordingsExternally: Scalars["Boolean"];
  recruiterCanViewInterviewerQualityReport: Scalars["Boolean"];
  siteAdminCanDownloadRecordings: Scalars["Boolean"];
};

export type RunSkillReport = {
  __typename?: "RunSkillReport";
  skillReport: SkillReport;
};

export type ScheduledInterview = {
  __typename?: "ScheduledInterview";
  adminForceRecord?: Maybe<Scalars["Boolean"]>;
  aiSummaryFormat?: Maybe<CallAiSummaryFormat>;
  apiShouldRecord?: Maybe<Scalars["Boolean"]>;
  ashbyFeedbackLink?: Maybe<Scalars["String"]>;
  ashbyInterviewScheduleId?: Maybe<Scalars["String"]>;
  callGuide?: Maybe<CallGuide>;
  callGuideId?: Maybe<Scalars["UUID"]>;
  canAddBrighthire?: Maybe<Scalars["Boolean"]>;
  canAddBrighthireReason?: Maybe<Scalars["String"]>;
  canLaunchMeeting?: Maybe<Scalars["Boolean"]>;
  canLaunchMeetingReason?: Maybe<Scalars["String"]>;
  candidate?: Maybe<Candidate>;
  candidateId?: Maybe<Scalars["UUID"]>;
  candidateOptOutReminderEmailSent: Scalars["Boolean"];
  completedMeeting?: Maybe<NotetakerMeeting>;
  createdAt: Scalars["DateTime"];
  createdById?: Maybe<Scalars["UUID"]>;
  customAtsId?: Maybe<Scalars["String"]>;
  customAtsStageId?: Maybe<Scalars["String"]>;
  customAtsStageName?: Maybe<Scalars["String"]>;
  customAtsUrl?: Maybe<Scalars["String"]>;
  durationMinutes?: Maybe<Scalars["Float"]>;
  ghApplicationId?: Maybe<Scalars["Float"]>;
  ghCandidateId?: Maybe<Scalars["Float"]>;
  ghInterviewKitId?: Maybe<Scalars["Float"]>;
  ghJobStageId?: Maybe<Scalars["Float"]>;
  ghJobStageName?: Maybe<Scalars["String"]>;
  googleMeetMeetingUrl?: Maybe<Scalars["String"]>;
  googleMeetMeetings: Array<GoogleMeetMeeting>;
  greenhouseInterviewId?: Maybe<Scalars["Float"]>;
  greenhouseLink?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  importDecision: Scalars["Boolean"];
  importDecisionReason?: Maybe<InterviewImportDecisionReason>;
  inProgressMeeting?: Maybe<NotetakerMeeting>;
  interviewType?: Maybe<InterviewType>;
  isExcluded: Scalars["Boolean"];
  isImported: Scalars["Boolean"];
  isInPersonInterview: Scalars["Boolean"];
  isInterviewer: Scalars["Boolean"];
  isUpcoming?: Maybe<Scalars["String"]>;
  isVideo: Scalars["Boolean"];
  joiningMeeting?: Maybe<NotetakerMeeting>;
  leverInterviewId?: Maybe<Scalars["String"]>;
  leverOpportunityId?: Maybe<Scalars["String"]>;
  leverStage?: Maybe<LeverStage>;
  leverStageId?: Maybe<Scalars["UUID"]>;
  mergeId?: Maybe<Scalars["String"]>;
  mergeJobInterviewStageId?: Maybe<Scalars["UUID"]>;
  mergeRemoteId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  optInUrl?: Maybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  pendingMeeting?: Maybe<NotetakerMeeting>;
  phoneNumber?: Maybe<Scalars["String"]>;
  position?: Maybe<Position>;
  positionId?: Maybe<Scalars["UUID"]>;
  recordingDisabled: Scalars["Boolean"];
  redactedAt?: Maybe<Scalars["DateTime"]>;
  scheduledEnd?: Maybe<Scalars["DateTime"]>;
  scheduledInterviewers: Array<ScheduledInterviewer>;
  scheduledStart?: Maybe<Scalars["DateTime"]>;
  scheduledType: ScheduledInterviewType;
  smartrecruitersApplicationId?: Maybe<Scalars["String"]>;
  smartrecruitersId?: Maybe<Scalars["String"]>;
  smartrecruitersInterviewLink?: Maybe<Scalars["String"]>;
  smartrecruitersInterviewSubStatus?: Maybe<Scalars["String"]>;
  smartrecruitersInterviewType?: Maybe<Scalars["String"]>;
  smartrecruitersTimeslotId?: Maybe<Scalars["String"]>;
  someoneOptedOut: Scalars["Boolean"];
  teamsMeetingUrl?: Maybe<Scalars["String"]>;
  teamsNotetakerMeetings: Array<TeamsNotetakerMeeting>;
  timezoneName?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  virtualOnsite?: Maybe<VirtualOnsite>;
  virtualOnsiteId?: Maybe<Scalars["UUID"]>;
  workableId?: Maybe<Scalars["String"]>;
  zoomAppAutoOpenEnabled: Scalars["Boolean"];
  zoomId?: Maybe<Scalars["Float"]>;
  zoomJoinUrl?: Maybe<Scalars["String"]>;
  zoomMeetingPassword?: Maybe<Scalars["String"]>;
  zoomMeetingUrl?: Maybe<Scalars["String"]>;
  zoomMeetings: Array<ZoomMeeting>;
};

export type ScheduledInterviewInput = {
  callGuideId?: InputMaybe<Scalars["ID"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  candidateFirstName?: InputMaybe<Scalars["String"]>;
  candidateId?: InputMaybe<Scalars["ID"]>;
  candidateLastName?: InputMaybe<Scalars["String"]>;
  durationMinutes: Scalars["Int"];
  googleMeetMeetingUrl?: InputMaybe<Scalars["String"]>;
  interviewerIds: Array<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  positionId?: InputMaybe<Scalars["ID"]>;
  scheduledStartTime: Scalars["DateTime"];
  teamsMeetingUrl?: InputMaybe<Scalars["String"]>;
  timezoneName: Scalars["String"];
  zoomJoinUrl?: InputMaybe<Scalars["String"]>;
};

export enum ScheduledInterviewType {
  Api = "API",
  Calendar = "CALENDAR",
  ChromeExtension = "CHROME_EXTENSION",
  Email = "EMAIL",
  IntegrationSearch = "INTEGRATION_SEARCH",
  Manual = "MANUAL",
}

export type ScheduledInterviewer = {
  __typename?: "ScheduledInterviewer";
  callGuideId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  createdBy: ScheduledInterviewerCreatedBy;
  customAtsId?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  ghCompletedScorecardId?: Maybe<Scalars["Float"]>;
  greenhouseId?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  leverId?: Maybe<Scalars["String"]>;
  mergeId?: Maybe<Scalars["String"]>;
  mergeRemoteId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  recordingDisabled: Scalars["Boolean"];
  scheduledInterview: ScheduledInterview;
  scheduledInterviewId: Scalars["UUID"];
  smartrecruitersId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["UUID"]>;
};

export enum ScheduledInterviewerCreatedBy {
  Ats = "ATS",
  Calendar = "CALENDAR",
  Email = "EMAIL",
  Manual = "MANUAL",
  Mutation = "MUTATION",
}

export type ScheduledInterviewerOptOut = {
  __typename?: "ScheduledInterviewerOptOut";
  scheduledInterview: ScheduledInterview;
};

export type Scorecard = {
  __typename?: "Scorecard";
  callGuideId?: Maybe<Scalars["UUID"]>;
  competencies: Array<ScorecardItem>;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  interviewDate: Scalars["DateTime"];
  interviewName?: Maybe<Scalars["String"]>;
  interviewer: User;
  items: Array<ScorecardItem>;
  organizationId: Scalars["UUID"];
  overallNotes?: Maybe<Scalars["String"]>;
  overallScore?: Maybe<Scalars["Int"]>;
  overallScoringEnabled: Scalars["Boolean"];
  questions: Array<ScorecardItem>;
  updatedAt: Scalars["DateTime"];
};

export type ScorecardCompetenciesArgs = {
  scoringEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type ScorecardItem = {
  __typename?: "ScorecardItem";
  childItems: Array<ScorecardItem>;
  id: Scalars["ID"];
  isAtsSingleLineInput: Scalars["Boolean"];
  isHtmlType?: Maybe<Scalars["Boolean"]>;
  itemPlainText: Scalars["String"];
  itemText: Scalars["String"];
  leverFieldType?: Maybe<LeverFeedbackTemplateFieldTypes>;
  marked?: Maybe<Scalars["Boolean"]>;
  markedTime?: Maybe<Scalars["Float"]>;
  notes?: Maybe<Scalars["String"]>;
  parentCompetencyId?: Maybe<Scalars["ID"]>;
  position: Scalars["Int"];
  questionNotes: Array<CallNote>;
  score?: Maybe<Scalars["Int"]>;
  scoringEnabled?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<GuideItemType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ScorecardItemQuestionNotesArgs = {
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type SendBeforeCallTextMessage = {
  __typename?: "SendBeforeCallTextMessage";
  textMessage?: Maybe<TextMessage>;
};

export type SendCallBackTextMessage = {
  __typename?: "SendCallBackTextMessage";
  textMessage?: Maybe<TextMessage>;
};

export type SendCandidateEmailVerification = {
  __typename?: "SendCandidateEmailVerification";
  success: Scalars["Boolean"];
};

/**
 * Resends an existing invitation by the current user if one exists, otherwise creates
 * and sends a new one.
 */
export type SendInvite = {
  __typename?: "SendInvite";
  sent: Scalars["Boolean"];
};

export type SenseConfiguration = {
  __typename?: "SenseConfiguration";
  apiKey?: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
};

export type SetClientMemberships = {
  __typename?: "SetClientMemberships";
  users?: Maybe<Array<User>>;
};

export type SetClientMembershipsInput = {
  clientId: Scalars["ID"];
  positionRoleId?: InputMaybe<Scalars["ID"]>;
  userId: Scalars["ID"];
};

export type SetMemberships = {
  __typename?: "SetMemberships";
  positions?: Maybe<Array<Position>>;
  users?: Maybe<Array<User>>;
};

export type SetMembershipsInput = {
  positionId: Scalars["ID"];
  positionRoleId?: InputMaybe<Scalars["ID"]>;
  userId: Scalars["ID"];
};

export type SetPlanUserRoles = {
  __typename?: "SetPlanUserRoles";
  users?: Maybe<Array<User>>;
};

export type SetPlanUserRolesInput = {
  planUserRoleId?: InputMaybe<Scalars["ID"]>;
  userId: Scalars["ID"];
};

export type SetUserRoles = {
  __typename?: "SetUserRoles";
  users?: Maybe<Array<User>>;
};

export type SetUserRolesInput = {
  userId: Scalars["ID"];
  userRoleId: Scalars["ID"];
};

export type SetupGreenhouse = {
  __typename?: "SetupGreenhouse";
  setup: GreenhouseSetup;
};

export type SetupGreenhouseWizard = {
  __typename?: "SetupGreenhouseWizard";
  setup: GreenhouseSetup;
};

export type SetupIcims = {
  __typename?: "SetupIcims";
  setup: IcimsSetup;
};

export type SetupLever = {
  __typename?: "SetupLever";
  leverSetup: LeverSetup;
};

export type SetupSense = {
  __typename?: "SetupSense";
  setup: SenseConfiguration;
};

export type SetupSingleSignOn = {
  __typename?: "SetupSingleSignOn";
  setup: SingleSignOnSetup;
};

export type ShareAnalyticsReport = {
  __typename?: "ShareAnalyticsReport";
  success: Scalars["Boolean"];
};

export type ShareCall = {
  __typename?: "ShareCall";
  call: Call;
};

export type ShareCallExternally = {
  __typename?: "ShareCallExternally";
  call: Call;
};

export type ShareClip = {
  __typename?: "ShareClip";
  clip: Clip;
};

export type ShareClipExternally = {
  __typename?: "ShareClipExternally";
  clip: Clip;
};

export type SharePlaylist = {
  __typename?: "SharePlaylist";
  playlist: Playlist;
};

export type SharePlaylistExternally = {
  __typename?: "SharePlaylistExternally";
  playlist: Playlist;
};

export enum ShowJoinWithBrighthireStatus {
  Hide = "HIDE",
  Show = "SHOW",
}

export type SignInByEmail = {
  __typename?: "SignInByEmail";
  verificationSent: Scalars["Boolean"];
};

export type SignInByPhone = {
  __typename?: "SignInByPhone";
  verificationSent: Scalars["Boolean"];
};

export type SignOut = {
  __typename?: "SignOut";
  isAuthenticated: Scalars["Boolean"];
};

export type SingleSignOnSetup = {
  __typename?: "SingleSignOnSetup";
  metadataUrl?: Maybe<Scalars["String"]>;
  oktaDomain?: Maybe<Scalars["String"]>;
  scimToken?: Maybe<Scalars["String"]>;
};

export type SkillDescriptionQueryResult = {
  __typename?: "SkillDescriptionQueryResult";
  description: Scalars["String"];
};

export type SkillReport = {
  __typename?: "SkillReport";
  createdAt: Scalars["DateTime"];
  creatorId: Scalars["UUID"];
  dateRangeEnd: Scalars["DateTime"];
  dateRangeStart: Scalars["DateTime"];
  id: Scalars["UUID"];
  interviewStageFilters?: Maybe<Scalars["JSONString"]>;
  minimumInterviewCountFilter?: Maybe<Scalars["Int"]>;
  organizationId: Scalars["UUID"];
  positions: Array<Position>;
  processingCompletedAt?: Maybe<Scalars["DateTime"]>;
  processingStartedAt?: Maybe<Scalars["DateTime"]>;
  processingStatus?: Maybe<SkillReportProcessingStatus>;
  results: Array<SkillReportResultItem>;
  skills: Array<SkillReportSkill>;
  source: SkillReportSkillsSource;
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export enum SkillReportProcessingStatus {
  Completed = "COMPLETED",
  Dispatched = "DISPATCHED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type SkillReportResultItem = {
  __typename?: "SkillReportResultItem";
  candidateId: Scalars["UUID"];
  candidateName: Scalars["String"];
  coverage?: Maybe<Scalars["JSONString"]>;
  id: Scalars["UUID"];
  numCalls: Scalars["Int"];
  organizationId: Scalars["UUID"];
  reportId: Scalars["UUID"];
};

export type SkillReportSkill = {
  __typename?: "SkillReportSkill";
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  organizationId: Scalars["UUID"];
  reportId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export type SkillReportSkillInput = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export enum SkillReportSkillsSource {
  AtsJobDescription = "ATS_JOB_DESCRIPTION",
  User = "USER",
}

export type SmartrecruitersAuthOnRedirect = {
  __typename?: "SmartrecruitersAuthOnRedirect";
  setup: SmartrecruitersSetup;
};

export enum SmartrecruitersAuthType {
  ApiKey = "API_KEY",
  Oauth = "OAUTH",
}

export type SmartrecruitersSetup = {
  __typename?: "SmartrecruitersSetup";
  authType?: Maybe<SmartrecruitersAuthType>;
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  valid: Scalars["Boolean"];
};

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

/**
 * Start a meeting that's part of a Virtual Onsite.
 * If the onsite is in progress, split the current meeting.
 */
export type StartVirtualOnsiteBotMeeting = {
  __typename?: "StartVirtualOnsiteBotMeeting";
  meeting: BotMeeting;
};

export enum SupportedTranscriptLanguage {
  Bulgarian = "BULGARIAN",
  Catalan = "CATALAN",
  Croatian = "CROATIAN",
  Czech = "CZECH",
  Danish = "DANISH",
  Dutch = "DUTCH",
  English = "ENGLISH",
  EnglishSpanishMix = "ENGLISH_SPANISH_MIX",
  Estonian = "ESTONIAN",
  Finnish = "FINNISH",
  French = "FRENCH",
  German = "GERMAN",
  Greek = "GREEK",
  Hebrew = "HEBREW",
  Hindi = "HINDI",
  Hungarian = "HUNGARIAN",
  Indonesian = "INDONESIAN",
  Italian = "ITALIAN",
  Japanese = "JAPANESE",
  Korean = "KOREAN",
  Latvian = "LATVIAN",
  Lithuanian = "LITHUANIAN",
  Malay = "MALAY",
  Mandarin = "MANDARIN",
  Norwegian = "NORWEGIAN",
  Polish = "POLISH",
  Portuguese = "PORTUGUESE",
  Romanian = "ROMANIAN",
  Russian = "RUSSIAN",
  Slovak = "SLOVAK",
  Slovenian = "SLOVENIAN",
  Spanish = "SPANISH",
  Swedish = "SWEDISH",
  Tamil = "TAMIL",
  Telugu = "TELUGU",
  Turkish = "TURKISH",
}

export enum TagsProcessingStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type TalemetryCandidateParams = {
  __typename?: "TalemetryCandidateParams";
  params?: Maybe<CallParams>;
};

export type TaskDefinition = {
  __typename?: "TaskDefinition";
  configuration: Scalars["JSONString"];
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  organizationId: Scalars["UUID"];
  type: TaskDefinitionType;
  updatedAt: Scalars["DateTime"];
};

export enum TaskDefinitionType {
  Coaching = "COACHING",
  CustomAgent = "CUSTOM_AGENT",
}

export type TaskRun = {
  __typename?: "TaskRun";
  completedAt?: Maybe<Scalars["DateTime"]>;
  configurationSnapshot: Scalars["JSONString"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  input: Scalars["JSONString"];
  organizationId: Scalars["UUID"];
  output: Scalars["JSONString"];
  runBy?: Maybe<Scalars["UUID"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<TaskRunStatus>;
  statusMessage?: Maybe<Scalars["String"]>;
  taskDefinitionId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export enum TaskRunStatus {
  Completed = "COMPLETED",
  CompletedPreview = "COMPLETED_PREVIEW",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type TeamsNotetakerMeeting = {
  __typename?: "TeamsNotetakerMeeting";
  autoRecordingSetting?: Maybe<Scalars["String"]>;
  calendarEventId?: Maybe<Scalars["UUID"]>;
  call?: Maybe<Call>;
  callId?: Maybe<Scalars["UUID"]>;
  canBeImported?: Maybe<Scalars["Boolean"]>;
  canNotBeImportedReason?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  emailEventId?: Maybe<Scalars["UUID"]>;
  endedReason?: Maybe<BotMeetingEndedReason>;
  errorType?: Maybe<BotMeetingErrorType>;
  exclude: Scalars["Boolean"];
  excludeReason?: Maybe<ExcludeReason>;
  id: Scalars["String"];
  importMeeting: Scalars["Boolean"];
  importReason: InterviewImportDecisionReason;
  joinTime?: Maybe<Scalars["DateTime"]>;
  leftTime?: Maybe<Scalars["DateTime"]>;
  meetingPassword?: Maybe<Scalars["String"]>;
  meetingUrl?: Maybe<Scalars["String"]>;
  nextVirtualOnsiteMeetingId?: Maybe<Scalars["UUID"]>;
  organizationId: Scalars["UUID"];
  participants: Array<TeamsNotetakerMeetingParticipant>;
  platform: BotMeetingPlatform;
  platformMeetingId?: Maybe<Scalars["String"]>;
  platformMeetingInstanceId?: Maybe<Scalars["String"]>;
  previousRunningMeetingId?: Maybe<Scalars["UUID"]>;
  recallRecordingId?: Maybe<Scalars["String"]>;
  recordingEndTime?: Maybe<Scalars["DateTime"]>;
  recordingStartTime?: Maybe<Scalars["DateTime"]>;
  recordingStarted: Scalars["Boolean"];
  scheduledEndTime: Scalars["DateTime"];
  scheduledStartTime: Scalars["DateTime"];
  status: BotMeetingStatus;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  virtualOnsite?: Maybe<VirtualOnsite>;
  virtualOnsiteId?: Maybe<Scalars["UUID"]>;
  waitingRoomJoinTime?: Maybe<Scalars["DateTime"]>;
};

export type TeamsNotetakerMeetingParticipant = {
  __typename?: "TeamsNotetakerMeetingParticipant";
  botMeetingId: Scalars["UUID"];
  callSpeakerOptionId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  identificationMethod?: Maybe<MeetingParticipantIdentificationMethod>;
  identificationSource?: Maybe<MeetingParticipantIdentificationSource>;
  identifiedAs?: Maybe<Scalars["String"]>;
  identifiedDuringInterview?: Maybe<Scalars["Boolean"]>;
  inMeeting: Scalars["Boolean"];
  isCandidate: Scalars["Boolean"];
  name: Scalars["String"];
  recallParticipantId?: Maybe<Scalars["Float"]>;
  scheduledInterviewerId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["DateTime"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["UUID"]>;
  zoomAppChatMessageSent: Scalars["Boolean"];
  zoomConfUserId?: Maybe<Scalars["String"]>;
  zoomUserId?: Maybe<Scalars["Float"]>;
};

export type TextMessage = {
  __typename?: "TextMessage";
  candidateId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  errorCode?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  messageSid: Scalars["String"];
  messageStatus: TextMessageStatus;
  phoneNumber?: Maybe<Scalars["String"]>;
  sentBySense: Scalars["Boolean"];
  sentFromPhoneNumber?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  userId: Scalars["UUID"];
};

export enum TextMessageStatus {
  Delivered = "DELIVERED",
  Failed = "FAILED",
  Initiated = "INITIATED",
  Queued = "QUEUED",
  Sent = "SENT",
  Undelivered = "UNDELIVERED",
}

export type TextMessageTemplate = {
  __typename?: "TextMessageTemplate";
  content: Scalars["String"];
  id?: Maybe<Scalars["ID"]>;
  templateType: TextMessageTemplateType;
};

export enum TextMessageTemplateType {
  CallBack = "CALL_BACK",
  TextBeforeCall = "TEXT_BEFORE_CALL",
}

export type Thumbnail = {
  __typename?: "Thumbnail";
  blobName: Scalars["String"];
  bucketName: Scalars["String"];
  callId: Scalars["UUID"];
  callNoteId?: Maybe<Scalars["UUID"]>;
  clipId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  dimensions: Scalars["String"];
  format: ThumbnailFormat;
  height: Scalars["Int"];
  id: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  uploaded: Scalars["Boolean"];
  width: Scalars["Int"];
};

export enum ThumbnailFormat {
  Png = "PNG",
}

export type Timezone = {
  __typename?: "Timezone";
  abbrev: Scalars["String"];
  isDst: Scalars["Boolean"];
  name: Scalars["String"];
  utcOffset: Scalars["String"];
};

export type TopicCounts = {
  __typename?: "TopicCounts";
  organizationId: Scalars["String"];
  topicDiscussed?: Maybe<Scalars["Float"]>;
  totalCalls?: Maybe<Scalars["Int"]>;
  xLabel: Scalars["String"];
};

export type TopicCountsResult = {
  __typename?: "TopicCountsResult";
  bucketInterval: Scalars["String"];
  bucketSize: Scalars["Int"];
  data: Array<TopicCounts>;
  id: Scalars["ID"];
};

export type TopicOccurrences = {
  __typename?: "TopicOccurrences";
  callId: Scalars["String"];
  callName: Scalars["String"];
  callStartTime: Scalars["String"];
  segmentId: Scalars["String"];
  segmentStartTime: Scalars["Int"];
  segmentText: Scalars["String"];
  speakerLabel: Scalars["String"];
  speakerType: Scalars["String"];
};

export type TopicOccurrencesResult = {
  __typename?: "TopicOccurrencesResult";
  data: Array<TopicOccurrences>;
  id: Scalars["ID"];
};

export type TopicSummaries = {
  __typename?: "TopicSummaries";
  summary: Array<Maybe<TopicSummaryItem>>;
  topic: Scalars["String"];
};

export type TopicSummaryItem = {
  __typename?: "TopicSummaryItem";
  id: Scalars["String"];
  startTime: Scalars["Int"];
  text: Scalars["String"];
  topic: Scalars["String"];
};

export type TrackerGroup = {
  __typename?: "TrackerGroup";
  count?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  isDefault: Scalars["Boolean"];
  isVisible: Scalars["Boolean"];
  name: Scalars["String"];
  organizationId?: Maybe<Scalars["UUID"]>;
  trackerKeywords: Array<TrackerKeyword>;
  uiOrder: Scalars["Float"];
  updatedAt: Scalars["DateTime"];
};

export type TrackerKeyword = {
  __typename?: "TrackerKeyword";
  count?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  groupId: Scalars["UUID"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  speakerCounts: Array<TrackerSpeakerCount>;
  updatedAt: Scalars["DateTime"];
};

export type TrackerSegment = {
  __typename?: "TrackerSegment";
  keyword?: Maybe<Scalars["String"]>;
  segments: Array<TranscriptSegment>;
};

export type TrackerSpeakerCount = {
  __typename?: "TrackerSpeakerCount";
  count: Scalars["Int"];
  speakerTag: Scalars["Int"];
};

export type TrainingItemQuestionAndAnswer = {
  __typename?: "TrainingItemQuestionAndAnswer";
  answer?: Maybe<Scalars["String"]>;
  order: Scalars["Int"];
  question: Scalars["String"];
  questionId: Scalars["String"];
};

export type TrainingItemStatus = {
  __typename?: "TrainingItemStatus";
  contentRedactedAt?: Maybe<Scalars["String"]>;
  deletedQuestionsAndAnswers: Array<TrainingItemQuestionAndAnswer>;
  itemId: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  questionsAndAnswers: Array<TrainingItemQuestionAndAnswer>;
  url: Scalars["String"];
  viewed: Scalars["Boolean"];
};

export type TrainingProgram = {
  __typename?: "TrainingProgram";
  assessmentEnabled: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["UUID"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  launchedAt?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  organizationId: Scalars["UUID"];
  owner: User;
  totalDuration?: Maybe<Scalars["Float"]>;
  trainableUsers: Array<User>;
  trainingProgramDeletedQuestions: Array<TrainingProgramQuestion>;
  trainingProgramItems: Array<TrainingProgramItem>;
  trainingProgramQuestions: Array<TrainingProgramQuestion>;
  trainingProgramTrainees: Array<TrainingProgramTrainee>;
  updatedAt: Scalars["DateTime"];
};

export type TrainingProgramAnswer = {
  __typename?: "TrainingProgramAnswer";
  answer: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  trainingProgramItemId: Scalars["UUID"];
  trainingProgramQuestionId: Scalars["UUID"];
  trainingProgramTraineeId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  userId: Scalars["UUID"];
};

export type TrainingProgramAnswerInput = {
  answer: Scalars["String"];
  itemId: Scalars["ID"];
  questionId: Scalars["ID"];
};

export type TrainingProgramItem = {
  __typename?: "TrainingProgramItem";
  addedBy: Scalars["UUID"];
  assessmentCompleted?: Maybe<Scalars["Boolean"]>;
  assessmentOutdated?: Maybe<Scalars["Boolean"]>;
  audioOnly: Scalars["Boolean"];
  callId: Scalars["UUID"];
  callTimestamp?: Maybe<Scalars["Int"]>;
  clipId?: Maybe<Scalars["UUID"]>;
  contentCreatedAt: Scalars["DateTime"];
  contentRedactedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  duration: Scalars["Int"];
  hasRecordings: Scalars["Boolean"];
  id: Scalars["String"];
  interviewers: Array<User>;
  isClip: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  organizationId: Scalars["UUID"];
  positionDisplayTitle?: Maybe<Scalars["String"]>;
  thumbnailImageScaleX?: Maybe<Scalars["Float"]>;
  thumbnailImageUrl?: Maybe<Scalars["String"]>;
  trainingProgramId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  url: Scalars["String"];
  viewed?: Maybe<Scalars["Boolean"]>;
};

export type TrainingProgramItemPreview = {
  __typename?: "TrainingProgramItemPreview";
  assessmentCompleted?: Maybe<Scalars["Boolean"]>;
  assessmentOutdated?: Maybe<Scalars["Boolean"]>;
  audioOnly: Scalars["Boolean"];
  contentCreatedAt: Scalars["DateTime"];
  contentRedactedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  duration: Scalars["Int"];
  hasRecordings: Scalars["Boolean"];
  id: Scalars["String"];
  interviewers: Array<User>;
  isClip: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  positionDisplayTitle?: Maybe<Scalars["String"]>;
  thumbnailImageScaleX?: Maybe<Scalars["Float"]>;
  thumbnailImageUrl?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
  viewed?: Maybe<Scalars["Boolean"]>;
};

export type TrainingProgramItemView = {
  __typename?: "TrainingProgramItemView";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  questionAnswer?: Maybe<Scalars["String"]>;
  trainingProgramItem: TrainingProgramItem;
  trainingProgramItemId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  userId: Scalars["UUID"];
};

export type TrainingProgramManagementListItem = {
  __typename?: "TrainingProgramManagementListItem";
  createdAt: Scalars["String"];
  draft: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  owner: Scalars["String"];
  trainees: Scalars["Int"];
};

export type TrainingProgramQuestion = {
  __typename?: "TrainingProgramQuestion";
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["UUID"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["UUID"];
  order: Scalars["Int"];
  organizationId: Scalars["UUID"];
  question: Scalars["String"];
  trainingProgramId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export type TrainingProgramTrainee = {
  __typename?: "TrainingProgramTrainee";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  itemStatuses: Array<TrainingItemStatus>;
  markedCompletedAt?: Maybe<Scalars["DateTime"]>;
  markedCompletedBy?: Maybe<Scalars["UUID"]>;
  organizationId: Scalars["UUID"];
  trainingProgram: TrainingProgram;
  trainingProgramAnswers: Array<TrainingProgramAnswer>;
  trainingProgramId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  user: User;
  userId: Scalars["UUID"];
};

export type TrainingProgramTraineeListItem = {
  __typename?: "TrainingProgramTraineeListItem";
  completed: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type TranscriptSegment = {
  __typename?: "TranscriptSegment";
  endTime: Scalars["Float"];
  id: Scalars["UUID"];
  languageCode?: Maybe<Scalars["String"]>;
  rawText?: Maybe<Scalars["String"]>;
  rawWords?: Maybe<Scalars["JSONString"]>;
  speakerTag: Scalars["Int"];
  startTime: Scalars["Float"];
  text: Scalars["String"];
  transcriptId: Scalars["UUID"];
  words: Array<TranscriptWord>;
};

export type TranscriptWord = {
  __typename?: "TranscriptWord";
  confidence?: Maybe<Scalars["Float"]>;
  endTime: Scalars["Float"];
  isRedacted?: Maybe<Scalars["Boolean"]>;
  rawWord?: Maybe<Scalars["String"]>;
  startTime: Scalars["Float"];
  word: Scalars["String"];
};

export enum TranscriptionStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Processing = "PROCESSING",
}

export type Unimpersonate = {
  __typename?: "Unimpersonate";
  currentUser: User;
  redirectTo: Scalars["String"];
};

export type UnkeepCall = {
  __typename?: "UnkeepCall";
  call: Call;
  success: Scalars["Boolean"];
};

export type UnkeepCandidate = {
  __typename?: "UnkeepCandidate";
  candidate: Candidate;
  success: Scalars["Boolean"];
};

export type UnmarkScorecardQuestion = {
  __typename?: "UnmarkScorecardQuestion";
  scorecardItem: ScorecardItem;
};

export type UpdateAiAnswer = {
  __typename?: "UpdateAiAnswer";
  answerItem: AnswerItem;
  success: Scalars["Boolean"];
};

export type UpdateAiQuestion = {
  __typename?: "UpdateAiQuestion";
  callAiNote: QuestionAndAnswer;
  success: Scalars["Boolean"];
};

export type UpdateAiSummaryTemplate = {
  __typename?: "UpdateAiSummaryTemplate";
  template?: Maybe<CallAiSummaryTemplate>;
  validationError?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type UpdateAnalyticsQualityReportConfig = {
  __typename?: "UpdateAnalyticsQualityReportConfig";
  reportConfig: AnalyticsQualityReportConfig;
};

export type UpdateApiToken = {
  __typename?: "UpdateApiToken";
  setup: RestApiSetup;
};

export type UpdateAutoJoinAllNewPositions = {
  __typename?: "UpdateAutoJoinAllNewPositions";
  organization: Organization;
  positions: Array<Position>;
};

export type UpdateAutoJoinExcludedUsers = {
  __typename?: "UpdateAutoJoinExcludedUsers";
  currentUser: User;
};

export type UpdateCalendarOrgSettings = {
  __typename?: "UpdateCalendarOrgSettings";
  currentUser: User;
};

export type UpdateCalendarSettings = {
  __typename?: "UpdateCalendarSettings";
  currentUser: User;
};

export type UpdateCall = {
  __typename?: "UpdateCall";
  call: Call;
};

export type UpdateCallAiSummaryFormat = {
  __typename?: "UpdateCallAISummaryFormat";
  summary?: Maybe<CallAiSummary>;
};

export type UpdateCallCandidate = {
  __typename?: "UpdateCallCandidate";
  call: Call;
};

export type UpdateCallGuide = {
  __typename?: "UpdateCallGuide";
  callGuide: CallGuide;
};

export type UpdateCallGuideAssignedUsers = {
  __typename?: "UpdateCallGuideAssignedUsers";
  callGuide: CallGuide;
};

export type UpdateCallGuideCue = {
  __typename?: "UpdateCallGuideCue";
  callGuideCue: CallGuideCue;
};

export type UpdateCallGuides = {
  __typename?: "UpdateCallGuides";
  callGuides?: Maybe<Array<Maybe<CallGuide>>>;
};

export type UpdateCallNote = {
  __typename?: "UpdateCallNote";
  callNote: CallNote;
};

export type UpdateCallPosition = {
  __typename?: "UpdateCallPosition";
  call: Call;
};

export type UpdateCallSpeaker = {
  __typename?: "UpdateCallSpeaker";
  call: Call;
};

export type UpdateCandidate = {
  __typename?: "UpdateCandidate";
  candidate: Candidate;
};

export type UpdateCandidateAlertAccuracyFeedback = {
  __typename?: "UpdateCandidateAlertAccuracyFeedback";
  feedback: CandidateAlertAccuracyFeedback;
  updatedIds: Array<Scalars["ID"]>;
};

export type UpdateCandidateEmailOptOut = {
  __typename?: "UpdateCandidateEmailOptOut";
  candidateEmailOptOut: CandidateEmailOptOut;
};

export type UpdateCandidateOptOut = {
  __typename?: "UpdateCandidateOptOut";
  candidate: Candidate;
};

export type UpdateCandidateSummaryTemplate = {
  __typename?: "UpdateCandidateSummaryTemplate";
  template: CandidateSummaryTemplate;
};

export type UpdateClient = {
  __typename?: "UpdateClient";
  client: Client;
};

export type UpdateClip = {
  __typename?: "UpdateClip";
  clip: Clip;
};

export type UpdateCurrentUser = {
  __typename?: "UpdateCurrentUser";
  currentUser: User;
};

export type UpdateCurrentUserOnboardingStep = {
  __typename?: "UpdateCurrentUserOnboardingStep";
  currentUser: User;
};

export type UpdateCurrentUserShowActionItems = {
  __typename?: "UpdateCurrentUserShowActionItems";
  currentUser: User;
};

export type UpdateCurrentUserSubscriptionSettings = {
  __typename?: "UpdateCurrentUserSubscriptionSettings";
  currentUser: User;
};

export type UpdateCurrentUserViewedTranscriptionWarning = {
  __typename?: "UpdateCurrentUserViewedTranscriptionWarning";
  currentUser: User;
};

export type UpdateInterviewOptIn = {
  __typename?: "UpdateInterviewOptIn";
  success: Scalars["Boolean"];
};

export type UpdateLeverOauthIntegrationSettings = {
  __typename?: "UpdateLeverOauthIntegrationSettings";
  leverOauthSetup: LeverOauthSetup;
};

export type UpdateMyInterviewRecordingOptions = {
  __typename?: "UpdateMyInterviewRecordingOptions";
  currentUser: User;
};

export type UpdateOnboardingSteps = {
  status: OnboardingStepStatus;
  step: OnboardingStep;
};

export type UpdateOptInSetup = {
  __typename?: "UpdateOptInSetup";
  success: Scalars["Boolean"];
};

export type UpdateOrganization = {
  __typename?: "UpdateOrganization";
  currentUser: User;
  organization: Organization;
};

export type UpdateOrganizationAdvancedConfigurations = {
  __typename?: "UpdateOrganizationAdvancedConfigurations";
  currentUser: User;
};

export type UpdateOrganizationAiSettings = {
  __typename?: "UpdateOrganizationAiSettings";
  organization: Organization;
};

export type UpdateOrganizationAtsSettings = {
  __typename?: "UpdateOrganizationAtsSettings";
  currentUser: User;
};

export type UpdateOrganizationBeta = {
  __typename?: "UpdateOrganizationBeta";
  currentUser: User;
};

/** Settings around automatic data redaction of EEOC-protected information */
export type UpdateOrganizationComplianceSettings = {
  __typename?: "UpdateOrganizationComplianceSettings";
  organization: Organization;
};

export type UpdateOrganizationDataRetention = {
  __typename?: "UpdateOrganizationDataRetention";
  organization: Organization;
};

export type UpdateOrganizationGenderLabels = {
  __typename?: "UpdateOrganizationGenderLabels";
  organization: Organization;
};

export type UpdateOrganizationGoogleMeet = {
  __typename?: "UpdateOrganizationGoogleMeet";
  organization: Organization;
};

export type UpdateOrganizationInterviewPlanSettings = {
  __typename?: "UpdateOrganizationInterviewPlanSettings";
  organization: Organization;
};

export type UpdateOrganizationName = {
  __typename?: "UpdateOrganizationName";
  currentUser: User;
  organization: Organization;
};

export type UpdateOrganizationScoring = {
  __typename?: "UpdateOrganizationScoring";
  organization: Organization;
};

export type UpdateOrganizationTeamsNotetaker = {
  __typename?: "UpdateOrganizationTeamsNotetaker";
  organization: Organization;
};

export type UpdateOrganizationVideoSettings = {
  __typename?: "UpdateOrganizationVideoSettings";
  currentUser: User;
};

export type UpdateOrganizationZoomNotetaker = {
  __typename?: "UpdateOrganizationZoomNotetaker";
  organization: Organization;
};

export type UpdateOrganizationZoomSettings = {
  __typename?: "UpdateOrganizationZoomSettings";
  currentUser: User;
};

export type UpdatePlanJobDescriptionSettings = {
  __typename?: "UpdatePlanJobDescriptionSettings";
  organization: Organization;
  planCustomTemplate?: Maybe<JobDescriptionTemplate>;
};

export type UpdatePosition = {
  __typename?: "UpdatePosition";
  position: Position;
};

export type UpdatePositionsAutoJoin = {
  __typename?: "UpdatePositionsAutoJoin";
  positions: Array<Position>;
};

export type UpdateRolePolicies = {
  __typename?: "UpdateRolePolicies";
  currentUser: User;
  rolePolicies: RolePolicies;
};

export type UpdateScheduledInterview = {
  __typename?: "UpdateScheduledInterview";
  scheduledInterview?: Maybe<ScheduledInterview>;
};

export type UpdateScorecard = {
  __typename?: "UpdateScorecard";
  scorecard: Scorecard;
};

export type UpdateScorecardCompetency = {
  __typename?: "UpdateScorecardCompetency";
  scorecardItem: ScorecardItem;
};

export type UpdateSummaryNoteFeedback = {
  __typename?: "UpdateSummaryNoteFeedback";
  feedbackRecord: CandidateSummaryNoteFeedback;
};

export type UpdateTextMessageTemplate = {
  __typename?: "UpdateTextMessageTemplate";
  textMessageTemplate?: Maybe<TextMessageTemplate>;
};

export type UpdateTrainingProgramQuestion = {
  __typename?: "UpdateTrainingProgramQuestion";
  trainingProgram: TrainingProgram;
};

export type UpdateUserRole = {
  __typename?: "UpdateUserRole";
  user: User;
};

export type UpdateWebhook = {
  __typename?: "UpdateWebhook";
  setup: RestApiSetup;
};

export type UpdateZoomNotetakerMeetingId = {
  __typename?: "UpdateZoomNotetakerMeetingId";
  success?: Maybe<Scalars["Boolean"]>;
};

export type User = {
  __typename?: "User";
  assignedCallGuides: Array<CallGuide>;
  autofillGreenhouseScorecard: Scalars["Boolean"];
  browserExtensionVersion?: Maybe<Scalars["String"]>;
  bullhornId?: Maybe<Scalars["Int"]>;
  calendars: Array<Calendar>;
  callAiSummaryTemplates: Array<CallAiSummaryTemplate>;
  callCount: Scalars["Int"];
  callGuideAssignments: Array<CallGuideUser>;
  callGuides: Array<CallGuide>;
  callNoteSubscription: UserSubscriptionSettings;
  callShareSubscription: UserSubscriptionSettings;
  callTraining?: Maybe<CallTraining>;
  callingMetrics: UserCallingMetrics;
  calls: PaginatedCalls;
  callsSharedWithMe: PaginatedCallShares;
  canEditCandidateSummaryTemplate: Scalars["Boolean"];
  canViewCandidateSummaries: Scalars["Boolean"];
  candidates: PaginatedCandidates;
  clips: PaginatedClips;
  clipsSharedWithMe: PaginatedClipShares;
  createdAt: Scalars["DateTime"];
  customAtsId?: Maybe<Scalars["String"]>;
  defaultAiNoteFormat: CallAiSummaryFormat;
  defaultCallAiSummaryTemplateId?: Maybe<Scalars["UUID"]>;
  defaultCallType: CallType;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  deletedById?: Maybe<Scalars["UUID"]>;
  email: Scalars["String"];
  emailAliases: Scalars["String"];
  enabledFeatures: Array<Scalars["String"]>;
  eventImportKeywords: Array<EventImportKeyword>;
  events: Array<Event>;
  excludeFromAutoJoin: Scalars["Boolean"];
  firstName?: Maybe<Scalars["String"]>;
  freemiumSignUp: Scalars["Boolean"];
  fullName: Scalars["String"];
  googleScopes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  googleUserId?: Maybe<Scalars["String"]>;
  greenhouseDisabled?: Maybe<Scalars["Boolean"]>;
  greenhouseDisabledIds: Array<Maybe<Scalars["Float"]>>;
  greenhouseId?: Maybe<Scalars["Float"]>;
  hasGrantedGoogleCalendarEventScope: Scalars["Boolean"];
  hasGrantedGoogleCalendarScope: Scalars["Boolean"];
  hasViewedTranscriptionWarning: Scalars["Boolean"];
  icimsId?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  impersonatedFeatures: Array<Scalars["String"]>;
  intercomUserHash: Scalars["String"];
  internalUserRole?: Maybe<InternalUserRoleName>;
  internalUserRolePermissions: Array<Scalars["String"]>;
  internationalCallingEnabled: Scalars["Boolean"];
  interviewImportRule?: Maybe<UserInterviewImportRule>;
  interviewPrepSubscription: UserSubscriptionSettings;
  interviewRecapSubscription: UserSubscriptionSettings;
  invitedAt?: Maybe<Scalars["DateTime"]>;
  invitedBy?: Maybe<User>;
  invitedById?: Maybe<Scalars["UUID"]>;
  isCalendarAuthorized: Scalars["Boolean"];
  isGcalAuthorized: Scalars["Boolean"];
  isImpersonated: Scalars["Boolean"];
  isInternationalCallingEnabled: Scalars["Boolean"];
  isMsftAuthorized: Scalars["Boolean"];
  isMsftCalAuthorized: Scalars["Boolean"];
  isWhitelistedForAutoJoin: Scalars["Boolean"];
  isZoomAppInstalled: Scalars["Boolean"];
  lastCalledAt?: Maybe<Scalars["DateTime"]>;
  lastName?: Maybe<Scalars["String"]>;
  leverId?: Maybe<Scalars["String"]>;
  loginPermitted: Scalars["Boolean"];
  maskPersonalPhoneNumber: Scalars["Boolean"];
  memberships: Array<Membership>;
  monthlyAnalyticsSubscription: UserSubscriptionSettings;
  morningDigestSubscription: UserSubscriptionSettings;
  msftScopes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  msftUserId?: Maybe<Scalars["String"]>;
  numUnreadAlerts: Scalars["Int"];
  oktaUserId?: Maybe<Scalars["String"]>;
  onboardingSteps: Array<UserOnboardingStep>;
  organization: Organization;
  organizationId: Scalars["UUID"];
  persona?: Maybe<UserPersona>;
  phoneNumber?: Maybe<Scalars["String"]>;
  phoneNumberExtension?: Maybe<Scalars["String"]>;
  phoneNumberVerified: Scalars["Boolean"];
  planUserRole?: Maybe<PlanUserRole>;
  planUserRoleId?: Maybe<Scalars["UUID"]>;
  playlists: PaginatedPlaylists;
  playlistsSharedWithMe: PaginatedPlaylistShares;
  positions: PaginatedPositions;
  primaryAreaCode?: Maybe<Scalars["String"]>;
  profilePicUrl?: Maybe<Scalars["String"]>;
  recentCallGuides?: Maybe<Array<CallGuide>>;
  requireCalendarSync: Scalars["Boolean"];
  scheduledInterviews: PaginatedScheduledInterviews;
  scheduledInterviewsNotImported: Array<ScheduledInterview>;
  sharedCallGuides: Array<CallGuide>;
  shouldReauthorizeZoomApp: Scalars["Boolean"];
  showCandidateActionItemAlerts: Scalars["Boolean"];
  showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
  signUpCompletedAt?: Maybe<Scalars["DateTime"]>;
  signUpMethod: UserSignUpMethod;
  signUpMethodLabel: Scalars["String"];
  signUpStartedAt?: Maybe<Scalars["DateTime"]>;
  signUpState: Scalars["String"];
  slackId?: Maybe<Scalars["String"]>;
  smartrecruitersId?: Maybe<Scalars["String"]>;
  timezoneName: Scalars["String"];
  trainingProgramItemViews: Array<TrainingProgramItemView>;
  trainingProgramsCompletedCount: Scalars["Int"];
  trainingProgramsCreatedCount: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
  userRole?: Maybe<UserRole>;
  userRoleGrantedById?: Maybe<Scalars["UUID"]>;
  userRoleId?: Maybe<Scalars["UUID"]>;
  voipPhoneNumber?: Maybe<Scalars["String"]>;
  weeklyRecapSubscription: UserSubscriptionSettings;
  zoomAppAutoOpenEnabled?: Maybe<Scalars["Boolean"]>;
  zoomAppInstalledAt?: Maybe<Scalars["DateTime"]>;
  zoomAppScopes: Array<Scalars["String"]>;
  zoomDisplayName?: Maybe<Scalars["String"]>;
  zoomEmail?: Maybe<Scalars["String"]>;
  zoomFirstName?: Maybe<Scalars["String"]>;
  zoomId?: Maybe<Scalars["String"]>;
  zoomLastName?: Maybe<Scalars["String"]>;
  zoomSettings?: Maybe<Scalars["JSONString"]>;
  zoomUserType?: Maybe<Scalars["Int"]>;
};

export type UserCallAiSummaryTemplatesArgs = {
  additionalTemplateId?: InputMaybe<Scalars["ID"]>;
};

export type UserCallGuidesArgs = {
  defaultCallGuideId?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
};

export type UserCallTrainingArgs = {
  callId: Scalars["ID"];
  clipId?: InputMaybe<Scalars["ID"]>;
  trainingProgramId: Scalars["ID"];
};

export type UserCallingMetricsArgs = {
  end?: InputMaybe<Scalars["Date"]>;
  start: Scalars["Date"];
};

export type UserCallsArgs = {
  end?: InputMaybe<Scalars["DateTime"]>;
  pagination?: InputMaybe<CursorPaginationDetails>;
  positionId?: InputMaybe<Scalars["String"]>;
  start?: InputMaybe<Scalars["DateTime"]>;
};

export type UserCallsSharedWithMeArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type UserCandidatesArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type UserClipsArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type UserClipsSharedWithMeArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type UserPlaylistsArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type UserPlaylistsSharedWithMeArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type UserPositionsArgs = {
  pagination?: InputMaybe<OffsetPaginationDetails>;
};

export type UserRecentCallGuidesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
};

export type UserScheduledInterviewsArgs = {
  end?: InputMaybe<Scalars["DateTime"]>;
  pagination?: InputMaybe<CursorPaginationDetails>;
  positionId?: InputMaybe<Scalars["String"]>;
  start?: InputMaybe<Scalars["DateTime"]>;
};

export type UserScheduledInterviewsNotImportedArgs = {
  end?: InputMaybe<Scalars["DateTime"]>;
  positionId?: InputMaybe<Scalars["String"]>;
  start?: InputMaybe<Scalars["DateTime"]>;
};

export type UserSharedCallGuidesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
};

export type UserTrainingProgramItemViewsArgs = {
  trainingProgramId: Scalars["ID"];
};

/** User call metrics */
export type UserCallingMetrics = {
  __typename?: "UserCallingMetrics";
  callInteractivity?: Maybe<Scalars["Float"]>;
  candidateLongestMonologue?: Maybe<Scalars["Float"]>;
  candidateWordsPerMinute?: Maybe<Scalars["Float"]>;
  interviewerLongestMonologue?: Maybe<Scalars["Float"]>;
  interviewerTalkRatio?: Maybe<Scalars["Float"]>;
  interviewerWordsPerMinute?: Maybe<Scalars["Float"]>;
  numDials: Scalars["Int"];
  numSignificantCalls: Scalars["Int"];
  totalDuration: Scalars["Float"];
  weeklyCalls: Scalars["Float"];
  weeklyDuration: Scalars["Float"];
};

export enum UserInterviewImportRule {
  AllMyInterviews = "ALL_MY_INTERVIEWS",
  None = "NONE",
  OnlyInterviewerMyInterviews = "ONLY_INTERVIEWER_MY_INTERVIEWS",
}

export type UserOnboardingStep = {
  __typename?: "UserOnboardingStep";
  rule: OnboardingStepRule;
  status?: Maybe<OnboardingStepStatus>;
  step: OnboardingStep;
};

export enum UserPersona {
  CoordinatorOps = "COORDINATOR_OPS",
  HiringManager = "HIRING_MANAGER",
  Interviewer = "INTERVIEWER",
  Recruiter = "RECRUITER",
  Sourcer = "SOURCER",
  TaLeader = "TA_LEADER",
}

export type UserRole = {
  __typename?: "UserRole";
  canAccessPrivateCalls?: Maybe<Scalars["Boolean"]>;
  canAccessPublicCalls?: Maybe<Scalars["Boolean"]>;
  canAccessRestrictedCalls?: Maybe<Scalars["Boolean"]>;
  canCreatePosition?: Maybe<Scalars["Boolean"]>;
  canCreateTrainings?: Maybe<Scalars["Boolean"]>;
  canDeleteCall?: Maybe<Scalars["Boolean"]>;
  canDeleteCandidates?: Maybe<Scalars["Boolean"]>;
  canManageAllTrainings?: Maybe<Scalars["Boolean"]>;
  canManageCustomTemplates?: Maybe<Scalars["Boolean"]>;
  canManageHiringTeam?: Maybe<Scalars["Boolean"]>;
  canManageIQReport?: Maybe<Scalars["Boolean"]>;
  canManageIntegrationSettings?: Maybe<Scalars["Boolean"]>;
  canManageOrganizationSettings?: Maybe<Scalars["Boolean"]>;
  canManageUserRoles?: Maybe<Scalars["Boolean"]>;
  canViewInsights?: Maybe<Scalars["Boolean"]>;
  canViewInsightsAlerts?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["DateTime"];
  formattedName?: Maybe<Scalars["String"]>;
  formattedPermissions?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: UserRoleName;
  organizationId: Scalars["UUID"];
  permissions: Array<UserRolePermission>;
  updatedAt: Scalars["DateTime"];
};

export enum UserRoleName {
  Basic = "BASIC",
  Recruiter = "RECRUITER",
  SiteAdmin = "SITE_ADMIN",
}

export enum UserRolePermission {
  AccessAllPrivateCalls = "ACCESS_ALL_PRIVATE_CALLS",
  AccessAllPublicCalls = "ACCESS_ALL_PUBLIC_CALLS",
  AccessAllRestrictedCalls = "ACCESS_ALL_RESTRICTED_CALLS",
  AccessOthersNotes = "ACCESS_OTHERS_NOTES",
  CreatePosition = "CREATE_POSITION",
  CreateTrainings = "CREATE_TRAININGS",
  DeleteCall = "DELETE_CALL",
  DeleteCandidate = "DELETE_CANDIDATE",
  DownloadRecording = "DOWNLOAD_RECORDING",
  EditAiTaskConfiguration = "EDIT_AI_TASK_CONFIGURATION",
  ManageAllPlaylists = "MANAGE_ALL_PLAYLISTS",
  ManageAllRedactions = "MANAGE_ALL_REDACTIONS",
  ManageAllScores = "MANAGE_ALL_SCORES",
  ManageAllTrainings = "MANAGE_ALL_TRAININGS",
  ManageCustomTemplates = "MANAGE_CUSTOM_TEMPLATES",
  ManageHiringTeam = "MANAGE_HIRING_TEAM",
  ManageIntegrationSettings = "MANAGE_INTEGRATION_SETTINGS",
  ManageInterviewerQualityReport = "MANAGE_INTERVIEWER_QUALITY_REPORT",
  ManageOrganizationSettings = "MANAGE_ORGANIZATION_SETTINGS",
  ManageUserRoles = "MANAGE_USER_ROLES",
  ShareRecordingsExternally = "SHARE_RECORDINGS_EXTERNALLY",
  SubscribesToAlerts = "SUBSCRIBES_TO_ALERTS",
  ViewInterviewerQualityReport = "VIEW_INTERVIEWER_QUALITY_REPORT",
}

export enum UserSignUpMethod {
  Api = "API",
  Invitation = "INVITATION",
  Okta = "OKTA",
  Onboarding = "ONBOARDING",
  ScheduledInterview = "SCHEDULED_INTERVIEW",
  SelfSignUp = "SELF_SIGN_UP",
  Zoom = "ZOOM",
}

export enum UserSubscriptionSettings {
  All = "ALL",
  Email = "EMAIL",
  None = "NONE",
  Slack = "SLACK",
}

export type VerifyEmailSignIn = {
  __typename?: "VerifyEmailSignIn";
  currentUser: User;
};

export type VerifyExternalUserAccessCode = {
  __typename?: "VerifyExternalUserAccessCode";
  currentUser: ExternalUser;
};

/** https://support.twilio.com/hc/en-us/articles/223180048 */
export type VerifyPhoneNumber = {
  __typename?: "VerifyPhoneNumber";
  validationCode?: Maybe<Scalars["String"]>;
  verified: Scalars["Boolean"];
};

export type VerifyPhoneSignIn = {
  __typename?: "VerifyPhoneSignIn";
  currentUser: User;
};

export type Video = {
  __typename?: "Video";
  url: Scalars["String"];
};

export type VideoInput = {
  url: Scalars["String"];
};

export type ViewCall = {
  __typename?: "ViewCall";
  callViewed: Scalars["Boolean"];
};

export type ViewCallScorecard = {
  __typename?: "ViewCallScorecard";
  scorecardViewed: Scalars["Boolean"];
};

export type ViewCandidate = {
  __typename?: "ViewCandidate";
  candidateViewed: Scalars["Boolean"];
};

export type ViewClip = {
  __typename?: "ViewClip";
  clipViewed: Scalars["Boolean"];
};

export type ViewHighlight = {
  __typename?: "ViewHighlight";
  viewed: Scalars["Boolean"];
};

export type ViewRedactionPrediction = {
  __typename?: "ViewRedactionPrediction";
  redactionPrediction: RedactionPrediction;
};

export type VirtualOnsite = {
  __typename?: "VirtualOnsite";
  createdAt: Scalars["DateTime"];
  endTime?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  inProgressScheduledInterview?: Maybe<ScheduledInterview>;
  isScheduledInterviewer: Scalars["Boolean"];
  meetingUrl: Scalars["String"];
  nextScheduledInterview?: Maybe<ScheduledInterview>;
  organization: Organization;
  organizationId: Scalars["UUID"];
  scheduledEnd: Scalars["DateTime"];
  scheduledInterviews: Array<ScheduledInterview>;
  scheduledStart: Scalars["DateTime"];
  startTime?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
};

export type VisibilityLevelDescription = {
  __typename?: "VisibilityLevelDescription";
  description: Scalars["String"];
  userRoleDescription: Scalars["String"];
  visibilityLevel: CallVisibility;
};

export type WebhookConfiguration = {
  __typename?: "WebhookConfiguration";
  eventCandidateUpdatedRecordingDisabled: Scalars["Boolean"];
  eventInterviewCompleted: Scalars["Boolean"];
  id: Scalars["ID"];
  webhookSecret?: Maybe<Scalars["String"]>;
  webhookUrl?: Maybe<Scalars["String"]>;
};

export type ZoomMeeting = {
  __typename?: "ZoomMeeting";
  agenda: Scalars["String"];
  autoRecordingSetting?: Maybe<Scalars["String"]>;
  calendarEventId?: Maybe<Scalars["UUID"]>;
  call?: Maybe<Call>;
  callId?: Maybe<Scalars["UUID"]>;
  canBeImported?: Maybe<Scalars["Boolean"]>;
  canNotBeImportedReason?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  creatorEmail?: Maybe<Scalars["String"]>;
  creatorId?: Maybe<Scalars["String"]>;
  emailId?: Maybe<Scalars["UUID"]>;
  endTime?: Maybe<Scalars["DateTime"]>;
  exclude: Scalars["Boolean"];
  excludeReason?: Maybe<ExcludeReason>;
  finalized: Scalars["Boolean"];
  host?: Maybe<User>;
  hostEmail?: Maybe<Scalars["String"]>;
  hostId?: Maybe<Scalars["UUID"]>;
  hostZoomId?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  importMeeting: Scalars["Boolean"];
  importReason: InterviewImportDecisionReason;
  joinUrl?: Maybe<Scalars["String"]>;
  meetingCreatedAt?: Maybe<Scalars["DateTime"]>;
  organizationId: Scalars["UUID"];
  participants: Array<ZoomMeetingParticipant>;
  pmi?: Maybe<Scalars["Float"]>;
  recordingEndTime?: Maybe<Scalars["DateTime"]>;
  recordingStartTime?: Maybe<Scalars["DateTime"]>;
  recordingStarted: Scalars["Boolean"];
  scheduledDuration?: Maybe<Scalars["Float"]>;
  scheduledStartTime?: Maybe<Scalars["DateTime"]>;
  settings?: Maybe<Scalars["JSONString"]>;
  settingsUpdatedAt?: Maybe<Scalars["DateTime"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
  status: ZoomMeetingStatus;
  topic: Scalars["String"];
  type: ZoomMeetingType;
  updatedAt: Scalars["DateTime"];
  uuid: Scalars["String"];
  zoomAppAutoOpenEnabled: Scalars["Boolean"];
  zoomId: Scalars["Float"];
};

export type ZoomMeetingParticipant = {
  __typename?: "ZoomMeetingParticipant";
  callSpeakerOptionId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  identificationMethod?: Maybe<MeetingParticipantIdentificationMethod>;
  identificationSource?: Maybe<MeetingParticipantIdentificationSource>;
  identifiedDuringInterview?: Maybe<Scalars["Boolean"]>;
  isCandidate: Scalars["Boolean"];
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  scheduledInterviewerId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["DateTime"];
  userId?: Maybe<Scalars["UUID"]>;
  zoomMeeting: ZoomMeeting;
  zoomMeetingId: Scalars["UUID"];
  zoomUserId?: Maybe<Scalars["String"]>;
};

export enum ZoomMeetingStatus {
  Finished = "FINISHED",
  Started = "STARTED",
  Waiting = "WAITING",
}

export enum ZoomMeetingType {
  Instant = "INSTANT",
  Pac = "PAC",
  Pmi = "PMI",
  Prescheduled = "PRESCHEDULED",
  RecurringFixedTime = "RECURRING_FIXED_TIME",
  RecurringNoTime = "RECURRING_NO_TIME",
  Scheduled = "SCHEDULED",
}

export type ZoomNotetakerMeeting = {
  __typename?: "ZoomNotetakerMeeting";
  calendarEventId?: Maybe<Scalars["UUID"]>;
  call?: Maybe<Call>;
  callId?: Maybe<Scalars["UUID"]>;
  canBeImported?: Maybe<Scalars["Boolean"]>;
  canNotBeImportedReason?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  emailEventId?: Maybe<Scalars["UUID"]>;
  endedReason?: Maybe<BotMeetingEndedReason>;
  errorType?: Maybe<BotMeetingErrorType>;
  exclude: Scalars["Boolean"];
  excludeReason?: Maybe<ExcludeReason>;
  id: Scalars["UUID"];
  importMeeting: Scalars["Boolean"];
  importReason: InterviewImportDecisionReason;
  joinTime?: Maybe<Scalars["DateTime"]>;
  leftTime?: Maybe<Scalars["DateTime"]>;
  meetingPassword?: Maybe<Scalars["String"]>;
  meetingUrl?: Maybe<Scalars["String"]>;
  nextVirtualOnsiteMeetingId?: Maybe<Scalars["UUID"]>;
  organizationId: Scalars["UUID"];
  participants: Array<ZoomNotetakerMeetingParticipant>;
  platform: BotMeetingPlatform;
  platformMeetingId?: Maybe<Scalars["String"]>;
  platformMeetingInstanceId?: Maybe<Scalars["String"]>;
  previousRunningMeetingId?: Maybe<Scalars["UUID"]>;
  recallRecordingId?: Maybe<Scalars["String"]>;
  recordingEndTime?: Maybe<Scalars["DateTime"]>;
  recordingStartTime?: Maybe<Scalars["DateTime"]>;
  recordingStarted: Scalars["Boolean"];
  scheduledEndTime: Scalars["DateTime"];
  scheduledInterview?: Maybe<ScheduledInterview>;
  scheduledStartTime: Scalars["DateTime"];
  status: BotMeetingStatus;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  virtualOnsite?: Maybe<VirtualOnsite>;
  virtualOnsiteId?: Maybe<Scalars["UUID"]>;
  waitingRoomJoinTime?: Maybe<Scalars["DateTime"]>;
  zoomAppAutoOpenEnabled: Scalars["Boolean"];
  zoomId: Scalars["Float"];
};

export type ZoomNotetakerMeetingParticipant = {
  __typename?: "ZoomNotetakerMeetingParticipant";
  botMeetingId: Scalars["UUID"];
  callSpeakerOptionId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  identificationMethod?: Maybe<MeetingParticipantIdentificationMethod>;
  identificationSource?: Maybe<MeetingParticipantIdentificationSource>;
  identifiedAs?: Maybe<Scalars["String"]>;
  identifiedDuringInterview?: Maybe<Scalars["Boolean"]>;
  inMeeting: Scalars["Boolean"];
  isCandidate: Scalars["Boolean"];
  name: Scalars["String"];
  recallParticipantId?: Maybe<Scalars["Float"]>;
  scheduledInterviewerId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["DateTime"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["UUID"]>;
  zoomAppChatMessageSent: Scalars["Boolean"];
  zoomConfUserId?: Maybe<Scalars["String"]>;
  zoomNotetakerMeeting: ZoomNotetakerMeeting;
  zoomNotetakerMeetingId: Scalars["String"];
  zoomUserId?: Maybe<Scalars["Float"]>;
};

export type ActivityFeedListItemFragment = {
  __typename?: "ActivityFeed";
  id: any;
  ownerId: any;
  createdAt: any;
  activity: {
    __typename?: "Activity";
    action: ActivityType;
    createdAt: any;
    subject?: { __typename?: "User"; id: any; fullName: string } | null;
    externalSubject?: {
      __typename?: "ExternalUser";
      id: any;
      email: string;
    } | null;
    call?: { __typename?: "Call"; id: any; name?: string | null } | null;
    clip?: {
      __typename?: "Clip";
      id: any;
      userId: any;
      callId: any;
      name?: string | null;
      displayName: string;
    } | null;
    callNote?: {
      __typename?: "CallNote";
      id: any;
      highlightStartTime?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
    } | null;
    callShare?: {
      __typename?: "CallShare";
      id: any;
      sharedBy: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      };
    } | null;
    clipShare?: {
      __typename?: "ClipShare";
      id: any;
      sharedBy: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      };
    } | null;
    playlistShare?: {
      __typename?: "PlaylistShare";
      id: any;
      absoluteUrl: string;
      sharedByUser: { __typename?: "User"; id: any; fullName: string };
    } | null;
  };
};

export type ApplicationFragment = {
  __typename?: "Application";
  id: any;
  candidateId: any;
  positionId: any;
  atsStatus?: string | null;
  smartrecruitersLink?: string | null;
};

export type CallAiSummaryTemplateFragment = {
  __typename?: "CallAiSummaryTemplate";
  id: any;
  name: string;
  isCreatorDefault: boolean;
  customType?: CustomTemplateCustomType | null;
  customPrompt?: string | null;
  model?: string | null;
  visibility: CallAiSummaryTemplateVisibility;
  isCurrentUserDefault: boolean;
  defaultFor: CallAiSummaryTemplateDefaultFor;
  defaultForPersona?: UserPersona | null;
  defaultForKeywords: Array<string>;
  defaultForMeetingType?: CustomTemplateType | null;
  canMakePublic: boolean;
  sections: Array<{
    __typename?: "CallAiSummaryTemplateSection";
    id: any;
    title: string;
    prompt: Array<string>;
  }>;
  creator: { __typename?: "User"; id: any; email: string };
};

export type CallAiSummaryTemplateListItemFragment = {
  __typename?: "CallAiSummaryTemplate";
  id: any;
  name: string;
  createdAt: any;
  visibility: CallAiSummaryTemplateVisibility;
  canEdit: boolean;
  canDelete: boolean;
  defaultFor: CallAiSummaryTemplateDefaultFor;
  defaultForPersona?: UserPersona | null;
  defaultForKeywords: Array<string>;
  defaultForMeetingType?: CustomTemplateType | null;
  creator: { __typename?: "User"; id: any; fullName: string };
};

export type CallFragment = {
  __typename?: "Call";
  id: any;
  name?: string | null;
  type: CallType;
  status: CallStatus;
  visibility: CallVisibility;
  canEdit: boolean;
  canDelete: boolean;
  startTime?: any | null;
  endTime?: any | null;
  createdAt: any;
  duration?: number | null;
  rating: number;
  phoneNumber?: string | null;
  useSafeNumber: boolean;
  isProcessing: boolean;
  transcriptionStatus?: TranscriptionStatus | null;
  recordingDisabled: boolean;
  greenhouseScorecardLink?: string | null;
  leverInterviewLink?: string | null;
  smartrecruitersInterviewLink?: string | null;
  customAtsUrl?: string | null;
  interviewerIds: Array<string>;
  skipRedaction: boolean;
  interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
  candidate?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
    fullName: string;
    greenhouseId?: number | null;
    greenhouseLink?: string | null;
    lastLeverOpportunityId?: string | null;
  } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
  streamableAudio?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  streamableVideo?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  notes: Array<{
    __typename?: "CallNote";
    id: any;
    callId: any;
    clipId?: any | null;
    time: number;
    highlightStartTime?: number | null;
    highlightEndTime?: number | null;
    speakerTag?: number | null;
    text: string;
    type: CallNoteType;
    description?: string | null;
    isEdited: boolean;
    visibility: CallNoteVisibility;
    guideItemId?: any | null;
    parentGuideItemId?: any | null;
    scorecardQuestionId?: any | null;
    questionId?: any | null;
    createdAt: any;
    updatedAt: any;
    score?: number | null;
    user?: {
      __typename?: "User";
      id: any;
      profilePicUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
    } | null;
    replies: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      text: string;
      isEdited: boolean;
      createdAt: any;
      visibility: CallNoteVisibility;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      } | null;
    }>;
  }>;
  speakers: Array<{
    __typename?: "CallSpeaker";
    id: any;
    speakerTag: number;
    label: string;
    isCandidate: boolean;
    isInterviewer?: boolean | null;
    identified: boolean;
    talkTimePercentage: number;
    longestMonologue?: number | null;
  }>;
  viewers: Array<{
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    email: string;
    profilePicUrl?: string | null;
    phoneNumber?: string | null;
    phoneNumberExtension?: string | null;
    phoneNumberVerified: boolean;
    isCalendarAuthorized: boolean;
    createdAt: any;
    deletedAt?: any | null;
    signUpMethod: UserSignUpMethod;
    signUpMethodLabel: string;
    signUpCompletedAt?: any | null;
    voipPhoneNumber?: string | null;
    invitedBy?: {
      __typename?: "User";
      id: any;
      fullName: string;
      email: string;
    } | null;
  }>;
  scheduledInterview?: {
    __typename?: "ScheduledInterview";
    greenhouseInterviewId?: number | null;
    leverInterviewId?: string | null;
    customAtsUrl?: string | null;
  } | null;
};

export type CallListItemFragment = {
  __typename?: "Call";
  id: any;
  name?: string | null;
  rating: number;
  type: CallType;
  phoneNumber?: string | null;
  visibility: CallVisibility;
  visibleVisibilityLevels: Array<CallVisibility>;
  canEdit: boolean;
  canDelete: boolean;
  interviewerIds: Array<string>;
  skipRedaction: boolean;
  noRecordingReason?: NoRecordingReason | null;
  isInterviewer: boolean;
  greenhouseScorecardLink?: string | null;
  greenhouseScorecardLinkWithId?: string | null;
  greenhouseUserRating?: string | null;
  leverInterviewLink?: string | null;
  leverInterviewLinkWithId?: string | null;
  startTime?: any | null;
  createdAt: any;
  duration?: number | null;
  status: CallStatus;
  transcriptionStatus?: TranscriptionStatus | null;
  recordingDisabled: boolean;
  thumbnailImageUrl?: string | null;
  recordingProcessingStatus?: CallRecordingProcessingStatus | null;
  thumbnailsProcessingStatus?: CallThumbnailsProcessingStatus | null;
  aiNotesProcessingStatus?: AiNotesProcessingStatus | null;
  myScore?: number | null;
  averageScore?: number | null;
  needsCurrentUserScore?: boolean | null;
  hasPendingScores?: boolean | null;
  interviewers: Array<{
    __typename?: "User";
    id: any;
    fullName: string;
    firstName?: string | null;
    lastName?: string | null;
    profilePicUrl?: string | null;
  }>;
  candidate?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
    fullName: string;
    defaultPhoneNumber?: string | null;
    defaultEmail?: string | null;
    readOnly: boolean;
  } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
  recordings: Array<{
    __typename?: "Recording";
    id: any;
    format?: RecordingFormat | null;
  }>;
};

export type CallNoteFragment = {
  __typename?: "CallNote";
  id: any;
  callId: any;
  clipId?: any | null;
  time: number;
  highlightStartTime?: number | null;
  highlightEndTime?: number | null;
  speakerTag?: number | null;
  text: string;
  type: CallNoteType;
  description?: string | null;
  isEdited: boolean;
  visibility: CallNoteVisibility;
  guideItemId?: any | null;
  parentGuideItemId?: any | null;
  scorecardQuestionId?: any | null;
  questionId?: any | null;
  createdAt: any;
  updatedAt: any;
  score?: number | null;
  user?: {
    __typename?: "User";
    id: any;
    profilePicUrl?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
  } | null;
  replies: Array<{
    __typename?: "CallNote";
    id: any;
    callId: any;
    clipId?: any | null;
    text: string;
    isEdited: boolean;
    createdAt: any;
    visibility: CallNoteVisibility;
    user?: {
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      profilePicUrl?: string | null;
    } | null;
  }>;
};

export type CallNoteReplyFragment = {
  __typename?: "CallNote";
  id: any;
  callId: any;
  clipId?: any | null;
  text: string;
  isEdited: boolean;
  createdAt: any;
  visibility: CallNoteVisibility;
  user?: {
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    profilePicUrl?: string | null;
  } | null;
};

export type CallSpeakerFragment = {
  __typename?: "CallSpeaker";
  id: any;
  speakerTag: number;
  label: string;
  isCandidate: boolean;
  isInterviewer?: boolean | null;
  identified: boolean;
  talkTimePercentage: number;
  longestMonologue?: number | null;
};

export type CallTranscriptFragment = {
  __typename?: "Call";
  organizationId: any;
  canEdit: boolean;
  id: any;
  name?: string | null;
  type: CallType;
  status: CallStatus;
  visibility: CallVisibility;
  canDelete: boolean;
  startTime?: any | null;
  endTime?: any | null;
  createdAt: any;
  duration?: number | null;
  rating: number;
  phoneNumber?: string | null;
  useSafeNumber: boolean;
  isProcessing: boolean;
  transcriptionStatus?: TranscriptionStatus | null;
  recordingDisabled: boolean;
  greenhouseScorecardLink?: string | null;
  leverInterviewLink?: string | null;
  smartrecruitersInterviewLink?: string | null;
  customAtsUrl?: string | null;
  interviewerIds: Array<string>;
  skipRedaction: boolean;
  transcript: Array<{
    __typename?: "TranscriptSegment";
    id: any;
    speakerTag: number;
    startTime: number;
    endTime: number;
    languageCode?: string | null;
    words: Array<{
      __typename?: "TranscriptWord";
      word: string;
      startTime: number;
      endTime: number;
      confidence?: number | null;
      isRedacted?: boolean | null;
      rawWord?: string | null;
    }>;
  }>;
  interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
  candidate?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
    fullName: string;
    greenhouseId?: number | null;
    greenhouseLink?: string | null;
    lastLeverOpportunityId?: string | null;
  } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
  streamableAudio?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  streamableVideo?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  notes: Array<{
    __typename?: "CallNote";
    id: any;
    callId: any;
    clipId?: any | null;
    time: number;
    highlightStartTime?: number | null;
    highlightEndTime?: number | null;
    speakerTag?: number | null;
    text: string;
    type: CallNoteType;
    description?: string | null;
    isEdited: boolean;
    visibility: CallNoteVisibility;
    guideItemId?: any | null;
    parentGuideItemId?: any | null;
    scorecardQuestionId?: any | null;
    questionId?: any | null;
    createdAt: any;
    updatedAt: any;
    score?: number | null;
    user?: {
      __typename?: "User";
      id: any;
      profilePicUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
    } | null;
    replies: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      text: string;
      isEdited: boolean;
      createdAt: any;
      visibility: CallNoteVisibility;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      } | null;
    }>;
  }>;
  speakers: Array<{
    __typename?: "CallSpeaker";
    id: any;
    speakerTag: number;
    label: string;
    isCandidate: boolean;
    isInterviewer?: boolean | null;
    identified: boolean;
    talkTimePercentage: number;
    longestMonologue?: number | null;
  }>;
  viewers: Array<{
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    email: string;
    profilePicUrl?: string | null;
    phoneNumber?: string | null;
    phoneNumberExtension?: string | null;
    phoneNumberVerified: boolean;
    isCalendarAuthorized: boolean;
    createdAt: any;
    deletedAt?: any | null;
    signUpMethod: UserSignUpMethod;
    signUpMethodLabel: string;
    signUpCompletedAt?: any | null;
    voipPhoneNumber?: string | null;
    invitedBy?: {
      __typename?: "User";
      id: any;
      fullName: string;
      email: string;
    } | null;
  }>;
  scheduledInterview?: {
    __typename?: "ScheduledInterview";
    greenhouseInterviewId?: number | null;
    leverInterviewId?: string | null;
    customAtsUrl?: string | null;
  } | null;
};

export type CandidateAlertFeedListItemFragment = {
  __typename?: "CandidateAlertFeed";
  id: any;
  readAt?: any | null;
  accuracyFeedback?: CandidateAlertAccuracyFeedback | null;
  alert: {
    __typename?: "CandidateAlert";
    id: any;
    startTime: number;
    text: string;
    type: CandidateAlertType;
    category: CandidateAlertCategory;
    call: { __typename?: "Call"; id: any; startTime?: any | null };
    candidate: { __typename?: "Candidate"; id: any; fullName: string };
    position?: { __typename?: "Position"; id: any; title: string } | null;
  };
};

export type CandidateAlertListItemFragment = {
  __typename?: "CandidateAlert";
  id: any;
  startTime: number;
  text: string;
  type: CandidateAlertType;
  category: CandidateAlertCategory;
  call: { __typename?: "Call"; id: any; startTime?: any | null };
  candidate: { __typename?: "Candidate"; id: any; fullName: string };
  position?: { __typename?: "Position"; id: any; title: string } | null;
};

export type CandidateFragment = {
  __typename?: "Candidate";
  id: any;
  firstName: string;
  lastName: string;
  fullName: string;
  skipRedaction: boolean;
  emails: Array<string>;
  defaultEmail?: string | null;
  rating?: number | null;
  canEdit: boolean;
  greenhouseId?: number | null;
  greenhouseLink?: string | null;
  smartrecruitersLink?: string | null;
  lastLeverOpportunityId?: string | null;
  customAtsUrl?: string | null;
  mergeRemoteId?: string | null;
  mergeType?: Ats | null;
  recordingDisabled: boolean;
  phoneNumbers: Array<{
    __typename?: "CandidatePhoneNumber";
    id: any;
    type: CandidatePhoneType;
    phoneNumber: string;
  }>;
  defaultPhoneNumberObj?: {
    __typename?: "CandidatePhoneNumber";
    id: any;
    type: CandidatePhoneType;
    phoneNumber: string;
  } | null;
  ratingBreakdown?: Array<{
    __typename?: "CandidateRatingBreakdown";
    rating: number;
    count: number;
  } | null> | null;
  calls: Array<{ __typename?: "Call"; id: any; positionId?: any | null }>;
  scheduledInterviews: Array<{
    __typename?: "ScheduledInterview";
    id: string;
    positionId?: any | null;
  }>;
  positions: Array<{ __typename?: "Position"; id: any; displayTitle: string }>;
  candidateLinks: Array<{
    __typename?: "CandidateLink";
    id: any;
    source: string;
    link: string;
    type: CandidateLinkType;
  }>;
  lastCall?: { __typename?: "Call"; id: any; positionId?: any | null } | null;
  pastRecordingDisabledScheduledInterviews: Array<{
    __typename?: "ScheduledInterview";
    id: string;
    scheduledStart?: any | null;
    scheduledInterviewers: Array<{
      __typename?: "ScheduledInterviewer";
      id: string;
      user?: { __typename?: "User"; id: any; fullName: string } | null;
    }>;
  }>;
  applications: Array<{
    __typename?: "Application";
    id: any;
    candidateId: any;
    positionId: any;
    atsStatus?: string | null;
    smartrecruitersLink?: string | null;
  }>;
};

export type CandidateListItemFragment = {
  __typename?: "Candidate";
  id: any;
  fullName: string;
  firstName: string;
  lastName: string;
  defaultEmail?: string | null;
  defaultPhoneNumber?: string | null;
  rating?: number | null;
  skipRedaction: boolean;
  callCount?: number | null;
  validCallsCount?: number | null;
  recordingDisabled: boolean;
  positionAverageScore?: number | null;
  lastCall?: {
    __typename?: "Call";
    id: any;
    name?: string | null;
    type: CallType;
    startTime?: any | null;
    createdAt: any;
    duration?: number | null;
    status: CallStatus;
    transcriptionStatus?: TranscriptionStatus | null;
    recordingDisabled: boolean;
    rating: number;
    interviewerIds: Array<string>;
    interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
    position?: {
      __typename?: "Position";
      id: any;
      displayTitle: string;
    } | null;
  } | null;
};

export type ClipFragment = {
  __typename?: "Clip";
  id: any;
  callId: any;
  userId: any;
  startTime: number;
  endTime: number;
  recordingStatus: ClipRecordingStatus;
  failureReason?: string | null;
  createdAt: any;
  updatedAt: any;
  visibility: CallVisibility;
  visibleVisibilityLevels: Array<CallVisibility>;
  thumbnailImageUrl?: string | null;
  canDelete: boolean;
  canEdit: boolean;
  name?: string | null;
  displayName: string;
  iaVersion: number;
  interviewerIds: Array<string>;
  callStartTime?: any | null;
  callEndTime?: any | null;
  callName?: string | null;
  callRecordingDisabled: boolean;
  callPhoneNumber?: string | null;
  callType: CallType;
  streamableAudio?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  streamableVideo?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  user: { __typename?: "User"; id: any; fullName: string };
  viewers: Array<{
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    email: string;
    profilePicUrl?: string | null;
    phoneNumber?: string | null;
    phoneNumberExtension?: string | null;
    phoneNumberVerified: boolean;
    isCalendarAuthorized: boolean;
    createdAt: any;
    deletedAt?: any | null;
    signUpMethod: UserSignUpMethod;
    signUpMethodLabel: string;
    signUpCompletedAt?: any | null;
    voipPhoneNumber?: string | null;
    invitedBy?: {
      __typename?: "User";
      id: any;
      fullName: string;
      email: string;
    } | null;
  }>;
  callSpeakers: Array<{
    __typename?: "CallSpeaker";
    id: any;
    speakerTag: number;
    label: string;
    isCandidate: boolean;
    isInterviewer?: boolean | null;
    identified: boolean;
    talkTimePercentage: number;
    longestMonologue?: number | null;
  }>;
  candidate?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
    fullName: string;
    greenhouseId?: number | null;
    greenhouseLink?: string | null;
    lastLeverOpportunityId?: string | null;
  } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
};

export type ClipListItemFragment = {
  __typename?: "Clip";
  id: any;
  createdAt: any;
  updatedAt: any;
  canEdit: boolean;
  canDelete: boolean;
  startTime: number;
  endTime: number;
  thumbnailImageUrl?: string | null;
  name?: string | null;
  displayName: string;
  userId: any;
  callId: any;
  callName?: string | null;
  duration: number;
  durationFormatted: string;
  recordingReady: boolean;
  visibility: CallVisibility;
  visibleVisibilityLevels: Array<CallVisibility>;
  candidate?: { __typename?: "Candidate"; id: any; fullName: string } | null;
  position?: {
    __typename?: "Position";
    id: any;
    title: string;
    displayTitle: string;
  } | null;
};

export type ClipTranscriptFragment = {
  __typename?: "Clip";
  canEdit: boolean;
  id: any;
  callId: any;
  userId: any;
  startTime: number;
  endTime: number;
  recordingStatus: ClipRecordingStatus;
  failureReason?: string | null;
  createdAt: any;
  updatedAt: any;
  visibility: CallVisibility;
  visibleVisibilityLevels: Array<CallVisibility>;
  thumbnailImageUrl?: string | null;
  canDelete: boolean;
  name?: string | null;
  displayName: string;
  iaVersion: number;
  interviewerIds: Array<string>;
  callStartTime?: any | null;
  callEndTime?: any | null;
  callName?: string | null;
  callRecordingDisabled: boolean;
  callPhoneNumber?: string | null;
  callType: CallType;
  notes: Array<{
    __typename?: "CallNote";
    id: any;
    callId: any;
    clipId?: any | null;
    time: number;
    highlightStartTime?: number | null;
    highlightEndTime?: number | null;
    speakerTag?: number | null;
    text: string;
    type: CallNoteType;
    description?: string | null;
    isEdited: boolean;
    visibility: CallNoteVisibility;
    guideItemId?: any | null;
    parentGuideItemId?: any | null;
    scorecardQuestionId?: any | null;
    questionId?: any | null;
    createdAt: any;
    updatedAt: any;
    score?: number | null;
    user?: {
      __typename?: "User";
      id: any;
      profilePicUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
    } | null;
    replies: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      text: string;
      isEdited: boolean;
      createdAt: any;
      visibility: CallNoteVisibility;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      } | null;
    }>;
  }>;
  interviewers: Array<{
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    email: string;
  }>;
  transcript: Array<{
    __typename?: "TranscriptSegment";
    id: any;
    speakerTag: number;
    startTime: number;
    endTime: number;
    languageCode?: string | null;
    words: Array<{
      __typename?: "TranscriptWord";
      word: string;
      startTime: number;
      endTime: number;
      confidence?: number | null;
      isRedacted?: boolean | null;
      rawWord?: string | null;
    }>;
  }>;
  streamableAudio?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  streamableVideo?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  user: { __typename?: "User"; id: any; fullName: string };
  viewers: Array<{
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    email: string;
    profilePicUrl?: string | null;
    phoneNumber?: string | null;
    phoneNumberExtension?: string | null;
    phoneNumberVerified: boolean;
    isCalendarAuthorized: boolean;
    createdAt: any;
    deletedAt?: any | null;
    signUpMethod: UserSignUpMethod;
    signUpMethodLabel: string;
    signUpCompletedAt?: any | null;
    voipPhoneNumber?: string | null;
    invitedBy?: {
      __typename?: "User";
      id: any;
      fullName: string;
      email: string;
    } | null;
  }>;
  callSpeakers: Array<{
    __typename?: "CallSpeaker";
    id: any;
    speakerTag: number;
    label: string;
    isCandidate: boolean;
    isInterviewer?: boolean | null;
    identified: boolean;
    talkTimePercentage: number;
    longestMonologue?: number | null;
  }>;
  candidate?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
    fullName: string;
    greenhouseId?: number | null;
    greenhouseLink?: string | null;
    lastLeverOpportunityId?: string | null;
  } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
};

export type CurrentUserFragment = {
  __typename?: "User";
  id: any;
  internalUserRole?: InternalUserRoleName | null;
  internalUserRolePermissions: Array<string>;
  firstName?: string | null;
  lastName?: string | null;
  fullName: string;
  email: string;
  emailAliases: string;
  profilePicUrl?: string | null;
  persona?: UserPersona | null;
  phoneNumber?: string | null;
  phoneNumberExtension?: string | null;
  maskPersonalPhoneNumber: boolean;
  phoneNumberVerified: boolean;
  numUnreadAlerts: number;
  voipPhoneNumber?: string | null;
  isImpersonated: boolean;
  autofillGreenhouseScorecard: boolean;
  zoomAppAutoOpenEnabled?: boolean | null;
  defaultCallType: CallType;
  intercomUserHash: string;
  createdAt: any;
  signUpCompletedAt?: any | null;
  signUpState: string;
  requireCalendarSync: boolean;
  enabledFeatures: Array<string>;
  impersonatedFeatures: Array<string>;
  browserExtensionVersion?: string | null;
  showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
  callCount: number;
  lastCalledAt?: any | null;
  signUpMethod: UserSignUpMethod;
  timezoneName: string;
  hasViewedTranscriptionWarning: boolean;
  interviewRecapSubscription: UserSubscriptionSettings;
  interviewPrepSubscription: UserSubscriptionSettings;
  weeklyRecapSubscription: UserSubscriptionSettings;
  monthlyAnalyticsSubscription: UserSubscriptionSettings;
  callShareSubscription: UserSubscriptionSettings;
  callNoteSubscription: UserSubscriptionSettings;
  morningDigestSubscription: UserSubscriptionSettings;
  isZoomAppInstalled: boolean;
  shouldReauthorizeZoomApp: boolean;
  trainingProgramsCreatedCount: number;
  trainingProgramsCompletedCount: number;
  showCandidateActionItemAlerts: boolean;
  isInternationalCallingEnabled: boolean;
  defaultAiNoteFormat: CallAiSummaryFormat;
  organization: {
    __typename?: "Organization";
    id: any;
    name: string;
    freemium: boolean;
    phoneNumber?: string | null;
    playCallDisclaimer: boolean;
    isBullhornAuthorized: boolean;
    isZoomAuthorized: boolean;
    greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
    greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
    allowsGreenhouseScorecardAutoDraft: boolean;
    allowUserLevelImportRules: boolean;
    requiresGreenhouseScorecardAutoDraft: boolean;
    allowsZoomAppAutoOpen: boolean;
    requiresZoomAppAutoOpen: boolean;
    isZoomBotAuthorized: boolean;
    zoomNotetakerEnabled: boolean;
    recordingAllowed: boolean;
    googleMeetDisplayName?: string | null;
    googleMeetDisplayImageBucketPath?: string | null;
    googleMeetEnabled: boolean;
    virtualOnsiteEnabledGoogleMeet: boolean;
    googleMeetRecordingDisclaimer?: string | null;
    googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
    externalShareDefaultDurationDays: number;
    teamsNotetakerEnabled: boolean;
    teamsNotetakerDisplayName?: string | null;
    virtualOnsiteEnabledMsTeams: boolean;
    teamsNotetakerDisclaimer?: string | null;
    teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
    teamsNotetakerDisplayImageBucketPath?: string | null;
    notetakerRecorderDisclaimerDefault: string;
    audioOnlyViewing: boolean;
    allowSelfSignUp: boolean;
    allowPhoneSignIn: boolean;
    postNotesToAts: boolean;
    hideOtherInterviewerNotesInExtension: boolean;
    emailDomains?: Array<string> | null;
    inviteScheduledInterviewers: boolean;
    assignGuidesToInterviewersEnabled: boolean;
    requireActiveUserToImport: boolean;
    allowCandidateOptOut: boolean;
    candidateOptOutUrl: string;
    allowInterviewerOptOut: boolean;
    dataRedactionEnabled: boolean;
    dataRedactionPredictionEnabled: boolean;
    planEnabled: boolean;
    ats?: Ats | null;
    showStarRatings?: boolean | null;
    slackAppInstalled: boolean;
    allowManualScheduledInterviews: boolean;
    allowInPersonMeetings: boolean;
    scoringEnabled: boolean;
    guideCompetencyScoringEnabledByDefault: boolean;
    guideOverallScoringEnabledByDefault: boolean;
    playInPersonRecordingDisclaimer: boolean;
    includeTimestampsInNoteOutputs: boolean;
    interviewTypes: Array<InterviewType>;
    allowEmailSignIn: boolean;
    textBeforeCallEnabled: boolean;
    allowAiNotes: boolean;
    maximumCallDurationForNotetakerToRejoin?: number | null;
    individualVoipNumbersEnabled: boolean;
    callNameFieldIsDropdown: boolean;
    atsOptInEnabled: boolean;
    pasteAiNotesInScorecard: boolean;
    allowAiCustomTemplates: boolean;
    interviewEnabled: boolean;
    defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
    allowPlanJobDescriptionToneOverride: boolean;
    callDropdownNames?: Array<string> | null;
    askEnabled: boolean;
    createGuidesFromInterviewPlansEnabled: boolean;
    disableAnalyticsGenderSegmentation: boolean;
    ratingDescriptions: {
      __typename?: "RatingDescriptions";
      oneStar: string;
      twoStar: string;
      threeStar: string;
      fourStar: string;
      fiveStar: string;
    };
    competencyRatingDescriptions: {
      __typename?: "CompetencyRatingDescriptions";
      oneStar: string;
      twoStar: string;
      threeStar: string;
      fourStar: string;
      fiveStar: string;
    };
    userRoles: Array<{
      __typename?: "UserRole";
      id: any;
      name: UserRoleName;
      permissions: Array<UserRolePermission>;
      formattedName?: string | null;
      formattedPermissions?: string | null;
      canAccessPublicCalls?: boolean | null;
      canAccessRestrictedCalls?: boolean | null;
      canAccessPrivateCalls?: boolean | null;
    }>;
    positionRoles: Array<{
      __typename?: "PositionRole";
      id: any;
      name: PositionRoleName;
      permissions: Array<PositionRolePermission>;
      formattedName?: string | null;
      formattedPermissions?: string | null;
      canAccessTeamPublicCalls?: boolean | null;
      canAccessTeamRestrictedCalls?: boolean | null;
      canAccessTeamPrivateCalls?: boolean | null;
    }>;
    planUserRoles: Array<{
      __typename?: "PlanUserRole";
      id: any;
      name: PlanUserRoleName;
      permissions: Array<PlanUserRolePermission>;
      formattedName?: string | null;
      formattedPermissions?: string | null;
    }>;
    featureAccess?: Array<{
      __typename?: "OrganizationFeatureAccess";
      id: string;
      name: FeatureName;
      settingLevel: FeatureSettingLevel;
      isVisible: boolean;
      showCta: boolean;
      marketingRedirect: boolean;
    }> | null;
  };
  userRole?: {
    __typename?: "UserRole";
    id: any;
    name: UserRoleName;
    formattedName?: string | null;
    permissions: Array<UserRolePermission>;
    canManageOrganizationSettings?: boolean | null;
    canManageIntegrationSettings?: boolean | null;
    canManageHiringTeam?: boolean | null;
    canManageUserRoles?: boolean | null;
    canCreatePosition?: boolean | null;
    canDeleteCall?: boolean | null;
    canCreateTrainings?: boolean | null;
    canManageAllTrainings?: boolean | null;
    canDeleteCandidates?: boolean | null;
    canViewInsights?: boolean | null;
    canViewInsightsAlerts?: boolean | null;
    canManageIQReport?: boolean | null;
    canManageCustomTemplates?: boolean | null;
  } | null;
  planUserRole?: {
    __typename?: "PlanUserRole";
    id: any;
    name: PlanUserRoleName;
    formattedName?: string | null;
    permissions: Array<PlanUserRolePermission>;
    canManageOrganizationSettings?: boolean | null;
    canManageUserRoles?: boolean | null;
  } | null;
  memberships: Array<{
    __typename?: "Membership";
    position: {
      __typename?: "Position";
      id: any;
      title: string;
      displayTitle: string;
    };
    role: {
      __typename?: "PositionRole";
      id: any;
      permissions: Array<PositionRolePermission>;
      canManageHiringTeam?: boolean | null;
      canCreateTrainings?: boolean | null;
      canViewInsights?: boolean | null;
    };
  }>;
};

export type EventImportKeywordFragment = {
  __typename?: "EventImportKeyword";
  id: any;
  keywordText: string;
};

export type EventListItemFragment = {
  __typename?: "Event";
  id: any;
  htmlLink: string;
  summary?: string | null;
  description?: string | null;
  start: any;
  end: any;
  location?: string | null;
  calendar: { __typename?: "Calendar"; id: any; name: string };
  attendees: Array<{
    __typename?: "Attendee";
    email: string;
    responseStatus: ResponseStatus;
    displayName?: string | null;
  }>;
};

export type FeatureFlagFragment = {
  __typename?: "FeatureFlag";
  id: any;
  name: string;
  description: string;
  enabled: boolean;
  updatedAt: any;
};

export type FreemiumScheduledInterviewListItemFragment = {
  __typename?: "ScheduledInterview";
  id: string;
  name?: string | null;
  scheduledStart?: any | null;
  scheduledEnd?: any | null;
  isExcluded: boolean;
  isImported: boolean;
  importDecision: boolean;
  importDecisionReason?: InterviewImportDecisionReason | null;
  greenhouseLink?: string | null;
  ghJobStageName?: string | null;
  interviewType?: InterviewType | null;
  isInterviewer: boolean;
  recordingDisabled: boolean;
  scheduledType: ScheduledInterviewType;
  candidate?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
    fullName: string;
    callCount?: number | null;
    defaultPhoneNumber?: string | null;
    recordingDisabled: boolean;
  } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
};

export type GoogleMeetMeetingFragment = {
  __typename?: "GoogleMeetMeeting";
  id: string;
  meetingUrl?: string | null;
  recordingStarted: boolean;
  autoRecordingSetting?: string | null;
  canBeImported?: boolean | null;
  canNotBeImportedReason?: string | null;
  excludeReason?: ExcludeReason | null;
  participants: Array<{
    __typename?: "GoogleMeetMeetingParticipant";
    name: string;
    profilePicUrl?: string | null;
    user?: {
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
    } | null;
  }>;
  call?: { __typename?: "Call"; id: any; name?: string | null } | null;
};

export type GoogleMeetMeetingParticipantFragment = {
  __typename?: "GoogleMeetMeetingParticipant";
  name: string;
  profilePicUrl?: string | null;
  user?: {
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    email: string;
    profilePicUrl?: string | null;
    phoneNumber?: string | null;
  } | null;
};

export type GreenhouseSetupFragment = {
  __typename?: "GreenhouseSetup";
  id: string;
  enabled: boolean;
  apiKey: string;
  host: string;
  adminUserId: any;
  valid: boolean;
  invalidReason?: string | null;
  webhookSecretKey?: string | null;
  syncCallGuidesFromAts: boolean;
  atsOptInEnabled: boolean;
  greenhouseConsentKey?: string | null;
  permissionsStatus: Array<{
    __typename?: "GreenhousePermissionStatus";
    permission: string;
    isGranted: boolean;
  }>;
};

export type GreenhouseUserFragment = {
  __typename?: "GreenhouseUser";
  id: string;
  email: string;
  greenhouseId: any;
  siteAdmin: boolean;
  disabled: boolean;
};

export type IaCallFragment = {
  __typename?: "Call";
  id: any;
  interviewerIds: Array<string>;
  duration?: number | null;
  scheduledEndTime?: any | null;
  endTime?: any | null;
  rating: number;
  phoneNumber?: string | null;
  carrierType?: string | null;
  greenhouseScorecardLink?: string | null;
  greenhouseScorecardLinkWithId?: string | null;
  leverInterviewLink?: string | null;
  leverInterviewLinkWithId?: string | null;
  ashbyFeedbackLinkWithId?: string | null;
  bullhornCommentAction?: string | null;
  recordingDisabled: boolean;
  startTime?: any | null;
  type: CallType;
  useSafeNumber: boolean;
  virtualOnsiteId?: any | null;
  scorecard?: {
    __typename?: "Scorecard";
    id: any;
    overallScoringEnabled: boolean;
    overallScore?: number | null;
    overallNotes?: string | null;
    items: Array<{
      __typename?: "ScorecardItem";
      id: string;
      type?: GuideItemType | null;
      itemText: string;
      isHtmlType?: boolean | null;
      scoringEnabled?: boolean | null;
      score?: number | null;
      notes?: string | null;
      marked?: boolean | null;
      markedTime?: number | null;
      questionNotes: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        time: number;
        highlightStartTime?: number | null;
        highlightEndTime?: number | null;
        speakerTag?: number | null;
        text: string;
        type: CallNoteType;
        description?: string | null;
        isEdited: boolean;
        visibility: CallNoteVisibility;
        guideItemId?: any | null;
        parentGuideItemId?: any | null;
        scorecardQuestionId?: any | null;
        questionId?: any | null;
        createdAt: any;
        updatedAt: any;
        score?: number | null;
        user?: {
          __typename?: "User";
          id: any;
          profilePicUrl?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
        } | null;
        replies: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          clipId?: any | null;
          text: string;
          isEdited: boolean;
          createdAt: any;
          visibility: CallNoteVisibility;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
          } | null;
        }>;
      }>;
      childItems: Array<{
        __typename?: "ScorecardItem";
        id: string;
        itemText: string;
        isHtmlType?: boolean | null;
        scoringEnabled?: boolean | null;
        score?: number | null;
        notes?: string | null;
        marked?: boolean | null;
        markedTime?: number | null;
        questionNotes: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          clipId?: any | null;
          time: number;
          highlightStartTime?: number | null;
          highlightEndTime?: number | null;
          speakerTag?: number | null;
          text: string;
          type: CallNoteType;
          description?: string | null;
          isEdited: boolean;
          visibility: CallNoteVisibility;
          guideItemId?: any | null;
          parentGuideItemId?: any | null;
          scorecardQuestionId?: any | null;
          questionId?: any | null;
          createdAt: any;
          updatedAt: any;
          score?: number | null;
          user?: {
            __typename?: "User";
            id: any;
            profilePicUrl?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
          } | null;
          replies: Array<{
            __typename?: "CallNote";
            id: any;
            callId: any;
            clipId?: any | null;
            text: string;
            isEdited: boolean;
            createdAt: any;
            visibility: CallNoteVisibility;
            user?: {
              __typename?: "User";
              id: any;
              firstName?: string | null;
              lastName?: string | null;
              fullName: string;
              profilePicUrl?: string | null;
            } | null;
          }>;
        }>;
      }>;
    }>;
  } | null;
  notes: Array<{
    __typename?: "CallNote";
    id: any;
    callId: any;
    clipId?: any | null;
    time: number;
    highlightStartTime?: number | null;
    highlightEndTime?: number | null;
    speakerTag?: number | null;
    text: string;
    type: CallNoteType;
    description?: string | null;
    isEdited: boolean;
    visibility: CallNoteVisibility;
    guideItemId?: any | null;
    parentGuideItemId?: any | null;
    scorecardQuestionId?: any | null;
    questionId?: any | null;
    createdAt: any;
    updatedAt: any;
    score?: number | null;
    user?: {
      __typename?: "User";
      id: any;
      profilePicUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
    } | null;
    replies: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      text: string;
      isEdited: boolean;
      createdAt: any;
      visibility: CallNoteVisibility;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      } | null;
    }>;
  }>;
  generalNotes: Array<{
    __typename?: "CallNote";
    id: any;
    callId: any;
    clipId?: any | null;
    time: number;
    highlightStartTime?: number | null;
    highlightEndTime?: number | null;
    speakerTag?: number | null;
    text: string;
    type: CallNoteType;
    description?: string | null;
    isEdited: boolean;
    visibility: CallNoteVisibility;
    guideItemId?: any | null;
    parentGuideItemId?: any | null;
    scorecardQuestionId?: any | null;
    questionId?: any | null;
    createdAt: any;
    updatedAt: any;
    score?: number | null;
    user?: {
      __typename?: "User";
      id: any;
      profilePicUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
    } | null;
    replies: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      text: string;
      isEdited: boolean;
      createdAt: any;
      visibility: CallNoteVisibility;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      } | null;
    }>;
  }>;
  candidate?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
    fullName: string;
    defaultEmail?: string | null;
    greenhouseLink?: string | null;
    lastLeverOpportunityId?: string | null;
    bullhornId?: number | null;
    candidateLinks: Array<{
      __typename?: "CandidateLink";
      link: string;
      type: CandidateLinkType;
    }>;
  } | null;
  callGuide?: { __typename?: "CallGuide"; id: any } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
  scheduledInterview?: {
    __typename?: "ScheduledInterview";
    id: string;
    scheduledStart?: any | null;
    scheduledEnd?: any | null;
    virtualOnsiteId?: any | null;
    inProgressMeeting?: {
      __typename?: "NotetakerMeeting";
      id: string;
      callId?: string | null;
    } | null;
    scheduledInterviewers: Array<{
      __typename?: "ScheduledInterviewer";
      id: string;
      recordingDisabled: boolean;
      name?: string | null;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
        phoneNumber?: string | null;
      } | null;
    }>;
  } | null;
};

export type ImportZoomMeetingFragment = {
  __typename?: "ZoomMeeting";
  id: any;
  zoomId: number;
  joinUrl?: string | null;
  importMeeting: boolean;
  exclude: boolean;
  excludeReason?: ExcludeReason | null;
  hostId?: any | null;
  callId?: any | null;
};

export type InterviewStageItemFragment = {
  __typename?: "InterviewStage";
  id: any;
  title: string;
};

export type InterviewStageListItemFragment = {
  __typename?: "InterviewStage";
  id: any;
  title: string;
  createdAt: any;
};

export type NotetakerMeetingFragment = {
  __typename?: "NotetakerMeeting";
  id: string;
  type: NotetakerType;
  meetingUrl: string;
  callId?: string | null;
};

export type OrgCallingMetricsFragment = {
  __typename?: "Organization";
  id: any;
  users: Array<{
    __typename?: "User";
    id: any;
    fullName: string;
    callingMetrics: {
      __typename?: "UserCallingMetrics";
      numSignificantCalls: number;
      totalDuration: number;
      interviewerTalkRatio?: number | null;
      interviewerLongestMonologue?: number | null;
      interviewerWordsPerMinute?: number | null;
      callInteractivity?: number | null;
      weeklyCalls: number;
      weeklyDuration: number;
    };
  }>;
  callingMetrics: {
    __typename?: "OrgCallingMetrics";
    numSignificantCalls: number;
    totalDuration: number;
    weeklyCalls: number;
    weeklyDuration: number;
  };
};

export type OrganizationFeatureAccessFragment = {
  __typename?: "OrganizationFeatureAccess";
  id: string;
  name: FeatureName;
  settingLevel: FeatureSettingLevel;
  isVisible: boolean;
  showCta: boolean;
  marketingRedirect: boolean;
};

export type OrganizationFragment = {
  __typename?: "Organization";
  id: any;
  name: string;
  freemium: boolean;
  phoneNumber?: string | null;
  playCallDisclaimer: boolean;
  isBullhornAuthorized: boolean;
  isZoomAuthorized: boolean;
  greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
  greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
  allowsGreenhouseScorecardAutoDraft: boolean;
  allowUserLevelImportRules: boolean;
  requiresGreenhouseScorecardAutoDraft: boolean;
  allowsZoomAppAutoOpen: boolean;
  requiresZoomAppAutoOpen: boolean;
  isZoomBotAuthorized: boolean;
  zoomNotetakerEnabled: boolean;
  recordingAllowed: boolean;
  googleMeetDisplayName?: string | null;
  googleMeetDisplayImageBucketPath?: string | null;
  googleMeetEnabled: boolean;
  virtualOnsiteEnabledGoogleMeet: boolean;
  googleMeetRecordingDisclaimer?: string | null;
  googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
  externalShareDefaultDurationDays: number;
  teamsNotetakerEnabled: boolean;
  teamsNotetakerDisplayName?: string | null;
  virtualOnsiteEnabledMsTeams: boolean;
  teamsNotetakerDisclaimer?: string | null;
  teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
  teamsNotetakerDisplayImageBucketPath?: string | null;
  notetakerRecorderDisclaimerDefault: string;
  audioOnlyViewing: boolean;
  allowSelfSignUp: boolean;
  allowPhoneSignIn: boolean;
  postNotesToAts: boolean;
  hideOtherInterviewerNotesInExtension: boolean;
  emailDomains?: Array<string> | null;
  inviteScheduledInterviewers: boolean;
  assignGuidesToInterviewersEnabled: boolean;
  requireActiveUserToImport: boolean;
  allowCandidateOptOut: boolean;
  candidateOptOutUrl: string;
  allowInterviewerOptOut: boolean;
  dataRedactionEnabled: boolean;
  dataRedactionPredictionEnabled: boolean;
  planEnabled: boolean;
  ats?: Ats | null;
  showStarRatings?: boolean | null;
  slackAppInstalled: boolean;
  allowManualScheduledInterviews: boolean;
  allowInPersonMeetings: boolean;
  scoringEnabled: boolean;
  guideCompetencyScoringEnabledByDefault: boolean;
  guideOverallScoringEnabledByDefault: boolean;
  playInPersonRecordingDisclaimer: boolean;
  includeTimestampsInNoteOutputs: boolean;
  interviewTypes: Array<InterviewType>;
  allowEmailSignIn: boolean;
  textBeforeCallEnabled: boolean;
  allowAiNotes: boolean;
  maximumCallDurationForNotetakerToRejoin?: number | null;
  individualVoipNumbersEnabled: boolean;
  callNameFieldIsDropdown: boolean;
  atsOptInEnabled: boolean;
  pasteAiNotesInScorecard: boolean;
  allowAiCustomTemplates: boolean;
  interviewEnabled: boolean;
  defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
  allowPlanJobDescriptionToneOverride: boolean;
  callDropdownNames?: Array<string> | null;
  askEnabled: boolean;
  createGuidesFromInterviewPlansEnabled: boolean;
  disableAnalyticsGenderSegmentation: boolean;
  ratingDescriptions: {
    __typename?: "RatingDescriptions";
    oneStar: string;
    twoStar: string;
    threeStar: string;
    fourStar: string;
    fiveStar: string;
  };
  competencyRatingDescriptions: {
    __typename?: "CompetencyRatingDescriptions";
    oneStar: string;
    twoStar: string;
    threeStar: string;
    fourStar: string;
    fiveStar: string;
  };
  userRoles: Array<{
    __typename?: "UserRole";
    id: any;
    name: UserRoleName;
    permissions: Array<UserRolePermission>;
    formattedName?: string | null;
    formattedPermissions?: string | null;
    canAccessPublicCalls?: boolean | null;
    canAccessRestrictedCalls?: boolean | null;
    canAccessPrivateCalls?: boolean | null;
  }>;
  positionRoles: Array<{
    __typename?: "PositionRole";
    id: any;
    name: PositionRoleName;
    permissions: Array<PositionRolePermission>;
    formattedName?: string | null;
    formattedPermissions?: string | null;
    canAccessTeamPublicCalls?: boolean | null;
    canAccessTeamRestrictedCalls?: boolean | null;
    canAccessTeamPrivateCalls?: boolean | null;
  }>;
  planUserRoles: Array<{
    __typename?: "PlanUserRole";
    id: any;
    name: PlanUserRoleName;
    permissions: Array<PlanUserRolePermission>;
    formattedName?: string | null;
    formattedPermissions?: string | null;
  }>;
  featureAccess?: Array<{
    __typename?: "OrganizationFeatureAccess";
    id: string;
    name: FeatureName;
    settingLevel: FeatureSettingLevel;
    isVisible: boolean;
    showCta: boolean;
    marketingRedirect: boolean;
  }> | null;
};

export type PageInfoFragment = {
  __typename?: "CursorPaginationInfo";
  hasNextPage?: boolean | null;
  hasPreviousPage?: boolean | null;
  nextPageStartId?: string | null;
  nextPageStartTimestamp?: any | null;
};

export type PlaylistShareListItemFragment = {
  __typename?: "PlaylistShare";
  id: any;
  relativeUrl: string;
  createdAt: any;
  playlist: {
    __typename?: "Playlist";
    id: any;
    title: string;
    createdBy: { __typename?: "User"; id: any; fullName: string };
  };
  firstClip: {
    __typename?: "Clip";
    id: any;
    displayName: string;
    duration: number;
    thumbnailImageUrl?: string | null;
  };
};

export type PositionFragment = {
  __typename?: "Position";
  id: any;
  title: string;
  displayTitle: string;
  createdAt: any;
  greenhouseId?: number | null;
  greenhouseLink?: string | null;
  leverPostingId?: string | null;
  mergeType?: Ats | null;
  client: { __typename?: "Client"; id: any; name: string };
  candidates: Array<{
    __typename?: "Candidate";
    id: any;
    fullName: string;
    firstName: string;
    lastName: string;
    defaultEmail?: string | null;
    defaultPhoneNumber?: string | null;
    rating?: number | null;
    skipRedaction: boolean;
    callCount?: number | null;
    validCallsCount?: number | null;
    recordingDisabled: boolean;
    positionAverageScore?: number | null;
    lastCall?: {
      __typename?: "Call";
      id: any;
      name?: string | null;
      type: CallType;
      startTime?: any | null;
      createdAt: any;
      duration?: number | null;
      status: CallStatus;
      transcriptionStatus?: TranscriptionStatus | null;
      recordingDisabled: boolean;
      rating: number;
      interviewerIds: Array<string>;
      interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
      position?: {
        __typename?: "Position";
        id: any;
        displayTitle: string;
      } | null;
    } | null;
  }>;
  callGuides: Array<{
    __typename?: "CallGuide";
    canEdit: boolean;
    id: any;
    name?: string | null;
    isTemplate: boolean;
    visibility: CallGuideVisibility;
    createdAt: any;
    updatedAt: any;
    greenhouseId?: number | null;
    leverFeedbackTemplateId?: string | null;
    ashbyFeedbackFormId?: string | null;
    smartrecruitersCriteriaId?: string | null;
    atsId?: string | null;
    overallScoringEnabled: boolean;
    position?: {
      __typename?: "Position";
      id: any;
      title: string;
      displayTitle: string;
    } | null;
    creator?: { __typename?: "User"; id: any; fullName: string } | null;
    cues: Array<{
      __typename?: "CallGuideCue";
      id: any;
      cue: string;
      description: string;
      isHtmlType: boolean;
      position: number;
      scoringEnabled: boolean;
      type: GuideItemType;
      competencyId?: any | null;
      childItems: Array<{
        __typename?: "CallGuideItemChild";
        id: any;
        description: string;
        position: number;
      }>;
    }>;
    assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
    interviewStage?: {
      __typename?: "InterviewStage";
      id: any;
      title: string;
    } | null;
  }>;
  videos: Array<{ __typename?: "Video"; url: string }>;
};

export type PositionJbpListItemFragment = {
  __typename?: "Position";
  id: any;
  title: string;
  requisitionId?: string | null;
  locations?: Array<string | null> | null;
  createdAt: any;
  displayTitle: string;
  greenhouseId?: number | null;
  greenhouseLink?: string | null;
  leverPostingId?: string | null;
  autoJoinInterview: boolean;
  atsStatus?: string | null;
  client: {
    __typename?: "Client";
    id: any;
    name: string;
    autoJoinAllNewPositions: boolean;
  };
};

export type PositionListItemFragment = {
  __typename?: "Position";
  id: any;
  title: string;
  createdAt: any;
  displayTitle: string;
  client: { __typename?: "Client"; id: any; name: string };
};

export type PositionPageListItemFragment = {
  __typename?: "Position";
  id: any;
  title: string;
  createdAt: any;
  lastCallAt?: any | null;
  displayTitle: string;
  greenhouseId?: number | null;
  greenhouseLink?: string | null;
  leverPostingId?: string | null;
  autoJoinInterview: boolean;
  atsStatus?: string | null;
  candidatesCount?: number | null;
  callGuidesCount?: number | null;
  client: {
    __typename?: "Client";
    id: any;
    name: string;
    autoJoinAllNewPositions: boolean;
  };
};

export type PositionTitleFragment = {
  __typename?: "Position";
  id: any;
  displayTitle: string;
};

export type RedactionPredictionFragment = {
  __typename?: "RedactionPrediction";
  id: any;
  startTime: number;
  endTime: number;
  text: string;
  modelTopic: RedactionModelTopic;
  friendlyTopic: string;
  viewed: boolean;
  speaker?: { __typename?: "CallSpeaker"; label: string } | null;
  call?: {
    __typename?: "Call";
    id: any;
    name?: string | null;
    startTime?: any | null;
  } | null;
};

export type ScheduledInterviewListItemFragment = {
  __typename?: "ScheduledInterview";
  id: string;
  name?: string | null;
  scheduledStart?: any | null;
  timezoneName?: string | null;
  scheduledEnd?: any | null;
  durationMinutes?: number | null;
  phoneNumber?: string | null;
  zoomId?: number | null;
  zoomJoinUrl?: string | null;
  zoomMeetingUrl?: string | null;
  zoomAppAutoOpenEnabled: boolean;
  googleMeetMeetingUrl?: string | null;
  teamsMeetingUrl?: string | null;
  isVideo: boolean;
  canAddBrighthire?: boolean | null;
  canAddBrighthireReason?: string | null;
  canLaunchMeeting?: boolean | null;
  canLaunchMeetingReason?: string | null;
  isExcluded: boolean;
  isImported: boolean;
  importDecision: boolean;
  importDecisionReason?: InterviewImportDecisionReason | null;
  greenhouseLink?: string | null;
  ghJobStageName?: string | null;
  optInUrl?: string | null;
  leverInterviewId?: string | null;
  smartrecruitersInterviewLink?: string | null;
  isInPersonInterview: boolean;
  interviewType?: InterviewType | null;
  isInterviewer: boolean;
  recordingDisabled: boolean;
  scheduledType: ScheduledInterviewType;
  leverStage?: {
    __typename?: "LeverStage";
    id: any;
    text?: string | null;
  } | null;
  scheduledInterviewers: Array<{
    __typename?: "ScheduledInterviewer";
    id: string;
    recordingDisabled: boolean;
    name?: string | null;
    user?: {
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
    } | null;
  }>;
  callGuide?: { __typename?: "CallGuide"; id: any } | null;
  candidate?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
    fullName: string;
    callCount?: number | null;
    defaultPhoneNumber?: string | null;
    recordingDisabled: boolean;
  } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
  googleMeetMeetings: Array<{ __typename?: "GoogleMeetMeeting"; id: string }>;
  teamsNotetakerMeetings: Array<{
    __typename?: "TeamsNotetakerMeeting";
    id: string;
  }>;
};

export type TrackerGroupFragment = {
  __typename?: "TrackerGroup";
  id: any;
  name: string;
  count?: number | null;
  trackerKeywords: Array<{
    __typename?: "TrackerKeyword";
    id: any;
    name: string;
    count?: number | null;
    speakerCounts: Array<{
      __typename?: "TrackerSpeakerCount";
      speakerTag: number;
      count: number;
    }>;
  }>;
};

export type TranscriptSegmentFragment = {
  __typename?: "TranscriptSegment";
  id: any;
  speakerTag: number;
  startTime: number;
  endTime: number;
  languageCode?: string | null;
  words: Array<{
    __typename?: "TranscriptWord";
    word: string;
    startTime: number;
    endTime: number;
    confidence?: number | null;
    isRedacted?: boolean | null;
    rawWord?: string | null;
  }>;
};

export type UserAvatarFragment = {
  __typename?: "User";
  id: any;
  fullName: string;
  firstName?: string | null;
  lastName?: string | null;
  profilePicUrl?: string | null;
};

export type UserCallingMetricsFragment = {
  __typename?: "User";
  id: any;
  callingMetrics: {
    __typename?: "UserCallingMetrics";
    numSignificantCalls: number;
    totalDuration: number;
    interviewerTalkRatio?: number | null;
    interviewerLongestMonologue?: number | null;
    interviewerWordsPerMinute?: number | null;
    callInteractivity?: number | null;
    weeklyCalls: number;
    weeklyDuration: number;
  };
};

export type UserFragment = {
  __typename?: "User";
  id: any;
  firstName?: string | null;
  lastName?: string | null;
  fullName: string;
  email: string;
  profilePicUrl?: string | null;
  phoneNumber?: string | null;
  phoneNumberExtension?: string | null;
  phoneNumberVerified: boolean;
  isCalendarAuthorized: boolean;
  createdAt: any;
  deletedAt?: any | null;
  signUpMethod: UserSignUpMethod;
  signUpMethodLabel: string;
  signUpCompletedAt?: any | null;
  voipPhoneNumber?: string | null;
  invitedBy?: {
    __typename?: "User";
    id: any;
    fullName: string;
    email: string;
  } | null;
};

export type UserInfoFragment = {
  __typename?: "User";
  id: any;
  firstName?: string | null;
  lastName?: string | null;
  fullName: string;
  email: string;
  profilePicUrl?: string | null;
  phoneNumber?: string | null;
};

export type ViewableClipFragment = {
  __typename?: "Clip";
  canEditNotes: boolean;
  canShare: boolean;
  canShareExternal: boolean;
  hasTraining: boolean;
  greenhouseScorecardLinkWithId?: string | null;
  leverInterviewLinkWithId?: string | null;
  skipRedaction: boolean;
  rating: number;
  id: any;
  callId: any;
  userId: any;
  startTime: number;
  endTime: number;
  recordingStatus: ClipRecordingStatus;
  failureReason?: string | null;
  createdAt: any;
  updatedAt: any;
  visibility: CallVisibility;
  visibleVisibilityLevels: Array<CallVisibility>;
  thumbnailImageUrl?: string | null;
  canDelete: boolean;
  canEdit: boolean;
  name?: string | null;
  displayName: string;
  iaVersion: number;
  interviewerIds: Array<string>;
  callStartTime?: any | null;
  callEndTime?: any | null;
  callName?: string | null;
  callRecordingDisabled: boolean;
  callPhoneNumber?: string | null;
  callType: CallType;
  notes: Array<{
    __typename?: "CallNote";
    id: any;
    callId: any;
    clipId?: any | null;
    time: number;
    highlightStartTime?: number | null;
    highlightEndTime?: number | null;
    speakerTag?: number | null;
    text: string;
    type: CallNoteType;
    description?: string | null;
    isEdited: boolean;
    visibility: CallNoteVisibility;
    guideItemId?: any | null;
    parentGuideItemId?: any | null;
    scorecardQuestionId?: any | null;
    questionId?: any | null;
    createdAt: any;
    updatedAt: any;
    score?: number | null;
    user?: {
      __typename?: "User";
      id: any;
      profilePicUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
    } | null;
    replies: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      text: string;
      isEdited: boolean;
      createdAt: any;
      visibility: CallNoteVisibility;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      } | null;
    }>;
  }>;
  questions: Array<{
    __typename?: "CallNote";
    id: any;
    description?: string | null;
    time: number;
    questionNotes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
  }>;
  generalNotes: Array<{
    __typename?: "CallNote";
    id: any;
    callId: any;
    clipId?: any | null;
    time: number;
    highlightStartTime?: number | null;
    highlightEndTime?: number | null;
    speakerTag?: number | null;
    text: string;
    type: CallNoteType;
    description?: string | null;
    isEdited: boolean;
    visibility: CallNoteVisibility;
    guideItemId?: any | null;
    parentGuideItemId?: any | null;
    scorecardQuestionId?: any | null;
    questionId?: any | null;
    createdAt: any;
    updatedAt: any;
    score?: number | null;
    user?: {
      __typename?: "User";
      id: any;
      profilePicUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
    } | null;
    replies: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      text: string;
      isEdited: boolean;
      createdAt: any;
      visibility: CallNoteVisibility;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      } | null;
    }>;
  }>;
  interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
  streamableAudio?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  streamableVideo?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  user: { __typename?: "User"; id: any; fullName: string };
  viewers: Array<{
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    email: string;
    profilePicUrl?: string | null;
    phoneNumber?: string | null;
    phoneNumberExtension?: string | null;
    phoneNumberVerified: boolean;
    isCalendarAuthorized: boolean;
    createdAt: any;
    deletedAt?: any | null;
    signUpMethod: UserSignUpMethod;
    signUpMethodLabel: string;
    signUpCompletedAt?: any | null;
    voipPhoneNumber?: string | null;
    invitedBy?: {
      __typename?: "User";
      id: any;
      fullName: string;
      email: string;
    } | null;
  }>;
  callSpeakers: Array<{
    __typename?: "CallSpeaker";
    id: any;
    speakerTag: number;
    label: string;
    isCandidate: boolean;
    isInterviewer?: boolean | null;
    identified: boolean;
    talkTimePercentage: number;
    longestMonologue?: number | null;
  }>;
  candidate?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
    fullName: string;
    greenhouseId?: number | null;
    greenhouseLink?: string | null;
    lastLeverOpportunityId?: string | null;
  } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
};

export type VirtualOnsiteFragment = {
  __typename?: "VirtualOnsite";
  id: string;
  meetingUrl: string;
  nextScheduledInterview?: {
    __typename?: "ScheduledInterview";
    id: string;
  } | null;
  scheduledInterviews: Array<{
    __typename?: "ScheduledInterview";
    id: string;
    name?: string | null;
    isInterviewer: boolean;
    scheduledStart?: any | null;
    scheduledEnd?: any | null;
    scheduledInterviewers: Array<{
      __typename?: "ScheduledInterviewer";
      id: string;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
    inProgressMeeting?: {
      __typename?: "NotetakerMeeting";
      id: string;
      callId?: string | null;
    } | null;
    pendingMeeting?: { __typename?: "NotetakerMeeting"; id: string } | null;
    joiningMeeting?: { __typename?: "NotetakerMeeting"; id: string } | null;
    completedMeeting?: { __typename?: "NotetakerMeeting"; id: string } | null;
  }>;
};

export type MyInsightsDatumFragment = {
  __typename?: "ReportDataPoint";
  id: string;
  organizationId?: string | null;
  xLabel?: string | null;
  totalCalls?: number | null;
  score?: number | null;
  onTimeStarts?: number | null;
  talkRatio?: number | null;
  longestMonologue?: number | null;
  isAgendaSet?: number | null;
  candidateQuestionOpportunity?: number | null;
  problematicQuestions?: number | null;
  speakingRate?: number | null;
  interactivity?: number | null;
  questionsAsked?: number | null;
  feedbackSubmissionTime?: number | null;
  feedbackSubmissionRate?: number | null;
  onTimeStartsRaw?: number | null;
  talkRatioRaw?: number | null;
  longestMonologueRaw?: number | null;
  isAgendaSetRaw?: number | null;
  candidateQuestionOpportunityRaw?: number | null;
  problematicQuestionsRaw?: number | null;
  speakingRateRaw?: number | null;
  interactivityRaw?: number | null;
  questionsAskedRaw?: number | null;
  feedbackSubmissionTimeRaw?: number | null;
  feedbackSubmissionRateRaw?: number | null;
};

export type ReportDatumFragment = {
  __typename?: "ReportDataPoint";
  id: string;
  organizationId?: string | null;
  xLabel?: string | null;
  totalCalls?: number | null;
  score?: number | null;
  interviewerIds?: Array<string> | null;
  onTimeStarts?: number | null;
  talkRatio?: number | null;
  longestMonologue?: number | null;
  isAgendaSet?: number | null;
  candidateQuestionOpportunity?: number | null;
  problematicQuestions?: number | null;
  speakingRate?: number | null;
  interactivity?: number | null;
  questionsAsked?: number | null;
  feedbackSubmissionTime?: number | null;
  feedbackSubmissionRate?: number | null;
};

export type ReportPositionOptionFragment = {
  __typename?: "Position";
  id: any;
  title: string;
  createdAt: any;
  displayTitle: string;
  requisitionId?: string | null;
  atsStatus?: string | null;
  greenhouseId?: number | null;
  leverPostingId?: string | null;
  smartrecruitersId?: string | null;
  mergeId?: string | null;
  hasJobPostingDescription: boolean;
  client: { __typename?: "Client"; id: any; name: string };
};

export type SkillsReportWithResultsFragment = {
  __typename?: "SkillReport";
  id: any;
  title: string;
  source: SkillReportSkillsSource;
  creatorId: any;
  minimumInterviewCountFilter?: number | null;
  interviewStageFilters?: any | null;
  dateRangeStart: any;
  dateRangeEnd: any;
  skills: Array<{
    __typename?: "SkillReportSkill";
    id: any;
    name: string;
    description?: string | null;
  }>;
  positions: Array<{
    __typename?: "Position";
    id: any;
    title: string;
    createdAt: any;
    displayTitle: string;
    requisitionId?: string | null;
    atsStatus?: string | null;
    greenhouseId?: number | null;
    leverPostingId?: string | null;
    smartrecruitersId?: string | null;
    mergeId?: string | null;
    hasJobPostingDescription: boolean;
    client: { __typename?: "Client"; id: any; name: string };
  }>;
  results: Array<{
    __typename?: "SkillReportResultItem";
    candidateId: any;
    candidateName: string;
    numCalls: number;
    coverage?: any | null;
  }>;
};

export type CallBetaFragment = {
  __typename?: "Call";
  id: any;
  aiNotesSupportedFormats: Array<CallAiSummaryFormat>;
  greenhouseScorecardLink?: string | null;
  greenhouseScorecardLinkWithId?: string | null;
  leverInterviewLink?: string | null;
  leverInterviewLinkWithId?: string | null;
  smartrecruitersInterviewLink?: string | null;
  ashbyFeedbackLinkWithId?: string | null;
  customAtsUrl?: string | null;
  transcriptionStatus?: TranscriptionStatus | null;
  transcriptLanguageCode?: string | null;
  aiNotesProcessingStatus?: AiNotesProcessingStatus | null;
  interviewerIds: Array<string>;
  isProcessing: boolean;
  iaVersion: number;
  duration?: number | null;
  canEdit: boolean;
  canShare: boolean;
  canShareExternal: boolean;
  clipsCount: number;
  type: CallType;
  recordingDisabled: boolean;
  status: CallStatus;
  startTime?: any | null;
  endTime?: any | null;
  name?: string | null;
  rating: number;
  visibility: CallVisibility;
  skipRedaction: boolean;
  canDelete: boolean;
  scoringEnabled: boolean;
  visibilityLevels: Array<CallVisibility>;
  streamableAudio?: { __typename?: "CallMedia"; url: string } | null;
  streamableVideo?: { __typename?: "CallMedia"; url: string } | null;
  aiNotesCustomFormat: {
    __typename?: "AiNotesFormat";
    format: CallAiSummaryFormat;
    customTemplateId?: string | null;
  };
  speakers: Array<{
    __typename?: "CallSpeaker";
    id: any;
    speakerTag: number;
    label: string;
    isCandidate: boolean;
    isInterviewer?: boolean | null;
    identified: boolean;
    talkTimePercentage: number;
    longestMonologue?: number | null;
  }>;
  candidate?: {
    __typename?: "Candidate";
    id: any;
    fullName: string;
    greenhouseLink?: string | null;
    lastLeverOpportunityId?: string | null;
    candidateLinks: Array<{
      __typename?: "CandidateLink";
      id: any;
      link: string;
      type: CandidateLinkType;
    }>;
  } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
  interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
  viewers: Array<{
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    profilePicUrl?: string | null;
    email: string;
  }>;
  externalViewers: Array<{
    __typename?: "ExternalUser";
    id: any;
    email: string;
  }>;
  aiTopicsStatuses?: Array<{
    __typename?: "CallAiTopicStatus";
    id: string;
    topic: AiTopic;
    status: AiNotesProcessingStatus;
  }> | null;
};

export type CallCandidateFragment = {
  __typename?: "Candidate";
  id: any;
  fullName: string;
  greenhouseLink?: string | null;
  lastLeverOpportunityId?: string | null;
  candidateLinks: Array<{
    __typename?: "CandidateLink";
    id: any;
    link: string;
    type: CandidateLinkType;
  }>;
};

export type CallClipFragment = {
  __typename?: "Clip";
  id: any;
  name?: string | null;
  callId: any;
  startTime: number;
  endTime: number;
  thumbnailImageUrl?: string | null;
  canEdit: boolean;
  canDelete: boolean;
  visibility: CallVisibility;
  visibleVisibilityLevels: Array<CallVisibility>;
};

export type IaCallStatusFragment = {
  __typename?: "Call";
  id: any;
  twilioCallSid?: string | null;
  type: CallType;
  status: CallStatus;
  phoneNumber?: string | null;
  recordingDisabled: boolean;
  recordingPaused: boolean;
  recordingPauseInProgress: boolean;
  startTime?: any | null;
  iaVersion: number;
};

export type InPersonScheduledInterviewFragment = {
  __typename?: "ScheduledInterview";
  id: string;
  name?: string | null;
  candidate?: { __typename?: "Candidate"; id: any } | null;
  position?: { __typename?: "Position"; id: any } | null;
  callGuide?: { __typename?: "CallGuide"; id: any } | null;
  scheduledInterviewers: Array<{
    __typename?: "ScheduledInterviewer";
    id: string;
    user?: {
      __typename?: "User";
      id: any;
      fullName: string;
      email: string;
    } | null;
  }>;
};

export type CandidateSummaryFragment = {
  __typename?: "CandidateSummary";
  id: any;
  status: CandidateSummaryProcessingStatus;
  notes: Array<{
    __typename?: "CandidateSummaryNote";
    id: any;
    text: string;
    topic?: string | null;
    subtopic?: string | null;
    position: number;
    citations: Array<{
      __typename?: "CandidateSummaryCitation";
      id: any;
      callId: string;
      time: number;
    }>;
  }>;
  calls: Array<{ __typename?: "Call"; id: any }>;
};

export type CandidateSummaryTemplateFragment = {
  __typename?: "CandidateSummaryTemplate";
  id: any;
  name: string;
  isOrgDefault: boolean;
  isPositionDefault?: boolean | null;
  processingStatus?: CandidateSummaryTemplateStatus | null;
  creator?: { __typename?: "User"; id: any; fullName: string } | null;
  sections: Array<{
    __typename?: "CandidateSummaryTemplateSection";
    id: any;
    position: number;
    title: string;
    prompt: Array<string | null>;
    subsections: Array<{
      __typename?: "CandidateSummaryTemplateSubsection";
      id: any;
      position: number;
      title: string;
      prompt: Array<string | null>;
    }>;
  }>;
  positions: Array<{
    __typename?: "Position";
    id: any;
    title: string;
    candidateSummaryTemplateId?: string | null;
  }>;
};

export type CandidateHighlightFragment = {
  __typename?: "Call";
  id: any;
  highlights: Array<
    | {
        __typename?: "AiNoteHighlight";
        tags?: Array<string> | null;
        id: any;
        thumbnailImageUrl?: string | null;
        startTime: number;
        endTime?: number | null;
        text: string;
        description: string;
      }
    | {
        __typename?: "CallNoteHighlight";
        type: CallNoteType;
        id: any;
        thumbnailImageUrl?: string | null;
        startTime: number;
        endTime?: number | null;
        text: string;
        description: string;
      }
  >;
};

export type CandidateInterviewFragment = {
  __typename?: "Call";
  id: any;
  name?: string | null;
  createdAt: any;
  startTime?: any | null;
  duration?: number | null;
  thumbnailImageUrl?: string | null;
  isInterviewer: boolean;
  greenhouseScorecardLink?: string | null;
  greenhouseScorecardLinkWithId?: string | null;
  greenhouseUserRating?: string | null;
  leverInterviewLink?: string | null;
  leverInterviewLinkWithId?: string | null;
  canDelete: boolean;
  positionId?: any | null;
  visibility: CallVisibility;
  visibleVisibilityLevels: Array<CallVisibility>;
  myScore?: number | null;
  averageScore?: number | null;
  needsCurrentUserScore?: boolean | null;
  hasPendingScores?: boolean | null;
  notes: Array<{ __typename?: "CallNote"; id: any; type: CallNoteType }>;
  interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
  streamableAudio?: { __typename?: "CallMedia"; url: string } | null;
  streamableVideo?: {
    __typename?: "CallMedia";
    url: string;
    height?: number | null;
    width?: number | null;
  } | null;
};

type CallHighlight_AiNoteHighlight_Fragment = {
  __typename?: "AiNoteHighlight";
  tags?: Array<string> | null;
  id: any;
  thumbnailImageUrl?: string | null;
  startTime: number;
  endTime?: number | null;
  text: string;
  description: string;
};

type CallHighlight_CallNoteHighlight_Fragment = {
  __typename?: "CallNoteHighlight";
  type: CallNoteType;
  id: any;
  thumbnailImageUrl?: string | null;
  startTime: number;
  endTime?: number | null;
  text: string;
  description: string;
};

export type CallHighlightFragment =
  | CallHighlight_AiNoteHighlight_Fragment
  | CallHighlight_CallNoteHighlight_Fragment;

export type CandidateSearchResultFragment = {
  __typename?: "Candidate";
  id: any;
  fullName: string;
  defaultEmail?: string | null;
  defaultPhoneNumber?: string | null;
};

export type ClipBetaFragment = {
  __typename?: "Clip";
  id: any;
  name?: string | null;
  displayName: string;
  startTime: number;
  endTime: number;
  duration: number;
  callStartTime?: any | null;
  recordingStatus: ClipRecordingStatus;
  interviewerIds: Array<string>;
  canEdit: boolean;
  canDelete: boolean;
  canShare: boolean;
  canShareExternal: boolean;
  visibility: CallVisibility;
  visibleVisibilityLevels: Array<CallVisibility>;
  streamableAudio?: { __typename?: "CallMedia"; url: string } | null;
  streamableVideo?: { __typename?: "CallMedia"; url: string } | null;
  candidate?: { __typename?: "Candidate"; id: any; fullName: string } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
  interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
  viewers: Array<{
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    profilePicUrl?: string | null;
    email: string;
  }>;
  externalViewers: Array<{
    __typename?: "ExternalUser";
    id: any;
    email: string;
  }>;
  speakers: Array<{
    __typename?: "CallSpeaker";
    id: any;
    speakerTag: number;
    label: string;
    isCandidate: boolean;
    isInterviewer?: boolean | null;
    identified: boolean;
    talkTimePercentage: number;
    longestMonologue?: number | null;
  }>;
};

export type ExternalCallFragment = {
  __typename?: "ExternalCall";
  id: string;
  iaVersion: number;
  canEdit: boolean;
  name?: string | null;
  duration: number;
  startTime?: any | null;
  endTime?: any | null;
  transcriptLanguageCode?: string | null;
  type: CallType;
  pointsOfInterest: Array<{
    __typename?: "TrackerGroup";
    id: any;
    name: string;
    count?: number | null;
    trackerKeywords: Array<{
      __typename?: "TrackerKeyword";
      id: any;
      name: string;
      count?: number | null;
      speakerCounts: Array<{
        __typename?: "TrackerSpeakerCount";
        speakerTag: number;
        count: number;
      }>;
    }>;
  }>;
  interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
  speakers: Array<{
    __typename?: "CallSpeaker";
    id: any;
    speakerTag: number;
    label: string;
    isCandidate: boolean;
    isInterviewer?: boolean | null;
    identified: boolean;
    talkTimePercentage: number;
    longestMonologue?: number | null;
  }>;
  streamableAudio?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  streamableVideo?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  transcript: Array<{
    __typename?: "TranscriptSegment";
    id: any;
    speakerTag: number;
    startTime: number;
    endTime: number;
    languageCode?: string | null;
    words: Array<{
      __typename?: "TranscriptWord";
      word: string;
      startTime: number;
      endTime: number;
      confidence?: number | null;
      isRedacted?: boolean | null;
      rawWord?: string | null;
    }>;
  }>;
  candidate?: { __typename?: "Candidate"; id: any; fullName: string } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
};

export type ExternalClipFragment = {
  __typename?: "ExternalClip";
  id: string;
  iaVersion: number;
  canEdit: boolean;
  name?: string | null;
  duration: number;
  endTime?: any | null;
  type: CallType;
  interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
  speakers: Array<{
    __typename?: "CallSpeaker";
    id: any;
    speakerTag: number;
    label: string;
    isCandidate: boolean;
    isInterviewer?: boolean | null;
    identified: boolean;
    talkTimePercentage: number;
    longestMonologue?: number | null;
  }>;
  streamableAudio?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  streamableVideo?: {
    __typename?: "CallMedia";
    url: string;
    expiration: any;
  } | null;
  transcript: Array<{
    __typename?: "TranscriptSegment";
    id: any;
    speakerTag: number;
    startTime: number;
    endTime: number;
    languageCode?: string | null;
    words: Array<{
      __typename?: "TranscriptWord";
      word: string;
      startTime: number;
      endTime: number;
      confidence?: number | null;
      isRedacted?: boolean | null;
      rawWord?: string | null;
    }>;
  }>;
  candidate?: { __typename?: "Candidate"; id: any; fullName: string } | null;
  position?: { __typename?: "Position"; id: any; displayTitle: string } | null;
  pointsOfInterest: Array<{
    __typename?: "TrackerGroup";
    id: any;
    name: string;
    count?: number | null;
    trackerKeywords: Array<{
      __typename?: "TrackerKeyword";
      id: any;
      name: string;
      count?: number | null;
      speakerCounts: Array<{
        __typename?: "TrackerSpeakerCount";
        speakerTag: number;
        count: number;
      }>;
    }>;
  }>;
};

export type CallGuideCueFragment = {
  __typename?: "CallGuideCue";
  id: any;
  cue: string;
  description: string;
  isHtmlType: boolean;
  position: number;
  scoringEnabled: boolean;
  type: GuideItemType;
  competencyId?: any | null;
  childItems: Array<{
    __typename?: "CallGuideItemChild";
    id: any;
    description: string;
    position: number;
  }>;
};

export type CallGuideFragment = {
  __typename?: "CallGuide";
  id: any;
  name?: string | null;
  visibility: CallGuideVisibility;
  isTemplate: boolean;
  canEdit: boolean;
  canManageCallGuideAssignments: boolean;
  createdAt: any;
  updatedAt: any;
  greenhouseId?: number | null;
  leverFeedbackTemplateId?: string | null;
  customAtsId?: string | null;
  ashbyFeedbackFormId?: string | null;
  smartrecruitersCriteriaId?: string | null;
  atsId?: string | null;
  overallScoringEnabled: boolean;
  position?: {
    __typename?: "Position";
    id: any;
    title: string;
    displayTitle: string;
    client: { __typename?: "Client"; id: any; name: string };
  } | null;
  creator?: { __typename?: "User"; id: any; fullName: string } | null;
  assignedUsers: Array<{
    __typename?: "CallGuideUser";
    createdAt: any;
    userId: any;
    user: {
      __typename?: "User";
      id: any;
      createdAt: any;
      lastName?: string | null;
      fullName: string;
      profilePicUrl?: string | null;
      email: string;
    };
  }>;
  cues: Array<{
    __typename?: "CallGuideCue";
    id: any;
    cue: string;
    description: string;
    isHtmlType: boolean;
    position: number;
    scoringEnabled: boolean;
    type: GuideItemType;
    competencyId?: any | null;
    childItems: Array<{
      __typename?: "CallGuideItemChild";
      id: any;
      description: string;
      position: number;
    }>;
  }>;
  organization: {
    __typename?: "Organization";
    id: any;
    scoringEnabled: boolean;
  };
  interviewStage?: {
    __typename?: "InterviewStage";
    id: any;
    title: string;
  } | null;
};

export type CallGuideItemChildFragment = {
  __typename?: "CallGuideItemChild";
  id: any;
  description: string;
  position: number;
};

export type CallGuideListItemFragment = {
  __typename?: "CallGuide";
  canEdit: boolean;
  id: any;
  name?: string | null;
  isTemplate: boolean;
  visibility: CallGuideVisibility;
  createdAt: any;
  updatedAt: any;
  greenhouseId?: number | null;
  leverFeedbackTemplateId?: string | null;
  ashbyFeedbackFormId?: string | null;
  smartrecruitersCriteriaId?: string | null;
  atsId?: string | null;
  overallScoringEnabled: boolean;
  position?: {
    __typename?: "Position";
    id: any;
    title: string;
    displayTitle: string;
  } | null;
  creator?: { __typename?: "User"; id: any; fullName: string } | null;
  cues: Array<{
    __typename?: "CallGuideCue";
    id: any;
    cue: string;
    description: string;
    isHtmlType: boolean;
    position: number;
    scoringEnabled: boolean;
    type: GuideItemType;
    competencyId?: any | null;
    childItems: Array<{
      __typename?: "CallGuideItemChild";
      id: any;
      description: string;
      position: number;
    }>;
  }>;
  assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
  interviewStage?: {
    __typename?: "InterviewStage";
    id: any;
    title: string;
  } | null;
};

export type CurrentCallScorecardFragment = {
  __typename?: "Scorecard";
  id: any;
  overallScoringEnabled: boolean;
  overallScore?: number | null;
  overallNotes?: string | null;
  items: Array<{
    __typename?: "ScorecardItem";
    id: string;
    type?: GuideItemType | null;
    itemText: string;
    isHtmlType?: boolean | null;
    scoringEnabled?: boolean | null;
    score?: number | null;
    notes?: string | null;
    marked?: boolean | null;
    markedTime?: number | null;
    questionNotes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
    childItems: Array<{
      __typename?: "ScorecardItem";
      id: string;
      itemText: string;
      isHtmlType?: boolean | null;
      scoringEnabled?: boolean | null;
      score?: number | null;
      notes?: string | null;
      marked?: boolean | null;
      markedTime?: number | null;
      questionNotes: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        time: number;
        highlightStartTime?: number | null;
        highlightEndTime?: number | null;
        speakerTag?: number | null;
        text: string;
        type: CallNoteType;
        description?: string | null;
        isEdited: boolean;
        visibility: CallNoteVisibility;
        guideItemId?: any | null;
        parentGuideItemId?: any | null;
        scorecardQuestionId?: any | null;
        questionId?: any | null;
        createdAt: any;
        updatedAt: any;
        score?: number | null;
        user?: {
          __typename?: "User";
          id: any;
          profilePicUrl?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
        } | null;
        replies: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          clipId?: any | null;
          text: string;
          isEdited: boolean;
          createdAt: any;
          visibility: CallNoteVisibility;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
          } | null;
        }>;
      }>;
    }>;
  }>;
};

export type CurrentCallScorecardItemFragment = {
  __typename?: "ScorecardItem";
  id: string;
  type?: GuideItemType | null;
  itemText: string;
  isHtmlType?: boolean | null;
  scoringEnabled?: boolean | null;
  score?: number | null;
  notes?: string | null;
  marked?: boolean | null;
  markedTime?: number | null;
  questionNotes: Array<{
    __typename?: "CallNote";
    id: any;
    callId: any;
    clipId?: any | null;
    time: number;
    highlightStartTime?: number | null;
    highlightEndTime?: number | null;
    speakerTag?: number | null;
    text: string;
    type: CallNoteType;
    description?: string | null;
    isEdited: boolean;
    visibility: CallNoteVisibility;
    guideItemId?: any | null;
    parentGuideItemId?: any | null;
    scorecardQuestionId?: any | null;
    questionId?: any | null;
    createdAt: any;
    updatedAt: any;
    score?: number | null;
    user?: {
      __typename?: "User";
      id: any;
      profilePicUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
    } | null;
    replies: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      text: string;
      isEdited: boolean;
      createdAt: any;
      visibility: CallNoteVisibility;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      } | null;
    }>;
  }>;
  childItems: Array<{
    __typename?: "ScorecardItem";
    id: string;
    itemText: string;
    isHtmlType?: boolean | null;
    scoringEnabled?: boolean | null;
    score?: number | null;
    notes?: string | null;
    marked?: boolean | null;
    markedTime?: number | null;
    questionNotes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
  }>;
};

export type InterviewerScorecardFragment = {
  __typename?: "Scorecard";
  id: any;
  updatedAt: any;
  overallScoringEnabled: boolean;
  overallScore?: number | null;
  overallNotes?: string | null;
  interviewer: { __typename?: "User"; id: any; fullName: string };
  competencies: Array<{
    __typename?: "ScorecardItem";
    id: string;
    type?: GuideItemType | null;
    itemText: string;
    marked?: boolean | null;
    markedTime?: number | null;
    scoringEnabled?: boolean | null;
    score?: number | null;
    notes?: string | null;
    updatedAt?: any | null;
  }>;
};

export type ScorecardItemFragment = {
  __typename?: "ScorecardItem";
  id: string;
  type?: GuideItemType | null;
  itemText: string;
  marked?: boolean | null;
  markedTime?: number | null;
  scoringEnabled?: boolean | null;
  score?: number | null;
  notes?: string | null;
  updatedAt?: any | null;
};

export type CallShareFragment = {
  __typename?: "CallShare";
  id: any;
  createdAt: any;
  updatedAt: any;
  sharedTo: {
    __typename?: "User";
    id: any;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    profilePicUrl?: string | null;
  };
};

export type ClipShareFragment = {
  __typename?: "ClipShare";
  id: any;
  createdAt: any;
  updatedAt: any;
  sharedTo: {
    __typename?: "User";
    id: any;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    profilePicUrl?: string | null;
  };
};

export type ExternalCallShareFragment = {
  __typename?: "ExternalCallShare";
  id: any;
  createdAt: any;
  updatedAt: any;
  isExpired: boolean;
  shareDaysRemaining: number;
  daysRemaining: number;
  sharedToExternalUser: { __typename?: "ExternalUser"; id: any; email: string };
  sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
};

export type ExternalClipShareFragment = {
  __typename?: "ExternalClipShare";
  id: any;
  createdAt: any;
  updatedAt: any;
  isExpired: boolean;
  shareDaysRemaining: number;
  daysRemaining: number;
  sharedToExternalUser: { __typename?: "ExternalUser"; id: any; email: string };
  sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
};

export type TaskDefinitionFragment = {
  __typename?: "TaskDefinition";
  id: any;
  type: TaskDefinitionType;
  configuration: any;
};

export type TaskRunFragment = {
  __typename?: "TaskRun";
  id: any;
  output: any;
  status?: TaskRunStatus | null;
  statusMessage?: string | null;
};

export type CallTrainingFragment = {
  __typename?: "CallTraining";
  trainingProgramId: string;
  trainingProgramName: string;
  trainingProgramItemId: string;
  lastSubmittedAt?: string | null;
  canSubmit: boolean;
  questions: Array<{
    __typename?: "CallQuestionAnswer";
    questionId: string;
    question: string;
    answer: string;
  }>;
  deletedQuestions: Array<{
    __typename?: "CallQuestionAnswer";
    questionId: string;
    question: string;
    answer: string;
  }>;
};

export type TrainingProgramFragment = {
  __typename?: "TrainingProgram";
  id: any;
  name: string;
  description?: string | null;
  updatedAt: any;
  launchedAt?: any | null;
  assessmentEnabled: boolean;
  totalDuration?: number | null;
  trainingProgramQuestions: Array<{
    __typename?: "TrainingProgramQuestion";
    id: any;
    order: number;
    question: string;
    createdAt: any;
    updatedAt: any;
  }>;
  trainingProgramDeletedQuestions: Array<{
    __typename?: "TrainingProgramQuestion";
    id: any;
    order: number;
    question: string;
  }>;
  owner: {
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    email: string;
    profilePicUrl?: string | null;
  };
  trainingProgramItems: Array<{
    __typename?: "TrainingProgramItem";
    id: string;
    description?: string | null;
    name?: string | null;
    order?: number | null;
    isClip: boolean;
    url: string;
    positionDisplayTitle?: string | null;
    createdAt: any;
    contentCreatedAt: any;
    contentRedactedAt?: any | null;
    hasRecordings: boolean;
    audioOnly: boolean;
    duration: number;
    thumbnailImageUrl?: string | null;
    viewed?: boolean | null;
    assessmentCompleted?: boolean | null;
    assessmentOutdated?: boolean | null;
    interviewers: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      profilePicUrl?: string | null;
    }>;
  }>;
  trainingProgramTrainees: Array<{
    __typename?: "TrainingProgramTrainee";
    id: any;
    createdAt: any;
    user: {
      __typename?: "User";
      id: any;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
    };
  }>;
};

export type TrainingProgramItemFragment = {
  __typename?: "TrainingProgramItem";
  id: string;
  description?: string | null;
  name?: string | null;
  order?: number | null;
  isClip: boolean;
  url: string;
  positionDisplayTitle?: string | null;
  createdAt: any;
  contentCreatedAt: any;
  contentRedactedAt?: any | null;
  hasRecordings: boolean;
  audioOnly: boolean;
  duration: number;
  thumbnailImageUrl?: string | null;
  viewed?: boolean | null;
  assessmentCompleted?: boolean | null;
  assessmentOutdated?: boolean | null;
  interviewers: Array<{
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    profilePicUrl?: string | null;
  }>;
};

export type TrainingProgramTraineeFragment = {
  __typename?: "TrainingProgramTrainee";
  id: any;
  createdAt: any;
  markedCompletedAt?: any | null;
  trainingProgramAnswers: Array<{
    __typename?: "TrainingProgramAnswer";
    trainingProgramQuestionId: any;
    trainingProgramItemId: any;
    answer: string;
    createdAt: any;
  }>;
  user: {
    __typename?: "User";
    id: any;
    fullName: string;
    lastName?: string | null;
    trainingProgramItemViews: Array<{
      __typename?: "TrainingProgramItemView";
      id: any;
      questionAnswer?: string | null;
      createdAt: any;
      trainingProgramItem: {
        __typename?: "TrainingProgramItem";
        id: string;
        trainingProgramId: any;
      };
    }>;
  };
};

export type AddAlertViewMutationVariables = Exact<{
  organizationId: Scalars["ID"];
  type: Scalars["String"];
  aggregation: Scalars["String"];
  aggregationIds: Array<Scalars["String"]> | Scalars["String"];
  valence: Scalars["String"];
  category: Scalars["String"];
  segment: Scalars["String"];
}>;

export type AddAlertViewMutation = {
  __typename?: "Mutation";
  addAlertView?: { __typename?: "AddAlertView"; success: boolean } | null;
};

export type AddBrightHireToInterviewsMutationVariables = Exact<{
  scheduledInterviewIds: Array<Scalars["ID"]> | Scalars["ID"];
  source: Scalars["String"];
}>;

export type AddBrightHireToInterviewsMutation = {
  __typename?: "Mutation";
  addBrightHireToInterviews?: {
    __typename?: "AddBrightHireToInterviews";
    source: string;
    scheduledInterviews?: Array<{
      __typename?: "ScheduledInterview";
      id: string;
      name?: string | null;
      scheduledStart?: any | null;
      timezoneName?: string | null;
      scheduledEnd?: any | null;
      durationMinutes?: number | null;
      phoneNumber?: string | null;
      zoomId?: number | null;
      zoomJoinUrl?: string | null;
      zoomMeetingUrl?: string | null;
      zoomAppAutoOpenEnabled: boolean;
      googleMeetMeetingUrl?: string | null;
      teamsMeetingUrl?: string | null;
      isVideo: boolean;
      canAddBrighthire?: boolean | null;
      canAddBrighthireReason?: string | null;
      canLaunchMeeting?: boolean | null;
      canLaunchMeetingReason?: string | null;
      isExcluded: boolean;
      isImported: boolean;
      importDecision: boolean;
      importDecisionReason?: InterviewImportDecisionReason | null;
      greenhouseLink?: string | null;
      ghJobStageName?: string | null;
      optInUrl?: string | null;
      leverInterviewId?: string | null;
      smartrecruitersInterviewLink?: string | null;
      isInPersonInterview: boolean;
      interviewType?: InterviewType | null;
      isInterviewer: boolean;
      recordingDisabled: boolean;
      scheduledType: ScheduledInterviewType;
      leverStage?: {
        __typename?: "LeverStage";
        id: any;
        text?: string | null;
      } | null;
      scheduledInterviewers: Array<{
        __typename?: "ScheduledInterviewer";
        id: string;
        recordingDisabled: boolean;
        name?: string | null;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
          phoneNumber?: string | null;
        } | null;
      }>;
      callGuide?: { __typename?: "CallGuide"; id: any } | null;
      candidate?: {
        __typename?: "Candidate";
        id: any;
        firstName: string;
        lastName: string;
        fullName: string;
        callCount?: number | null;
        defaultPhoneNumber?: string | null;
        recordingDisabled: boolean;
      } | null;
      position?: {
        __typename?: "Position";
        id: any;
        displayTitle: string;
      } | null;
      googleMeetMeetings: Array<{
        __typename?: "GoogleMeetMeeting";
        id: string;
      }>;
      teamsNotetakerMeetings: Array<{
        __typename?: "TeamsNotetakerMeeting";
        id: string;
      }>;
    }> | null;
    errors?: Array<{
      __typename?: "AddBrightHireToInterviewsError";
      id: string;
      message?: string | null;
    }> | null;
  } | null;
};

export type AddBullhornCredentialsMutationVariables = Exact<{
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
  username: Scalars["String"];
  password: Scalars["String"];
}>;

export type AddBullhornCredentialsMutation = {
  __typename?: "Mutation";
  addBullhornCredentials?: {
    __typename?: "AddBullhornCredentials";
    authUrl?: string | null;
    currentUser: {
      __typename?: "User";
      organization: {
        __typename?: "Organization";
        isBullhornAuthorized: boolean;
      };
    };
  } | null;
};

export type AddCallMutationVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  candidateFirstName?: InputMaybe<Scalars["String"]>;
  candidateLastName?: InputMaybe<Scalars["String"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  candidateId?: InputMaybe<Scalars["ID"]>;
  callType: Scalars["String"];
  callGuideId?: InputMaybe<Scalars["ID"]>;
  positionId?: InputMaybe<Scalars["ID"]>;
  greenhouseScorecardLink?: InputMaybe<Scalars["String"]>;
  bullhornCommentAction?: InputMaybe<Scalars["String"]>;
  leverInterviewLink?: InputMaybe<Scalars["String"]>;
  smartrecruitersInterviewLink?: InputMaybe<Scalars["String"]>;
  scheduledInterviewId?: InputMaybe<Scalars["ID"]>;
  iaVersion?: InputMaybe<Scalars["Float"]>;
  atsRequisitionId?: InputMaybe<Scalars["String"]>;
}>;

export type AddCallMutation = {
  __typename?: "Mutation";
  addCall?: {
    __typename?: "AddCall";
    call: { __typename?: "Call"; id: any; type: CallType };
  } | null;
};

export type AddCallNoteHighlightFeedbackMutationVariables = Exact<{
  id: Scalars["ID"];
  response: Scalars["String"];
}>;

export type AddCallNoteHighlightFeedbackMutation = {
  __typename?: "Mutation";
  addCallNoteHighlightFeedback?: {
    __typename?: "AddCallNoteHighlightFeedback";
    callNote: { __typename?: "CallNote"; id: any; type: CallNoteType };
  } | null;
};

export type AddCallNoteReplyMutationVariables = Exact<{
  parentCallNoteId: Scalars["ID"];
  text: Scalars["String"];
  visibility: CallNoteVisibility;
}>;

export type AddCallNoteReplyMutation = {
  __typename?: "Mutation";
  addCallNoteReply?: {
    __typename?: "AddCallNoteReply";
    callNote: {
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      text: string;
      isEdited: boolean;
      createdAt: any;
      visibility: CallNoteVisibility;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      } | null;
    };
  } | null;
};

export type AddCandidateAlertUserSubscriptionsMutationVariables = Exact<{
  candidateIds: Array<Scalars["ID"]> | Scalars["ID"];
  positionIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type AddCandidateAlertUserSubscriptionsMutation = {
  __typename?: "Mutation";
  addCandidateAlertUserSubscriptions?: {
    __typename?: "AddCandidateAlertUserSubscriptions";
    subscriptions: {
      __typename?: "CandidateAlertUserSubscriptions";
      id: string;
      candidateSubscriptions: Array<{
        __typename?: "CandidateAlertCandidateSubscription";
        id: any;
        candidateId: any;
        mutedAt?: any | null;
      }>;
      positionSubscriptions: Array<{
        __typename?: "CandidateAlertPositionSubscription";
        id: any;
        positionId: any;
        mutedAt?: any | null;
      }>;
    };
  } | null;
};

export type AddClientMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type AddClientMutation = {
  __typename?: "Mutation";
  addClient?: {
    __typename?: "AddClient";
    client: { __typename?: "Client"; id: any; name: string };
  } | null;
};

export type AddGuideToCallMutationVariables = Exact<{
  callId: Scalars["ID"];
  callGuideId: Scalars["ID"];
}>;

export type AddGuideToCallMutation = {
  __typename?: "Mutation";
  updateCall?: {
    __typename?: "UpdateCall";
    call: {
      __typename?: "Call";
      id: any;
      callGuide?: {
        __typename?: "CallGuide";
        id: any;
        name?: string | null;
        visibility: CallGuideVisibility;
        isTemplate: boolean;
        canEdit: boolean;
        canManageCallGuideAssignments: boolean;
        createdAt: any;
        updatedAt: any;
        greenhouseId?: number | null;
        leverFeedbackTemplateId?: string | null;
        customAtsId?: string | null;
        ashbyFeedbackFormId?: string | null;
        smartrecruitersCriteriaId?: string | null;
        atsId?: string | null;
        overallScoringEnabled: boolean;
        position?: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
          client: { __typename?: "Client"; id: any; name: string };
        } | null;
        creator?: { __typename?: "User"; id: any; fullName: string } | null;
        assignedUsers: Array<{
          __typename?: "CallGuideUser";
          createdAt: any;
          userId: any;
          user: {
            __typename?: "User";
            id: any;
            createdAt: any;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
            email: string;
          };
        }>;
        cues: Array<{
          __typename?: "CallGuideCue";
          id: any;
          cue: string;
          description: string;
          isHtmlType: boolean;
          position: number;
          scoringEnabled: boolean;
          type: GuideItemType;
          competencyId?: any | null;
          childItems: Array<{
            __typename?: "CallGuideItemChild";
            id: any;
            description: string;
            position: number;
          }>;
        }>;
        organization: {
          __typename?: "Organization";
          id: any;
          scoringEnabled: boolean;
        };
        interviewStage?: {
          __typename?: "InterviewStage";
          id: any;
          title: string;
        } | null;
      } | null;
      scorecard?: {
        __typename?: "Scorecard";
        id: any;
        overallScoringEnabled: boolean;
        overallScore?: number | null;
        overallNotes?: string | null;
        items: Array<{
          __typename?: "ScorecardItem";
          id: string;
          type?: GuideItemType | null;
          itemText: string;
          isHtmlType?: boolean | null;
          scoringEnabled?: boolean | null;
          score?: number | null;
          notes?: string | null;
          marked?: boolean | null;
          markedTime?: number | null;
          questionNotes: Array<{
            __typename?: "CallNote";
            id: any;
            callId: any;
            clipId?: any | null;
            time: number;
            highlightStartTime?: number | null;
            highlightEndTime?: number | null;
            speakerTag?: number | null;
            text: string;
            type: CallNoteType;
            description?: string | null;
            isEdited: boolean;
            visibility: CallNoteVisibility;
            guideItemId?: any | null;
            parentGuideItemId?: any | null;
            scorecardQuestionId?: any | null;
            questionId?: any | null;
            createdAt: any;
            updatedAt: any;
            score?: number | null;
            user?: {
              __typename?: "User";
              id: any;
              profilePicUrl?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              fullName: string;
            } | null;
            replies: Array<{
              __typename?: "CallNote";
              id: any;
              callId: any;
              clipId?: any | null;
              text: string;
              isEdited: boolean;
              createdAt: any;
              visibility: CallNoteVisibility;
              user?: {
                __typename?: "User";
                id: any;
                firstName?: string | null;
                lastName?: string | null;
                fullName: string;
                profilePicUrl?: string | null;
              } | null;
            }>;
          }>;
          childItems: Array<{
            __typename?: "ScorecardItem";
            id: string;
            itemText: string;
            isHtmlType?: boolean | null;
            scoringEnabled?: boolean | null;
            score?: number | null;
            notes?: string | null;
            marked?: boolean | null;
            markedTime?: number | null;
            questionNotes: Array<{
              __typename?: "CallNote";
              id: any;
              callId: any;
              clipId?: any | null;
              time: number;
              highlightStartTime?: number | null;
              highlightEndTime?: number | null;
              speakerTag?: number | null;
              text: string;
              type: CallNoteType;
              description?: string | null;
              isEdited: boolean;
              visibility: CallNoteVisibility;
              guideItemId?: any | null;
              parentGuideItemId?: any | null;
              scorecardQuestionId?: any | null;
              questionId?: any | null;
              createdAt: any;
              updatedAt: any;
              score?: number | null;
              user?: {
                __typename?: "User";
                id: any;
                profilePicUrl?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                fullName: string;
              } | null;
              replies: Array<{
                __typename?: "CallNote";
                id: any;
                callId: any;
                clipId?: any | null;
                text: string;
                isEdited: boolean;
                createdAt: any;
                visibility: CallNoteVisibility;
                user?: {
                  __typename?: "User";
                  id: any;
                  firstName?: string | null;
                  lastName?: string | null;
                  fullName: string;
                  profilePicUrl?: string | null;
                } | null;
              }>;
            }>;
          }>;
        }>;
      } | null;
      notes: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        time: number;
        highlightStartTime?: number | null;
        highlightEndTime?: number | null;
        speakerTag?: number | null;
        text: string;
        type: CallNoteType;
        description?: string | null;
        isEdited: boolean;
        visibility: CallNoteVisibility;
        guideItemId?: any | null;
        parentGuideItemId?: any | null;
        scorecardQuestionId?: any | null;
        questionId?: any | null;
        createdAt: any;
        updatedAt: any;
        score?: number | null;
        user?: {
          __typename?: "User";
          id: any;
          profilePicUrl?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
        } | null;
        replies: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          clipId?: any | null;
          text: string;
          isEdited: boolean;
          createdAt: any;
          visibility: CallNoteVisibility;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
          } | null;
        }>;
      }>;
      generalNotes: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        time: number;
        highlightStartTime?: number | null;
        highlightEndTime?: number | null;
        speakerTag?: number | null;
        text: string;
        type: CallNoteType;
        description?: string | null;
        isEdited: boolean;
        visibility: CallNoteVisibility;
        guideItemId?: any | null;
        parentGuideItemId?: any | null;
        scorecardQuestionId?: any | null;
        questionId?: any | null;
        createdAt: any;
        updatedAt: any;
        score?: number | null;
        user?: {
          __typename?: "User";
          id: any;
          profilePicUrl?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
        } | null;
        replies: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          clipId?: any | null;
          text: string;
          isEdited: boolean;
          createdAt: any;
          visibility: CallNoteVisibility;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
          } | null;
        }>;
      }>;
    };
  } | null;
};

export type AddInterviewAssistantOpensMutationVariables = Exact<{
  callId: Scalars["ID"];
  entryPoint: InterviewAssistantEntryPoint;
}>;

export type AddInterviewAssistantOpensMutation = {
  __typename?: "Mutation";
  addInterviewAssistantOpens: {
    __typename?: "AddInterviewAssistantOpens";
    success: boolean;
  };
};

export type AddNewCallNoteMutationVariables = Exact<{
  callId: Scalars["ID"];
  time: Scalars["Float"];
  text: Scalars["String"];
  type: CallNoteType;
  visibility: CallNoteVisibility;
  description?: InputMaybe<Scalars["String"]>;
  guideItemId?: InputMaybe<Scalars["ID"]>;
  clipId?: InputMaybe<Scalars["ID"]>;
  parentGuideItemId?: InputMaybe<Scalars["ID"]>;
  questionId?: InputMaybe<Scalars["ID"]>;
  scorecardQuestionId?: InputMaybe<Scalars["ID"]>;
  markQuestion?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddNewCallNoteMutation = {
  __typename?: "Mutation";
  addNewCallNote?: {
    __typename?: "AddNewCallNote";
    callNote: {
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    };
  } | null;
};

export type AddPositionMutationVariables = Exact<{
  clientId: Scalars["ID"];
  title: Scalars["String"];
}>;

export type AddPositionMutation = {
  __typename?: "Mutation";
  addPosition?: {
    __typename?: "AddPosition";
    position: {
      __typename?: "Position";
      id: any;
      title: string;
      createdAt: any;
      displayTitle: string;
      client: { __typename?: "Client"; id: any; name: string };
    };
  } | null;
};

export type AddInterviewStageMutationVariables = Exact<{
  title: Scalars["String"];
}>;

export type AddInterviewStageMutation = {
  __typename?: "Mutation";
  addInterviewStage?: {
    __typename?: "AddInterviewStage";
    interviewStage: {
      __typename?: "InterviewStage";
      id: any;
      title: string;
      createdAt: any;
    };
  } | null;
};

export type AshbyCandidateParamsMutationVariables = Exact<{
  candidateId: Scalars["String"];
  phoneNumber: Scalars["String"];
  applicationId?: InputMaybe<Scalars["String"]>;
}>;

export type AshbyCandidateParamsMutation = {
  __typename?: "Mutation";
  ashbyCandidateParams?: {
    __typename?: "AshbyCandidateParams";
    params?: {
      __typename?: "CallParams";
      candidateFirstName?: string | null;
      candidateLastName?: string | null;
      phoneNumber?: string | null;
      candidateEmail?: string | null;
      positionId?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type AuthorizeZoomBotMutationVariables = Exact<{
  authCode: Scalars["String"];
}>;

export type AuthorizeZoomBotMutation = {
  __typename?: "Mutation";
  authorizeZoomBot?: {
    __typename?: "AuthorizeZoomBot";
    organization: {
      __typename?: "Organization";
      id: any;
      isZoomBotAuthorized: boolean;
    };
  } | null;
};

export type AuthorizeZoomMutationVariables = Exact<{
  authCode: Scalars["String"];
}>;

export type AuthorizeZoomMutation = {
  __typename?: "Mutation";
  authorizeZoom?: {
    __typename?: "AuthorizeZoom";
    organization: {
      __typename?: "Organization";
      id: any;
      isZoomAuthorized: boolean;
    };
  } | null;
};

export type BullhornCallParamsMutationVariables = Exact<{
  candidateId: Scalars["String"];
  userId?: InputMaybe<Scalars["String"]>;
  entityType?: InputMaybe<Scalars["String"]>;
}>;

export type BullhornCallParamsMutation = {
  __typename?: "Mutation";
  bullhornCallParams?: {
    __typename?: "BullhornCallParams";
    bullhornCallParams?: {
      __typename?: "CallParams";
      phoneNumber?: string | null;
      candidateFirstName?: string | null;
      candidateLastName?: string | null;
      candidateEmail?: string | null;
      name?: string | null;
      positionId?: string | null;
      candidateId?: string | null;
    } | null;
  } | null;
};

export type CancelRecordingMutationVariables = Exact<{
  slug: Scalars["String"];
}>;

export type CancelRecordingMutation = {
  __typename?: "Mutation";
  cancelRecording: { __typename?: "CancelRecording"; success: boolean };
};

export type ClipCallMutationVariables = Exact<{
  callId: Scalars["ID"];
  clipStart: Scalars["Float"];
  clipEnd: Scalars["Float"];
  name?: InputMaybe<Scalars["String"]>;
}>;

export type ClipCallMutation = {
  __typename?: "Mutation";
  clipCall?: {
    __typename?: "ClipCall";
    clip: {
      __typename?: "Clip";
      id: any;
      callId: any;
      userId: any;
      startTime: number;
      endTime: number;
      recordingStatus: ClipRecordingStatus;
      failureReason?: string | null;
      createdAt: any;
      updatedAt: any;
      visibility: CallVisibility;
      visibleVisibilityLevels: Array<CallVisibility>;
      thumbnailImageUrl?: string | null;
      canDelete: boolean;
      canEdit: boolean;
      name?: string | null;
      displayName: string;
      iaVersion: number;
      interviewerIds: Array<string>;
      callStartTime?: any | null;
      callEndTime?: any | null;
      callName?: string | null;
      callRecordingDisabled: boolean;
      callPhoneNumber?: string | null;
      callType: CallType;
      streamableAudio?: {
        __typename?: "CallMedia";
        url: string;
        expiration: any;
      } | null;
      streamableVideo?: {
        __typename?: "CallMedia";
        url: string;
        expiration: any;
      } | null;
      user: { __typename?: "User"; id: any; fullName: string };
      viewers: Array<{
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
        phoneNumber?: string | null;
        phoneNumberExtension?: string | null;
        phoneNumberVerified: boolean;
        isCalendarAuthorized: boolean;
        createdAt: any;
        deletedAt?: any | null;
        signUpMethod: UserSignUpMethod;
        signUpMethodLabel: string;
        signUpCompletedAt?: any | null;
        voipPhoneNumber?: string | null;
        invitedBy?: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
        } | null;
      }>;
      callSpeakers: Array<{
        __typename?: "CallSpeaker";
        id: any;
        speakerTag: number;
        label: string;
        isCandidate: boolean;
        isInterviewer?: boolean | null;
        identified: boolean;
        talkTimePercentage: number;
        longestMonologue?: number | null;
      }>;
      candidate?: {
        __typename?: "Candidate";
        id: any;
        firstName: string;
        lastName: string;
        fullName: string;
        greenhouseId?: number | null;
        greenhouseLink?: string | null;
        lastLeverOpportunityId?: string | null;
      } | null;
      position?: {
        __typename?: "Position";
        id: any;
        displayTitle: string;
      } | null;
    };
  } | null;
};

export type CreateAiNoteTagsMutationVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type CreateAiNoteTagsMutation = {
  __typename?: "Mutation";
  createAiNoteTags?: {
    __typename?: "CreateAiNoteTags";
    success: boolean;
  } | null;
};

export type CreateAiNotesMutationVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type CreateAiNotesMutation = {
  __typename?: "Mutation";
  createAiNotes?: { __typename?: "CreateAiNotes"; success: boolean } | null;
};

export type CreateAiTopicsMutationVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type CreateAiTopicsMutation = {
  __typename?: "Mutation";
  createAiTopics?: { __typename?: "CreateAiTopics"; success: boolean } | null;
};

export type CreateExcludeStageMutationVariables = Exact<{
  excludeText: Scalars["String"];
}>;

export type CreateExcludeStageMutation = {
  __typename?: "Mutation";
  createExcludeStage?: {
    __typename?: "CreateExcludeStage";
    excludeStage: { __typename?: "ExcludeStage"; id: any; excludeText: string };
  } | null;
};

export type CreateLeverOauthRequestUrlMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateLeverOauthRequestUrlMutation = {
  __typename?: "Mutation";
  createLeverOauthRequestUrl?: {
    __typename?: "CreateLeverOauthRequestUrl";
    leverOauthUrl: string;
  } | null;
};

export type CreateMergeLinkTokenMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateMergeLinkTokenMutation = {
  __typename?: "Mutation";
  createMergeLinkToken?: {
    __typename?: "CreateMergeLinkToken";
    linkToken: string;
  } | null;
};

export type CreateOrUpdateExcludedUserMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type CreateOrUpdateExcludedUserMutation = {
  __typename?: "Mutation";
  createOrUpdateExcludedUser?: {
    __typename?: "CreateOrUpdateExcludedUser";
    excludedUser: {
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      phoneNumberVerified: boolean;
      isCalendarAuthorized: boolean;
      createdAt: any;
      deletedAt?: any | null;
      signUpMethod: UserSignUpMethod;
      signUpMethodLabel: string;
      signUpCompletedAt?: any | null;
      voipPhoneNumber?: string | null;
      invitedBy?: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
      } | null;
    };
  } | null;
};

export type CreateScheduledInterviewMutationVariables = Exact<{
  scheduledInterviewInput: ScheduledInterviewInput;
}>;

export type CreateScheduledInterviewMutation = {
  __typename?: "Mutation";
  createScheduledInterview?: {
    __typename?: "CreateScheduledInterview";
    scheduledInterview?: {
      __typename?: "ScheduledInterview";
      id: string;
      name?: string | null;
      scheduledStart?: any | null;
      timezoneName?: string | null;
      scheduledEnd?: any | null;
      durationMinutes?: number | null;
      phoneNumber?: string | null;
      zoomId?: number | null;
      zoomJoinUrl?: string | null;
      zoomMeetingUrl?: string | null;
      zoomAppAutoOpenEnabled: boolean;
      googleMeetMeetingUrl?: string | null;
      teamsMeetingUrl?: string | null;
      isVideo: boolean;
      canAddBrighthire?: boolean | null;
      canAddBrighthireReason?: string | null;
      canLaunchMeeting?: boolean | null;
      canLaunchMeetingReason?: string | null;
      isExcluded: boolean;
      isImported: boolean;
      importDecision: boolean;
      importDecisionReason?: InterviewImportDecisionReason | null;
      greenhouseLink?: string | null;
      ghJobStageName?: string | null;
      optInUrl?: string | null;
      leverInterviewId?: string | null;
      smartrecruitersInterviewLink?: string | null;
      isInPersonInterview: boolean;
      interviewType?: InterviewType | null;
      isInterviewer: boolean;
      recordingDisabled: boolean;
      scheduledType: ScheduledInterviewType;
      leverStage?: {
        __typename?: "LeverStage";
        id: any;
        text?: string | null;
      } | null;
      scheduledInterviewers: Array<{
        __typename?: "ScheduledInterviewer";
        id: string;
        recordingDisabled: boolean;
        name?: string | null;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
          phoneNumber?: string | null;
        } | null;
      }>;
      callGuide?: { __typename?: "CallGuide"; id: any } | null;
      candidate?: {
        __typename?: "Candidate";
        id: any;
        firstName: string;
        lastName: string;
        fullName: string;
        callCount?: number | null;
        defaultPhoneNumber?: string | null;
        recordingDisabled: boolean;
      } | null;
      position?: {
        __typename?: "Position";
        id: any;
        displayTitle: string;
      } | null;
      googleMeetMeetings: Array<{
        __typename?: "GoogleMeetMeeting";
        id: string;
      }>;
      teamsNotetakerMeetings: Array<{
        __typename?: "TeamsNotetakerMeeting";
        id: string;
      }>;
    } | null;
  } | null;
};

export type CreateScheduledInterviewZoomNotetakerMeetingMutationVariables =
  Exact<{
    scheduledInterviewInput: ScheduledInterviewInput;
  }>;

export type CreateScheduledInterviewZoomNotetakerMeetingMutation = {
  __typename?: "Mutation";
  createScheduledInterviewZoomNotetakerMeeting?: {
    __typename?: "CreateScheduledInterviewZoomNotetakerMeeting";
    meeting?: {
      __typename?: "ZoomNotetakerMeeting";
      id: any;
      meetingUrl?: string | null;
    } | null;
  } | null;
};

export type DeauthorizeLeverMutationVariables = Exact<{ [key: string]: never }>;

export type DeauthorizeLeverMutation = {
  __typename?: "Mutation";
  deauthorizeLever?: {
    __typename?: "DeauthorizeLever";
    success: boolean;
  } | null;
};

export type DeauthorizeMergeMutationVariables = Exact<{
  accountId: Scalars["String"];
}>;

export type DeauthorizeMergeMutation = {
  __typename?: "Mutation";
  deauthorizeMerge?: {
    __typename?: "DeauthorizeMerge";
    success: boolean;
  } | null;
};

export type DeleteApiTokenMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteApiTokenMutation = {
  __typename?: "Mutation";
  deleteApiToken?: {
    __typename?: "DeleteApiToken";
    setup: {
      __typename?: "RestApiSetup";
      apiTokens: Array<{
        __typename?: "OrganizationApiToken";
        id: string;
        name?: string | null;
        tokenPrefix?: string | null;
      }>;
      webhooks: Array<{
        __typename?: "WebhookConfiguration";
        id: string;
        webhookUrl?: string | null;
        webhookSecret?: string | null;
        eventInterviewCompleted: boolean;
        eventCandidateUpdatedRecordingDisabled: boolean;
      }>;
    };
  } | null;
};

export type DeleteCalendarOrgSettingsMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCalendarOrgSettingsMutation = {
  __typename?: "Mutation";
  deleteCalendarOrgSettings?: {
    __typename?: "DeleteCalendarOrgSettings";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type DeleteCallNoteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCallNoteMutation = {
  __typename?: "Mutation";
  deleteCallNote?: {
    __typename?: "DeleteCallNote";
    callNote: { __typename?: "CallNote"; id: any };
  } | null;
};

export type DeleteClipMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteClipMutation = {
  __typename?: "Mutation";
  deleteClip?: {
    __typename?: "DeleteClip";
    clip: {
      __typename?: "Clip";
      id: any;
      call: { __typename?: "Call"; id: any };
    };
  } | null;
};

export type DeleteExcludeStageMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteExcludeStageMutation = {
  __typename?: "Mutation";
  deleteExcludeStage?: {
    __typename?: "DeleteExcludeStage";
    excludeStage: { __typename?: "ExcludeStage"; id: any; excludeText: string };
  } | null;
};

export type DeleteScheduledInterviewMutationVariables = Exact<{
  scheduledInterviewId: Scalars["ID"];
}>;

export type DeleteScheduledInterviewMutation = {
  __typename?: "Mutation";
  deleteScheduledInterview?: {
    __typename?: "DeleteScheduledInterview";
    scheduledInterview: { __typename?: "ScheduledInterview"; id: string };
  } | null;
};

export type DeleteTrainingProgramTraineeMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteTrainingProgramTraineeMutation = {
  __typename?: "Mutation";
  deleteTrainingProgramTrainee?: {
    __typename?: "DeleteTrainingProgramTrainee";
    deletedTraineeId: string;
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type DeleteUsersMutationVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeleteUsersMutation = {
  __typename?: "Mutation";
  deleteUsers?: { __typename?: "DeleteUsers"; success: boolean } | null;
};

export type DeleteWebhookMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteWebhookMutation = {
  __typename?: "Mutation";
  deleteWebhook?: {
    __typename?: "DeleteWebhook";
    setup: {
      __typename?: "RestApiSetup";
      apiTokens: Array<{
        __typename?: "OrganizationApiToken";
        id: string;
        name?: string | null;
        tokenPrefix?: string | null;
      }>;
      webhooks: Array<{
        __typename?: "WebhookConfiguration";
        id: string;
        webhookUrl?: string | null;
        webhookSecret?: string | null;
        eventInterviewCompleted: boolean;
        eventCandidateUpdatedRecordingDisabled: boolean;
      }>;
    };
  } | null;
};

export type DisableCallRecordingMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DisableCallRecordingMutation = {
  __typename?: "Mutation";
  updateCall?: {
    __typename?: "UpdateCall";
    call: { __typename?: "Call"; id: any; recordingDisabled: boolean };
  } | null;
};

export type EndCallMutationVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type EndCallMutation = {
  __typename?: "Mutation";
  endCall?: {
    __typename?: "EndCall";
    call: {
      __typename?: "Call";
      id: any;
      status: CallStatus;
      endTime?: any | null;
      duration?: number | null;
    };
  } | null;
};

export type ExternalUserRequestAccessCodeMutationVariables = Exact<{
  externalShareId: Scalars["ID"];
}>;

export type ExternalUserRequestAccessCodeMutation = {
  __typename?: "Mutation";
  externalUserRequestAccessCode?: {
    __typename?: "ExternalUserRequestAccessCode";
    verificationSent: boolean;
  } | null;
};

export type ExternalUserRequestShareRenewalMutationVariables = Exact<{
  externalShareId: Scalars["ID"];
}>;

export type ExternalUserRequestShareRenewalMutation = {
  __typename?: "Mutation";
  externalUserRequestShareRenewal?: {
    __typename?: "ExternalUserRequestShareRenewal";
    requestSent: boolean;
  } | null;
};

export type ExternalUserViewCallMutationVariables = Exact<{
  externalShareId: Scalars["ID"];
}>;

export type ExternalUserViewCallMutation = {
  __typename?: "Mutation";
  externalUserViewCall?: {
    __typename?: "ExternalUserViewCall";
    callViewed: boolean;
    firstExternalView: boolean;
  } | null;
};

export type ExternalUserViewClipMutationVariables = Exact<{
  externalShareId: Scalars["ID"];
}>;

export type ExternalUserViewClipMutation = {
  __typename?: "Mutation";
  externalUserViewClip?: {
    __typename?: "ExternalUserViewClip";
    clipViewed: boolean;
    firstExternalView: boolean;
  } | null;
};

export type GenerateGreenhouseWebhookSecretMutationVariables = Exact<{
  [key: string]: never;
}>;

export type GenerateGreenhouseWebhookSecretMutation = {
  __typename?: "Mutation";
  generateGreenhouseWebhookSecret?: {
    __typename?: "GenerateGreenhouseWebhookSecret";
    webhookSecretKey: string;
  } | null;
};

export type GenerateTokenMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateTokenMutation = {
  __typename?: "Mutation";
  generateToken?: {
    __typename?: "GenerateToken";
    setup: {
      __typename?: "SingleSignOnSetup";
      oktaDomain?: string | null;
      metadataUrl?: string | null;
      scimToken?: string | null;
    };
  } | null;
};

export type GreenhouseCallParamsMutationVariables = Exact<{
  applicationId?: InputMaybe<Scalars["String"]>;
  interviewKitId?: InputMaybe<Scalars["String"]>;
  scorecardUrl?: InputMaybe<Scalars["String"]>;
}>;

export type GreenhouseCallParamsMutation = {
  __typename?: "Mutation";
  greenhouseCallParams?: {
    __typename?: "GreenhouseCallParams";
    params?: {
      __typename?: "CallParams";
      name?: string | null;
      candidateFirstName?: string | null;
      candidateLastName?: string | null;
      phoneNumber?: string | null;
      callGuideId?: string | null;
      positionId?: string | null;
      greenhouseScorecardLink?: string | null;
      scheduledInterviewId?: string | null;
      candidateId?: string | null;
    } | null;
  } | null;
};

export type GreenhouseCandidateParamsMutationVariables = Exact<{
  candidateId: Scalars["String"];
  phoneNumber: Scalars["String"];
  applicationId?: InputMaybe<Scalars["String"]>;
}>;

export type GreenhouseCandidateParamsMutation = {
  __typename?: "Mutation";
  greenhouseCandidateParams?: {
    __typename?: "GreenhouseCandidateParams";
    params?: {
      __typename?: "CallParams";
      name?: string | null;
      candidateFirstName?: string | null;
      candidateLastName?: string | null;
      phoneNumber?: string | null;
      callGuideId?: string | null;
      positionId?: string | null;
      greenhouseScorecardLink?: string | null;
      scheduledInterviewId?: string | null;
      candidateId?: string | null;
    } | null;
  } | null;
};

export type GreenhouseFreemiumSyncMutationVariables = Exact<{
  [key: string]: never;
}>;

export type GreenhouseFreemiumSyncMutation = {
  __typename?: "Mutation";
  greenhouseFreemiumSync?: {
    __typename?: "GreenhouseFreemiumSync";
    processed: boolean;
  } | null;
};

export type GreenhousePhoneNumberParamsMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
  scorecardUrl?: InputMaybe<Scalars["String"]>;
}>;

export type GreenhousePhoneNumberParamsMutation = {
  __typename?: "Mutation";
  greenhousePhoneNumberParams?: {
    __typename?: "GreenhousePhoneNumberParams";
    params?: {
      __typename?: "CallParams";
      name?: string | null;
      candidateFirstName?: string | null;
      candidateLastName?: string | null;
      phoneNumber?: string | null;
      callGuideId?: string | null;
      positionId?: string | null;
      greenhouseScorecardLink?: string | null;
      scheduledInterviewId?: string | null;
      candidateId?: string | null;
    } | null;
  } | null;
};

export type IcimsCandidateParamsMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
  candidateFullName?: InputMaybe<Scalars["String"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  atsCandidateId?: InputMaybe<Scalars["String"]>;
}>;

export type IcimsCandidateParamsMutation = {
  __typename?: "Mutation";
  icimsCandidateParams?: {
    __typename?: "IcimsCandidateParams";
    params?: {
      __typename?: "CallParams";
      candidateFirstName?: string | null;
      candidateLastName?: string | null;
      phoneNumber?: string | null;
      candidateEmail?: string | null;
      positionId?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type ImportGoogleMeetMeetingMutationVariables = Exact<{
  meetingUrl: Scalars["String"];
  meetingId?: InputMaybe<Scalars["String"]>;
  entryPoint?: InputMaybe<InterviewAssistantEntryPoint>;
  forceImport?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ImportGoogleMeetMeetingMutation = {
  __typename?: "Mutation";
  importGoogleMeetMeeting?: {
    __typename?: "ImportGoogleMeetMeeting";
    meeting?: {
      __typename?: "GoogleMeetMeeting";
      id: string;
      importMeeting: boolean;
      exclude: boolean;
      excludeReason?: ExcludeReason | null;
      meetingUrl?: string | null;
      call?: { __typename?: "Call"; id: any } | null;
    } | null;
  } | null;
};

export type ImportTeamsNotetakerMeetingMutationVariables = Exact<{
  meetingUrl: Scalars["String"];
  meetingId?: InputMaybe<Scalars["String"]>;
  entryPoint?: InputMaybe<InterviewAssistantEntryPoint>;
  forceImport?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ImportTeamsNotetakerMeetingMutation = {
  __typename?: "Mutation";
  importTeamsNotetakerMeeting?: {
    __typename?: "ImportTeamsNotetakerMeeting";
    meeting?: {
      __typename?: "TeamsNotetakerMeeting";
      id: string;
      importMeeting: boolean;
      exclude: boolean;
      excludeReason?: ExcludeReason | null;
      meetingUrl?: string | null;
      call?: { __typename?: "Call"; id: any } | null;
    } | null;
  } | null;
};

export type ImportZoomMeetingMutationVariables = Exact<{
  meetingId: Scalars["Float"];
  entryPoint?: InputMaybe<InterviewAssistantEntryPoint>;
  forceImport?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ImportZoomMeetingMutation = {
  __typename?: "Mutation";
  importZoomMeeting?: {
    __typename?: "ImportZoomMeeting";
    meeting?: {
      __typename?: "ZoomMeeting";
      zoomAppAutoOpenEnabled: boolean;
      id: any;
      zoomId: number;
      joinUrl?: string | null;
      importMeeting: boolean;
      exclude: boolean;
      excludeReason?: ExcludeReason | null;
      hostId?: any | null;
      callId?: any | null;
    } | null;
    notetakerMeeting?: {
      __typename?: "ZoomNotetakerMeeting";
      id: any;
      importMeeting: boolean;
      exclude: boolean;
      excludeReason?: ExcludeReason | null;
      meetingUrl?: string | null;
      zoomId: number;
      callId?: any | null;
      zoomAppAutoOpenEnabled: boolean;
      scheduledInterview?: {
        __typename?: "ScheduledInterview";
        id: string;
        isInterviewer: boolean;
      } | null;
      virtualOnsite?: {
        __typename?: "VirtualOnsite";
        id: string;
        isScheduledInterviewer: boolean;
      } | null;
    } | null;
  } | null;
};

export type InforCandidateParamsMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
  candidateFullName?: InputMaybe<Scalars["String"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  atsCandidateId?: InputMaybe<Scalars["String"]>;
  jobCode?: InputMaybe<Scalars["String"]>;
  jobCodeId?: InputMaybe<Scalars["String"]>;
  candidateType?: InputMaybe<Scalars["String"]>;
  requisitionId?: InputMaybe<Scalars["String"]>;
}>;

export type InforCandidateParamsMutation = {
  __typename?: "Mutation";
  inforCandidateParams?: {
    __typename?: "InforCandidateParams";
    params?: {
      __typename?: "CallParams";
      candidateFirstName?: string | null;
      candidateLastName?: string | null;
      phoneNumber?: string | null;
      candidateEmail?: string | null;
      candidateId?: string | null;
      positionId?: string | null;
      name?: string | null;
      atsRequisitionId?: string | null;
    } | null;
  } | null;
};

export type InstallMergeIntegrationMutationVariables = Exact<{
  publicToken: Scalars["String"];
}>;

export type InstallMergeIntegrationMutation = {
  __typename?: "Mutation";
  installMergeIntegration?: {
    __typename?: "InstallMergeIntegration";
    setup: { __typename?: "MergeSetup"; valid: boolean };
  } | null;
};

export type InstallZoomNotetakerAppStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InstallZoomNotetakerAppStatusQuery = {
  __typename?: "Query";
  zoomBotInstallStatus?: InstallZoomNotetakerAppJobProcessingStatus | null;
};

export type InviteUsersMutationVariables = Exact<{
  users: Array<InputMaybe<InviteUser>> | InputMaybe<InviteUser>;
}>;

export type InviteUsersMutation = {
  __typename?: "Mutation";
  inviteUsers?: {
    __typename?: "InviteUsers";
    users: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      phoneNumberVerified: boolean;
      isCalendarAuthorized: boolean;
      createdAt: any;
      deletedAt?: any | null;
      signUpMethod: UserSignUpMethod;
      signUpMethodLabel: string;
      signUpCompletedAt?: any | null;
      voipPhoneNumber?: string | null;
      invitedBy?: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
      } | null;
    } | null>;
  } | null;
};

export type JoinZoomNotetakerMeetingMutationVariables = Exact<{
  zoomNotetakerMeetingId: Scalars["ID"];
}>;

export type JoinZoomNotetakerMeetingMutation = {
  __typename?: "Mutation";
  joinZoomNotetakerMeeting?: {
    __typename?: "JoinZoomNotetakerMeeting";
    meeting: {
      __typename?: "ZoomNotetakerMeeting";
      id: any;
      callId?: any | null;
    };
  } | null;
};

export type KeepCallMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type KeepCallMutation = {
  __typename?: "Mutation";
  keepCall?: {
    __typename?: "KeepCall";
    success: boolean;
    call: {
      __typename?: "Call";
      id: any;
      skipRedaction: boolean;
      canDelete: boolean;
    };
  } | null;
};

export type KeepCandidateMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type KeepCandidateMutation = {
  __typename?: "Mutation";
  keepCandidate?: {
    __typename?: "KeepCandidate";
    success: boolean;
    candidate: {
      __typename?: "Candidate";
      id: any;
      fullName: string;
      skipRedaction: boolean;
    };
  } | null;
};

export type LeverAuthOnRedirectMutationVariables = Exact<{
  leverStateToken: Scalars["String"];
  leverCode?: InputMaybe<Scalars["String"]>;
  responseError?: InputMaybe<Scalars["String"]>;
  responseErrorDescription?: InputMaybe<Scalars["String"]>;
}>;

export type LeverAuthOnRedirectMutation = {
  __typename?: "Mutation";
  leverAuthOnRedirect?: {
    __typename?: "LeverAuthOnRedirect";
    leverOauthSetup: {
      __typename?: "LeverOauthSetup";
      id: string;
      useSandbox: boolean;
      valid: boolean;
      syncCallGuidesFromAts: boolean;
      createSecretNotes: boolean;
    };
  } | null;
};

export type LeverCallParamsMutationVariables = Exact<{
  interviewId: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
}>;

export type LeverCallParamsMutation = {
  __typename?: "Mutation";
  leverCallParams?: {
    __typename?: "LeverCallParams";
    callParams?: {
      __typename?: "CallParams";
      name?: string | null;
      candidateFirstName?: string | null;
      candidateLastName?: string | null;
      candidateEmail?: string | null;
      phoneNumber?: string | null;
      callGuideId?: string | null;
      positionId?: string | null;
      leverInterviewLink?: string | null;
      scheduledInterviewId?: string | null;
      candidateId?: string | null;
    } | null;
  } | null;
};

export type LeverCandidateParamsMutationVariables = Exact<{
  opportunityId: Scalars["String"];
}>;

export type LeverCandidateParamsMutation = {
  __typename?: "Mutation";
  leverCandidateParams?: {
    __typename?: "LeverCandidateParams";
    callParams?: {
      __typename?: "CallParams";
      candidateFirstName?: string | null;
      candidateLastName?: string | null;
      candidateEmail?: string | null;
      phoneNumber?: string | null;
      positionId?: string | null;
      scheduledInterviewId?: string | null;
      name?: string | null;
      candidateId?: string | null;
      callGuideId?: string | null;
    } | null;
  } | null;
};

export type MakeOutgoingCallMutationVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type MakeOutgoingCallMutation = {
  __typename?: "Mutation";
  makeOutgoingCall?: {
    __typename?: "MakeOutgoingCall";
    call: { __typename?: "Call"; id: any; type: CallType };
  } | null;
};

export type MarkTrainingProgramTraineeCompleteMutationVariables = Exact<{
  id: Scalars["ID"];
  complete: Scalars["Boolean"];
}>;

export type MarkTrainingProgramTraineeCompleteMutation = {
  __typename?: "Mutation";
  markTrainingProgramTraineeComplete?: {
    __typename?: "MarkTrainingProgramTraineeComplete";
    trainingProgramTrainee: {
      __typename?: "TrainingProgramTrainee";
      id: any;
      markedCompletedAt?: any | null;
    };
  } | null;
};

export type MuteCandidateAlertUserSubscriptionsMutationVariables = Exact<{
  candidateIds: Array<Scalars["ID"]> | Scalars["ID"];
  positionIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type MuteCandidateAlertUserSubscriptionsMutation = {
  __typename?: "Mutation";
  muteCandidateAlertUserSubscriptions?: {
    __typename?: "MuteCandidateAlertUserSubscriptions";
    subscriptions: {
      __typename?: "CandidateAlertUserSubscriptions";
      id: string;
      candidateSubscriptions: Array<{
        __typename?: "CandidateAlertCandidateSubscription";
        id: any;
        candidateId: any;
        mutedAt?: any | null;
      }>;
      positionSubscriptions: Array<{
        __typename?: "CandidateAlertPositionSubscription";
        id: any;
        positionId: any;
        mutedAt?: any | null;
      }>;
    };
  } | null;
};

export type OutlookWebAppCallParamsMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
  eventTitle?: InputMaybe<Scalars["String"]>;
}>;

export type OutlookWebAppCallParamsMutation = {
  __typename?: "Mutation";
  outlookWebAppCallParams?: {
    __typename?: "OutlookWebAppCallParams";
    params?: {
      __typename?: "CallParams";
      candidateFirstName?: string | null;
      candidateLastName?: string | null;
      phoneNumber?: string | null;
      candidateEmail?: string | null;
      candidateId?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type PauseCallRecordingMutationVariables = Exact<{
  callId: Scalars["ID"];
  pauseRecording: Scalars["Boolean"];
}>;

export type PauseCallRecordingMutation = {
  __typename?: "Mutation";
  pauseCallRecording?: {
    __typename?: "PauseCallRecording";
    call: {
      __typename?: "Call";
      id: any;
      recordingPaused: boolean;
      recordingPauseInProgress: boolean;
    };
  } | null;
};

export type ReTranscribeCallMutationVariables = Exact<{
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
}>;

export type ReTranscribeCallMutation = {
  __typename?: "Mutation";
  reTranscribeCall?: {
    __typename?: "ReTranscribeCall";
    call: {
      __typename?: "Call";
      id: any;
      isProcessing: boolean;
      transcriptionStatus?: TranscriptionStatus | null;
    };
  } | null;
};

export type ReactivateUserMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ReactivateUserMutation = {
  __typename?: "Mutation";
  reactivateUser?: {
    __typename?: "ReactivateUser";
    user: {
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      phoneNumberVerified: boolean;
      isCalendarAuthorized: boolean;
      createdAt: any;
      deletedAt?: any | null;
      signUpMethod: UserSignUpMethod;
      signUpMethodLabel: string;
      signUpCompletedAt?: any | null;
      voipPhoneNumber?: string | null;
      invitedBy?: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
      } | null;
    };
  } | null;
};

export type RedactScheduledCallsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RedactScheduledCallsMutation = {
  __typename?: "Mutation";
  redactScheduledCalls?: {
    __typename?: "RedactScheduledCalls";
    success: boolean;
  } | null;
};

export type RedactScheduledCandidatesMutationVariables = Exact<{
  candidateStatus?: InputMaybe<CandidateRedactionStatus>;
}>;

export type RedactScheduledCandidatesMutation = {
  __typename?: "Mutation";
  redactScheduledCandidates?: {
    __typename?: "RedactScheduledCandidates";
    success: boolean;
  } | null;
};

export type RedactCallMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RedactCallMutation = {
  __typename?: "Mutation";
  redactCall?: { __typename?: "RedactCall"; success: boolean } | null;
};

export type RedactCandidateMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RedactCandidateMutation = {
  __typename?: "Mutation";
  redactCandidate?: { __typename?: "RedactCandidate"; success: boolean } | null;
};

export type RedialMutationVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type RedialMutation = {
  __typename?: "Mutation";
  redial?: {
    __typename?: "Redial";
    call: { __typename?: "Call"; id: any; type: CallType };
  } | null;
};

export type RemoveBullhornCredentialsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveBullhornCredentialsMutation = {
  __typename?: "Mutation";
  removeBullhornCredentials?: {
    __typename?: "RemoveBullhornCredentials";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type RemoveClipShareMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveClipShareMutation = {
  __typename?: "Mutation";
  removeClipShare?: {
    __typename?: "RemoveClipShare";
    clip: {
      __typename?: "Clip";
      id: any;
      clipShares: Array<{
        __typename?: "ClipShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        sharedTo: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type RemoveGuideFromCallMutationVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type RemoveGuideFromCallMutation = {
  __typename?: "Mutation";
  updateCall?: {
    __typename?: "UpdateCall";
    call: {
      __typename?: "Call";
      id: any;
      callGuide?: {
        __typename?: "CallGuide";
        id: any;
        name?: string | null;
        visibility: CallGuideVisibility;
        isTemplate: boolean;
        canEdit: boolean;
        canManageCallGuideAssignments: boolean;
        createdAt: any;
        updatedAt: any;
        greenhouseId?: number | null;
        leverFeedbackTemplateId?: string | null;
        customAtsId?: string | null;
        ashbyFeedbackFormId?: string | null;
        smartrecruitersCriteriaId?: string | null;
        atsId?: string | null;
        overallScoringEnabled: boolean;
        position?: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
          client: { __typename?: "Client"; id: any; name: string };
        } | null;
        creator?: { __typename?: "User"; id: any; fullName: string } | null;
        assignedUsers: Array<{
          __typename?: "CallGuideUser";
          createdAt: any;
          userId: any;
          user: {
            __typename?: "User";
            id: any;
            createdAt: any;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
            email: string;
          };
        }>;
        cues: Array<{
          __typename?: "CallGuideCue";
          id: any;
          cue: string;
          description: string;
          isHtmlType: boolean;
          position: number;
          scoringEnabled: boolean;
          type: GuideItemType;
          competencyId?: any | null;
          childItems: Array<{
            __typename?: "CallGuideItemChild";
            id: any;
            description: string;
            position: number;
          }>;
        }>;
        organization: {
          __typename?: "Organization";
          id: any;
          scoringEnabled: boolean;
        };
        interviewStage?: {
          __typename?: "InterviewStage";
          id: any;
          title: string;
        } | null;
      } | null;
    };
  } | null;
};

export type RemoveShareMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveShareMutation = {
  __typename?: "Mutation";
  removeShare?: {
    __typename?: "RemoveShare";
    call: {
      __typename?: "Call";
      id: any;
      callShares: Array<{
        __typename?: "CallShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        sharedTo: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type RequestOrganizationDataRedactionAccessMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RequestOrganizationDataRedactionAccessMutation = {
  __typename?: "Mutation";
  requestOrganizationDataRedactionAccess?: {
    __typename?: "RequestOrganizationDataRedactionAccess";
    success?: boolean | null;
  } | null;
};

export type RequestUpgradeMutationVariables = Exact<{
  featureName: Scalars["String"];
}>;

export type RequestUpgradeMutation = {
  __typename?: "Mutation";
  requestUpgrade?: {
    __typename?: "RequestUpgrade";
    success?: boolean | null;
  } | null;
};

export type ResetAnalyticsQualityReportConfigMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResetAnalyticsQualityReportConfigMutation = {
  __typename?: "Mutation";
  resetAnalyticsQualityReportConfig?: {
    __typename?: "ResetAnalyticsQualityReportConfig";
    reportConfig: {
      __typename?: "AnalyticsQualityReportConfig";
      agendaSetEnabled: boolean;
      candidateQuestionOpportunityEnabled: boolean;
      candidateTalkRatioEnabled: boolean;
      onTimeStartsEnabled: boolean;
      problematicQuestionsEnabled: boolean;
      longestMonologueEnabled: boolean;
      speakingRateEnabled: boolean;
      interactivityEnabled: boolean;
      questionsAskedEnabled: boolean;
    };
  } | null;
};

export type RunSkillReportMutationVariables = Exact<{
  reportId: Scalars["ID"];
  regenerate?: InputMaybe<Scalars["Boolean"]>;
}>;

export type RunSkillReportMutation = {
  __typename?: "Mutation";
  runSkillReport?: {
    __typename?: "RunSkillReport";
    skillReport: {
      __typename?: "SkillReport";
      id: any;
      processingStatus?: SkillReportProcessingStatus | null;
    };
  } | null;
};

export type ScheduledInterviewerOptOutMutationVariables = Exact<{
  id: Scalars["ID"];
  recordingDisabled: Scalars["Boolean"];
}>;

export type ScheduledInterviewerOptOutMutation = {
  __typename?: "Mutation";
  scheduledInterviewerOptOut: {
    __typename?: "ScheduledInterviewerOptOut";
    scheduledInterview: {
      __typename?: "ScheduledInterview";
      id: string;
      recordingDisabled: boolean;
      scheduledInterviewers: Array<{
        __typename?: "ScheduledInterviewer";
        id: string;
        recordingDisabled: boolean;
        user?: { __typename?: "User"; id: any } | null;
      }>;
    };
  };
};

export type SendCallBackTextMessageMutationVariables = Exact<{
  callId: Scalars["ID"];
  body: Scalars["String"];
}>;

export type SendCallBackTextMessageMutation = {
  __typename?: "Mutation";
  sendCallBackTextMessage: {
    __typename?: "SendCallBackTextMessage";
    textMessage?: {
      __typename?: "TextMessage";
      messageSid: string;
      messageStatus: TextMessageStatus;
    } | null;
  };
};

export type SendCandidateEmailVerificationMutationVariables = Exact<{
  orgId: Scalars["ID"];
  email: Scalars["String"];
}>;

export type SendCandidateEmailVerificationMutation = {
  __typename?: "Mutation";
  sendCandidateEmailVerification: {
    __typename?: "SendCandidateEmailVerification";
    success: boolean;
  };
};

export type SendInviteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SendInviteMutation = {
  __typename?: "Mutation";
  sendInvite?: { __typename?: "SendInvite"; sent: boolean } | null;
};

export type SetClientMembershipsMutationVariables = Exact<{
  users: Array<SetClientMembershipsInput> | SetClientMembershipsInput;
}>;

export type SetClientMembershipsMutation = {
  __typename?: "Mutation";
  setClientMemberships?: {
    __typename?: "SetClientMemberships";
    users?: Array<{ __typename?: "User"; id: any }> | null;
  } | null;
};

export type SetMembershipsMutationVariables = Exact<{
  users: Array<SetMembershipsInput> | SetMembershipsInput;
}>;

export type SetMembershipsMutation = {
  __typename?: "Mutation";
  setMemberships?: {
    __typename?: "SetMemberships";
    positions?: Array<{
      __typename?: "Position";
      id: any;
      membershipsCount?: number | null;
      memberships: Array<{
        __typename?: "Membership";
        createdAt: any;
        role: { __typename?: "PositionRole"; id: any; name: PositionRoleName };
        position: { __typename?: "Position"; id: any; title: string };
      }>;
    }> | null;
    users?: Array<{
      __typename?: "User";
      id: any;
      userRole?: { __typename?: "UserRole"; name: UserRoleName } | null;
      memberships: Array<{
        __typename?: "Membership";
        createdAt: any;
        role: { __typename?: "PositionRole"; id: any; name: PositionRoleName };
        position: { __typename?: "Position"; id: any; title: string };
      }>;
    }> | null;
  } | null;
};

export type SetPlanUserRolesMutationVariables = Exact<{
  users: Array<SetPlanUserRolesInput> | SetPlanUserRolesInput;
}>;

export type SetPlanUserRolesMutation = {
  __typename?: "Mutation";
  setPlanUserRoles?: {
    __typename?: "SetPlanUserRoles";
    users?: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      phoneNumberVerified: boolean;
      isCalendarAuthorized: boolean;
      createdAt: any;
      deletedAt?: any | null;
      signUpMethod: UserSignUpMethod;
      signUpMethodLabel: string;
      signUpCompletedAt?: any | null;
      voipPhoneNumber?: string | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        name: PlanUserRoleName;
      } | null;
      invitedBy?: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
      } | null;
    }> | null;
  } | null;
};

export type SetUserRolesMutationVariables = Exact<{
  users: Array<SetUserRolesInput> | SetUserRolesInput;
}>;

export type SetUserRolesMutation = {
  __typename?: "Mutation";
  setUserRoles?: {
    __typename?: "SetUserRoles";
    users?: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      phoneNumberVerified: boolean;
      isCalendarAuthorized: boolean;
      createdAt: any;
      deletedAt?: any | null;
      signUpMethod: UserSignUpMethod;
      signUpMethodLabel: string;
      signUpCompletedAt?: any | null;
      voipPhoneNumber?: string | null;
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        createdAt: any;
        role: { __typename?: "PositionRole"; id: any; name: PositionRoleName };
        position: { __typename?: "Position"; id: any; title: string };
      }>;
      invitedBy?: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
      } | null;
    }> | null;
  } | null;
};

export type SetupGreenhouseMutationVariables = Exact<{
  enabled: Scalars["Boolean"];
  apiKey?: InputMaybe<Scalars["String"]>;
  host?: InputMaybe<Scalars["String"]>;
  adminUserId: Scalars["BigInt"];
  syncCallGuidesFromAts: Scalars["Boolean"];
}>;

export type SetupGreenhouseMutation = {
  __typename?: "Mutation";
  setupGreenhouse?: {
    __typename?: "SetupGreenhouse";
    setup: {
      __typename?: "GreenhouseSetup";
      id: string;
      enabled: boolean;
      apiKey: string;
      host: string;
      adminUserId: any;
      valid: boolean;
      invalidReason?: string | null;
      webhookSecretKey?: string | null;
      syncCallGuidesFromAts: boolean;
      atsOptInEnabled: boolean;
      greenhouseConsentKey?: string | null;
      permissionsStatus: Array<{
        __typename?: "GreenhousePermissionStatus";
        permission: string;
        isGranted: boolean;
      }>;
    };
  } | null;
};

export type SetupGreenhouseWizardMutationVariables = Exact<{
  enabled: Scalars["Boolean"];
  apiKey?: InputMaybe<Scalars["String"]>;
  host?: InputMaybe<Scalars["String"]>;
  adminUserId?: InputMaybe<Scalars["BigInt"]>;
}>;

export type SetupGreenhouseWizardMutation = {
  __typename?: "Mutation";
  setupGreenhouseWizard?: {
    __typename?: "SetupGreenhouseWizard";
    setup: {
      __typename?: "GreenhouseSetup";
      id: string;
      enabled: boolean;
      apiKey: string;
      host: string;
      adminUserId: any;
      valid: boolean;
      invalidReason?: string | null;
      webhookSecretKey?: string | null;
      syncCallGuidesFromAts: boolean;
      atsOptInEnabled: boolean;
      greenhouseConsentKey?: string | null;
      permissionsStatus: Array<{
        __typename?: "GreenhousePermissionStatus";
        permission: string;
        isGranted: boolean;
      }>;
    };
  } | null;
};

export type SetupIcimsMutationVariables = Exact<{
  enabled: Scalars["Boolean"];
  customerId?: InputMaybe<Scalars["String"]>;
  clientId?: InputMaybe<Scalars["String"]>;
  clientSecret?: InputMaybe<Scalars["String"]>;
  tokenUrl?: InputMaybe<Scalars["String"]>;
}>;

export type SetupIcimsMutation = {
  __typename?: "Mutation";
  setupIcims?: {
    __typename?: "SetupIcims";
    setup: {
      __typename?: "IcimsSetup";
      id: string;
      enabled: boolean;
      valid: boolean;
    };
  } | null;
};

export type SetupLeverMutationVariables = Exact<{
  enabled?: InputMaybe<Scalars["Boolean"]>;
  apiKey?: InputMaybe<Scalars["String"]>;
  signatureTokenObj?: InputMaybe<Scalars["String"]>;
  syncCallGuidesFromAts: Scalars["Boolean"];
  createSecretNotes: Scalars["Boolean"];
}>;

export type SetupLeverMutation = {
  __typename?: "Mutation";
  setupLever?: {
    __typename?: "SetupLever";
    leverSetup: {
      __typename?: "LeverSetup";
      id: string;
      enabled: boolean;
      apiKey: string;
      signatureTokenObj?: string | null;
      valid: boolean;
      syncCallGuidesFromAts: boolean;
      createSecretNotes: boolean;
    };
  } | null;
};

export type SetupSenseMutationVariables = Exact<{
  enabled: Scalars["Boolean"];
  apiKey?: InputMaybe<Scalars["String"]>;
}>;

export type SetupSenseMutation = {
  __typename?: "Mutation";
  setupSense?: {
    __typename?: "SetupSense";
    setup: {
      __typename?: "SenseConfiguration";
      enabled: boolean;
      apiKey?: string | null;
    };
  } | null;
};

export type SetupSingleSignOnMutationVariables = Exact<{
  oktaDomain: Scalars["String"];
  metadataUrl: Scalars["String"];
}>;

export type SetupSingleSignOnMutation = {
  __typename?: "Mutation";
  setupSingleSignOn?: {
    __typename?: "SetupSingleSignOn";
    setup: {
      __typename?: "SingleSignOnSetup";
      oktaDomain?: string | null;
      metadataUrl?: string | null;
      scimToken?: string | null;
    };
  } | null;
};

export type ShareAnalyticsReportMutationVariables = Exact<{
  shareToUserIds: Array<Scalars["ID"]> | Scalars["ID"];
  message: Scalars["String"];
  url: Scalars["String"];
}>;

export type ShareAnalyticsReportMutation = {
  __typename?: "Mutation";
  shareAnalyticsReport?: {
    __typename?: "ShareAnalyticsReport";
    success: boolean;
  } | null;
};

export type ShareCallMutationVariables = Exact<{
  id: Scalars["ID"];
  shareToUserIds: Array<Scalars["ID"]> | Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
}>;

export type ShareCallMutation = {
  __typename?: "Mutation";
  shareCall?: {
    __typename?: "ShareCall";
    call: {
      __typename?: "Call";
      id: any;
      callShares: Array<{
        __typename?: "CallShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        sharedTo: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type ShareClipMutationVariables = Exact<{
  clipId: Scalars["ID"];
  shareToUserIds: Array<Scalars["ID"]> | Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
}>;

export type ShareClipMutation = {
  __typename?: "Mutation";
  shareClip?: {
    __typename?: "ShareClip";
    clip: {
      __typename?: "Clip";
      id: any;
      clipShares: Array<{
        __typename?: "ClipShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        sharedTo: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type SignInByEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type SignInByEmailMutation = {
  __typename?: "Mutation";
  signInByEmail?: {
    __typename?: "SignInByEmail";
    verificationSent: boolean;
  } | null;
};

export type SignInByPhoneMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
  phoneNumberExtension?: InputMaybe<Scalars["String"]>;
  channel: Scalars["String"];
}>;

export type SignInByPhoneMutation = {
  __typename?: "Mutation";
  signInByPhone?: {
    __typename?: "SignInByPhone";
    verificationSent: boolean;
  } | null;
};

export type SignOutMutationVariables = Exact<{ [key: string]: never }>;

export type SignOutMutation = {
  __typename?: "Mutation";
  signOut?: { __typename?: "SignOut"; isAuthenticated: boolean } | null;
};

export type SmartrecruitersAuthOnRedirectMutationVariables = Exact<{
  state: Scalars["String"];
  code?: InputMaybe<Scalars["String"]>;
  error?: InputMaybe<Scalars["String"]>;
  errorDescription?: InputMaybe<Scalars["String"]>;
}>;

export type SmartrecruitersAuthOnRedirectMutation = {
  __typename?: "Mutation";
  smartrecruitersAuthOnRedirect?: {
    __typename?: "SmartrecruitersAuthOnRedirect";
    setup: { __typename?: "SmartrecruitersSetup"; id: string; valid: boolean };
  } | null;
};

export type CreateSmartrecruitersOauthRequestUrlMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateSmartrecruitersOauthRequestUrlMutation = {
  __typename?: "Mutation";
  createSmartrecruitersOauthRequestUrl?: {
    __typename?: "CreateSmartrecruitersOauthRequestUrl";
    oauthUrl: string;
  } | null;
};

export type DeauthorizeSmartrecruitersMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeauthorizeSmartrecruitersMutation = {
  __typename?: "Mutation";
  deauthorizeSmartrecruiters?: {
    __typename?: "DeauthorizeSmartrecruiters";
    success: boolean;
  } | null;
};

export type StartVirtualOnsiteBotMeetingMutationVariables = Exact<{
  botMeetingId: Scalars["ID"];
  addApprovedInterviewer?: InputMaybe<Scalars["Boolean"]>;
}>;

export type StartVirtualOnsiteBotMeetingMutation = {
  __typename?: "Mutation";
  startVirtualOnsiteBotMeeting?: {
    __typename?: "StartVirtualOnsiteBotMeeting";
    meeting: { __typename?: "BotMeeting"; id: any; meetingUrl?: string | null };
  } | null;
};

export type TalemetryCandidateParamsMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
  candidateFullName?: InputMaybe<Scalars["String"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  atsCandidateId?: InputMaybe<Scalars["String"]>;
  jobOpeningId?: InputMaybe<Scalars["String"]>;
  talemetryCandidateId?: InputMaybe<Scalars["String"]>;
}>;

export type TalemetryCandidateParamsMutation = {
  __typename?: "Mutation";
  talemetryCandidateParams?: {
    __typename?: "TalemetryCandidateParams";
    params?: {
      __typename?: "CallParams";
      candidateFirstName?: string | null;
      candidateLastName?: string | null;
      phoneNumber?: string | null;
      candidateEmail?: string | null;
      positionId?: string | null;
      candidateId?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type UnimpersonateMutationVariables = Exact<{ [key: string]: never }>;

export type UnimpersonateMutation = {
  __typename?: "Mutation";
  unimpersonate?: {
    __typename?: "Unimpersonate";
    redirectTo: string;
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UnkeepCallMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnkeepCallMutation = {
  __typename?: "Mutation";
  unkeepCall?: {
    __typename?: "UnkeepCall";
    success: boolean;
    call: {
      __typename?: "Call";
      id: any;
      skipRedaction: boolean;
      canDelete: boolean;
    };
  } | null;
};

export type UnkeepCandidateMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnkeepCandidateMutation = {
  __typename?: "Mutation";
  unkeepCandidate?: {
    __typename?: "UnkeepCandidate";
    success: boolean;
    candidate: {
      __typename?: "Candidate";
      id: any;
      fullName: string;
      skipRedaction: boolean;
    };
  } | null;
};

export type UpdateAiAnswerMutationVariables = Exact<{
  answerItemId: Scalars["ID"];
  text: Scalars["String"];
}>;

export type UpdateAiAnswerMutation = {
  __typename?: "Mutation";
  updateAiAnswer?: {
    __typename?: "UpdateAiAnswer";
    answerItem: {
      __typename?: "AnswerItem";
      id: string;
      text: string;
      edited: boolean;
    };
  } | null;
};

export type UpdateAiQuestionMutationVariables = Exact<{
  callAiNoteId: Scalars["ID"];
  text: Scalars["String"];
}>;

export type UpdateAiQuestionMutation = {
  __typename?: "Mutation";
  updateAiQuestion?: {
    __typename?: "UpdateAiQuestion";
    callAiNote: {
      __typename?: "QuestionAndAnswer";
      id: string;
      startTime: number;
      endTime: number;
      segmentIndex: number;
      question: string;
      answer: string;
      originalQuestion?: string | null;
      originalAnswer?: string | null;
      matchReason: string;
      error?: string | null;
      edited?: boolean | null;
      isCandidate: boolean;
      answerItems: Array<{
        __typename?: "AnswerItem";
        id: string;
        text: string;
        edited: boolean;
      }>;
    };
  } | null;
};

export type UpdateOrganizationGenderLabelsMutationVariables = Exact<{
  genderLabelMale: Scalars["String"];
  genderLabelFemale: Scalars["String"];
}>;

export type UpdateOrganizationGenderLabelsMutation = {
  __typename?: "Mutation";
  updateOrganizationGenderLabels?: {
    __typename?: "UpdateOrganizationGenderLabels";
    organization: {
      __typename?: "Organization";
      id: any;
      genderLabelMale?: string | null;
      genderLabelFemale?: string | null;
    };
  } | null;
};

export type UpdateAnalyticsQualityReportConfigMutationVariables = Exact<{
  agendaSetEnabled: Scalars["Boolean"];
  candidateQuestionOpportunityEnabled: Scalars["Boolean"];
  candidateTalkRatioEnabled: Scalars["Boolean"];
  onTimeStartsEnabled: Scalars["Boolean"];
  problematicQuestionsEnabled: Scalars["Boolean"];
  longestMonologueEnabled: Scalars["Boolean"];
  speakingRateEnabled: Scalars["Boolean"];
  interactivityEnabled: Scalars["Boolean"];
  questionsAskedEnabled: Scalars["Boolean"];
  feedbackSubmissionTimeEnabled: Scalars["Boolean"];
  feedbackSubmissionRateEnabled: Scalars["Boolean"];
}>;

export type UpdateAnalyticsQualityReportConfigMutation = {
  __typename?: "Mutation";
  updateAnalyticsQualityReportConfig?: {
    __typename?: "UpdateAnalyticsQualityReportConfig";
    reportConfig: {
      __typename?: "AnalyticsQualityReportConfig";
      agendaSetEnabled: boolean;
      candidateQuestionOpportunityEnabled: boolean;
      candidateTalkRatioEnabled: boolean;
      onTimeStartsEnabled: boolean;
      problematicQuestionsEnabled: boolean;
      longestMonologueEnabled: boolean;
      speakingRateEnabled: boolean;
      interactivityEnabled: boolean;
      questionsAskedEnabled: boolean;
      feedbackSubmissionTimeEnabled: boolean;
      feedbackSubmissionRateEnabled: boolean;
    };
  } | null;
};

export type UpdateApiTokenMutationVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
}>;

export type UpdateApiTokenMutation = {
  __typename?: "Mutation";
  updateApiToken?: {
    __typename?: "UpdateApiToken";
    setup: {
      __typename?: "RestApiSetup";
      apiTokens: Array<{
        __typename?: "OrganizationApiToken";
        id: string;
        name?: string | null;
        tokenPrefix?: string | null;
      }>;
      webhooks: Array<{
        __typename?: "WebhookConfiguration";
        id: string;
        webhookUrl?: string | null;
        webhookSecret?: string | null;
        eventInterviewCompleted: boolean;
        eventCandidateUpdatedRecordingDisabled: boolean;
      }>;
    };
  } | null;
};

export type UpdateAutoJoinAllNewPositionsMutationVariables = Exact<{
  enable: Scalars["Boolean"];
  allPositionGroups: Scalars["Boolean"];
  positionGroupId?: InputMaybe<Scalars["ID"]>;
}>;

export type UpdateAutoJoinAllNewPositionsMutation = {
  __typename?: "Mutation";
  updateAutoJoinAllNewPositions?: {
    __typename?: "UpdateAutoJoinAllNewPositions";
    positions: Array<{
      __typename?: "Position";
      id: any;
      title: string;
      requisitionId?: string | null;
      locations?: Array<string | null> | null;
      createdAt: any;
      displayTitle: string;
      greenhouseId?: number | null;
      greenhouseLink?: string | null;
      leverPostingId?: string | null;
      autoJoinInterview: boolean;
      atsStatus?: string | null;
      client: {
        __typename?: "Client";
        id: any;
        name: string;
        autoJoinAllNewPositions: boolean;
      };
    }>;
    organization: {
      __typename?: "Organization";
      id: any;
      autoJoinAllNewPositions: boolean;
    };
  } | null;
};

export type UpdateAutoJoinExcludedUsersMutationVariables = Exact<{
  userIds: Array<Scalars["ID"]> | Scalars["ID"];
  exclude: Scalars["Boolean"];
}>;

export type UpdateAutoJoinExcludedUsersMutation = {
  __typename?: "Mutation";
  updateAutoJoinExcludedUsers?: {
    __typename?: "UpdateAutoJoinExcludedUsers";
    currentUser: {
      __typename?: "User";
      id: any;
      organization: {
        __typename?: "Organization";
        id: any;
        userAutoJoinExclusions: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
          phoneNumber?: string | null;
          phoneNumberExtension?: string | null;
          phoneNumberVerified: boolean;
          isCalendarAuthorized: boolean;
          createdAt: any;
          deletedAt?: any | null;
          signUpMethod: UserSignUpMethod;
          signUpMethodLabel: string;
          signUpCompletedAt?: any | null;
          voipPhoneNumber?: string | null;
          invitedBy?: {
            __typename?: "User";
            id: any;
            fullName: string;
            email: string;
          } | null;
        }>;
      };
    };
  } | null;
};

export type UpdateCalendarOrgSettingsMutationVariables = Exact<{
  id: Scalars["ID"];
  shouldImportVideoMeeting: Scalars["String"];
}>;

export type UpdateCalendarOrgSettingsMutation = {
  __typename?: "Mutation";
  updateCalendarOrgSettings?: {
    __typename?: "UpdateCalendarOrgSettings";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateCalendarSettingsMutationVariables = Exact<{
  id: Scalars["ID"];
  shouldImportVideoMeeting: Scalars["String"];
}>;

export type UpdateCalendarSettingsMutation = {
  __typename?: "Mutation";
  updateCalendarSettings?: {
    __typename?: "UpdateCalendarSettings";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateCallMutationVariables = Exact<{
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  positionId?: InputMaybe<Scalars["ID"]>;
  rating?: InputMaybe<Scalars["Int"]>;
  bullhornCommentAction?: InputMaybe<Scalars["String"]>;
  candidateFirstName?: InputMaybe<Scalars["String"]>;
  candidateLastName?: InputMaybe<Scalars["String"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  candidateId?: InputMaybe<Scalars["String"]>;
  iaVersion?: InputMaybe<Scalars["Float"]>;
}>;

export type UpdateCallMutation = {
  __typename?: "Mutation";
  updateCall?: {
    __typename?: "UpdateCall";
    call: {
      __typename?: "Call";
      id: any;
      name?: string | null;
      iaVersion: number;
      visibility: CallVisibility;
      rating: number;
      candidate?: {
        __typename?: "Candidate";
        id: any;
        firstName: string;
        lastName: string;
        fullName: string;
      } | null;
      position?: {
        __typename?: "Position";
        id: any;
        displayTitle: string;
      } | null;
    };
  } | null;
};

export type UpdateCallNoteMutationVariables = Exact<{
  id: Scalars["ID"];
  text?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<CallNoteType>;
  visibility?: InputMaybe<CallNoteVisibility>;
  clipId?: InputMaybe<Scalars["ID"]>;
}>;

export type UpdateCallNoteMutation = {
  __typename?: "Mutation";
  updateCallNote?: {
    __typename?: "UpdateCallNote";
    callNote: {
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    };
  } | null;
};

export type UpdateCallPositionMutationVariables = Exact<{
  id: Scalars["ID"];
  positionId: Scalars["String"];
}>;

export type UpdateCallPositionMutation = {
  __typename?: "Mutation";
  updateCallPosition?: {
    __typename?: "UpdateCallPosition";
    call: {
      __typename?: "Call";
      id: any;
      position?: {
        __typename?: "Position";
        id: any;
        displayTitle: string;
      } | null;
    };
  } | null;
};

export type UpdateCallRatingMutationVariables = Exact<{
  id: Scalars["ID"];
  rating: Scalars["Int"];
}>;

export type UpdateCallRatingMutation = {
  __typename?: "Mutation";
  updateCall?: {
    __typename?: "UpdateCall";
    call: { __typename?: "Call"; id: any; rating: number };
  } | null;
};

export type UpdateCallSpeakerMutationVariables = Exact<{
  callId: Scalars["ID"];
  speakerTag: Scalars["Int"];
  speakerOptionId: Scalars["String"];
}>;

export type UpdateCallSpeakerMutation = {
  __typename?: "Mutation";
  updateCallSpeaker?: {
    __typename?: "UpdateCallSpeaker";
    call: {
      __typename?: "Call";
      id: any;
      speakers: Array<{
        __typename?: "CallSpeaker";
        id: any;
        speakerTag: number;
        label: string;
        isCandidate: boolean;
        isInterviewer?: boolean | null;
        identified: boolean;
        talkTimePercentage: number;
        longestMonologue?: number | null;
      }>;
      notes: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        time: number;
        highlightStartTime?: number | null;
        highlightEndTime?: number | null;
        speakerTag?: number | null;
        text: string;
        type: CallNoteType;
        description?: string | null;
        isEdited: boolean;
        visibility: CallNoteVisibility;
        guideItemId?: any | null;
        parentGuideItemId?: any | null;
        scorecardQuestionId?: any | null;
        questionId?: any | null;
        createdAt: any;
        updatedAt: any;
        score?: number | null;
        user?: {
          __typename?: "User";
          id: any;
          profilePicUrl?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
        } | null;
        replies: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          clipId?: any | null;
          text: string;
          isEdited: boolean;
          createdAt: any;
          visibility: CallNoteVisibility;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
          } | null;
        }>;
      }>;
    };
  } | null;
};

export type UpdateCallStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: Scalars["String"];
}>;

export type UpdateCallStatusMutation = {
  __typename?: "Mutation";
  updateCall?: {
    __typename?: "UpdateCall";
    call: { __typename?: "Call"; id: any; status: CallStatus };
  } | null;
};

export type UpdateCallVisibilityMutationVariables = Exact<{
  id: Scalars["ID"];
  visibility: CallVisibility;
}>;

export type UpdateCallVisibilityMutation = {
  __typename?: "Mutation";
  updateCall?: {
    __typename?: "UpdateCall";
    call: {
      __typename?: "Call";
      id: any;
      visibility: CallVisibility;
      visibleTo: {
        __typename?: "PaginatedRecordingVisibleTo";
        resultCount: number;
        results: Array<{
          __typename?: "RecordingVisibleTo";
          id: string;
          role: string;
          user: {
            __typename?: "User";
            id: any;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
          };
        }>;
      };
    };
  } | null;
};

export type UpdateCandidateEmailOptOutMutationVariables = Exact<{
  orgId: Scalars["ID"];
  verificationCode: Scalars["String"];
  recordingDisabled: Scalars["Boolean"];
}>;

export type UpdateCandidateEmailOptOutMutation = {
  __typename?: "Mutation";
  updateCandidateEmailOptOut: {
    __typename?: "UpdateCandidateEmailOptOut";
    candidateEmailOptOut: {
      __typename?: "CandidateEmailOptOut";
      verificationCode: string;
      recordingDisabled: boolean;
      organizationName: string;
    };
  };
};

export type UpdateCandidateMutationVariables = Exact<{
  id: Scalars["ID"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  phoneType?: InputMaybe<CandidatePhoneType>;
}>;

export type UpdateCandidateMutation = {
  __typename?: "Mutation";
  updateCandidate?: {
    __typename?: "UpdateCandidate";
    candidate: {
      __typename?: "Candidate";
      id: any;
      firstName: string;
      lastName: string;
      fullName: string;
      defaultEmail?: string | null;
      emails: Array<string>;
      defaultPhoneNumberObj?: {
        __typename?: "CandidatePhoneNumber";
        id: any;
        type: CandidatePhoneType;
        phoneNumber: string;
      } | null;
      phoneNumbers: Array<{
        __typename?: "CandidatePhoneNumber";
        id: any;
        type: CandidatePhoneType;
        phoneNumber: string;
      }>;
    };
  } | null;
};

export type UpdateCandidateOptOutMutationVariables = Exact<{
  id: Scalars["ID"];
  recordingDisabled: Scalars["Boolean"];
}>;

export type UpdateCandidateOptOutMutation = {
  __typename?: "Mutation";
  updateCandidateOptOut: {
    __typename?: "UpdateCandidateOptOut";
    candidate: {
      __typename?: "Candidate";
      id: any;
      recordingDisabled: boolean;
      scheduledInterviews: Array<{
        __typename?: "ScheduledInterview";
        id: string;
        name?: string | null;
        scheduledStart?: any | null;
        timezoneName?: string | null;
        scheduledEnd?: any | null;
        durationMinutes?: number | null;
        phoneNumber?: string | null;
        zoomId?: number | null;
        zoomJoinUrl?: string | null;
        zoomMeetingUrl?: string | null;
        zoomAppAutoOpenEnabled: boolean;
        googleMeetMeetingUrl?: string | null;
        teamsMeetingUrl?: string | null;
        isVideo: boolean;
        canAddBrighthire?: boolean | null;
        canAddBrighthireReason?: string | null;
        canLaunchMeeting?: boolean | null;
        canLaunchMeetingReason?: string | null;
        isExcluded: boolean;
        isImported: boolean;
        importDecision: boolean;
        importDecisionReason?: InterviewImportDecisionReason | null;
        greenhouseLink?: string | null;
        ghJobStageName?: string | null;
        optInUrl?: string | null;
        leverInterviewId?: string | null;
        smartrecruitersInterviewLink?: string | null;
        isInPersonInterview: boolean;
        interviewType?: InterviewType | null;
        isInterviewer: boolean;
        recordingDisabled: boolean;
        scheduledType: ScheduledInterviewType;
        leverStage?: {
          __typename?: "LeverStage";
          id: any;
          text?: string | null;
        } | null;
        scheduledInterviewers: Array<{
          __typename?: "ScheduledInterviewer";
          id: string;
          recordingDisabled: boolean;
          name?: string | null;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            email: string;
            profilePicUrl?: string | null;
            phoneNumber?: string | null;
          } | null;
        }>;
        callGuide?: { __typename?: "CallGuide"; id: any } | null;
        candidate?: {
          __typename?: "Candidate";
          id: any;
          firstName: string;
          lastName: string;
          fullName: string;
          callCount?: number | null;
          defaultPhoneNumber?: string | null;
          recordingDisabled: boolean;
        } | null;
        position?: {
          __typename?: "Position";
          id: any;
          displayTitle: string;
        } | null;
        googleMeetMeetings: Array<{
          __typename?: "GoogleMeetMeeting";
          id: string;
        }>;
        teamsNotetakerMeetings: Array<{
          __typename?: "TeamsNotetakerMeeting";
          id: string;
        }>;
      }>;
    };
  };
};

export type UpdateClientMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
}>;

export type UpdateClientMutation = {
  __typename?: "Mutation";
  updateClient?: {
    __typename?: "UpdateClient";
    client: { __typename?: "Client"; id: any; name: string };
  } | null;
};

export type UpdateClipMutationVariables = Exact<{
  clipId: Scalars["ID"];
  visibility?: InputMaybe<CallVisibility>;
  name?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateClipMutation = {
  __typename?: "Mutation";
  updateClip?: {
    __typename?: "UpdateClip";
    clip: {
      __typename?: "Clip";
      id: any;
      callId: any;
      userId: any;
      startTime: number;
      endTime: number;
      recordingStatus: ClipRecordingStatus;
      failureReason?: string | null;
      createdAt: any;
      updatedAt: any;
      visibility: CallVisibility;
      visibleVisibilityLevels: Array<CallVisibility>;
      thumbnailImageUrl?: string | null;
      canDelete: boolean;
      canEdit: boolean;
      name?: string | null;
      displayName: string;
      iaVersion: number;
      interviewerIds: Array<string>;
      callStartTime?: any | null;
      callEndTime?: any | null;
      callName?: string | null;
      callRecordingDisabled: boolean;
      callPhoneNumber?: string | null;
      callType: CallType;
      visibleTo: {
        __typename?: "PaginatedRecordingVisibleTo";
        resultCount: number;
        results: Array<{
          __typename?: "RecordingVisibleTo";
          id: string;
          role: string;
          user: {
            __typename?: "User";
            id: any;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
          };
        }>;
      };
      call: {
        __typename?: "Call";
        id: any;
        callClips: Array<{
          __typename?: "Clip";
          id: any;
          callId: any;
          userId: any;
          startTime: number;
          endTime: number;
          recordingStatus: ClipRecordingStatus;
          failureReason?: string | null;
          createdAt: any;
          updatedAt: any;
          visibility: CallVisibility;
          visibleVisibilityLevels: Array<CallVisibility>;
          thumbnailImageUrl?: string | null;
          canDelete: boolean;
          canEdit: boolean;
          name?: string | null;
          displayName: string;
          iaVersion: number;
          interviewerIds: Array<string>;
          callStartTime?: any | null;
          callEndTime?: any | null;
          callName?: string | null;
          callRecordingDisabled: boolean;
          callPhoneNumber?: string | null;
          callType: CallType;
          streamableAudio?: {
            __typename?: "CallMedia";
            url: string;
            expiration: any;
          } | null;
          streamableVideo?: {
            __typename?: "CallMedia";
            url: string;
            expiration: any;
          } | null;
          user: { __typename?: "User"; id: any; fullName: string };
          viewers: Array<{
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            email: string;
            profilePicUrl?: string | null;
            phoneNumber?: string | null;
            phoneNumberExtension?: string | null;
            phoneNumberVerified: boolean;
            isCalendarAuthorized: boolean;
            createdAt: any;
            deletedAt?: any | null;
            signUpMethod: UserSignUpMethod;
            signUpMethodLabel: string;
            signUpCompletedAt?: any | null;
            voipPhoneNumber?: string | null;
            invitedBy?: {
              __typename?: "User";
              id: any;
              fullName: string;
              email: string;
            } | null;
          }>;
          callSpeakers: Array<{
            __typename?: "CallSpeaker";
            id: any;
            speakerTag: number;
            label: string;
            isCandidate: boolean;
            isInterviewer?: boolean | null;
            identified: boolean;
            talkTimePercentage: number;
            longestMonologue?: number | null;
          }>;
          candidate?: {
            __typename?: "Candidate";
            id: any;
            firstName: string;
            lastName: string;
            fullName: string;
            greenhouseId?: number | null;
            greenhouseLink?: string | null;
            lastLeverOpportunityId?: string | null;
          } | null;
          position?: {
            __typename?: "Position";
            id: any;
            displayTitle: string;
          } | null;
        }>;
      };
      streamableAudio?: {
        __typename?: "CallMedia";
        url: string;
        expiration: any;
      } | null;
      streamableVideo?: {
        __typename?: "CallMedia";
        url: string;
        expiration: any;
      } | null;
      user: { __typename?: "User"; id: any; fullName: string };
      viewers: Array<{
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
        phoneNumber?: string | null;
        phoneNumberExtension?: string | null;
        phoneNumberVerified: boolean;
        isCalendarAuthorized: boolean;
        createdAt: any;
        deletedAt?: any | null;
        signUpMethod: UserSignUpMethod;
        signUpMethodLabel: string;
        signUpCompletedAt?: any | null;
        voipPhoneNumber?: string | null;
        invitedBy?: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
        } | null;
      }>;
      callSpeakers: Array<{
        __typename?: "CallSpeaker";
        id: any;
        speakerTag: number;
        label: string;
        isCandidate: boolean;
        isInterviewer?: boolean | null;
        identified: boolean;
        talkTimePercentage: number;
        longestMonologue?: number | null;
      }>;
      candidate?: {
        __typename?: "Candidate";
        id: any;
        firstName: string;
        lastName: string;
        fullName: string;
        greenhouseId?: number | null;
        greenhouseLink?: string | null;
        lastLeverOpportunityId?: string | null;
      } | null;
      position?: {
        __typename?: "Position";
        id: any;
        displayTitle: string;
      } | null;
    };
  } | null;
};

export type UpdateCurrentUserMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  emailAliases?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  phoneNumberExtension?: InputMaybe<Scalars["String"]>;
  maskPersonalPhoneNumber?: InputMaybe<Scalars["Boolean"]>;
  verifyPhoneNumber?: InputMaybe<Scalars["Boolean"]>;
  phoneNumberSkipped?: InputMaybe<Scalars["Boolean"]>;
  calendarSyncSkipped?: InputMaybe<Scalars["Boolean"]>;
  autofillGreenhouseScorecard?: InputMaybe<Scalars["Boolean"]>;
  zoomAppAutoOpenEnabled?: InputMaybe<Scalars["Boolean"]>;
  browserExtensionVersion?: InputMaybe<Scalars["String"]>;
  timezoneName?: InputMaybe<Scalars["String"]>;
  persona?: InputMaybe<Scalars["String"]>;
  showJoinWithBrighthire?: InputMaybe<Scalars["String"]>;
  defaultAiNoteFormat?: InputMaybe<Scalars["String"]>;
  includeOnboardingSteps?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateCurrentUserMutation = {
  __typename?: "Mutation";
  updateCurrentUser?: {
    __typename?: "UpdateCurrentUser";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      onboardingSteps?: Array<{
        __typename?: "UserOnboardingStep";
        step: OnboardingStep;
        rule: OnboardingStepRule;
        status?: OnboardingStepStatus | null;
      }>;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateCurrentUserSubscriptionsSettingsMutationVariables = Exact<{
  interviewRecapSubscription: UserSubscriptionSettings;
  interviewPrepSubscription: UserSubscriptionSettings;
  weeklyRecapSubscription: UserSubscriptionSettings;
  monthlyAnalyticsSubscription: UserSubscriptionSettings;
  callShareSubscription: UserSubscriptionSettings;
  callNoteSubscription: UserSubscriptionSettings;
  morningDigestSubscription: UserSubscriptionSettings;
}>;

export type UpdateCurrentUserSubscriptionsSettingsMutation = {
  __typename?: "Mutation";
  updateCurrentUserSubscriptionsSettings?: {
    __typename?: "UpdateCurrentUserSubscriptionSettings";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateCurrentUserShowActionItemsMutationVariables = Exact<{
  show: Scalars["Boolean"];
}>;

export type UpdateCurrentUserShowActionItemsMutation = {
  __typename?: "Mutation";
  updateCurrentUserShowActionItems?: {
    __typename?: "UpdateCurrentUserShowActionItems";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateCurrentUserViewedTranscriptionWarningMutationVariables =
  Exact<{
    hasViewedTranscriptionWarning?: InputMaybe<Scalars["Boolean"]>;
  }>;

export type UpdateCurrentUserViewedTranscriptionWarningMutation = {
  __typename?: "Mutation";
  updateCurrentUserViewedTranscriptionWarning?: {
    __typename?: "UpdateCurrentUserViewedTranscriptionWarning";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateInterviewOptInMutationVariables = Exact<{
  location: Scalars["String"];
  optIn: Scalars["Boolean"];
  isInterviewer: Scalars["Boolean"];
  organizationId: Scalars["String"];
}>;

export type UpdateInterviewOptInMutation = {
  __typename?: "Mutation";
  updateInterviewOptIn: {
    __typename?: "UpdateInterviewOptIn";
    success: boolean;
  };
};

export type UpdateLeverOauthIntegrationSettingsMutationVariables = Exact<{
  useSandbox: Scalars["Boolean"];
  syncGuides: Scalars["Boolean"];
  createSecretNotes: Scalars["Boolean"];
}>;

export type UpdateLeverOauthIntegrationSettingsMutation = {
  __typename?: "Mutation";
  updateLeverOauthIntegrationSettings?: {
    __typename?: "UpdateLeverOauthIntegrationSettings";
    leverOauthSetup: {
      __typename?: "LeverOauthSetup";
      id: string;
      useSandbox: boolean;
      valid: boolean;
      syncCallGuidesFromAts: boolean;
      createSecretNotes: boolean;
    };
  } | null;
};

export type UpdateMyInterviewRecordingOptionsMutationVariables = Exact<{
  importRule?: InputMaybe<UserInterviewImportRule>;
}>;

export type UpdateMyInterviewRecordingOptionsMutation = {
  __typename?: "Mutation";
  updateMyInterviewRecordingOptions?: {
    __typename?: "UpdateMyInterviewRecordingOptions";
    currentUser: {
      __typename?: "User";
      id: any;
      interviewImportRule?: UserInterviewImportRule | null;
    };
  } | null;
};

export type UpdateOptInSetupMutationVariables = Exact<{
  whoIsPageText: Scalars["String"];
  yesNoPageText?: InputMaybe<Scalars["String"]>;
  alwaysCandidate: Scalars["Boolean"];
  learnMoreLink?: InputMaybe<Scalars["String"]>;
  heroImageUrl?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateOptInSetupMutation = {
  __typename?: "Mutation";
  updateOptInSetup: { __typename?: "UpdateOptInSetup"; success: boolean };
};

export type UpdateOrganizationAdvancedConfigurationsMutationVariables = Exact<{
  hideOtherInterviewerNotesInExtension: Scalars["Boolean"];
  assignGuidesToInterviewersEnabled: Scalars["Boolean"];
  audioOnlyViewing: Scalars["Boolean"];
  allowManualScheduledInterviews: Scalars["Boolean"];
  allowInPersonMeetings: Scalars["Boolean"];
  playInPersonRecordingDisclaimer: Scalars["Boolean"];
  textBeforeCallEnabled: Scalars["Boolean"];
  includeTimestampsInNoteOutputs: Scalars["Boolean"];
  externalShareDefaultDurationDays: Scalars["Float"];
  allowUserLevelImportRules: Scalars["Boolean"];
}>;

export type UpdateOrganizationAdvancedConfigurationsMutation = {
  __typename?: "Mutation";
  updateOrganizationAdvancedConfigurations?: {
    __typename?: "UpdateOrganizationAdvancedConfigurations";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateOrganizationAiSettingsMutationVariables = Exact<{
  allowAiCustomTemplates: Scalars["Boolean"];
  pasteAiNotesInScorecard: Scalars["Boolean"];
  askEnabled: Scalars["Boolean"];
}>;

export type UpdateOrganizationAiSettingsMutation = {
  __typename?: "Mutation";
  updateOrganizationAiSettings?: {
    __typename?: "UpdateOrganizationAiSettings";
    organization: {
      __typename?: "Organization";
      id: any;
      allowAiCustomTemplates: boolean;
      pasteAiNotesInScorecard: boolean;
      askEnabled: boolean;
    };
  } | null;
};

export type UpdateOrganizationAtsSettingsMutationVariables = Exact<{
  postNotesToAts: Scalars["Boolean"];
}>;

export type UpdateOrganizationAtsSettingsMutation = {
  __typename?: "Mutation";
  updateOrganizationAtsSettings?: {
    __typename?: "UpdateOrganizationAtsSettings";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateOrganizationBetaMutationVariables = Exact<{
  allowSelfSignUp: Scalars["Boolean"];
  allowPhoneSignIn: Scalars["Boolean"];
  emailDomains: Array<Scalars["String"]> | Scalars["String"];
  inviteScheduledInterviewers: Scalars["Boolean"];
  requireActiveUserToImport?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateOrganizationBetaMutation = {
  __typename?: "Mutation";
  updateOrganizationBeta?: {
    __typename?: "UpdateOrganizationBeta";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateOrganizationGoogleMeetMutationVariables = Exact<{
  enabled: Scalars["Boolean"];
  disclaimerType: NotetakerRecorderDisclaimerType;
  disclaimer?: InputMaybe<Scalars["String"]>;
  virtualOnsiteEnabled?: InputMaybe<Scalars["Boolean"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  displayImage?: InputMaybe<Scalars["String"]>;
  displayImageFilename?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateOrganizationGoogleMeetMutation = {
  __typename?: "Mutation";
  updateOrganizationGoogleMeet?: {
    __typename?: "UpdateOrganizationGoogleMeet";
    organization: {
      __typename?: "Organization";
      id: any;
      name: string;
      freemium: boolean;
      phoneNumber?: string | null;
      playCallDisclaimer: boolean;
      isBullhornAuthorized: boolean;
      isZoomAuthorized: boolean;
      greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
      greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
      allowsGreenhouseScorecardAutoDraft: boolean;
      allowUserLevelImportRules: boolean;
      requiresGreenhouseScorecardAutoDraft: boolean;
      allowsZoomAppAutoOpen: boolean;
      requiresZoomAppAutoOpen: boolean;
      isZoomBotAuthorized: boolean;
      zoomNotetakerEnabled: boolean;
      recordingAllowed: boolean;
      googleMeetDisplayName?: string | null;
      googleMeetDisplayImageBucketPath?: string | null;
      googleMeetEnabled: boolean;
      virtualOnsiteEnabledGoogleMeet: boolean;
      googleMeetRecordingDisclaimer?: string | null;
      googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
      externalShareDefaultDurationDays: number;
      teamsNotetakerEnabled: boolean;
      teamsNotetakerDisplayName?: string | null;
      virtualOnsiteEnabledMsTeams: boolean;
      teamsNotetakerDisclaimer?: string | null;
      teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
      teamsNotetakerDisplayImageBucketPath?: string | null;
      notetakerRecorderDisclaimerDefault: string;
      audioOnlyViewing: boolean;
      allowSelfSignUp: boolean;
      allowPhoneSignIn: boolean;
      postNotesToAts: boolean;
      hideOtherInterviewerNotesInExtension: boolean;
      emailDomains?: Array<string> | null;
      inviteScheduledInterviewers: boolean;
      assignGuidesToInterviewersEnabled: boolean;
      requireActiveUserToImport: boolean;
      allowCandidateOptOut: boolean;
      candidateOptOutUrl: string;
      allowInterviewerOptOut: boolean;
      dataRedactionEnabled: boolean;
      dataRedactionPredictionEnabled: boolean;
      planEnabled: boolean;
      ats?: Ats | null;
      showStarRatings?: boolean | null;
      slackAppInstalled: boolean;
      allowManualScheduledInterviews: boolean;
      allowInPersonMeetings: boolean;
      scoringEnabled: boolean;
      guideCompetencyScoringEnabledByDefault: boolean;
      guideOverallScoringEnabledByDefault: boolean;
      playInPersonRecordingDisclaimer: boolean;
      includeTimestampsInNoteOutputs: boolean;
      interviewTypes: Array<InterviewType>;
      allowEmailSignIn: boolean;
      textBeforeCallEnabled: boolean;
      allowAiNotes: boolean;
      maximumCallDurationForNotetakerToRejoin?: number | null;
      individualVoipNumbersEnabled: boolean;
      callNameFieldIsDropdown: boolean;
      atsOptInEnabled: boolean;
      pasteAiNotesInScorecard: boolean;
      allowAiCustomTemplates: boolean;
      interviewEnabled: boolean;
      defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
      allowPlanJobDescriptionToneOverride: boolean;
      callDropdownNames?: Array<string> | null;
      askEnabled: boolean;
      createGuidesFromInterviewPlansEnabled: boolean;
      disableAnalyticsGenderSegmentation: boolean;
      ratingDescriptions: {
        __typename?: "RatingDescriptions";
        oneStar: string;
        twoStar: string;
        threeStar: string;
        fourStar: string;
        fiveStar: string;
      };
      competencyRatingDescriptions: {
        __typename?: "CompetencyRatingDescriptions";
        oneStar: string;
        twoStar: string;
        threeStar: string;
        fourStar: string;
        fiveStar: string;
      };
      userRoles: Array<{
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        permissions: Array<UserRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
        canAccessPublicCalls?: boolean | null;
        canAccessRestrictedCalls?: boolean | null;
        canAccessPrivateCalls?: boolean | null;
      }>;
      positionRoles: Array<{
        __typename?: "PositionRole";
        id: any;
        name: PositionRoleName;
        permissions: Array<PositionRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
        canAccessTeamPublicCalls?: boolean | null;
        canAccessTeamRestrictedCalls?: boolean | null;
        canAccessTeamPrivateCalls?: boolean | null;
      }>;
      planUserRoles: Array<{
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        permissions: Array<PlanUserRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
      }>;
      featureAccess?: Array<{
        __typename?: "OrganizationFeatureAccess";
        id: string;
        name: FeatureName;
        settingLevel: FeatureSettingLevel;
        isVisible: boolean;
        showCta: boolean;
        marketingRedirect: boolean;
      }> | null;
    };
  } | null;
};

export type UpdateOrganizationInterviewPlanSettingsMutationVariables = Exact<{
  createGuidesFromInterviewPlansEnabled: Scalars["Boolean"];
}>;

export type UpdateOrganizationInterviewPlanSettingsMutation = {
  __typename?: "Mutation";
  updateOrganizationInterviewPlanSettings?: {
    __typename?: "UpdateOrganizationInterviewPlanSettings";
    organization: {
      __typename?: "Organization";
      id: any;
      name: string;
      freemium: boolean;
      phoneNumber?: string | null;
      playCallDisclaimer: boolean;
      isBullhornAuthorized: boolean;
      isZoomAuthorized: boolean;
      greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
      greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
      allowsGreenhouseScorecardAutoDraft: boolean;
      allowUserLevelImportRules: boolean;
      requiresGreenhouseScorecardAutoDraft: boolean;
      allowsZoomAppAutoOpen: boolean;
      requiresZoomAppAutoOpen: boolean;
      isZoomBotAuthorized: boolean;
      zoomNotetakerEnabled: boolean;
      recordingAllowed: boolean;
      googleMeetDisplayName?: string | null;
      googleMeetDisplayImageBucketPath?: string | null;
      googleMeetEnabled: boolean;
      virtualOnsiteEnabledGoogleMeet: boolean;
      googleMeetRecordingDisclaimer?: string | null;
      googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
      externalShareDefaultDurationDays: number;
      teamsNotetakerEnabled: boolean;
      teamsNotetakerDisplayName?: string | null;
      virtualOnsiteEnabledMsTeams: boolean;
      teamsNotetakerDisclaimer?: string | null;
      teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
      teamsNotetakerDisplayImageBucketPath?: string | null;
      notetakerRecorderDisclaimerDefault: string;
      audioOnlyViewing: boolean;
      allowSelfSignUp: boolean;
      allowPhoneSignIn: boolean;
      postNotesToAts: boolean;
      hideOtherInterviewerNotesInExtension: boolean;
      emailDomains?: Array<string> | null;
      inviteScheduledInterviewers: boolean;
      assignGuidesToInterviewersEnabled: boolean;
      requireActiveUserToImport: boolean;
      allowCandidateOptOut: boolean;
      candidateOptOutUrl: string;
      allowInterviewerOptOut: boolean;
      dataRedactionEnabled: boolean;
      dataRedactionPredictionEnabled: boolean;
      planEnabled: boolean;
      ats?: Ats | null;
      showStarRatings?: boolean | null;
      slackAppInstalled: boolean;
      allowManualScheduledInterviews: boolean;
      allowInPersonMeetings: boolean;
      scoringEnabled: boolean;
      guideCompetencyScoringEnabledByDefault: boolean;
      guideOverallScoringEnabledByDefault: boolean;
      playInPersonRecordingDisclaimer: boolean;
      includeTimestampsInNoteOutputs: boolean;
      interviewTypes: Array<InterviewType>;
      allowEmailSignIn: boolean;
      textBeforeCallEnabled: boolean;
      allowAiNotes: boolean;
      maximumCallDurationForNotetakerToRejoin?: number | null;
      individualVoipNumbersEnabled: boolean;
      callNameFieldIsDropdown: boolean;
      atsOptInEnabled: boolean;
      pasteAiNotesInScorecard: boolean;
      allowAiCustomTemplates: boolean;
      interviewEnabled: boolean;
      defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
      allowPlanJobDescriptionToneOverride: boolean;
      callDropdownNames?: Array<string> | null;
      askEnabled: boolean;
      createGuidesFromInterviewPlansEnabled: boolean;
      disableAnalyticsGenderSegmentation: boolean;
      ratingDescriptions: {
        __typename?: "RatingDescriptions";
        oneStar: string;
        twoStar: string;
        threeStar: string;
        fourStar: string;
        fiveStar: string;
      };
      competencyRatingDescriptions: {
        __typename?: "CompetencyRatingDescriptions";
        oneStar: string;
        twoStar: string;
        threeStar: string;
        fourStar: string;
        fiveStar: string;
      };
      userRoles: Array<{
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        permissions: Array<UserRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
        canAccessPublicCalls?: boolean | null;
        canAccessRestrictedCalls?: boolean | null;
        canAccessPrivateCalls?: boolean | null;
      }>;
      positionRoles: Array<{
        __typename?: "PositionRole";
        id: any;
        name: PositionRoleName;
        permissions: Array<PositionRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
        canAccessTeamPublicCalls?: boolean | null;
        canAccessTeamRestrictedCalls?: boolean | null;
        canAccessTeamPrivateCalls?: boolean | null;
      }>;
      planUserRoles: Array<{
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        permissions: Array<PlanUserRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
      }>;
      featureAccess?: Array<{
        __typename?: "OrganizationFeatureAccess";
        id: string;
        name: FeatureName;
        settingLevel: FeatureSettingLevel;
        isVisible: boolean;
        showCta: boolean;
        marketingRedirect: boolean;
      }> | null;
    };
  } | null;
};

export type UpdateOrganizationScoringMutationVariables = Exact<{
  isEnabled: Scalars["Boolean"];
  guideOverallScoringEnabledByDefault: Scalars["Boolean"];
  guideCompetencyScoringEnabledByDefault: Scalars["Boolean"];
  oneStarRating: Scalars["String"];
  twoStarRating: Scalars["String"];
  threeStarRating: Scalars["String"];
  fourStarRating: Scalars["String"];
  fiveStarRating: Scalars["String"];
  oneStarCompetencyRating: Scalars["String"];
  twoStarCompetencyRating: Scalars["String"];
  threeStarCompetencyRating: Scalars["String"];
  fourStarCompetencyRating: Scalars["String"];
  fiveStarCompetencyRating: Scalars["String"];
}>;

export type UpdateOrganizationScoringMutation = {
  __typename?: "Mutation";
  updateOrganizationScoring?: {
    __typename?: "UpdateOrganizationScoring";
    organization: {
      __typename?: "Organization";
      id: any;
      name: string;
      freemium: boolean;
      phoneNumber?: string | null;
      playCallDisclaimer: boolean;
      isBullhornAuthorized: boolean;
      isZoomAuthorized: boolean;
      greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
      greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
      allowsGreenhouseScorecardAutoDraft: boolean;
      allowUserLevelImportRules: boolean;
      requiresGreenhouseScorecardAutoDraft: boolean;
      allowsZoomAppAutoOpen: boolean;
      requiresZoomAppAutoOpen: boolean;
      isZoomBotAuthorized: boolean;
      zoomNotetakerEnabled: boolean;
      recordingAllowed: boolean;
      googleMeetDisplayName?: string | null;
      googleMeetDisplayImageBucketPath?: string | null;
      googleMeetEnabled: boolean;
      virtualOnsiteEnabledGoogleMeet: boolean;
      googleMeetRecordingDisclaimer?: string | null;
      googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
      externalShareDefaultDurationDays: number;
      teamsNotetakerEnabled: boolean;
      teamsNotetakerDisplayName?: string | null;
      virtualOnsiteEnabledMsTeams: boolean;
      teamsNotetakerDisclaimer?: string | null;
      teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
      teamsNotetakerDisplayImageBucketPath?: string | null;
      notetakerRecorderDisclaimerDefault: string;
      audioOnlyViewing: boolean;
      allowSelfSignUp: boolean;
      allowPhoneSignIn: boolean;
      postNotesToAts: boolean;
      hideOtherInterviewerNotesInExtension: boolean;
      emailDomains?: Array<string> | null;
      inviteScheduledInterviewers: boolean;
      assignGuidesToInterviewersEnabled: boolean;
      requireActiveUserToImport: boolean;
      allowCandidateOptOut: boolean;
      candidateOptOutUrl: string;
      allowInterviewerOptOut: boolean;
      dataRedactionEnabled: boolean;
      dataRedactionPredictionEnabled: boolean;
      planEnabled: boolean;
      ats?: Ats | null;
      showStarRatings?: boolean | null;
      slackAppInstalled: boolean;
      allowManualScheduledInterviews: boolean;
      allowInPersonMeetings: boolean;
      scoringEnabled: boolean;
      guideCompetencyScoringEnabledByDefault: boolean;
      guideOverallScoringEnabledByDefault: boolean;
      playInPersonRecordingDisclaimer: boolean;
      includeTimestampsInNoteOutputs: boolean;
      interviewTypes: Array<InterviewType>;
      allowEmailSignIn: boolean;
      textBeforeCallEnabled: boolean;
      allowAiNotes: boolean;
      maximumCallDurationForNotetakerToRejoin?: number | null;
      individualVoipNumbersEnabled: boolean;
      callNameFieldIsDropdown: boolean;
      atsOptInEnabled: boolean;
      pasteAiNotesInScorecard: boolean;
      allowAiCustomTemplates: boolean;
      interviewEnabled: boolean;
      defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
      allowPlanJobDescriptionToneOverride: boolean;
      callDropdownNames?: Array<string> | null;
      askEnabled: boolean;
      createGuidesFromInterviewPlansEnabled: boolean;
      disableAnalyticsGenderSegmentation: boolean;
      ratingDescriptions: {
        __typename?: "RatingDescriptions";
        oneStar: string;
        twoStar: string;
        threeStar: string;
        fourStar: string;
        fiveStar: string;
      };
      competencyRatingDescriptions: {
        __typename?: "CompetencyRatingDescriptions";
        oneStar: string;
        twoStar: string;
        threeStar: string;
        fourStar: string;
        fiveStar: string;
      };
      userRoles: Array<{
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        permissions: Array<UserRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
        canAccessPublicCalls?: boolean | null;
        canAccessRestrictedCalls?: boolean | null;
        canAccessPrivateCalls?: boolean | null;
      }>;
      positionRoles: Array<{
        __typename?: "PositionRole";
        id: any;
        name: PositionRoleName;
        permissions: Array<PositionRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
        canAccessTeamPublicCalls?: boolean | null;
        canAccessTeamRestrictedCalls?: boolean | null;
        canAccessTeamPrivateCalls?: boolean | null;
      }>;
      planUserRoles: Array<{
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        permissions: Array<PlanUserRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
      }>;
      featureAccess?: Array<{
        __typename?: "OrganizationFeatureAccess";
        id: string;
        name: FeatureName;
        settingLevel: FeatureSettingLevel;
        isVisible: boolean;
        showCta: boolean;
        marketingRedirect: boolean;
      }> | null;
    };
  } | null;
};

export type UpdateOrganizationTeamsNotetakerMutationVariables = Exact<{
  displayName?: InputMaybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  disclaimerType: NotetakerRecorderDisclaimerType;
  disclaimer?: InputMaybe<Scalars["String"]>;
  virtualOnsiteEnabled?: InputMaybe<Scalars["Boolean"]>;
  displayImage?: InputMaybe<Scalars["String"]>;
  displayImageFilename?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateOrganizationTeamsNotetakerMutation = {
  __typename?: "Mutation";
  updateOrganizationTeamsNotetaker?: {
    __typename?: "UpdateOrganizationTeamsNotetaker";
    organization: {
      __typename?: "Organization";
      id: any;
      name: string;
      freemium: boolean;
      phoneNumber?: string | null;
      playCallDisclaimer: boolean;
      isBullhornAuthorized: boolean;
      isZoomAuthorized: boolean;
      greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
      greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
      allowsGreenhouseScorecardAutoDraft: boolean;
      allowUserLevelImportRules: boolean;
      requiresGreenhouseScorecardAutoDraft: boolean;
      allowsZoomAppAutoOpen: boolean;
      requiresZoomAppAutoOpen: boolean;
      isZoomBotAuthorized: boolean;
      zoomNotetakerEnabled: boolean;
      recordingAllowed: boolean;
      googleMeetDisplayName?: string | null;
      googleMeetDisplayImageBucketPath?: string | null;
      googleMeetEnabled: boolean;
      virtualOnsiteEnabledGoogleMeet: boolean;
      googleMeetRecordingDisclaimer?: string | null;
      googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
      externalShareDefaultDurationDays: number;
      teamsNotetakerEnabled: boolean;
      teamsNotetakerDisplayName?: string | null;
      virtualOnsiteEnabledMsTeams: boolean;
      teamsNotetakerDisclaimer?: string | null;
      teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
      teamsNotetakerDisplayImageBucketPath?: string | null;
      notetakerRecorderDisclaimerDefault: string;
      audioOnlyViewing: boolean;
      allowSelfSignUp: boolean;
      allowPhoneSignIn: boolean;
      postNotesToAts: boolean;
      hideOtherInterviewerNotesInExtension: boolean;
      emailDomains?: Array<string> | null;
      inviteScheduledInterviewers: boolean;
      assignGuidesToInterviewersEnabled: boolean;
      requireActiveUserToImport: boolean;
      allowCandidateOptOut: boolean;
      candidateOptOutUrl: string;
      allowInterviewerOptOut: boolean;
      dataRedactionEnabled: boolean;
      dataRedactionPredictionEnabled: boolean;
      planEnabled: boolean;
      ats?: Ats | null;
      showStarRatings?: boolean | null;
      slackAppInstalled: boolean;
      allowManualScheduledInterviews: boolean;
      allowInPersonMeetings: boolean;
      scoringEnabled: boolean;
      guideCompetencyScoringEnabledByDefault: boolean;
      guideOverallScoringEnabledByDefault: boolean;
      playInPersonRecordingDisclaimer: boolean;
      includeTimestampsInNoteOutputs: boolean;
      interviewTypes: Array<InterviewType>;
      allowEmailSignIn: boolean;
      textBeforeCallEnabled: boolean;
      allowAiNotes: boolean;
      maximumCallDurationForNotetakerToRejoin?: number | null;
      individualVoipNumbersEnabled: boolean;
      callNameFieldIsDropdown: boolean;
      atsOptInEnabled: boolean;
      pasteAiNotesInScorecard: boolean;
      allowAiCustomTemplates: boolean;
      interviewEnabled: boolean;
      defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
      allowPlanJobDescriptionToneOverride: boolean;
      callDropdownNames?: Array<string> | null;
      askEnabled: boolean;
      createGuidesFromInterviewPlansEnabled: boolean;
      disableAnalyticsGenderSegmentation: boolean;
      ratingDescriptions: {
        __typename?: "RatingDescriptions";
        oneStar: string;
        twoStar: string;
        threeStar: string;
        fourStar: string;
        fiveStar: string;
      };
      competencyRatingDescriptions: {
        __typename?: "CompetencyRatingDescriptions";
        oneStar: string;
        twoStar: string;
        threeStar: string;
        fourStar: string;
        fiveStar: string;
      };
      userRoles: Array<{
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        permissions: Array<UserRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
        canAccessPublicCalls?: boolean | null;
        canAccessRestrictedCalls?: boolean | null;
        canAccessPrivateCalls?: boolean | null;
      }>;
      positionRoles: Array<{
        __typename?: "PositionRole";
        id: any;
        name: PositionRoleName;
        permissions: Array<PositionRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
        canAccessTeamPublicCalls?: boolean | null;
        canAccessTeamRestrictedCalls?: boolean | null;
        canAccessTeamPrivateCalls?: boolean | null;
      }>;
      planUserRoles: Array<{
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        permissions: Array<PlanUserRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
      }>;
      featureAccess?: Array<{
        __typename?: "OrganizationFeatureAccess";
        id: string;
        name: FeatureName;
        settingLevel: FeatureSettingLevel;
        isVisible: boolean;
        showCta: boolean;
        marketingRedirect: boolean;
      }> | null;
    };
  } | null;
};

export type UpdateOrganizationVideoSettingsMutationVariables = Exact<{
  maximumCallDurationForNotetakerToRejoin?: InputMaybe<Scalars["Int"]>;
}>;

export type UpdateOrganizationVideoSettingsMutation = {
  __typename?: "Mutation";
  updateOrganizationVideoSettings?: {
    __typename?: "UpdateOrganizationVideoSettings";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateOrganizationZoomNotetakerMutationVariables = Exact<{
  enabled: Scalars["Boolean"];
  sendCancelRecordingChatMessage: Scalars["Boolean"];
  disclaimerType: NotetakerRecorderDisclaimerType;
  disclaimer?: InputMaybe<Scalars["String"]>;
  virtualOnsiteEnabled?: InputMaybe<Scalars["Boolean"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  displayImage?: InputMaybe<Scalars["String"]>;
  displayImageFilename?: InputMaybe<Scalars["String"]>;
  zoomAppAutoOpenRule: OrgZoomAppAutoOpenRule;
}>;

export type UpdateOrganizationZoomNotetakerMutation = {
  __typename?: "Mutation";
  updateOrganizationZoomNotetaker?: {
    __typename?: "UpdateOrganizationZoomNotetaker";
    organization: {
      __typename?: "Organization";
      id: any;
      zoomNotetakerEnabled: boolean;
      sendCancelRecordingChatMessage: boolean;
      zoomNotetakerDisclaimer?: string | null;
      zoomNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
      zoomNotetakerDisplayImageBucketPath?: string | null;
      zoomNotetakerDisplayName?: string | null;
      virtualOnsiteEnabledZoom: boolean;
      zoomAppAutoOpenRule?: OrgZoomAppAutoOpenRule | null;
    };
  } | null;
};

export type UpdateOrganizationZoomSettingsMutationVariables = Exact<{
  automaticallyDeleteZoomRecordings: Scalars["Boolean"];
  zoomAppAutoOpenRule: OrgZoomAppAutoOpenRule;
}>;

export type UpdateOrganizationZoomSettingsMutation = {
  __typename?: "Mutation";
  updateOrganizationZoomSettings?: {
    __typename?: "UpdateOrganizationZoomSettings";
    currentUser: {
      __typename?: "User";
      id: any;
      organization: {
        __typename?: "Organization";
        id: any;
        automaticallyDeleteZoomRecordings: boolean;
        zoomAppAutoOpenRule?: OrgZoomAppAutoOpenRule | null;
      };
    };
  } | null;
};

export type UpdatePlanJobDescriptionSettingsMutationVariables = Exact<{
  customTemplateId: Scalars["ID"];
  sections: Array<CustomTemplateSection> | CustomTemplateSection;
  defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
  allowPlanJobDescriptionToneOverride: Scalars["Boolean"];
}>;

export type UpdatePlanJobDescriptionSettingsMutation = {
  __typename?: "Mutation";
  updatePlanJobDescriptionSettings?: {
    __typename?: "UpdatePlanJobDescriptionSettings";
    planCustomTemplate?: {
      __typename?: "JobDescriptionTemplate";
      id: any;
      sections: Array<{
        __typename?: "JobDescriptionTemplateSection";
        id: any;
        sectionHeader: string;
        sectionText: string;
        order: number;
        type: JobDescriptionTemplateSectionType;
      }>;
    } | null;
    organization: {
      __typename?: "Organization";
      id: any;
      defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
      allowPlanJobDescriptionToneOverride: boolean;
    };
  } | null;
};

export type UpdatePositionMutationVariables = Exact<{
  id: Scalars["ID"];
  clientId?: InputMaybe<Scalars["ID"]>;
  title?: InputMaybe<Scalars["String"]>;
  videos?: InputMaybe<Array<VideoInput> | VideoInput>;
}>;

export type UpdatePositionMutation = {
  __typename?: "Mutation";
  updatePosition?: {
    __typename?: "UpdatePosition";
    position: {
      __typename?: "Position";
      id: any;
      title: string;
      createdAt: any;
      displayTitle: string;
      videos: Array<{ __typename?: "Video"; url: string }>;
      client: { __typename?: "Client"; id: any; name: string };
    };
  } | null;
};

export type UpdatePositionsAutoJoinMutationVariables = Exact<{
  positionIds: Array<Scalars["ID"]> | Scalars["ID"];
  autoJoinInterview: Scalars["Boolean"];
}>;

export type UpdatePositionsAutoJoinMutation = {
  __typename?: "Mutation";
  updatePositionsAutoJoin?: {
    __typename?: "UpdatePositionsAutoJoin";
    positions: Array<{
      __typename?: "Position";
      id: any;
      title: string;
      requisitionId?: string | null;
      locations?: Array<string | null> | null;
      createdAt: any;
      displayTitle: string;
      greenhouseId?: number | null;
      greenhouseLink?: string | null;
      leverPostingId?: string | null;
      autoJoinInterview: boolean;
      atsStatus?: string | null;
      client: {
        __typename?: "Client";
        id: any;
        name: string;
        autoJoinAllNewPositions: boolean;
      };
    }>;
  } | null;
};

export type UpdateRolePoliciesMutationVariables = Exact<{
  basicCanSeeOthersNotes: Scalars["Boolean"];
  recruiterCanSeeScores: Scalars["Boolean"];
  recruiterCanSeePublicCalls: Scalars["Boolean"];
  recruiterCanSeeRestrictedCalls: Scalars["Boolean"];
  recruiterCanManageHiringTeams: Scalars["Boolean"];
  recruiterCanCreatePositions: Scalars["Boolean"];
  recruiterCanSeeOthersNotes: Scalars["Boolean"];
  recruiterCanCreateTrainings: Scalars["Boolean"];
  recruiterCanManageAllTrainings: Scalars["Boolean"];
  recruiterCanShareRecordingsExternally: Scalars["Boolean"];
  recruiterCanViewInterviewerQualityReport: Scalars["Boolean"];
  recruiterCanConfigureInterviewerQualityReport: Scalars["Boolean"];
  recruiterCanDownloadRecordings: Scalars["Boolean"];
  recruiterCanManageRedactions: Scalars["Boolean"];
  recruiterCanManageCustomTemplates: Scalars["Boolean"];
  siteAdminCanDownloadRecordings: Scalars["Boolean"];
  hiringManagerCanSeePrivateCalls: Scalars["Boolean"];
  hiringManagerCanSeeOthersNotes: Scalars["Boolean"];
  hiringManagerCanCreateTrainings: Scalars["Boolean"];
  hiringManagerCanShareRecordingsExternally: Scalars["Boolean"];
  interviewerCanSeeOthersNotes: Scalars["Boolean"];
  memberCanSeePublicCalls: Scalars["Boolean"];
  memberCanSeeOthersNotes: Scalars["Boolean"];
  defaultSelfSignUpRole: Scalars["ID"];
  defaultScheduledInterviewerInviteRole: Scalars["ID"];
  defaultHiringTeamRole: Scalars["ID"];
  defaultPositionCreateRole: Scalars["ID"];
  defaultRecruiterCallVisibility: CallVisibility;
  defaultPlanRole?: InputMaybe<Scalars["ID"]>;
}>;

export type UpdateRolePoliciesMutation = {
  __typename?: "Mutation";
  updateRolePolicies?: {
    __typename?: "UpdateRolePolicies";
    rolePolicies: {
      __typename?: "RolePolicies";
      basicCanSeeOthersNotes: boolean;
      recruiterCanSeeScores: boolean;
      recruiterCanSeePublicCalls: boolean;
      recruiterCanSeeRestrictedCalls: boolean;
      recruiterCanManageHiringTeams: boolean;
      recruiterCanCreatePositions: boolean;
      recruiterCanSeeOthersNotes: boolean;
      recruiterCanCreateTrainings: boolean;
      recruiterCanManageAllTrainings: boolean;
      recruiterCanShareRecordingsExternally: boolean;
      recruiterCanViewInterviewerQualityReport: boolean;
      recruiterCanConfigureInterviewerQualityReport: boolean;
      recruiterCanManageRedactions: boolean;
      recruiterCanManageCustomTemplates: boolean;
      siteAdminCanDownloadRecordings: boolean;
      hiringManagerCanSeePrivateCalls: boolean;
      hiringManagerCanSeeOthersNotes: boolean;
      hiringManagerCanCreateTrainings: boolean;
      hiringManagerCanShareRecordingsExternally: boolean;
      interviewerCanSeeOthersNotes: boolean;
      memberCanSeePublicCalls: boolean;
      memberCanSeeOthersNotes: boolean;
      defaultSelfSignUpRole: string;
      defaultScheduledInterviewerInviteRole: string;
      defaultHiringTeamRole: string;
      defaultPositionCreateRole: string;
      defaultRecruiterCallVisibility: CallVisibility;
      defaultPlanRole?: string | null;
    };
    currentUser: {
      __typename?: "User";
      id: any;
      organization: {
        __typename?: "Organization";
        id: any;
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
        }>;
      };
    };
  } | null;
};

export type UpdateScheduledInterviewMutationVariables = Exact<{
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  interviewerIds: Array<Scalars["ID"]> | Scalars["ID"];
  scheduledStartTime: Scalars["DateTime"];
  timezoneName: Scalars["String"];
  durationMinutes: Scalars["Int"];
  zoomJoinUrl?: InputMaybe<Scalars["String"]>;
  googleMeetMeetingUrl?: InputMaybe<Scalars["String"]>;
  teamsMeetingUrl?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  positionId?: InputMaybe<Scalars["ID"]>;
  callGuideId?: InputMaybe<Scalars["ID"]>;
}>;

export type UpdateScheduledInterviewMutation = {
  __typename?: "Mutation";
  updateScheduledInterview?: {
    __typename?: "UpdateScheduledInterview";
    scheduledInterview?: {
      __typename?: "ScheduledInterview";
      id: string;
      name?: string | null;
      scheduledStart?: any | null;
      timezoneName?: string | null;
      scheduledEnd?: any | null;
      durationMinutes?: number | null;
      phoneNumber?: string | null;
      zoomId?: number | null;
      zoomJoinUrl?: string | null;
      zoomMeetingUrl?: string | null;
      zoomAppAutoOpenEnabled: boolean;
      googleMeetMeetingUrl?: string | null;
      teamsMeetingUrl?: string | null;
      isVideo: boolean;
      canAddBrighthire?: boolean | null;
      canAddBrighthireReason?: string | null;
      canLaunchMeeting?: boolean | null;
      canLaunchMeetingReason?: string | null;
      isExcluded: boolean;
      isImported: boolean;
      importDecision: boolean;
      importDecisionReason?: InterviewImportDecisionReason | null;
      greenhouseLink?: string | null;
      ghJobStageName?: string | null;
      optInUrl?: string | null;
      leverInterviewId?: string | null;
      smartrecruitersInterviewLink?: string | null;
      isInPersonInterview: boolean;
      interviewType?: InterviewType | null;
      isInterviewer: boolean;
      recordingDisabled: boolean;
      scheduledType: ScheduledInterviewType;
      leverStage?: {
        __typename?: "LeverStage";
        id: any;
        text?: string | null;
      } | null;
      scheduledInterviewers: Array<{
        __typename?: "ScheduledInterviewer";
        id: string;
        recordingDisabled: boolean;
        name?: string | null;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
          phoneNumber?: string | null;
        } | null;
      }>;
      callGuide?: { __typename?: "CallGuide"; id: any } | null;
      candidate?: {
        __typename?: "Candidate";
        id: any;
        firstName: string;
        lastName: string;
        fullName: string;
        callCount?: number | null;
        defaultPhoneNumber?: string | null;
        recordingDisabled: boolean;
      } | null;
      position?: {
        __typename?: "Position";
        id: any;
        displayTitle: string;
      } | null;
      googleMeetMeetings: Array<{
        __typename?: "GoogleMeetMeeting";
        id: string;
      }>;
      teamsNotetakerMeetings: Array<{
        __typename?: "TeamsNotetakerMeeting";
        id: string;
      }>;
    } | null;
  } | null;
};

export type UpdateScorecardCompetencyMutationVariables = Exact<{
  scorecardCompetencyId: Scalars["ID"];
  score: Scalars["Int"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateScorecardCompetencyMutation = {
  __typename?: "Mutation";
  updateScorecardCompetency?: {
    __typename?: "UpdateScorecardCompetency";
    scorecardItem: {
      __typename?: "ScorecardItem";
      id: string;
      score?: number | null;
      notes?: string | null;
      updatedAt?: any | null;
    };
  } | null;
};

export type UpdateScorecardMutationVariables = Exact<{
  scorecardId: Scalars["ID"];
  overallScore: Scalars["Int"];
  overallNotes?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateScorecardMutation = {
  __typename?: "Mutation";
  updateScorecard?: {
    __typename?: "UpdateScorecard";
    scorecard: {
      __typename?: "Scorecard";
      id: any;
      overallScore?: number | null;
      updatedAt: any;
      overallNotes?: string | null;
    };
  } | null;
};

export type UpdateSummaryNoteFeedbackMutationVariables = Exact<{
  noteId: Scalars["ID"];
  feedback: CandidateSummaryNoteAccuracyFeedback;
}>;

export type UpdateSummaryNoteFeedbackMutation = {
  __typename?: "Mutation";
  updateSummaryNoteFeedback?: {
    __typename?: "UpdateSummaryNoteFeedback";
    feedbackRecord: {
      __typename?: "CandidateSummaryNoteFeedback";
      id: any;
      accuracyFeedback: CandidateSummaryNoteAccuracyFeedback;
    };
  } | null;
};

export type UpdateTextMessageTemplateMutationVariables = Exact<{
  templateType: TextMessageTemplateType;
  content: Scalars["String"];
}>;

export type UpdateTextMessageTemplateMutation = {
  __typename?: "Mutation";
  updateTextMessageTemplate: {
    __typename?: "UpdateTextMessageTemplate";
    textMessageTemplate?: {
      __typename?: "TextMessageTemplate";
      id?: string | null;
      templateType: TextMessageTemplateType;
      content: string;
    } | null;
  };
};

export type UpdateUserRoleMutationVariables = Exact<{
  id: Scalars["ID"];
  userRoleId: Scalars["ID"];
}>;

export type UpdateUserRoleMutation = {
  __typename?: "Mutation";
  updateUserRole?: {
    __typename?: "UpdateUserRole";
    user: {
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      phoneNumberVerified: boolean;
      isCalendarAuthorized: boolean;
      createdAt: any;
      deletedAt?: any | null;
      signUpMethod: UserSignUpMethod;
      signUpMethodLabel: string;
      signUpCompletedAt?: any | null;
      voipPhoneNumber?: string | null;
      invitedBy?: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
      } | null;
    };
  } | null;
};

export type UpdateWebhookMutationVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]>;
  url: Scalars["String"];
  enableInterviewCompleted: Scalars["Boolean"];
  enableCandidateUpdated: Scalars["Boolean"];
}>;

export type UpdateWebhookMutation = {
  __typename?: "Mutation";
  updateWebhook?: {
    __typename?: "UpdateWebhook";
    setup: {
      __typename?: "RestApiSetup";
      apiTokens: Array<{
        __typename?: "OrganizationApiToken";
        id: string;
        name?: string | null;
        tokenPrefix?: string | null;
      }>;
      webhooks: Array<{
        __typename?: "WebhookConfiguration";
        id: string;
        webhookUrl?: string | null;
        webhookSecret?: string | null;
        eventInterviewCompleted: boolean;
        eventCandidateUpdatedRecordingDisabled: boolean;
      }>;
    };
  } | null;
};

export type UpdateZoomNotetakerMeetingIdMutationVariables = Exact<{
  meetingUuid: Scalars["String"];
  meetingId: Scalars["Float"];
}>;

export type UpdateZoomNotetakerMeetingIdMutation = {
  __typename?: "Mutation";
  updateZoomNotetakerMeetingId?: {
    __typename?: "UpdateZoomNotetakerMeetingId";
    success?: boolean | null;
  } | null;
};

export type VerifyEmailSignInMutationVariables = Exact<{
  email: Scalars["String"];
  verificationCode: Scalars["String"];
}>;

export type VerifyEmailSignInMutation = {
  __typename?: "Mutation";
  verifyEmailSignIn?: {
    __typename?: "VerifyEmailSignIn";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type VerifyExternalUserAccessCodeMutationVariables = Exact<{
  externalShareId: Scalars["ID"];
  verificationCode: Scalars["String"];
}>;

export type VerifyExternalUserAccessCodeMutation = {
  __typename?: "Mutation";
  verifyExternalUserAccessCode?: {
    __typename?: "VerifyExternalUserAccessCode";
    currentUser: { __typename?: "ExternalUser"; id: any };
  } | null;
};

export type VerifyPhoneNumberMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
  phoneNumberExtension: Scalars["String"];
}>;

export type VerifyPhoneNumberMutation = {
  __typename?: "Mutation";
  verifyPhoneNumber?: {
    __typename?: "VerifyPhoneNumber";
    validationCode?: string | null;
    verified: boolean;
  } | null;
};

export type VerifyPhoneSignInMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
  phoneNumberExtension?: InputMaybe<Scalars["String"]>;
  verificationCode: Scalars["String"];
}>;

export type VerifyPhoneSignInMutation = {
  __typename?: "Mutation";
  verifyPhoneSignIn?: {
    __typename?: "VerifyPhoneSignIn";
    currentUser: {
      __typename?: "User";
      id: any;
      internalUserRole?: InternalUserRoleName | null;
      internalUserRolePermissions: Array<string>;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      emailAliases: string;
      profilePicUrl?: string | null;
      persona?: UserPersona | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      maskPersonalPhoneNumber: boolean;
      phoneNumberVerified: boolean;
      numUnreadAlerts: number;
      voipPhoneNumber?: string | null;
      isImpersonated: boolean;
      autofillGreenhouseScorecard: boolean;
      zoomAppAutoOpenEnabled?: boolean | null;
      defaultCallType: CallType;
      intercomUserHash: string;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      requireCalendarSync: boolean;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
      callCount: number;
      lastCalledAt?: any | null;
      signUpMethod: UserSignUpMethod;
      timezoneName: string;
      hasViewedTranscriptionWarning: boolean;
      interviewRecapSubscription: UserSubscriptionSettings;
      interviewPrepSubscription: UserSubscriptionSettings;
      weeklyRecapSubscription: UserSubscriptionSettings;
      monthlyAnalyticsSubscription: UserSubscriptionSettings;
      callShareSubscription: UserSubscriptionSettings;
      callNoteSubscription: UserSubscriptionSettings;
      morningDigestSubscription: UserSubscriptionSettings;
      isZoomAppInstalled: boolean;
      shouldReauthorizeZoomApp: boolean;
      trainingProgramsCreatedCount: number;
      trainingProgramsCompletedCount: number;
      showCandidateActionItemAlerts: boolean;
      isInternationalCallingEnabled: boolean;
      defaultAiNoteFormat: CallAiSummaryFormat;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        phoneNumber?: string | null;
        playCallDisclaimer: boolean;
        isBullhornAuthorized: boolean;
        isZoomAuthorized: boolean;
        greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
        greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
        allowsGreenhouseScorecardAutoDraft: boolean;
        allowUserLevelImportRules: boolean;
        requiresGreenhouseScorecardAutoDraft: boolean;
        allowsZoomAppAutoOpen: boolean;
        requiresZoomAppAutoOpen: boolean;
        isZoomBotAuthorized: boolean;
        zoomNotetakerEnabled: boolean;
        recordingAllowed: boolean;
        googleMeetDisplayName?: string | null;
        googleMeetDisplayImageBucketPath?: string | null;
        googleMeetEnabled: boolean;
        virtualOnsiteEnabledGoogleMeet: boolean;
        googleMeetRecordingDisclaimer?: string | null;
        googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
        externalShareDefaultDurationDays: number;
        teamsNotetakerEnabled: boolean;
        teamsNotetakerDisplayName?: string | null;
        virtualOnsiteEnabledMsTeams: boolean;
        teamsNotetakerDisclaimer?: string | null;
        teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
        teamsNotetakerDisplayImageBucketPath?: string | null;
        notetakerRecorderDisclaimerDefault: string;
        audioOnlyViewing: boolean;
        allowSelfSignUp: boolean;
        allowPhoneSignIn: boolean;
        postNotesToAts: boolean;
        hideOtherInterviewerNotesInExtension: boolean;
        emailDomains?: Array<string> | null;
        inviteScheduledInterviewers: boolean;
        assignGuidesToInterviewersEnabled: boolean;
        requireActiveUserToImport: boolean;
        allowCandidateOptOut: boolean;
        candidateOptOutUrl: string;
        allowInterviewerOptOut: boolean;
        dataRedactionEnabled: boolean;
        dataRedactionPredictionEnabled: boolean;
        planEnabled: boolean;
        ats?: Ats | null;
        showStarRatings?: boolean | null;
        slackAppInstalled: boolean;
        allowManualScheduledInterviews: boolean;
        allowInPersonMeetings: boolean;
        scoringEnabled: boolean;
        guideCompetencyScoringEnabledByDefault: boolean;
        guideOverallScoringEnabledByDefault: boolean;
        playInPersonRecordingDisclaimer: boolean;
        includeTimestampsInNoteOutputs: boolean;
        interviewTypes: Array<InterviewType>;
        allowEmailSignIn: boolean;
        textBeforeCallEnabled: boolean;
        allowAiNotes: boolean;
        maximumCallDurationForNotetakerToRejoin?: number | null;
        individualVoipNumbersEnabled: boolean;
        callNameFieldIsDropdown: boolean;
        atsOptInEnabled: boolean;
        pasteAiNotesInScorecard: boolean;
        allowAiCustomTemplates: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        callDropdownNames?: Array<string> | null;
        askEnabled: boolean;
        createGuidesFromInterviewPlansEnabled: boolean;
        disableAnalyticsGenderSegmentation: boolean;
        ratingDescriptions: {
          __typename?: "RatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        competencyRatingDescriptions: {
          __typename?: "CompetencyRatingDescriptions";
          oneStar: string;
          twoStar: string;
          threeStar: string;
          fourStar: string;
          fiveStar: string;
        };
        userRoles: Array<{
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
          permissions: Array<UserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessPublicCalls?: boolean | null;
          canAccessRestrictedCalls?: boolean | null;
          canAccessPrivateCalls?: boolean | null;
        }>;
        positionRoles: Array<{
          __typename?: "PositionRole";
          id: any;
          name: PositionRoleName;
          permissions: Array<PositionRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
          canAccessTeamPublicCalls?: boolean | null;
          canAccessTeamRestrictedCalls?: boolean | null;
          canAccessTeamPrivateCalls?: boolean | null;
        }>;
        planUserRoles: Array<{
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
          permissions: Array<PlanUserRolePermission>;
          formattedName?: string | null;
          formattedPermissions?: string | null;
        }>;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
        canViewInsights?: boolean | null;
        canViewInsightsAlerts?: boolean | null;
        canManageIQReport?: boolean | null;
        canManageCustomTemplates?: boolean | null;
      } | null;
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
        permissions: Array<PlanUserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageUserRoles?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
          canViewInsights?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type ViewCallMutationVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type ViewCallMutation = {
  __typename?: "Mutation";
  viewCall?: { __typename?: "ViewCall"; callViewed: boolean } | null;
};

export type ViewCandidateMutationVariables = Exact<{
  candidateId: Scalars["ID"];
}>;

export type ViewCandidateMutation = {
  __typename?: "Mutation";
  viewCandidate?: {
    __typename?: "ViewCandidate";
    candidateViewed: boolean;
  } | null;
};

export type ViewClipMutationVariables = Exact<{
  clipId: Scalars["ID"];
  playlistId?: InputMaybe<Scalars["ID"]>;
}>;

export type ViewClipMutation = {
  __typename?: "Mutation";
  viewClip?: { __typename?: "ViewClip"; clipViewed: boolean } | null;
};

export type ViewRedactionPredictionMutationVariables = Exact<{
  callId: Scalars["ID"];
  predictionId: Scalars["ID"];
}>;

export type ViewRedactionPredictionMutation = {
  __typename?: "Mutation";
  viewRedactionPrediction?: {
    __typename?: "ViewRedactionPrediction";
    redactionPrediction: {
      __typename?: "RedactionPrediction";
      id: any;
      viewed: boolean;
    };
  } | null;
};

export type ViewCallScorecardMutationVariables = Exact<{
  callId: Scalars["ID"];
  scorecardId: Scalars["ID"];
}>;

export type ViewCallScorecardMutation = {
  __typename?: "Mutation";
  viewCallScorecard?: {
    __typename?: "ViewCallScorecard";
    scorecardViewed: boolean;
  } | null;
};

export type CreateAiSummaryTemplateMutationVariables = Exact<{
  name: Scalars["String"];
  isCreatorDefault?: InputMaybe<Scalars["Boolean"]>;
  sections:
    | Array<AiSummaryTemplateSectionInput>
    | AiSummaryTemplateSectionInput;
  customType?: InputMaybe<CustomTemplateCustomType>;
  customPrompt?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  visibility?: InputMaybe<CallAiSummaryTemplateVisibility>;
  isCurrentUserDefault?: InputMaybe<Scalars["Boolean"]>;
  defaultFor?: InputMaybe<CallAiSummaryTemplateDefaultFor>;
  defaultForPersona?: InputMaybe<UserPersona>;
  defaultForKeywords?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  defaultForMeetingType?: InputMaybe<CustomTemplateType>;
}>;

export type CreateAiSummaryTemplateMutation = {
  __typename?: "Mutation";
  createAiSummaryTemplate?: {
    __typename?: "CreateAiSummaryTemplate";
    validationError?: Array<string | null> | null;
    template?: { __typename?: "CallAiSummaryTemplate"; id: any } | null;
  } | null;
};

export type DeleteAiSummaryTemplateMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteAiSummaryTemplateMutation = {
  __typename?: "Mutation";
  deleteAiSummaryTemplate?: {
    __typename?: "DeleteAiSummaryTemplate";
    template?: { __typename?: "CallAiSummaryTemplate"; id: any } | null;
  } | null;
};

export type DuplicateAiSummaryTemplateMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DuplicateAiSummaryTemplateMutation = {
  __typename?: "Mutation";
  duplicateAiSummaryTemplate?: {
    __typename?: "DuplicateAiSummaryTemplate";
    template?: {
      __typename?: "CallAiSummaryTemplate";
      id: any;
      name: string;
      createdAt: any;
      visibility: CallAiSummaryTemplateVisibility;
      canEdit: boolean;
      canDelete: boolean;
      defaultFor: CallAiSummaryTemplateDefaultFor;
      defaultForPersona?: UserPersona | null;
      defaultForKeywords: Array<string>;
      defaultForMeetingType?: CustomTemplateType | null;
      creator: { __typename?: "User"; id: any; fullName: string };
    } | null;
  } | null;
};

export type UpdateAiSummaryTemplateMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
  isCreatorDefault?: InputMaybe<Scalars["Boolean"]>;
  sections:
    | Array<AiSummaryTemplateSectionInput>
    | AiSummaryTemplateSectionInput;
  customType?: InputMaybe<CustomTemplateCustomType>;
  customPrompt?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  visibility?: InputMaybe<CallAiSummaryTemplateVisibility>;
  isCurrentUserDefault?: InputMaybe<Scalars["Boolean"]>;
  defaultFor?: InputMaybe<CallAiSummaryTemplateDefaultFor>;
  defaultForPersona?: InputMaybe<UserPersona>;
  defaultForKeywords?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  defaultForMeetingType?: InputMaybe<CustomTemplateType>;
}>;

export type UpdateAiSummaryTemplateMutation = {
  __typename?: "Mutation";
  updateAiSummaryTemplate?: {
    __typename?: "UpdateAiSummaryTemplate";
    validationError?: Array<string | null> | null;
    template?: { __typename?: "CallAiSummaryTemplate"; id: any } | null;
  } | null;
};

export type AddInPersonCallMutationVariables = Exact<{
  interviewerIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  scheduledInterviewId?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  candidateId?: InputMaybe<Scalars["ID"]>;
  candidateFirstName?: InputMaybe<Scalars["String"]>;
  candidateLastName?: InputMaybe<Scalars["String"]>;
  candidateEmail?: InputMaybe<Scalars["String"]>;
  positionId?: InputMaybe<Scalars["ID"]>;
  callGuideId?: InputMaybe<Scalars["ID"]>;
  recordingContentType: Scalars["String"];
  iaVersion?: InputMaybe<Scalars["Float"]>;
}>;

export type AddInPersonCallMutation = {
  __typename?: "Mutation";
  addInPersonCall?: {
    __typename?: "AddInPersonCall";
    recordingUploadUrl: string;
    call: { __typename?: "Call"; id: any };
  } | null;
};

export type DeleteAiSummaryCustomTopicMutationVariables = Exact<{
  customTopicNoteId: Scalars["ID"];
}>;

export type DeleteAiSummaryCustomTopicMutation = {
  __typename?: "Mutation";
  deleteAiSummaryCustomTopic?: {
    __typename?: "DeleteAiSummaryCustomTopic";
    summary: {
      __typename?: "CallAiSummary";
      id: any;
      status: CallAiSummaryProcessingStatus;
      format: CallAiSummaryFormat;
      processingStartedAt?: any | null;
      targetSpeakerTags: Array<number>;
      customTopics?: Array<{
        __typename?: "CallAiSummaryCustomTopic";
        title: string;
        questions: Array<string>;
      }> | null;
      headers?: Array<{
        __typename?: "CallAiSummaryHeader";
        id: any;
        text: string;
        notes?: Array<{
          __typename?: "CallAiSummaryNote";
          id: any;
          text: string;
          tag?: string | null;
          startTime: number;
          startTimes: Array<number | null>;
          variation1?: string | null;
          variation2?: string | null;
        }> | null;
      }> | null;
    };
  } | null;
};

export type EndInPersonCallMutationVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type EndInPersonCallMutation = {
  __typename?: "Mutation";
  endInPersonCall?: {
    __typename?: "EndInPersonCall";
    call: {
      __typename?: "Call";
      id: any;
      status: CallStatus;
      endTime?: any | null;
      duration?: number | null;
    };
  } | null;
};

export type ProcessInPersonRecordingMutationVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type ProcessInPersonRecordingMutation = {
  __typename?: "Mutation";
  processInPersonRecording?: {
    __typename?: "ProcessInPersonRecording";
    success: boolean;
  } | null;
};

export type SendBeforeCallTextMessageMutationVariables = Exact<{
  body: Scalars["String"];
  candidatePhone: Scalars["String"];
  candidateFirstName?: InputMaybe<Scalars["String"]>;
  candidateLastName?: InputMaybe<Scalars["String"]>;
}>;

export type SendBeforeCallTextMessageMutation = {
  __typename?: "Mutation";
  sendBeforeCallTextMessage: {
    __typename?: "SendBeforeCallTextMessage";
    textMessage?: {
      __typename?: "TextMessage";
      messageSid: string;
      messageStatus: TextMessageStatus;
    } | null;
  };
};

export type UpdateCallAiSummaryFormatMutationVariables = Exact<{
  callId: Scalars["ID"];
  format?: InputMaybe<CallAiSummaryFormat>;
  customTemplateId?: InputMaybe<Scalars["ID"]>;
  targetSpeakerTags: Array<Scalars["Int"]> | Scalars["Int"];
  forceRegenerate?: InputMaybe<Scalars["Boolean"]>;
  customTopics:
    | Array<CallAiSummaryCustomTopicInput>
    | CallAiSummaryCustomTopicInput;
}>;

export type UpdateCallAiSummaryFormatMutation = {
  __typename?: "Mutation";
  updateCallAiSummaryFormat?: {
    __typename?: "UpdateCallAISummaryFormat";
    summary?: {
      __typename?: "CallAiSummary";
      id: any;
      status: CallAiSummaryProcessingStatus;
    } | null;
  } | null;
};

export type UpdateCallCandidateMutationVariables = Exact<{
  callId: Scalars["ID"];
  candidateId?: InputMaybe<Scalars["ID"]>;
}>;

export type UpdateCallCandidateMutation = {
  __typename?: "Mutation";
  updateCallCandidate?: {
    __typename?: "UpdateCallCandidate";
    call: {
      __typename?: "Call";
      id: any;
      candidate?: {
        __typename?: "Candidate";
        firstName: string;
        lastName: string;
        defaultEmail?: string | null;
        bullhornId?: number | null;
        id: any;
        fullName: string;
        greenhouseLink?: string | null;
        lastLeverOpportunityId?: string | null;
        candidateLinks: Array<{
          __typename?: "CandidateLink";
          id: any;
          link: string;
          type: CandidateLinkType;
        }>;
      } | null;
    };
  } | null;
};

export type AutoGenerateCandidateSummaryIfMissingMutationVariables = Exact<{
  candidateId: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
  templateId: Scalars["ID"];
}>;

export type AutoGenerateCandidateSummaryIfMissingMutation = {
  __typename?: "Mutation";
  autoGenerateCandidateSummaryIfMissing?: {
    __typename?: "AutoGenerateCandidateSummaryIfMissing";
    created: boolean;
  } | null;
};

export type GenerateCandidateSummaryMutationVariables = Exact<{
  candidateId: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
  templateId: Scalars["ID"];
}>;

export type GenerateCandidateSummaryMutation = {
  __typename?: "Mutation";
  generateCandidateSummary?: {
    __typename?: "GenerateCandidateSummary";
    summary: {
      __typename?: "CandidateSummary";
      id: any;
      status: CandidateSummaryProcessingStatus;
      notes: Array<{
        __typename?: "CandidateSummaryNote";
        id: any;
        text: string;
        topic?: string | null;
        subtopic?: string | null;
        position: number;
        citations: Array<{
          __typename?: "CandidateSummaryCitation";
          id: any;
          callId: string;
          time: number;
        }>;
      }>;
      calls: Array<{ __typename?: "Call"; id: any }>;
    };
  } | null;
};

export type UpdateCandidateSummaryTemplateMutationVariables = Exact<{
  templateId: Scalars["ID"];
  sections:
    | Array<CandidateSummaryTemplateSectionInput>
    | CandidateSummaryTemplateSectionInput;
  isDefault: Scalars["Boolean"];
  positionId?: InputMaybe<Scalars["ID"]>;
  newTemplateName?: InputMaybe<Scalars["String"]>;
  asNewTemplate?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateCandidateSummaryTemplateMutation = {
  __typename?: "Mutation";
  updateCandidateSummaryTemplate?: {
    __typename?: "UpdateCandidateSummaryTemplate";
    template: {
      __typename?: "CandidateSummaryTemplate";
      id: any;
      name: string;
      isOrgDefault: boolean;
      isPositionDefault?: boolean | null;
      processingStatus?: CandidateSummaryTemplateStatus | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      sections: Array<{
        __typename?: "CandidateSummaryTemplateSection";
        id: any;
        position: number;
        title: string;
        prompt: Array<string | null>;
        subsections: Array<{
          __typename?: "CandidateSummaryTemplateSubsection";
          id: any;
          position: number;
          title: string;
          prompt: Array<string | null>;
        }>;
      }>;
      positions: Array<{
        __typename?: "Position";
        id: any;
        title: string;
        candidateSummaryTemplateId?: string | null;
      }>;
    };
  } | null;
};

export type ViewHighlightMutationVariables = Exact<{
  callId: Scalars["ID"];
  highlightId: Scalars["ID"];
  highlightType: Scalars["String"];
}>;

export type ViewHighlightMutation = {
  __typename?: "Mutation";
  viewHighlight?: { __typename?: "ViewHighlight"; viewed: boolean } | null;
};

export type MarkCandidateAlertFeedReadMutationVariables = Exact<{
  candidateAlertFeedIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  alertFilters?: InputMaybe<
    Array<CandidateAlertFeedFilter> | CandidateAlertFeedFilter
  >;
}>;

export type MarkCandidateAlertFeedReadMutation = {
  __typename?: "Mutation";
  markCandidateAlertFeedRead?: {
    __typename?: "MarkCandidateAlertFeedRead";
    markedIds: Array<string>;
    readAt: any;
    currentUser: { __typename?: "User"; id: any; numUnreadAlerts: number };
  } | null;
};

export type UpdateCandidateAlertAccuracyFeedbackMutationVariables = Exact<{
  candidateAlertFeedIds: Array<Scalars["ID"]> | Scalars["ID"];
  feedback: CandidateAlertAccuracyFeedback;
}>;

export type UpdateCandidateAlertAccuracyFeedbackMutation = {
  __typename?: "Mutation";
  updateCandidateAlertAccuracyFeedback?: {
    __typename?: "UpdateCandidateAlertAccuracyFeedback";
    updatedIds: Array<string>;
    feedback: CandidateAlertAccuracyFeedback;
  } | null;
};

export type UpdateCurrentUserOnboardingStepMutationVariables = Exact<{
  steps: Array<UpdateOnboardingSteps> | UpdateOnboardingSteps;
}>;

export type UpdateCurrentUserOnboardingStepMutation = {
  __typename?: "Mutation";
  updateCurrentUserOnboardingStep?: {
    __typename?: "UpdateCurrentUserOnboardingStep";
    currentUser: {
      __typename?: "User";
      id: any;
      signUpCompletedAt?: any | null;
      onboardingSteps: Array<{
        __typename?: "UserOnboardingStep";
        step: OnboardingStep;
        rule: OnboardingStepRule;
        status?: OnboardingStepStatus | null;
      }>;
    };
  } | null;
};

export type CreateEventImportKeywordMutationVariables = Exact<{
  keywords: Array<Scalars["String"]> | Scalars["String"];
}>;

export type CreateEventImportKeywordMutation = {
  __typename?: "Mutation";
  createEventImportKeyword?: {
    __typename?: "CreateEventImportKeyword";
    eventImportKeywords: Array<{
      __typename?: "EventImportKeyword";
      id: any;
      keywordText: string;
    }>;
  } | null;
};

export type DeleteEventImportKeywordMutationVariables = Exact<{
  keywords: Array<Scalars["String"]> | Scalars["String"];
}>;

export type DeleteEventImportKeywordMutation = {
  __typename?: "Mutation";
  deleteEventImportKeyword?: {
    __typename?: "DeleteEventImportKeyword";
    success: boolean;
  } | null;
};

export type RemoveExternalCallShareMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveExternalCallShareMutation = {
  __typename?: "Mutation";
  removeExternalCallShare?: {
    __typename?: "RemoveExternalCallShare";
    call: {
      __typename?: "Call";
      id: any;
      externalCallShares: Array<{
        __typename?: "ExternalCallShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        isExpired: boolean;
        shareDaysRemaining: number;
        daysRemaining: number;
        sharedToExternalUser: {
          __typename?: "ExternalUser";
          id: any;
          email: string;
        };
        sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
      }>;
    };
  } | null;
};

export type RemoveExternalClipShareMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveExternalClipShareMutation = {
  __typename?: "Mutation";
  removeExternalClipShare?: {
    __typename?: "RemoveExternalClipShare";
    clip: {
      __typename?: "Clip";
      id: any;
      externalClipShares: Array<{
        __typename?: "ExternalClipShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        isExpired: boolean;
        shareDaysRemaining: number;
        daysRemaining: number;
        sharedToExternalUser: {
          __typename?: "ExternalUser";
          id: any;
          email: string;
        };
        sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
      }>;
    };
  } | null;
};

export type RenewExternalCallShareMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RenewExternalCallShareMutation = {
  __typename?: "Mutation";
  renewExternalCallShare?: {
    __typename?: "RenewExternalCallShare";
    call: {
      __typename?: "Call";
      id: any;
      externalCallShares: Array<{
        __typename?: "ExternalCallShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        isExpired: boolean;
        shareDaysRemaining: number;
        daysRemaining: number;
        sharedToExternalUser: {
          __typename?: "ExternalUser";
          id: any;
          email: string;
        };
        sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
      }>;
    };
  } | null;
};

export type RenewExternalClipShareMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RenewExternalClipShareMutation = {
  __typename?: "Mutation";
  renewExternalClipShare?: {
    __typename?: "RenewExternalClipShare";
    clip: {
      __typename?: "Clip";
      id: any;
      externalClipShares: Array<{
        __typename?: "ExternalClipShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        isExpired: boolean;
        shareDaysRemaining: number;
        daysRemaining: number;
        sharedToExternalUser: {
          __typename?: "ExternalUser";
          id: any;
          email: string;
        };
        sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
      }>;
    };
  } | null;
};

export type ShareCallExternallyMutationVariables = Exact<{
  callId: Scalars["ID"];
  shareToEmails: Array<Scalars["String"]> | Scalars["String"];
  message?: InputMaybe<Scalars["String"]>;
}>;

export type ShareCallExternallyMutation = {
  __typename?: "Mutation";
  shareCallExternally?: {
    __typename?: "ShareCallExternally";
    call: {
      __typename?: "Call";
      id: any;
      externalCallShares: Array<{
        __typename?: "ExternalCallShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        isExpired: boolean;
        shareDaysRemaining: number;
        daysRemaining: number;
        sharedToExternalUser: {
          __typename?: "ExternalUser";
          id: any;
          email: string;
        };
        sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
      }>;
    };
  } | null;
};

export type ShareClipExternallyMutationVariables = Exact<{
  clipId: Scalars["ID"];
  shareToEmails: Array<Scalars["String"]> | Scalars["String"];
  message?: InputMaybe<Scalars["String"]>;
}>;

export type ShareClipExternallyMutation = {
  __typename?: "Mutation";
  shareClipExternally?: {
    __typename?: "ShareClipExternally";
    clip: {
      __typename?: "Clip";
      id: any;
      externalClipShares: Array<{
        __typename?: "ExternalClipShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        isExpired: boolean;
        shareDaysRemaining: number;
        daysRemaining: number;
        sharedToExternalUser: {
          __typename?: "ExternalUser";
          id: any;
          email: string;
        };
        sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
      }>;
    };
  } | null;
};

export type AddCallGuideCueMutationVariables = Exact<{
  callGuideId: Scalars["ID"];
  cue: Scalars["String"];
  description: Scalars["String"];
}>;

export type AddCallGuideCueMutation = {
  __typename?: "Mutation";
  addCallGuideCue?: {
    __typename?: "AddCallGuideCue";
    callGuideCue: {
      __typename?: "CallGuideCue";
      id: any;
      cue: string;
      description: string;
      isHtmlType: boolean;
      position: number;
      scoringEnabled: boolean;
      type: GuideItemType;
      competencyId?: any | null;
      childItems: Array<{
        __typename?: "CallGuideItemChild";
        id: any;
        description: string;
        position: number;
      }>;
    };
  } | null;
};

export type AddCallGuideMutationVariables = Exact<{
  name: Scalars["String"];
  positionId?: InputMaybe<Scalars["ID"]>;
  interviewStageId?: InputMaybe<Scalars["ID"]>;
  assignedUserIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type AddCallGuideMutation = {
  __typename?: "Mutation";
  addCallGuide?: {
    __typename?: "AddCallGuide";
    callGuide: {
      __typename?: "CallGuide";
      id: any;
      name?: string | null;
      visibility: CallGuideVisibility;
      isTemplate: boolean;
      canEdit: boolean;
      canManageCallGuideAssignments: boolean;
      createdAt: any;
      updatedAt: any;
      greenhouseId?: number | null;
      leverFeedbackTemplateId?: string | null;
      customAtsId?: string | null;
      ashbyFeedbackFormId?: string | null;
      smartrecruitersCriteriaId?: string | null;
      atsId?: string | null;
      overallScoringEnabled: boolean;
      position?: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
        client: { __typename?: "Client"; id: any; name: string };
      } | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      assignedUsers: Array<{
        __typename?: "CallGuideUser";
        createdAt: any;
        userId: any;
        user: {
          __typename?: "User";
          id: any;
          createdAt: any;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
          email: string;
        };
      }>;
      cues: Array<{
        __typename?: "CallGuideCue";
        id: any;
        cue: string;
        description: string;
        isHtmlType: boolean;
        position: number;
        scoringEnabled: boolean;
        type: GuideItemType;
        competencyId?: any | null;
        childItems: Array<{
          __typename?: "CallGuideItemChild";
          id: any;
          description: string;
          position: number;
        }>;
      }>;
      organization: {
        __typename?: "Organization";
        id: any;
        scoringEnabled: boolean;
      };
      interviewStage?: {
        __typename?: "InterviewStage";
        id: any;
        title: string;
      } | null;
    };
  } | null;
};

export type AddCompetencyMutationVariables = Exact<{
  callGuideId: Scalars["ID"];
  name: Scalars["String"];
}>;

export type AddCompetencyMutation = {
  __typename?: "Mutation";
  addCompetency?: {
    __typename?: "AddCompetency";
    competency: {
      __typename?: "OrganizationCompetency";
      id: any;
      name: string;
      creatorId?: any | null;
      isMigrated: boolean;
    };
  } | null;
};

export type DeleteCallGuideCueMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCallGuideCueMutation = {
  __typename?: "Mutation";
  deleteCallGuideCue?: {
    __typename?: "DeleteCallGuideCue";
    callGuideCue: { __typename?: "CallGuideCue"; id: any };
  } | null;
};

export type DeleteCallGuideMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCallGuideMutation = {
  __typename?: "Mutation";
  deleteCallGuide?: {
    __typename?: "DeleteCallGuide";
    callGuide: { __typename?: "CallGuide"; id: any; positionId?: any | null };
  } | null;
};

export type DuplicateCallGuideMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DuplicateCallGuideMutation = {
  __typename?: "Mutation";
  duplicateCallGuide?: {
    __typename?: "DuplicateCallGuide";
    callGuide: {
      __typename?: "CallGuide";
      canEdit: boolean;
      id: any;
      name?: string | null;
      isTemplate: boolean;
      visibility: CallGuideVisibility;
      createdAt: any;
      updatedAt: any;
      greenhouseId?: number | null;
      leverFeedbackTemplateId?: string | null;
      ashbyFeedbackFormId?: string | null;
      smartrecruitersCriteriaId?: string | null;
      atsId?: string | null;
      overallScoringEnabled: boolean;
      position?: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      } | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      cues: Array<{
        __typename?: "CallGuideCue";
        id: any;
        cue: string;
        description: string;
        isHtmlType: boolean;
        position: number;
        scoringEnabled: boolean;
        type: GuideItemType;
        competencyId?: any | null;
        childItems: Array<{
          __typename?: "CallGuideItemChild";
          id: any;
          description: string;
          position: number;
        }>;
      }>;
      assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
      interviewStage?: {
        __typename?: "InterviewStage";
        id: any;
        title: string;
      } | null;
    };
  } | null;
};

export type DuplicateCallGuidesToPositionMutationVariables = Exact<{
  toPositionId: Scalars["String"];
  fromPositionId: Scalars["String"];
  callGuideIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type DuplicateCallGuidesToPositionMutation = {
  __typename?: "Mutation";
  duplicateCallGuidesToPosition?: {
    __typename?: "DuplicateCallGuidesToPosition";
    position: { __typename?: "Position"; id: any };
  } | null;
};

export type OverrideCallGuideCueMutationVariables = Exact<{
  id: Scalars["ID"];
  cue?: InputMaybe<Scalars["String"]>;
}>;

export type OverrideCallGuideCueMutation = {
  __typename?: "Mutation";
  overrideCallGuideCue?: {
    __typename?: "OverrideCallGuideCue";
    callGuideCue: {
      __typename?: "CallGuideCue";
      id: any;
      cue: string;
      description: string;
      isHtmlType: boolean;
      position: number;
      scoringEnabled: boolean;
      type: GuideItemType;
      competencyId?: any | null;
      childItems: Array<{
        __typename?: "CallGuideItemChild";
        id: any;
        description: string;
        position: number;
      }>;
    };
  } | null;
};

export type RemoveCallGuideAssignedUserMutationVariables = Exact<{
  callGuideId: Scalars["ID"];
  userId: Scalars["ID"];
}>;

export type RemoveCallGuideAssignedUserMutation = {
  __typename?: "Mutation";
  removeCallGuideAssignedUser?: {
    __typename?: "RemoveCallGuideAssignedUser";
    callGuide: {
      __typename?: "CallGuide";
      canEdit: boolean;
      id: any;
      name?: string | null;
      isTemplate: boolean;
      visibility: CallGuideVisibility;
      createdAt: any;
      updatedAt: any;
      greenhouseId?: number | null;
      leverFeedbackTemplateId?: string | null;
      ashbyFeedbackFormId?: string | null;
      smartrecruitersCriteriaId?: string | null;
      atsId?: string | null;
      overallScoringEnabled: boolean;
      position?: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      } | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      cues: Array<{
        __typename?: "CallGuideCue";
        id: any;
        cue: string;
        description: string;
        isHtmlType: boolean;
        position: number;
        scoringEnabled: boolean;
        type: GuideItemType;
        competencyId?: any | null;
        childItems: Array<{
          __typename?: "CallGuideItemChild";
          id: any;
          description: string;
          position: number;
        }>;
      }>;
      assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
      interviewStage?: {
        __typename?: "InterviewStage";
        id: any;
        title: string;
      } | null;
    };
  } | null;
};

export type UpdateCallGuideAssignedUsersMutationVariables = Exact<{
  id: Scalars["ID"];
  assignedUserIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type UpdateCallGuideAssignedUsersMutation = {
  __typename?: "Mutation";
  updateCallGuideAssignedUsers?: {
    __typename?: "UpdateCallGuideAssignedUsers";
    callGuide: {
      __typename?: "CallGuide";
      canEdit: boolean;
      id: any;
      name?: string | null;
      isTemplate: boolean;
      visibility: CallGuideVisibility;
      createdAt: any;
      updatedAt: any;
      greenhouseId?: number | null;
      leverFeedbackTemplateId?: string | null;
      ashbyFeedbackFormId?: string | null;
      smartrecruitersCriteriaId?: string | null;
      atsId?: string | null;
      overallScoringEnabled: boolean;
      position?: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      } | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      cues: Array<{
        __typename?: "CallGuideCue";
        id: any;
        cue: string;
        description: string;
        isHtmlType: boolean;
        position: number;
        scoringEnabled: boolean;
        type: GuideItemType;
        competencyId?: any | null;
        childItems: Array<{
          __typename?: "CallGuideItemChild";
          id: any;
          description: string;
          position: number;
        }>;
      }>;
      assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
      interviewStage?: {
        __typename?: "InterviewStage";
        id: any;
        title: string;
      } | null;
    };
  } | null;
};

export type UpdateCallGuideCueMutationVariables = Exact<{
  id: Scalars["ID"];
  cue?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  index?: InputMaybe<Scalars["Int"]>;
}>;

export type UpdateCallGuideCueMutation = {
  __typename?: "Mutation";
  updateCallGuideCue?: {
    __typename?: "UpdateCallGuideCue";
    callGuideCue: {
      __typename?: "CallGuideCue";
      id: any;
      cue: string;
      description: string;
      isHtmlType: boolean;
      position: number;
      scoringEnabled: boolean;
      type: GuideItemType;
      competencyId?: any | null;
      childItems: Array<{
        __typename?: "CallGuideItemChild";
        id: any;
        description: string;
        position: number;
      }>;
    };
  } | null;
};

export type UpdateCallGuideMutationVariables = Exact<{
  id: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
  interviewStageId?: InputMaybe<Scalars["ID"]>;
  assignedUserIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  visibility?: InputMaybe<Scalars["String"]>;
  isTemplate?: InputMaybe<Scalars["Boolean"]>;
  overallScoringEnabled?: InputMaybe<Scalars["Boolean"]>;
  guideItems?: InputMaybe<Array<CallGuideItemInput> | CallGuideItemInput>;
}>;

export type UpdateCallGuideMutation = {
  __typename?: "Mutation";
  updateCallGuide?: {
    __typename?: "UpdateCallGuide";
    callGuide: {
      __typename?: "CallGuide";
      canEdit: boolean;
      id: any;
      name?: string | null;
      isTemplate: boolean;
      visibility: CallGuideVisibility;
      createdAt: any;
      updatedAt: any;
      greenhouseId?: number | null;
      leverFeedbackTemplateId?: string | null;
      ashbyFeedbackFormId?: string | null;
      smartrecruitersCriteriaId?: string | null;
      atsId?: string | null;
      overallScoringEnabled: boolean;
      position?: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      } | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      cues: Array<{
        __typename?: "CallGuideCue";
        id: any;
        cue: string;
        description: string;
        isHtmlType: boolean;
        position: number;
        scoringEnabled: boolean;
        type: GuideItemType;
        competencyId?: any | null;
        childItems: Array<{
          __typename?: "CallGuideItemChild";
          id: any;
          description: string;
          position: number;
        }>;
      }>;
      assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
      interviewStage?: {
        __typename?: "InterviewStage";
        id: any;
        title: string;
      } | null;
    };
  } | null;
};

export type UpdateCallGuidesMutationVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
  assignedUserIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type UpdateCallGuidesMutation = {
  __typename?: "Mutation";
  updateCallGuides?: {
    __typename?: "UpdateCallGuides";
    callGuides?: Array<{
      __typename?: "CallGuide";
      canEdit: boolean;
      id: any;
      name?: string | null;
      isTemplate: boolean;
      visibility: CallGuideVisibility;
      createdAt: any;
      updatedAt: any;
      greenhouseId?: number | null;
      leverFeedbackTemplateId?: string | null;
      ashbyFeedbackFormId?: string | null;
      smartrecruitersCriteriaId?: string | null;
      atsId?: string | null;
      overallScoringEnabled: boolean;
      position?: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      } | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      cues: Array<{
        __typename?: "CallGuideCue";
        id: any;
        cue: string;
        description: string;
        isHtmlType: boolean;
        position: number;
        scoringEnabled: boolean;
        type: GuideItemType;
        competencyId?: any | null;
        childItems: Array<{
          __typename?: "CallGuideItemChild";
          id: any;
          description: string;
          position: number;
        }>;
      }>;
      assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
      interviewStage?: {
        __typename?: "InterviewStage";
        id: any;
        title: string;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateOrganizationComplianceSettingsMutationVariables = Exact<{
  playCallDisclaimer: Scalars["Boolean"];
  allowCandidateOptOut: Scalars["Boolean"];
  allowInterviewerOptOut: Scalars["Boolean"];
  dataRedactionEnabled?: InputMaybe<Scalars["Boolean"]>;
  dataRedactionPredictionEnabled?: InputMaybe<Scalars["Boolean"]>;
  redactEeocFamilyEnabled?: InputMaybe<Scalars["Boolean"]>;
  redactEeocSexEnabled?: InputMaybe<Scalars["Boolean"]>;
  redactEeocRaceEnabled?: InputMaybe<Scalars["Boolean"]>;
  redactEeocHighRiskQuestionsEnabled?: InputMaybe<Scalars["Boolean"]>;
  redactCompensationEnabled?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateOrganizationComplianceSettingsMutation = {
  __typename?: "Mutation";
  updateOrganizationComplianceSettings?: {
    __typename?: "UpdateOrganizationComplianceSettings";
    organization: {
      __typename?: "Organization";
      id: any;
      dataRedactionEnabled: boolean;
      dataRedactionPredictionEnabled: boolean;
      redactEeocFamilyEnabled: boolean;
      redactEeocSexEnabled: boolean;
      redactEeocRaceEnabled: boolean;
      redactEeocHighRiskQuestionsEnabled: boolean;
      redactCompensationEnabled: boolean;
      playCallDisclaimer: boolean;
      allowCandidateOptOut: boolean;
      allowInterviewerOptOut: boolean;
    };
  } | null;
};

export type UpdateOrganizationDataRetentionMutationVariables = Exact<{
  enableRetentionSinceCandidateHired?: InputMaybe<Scalars["Boolean"]>;
  retentionDaysSinceCandidateHired?: InputMaybe<Scalars["Int"]>;
  enableRetentionSinceCandidateRejected?: InputMaybe<Scalars["Boolean"]>;
  retentionDaysSinceCandidateRejected?: InputMaybe<Scalars["Int"]>;
  enableRetentionSinceLastInterview: Scalars["Boolean"];
  retentionDaysSinceLastInterview: Scalars["Int"];
  enableRetentionSinceInterview: Scalars["Boolean"];
  retentionDaysSinceInterview: Scalars["Int"];
  enableRedactionForGreenhouse?: InputMaybe<Scalars["Boolean"]>;
  enableRedactionForLever?: InputMaybe<Scalars["Boolean"]>;
  enableRedactionForSmartrecruiters?: InputMaybe<Scalars["Boolean"]>;
  enableRedactionForAshby?: InputMaybe<Scalars["Boolean"]>;
  enableAutomaticRetentionDeletion?: InputMaybe<Scalars["Boolean"]>;
  retentionNotificationDays:
    | Array<InputMaybe<Scalars["String"]>>
    | InputMaybe<Scalars["String"]>;
  retentionEmailList:
    | Array<InputMaybe<Scalars["String"]>>
    | InputMaybe<Scalars["String"]>;
  retentionEmailSendTime: Scalars["String"];
  retentionEmailSendTimezone: Scalars["String"];
  preventRedactionForTraining: Scalars["Boolean"];
}>;

export type UpdateOrganizationDataRetentionMutation = {
  __typename?: "Mutation";
  updateOrganizationDataRetention?: {
    __typename?: "UpdateOrganizationDataRetention";
    organization: {
      __typename?: "Organization";
      id: any;
      ats?: Ats | null;
      enableRetentionSinceCandidateHired: boolean;
      retentionDaysSinceCandidateHired: number;
      enableRetentionSinceCandidateRejected: boolean;
      retentionDaysSinceCandidateRejected: number;
      enableRetentionSinceLastInterview: boolean;
      retentionDaysSinceLastInterview: number;
      enableRetentionSinceInterview: boolean;
      retentionDaysSinceInterview: number;
      enableRedactionForGreenhouse: boolean;
      enableRedactionForLever: boolean;
      enableRedactionForSmartrecruiters: boolean;
      enableRedactionForAshby: boolean;
      enableAutomaticRetentionDeletion: boolean;
      retentionNotificationDays?: Array<string | null> | null;
      retentionEmailSendTime: any;
      retentionEmailSendTimezone: string;
      preventRedactionForTraining: boolean;
      organizationRetentionUser: Array<{
        __typename?: "OrganizationRetentionUser";
        userId: any;
      }>;
    };
  } | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
  greenhouseScorecardAutoDraftRule?: InputMaybe<OrgScorecardAutoDraftRule>;
  greenhouseScorecardAutoDraftOnboardingRule?: InputMaybe<OrgScorecardAutoDraftOnboardingRule>;
}>;

export type UpdateOrganizationMutation = {
  __typename?: "Mutation";
  updateOrganization?: {
    __typename?: "UpdateOrganization";
    currentUser: {
      __typename?: "User";
      id: any;
      autofillGreenhouseScorecard: boolean;
    };
    organization: {
      __typename?: "Organization";
      id: any;
      greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
      greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
      allowsGreenhouseScorecardAutoDraft: boolean;
      requiresGreenhouseScorecardAutoDraft: boolean;
    };
  } | null;
};

export type UpdateOrganizationNameMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type UpdateOrganizationNameMutation = {
  __typename?: "Mutation";
  updateOrganizationName?: {
    __typename?: "UpdateOrganizationName";
    organization: { __typename?: "Organization"; id: any; name: string };
    currentUser: {
      __typename?: "User";
      id: any;
      onboardingSteps: Array<{
        __typename?: "UserOnboardingStep";
        step: OnboardingStep;
        status?: OnboardingStepStatus | null;
        rule: OnboardingStepRule;
      }>;
    };
  } | null;
};

export type CreateAndSharePlaylistExternallyMutationVariables = Exact<{
  clipIds: Array<Scalars["ID"]> | Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  shareMessage: Scalars["String"];
  shareToEmails: Array<Scalars["String"]> | Scalars["String"];
}>;

export type CreateAndSharePlaylistExternallyMutation = {
  __typename?: "Mutation";
  createAndSharePlaylistExternally?: {
    __typename?: "CreateAndSharePlaylistExternally";
    playlist: { __typename?: "Playlist"; id: any; title: string };
  } | null;
};

export type CreateAndSharePlaylistMutationVariables = Exact<{
  clipIds: Array<Scalars["ID"]> | Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  shareMessage: Scalars["String"];
  shareToUserIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type CreateAndSharePlaylistMutation = {
  __typename?: "Mutation";
  createAndSharePlaylist?: {
    __typename?: "CreateAndSharePlaylist";
    playlist: { __typename?: "Playlist"; id: any; title: string };
  } | null;
};

export type CreateExternalPlaylistShareMutationVariables = Exact<{
  playlistId: Scalars["ID"];
  shareMessage?: InputMaybe<Scalars["String"]>;
  shareToEmails: Array<Scalars["String"]> | Scalars["String"];
}>;

export type CreateExternalPlaylistShareMutation = {
  __typename?: "Mutation";
  sharePlaylistExternally?: {
    __typename?: "SharePlaylistExternally";
    playlist: {
      __typename?: "Playlist";
      id: any;
      externalShares: Array<{
        __typename?: "ExternalPlaylistShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        isExpired: boolean;
        daysRemaining: number;
        sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
      }>;
    };
  } | null;
};

export type CreatePlaylistMutationVariables = Exact<{
  clipIds: Array<Scalars["ID"]> | Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
}>;

export type CreatePlaylistMutation = {
  __typename?: "Mutation";
  createPlaylist?: {
    __typename?: "CreatePlaylist";
    playlist: { __typename?: "Playlist"; id: any };
  } | null;
};

export type CreatePlaylistShareMutationVariables = Exact<{
  playlistId: Scalars["ID"];
  shareMessage: Scalars["String"];
  shareToUserIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type CreatePlaylistShareMutation = {
  __typename?: "Mutation";
  sharePlaylist?: {
    __typename?: "SharePlaylist";
    playlist: {
      __typename?: "Playlist";
      id: any;
      shares: Array<{
        __typename?: "PlaylistShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        sharedTo: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type DeletePlaylistMutationVariables = Exact<{
  playlistId: Scalars["ID"];
}>;

export type DeletePlaylistMutation = {
  __typename?: "Mutation";
  deletePlaylist?: { __typename?: "DeletePlaylist"; playlistId: string } | null;
};

export type RemoveClipFromPlaylistMutationVariables = Exact<{
  playlistId: Scalars["ID"];
  clipId: Scalars["ID"];
}>;

export type RemoveClipFromPlaylistMutation = {
  __typename?: "Mutation";
  removeClipFromPlaylist?: {
    __typename?: "RemoveClipFromPlaylist";
    playlist: {
      __typename?: "Playlist";
      id: any;
      clips: Array<{
        __typename?: "Clip";
        id: any;
        callId: any;
        displayName: string;
        duration: number;
      }>;
    };
  } | null;
};

export type RemoveExternalPlaylistShareMutationVariables = Exact<{
  shareId: Scalars["ID"];
}>;

export type RemoveExternalPlaylistShareMutation = {
  __typename?: "Mutation";
  removeExternalPlaylistShare?: {
    __typename?: "RemoveExternalPlaylistShare";
    playlistShare: { __typename?: "ExternalPlaylistShare"; id: any };
  } | null;
};

export type RemovePlaylistShareMutationVariables = Exact<{
  shareId: Scalars["ID"];
}>;

export type RemovePlaylistShareMutation = {
  __typename?: "Mutation";
  removePlaylistShare?: {
    __typename?: "RemovePlaylistShare";
    playlistShare: { __typename?: "PlaylistShare"; id: any };
  } | null;
};

export type RenamePlaylistMutationVariables = Exact<{
  playlistId: Scalars["ID"];
  title: Scalars["String"];
}>;

export type RenamePlaylistMutation = {
  __typename?: "Mutation";
  renamePlaylist?: {
    __typename?: "RenamePlaylist";
    playlist: { __typename?: "Playlist"; id: any; title: string };
  } | null;
};

export type RenewExternalPlaylistShareMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RenewExternalPlaylistShareMutation = {
  __typename?: "Mutation";
  renewExternalPlaylistShare?: {
    __typename?: "RenewExternalPlaylistShare";
    externalPlaylistShare: {
      __typename?: "ExternalPlaylistShare";
      id: any;
      createdAt: any;
      updatedAt: any;
      isExpired: boolean;
      daysRemaining: number;
      sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
    };
  } | null;
};

export type ReorderPlaylistClipsMutationVariables = Exact<{
  playlistId: Scalars["ID"];
  clipIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type ReorderPlaylistClipsMutation = {
  __typename?: "Mutation";
  reorderPlaylistClips?: {
    __typename?: "ReorderPlaylistClips";
    playlist: {
      __typename?: "Playlist";
      id: any;
      clips: Array<{
        __typename?: "Clip";
        id: any;
        callId: any;
        displayName: string;
        duration: number;
      }>;
    };
  } | null;
};

export type MarkScorecardQuestionMutationVariables = Exact<{
  scorecardQuestionId: Scalars["ID"];
  markedTime: Scalars["Float"];
}>;

export type MarkScorecardQuestionMutation = {
  __typename?: "Mutation";
  markScorecardQuestion?: {
    __typename?: "MarkScorecardQuestion";
    scorecardItem: {
      __typename?: "ScorecardItem";
      id: string;
      marked?: boolean | null;
      markedTime?: number | null;
    };
  } | null;
};

export type UnmarkScorecardQuestionMutationVariables = Exact<{
  scorecardQuestionId: Scalars["ID"];
}>;

export type UnmarkScorecardQuestionMutation = {
  __typename?: "Mutation";
  unMarkScorecardQuestion?: {
    __typename?: "UnmarkScorecardQuestion";
    scorecardItem: {
      __typename?: "ScorecardItem";
      id: string;
      marked?: boolean | null;
      markedTime?: number | null;
    };
  } | null;
};

export type CreateSkillReportMutationVariables = Exact<{
  positionIds: Array<Scalars["ID"]> | Scalars["ID"];
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  skills: Array<SkillReportSkillInput> | SkillReportSkillInput;
  interviewStageFilters: Scalars["JSONString"];
  minimumInterviewCountFilter: Scalars["Int"];
  source: SkillReportSkillsSource;
  title: Scalars["String"];
}>;

export type CreateSkillReportMutation = {
  __typename?: "Mutation";
  createSkillReport?: {
    __typename?: "CreateSkillReport";
    skillReport: { __typename?: "SkillReport"; id: any };
  } | null;
};

export type DeleteSkillReportMutationVariables = Exact<{
  reportId: Scalars["ID"];
}>;

export type DeleteSkillReportMutation = {
  __typename?: "Mutation";
  deleteSkillReport?: {
    __typename?: "DeleteSkillReport";
    success: boolean;
  } | null;
};

export type DuplicateSkillReportMutationVariables = Exact<{
  reportId: Scalars["ID"];
}>;

export type DuplicateSkillReportMutation = {
  __typename?: "Mutation";
  duplicateSkillReport?: {
    __typename?: "DuplicateSkillReport";
    skillReport: { __typename?: "SkillReport"; id: any };
  } | null;
};

export type EditSkillReportMutationVariables = Exact<{
  reportId: Scalars["ID"];
  positionIds: Array<Scalars["ID"]> | Scalars["ID"];
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  skills: Array<SkillReportSkillInput> | SkillReportSkillInput;
  interviewStageFilters: Scalars["JSONString"];
  minimumInterviewCountFilter: Scalars["Int"];
  source: SkillReportSkillsSource;
  title: Scalars["String"];
}>;

export type EditSkillReportMutation = {
  __typename?: "Mutation";
  editSkillReport?: {
    __typename?: "EditSkillReport";
    skillReport: {
      __typename?: "SkillReport";
      id: any;
      title: string;
      source: SkillReportSkillsSource;
      creatorId: any;
      minimumInterviewCountFilter?: number | null;
      interviewStageFilters?: any | null;
      dateRangeStart: any;
      dateRangeEnd: any;
      skills: Array<{
        __typename?: "SkillReportSkill";
        id: any;
        name: string;
        description?: string | null;
      }>;
      positions: Array<{
        __typename?: "Position";
        id: any;
        title: string;
        createdAt: any;
        displayTitle: string;
        requisitionId?: string | null;
        atsStatus?: string | null;
        greenhouseId?: number | null;
        leverPostingId?: string | null;
        smartrecruitersId?: string | null;
        mergeId?: string | null;
        hasJobPostingDescription: boolean;
        client: { __typename?: "Client"; id: any; name: string };
      }>;
      results: Array<{
        __typename?: "SkillReportResultItem";
        candidateId: any;
        candidateName: string;
        numCalls: number;
        coverage?: any | null;
      }>;
    };
  } | null;
};

export type CreateOrUpdateTaskDefinitionMutationVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]>;
  type: TaskDefinitionType;
  configurationJson: Scalars["JSONString"];
}>;

export type CreateOrUpdateTaskDefinitionMutation = {
  __typename?: "Mutation";
  createOrUpdateTaskDefinition?: {
    __typename?: "CreateOrUpdateTaskDefinition";
    taskDefinition: {
      __typename?: "TaskDefinition";
      id: any;
      type: TaskDefinitionType;
      configuration: any;
    };
  } | null;
};

export type PreviewTaskRunMutationVariables = Exact<{
  id: Scalars["ID"];
  taskInputJson: Scalars["JSONString"];
}>;

export type PreviewTaskRunMutation = {
  __typename?: "Mutation";
  previewTaskRun?: {
    __typename?: "PreviewTaskRun";
    taskRun: {
      __typename?: "TaskRun";
      id: any;
      output: any;
      status?: TaskRunStatus | null;
      statusMessage?: string | null;
    };
  } | null;
};

export type CreateCustomTopicMutationVariables = Exact<{
  name: Scalars["String"];
  keywords: Array<Scalars["String"]> | Scalars["String"];
  matchRule: CustomTopicMatchRule;
}>;

export type CreateCustomTopicMutation = {
  __typename?: "Mutation";
  createCustomTopic?: {
    __typename?: "CreateCustomTopic";
    topic: { __typename?: "AnalyticsCustomTopic"; id: any };
  } | null;
};

export type DeleteCustomTopicMutationVariables = Exact<{
  topicId: Scalars["ID"];
}>;

export type DeleteCustomTopicMutation = {
  __typename?: "Mutation";
  deleteCustomTopic?: {
    __typename?: "DeleteCustomTopic";
    success: boolean;
  } | null;
};

export type EditCustomTopicMutationVariables = Exact<{
  topicId: Scalars["ID"];
  name: Scalars["String"];
  keywords: Array<Scalars["String"]> | Scalars["String"];
  matchRule: CustomTopicMatchRule;
}>;

export type EditCustomTopicMutation = {
  __typename?: "Mutation";
  editCustomTopic?: {
    __typename?: "EditCustomTopic";
    topic: {
      __typename?: "AnalyticsCustomTopic";
      id: any;
      name: string;
      keywords: any;
      matchRule: CustomTopicMatchRule;
    };
  } | null;
};

export type AddClipsToTrainingProgramMutationVariables = Exact<{
  trainingProgramId: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
  callId: Scalars["ID"];
  callTimestamp?: InputMaybe<Scalars["Int"]>;
  clipIds: Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>;
}>;

export type AddClipsToTrainingProgramMutation = {
  __typename?: "Mutation";
  addClipsToTrainingProgram?: {
    __typename?: "AddClipsToTrainingProgram";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type AddTrainingProgramQuestionMutationVariables = Exact<{
  trainingProgramId: Scalars["ID"];
  question: Scalars["String"];
  order: Scalars["Int"];
}>;

export type AddTrainingProgramQuestionMutation = {
  __typename?: "Mutation";
  addTrainingProgramQuestion?: {
    __typename?: "AddTrainingProgramQuestion";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type AddTrainingProgramTraineesMutationVariables = Exact<{
  trainingProgramId: Scalars["ID"];
  userIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type AddTrainingProgramTraineesMutation = {
  __typename?: "Mutation";
  addTrainingProgramTrainees?: {
    __typename?: "AddTrainingProgramTrainees";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type AnswerTrainingProgramQuestionsMutationVariables = Exact<{
  answers: Array<TrainingProgramAnswerInput> | TrainingProgramAnswerInput;
}>;

export type AnswerTrainingProgramQuestionsMutation = {
  __typename?: "Mutation";
  answerTrainingProgramQuestions?: {
    __typename?: "AnswerTrainingProgramQuestions";
    success: boolean;
  } | null;
};

export type CreateTrainingProgramItemMutationVariables = Exact<{
  trainingProgramId: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
  callId: Scalars["ID"];
  callTimestamp?: InputMaybe<Scalars["Int"]>;
  clipId?: InputMaybe<Scalars["ID"]>;
}>;

export type CreateTrainingProgramItemMutation = {
  __typename?: "Mutation";
  createTrainingProgramItem?: {
    __typename?: "CreateTrainingProgramItem";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type CreateTrainingProgramMutationVariables = Exact<{
  name: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
}>;

export type CreateTrainingProgramMutation = {
  __typename?: "Mutation";
  createTrainingProgram?: {
    __typename?: "CreateTrainingProgram";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type DeleteAllTrainingProgramTraineesMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteAllTrainingProgramTraineesMutation = {
  __typename?: "Mutation";
  deleteAllTrainingProgramTrainees?: {
    __typename?: "DeleteAllTrainingProgramTrainees";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type DeleteTrainingProgramItemMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteTrainingProgramItemMutation = {
  __typename?: "Mutation";
  deleteTrainingProgramItem?: {
    __typename?: "DeleteTrainingProgramItem";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type DeleteTrainingProgramMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteTrainingProgramMutation = {
  __typename?: "Mutation";
  deleteTrainingProgram?: {
    __typename?: "DeleteTrainingProgram";
    trainingProgramId: string;
  } | null;
};

export type DeleteTrainingProgramQuestionMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteTrainingProgramQuestionMutation = {
  __typename?: "Mutation";
  deleteTrainingProgramQuestion?: {
    __typename?: "DeleteTrainingProgramQuestion";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type DescribeTrainingProgramItemMutationVariables = Exact<{
  id: Scalars["ID"];
  description: Scalars["String"];
}>;

export type DescribeTrainingProgramItemMutation = {
  __typename?: "Mutation";
  describeTrainingProgramItem?: {
    __typename?: "DescribeTrainingProgramItem";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type EnableTrainingProgramAssessmentMutationVariables = Exact<{
  id: Scalars["ID"];
  enabled: Scalars["Boolean"];
}>;

export type EnableTrainingProgramAssessmentMutation = {
  __typename?: "Mutation";
  enableTrainingProgramAssessment?: {
    __typename?: "EnableTrainingProgramAssessment";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      assessmentEnabled: boolean;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        createdAt: any;
        updatedAt: any;
        order: number;
        question: string;
      }>;
    };
  } | null;
};

export type LaunchTrainingProgramMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LaunchTrainingProgramMutation = {
  __typename?: "Mutation";
  launchTrainingProgram?: {
    __typename?: "LaunchTrainingProgram";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type NameTrainingProgramMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
}>;

export type NameTrainingProgramMutation = {
  __typename?: "Mutation";
  nameTrainingProgram?: {
    __typename?: "NameTrainingProgram";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type ReorderTrainingProgramItemsMutationVariables = Exact<{
  trainingProgramId: Scalars["ID"];
  trainingProgramItemIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type ReorderTrainingProgramItemsMutation = {
  __typename?: "Mutation";
  reorderTrainingProgramItems?: {
    __typename?: "ReorderTrainingProgramItems";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type ReorderTrainingProgramQuestionsMutationVariables = Exact<{
  trainingProgramId: Scalars["ID"];
  trainingProgramQuestionIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type ReorderTrainingProgramQuestionsMutation = {
  __typename?: "Mutation";
  reorderTrainingProgramQuestions?: {
    __typename?: "ReorderTrainingProgramQuestions";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type ResetTrainingProgramQuestionsMutationVariables = Exact<{
  trainingProgramId: Scalars["ID"];
}>;

export type ResetTrainingProgramQuestionsMutation = {
  __typename?: "Mutation";
  resetTrainingProgramQuestions?: {
    __typename?: "ResetTrainingProgramQuestions";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type UpdateTrainingProgramQuestionMutationVariables = Exact<{
  id: Scalars["ID"];
  question: Scalars["String"];
}>;

export type UpdateTrainingProgramQuestionMutation = {
  __typename?: "Mutation";
  updateTrainingProgramQuestion?: {
    __typename?: "UpdateTrainingProgramQuestion";
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      launchedAt?: any | null;
      assessmentEnabled: boolean;
      totalDuration?: number | null;
      trainingProgramQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
        createdAt: any;
        updatedAt: any;
      }>;
      trainingProgramDeletedQuestions: Array<{
        __typename?: "TrainingProgramQuestion";
        id: any;
        order: number;
        question: string;
      }>;
      owner: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
      trainingProgramItems: Array<{
        __typename?: "TrainingProgramItem";
        id: string;
        description?: string | null;
        name?: string | null;
        order?: number | null;
        isClip: boolean;
        url: string;
        positionDisplayTitle?: string | null;
        createdAt: any;
        contentCreatedAt: any;
        contentRedactedAt?: any | null;
        hasRecordings: boolean;
        audioOnly: boolean;
        duration: number;
        thumbnailImageUrl?: string | null;
        viewed?: boolean | null;
        assessmentCompleted?: boolean | null;
        assessmentOutdated?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
      }>;
      trainingProgramTrainees: Array<{
        __typename?: "TrainingProgramTrainee";
        id: any;
        createdAt: any;
        user: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type ActivityFeedQueryVariables = Exact<{
  pagination?: InputMaybe<CursorPaginationDetails>;
  types?: InputMaybe<Array<ActivityType> | ActivityType>;
}>;

export type ActivityFeedQuery = {
  __typename?: "Query";
  activityFeed: {
    __typename?: "PaginatedActivityFeed";
    pageInfo?: {
      __typename?: "CursorPaginationInfo";
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
      nextPageStartId?: string | null;
      nextPageStartTimestamp?: any | null;
    } | null;
    results?: Array<{
      __typename?: "ActivityFeed";
      id: any;
      ownerId: any;
      createdAt: any;
      activity: {
        __typename?: "Activity";
        action: ActivityType;
        createdAt: any;
        subject?: { __typename?: "User"; id: any; fullName: string } | null;
        externalSubject?: {
          __typename?: "ExternalUser";
          id: any;
          email: string;
        } | null;
        call?: { __typename?: "Call"; id: any; name?: string | null } | null;
        clip?: {
          __typename?: "Clip";
          id: any;
          userId: any;
          callId: any;
          name?: string | null;
          displayName: string;
        } | null;
        callNote?: {
          __typename?: "CallNote";
          id: any;
          highlightStartTime?: number | null;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
          } | null;
        } | null;
        callShare?: {
          __typename?: "CallShare";
          id: any;
          sharedBy: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
          };
        } | null;
        clipShare?: {
          __typename?: "ClipShare";
          id: any;
          sharedBy: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
          };
        } | null;
        playlistShare?: {
          __typename?: "PlaylistShare";
          id: any;
          absoluteUrl: string;
          sharedByUser: { __typename?: "User"; id: any; fullName: string };
        } | null;
      };
    }> | null;
  };
};

export type AllUsersWithAssignedCallGuidesQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
}>;

export type AllUsersWithAssignedCallGuidesQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      usersWithGuideAssignments: Array<{
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
        callGuideAssignments: Array<{
          __typename?: "CallGuideUser";
          id: any;
          dateAssigned: any;
          callGuide: {
            __typename?: "CallGuide";
            id: any;
            name?: string | null;
          };
        }>;
      }>;
    };
  } | null;
};

export type AuditLogItemExistsQueryVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
  property: Scalars["String"];
}>;

export type AuditLogItemExistsQuery = {
  __typename?: "Query";
  auditLogItemExists?: boolean | null;
};

export type AuditLogQueryVariables = Exact<{ [key: string]: never }>;

export type AuditLogQuery = {
  __typename?: "Query";
  auditLog: Array<{
    __typename?: "AuditLog";
    name: string;
    details: any;
    createdAt: any;
    user: { __typename?: "User"; id: any; email: string };
  }>;
};

export type BrowserExtensionCallsQueryVariables = Exact<{
  tabUrl: Scalars["String"];
}>;

export type BrowserExtensionCallsQuery = {
  __typename?: "Query";
  browserExtensionCalls: Array<{
    __typename?: "Call";
    id: any;
    name?: string | null;
    duration?: number | null;
    status: CallStatus;
    startTime?: any | null;
    createdAt: any;
  }>;
};

export type BrowserExtensionDataQueryVariables = Exact<{
  tabUrl: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  intent?: InputMaybe<BrowserExtensionIntent>;
}>;

export type BrowserExtensionDataQuery = {
  __typename?: "Query";
  browserExtensionData?: {
    __typename?: "BrowserExtensionData";
    intent?: BrowserExtensionIntent | null;
    ats?: Ats | null;
    calls: Array<{ __typename?: "Call"; id: any; iaVersion: number }>;
    candidate?: { __typename?: "Candidate"; id: any; fullName: string } | null;
    position?: { __typename?: "Position"; id: any } | null;
  } | null;
};

export type BrowserExtensionDataFragment = {
  __typename?: "BrowserExtensionData";
  intent?: BrowserExtensionIntent | null;
  ats?: Ats | null;
  calls: Array<{ __typename?: "Call"; id: any; iaVersion: number }>;
  candidate?: { __typename?: "Candidate"; id: any; fullName: string } | null;
  position?: { __typename?: "Position"; id: any } | null;
};

export type BullhornAuthQueryVariables = Exact<{ [key: string]: never }>;

export type BullhornAuthQuery = {
  __typename?: "Query";
  bullhornAuth?: {
    __typename?: "BullhornAuth";
    isAuthorized: boolean;
    authUrl?: string | null;
  } | null;
};

export type BullhornCommentActionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BullhornCommentActionsQuery = {
  __typename?: "Query";
  bullhornCommentActions: Array<string>;
};

export type CallGuideQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CallGuideQuery = {
  __typename?: "Query";
  callGuide?: {
    __typename?: "CallGuide";
    id: any;
    name?: string | null;
    visibility: CallGuideVisibility;
    isTemplate: boolean;
    canEdit: boolean;
    canManageCallGuideAssignments: boolean;
    createdAt: any;
    updatedAt: any;
    greenhouseId?: number | null;
    leverFeedbackTemplateId?: string | null;
    customAtsId?: string | null;
    ashbyFeedbackFormId?: string | null;
    smartrecruitersCriteriaId?: string | null;
    atsId?: string | null;
    overallScoringEnabled: boolean;
    position?: {
      __typename?: "Position";
      id: any;
      title: string;
      displayTitle: string;
      client: { __typename?: "Client"; id: any; name: string };
    } | null;
    creator?: { __typename?: "User"; id: any; fullName: string } | null;
    assignedUsers: Array<{
      __typename?: "CallGuideUser";
      createdAt: any;
      userId: any;
      user: {
        __typename?: "User";
        id: any;
        createdAt: any;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
        email: string;
      };
    }>;
    cues: Array<{
      __typename?: "CallGuideCue";
      id: any;
      cue: string;
      description: string;
      isHtmlType: boolean;
      position: number;
      scoringEnabled: boolean;
      type: GuideItemType;
      competencyId?: any | null;
      childItems: Array<{
        __typename?: "CallGuideItemChild";
        id: any;
        description: string;
        position: number;
      }>;
    }>;
    organization: {
      __typename?: "Organization";
      id: any;
      scoringEnabled: boolean;
    };
    interviewStage?: {
      __typename?: "InterviewStage";
      id: any;
      title: string;
    } | null;
  } | null;
};

export type CallInProgressQueryVariables = Exact<{ [key: string]: never }>;

export type CallInProgressQuery = {
  __typename?: "Query";
  callInProgress?: {
    __typename?: "Call";
    id: any;
    type: CallType;
    phoneNumber?: string | null;
    candidate?: { __typename?: "Candidate"; fullName: string } | null;
  } | null;
};

export type CallQueryVariables = Exact<{
  id: Scalars["ID"];
  twilioCallSid?: InputMaybe<Scalars["String"]>;
  notesForCurrentUserOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CallQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    autoSummary?: string | null;
    iaVersion: number;
    canEdit: boolean;
    canDelete: boolean;
    canEditNotes: boolean;
    canShare: boolean;
    canShareExternal: boolean;
    visibleVisibilityLevels: Array<CallVisibility>;
    coverImageUrl?: string | null;
    clipsCount: number;
    greenhouseScorecardLinkWithId?: string | null;
    leverInterviewLinkWithId?: string | null;
    id: any;
    name?: string | null;
    type: CallType;
    status: CallStatus;
    visibility: CallVisibility;
    startTime?: any | null;
    endTime?: any | null;
    createdAt: any;
    duration?: number | null;
    rating: number;
    phoneNumber?: string | null;
    useSafeNumber: boolean;
    isProcessing: boolean;
    transcriptionStatus?: TranscriptionStatus | null;
    recordingDisabled: boolean;
    greenhouseScorecardLink?: string | null;
    leverInterviewLink?: string | null;
    smartrecruitersInterviewLink?: string | null;
    customAtsUrl?: string | null;
    interviewerIds: Array<string>;
    skipRedaction: boolean;
    callClips: Array<{
      __typename?: "Clip";
      id: any;
      callId: any;
      userId: any;
      startTime: number;
      endTime: number;
      recordingStatus: ClipRecordingStatus;
      failureReason?: string | null;
      createdAt: any;
      updatedAt: any;
      visibility: CallVisibility;
      visibleVisibilityLevels: Array<CallVisibility>;
      thumbnailImageUrl?: string | null;
      canDelete: boolean;
      canEdit: boolean;
      name?: string | null;
      displayName: string;
      iaVersion: number;
      interviewerIds: Array<string>;
      callStartTime?: any | null;
      callEndTime?: any | null;
      callName?: string | null;
      callRecordingDisabled: boolean;
      callPhoneNumber?: string | null;
      callType: CallType;
      streamableAudio?: {
        __typename?: "CallMedia";
        url: string;
        expiration: any;
      } | null;
      streamableVideo?: {
        __typename?: "CallMedia";
        url: string;
        expiration: any;
      } | null;
      user: { __typename?: "User"; id: any; fullName: string };
      viewers: Array<{
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
        phoneNumber?: string | null;
        phoneNumberExtension?: string | null;
        phoneNumberVerified: boolean;
        isCalendarAuthorized: boolean;
        createdAt: any;
        deletedAt?: any | null;
        signUpMethod: UserSignUpMethod;
        signUpMethodLabel: string;
        signUpCompletedAt?: any | null;
        voipPhoneNumber?: string | null;
        invitedBy?: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
        } | null;
      }>;
      callSpeakers: Array<{
        __typename?: "CallSpeaker";
        id: any;
        speakerTag: number;
        label: string;
        isCandidate: boolean;
        isInterviewer?: boolean | null;
        identified: boolean;
        talkTimePercentage: number;
        longestMonologue?: number | null;
      }>;
      candidate?: {
        __typename?: "Candidate";
        id: any;
        firstName: string;
        lastName: string;
        fullName: string;
        greenhouseId?: number | null;
        greenhouseLink?: string | null;
        lastLeverOpportunityId?: string | null;
      } | null;
      position?: {
        __typename?: "Position";
        id: any;
        displayTitle: string;
      } | null;
    }>;
    questions: Array<{
      __typename?: "CallNote";
      id: any;
      description?: string | null;
      time: number;
      questionNotes: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        time: number;
        highlightStartTime?: number | null;
        highlightEndTime?: number | null;
        speakerTag?: number | null;
        text: string;
        type: CallNoteType;
        description?: string | null;
        isEdited: boolean;
        visibility: CallNoteVisibility;
        guideItemId?: any | null;
        parentGuideItemId?: any | null;
        scorecardQuestionId?: any | null;
        questionId?: any | null;
        createdAt: any;
        updatedAt: any;
        score?: number | null;
        user?: {
          __typename?: "User";
          id: any;
          profilePicUrl?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
        } | null;
        replies: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          clipId?: any | null;
          text: string;
          isEdited: boolean;
          createdAt: any;
          visibility: CallNoteVisibility;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
          } | null;
        }>;
      }>;
    }>;
    generalNotes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
    externalViewers: Array<{
      __typename?: "ExternalUser";
      id: any;
      email: string;
    }>;
    interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
    candidate?: {
      __typename?: "Candidate";
      id: any;
      firstName: string;
      lastName: string;
      fullName: string;
      greenhouseId?: number | null;
      greenhouseLink?: string | null;
      lastLeverOpportunityId?: string | null;
    } | null;
    position?: {
      __typename?: "Position";
      id: any;
      displayTitle: string;
    } | null;
    streamableAudio?: {
      __typename?: "CallMedia";
      url: string;
      expiration: any;
    } | null;
    streamableVideo?: {
      __typename?: "CallMedia";
      url: string;
      expiration: any;
    } | null;
    notes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
    speakers: Array<{
      __typename?: "CallSpeaker";
      id: any;
      speakerTag: number;
      label: string;
      isCandidate: boolean;
      isInterviewer?: boolean | null;
      identified: boolean;
      talkTimePercentage: number;
      longestMonologue?: number | null;
    }>;
    viewers: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      phoneNumberVerified: boolean;
      isCalendarAuthorized: boolean;
      createdAt: any;
      deletedAt?: any | null;
      signUpMethod: UserSignUpMethod;
      signUpMethodLabel: string;
      signUpCompletedAt?: any | null;
      voipPhoneNumber?: string | null;
      invitedBy?: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
      } | null;
    }>;
    scheduledInterview?: {
      __typename?: "ScheduledInterview";
      greenhouseInterviewId?: number | null;
      leverInterviewId?: string | null;
      customAtsUrl?: string | null;
    } | null;
  } | null;
};

export type CallShareQueryVariables = Exact<{
  id: Scalars["ID"];
  forPlaylist?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CallShareQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    name?: string | null;
    canShare: boolean;
    canShareExternal: boolean;
    trainingProgramCount?: number;
    candidate?: { __typename?: "Candidate"; id: any; fullName: string } | null;
    shareableUsers: Array<{
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      profilePicUrl?: string | null;
    }>;
    callShares?: Array<{
      __typename?: "CallShare";
      id: any;
      createdAt: any;
      updatedAt: any;
      sharedTo: {
        __typename?: "User";
        id: any;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      };
    }>;
    externalCallShares?: Array<{
      __typename?: "ExternalCallShare";
      id: any;
      createdAt: any;
      updatedAt: any;
      isExpired: boolean;
      shareDaysRemaining: number;
      daysRemaining: number;
      sharedToExternalUser: {
        __typename?: "ExternalUser";
        id: any;
        email: string;
      };
      sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
    }>;
    visibleTo?: {
      __typename?: "PaginatedRecordingVisibleTo";
      resultCount: number;
      results: Array<{
        __typename?: "RecordingVisibleTo";
        id: string;
        role: string;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type ShareUserFragment = {
  __typename?: "User";
  id: any;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  fullName: string;
  profilePicUrl?: string | null;
};

export type RecordingVisibleToFragment = {
  __typename?: "PaginatedRecordingVisibleTo";
  resultCount: number;
  results: Array<{
    __typename?: "RecordingVisibleTo";
    id: string;
    role: string;
    user: {
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      profilePicUrl?: string | null;
    };
  }>;
};

export type CallSpeakerOptionsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CallSpeakerOptionsQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    speakerOptions: Array<{
      __typename?: "CallSpeakerOption";
      id: any;
      label: string;
      profilePicUrl?: string | null;
    }>;
  } | null;
};

export type CallTokenQueryVariables = Exact<{ [key: string]: never }>;

export type CallTokenQuery = { __typename?: "Query"; callToken: string };

export type CallTrackerSegmentQueryVariables = Exact<{
  id: Scalars["ID"];
  trackerKeywordId?: InputMaybe<Scalars["String"]>;
  trackerGroupId?: InputMaybe<Scalars["String"]>;
}>;

export type CallTrackerSegmentQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    canEdit: boolean;
    type: CallType;
    speakers: Array<{
      __typename?: "CallSpeaker";
      id: any;
      speakerTag: number;
      label: string;
      isCandidate: boolean;
      isInterviewer?: boolean | null;
      identified: boolean;
      talkTimePercentage: number;
      longestMonologue?: number | null;
    }>;
    trackerSegments: Array<{
      __typename?: "TrackerSegment";
      keyword?: string | null;
      segments: Array<{
        __typename?: "TranscriptSegment";
        id: any;
        speakerTag: number;
        startTime: number;
        endTime: number;
        languageCode?: string | null;
        words: Array<{
          __typename?: "TranscriptWord";
          word: string;
          startTime: number;
          endTime: number;
          confidence?: number | null;
          isRedacted?: boolean | null;
          rawWord?: string | null;
        }>;
      }>;
    }>;
  } | null;
};

export type CallTrackersQueryVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type CallTrackersQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    speakers: Array<{
      __typename?: "CallSpeaker";
      id: any;
      speakerTag: number;
      label: string;
      isCandidate: boolean;
      isInterviewer?: boolean | null;
      identified: boolean;
      talkTimePercentage: number;
      longestMonologue?: number | null;
    }>;
    trackers?: Array<{
      __typename?: "TrackerGroup";
      id: any;
      name: string;
      count?: number | null;
      trackerKeywords: Array<{
        __typename?: "TrackerKeyword";
        id: any;
        name: string;
        count?: number | null;
        speakerCounts: Array<{
          __typename?: "TrackerSpeakerCount";
          speakerTag: number;
          count: number;
        }>;
      }>;
    }> | null;
  } | null;
};

export type CallTranscriptQueryVariables = Exact<{
  id: Scalars["ID"];
  notesForCurrentUserOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CallTranscriptQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    organizationId: any;
    canEdit: boolean;
    id: any;
    name?: string | null;
    type: CallType;
    status: CallStatus;
    visibility: CallVisibility;
    canDelete: boolean;
    startTime?: any | null;
    endTime?: any | null;
    createdAt: any;
    duration?: number | null;
    rating: number;
    phoneNumber?: string | null;
    useSafeNumber: boolean;
    isProcessing: boolean;
    transcriptionStatus?: TranscriptionStatus | null;
    recordingDisabled: boolean;
    greenhouseScorecardLink?: string | null;
    leverInterviewLink?: string | null;
    smartrecruitersInterviewLink?: string | null;
    customAtsUrl?: string | null;
    interviewerIds: Array<string>;
    skipRedaction: boolean;
    transcript: Array<{
      __typename?: "TranscriptSegment";
      id: any;
      speakerTag: number;
      startTime: number;
      endTime: number;
      languageCode?: string | null;
      words: Array<{
        __typename?: "TranscriptWord";
        word: string;
        startTime: number;
        endTime: number;
        confidence?: number | null;
        isRedacted?: boolean | null;
        rawWord?: string | null;
      }>;
    }>;
    interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
    candidate?: {
      __typename?: "Candidate";
      id: any;
      firstName: string;
      lastName: string;
      fullName: string;
      greenhouseId?: number | null;
      greenhouseLink?: string | null;
      lastLeverOpportunityId?: string | null;
    } | null;
    position?: {
      __typename?: "Position";
      id: any;
      displayTitle: string;
    } | null;
    streamableAudio?: {
      __typename?: "CallMedia";
      url: string;
      expiration: any;
    } | null;
    streamableVideo?: {
      __typename?: "CallMedia";
      url: string;
      expiration: any;
    } | null;
    notes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
    speakers: Array<{
      __typename?: "CallSpeaker";
      id: any;
      speakerTag: number;
      label: string;
      isCandidate: boolean;
      isInterviewer?: boolean | null;
      identified: boolean;
      talkTimePercentage: number;
      longestMonologue?: number | null;
    }>;
    viewers: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      phoneNumberVerified: boolean;
      isCalendarAuthorized: boolean;
      createdAt: any;
      deletedAt?: any | null;
      signUpMethod: UserSignUpMethod;
      signUpMethodLabel: string;
      signUpCompletedAt?: any | null;
      voipPhoneNumber?: string | null;
      invitedBy?: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
      } | null;
    }>;
    scheduledInterview?: {
      __typename?: "ScheduledInterview";
      greenhouseInterviewId?: number | null;
      leverInterviewId?: string | null;
      customAtsUrl?: string | null;
    } | null;
  } | null;
};

export type CallsByIdQueryVariables = Exact<{
  callIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type CallsByIdQuery = {
  __typename?: "Query";
  callsById: Array<{
    __typename?: "Call";
    id: any;
    name?: string | null;
    duration?: number | null;
    status: CallStatus;
    startTime?: any | null;
    createdAt: any;
  }>;
};

export type CandidateAlertFeedQueryVariables = Exact<{
  pagination?: InputMaybe<CursorPaginationDetails>;
  alertFilters?: InputMaybe<
    Array<CandidateAlertFeedFilter> | CandidateAlertFeedFilter
  >;
}>;

export type CandidateAlertFeedQuery = {
  __typename?: "Query";
  candidateAlertFeed: {
    __typename?: "PaginatedCandidateAlertFeed";
    id: string;
    pageInfo: {
      __typename?: "CursorPaginationInfo";
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
      nextPageStartId?: string | null;
      nextPageStartTimestamp?: any | null;
    };
    results: Array<{
      __typename?: "CandidateAlertFeed";
      id: any;
      readAt?: any | null;
      accuracyFeedback?: CandidateAlertAccuracyFeedback | null;
      alert: {
        __typename?: "CandidateAlert";
        id: any;
        startTime: number;
        text: string;
        type: CandidateAlertType;
        category: CandidateAlertCategory;
        call: { __typename?: "Call"; id: any; startTime?: any | null };
        candidate: { __typename?: "Candidate"; id: any; fullName: string };
        position?: { __typename?: "Position"; id: any; title: string } | null;
      };
    }>;
  };
};

export type CandidateAlertQueryVariables = Exact<{
  candidateId: Scalars["ID"];
  pagination?: InputMaybe<CursorPaginationDetails>;
}>;

export type CandidateAlertQuery = {
  __typename?: "Query";
  candidateAlert: {
    __typename?: "PaginatedCandidateAlert";
    id: string;
    pageInfo: {
      __typename?: "CursorPaginationInfo";
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
      nextPageStartId?: string | null;
      nextPageStartTimestamp?: any | null;
    };
    results: Array<{
      __typename?: "CandidateAlert";
      id: any;
      startTime: number;
      text: string;
      type: CandidateAlertType;
      category: CandidateAlertCategory;
      call: { __typename?: "Call"; id: any; startTime?: any | null };
      candidate: { __typename?: "Candidate"; id: any; fullName: string };
      position?: { __typename?: "Position"; id: any; title: string } | null;
    }>;
  };
};

export type CandidateAlertUserSubscriptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CandidateAlertUserSubscriptionsQuery = {
  __typename?: "Query";
  candidateAlertUserSubscriptions: {
    __typename?: "CandidateAlertUserSubscriptions";
    id: string;
    candidateSubscriptions: Array<{
      __typename?: "CandidateAlertCandidateSubscription";
      id: any;
      candidateId: any;
      mutedAt?: any | null;
    }>;
    positionSubscriptions: Array<{
      __typename?: "CandidateAlertPositionSubscription";
      id: any;
      positionId: any;
      mutedAt?: any | null;
    }>;
  };
};

export type CandidateAskQueryVariables = Exact<{
  candidateId: Scalars["ID"];
  text: Scalars["String"];
}>;

export type CandidateAskQuery = {
  __typename?: "Query";
  candidateAsk: {
    __typename?: "CandidateAskResponse";
    id?: string | null;
    response?: string | null;
  };
};

export type CandidateByPhoneQueryVariables = Exact<{
  phoneNumber: Scalars["String"];
}>;

export type CandidateByPhoneQuery = {
  __typename?: "Query";
  candidateByPhone?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
  } | null;
};

export type CandidateCompareCandidatesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CandidateCompareCandidatesQuery = {
  __typename?: "Query";
  position?: {
    __typename?: "Position";
    id: any;
    candidates: Array<{
      __typename?: "Candidate";
      id: any;
      callCount?: number | null;
      fullName: string;
      validCallsCount?: number | null;
    }>;
  } | null;
};

export type CandidateEmailOptOutQueryVariables = Exact<{
  orgId: Scalars["ID"];
  verificationCode: Scalars["String"];
}>;

export type CandidateEmailOptOutQuery = {
  __typename?: "Query";
  candidateEmailOptOut?: {
    __typename?: "CandidateEmailOptOut";
    verificationCode: string;
    recordingDisabled: boolean;
    organizationName: string;
    videoUrls?: Array<string> | null;
  } | null;
};

export type CandidateLastCallByInterviewerPositionQueryVariables = Exact<{
  id: Scalars["ID"];
  positionId: Scalars["ID"];
}>;

export type CandidateLastCallByInterviewerPositionQuery = {
  __typename?: "Query";
  candidate?: {
    __typename?: "Candidate";
    id: any;
    lastCallByInterviewerPosition?: {
      __typename?: "Call";
      id: any;
      greenhouseScorecardLink?: string | null;
    } | null;
  } | null;
};

export type CandidateNamesQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type CandidateNamesQuery = {
  __typename?: "Query";
  candidates: {
    __typename?: "PaginatedCandidates";
    pageInfo?: {
      __typename?: "OffsetPaginationInfo";
      totalPages?: number | null;
      totalRows?: number | null;
    } | null;
    results?: Array<{
      __typename?: "Candidate";
      id: any;
      fullName: string;
    }> | null;
  };
};

export type CandidatePositionHighlightsQueryVariables = Exact<{
  id: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CandidatePositionHighlightsQuery = {
  __typename?: "Query";
  candidate?: {
    __typename?: "Candidate";
    id: any;
    callsByPosition: Array<{
      __typename?: "Call";
      id: any;
      highlights: Array<
        | {
            __typename?: "AiNoteHighlight";
            tags?: Array<string> | null;
            id: any;
            thumbnailImageUrl?: string | null;
            startTime: number;
            endTime?: number | null;
            text: string;
            description: string;
          }
        | {
            __typename?: "CallNoteHighlight";
            type: CallNoteType;
            id: any;
            thumbnailImageUrl?: string | null;
            startTime: number;
            endTime?: number | null;
            text: string;
            description: string;
          }
      >;
    }>;
  } | null;
};

export type CandidatePositionInterviewsQueryVariables = Exact<{
  id: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CandidatePositionInterviewsQuery = {
  __typename?: "Query";
  candidate?: {
    __typename?: "Candidate";
    id: any;
    callsByPosition: Array<{
      __typename?: "Call";
      id: any;
      name?: string | null;
      createdAt: any;
      startTime?: any | null;
      duration?: number | null;
      thumbnailImageUrl?: string | null;
      isInterviewer: boolean;
      greenhouseScorecardLink?: string | null;
      greenhouseScorecardLinkWithId?: string | null;
      greenhouseUserRating?: string | null;
      leverInterviewLink?: string | null;
      leverInterviewLinkWithId?: string | null;
      canDelete: boolean;
      positionId?: any | null;
      visibility: CallVisibility;
      visibleVisibilityLevels: Array<CallVisibility>;
      myScore?: number | null;
      averageScore?: number | null;
      needsCurrentUserScore?: boolean | null;
      hasPendingScores?: boolean | null;
      notes: Array<{ __typename?: "CallNote"; id: any; type: CallNoteType }>;
      interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
      streamableAudio?: { __typename?: "CallMedia"; url: string } | null;
      streamableVideo?: {
        __typename?: "CallMedia";
        url: string;
        height?: number | null;
        width?: number | null;
      } | null;
    }>;
    scheduledInterviewsByPosition: Array<{
      __typename?: "ScheduledInterview";
      id: string;
      name?: string | null;
      scheduledStart?: any | null;
      timezoneName?: string | null;
      scheduledEnd?: any | null;
      durationMinutes?: number | null;
      phoneNumber?: string | null;
      zoomId?: number | null;
      zoomJoinUrl?: string | null;
      zoomMeetingUrl?: string | null;
      zoomAppAutoOpenEnabled: boolean;
      googleMeetMeetingUrl?: string | null;
      teamsMeetingUrl?: string | null;
      isVideo: boolean;
      canAddBrighthire?: boolean | null;
      canAddBrighthireReason?: string | null;
      canLaunchMeeting?: boolean | null;
      canLaunchMeetingReason?: string | null;
      isExcluded: boolean;
      isImported: boolean;
      importDecision: boolean;
      importDecisionReason?: InterviewImportDecisionReason | null;
      greenhouseLink?: string | null;
      ghJobStageName?: string | null;
      optInUrl?: string | null;
      leverInterviewId?: string | null;
      smartrecruitersInterviewLink?: string | null;
      isInPersonInterview: boolean;
      interviewType?: InterviewType | null;
      isInterviewer: boolean;
      recordingDisabled: boolean;
      scheduledType: ScheduledInterviewType;
      leverStage?: {
        __typename?: "LeverStage";
        id: any;
        text?: string | null;
      } | null;
      scheduledInterviewers: Array<{
        __typename?: "ScheduledInterviewer";
        id: string;
        recordingDisabled: boolean;
        name?: string | null;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
          phoneNumber?: string | null;
        } | null;
      }>;
      callGuide?: { __typename?: "CallGuide"; id: any } | null;
      candidate?: {
        __typename?: "Candidate";
        id: any;
        firstName: string;
        lastName: string;
        fullName: string;
        callCount?: number | null;
        defaultPhoneNumber?: string | null;
        recordingDisabled: boolean;
      } | null;
      position?: {
        __typename?: "Position";
        id: any;
        displayTitle: string;
      } | null;
      googleMeetMeetings: Array<{
        __typename?: "GoogleMeetMeeting";
        id: string;
      }>;
      teamsNotetakerMeetings: Array<{
        __typename?: "TeamsNotetakerMeeting";
        id: string;
      }>;
    }>;
  } | null;
};

export type CandidateQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CandidateQuery = {
  __typename?: "Query";
  candidate?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
    fullName: string;
    skipRedaction: boolean;
    emails: Array<string>;
    defaultEmail?: string | null;
    rating?: number | null;
    canEdit: boolean;
    greenhouseId?: number | null;
    greenhouseLink?: string | null;
    smartrecruitersLink?: string | null;
    lastLeverOpportunityId?: string | null;
    customAtsUrl?: string | null;
    mergeRemoteId?: string | null;
    mergeType?: Ats | null;
    recordingDisabled: boolean;
    phoneNumbers: Array<{
      __typename?: "CandidatePhoneNumber";
      id: any;
      type: CandidatePhoneType;
      phoneNumber: string;
    }>;
    defaultPhoneNumberObj?: {
      __typename?: "CandidatePhoneNumber";
      id: any;
      type: CandidatePhoneType;
      phoneNumber: string;
    } | null;
    ratingBreakdown?: Array<{
      __typename?: "CandidateRatingBreakdown";
      rating: number;
      count: number;
    } | null> | null;
    calls: Array<{ __typename?: "Call"; id: any; positionId?: any | null }>;
    scheduledInterviews: Array<{
      __typename?: "ScheduledInterview";
      id: string;
      positionId?: any | null;
    }>;
    positions: Array<{
      __typename?: "Position";
      id: any;
      displayTitle: string;
    }>;
    candidateLinks: Array<{
      __typename?: "CandidateLink";
      id: any;
      source: string;
      link: string;
      type: CandidateLinkType;
    }>;
    lastCall?: { __typename?: "Call"; id: any; positionId?: any | null } | null;
    pastRecordingDisabledScheduledInterviews: Array<{
      __typename?: "ScheduledInterview";
      id: string;
      scheduledStart?: any | null;
      scheduledInterviewers: Array<{
        __typename?: "ScheduledInterviewer";
        id: string;
        user?: { __typename?: "User"; id: any; fullName: string } | null;
      }>;
    }>;
    applications: Array<{
      __typename?: "Application";
      id: any;
      candidateId: any;
      positionId: any;
      atsStatus?: string | null;
      smartrecruitersLink?: string | null;
    }>;
  } | null;
};

export type CandidateRediscoveryProspectsQueryVariables = Exact<{
  positionId: Scalars["ID"];
}>;

export type CandidateRediscoveryProspectsQuery = {
  __typename?: "Query";
  candidateRediscoveryProspects?: {
    __typename?: "CandidateRediscoveryProspects";
    prospects: Array<{
      __typename?: "CandidateRediscoveryProspect";
      candidateId: string;
      countSkillsMet: number;
      candidateName: string;
      linkedinUrl?: string | null;
      hasAtsData: boolean;
      lastAppliedPositionId?: string | null;
      lastAppliedPosition?: string | null;
      lastAppliedDate?: string | null;
      lastInterviewStage?: string | null;
      rejectionReason?: string | null;
      output: {
        __typename?: "ProspectOutput";
        tldr: Array<string | null>;
        categories: Array<{
          __typename?: "CategoryOutput";
          categoryName: string;
          tldr: string;
          indicesSkillsMet: Array<number | null>;
          bullets: Array<string | null>;
        } | null>;
      };
    }>;
  } | null;
};

export type CandidatesQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
  includeScores?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CandidatesQuery = {
  __typename?: "Query";
  candidates: {
    __typename?: "PaginatedCandidates";
    pageInfo?: {
      __typename?: "OffsetPaginationInfo";
      totalPages?: number | null;
      totalRows?: number | null;
    } | null;
    results?: Array<{
      __typename?: "Candidate";
      id: any;
      fullName: string;
      defaultPhoneNumber?: string | null;
      callCount?: number | null;
      lastPositionAverageScore?: number | null;
      lastCall?: {
        __typename?: "Call";
        id: any;
        name?: string | null;
        createdAt: any;
        position?: {
          __typename?: "Position";
          id: any;
          displayTitle: string;
        } | null;
      } | null;
    }> | null;
  };
};

export type CandidatePageListItemFragment = {
  __typename?: "Candidate";
  id: any;
  fullName: string;
  defaultPhoneNumber?: string | null;
  callCount?: number | null;
  lastPositionAverageScore?: number | null;
  lastCall?: {
    __typename?: "Call";
    id: any;
    name?: string | null;
    createdAt: any;
    position?: {
      __typename?: "Position";
      id: any;
      displayTitle: string;
    } | null;
  } | null;
};

export type ClipQueryVariables = Exact<{
  clipId: Scalars["ID"];
  notesForCurrentUserOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ClipQuery = {
  __typename?: "Query";
  clip?: {
    __typename?: "Clip";
    canEditNotes: boolean;
    canShare: boolean;
    canShareExternal: boolean;
    hasTraining: boolean;
    greenhouseScorecardLinkWithId?: string | null;
    leverInterviewLinkWithId?: string | null;
    skipRedaction: boolean;
    rating: number;
    id: any;
    callId: any;
    userId: any;
    startTime: number;
    endTime: number;
    recordingStatus: ClipRecordingStatus;
    failureReason?: string | null;
    createdAt: any;
    updatedAt: any;
    visibility: CallVisibility;
    visibleVisibilityLevels: Array<CallVisibility>;
    thumbnailImageUrl?: string | null;
    canDelete: boolean;
    canEdit: boolean;
    name?: string | null;
    displayName: string;
    iaVersion: number;
    interviewerIds: Array<string>;
    callStartTime?: any | null;
    callEndTime?: any | null;
    callName?: string | null;
    callRecordingDisabled: boolean;
    callPhoneNumber?: string | null;
    callType: CallType;
    notes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
    questions: Array<{
      __typename?: "CallNote";
      id: any;
      description?: string | null;
      time: number;
      questionNotes: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        time: number;
        highlightStartTime?: number | null;
        highlightEndTime?: number | null;
        speakerTag?: number | null;
        text: string;
        type: CallNoteType;
        description?: string | null;
        isEdited: boolean;
        visibility: CallNoteVisibility;
        guideItemId?: any | null;
        parentGuideItemId?: any | null;
        scorecardQuestionId?: any | null;
        questionId?: any | null;
        createdAt: any;
        updatedAt: any;
        score?: number | null;
        user?: {
          __typename?: "User";
          id: any;
          profilePicUrl?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
        } | null;
        replies: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          clipId?: any | null;
          text: string;
          isEdited: boolean;
          createdAt: any;
          visibility: CallNoteVisibility;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
          } | null;
        }>;
      }>;
    }>;
    generalNotes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
    interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
    streamableAudio?: {
      __typename?: "CallMedia";
      url: string;
      expiration: any;
    } | null;
    streamableVideo?: {
      __typename?: "CallMedia";
      url: string;
      expiration: any;
    } | null;
    user: { __typename?: "User"; id: any; fullName: string };
    viewers: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      phoneNumberVerified: boolean;
      isCalendarAuthorized: boolean;
      createdAt: any;
      deletedAt?: any | null;
      signUpMethod: UserSignUpMethod;
      signUpMethodLabel: string;
      signUpCompletedAt?: any | null;
      voipPhoneNumber?: string | null;
      invitedBy?: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
      } | null;
    }>;
    callSpeakers: Array<{
      __typename?: "CallSpeaker";
      id: any;
      speakerTag: number;
      label: string;
      isCandidate: boolean;
      isInterviewer?: boolean | null;
      identified: boolean;
      talkTimePercentage: number;
      longestMonologue?: number | null;
    }>;
    candidate?: {
      __typename?: "Candidate";
      id: any;
      firstName: string;
      lastName: string;
      fullName: string;
      greenhouseId?: number | null;
      greenhouseLink?: string | null;
      lastLeverOpportunityId?: string | null;
    } | null;
    position?: {
      __typename?: "Position";
      id: any;
      displayTitle: string;
    } | null;
  } | null;
};

export type ClipShareQueryVariables = Exact<{
  clipId: Scalars["ID"];
}>;

export type ClipShareQuery = {
  __typename?: "Query";
  clip?: {
    __typename?: "Clip";
    id: any;
    canShare: boolean;
    canShareExternal: boolean;
    trainingProgramCount: number;
    shareableUsers: Array<{
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      profilePicUrl?: string | null;
    }>;
    clipShares: Array<{
      __typename?: "ClipShare";
      id: any;
      createdAt: any;
      updatedAt: any;
      sharedTo: {
        __typename?: "User";
        id: any;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      };
    }>;
    externalClipShares: Array<{
      __typename?: "ExternalClipShare";
      id: any;
      createdAt: any;
      updatedAt: any;
      isExpired: boolean;
      shareDaysRemaining: number;
      daysRemaining: number;
      sharedToExternalUser: {
        __typename?: "ExternalUser";
        id: any;
        email: string;
      };
      sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
    }>;
    visibleTo: {
      __typename?: "PaginatedRecordingVisibleTo";
      resultCount: number;
      results: Array<{
        __typename?: "RecordingVisibleTo";
        id: string;
        role: string;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type ClipSpeakerOptionsQueryVariables = Exact<{
  clipId: Scalars["ID"];
}>;

export type ClipSpeakerOptionsQuery = {
  __typename?: "Query";
  clip?: {
    __typename?: "Clip";
    id: any;
    callSpeakerOptions: Array<{
      __typename?: "CallSpeakerOption";
      id: any;
      label: string;
    }>;
  } | null;
};

export type ClipTrackerSegmentQueryVariables = Exact<{
  clipId: Scalars["ID"];
  trackerKeywordId?: InputMaybe<Scalars["String"]>;
  trackerGroupId?: InputMaybe<Scalars["String"]>;
}>;

export type ClipTrackerSegmentQuery = {
  __typename?: "Query";
  clip?: {
    __typename?: "Clip";
    id: any;
    callType: CallType;
    callSpeakers: Array<{
      __typename?: "CallSpeaker";
      id: any;
      speakerTag: number;
      label: string;
      isCandidate: boolean;
      isInterviewer?: boolean | null;
      identified: boolean;
      talkTimePercentage: number;
      longestMonologue?: number | null;
    }>;
    trackerSegments: Array<{
      __typename?: "TrackerSegment";
      keyword?: string | null;
      segments: Array<{
        __typename?: "TranscriptSegment";
        id: any;
        speakerTag: number;
        startTime: number;
        endTime: number;
        languageCode?: string | null;
        words: Array<{
          __typename?: "TranscriptWord";
          word: string;
          startTime: number;
          endTime: number;
          confidence?: number | null;
          isRedacted?: boolean | null;
          rawWord?: string | null;
        }>;
      }>;
    }>;
  } | null;
};

export type ClipTrackersQueryVariables = Exact<{
  clipId: Scalars["ID"];
}>;

export type ClipTrackersQuery = {
  __typename?: "Query";
  clip?: {
    __typename?: "Clip";
    id: any;
    callSpeakers: Array<{
      __typename?: "CallSpeaker";
      id: any;
      speakerTag: number;
      label: string;
      isCandidate: boolean;
      isInterviewer?: boolean | null;
      identified: boolean;
      talkTimePercentage: number;
      longestMonologue?: number | null;
    }>;
    trackers?: Array<{
      __typename?: "TrackerGroup";
      id: any;
      name: string;
      count?: number | null;
      trackerKeywords: Array<{
        __typename?: "TrackerKeyword";
        id: any;
        name: string;
        count?: number | null;
        speakerCounts: Array<{
          __typename?: "TrackerSpeakerCount";
          speakerTag: number;
          count: number;
        }>;
      }>;
    }> | null;
  } | null;
};

export type ClipTranscriptQueryVariables = Exact<{
  clipId: Scalars["ID"];
  notesForCurrentUserOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ClipTranscriptQuery = {
  __typename?: "Query";
  clip?: {
    __typename?: "Clip";
    canEdit: boolean;
    id: any;
    callId: any;
    userId: any;
    startTime: number;
    endTime: number;
    recordingStatus: ClipRecordingStatus;
    failureReason?: string | null;
    createdAt: any;
    updatedAt: any;
    visibility: CallVisibility;
    visibleVisibilityLevels: Array<CallVisibility>;
    thumbnailImageUrl?: string | null;
    canDelete: boolean;
    name?: string | null;
    displayName: string;
    iaVersion: number;
    interviewerIds: Array<string>;
    callStartTime?: any | null;
    callEndTime?: any | null;
    callName?: string | null;
    callRecordingDisabled: boolean;
    callPhoneNumber?: string | null;
    callType: CallType;
    notes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
    interviewers: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
    }>;
    transcript: Array<{
      __typename?: "TranscriptSegment";
      id: any;
      speakerTag: number;
      startTime: number;
      endTime: number;
      languageCode?: string | null;
      words: Array<{
        __typename?: "TranscriptWord";
        word: string;
        startTime: number;
        endTime: number;
        confidence?: number | null;
        isRedacted?: boolean | null;
        rawWord?: string | null;
      }>;
    }>;
    streamableAudio?: {
      __typename?: "CallMedia";
      url: string;
      expiration: any;
    } | null;
    streamableVideo?: {
      __typename?: "CallMedia";
      url: string;
      expiration: any;
    } | null;
    user: { __typename?: "User"; id: any; fullName: string };
    viewers: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      phoneNumber?: string | null;
      phoneNumberExtension?: string | null;
      phoneNumberVerified: boolean;
      isCalendarAuthorized: boolean;
      createdAt: any;
      deletedAt?: any | null;
      signUpMethod: UserSignUpMethod;
      signUpMethodLabel: string;
      signUpCompletedAt?: any | null;
      voipPhoneNumber?: string | null;
      invitedBy?: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
      } | null;
    }>;
    callSpeakers: Array<{
      __typename?: "CallSpeaker";
      id: any;
      speakerTag: number;
      label: string;
      isCandidate: boolean;
      isInterviewer?: boolean | null;
      identified: boolean;
      talkTimePercentage: number;
      longestMonologue?: number | null;
    }>;
    candidate?: {
      __typename?: "Candidate";
      id: any;
      firstName: string;
      lastName: string;
      fullName: string;
      greenhouseId?: number | null;
      greenhouseLink?: string | null;
      lastLeverOpportunityId?: string | null;
    } | null;
    position?: {
      __typename?: "Position";
      id: any;
      displayTitle: string;
    } | null;
  } | null;
};

export type CurrentUserAiSummaryTemplatesQueryVariables = Exact<{
  additionalTemplateId?: InputMaybe<Scalars["ID"]>;
}>;

export type CurrentUserAiSummaryTemplatesQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    callAiSummaryTemplates: Array<{
      __typename?: "CallAiSummaryTemplate";
      id: any;
      name: string;
      isCreatorDefault: boolean;
      customType?: CustomTemplateCustomType | null;
      customPrompt?: string | null;
      model?: string | null;
      createdBy: any;
      visibility: CallAiSummaryTemplateVisibility;
      isCurrentUserDefault: boolean;
      defaultFor: CallAiSummaryTemplateDefaultFor;
      defaultForPersona?: UserPersona | null;
      defaultForKeywords: Array<string>;
      defaultForMeetingType?: CustomTemplateType | null;
      canEdit: boolean;
      canDelete: boolean;
      sections: Array<{
        __typename?: "CallAiSummaryTemplateSection";
        id: any;
        title: string;
        prompt: Array<string>;
      }>;
    }>;
  } | null;
};

export type CurrentUserCalendarQueryVariables = Exact<{
  authOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CurrentUserCalendarQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    googleUserId?: string | null;
    isCalendarAuthorized: boolean;
    isGcalAuthorized: boolean;
    isMsftCalAuthorized: boolean;
    calendars?: Array<{
      __typename?: "Calendar";
      id: any;
      resourceId: string;
      name: string;
      videoMeetingImportStrategy: string;
      canSetVideoMeetingImportStrategy: boolean;
      primary?: boolean | null;
    }>;
    organization?: {
      __typename?: "Organization";
      id: any;
      isZoomAuthorized: boolean;
      isZoomBotAuthorized: boolean;
      googleMeetEnabled: boolean;
      zoomNotetakerEnabled: boolean;
    };
  } | null;
};

export type CurrentUserCallGuidesQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type CurrentUserCallGuidesQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    callGuides: Array<{
      __typename?: "CallGuide";
      canEdit: boolean;
      id: any;
      name?: string | null;
      isTemplate: boolean;
      visibility: CallGuideVisibility;
      createdAt: any;
      updatedAt: any;
      greenhouseId?: number | null;
      leverFeedbackTemplateId?: string | null;
      ashbyFeedbackFormId?: string | null;
      smartrecruitersCriteriaId?: string | null;
      atsId?: string | null;
      overallScoringEnabled: boolean;
      position?: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      } | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      cues: Array<{
        __typename?: "CallGuideCue";
        id: any;
        cue: string;
        description: string;
        isHtmlType: boolean;
        position: number;
        scoringEnabled: boolean;
        type: GuideItemType;
        competencyId?: any | null;
        childItems: Array<{
          __typename?: "CallGuideItemChild";
          id: any;
          description: string;
          position: number;
        }>;
      }>;
      assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
      interviewStage?: {
        __typename?: "InterviewStage";
        id: any;
        title: string;
      } | null;
    }>;
  } | null;
};

export type CurrentUserCallingMetricsQueryVariables = Exact<{
  start: Scalars["Date"];
  end?: InputMaybe<Scalars["Date"]>;
}>;

export type CurrentUserCallingMetricsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    callingMetrics: {
      __typename?: "UserCallingMetrics";
      numSignificantCalls: number;
      totalDuration: number;
      interviewerTalkRatio?: number | null;
      interviewerLongestMonologue?: number | null;
      interviewerWordsPerMinute?: number | null;
      callInteractivity?: number | null;
      weeklyCalls: number;
      weeklyDuration: number;
    };
  } | null;
};

export type CurrentUserCallsQueryVariables = Exact<{
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  positionId?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<CursorPaginationDetails>;
}>;

export type CurrentUserCallsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      googleMeetEnabled: boolean;
      isZoomAuthorized: boolean;
      zoomNotetakerEnabled: boolean;
    };
    calls: {
      __typename?: "PaginatedCalls";
      pageInfo?: {
        __typename?: "CursorPaginationInfo";
        hasNextPage?: boolean | null;
        hasPreviousPage?: boolean | null;
        nextPageStartId?: string | null;
        nextPageStartTimestamp?: any | null;
      } | null;
      results?: Array<{
        __typename?: "Call";
        id: any;
        name?: string | null;
        rating: number;
        type: CallType;
        phoneNumber?: string | null;
        visibility: CallVisibility;
        visibleVisibilityLevels: Array<CallVisibility>;
        canEdit: boolean;
        canDelete: boolean;
        interviewerIds: Array<string>;
        skipRedaction: boolean;
        noRecordingReason?: NoRecordingReason | null;
        isInterviewer: boolean;
        greenhouseScorecardLink?: string | null;
        greenhouseScorecardLinkWithId?: string | null;
        greenhouseUserRating?: string | null;
        leverInterviewLink?: string | null;
        leverInterviewLinkWithId?: string | null;
        startTime?: any | null;
        createdAt: any;
        duration?: number | null;
        status: CallStatus;
        transcriptionStatus?: TranscriptionStatus | null;
        recordingDisabled: boolean;
        thumbnailImageUrl?: string | null;
        recordingProcessingStatus?: CallRecordingProcessingStatus | null;
        thumbnailsProcessingStatus?: CallThumbnailsProcessingStatus | null;
        aiNotesProcessingStatus?: AiNotesProcessingStatus | null;
        myScore?: number | null;
        averageScore?: number | null;
        needsCurrentUserScore?: boolean | null;
        hasPendingScores?: boolean | null;
        interviewers: Array<{
          __typename?: "User";
          id: any;
          fullName: string;
          firstName?: string | null;
          lastName?: string | null;
          profilePicUrl?: string | null;
        }>;
        candidate?: {
          __typename?: "Candidate";
          id: any;
          firstName: string;
          lastName: string;
          fullName: string;
          defaultPhoneNumber?: string | null;
          defaultEmail?: string | null;
          readOnly: boolean;
        } | null;
        position?: {
          __typename?: "Position";
          id: any;
          displayTitle: string;
        } | null;
        recordings: Array<{
          __typename?: "Recording";
          id: any;
          format?: RecordingFormat | null;
        }>;
      }> | null;
    };
  } | null;
};

export type CurrentUserClipsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type CurrentUserClipsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    clips: {
      __typename?: "PaginatedClips";
      pageInfo?: {
        __typename?: "OffsetPaginationInfo";
        totalPages?: number | null;
        totalRows?: number | null;
      } | null;
      results?: Array<{
        __typename?: "Clip";
        id: any;
        createdAt: any;
        updatedAt: any;
        canEdit: boolean;
        canDelete: boolean;
        startTime: number;
        endTime: number;
        thumbnailImageUrl?: string | null;
        name?: string | null;
        displayName: string;
        userId: any;
        callId: any;
        callName?: string | null;
        duration: number;
        durationFormatted: string;
        recordingReady: boolean;
        visibility: CallVisibility;
        visibleVisibilityLevels: Array<CallVisibility>;
        candidate?: {
          __typename?: "Candidate";
          id: any;
          fullName: string;
        } | null;
        position?: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        } | null;
      }> | null;
    };
  } | null;
};

export type CurrentUserEventImportKeywordsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserEventImportKeywordsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    eventImportKeywords: Array<{
      __typename?: "EventImportKeyword";
      id: any;
      keywordText: string;
    }>;
  } | null;
};

export type CurrentUserEventsQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserEventsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    isCalendarAuthorized: boolean;
    events: Array<{
      __typename?: "Event";
      id: any;
      htmlLink: string;
      summary?: string | null;
      description?: string | null;
      start: any;
      end: any;
      location?: string | null;
      calendar: { __typename?: "Calendar"; id: any; name: string };
      attendees: Array<{
        __typename?: "Attendee";
        email: string;
        responseStatus: ResponseStatus;
        displayName?: string | null;
      }>;
    }>;
  } | null;
};

export type CurrentUserFreemiumScheduledInterviewsQueryVariables = Exact<{
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  positionId?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<CursorPaginationDetails>;
}>;

export type CurrentUserFreemiumScheduledInterviewsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    scheduledInterviews: {
      __typename?: "PaginatedScheduledInterviews";
      pageInfo?: {
        __typename?: "CursorPaginationInfo";
        hasNextPage?: boolean | null;
        hasPreviousPage?: boolean | null;
        nextPageStartId?: string | null;
        nextPageStartTimestamp?: any | null;
      } | null;
      results?: Array<{
        __typename?: "ScheduledInterview";
        id: string;
        name?: string | null;
        scheduledStart?: any | null;
        scheduledEnd?: any | null;
        isExcluded: boolean;
        isImported: boolean;
        importDecision: boolean;
        importDecisionReason?: InterviewImportDecisionReason | null;
        greenhouseLink?: string | null;
        ghJobStageName?: string | null;
        interviewType?: InterviewType | null;
        isInterviewer: boolean;
        recordingDisabled: boolean;
        scheduledType: ScheduledInterviewType;
        candidate?: {
          __typename?: "Candidate";
          id: any;
          firstName: string;
          lastName: string;
          fullName: string;
          callCount?: number | null;
          defaultPhoneNumber?: string | null;
          recordingDisabled: boolean;
        } | null;
        position?: {
          __typename?: "Position";
          id: any;
          displayTitle: string;
        } | null;
      }> | null;
    };
  } | null;
};

export type CurrentUserInternationalPhoneVoipNumbersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserInternationalPhoneVoipNumbersQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      internationalPhoneVoipNumbers?: Array<{
        __typename?: "InternationalPhoneVoipNumbers";
        countryCode: string;
        orgPhoneNumber: string;
      }> | null;
    };
  } | null;
};

export type CurrentUserInterviewImportRuleQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserInterviewImportRuleQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    interviewImportRule?: UserInterviewImportRule | null;
  } | null;
};

export type CurrentUserMyCallSummaryTemplatesQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type CurrentUserMyCallSummaryTemplatesQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      callAiSummaryTemplates: {
        __typename?: "PaginatedCallAiSummaryTemplates";
        pageInfo?: {
          __typename?: "OffsetPaginationInfo";
          totalPages?: number | null;
          totalRows?: number | null;
        } | null;
        results?: Array<{
          __typename?: "CallAiSummaryTemplate";
          id: any;
          name: string;
          createdAt: any;
          visibility: CallAiSummaryTemplateVisibility;
          canEdit: boolean;
          canDelete: boolean;
          defaultFor: CallAiSummaryTemplateDefaultFor;
          defaultForPersona?: UserPersona | null;
          defaultForKeywords: Array<string>;
          defaultForMeetingType?: CustomTemplateType | null;
          creator: { __typename?: "User"; id: any; fullName: string };
        }> | null;
      };
    };
  } | null;
};

export type CurrentUserPhoneNumberVerifiedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserPhoneNumberVerifiedQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    phoneNumber?: string | null;
    phoneNumberExtension?: string | null;
    phoneNumberVerified: boolean;
  } | null;
};

export type CurrentUserPositionsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type CurrentUserPositionsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    positions: {
      __typename?: "PaginatedPositions";
      pageInfo?: {
        __typename?: "OffsetPaginationInfo";
        totalPages?: number | null;
        totalRows?: number | null;
      } | null;
      results?: Array<{
        __typename?: "Position";
        id: any;
        title: string;
        createdAt: any;
        lastCallAt?: any | null;
        displayTitle: string;
        greenhouseId?: number | null;
        greenhouseLink?: string | null;
        leverPostingId?: string | null;
        autoJoinInterview: boolean;
        atsStatus?: string | null;
        candidatesCount?: number | null;
        callGuidesCount?: number | null;
        client: {
          __typename?: "Client";
          id: any;
          name: string;
          autoJoinAllNewPositions: boolean;
        };
      }> | null;
    };
  } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    internalUserRole?: InternalUserRoleName | null;
    internalUserRolePermissions: Array<string>;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    email: string;
    emailAliases: string;
    profilePicUrl?: string | null;
    persona?: UserPersona | null;
    phoneNumber?: string | null;
    phoneNumberExtension?: string | null;
    maskPersonalPhoneNumber: boolean;
    phoneNumberVerified: boolean;
    numUnreadAlerts: number;
    voipPhoneNumber?: string | null;
    isImpersonated: boolean;
    autofillGreenhouseScorecard: boolean;
    zoomAppAutoOpenEnabled?: boolean | null;
    defaultCallType: CallType;
    intercomUserHash: string;
    createdAt: any;
    signUpCompletedAt?: any | null;
    signUpState: string;
    requireCalendarSync: boolean;
    enabledFeatures: Array<string>;
    impersonatedFeatures: Array<string>;
    browserExtensionVersion?: string | null;
    showJoinWithBrighthire: ShowJoinWithBrighthireStatus;
    callCount: number;
    lastCalledAt?: any | null;
    signUpMethod: UserSignUpMethod;
    timezoneName: string;
    hasViewedTranscriptionWarning: boolean;
    interviewRecapSubscription: UserSubscriptionSettings;
    interviewPrepSubscription: UserSubscriptionSettings;
    weeklyRecapSubscription: UserSubscriptionSettings;
    monthlyAnalyticsSubscription: UserSubscriptionSettings;
    callShareSubscription: UserSubscriptionSettings;
    callNoteSubscription: UserSubscriptionSettings;
    morningDigestSubscription: UserSubscriptionSettings;
    isZoomAppInstalled: boolean;
    shouldReauthorizeZoomApp: boolean;
    trainingProgramsCreatedCount: number;
    trainingProgramsCompletedCount: number;
    showCandidateActionItemAlerts: boolean;
    isInternationalCallingEnabled: boolean;
    defaultAiNoteFormat: CallAiSummaryFormat;
    organization: {
      __typename?: "Organization";
      id: any;
      name: string;
      freemium: boolean;
      phoneNumber?: string | null;
      playCallDisclaimer: boolean;
      isBullhornAuthorized: boolean;
      isZoomAuthorized: boolean;
      greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule;
      greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule;
      allowsGreenhouseScorecardAutoDraft: boolean;
      allowUserLevelImportRules: boolean;
      requiresGreenhouseScorecardAutoDraft: boolean;
      allowsZoomAppAutoOpen: boolean;
      requiresZoomAppAutoOpen: boolean;
      isZoomBotAuthorized: boolean;
      zoomNotetakerEnabled: boolean;
      recordingAllowed: boolean;
      googleMeetDisplayName?: string | null;
      googleMeetDisplayImageBucketPath?: string | null;
      googleMeetEnabled: boolean;
      virtualOnsiteEnabledGoogleMeet: boolean;
      googleMeetRecordingDisclaimer?: string | null;
      googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
      externalShareDefaultDurationDays: number;
      teamsNotetakerEnabled: boolean;
      teamsNotetakerDisplayName?: string | null;
      virtualOnsiteEnabledMsTeams: boolean;
      teamsNotetakerDisclaimer?: string | null;
      teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
      teamsNotetakerDisplayImageBucketPath?: string | null;
      notetakerRecorderDisclaimerDefault: string;
      audioOnlyViewing: boolean;
      allowSelfSignUp: boolean;
      allowPhoneSignIn: boolean;
      postNotesToAts: boolean;
      hideOtherInterviewerNotesInExtension: boolean;
      emailDomains?: Array<string> | null;
      inviteScheduledInterviewers: boolean;
      assignGuidesToInterviewersEnabled: boolean;
      requireActiveUserToImport: boolean;
      allowCandidateOptOut: boolean;
      candidateOptOutUrl: string;
      allowInterviewerOptOut: boolean;
      dataRedactionEnabled: boolean;
      dataRedactionPredictionEnabled: boolean;
      planEnabled: boolean;
      ats?: Ats | null;
      showStarRatings?: boolean | null;
      slackAppInstalled: boolean;
      allowManualScheduledInterviews: boolean;
      allowInPersonMeetings: boolean;
      scoringEnabled: boolean;
      guideCompetencyScoringEnabledByDefault: boolean;
      guideOverallScoringEnabledByDefault: boolean;
      playInPersonRecordingDisclaimer: boolean;
      includeTimestampsInNoteOutputs: boolean;
      interviewTypes: Array<InterviewType>;
      allowEmailSignIn: boolean;
      textBeforeCallEnabled: boolean;
      allowAiNotes: boolean;
      maximumCallDurationForNotetakerToRejoin?: number | null;
      individualVoipNumbersEnabled: boolean;
      callNameFieldIsDropdown: boolean;
      atsOptInEnabled: boolean;
      pasteAiNotesInScorecard: boolean;
      allowAiCustomTemplates: boolean;
      interviewEnabled: boolean;
      defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
      allowPlanJobDescriptionToneOverride: boolean;
      callDropdownNames?: Array<string> | null;
      askEnabled: boolean;
      createGuidesFromInterviewPlansEnabled: boolean;
      disableAnalyticsGenderSegmentation: boolean;
      ratingDescriptions: {
        __typename?: "RatingDescriptions";
        oneStar: string;
        twoStar: string;
        threeStar: string;
        fourStar: string;
        fiveStar: string;
      };
      competencyRatingDescriptions: {
        __typename?: "CompetencyRatingDescriptions";
        oneStar: string;
        twoStar: string;
        threeStar: string;
        fourStar: string;
        fiveStar: string;
      };
      userRoles: Array<{
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        permissions: Array<UserRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
        canAccessPublicCalls?: boolean | null;
        canAccessRestrictedCalls?: boolean | null;
        canAccessPrivateCalls?: boolean | null;
      }>;
      positionRoles: Array<{
        __typename?: "PositionRole";
        id: any;
        name: PositionRoleName;
        permissions: Array<PositionRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
        canAccessTeamPublicCalls?: boolean | null;
        canAccessTeamRestrictedCalls?: boolean | null;
        canAccessTeamPrivateCalls?: boolean | null;
      }>;
      planUserRoles: Array<{
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        permissions: Array<PlanUserRolePermission>;
        formattedName?: string | null;
        formattedPermissions?: string | null;
      }>;
      featureAccess?: Array<{
        __typename?: "OrganizationFeatureAccess";
        id: string;
        name: FeatureName;
        settingLevel: FeatureSettingLevel;
        isVisible: boolean;
        showCta: boolean;
        marketingRedirect: boolean;
      }> | null;
    };
    userRole?: {
      __typename?: "UserRole";
      id: any;
      name: UserRoleName;
      formattedName?: string | null;
      permissions: Array<UserRolePermission>;
      canManageOrganizationSettings?: boolean | null;
      canManageIntegrationSettings?: boolean | null;
      canManageHiringTeam?: boolean | null;
      canManageUserRoles?: boolean | null;
      canCreatePosition?: boolean | null;
      canDeleteCall?: boolean | null;
      canCreateTrainings?: boolean | null;
      canManageAllTrainings?: boolean | null;
      canDeleteCandidates?: boolean | null;
      canViewInsights?: boolean | null;
      canViewInsightsAlerts?: boolean | null;
      canManageIQReport?: boolean | null;
      canManageCustomTemplates?: boolean | null;
    } | null;
    planUserRole?: {
      __typename?: "PlanUserRole";
      id: any;
      name: PlanUserRoleName;
      formattedName?: string | null;
      permissions: Array<PlanUserRolePermission>;
      canManageOrganizationSettings?: boolean | null;
      canManageUserRoles?: boolean | null;
    } | null;
    memberships: Array<{
      __typename?: "Membership";
      position: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      };
      role: {
        __typename?: "PositionRole";
        id: any;
        permissions: Array<PositionRolePermission>;
        canManageHiringTeam?: boolean | null;
        canCreateTrainings?: boolean | null;
        canViewInsights?: boolean | null;
      };
    }>;
  } | null;
};

export type CurrentUserScheduledInterviewsNotImportedQueryVariables = Exact<{
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  positionId?: InputMaybe<Scalars["String"]>;
}>;

export type CurrentUserScheduledInterviewsNotImportedQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    scheduledInterviewsNotImported: Array<{
      __typename?: "ScheduledInterview";
      id: string;
      name?: string | null;
      scheduledStart?: any | null;
      timezoneName?: string | null;
      scheduledEnd?: any | null;
      durationMinutes?: number | null;
      phoneNumber?: string | null;
      zoomId?: number | null;
      zoomJoinUrl?: string | null;
      zoomMeetingUrl?: string | null;
      zoomAppAutoOpenEnabled: boolean;
      googleMeetMeetingUrl?: string | null;
      teamsMeetingUrl?: string | null;
      isVideo: boolean;
      canAddBrighthire?: boolean | null;
      canAddBrighthireReason?: string | null;
      canLaunchMeeting?: boolean | null;
      canLaunchMeetingReason?: string | null;
      isExcluded: boolean;
      isImported: boolean;
      importDecision: boolean;
      importDecisionReason?: InterviewImportDecisionReason | null;
      greenhouseLink?: string | null;
      ghJobStageName?: string | null;
      optInUrl?: string | null;
      leverInterviewId?: string | null;
      smartrecruitersInterviewLink?: string | null;
      isInPersonInterview: boolean;
      interviewType?: InterviewType | null;
      isInterviewer: boolean;
      recordingDisabled: boolean;
      scheduledType: ScheduledInterviewType;
      leverStage?: {
        __typename?: "LeverStage";
        id: any;
        text?: string | null;
      } | null;
      scheduledInterviewers: Array<{
        __typename?: "ScheduledInterviewer";
        id: string;
        recordingDisabled: boolean;
        name?: string | null;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
          phoneNumber?: string | null;
        } | null;
      }>;
      callGuide?: { __typename?: "CallGuide"; id: any } | null;
      candidate?: {
        __typename?: "Candidate";
        id: any;
        firstName: string;
        lastName: string;
        fullName: string;
        callCount?: number | null;
        defaultPhoneNumber?: string | null;
        recordingDisabled: boolean;
      } | null;
      position?: {
        __typename?: "Position";
        id: any;
        displayTitle: string;
      } | null;
      googleMeetMeetings: Array<{
        __typename?: "GoogleMeetMeeting";
        id: string;
      }>;
      teamsNotetakerMeetings: Array<{
        __typename?: "TeamsNotetakerMeeting";
        id: string;
      }>;
    }>;
  } | null;
};

export type CurrentUserScheduledInterviewsQueryVariables = Exact<{
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  positionId?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<CursorPaginationDetails>;
}>;

export type CurrentUserScheduledInterviewsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    scheduledInterviews: {
      __typename?: "PaginatedScheduledInterviews";
      pageInfo?: {
        __typename?: "CursorPaginationInfo";
        hasNextPage?: boolean | null;
        hasPreviousPage?: boolean | null;
        nextPageStartId?: string | null;
        nextPageStartTimestamp?: any | null;
      } | null;
      results?: Array<{
        __typename?: "ScheduledInterview";
        id: string;
        name?: string | null;
        scheduledStart?: any | null;
        timezoneName?: string | null;
        scheduledEnd?: any | null;
        durationMinutes?: number | null;
        phoneNumber?: string | null;
        zoomId?: number | null;
        zoomJoinUrl?: string | null;
        zoomMeetingUrl?: string | null;
        zoomAppAutoOpenEnabled: boolean;
        googleMeetMeetingUrl?: string | null;
        teamsMeetingUrl?: string | null;
        isVideo: boolean;
        canAddBrighthire?: boolean | null;
        canAddBrighthireReason?: string | null;
        canLaunchMeeting?: boolean | null;
        canLaunchMeetingReason?: string | null;
        isExcluded: boolean;
        isImported: boolean;
        importDecision: boolean;
        importDecisionReason?: InterviewImportDecisionReason | null;
        greenhouseLink?: string | null;
        ghJobStageName?: string | null;
        optInUrl?: string | null;
        leverInterviewId?: string | null;
        smartrecruitersInterviewLink?: string | null;
        isInPersonInterview: boolean;
        interviewType?: InterviewType | null;
        isInterviewer: boolean;
        recordingDisabled: boolean;
        scheduledType: ScheduledInterviewType;
        leverStage?: {
          __typename?: "LeverStage";
          id: any;
          text?: string | null;
        } | null;
        scheduledInterviewers: Array<{
          __typename?: "ScheduledInterviewer";
          id: string;
          recordingDisabled: boolean;
          name?: string | null;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            email: string;
            profilePicUrl?: string | null;
            phoneNumber?: string | null;
          } | null;
        }>;
        callGuide?: { __typename?: "CallGuide"; id: any } | null;
        candidate?: {
          __typename?: "Candidate";
          id: any;
          firstName: string;
          lastName: string;
          fullName: string;
          callCount?: number | null;
          defaultPhoneNumber?: string | null;
          recordingDisabled: boolean;
        } | null;
        position?: {
          __typename?: "Position";
          id: any;
          displayTitle: string;
        } | null;
        googleMeetMeetings: Array<{
          __typename?: "GoogleMeetMeeting";
          id: string;
        }>;
        teamsNotetakerMeetings: Array<{
          __typename?: "TeamsNotetakerMeeting";
          id: string;
        }>;
      }> | null;
    };
  } | null;
};

export type CurrentUserSharedCallGuidesQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type CurrentUserSharedCallGuidesQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    sharedCallGuides: Array<{
      __typename?: "CallGuide";
      canEdit: boolean;
      id: any;
      name?: string | null;
      isTemplate: boolean;
      visibility: CallGuideVisibility;
      createdAt: any;
      updatedAt: any;
      greenhouseId?: number | null;
      leverFeedbackTemplateId?: string | null;
      ashbyFeedbackFormId?: string | null;
      smartrecruitersCriteriaId?: string | null;
      atsId?: string | null;
      overallScoringEnabled: boolean;
      position?: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      } | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      cues: Array<{
        __typename?: "CallGuideCue";
        id: any;
        cue: string;
        description: string;
        isHtmlType: boolean;
        position: number;
        scoringEnabled: boolean;
        type: GuideItemType;
        competencyId?: any | null;
        childItems: Array<{
          __typename?: "CallGuideItemChild";
          id: any;
          description: string;
          position: number;
        }>;
      }>;
      assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
      interviewStage?: {
        __typename?: "InterviewStage";
        id: any;
        title: string;
      } | null;
    }>;
  } | null;
};

export type CurrentUserSharedCallSummaryTemplatesQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type CurrentUserSharedCallSummaryTemplatesQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      callAiSummaryTemplates: {
        __typename?: "PaginatedCallAiSummaryTemplates";
        pageInfo?: {
          __typename?: "OffsetPaginationInfo";
          totalPages?: number | null;
          totalRows?: number | null;
        } | null;
        results?: Array<{
          __typename?: "CallAiSummaryTemplate";
          id: any;
          name: string;
          createdAt: any;
          visibility: CallAiSummaryTemplateVisibility;
          canEdit: boolean;
          canDelete: boolean;
          defaultFor: CallAiSummaryTemplateDefaultFor;
          defaultForPersona?: UserPersona | null;
          defaultForKeywords: Array<string>;
          defaultForMeetingType?: CustomTemplateType | null;
          creator: { __typename?: "User"; id: any; fullName: string };
        }> | null;
      };
    };
  } | null;
};

export type ExcludeStagesQueryVariables = Exact<{ [key: string]: never }>;

export type ExcludeStagesQuery = {
  __typename?: "Query";
  excludeStages: Array<{
    __typename?: "ExcludeStage";
    id: any;
    excludeText: string;
  }>;
};

export type FeatureFlagQueryVariables = Exact<{
  featureFlag: Scalars["String"];
}>;

export type FeatureFlagQuery = {
  __typename?: "Query";
  featureFlag?: {
    __typename?: "FeatureFlag";
    id: any;
    name: string;
    description: string;
    enabled: boolean;
    updatedAt: any;
  } | null;
};

export type FeatureFlagValueQueryVariables = Exact<{
  featureFlag: Scalars["String"];
}>;

export type FeatureFlagValueQuery = {
  __typename?: "Query";
  featureFlag?: {
    __typename?: "FeatureFlag";
    name: string;
    value?: any | null;
  } | null;
};

export type FindGreenhouseUserQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type FindGreenhouseUserQuery = {
  __typename?: "Query";
  findGreenhouseUser?: {
    __typename?: "GreenhouseUser";
    id: string;
    email: string;
    greenhouseId: any;
    siteAdmin: boolean;
    disabled: boolean;
  } | null;
};

export type GreenhouseCurrentUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GreenhouseCurrentUserQuery = {
  __typename?: "Query";
  greenhouseCurrentUser?: {
    __typename?: "GreenhouseUser";
    id: string;
    email: string;
    greenhouseId: any;
    siteAdmin: boolean;
    disabled: boolean;
  } | null;
};

export type GreenhouseFreemiumSyncStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GreenhouseFreemiumSyncStatusQuery = {
  __typename?: "Query";
  greenhouseFreemiumSyncStatus?: {
    __typename?: "GreenhouseSync";
    id: any;
    lastSyncAt: any;
    status?: GreenhouseSyncStatus | null;
  } | null;
};

export type GreenhouseSetupQueryVariables = Exact<{
  verifyAdminUserId?: InputMaybe<Scalars["Boolean"]>;
  verifyApiPermissions?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GreenhouseSetupQuery = {
  __typename?: "Query";
  greenhouseSetup?: {
    __typename?: "GreenhouseSetup";
    id: string;
    enabled: boolean;
    apiKey: string;
    host: string;
    adminUserId: any;
    valid: boolean;
    invalidReason?: string | null;
    webhookSecretKey?: string | null;
    syncCallGuidesFromAts: boolean;
    atsOptInEnabled: boolean;
    greenhouseConsentKey?: string | null;
    permissionsStatus: Array<{
      __typename?: "GreenhousePermissionStatus";
      permission: string;
      isGranted: boolean;
    }>;
  } | null;
};

export type IaCallGuidesQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  defaultCallGuideId?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type IaCallGuidesQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    callGuides: Array<{
      __typename?: "CallGuide";
      canEdit: boolean;
      id: any;
      name?: string | null;
      isTemplate: boolean;
      visibility: CallGuideVisibility;
      createdAt: any;
      updatedAt: any;
      greenhouseId?: number | null;
      leverFeedbackTemplateId?: string | null;
      ashbyFeedbackFormId?: string | null;
      smartrecruitersCriteriaId?: string | null;
      atsId?: string | null;
      overallScoringEnabled: boolean;
      position?: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      } | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      cues: Array<{
        __typename?: "CallGuideCue";
        id: any;
        cue: string;
        description: string;
        isHtmlType: boolean;
        position: number;
        scoringEnabled: boolean;
        type: GuideItemType;
        competencyId?: any | null;
        childItems: Array<{
          __typename?: "CallGuideItemChild";
          id: any;
          description: string;
          position: number;
        }>;
      }>;
      assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
      interviewStage?: {
        __typename?: "InterviewStage";
        id: any;
        title: string;
      } | null;
    }>;
    recentCallGuides?: Array<{ __typename?: "CallGuide"; id: any }> | null;
    sharedCallGuides: Array<{
      __typename?: "CallGuide";
      canEdit: boolean;
      id: any;
      name?: string | null;
      isTemplate: boolean;
      visibility: CallGuideVisibility;
      createdAt: any;
      updatedAt: any;
      greenhouseId?: number | null;
      leverFeedbackTemplateId?: string | null;
      ashbyFeedbackFormId?: string | null;
      smartrecruitersCriteriaId?: string | null;
      atsId?: string | null;
      overallScoringEnabled: boolean;
      position?: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      } | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      cues: Array<{
        __typename?: "CallGuideCue";
        id: any;
        cue: string;
        description: string;
        isHtmlType: boolean;
        position: number;
        scoringEnabled: boolean;
        type: GuideItemType;
        competencyId?: any | null;
        childItems: Array<{
          __typename?: "CallGuideItemChild";
          id: any;
          description: string;
          position: number;
        }>;
      }>;
      assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
      interviewStage?: {
        __typename?: "InterviewStage";
        id: any;
        title: string;
      } | null;
    }>;
    assignedCallGuides: Array<{ __typename?: "CallGuide"; id: any }>;
  } | null;
};

export type IaCallQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type IaCallQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    interviewerIds: Array<string>;
    duration?: number | null;
    scheduledEndTime?: any | null;
    endTime?: any | null;
    rating: number;
    phoneNumber?: string | null;
    carrierType?: string | null;
    greenhouseScorecardLink?: string | null;
    greenhouseScorecardLinkWithId?: string | null;
    leverInterviewLink?: string | null;
    leverInterviewLinkWithId?: string | null;
    ashbyFeedbackLinkWithId?: string | null;
    bullhornCommentAction?: string | null;
    recordingDisabled: boolean;
    startTime?: any | null;
    type: CallType;
    useSafeNumber: boolean;
    virtualOnsiteId?: any | null;
    scorecard?: {
      __typename?: "Scorecard";
      id: any;
      overallScoringEnabled: boolean;
      overallScore?: number | null;
      overallNotes?: string | null;
      items: Array<{
        __typename?: "ScorecardItem";
        id: string;
        type?: GuideItemType | null;
        itemText: string;
        isHtmlType?: boolean | null;
        scoringEnabled?: boolean | null;
        score?: number | null;
        notes?: string | null;
        marked?: boolean | null;
        markedTime?: number | null;
        questionNotes: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          clipId?: any | null;
          time: number;
          highlightStartTime?: number | null;
          highlightEndTime?: number | null;
          speakerTag?: number | null;
          text: string;
          type: CallNoteType;
          description?: string | null;
          isEdited: boolean;
          visibility: CallNoteVisibility;
          guideItemId?: any | null;
          parentGuideItemId?: any | null;
          scorecardQuestionId?: any | null;
          questionId?: any | null;
          createdAt: any;
          updatedAt: any;
          score?: number | null;
          user?: {
            __typename?: "User";
            id: any;
            profilePicUrl?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
          } | null;
          replies: Array<{
            __typename?: "CallNote";
            id: any;
            callId: any;
            clipId?: any | null;
            text: string;
            isEdited: boolean;
            createdAt: any;
            visibility: CallNoteVisibility;
            user?: {
              __typename?: "User";
              id: any;
              firstName?: string | null;
              lastName?: string | null;
              fullName: string;
              profilePicUrl?: string | null;
            } | null;
          }>;
        }>;
        childItems: Array<{
          __typename?: "ScorecardItem";
          id: string;
          itemText: string;
          isHtmlType?: boolean | null;
          scoringEnabled?: boolean | null;
          score?: number | null;
          notes?: string | null;
          marked?: boolean | null;
          markedTime?: number | null;
          questionNotes: Array<{
            __typename?: "CallNote";
            id: any;
            callId: any;
            clipId?: any | null;
            time: number;
            highlightStartTime?: number | null;
            highlightEndTime?: number | null;
            speakerTag?: number | null;
            text: string;
            type: CallNoteType;
            description?: string | null;
            isEdited: boolean;
            visibility: CallNoteVisibility;
            guideItemId?: any | null;
            parentGuideItemId?: any | null;
            scorecardQuestionId?: any | null;
            questionId?: any | null;
            createdAt: any;
            updatedAt: any;
            score?: number | null;
            user?: {
              __typename?: "User";
              id: any;
              profilePicUrl?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              fullName: string;
            } | null;
            replies: Array<{
              __typename?: "CallNote";
              id: any;
              callId: any;
              clipId?: any | null;
              text: string;
              isEdited: boolean;
              createdAt: any;
              visibility: CallNoteVisibility;
              user?: {
                __typename?: "User";
                id: any;
                firstName?: string | null;
                lastName?: string | null;
                fullName: string;
                profilePicUrl?: string | null;
              } | null;
            }>;
          }>;
        }>;
      }>;
    } | null;
    notes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
    generalNotes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
    candidate?: {
      __typename?: "Candidate";
      id: any;
      firstName: string;
      lastName: string;
      fullName: string;
      defaultEmail?: string | null;
      greenhouseLink?: string | null;
      lastLeverOpportunityId?: string | null;
      bullhornId?: number | null;
      candidateLinks: Array<{
        __typename?: "CandidateLink";
        link: string;
        type: CandidateLinkType;
      }>;
    } | null;
    callGuide?: { __typename?: "CallGuide"; id: any } | null;
    position?: {
      __typename?: "Position";
      id: any;
      displayTitle: string;
    } | null;
    scheduledInterview?: {
      __typename?: "ScheduledInterview";
      id: string;
      scheduledStart?: any | null;
      scheduledEnd?: any | null;
      virtualOnsiteId?: any | null;
      inProgressMeeting?: {
        __typename?: "NotetakerMeeting";
        id: string;
        callId?: string | null;
      } | null;
      scheduledInterviewers: Array<{
        __typename?: "ScheduledInterviewer";
        id: string;
        recordingDisabled: boolean;
        name?: string | null;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          email: string;
          profilePicUrl?: string | null;
          phoneNumber?: string | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type IcimsSetupQueryVariables = Exact<{ [key: string]: never }>;

export type IcimsSetupQuery = {
  __typename?: "Query";
  icimsSetup?: {
    __typename?: "IcimsSetup";
    id: string;
    enabled: boolean;
    valid: boolean;
    customerId?: string | null;
    clientId?: string | null;
    clientSecret?: string | null;
    tokenUrl?: string | null;
  } | null;
};

export type InterviewOptInQueryVariables = Exact<{
  location: Scalars["String"];
  organizationId: Scalars["String"];
}>;

export type InterviewOptInQuery = {
  __typename?: "Query";
  interviewOptIn: {
    __typename?: "OptInResult";
    exists: boolean;
    willRecord: boolean;
    whoIsPageText?: string | null;
    yesNoPageText?: string | null;
    errorMessage?: string | null;
    alwaysCandidate?: boolean | null;
    learnMoreLink?: string | null;
    heroImageUrl?: string | null;
  };
};

export type InterviewersQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  signUpCompleted?: InputMaybe<Scalars["Boolean"]>;
}>;

export type InterviewersQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      users: Array<{
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
        phoneNumber?: string | null;
      }>;
    };
  } | null;
};

export type LeverOauthSetupQueryVariables = Exact<{ [key: string]: never }>;

export type LeverOauthSetupQuery = {
  __typename?: "Query";
  leverOauthSetup?: {
    __typename?: "LeverOauthSetup";
    id: string;
    useSandbox: boolean;
    valid: boolean;
    syncCallGuidesFromAts: boolean;
    createSecretNotes: boolean;
  } | null;
};

export type LeverSetupQueryVariables = Exact<{ [key: string]: never }>;

export type LeverSetupQuery = {
  __typename?: "Query";
  leverSetup?: {
    __typename?: "LeverSetup";
    id: string;
    enabled: boolean;
    apiKey: string;
    signatureTokenObj?: string | null;
    valid: boolean;
    syncCallGuidesFromAts: boolean;
    createSecretNotes: boolean;
  } | null;
};

export type MergeSetupQueryVariables = Exact<{ [key: string]: never }>;

export type MergeSetupQuery = {
  __typename?: "Query";
  mergeSetup: Array<{
    __typename?: "MergeSetup";
    id: string;
    enabled: boolean;
    valid: boolean;
    category?: string | null;
    slug?: string | null;
  }>;
};

export type OptInSetupQueryVariables = Exact<{ [key: string]: never }>;

export type OptInSetupQuery = {
  __typename?: "Query";
  optInSetup: {
    __typename?: "OptInSetup";
    id: string;
    whoIsPageText: string;
    yesNoPageText: string;
    optInUrlStub: string;
    alwaysCandidate: boolean;
    heroImageUrl: string;
    learnMoreLink: string;
  };
};

export type OrgCallingMetricsQueryVariables = Exact<{
  start: Scalars["Date"];
  end?: InputMaybe<Scalars["Date"]>;
}>;

export type OrgCallingMetricsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      users: Array<{
        __typename?: "User";
        id: any;
        fullName: string;
        callingMetrics: {
          __typename?: "UserCallingMetrics";
          numSignificantCalls: number;
          totalDuration: number;
          interviewerTalkRatio?: number | null;
          interviewerLongestMonologue?: number | null;
          interviewerWordsPerMinute?: number | null;
          callInteractivity?: number | null;
          weeklyCalls: number;
          weeklyDuration: number;
        };
      }>;
      callingMetrics: {
        __typename?: "OrgCallingMetrics";
        numSignificantCalls: number;
        totalDuration: number;
        weeklyCalls: number;
        weeklyDuration: number;
      };
    };
  } | null;
};

export type OrgClientsQueryVariables = Exact<{ [key: string]: never }>;

export type OrgClientsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      clients: Array<{ __typename?: "Client"; id: any; name: string }>;
    };
  } | null;
};

export type OrgHiringTeamGroupQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OrgHiringTeamGroupQuery = {
  __typename?: "Query";
  client?: {
    __typename?: "Client";
    id: any;
    name: string;
    createdAt: any;
    clientMemberships?: Array<{
      __typename?: "ClientMembership";
      positionRoleId: any;
      user: { __typename?: "User"; id: any; email: string; fullName: string };
    }> | null;
    positions: Array<{
      __typename?: "Position";
      id: any;
      title: string;
      displayTitle: string;
      createdAt: any;
    }>;
  } | null;
};

export type OrgHiringTeamGroupsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type OrgHiringTeamGroupsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      clientsPag?: {
        __typename?: "PaginatedClients";
        pageInfo?: {
          __typename?: "OffsetPaginationInfo";
          totalPages?: number | null;
        } | null;
        results?: Array<{
          __typename?: "Client";
          id: any;
          name: string;
          createdAt: any;
          positionsCount?: number | null;
          clientMembershipsCount?: number | null;
        }> | null;
      } | null;
    };
  } | null;
};

export type OrgHiringTeamQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OrgHiringTeamQuery = {
  __typename?: "Query";
  position?: {
    __typename?: "Position";
    id: any;
    title: string;
    createdAt: any;
    displayTitle: string;
    memberships: Array<{
      __typename?: "Membership";
      createdAt: any;
      user: { __typename?: "User"; id: any; email: string; fullName: string };
      role: { __typename?: "PositionRole"; id: any; name: PositionRoleName };
      position: { __typename?: "Position"; id: any; title: string };
    }>;
    client: { __typename?: "Client"; id: any; name: string };
  } | null;
};

export type OrgHiringTeamsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type OrgHiringTeamsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      positions: {
        __typename?: "PaginatedPositions";
        pageInfo?: {
          __typename?: "OffsetPaginationInfo";
          totalPages?: number | null;
        } | null;
        results?: Array<{
          __typename?: "Position";
          membershipsCount?: number | null;
          id: any;
          title: string;
          createdAt: any;
          displayTitle: string;
          client: { __typename?: "Client"; id: any; name: string };
        }> | null;
      };
    };
  } | null;
};

export type OrgPositionsJbpQueryVariables = Exact<{
  atsOnly?: InputMaybe<Scalars["Boolean"]>;
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type OrgPositionsJbpQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      autoJoinAllNewPositions: boolean;
      positions: {
        __typename?: "PaginatedPositions";
        pageInfo?: {
          __typename?: "OffsetPaginationInfo";
          totalPages?: number | null;
          totalRows?: number | null;
        } | null;
        results?: Array<{
          __typename?: "Position";
          id: any;
          title: string;
          requisitionId?: string | null;
          locations?: Array<string | null> | null;
          createdAt: any;
          displayTitle: string;
          greenhouseId?: number | null;
          greenhouseLink?: string | null;
          leverPostingId?: string | null;
          autoJoinInterview: boolean;
          atsStatus?: string | null;
          client: {
            __typename?: "Client";
            id: any;
            name: string;
            autoJoinAllNewPositions: boolean;
          };
        }> | null;
      };
    };
  } | null;
};

export type OrgPositionsPageQueryVariables = Exact<{
  atsOnly?: InputMaybe<Scalars["Boolean"]>;
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type OrgPositionsPageQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      autoJoinAllNewPositions: boolean;
      positions: {
        __typename?: "PaginatedPositions";
        pageInfo?: {
          __typename?: "OffsetPaginationInfo";
          totalPages?: number | null;
          totalRows?: number | null;
        } | null;
        results?: Array<{
          __typename?: "Position";
          id: any;
          title: string;
          createdAt: any;
          lastCallAt?: any | null;
          displayTitle: string;
          greenhouseId?: number | null;
          greenhouseLink?: string | null;
          leverPostingId?: string | null;
          autoJoinInterview: boolean;
          atsStatus?: string | null;
          candidatesCount?: number | null;
          callGuidesCount?: number | null;
          client: {
            __typename?: "Client";
            id: any;
            name: string;
            autoJoinAllNewPositions: boolean;
          };
        }> | null;
      };
    };
  } | null;
};

export type OrgPositionsQueryVariables = Exact<{
  atsOnly?: InputMaybe<Scalars["Boolean"]>;
  activeOnly?: InputMaybe<Scalars["Boolean"]>;
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type OrgPositionsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      positions: {
        __typename?: "PaginatedPositions";
        pageInfo?: {
          __typename?: "OffsetPaginationInfo";
          totalPages?: number | null;
          totalRows?: number | null;
        } | null;
        results?: Array<{
          __typename?: "Position";
          id: any;
          title: string;
          createdAt: any;
          displayTitle: string;
          client: { __typename?: "Client"; id: any; name: string };
        }> | null;
      };
    };
  } | null;
};

export type OrgInterviewStagesQueryVariables = Exact<{
  atsOnly?: InputMaybe<Scalars["Boolean"]>;
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type OrgInterviewStagesQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      interviewStages: {
        __typename?: "PaginatedStages";
        pageInfo?: {
          __typename?: "OffsetPaginationInfo";
          totalPages?: number | null;
          totalRows?: number | null;
        } | null;
        results?: Array<{
          __typename?: "InterviewStage";
          id: any;
          title: string;
          createdAt: any;
        }> | null;
      };
    };
  } | null;
};

export type OrgUserAutoJoinExclusionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrgUserAutoJoinExclusionsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      userAutoJoinExclusions: Array<{
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
        phoneNumber?: string | null;
        phoneNumberExtension?: string | null;
        phoneNumberVerified: boolean;
        isCalendarAuthorized: boolean;
        createdAt: any;
        deletedAt?: any | null;
        signUpMethod: UserSignUpMethod;
        signUpMethodLabel: string;
        signUpCompletedAt?: any | null;
        voipPhoneNumber?: string | null;
        invitedBy?: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type OrgUsersOnlyQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
}>;

export type OrgUsersOnlyQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      users: Array<{
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        userRole?: {
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
        } | null;
      }>;
    };
  } | null;
};

export type OrgUsersQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  signUpCompleted?: InputMaybe<Scalars["Boolean"]>;
  loginPermitted?: InputMaybe<Scalars["Boolean"]>;
}>;

export type OrgUsersQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      planEnabled: boolean;
      freemium: boolean;
      freemiumSeatCount: number;
      users: Array<{
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
        phoneNumber?: string | null;
        phoneNumberExtension?: string | null;
        phoneNumberVerified: boolean;
        isCalendarAuthorized: boolean;
        createdAt: any;
        deletedAt?: any | null;
        signUpMethod: UserSignUpMethod;
        signUpMethodLabel: string;
        signUpCompletedAt?: any | null;
        voipPhoneNumber?: string | null;
        userRole?: {
          __typename?: "UserRole";
          id: any;
          name: UserRoleName;
        } | null;
        planUserRole?: {
          __typename?: "PlanUserRole";
          id: any;
          name: PlanUserRoleName;
        } | null;
        memberships: Array<{
          __typename?: "Membership";
          createdAt: any;
          role: {
            __typename?: "PositionRole";
            id: any;
            name: PositionRoleName;
            formattedName?: string | null;
          };
          position: { __typename?: "Position"; id: any; title: string };
        }>;
        invitedBy?: {
          __typename?: "User";
          id: any;
          fullName: string;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type OrganizationDataRetentionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationDataRetentionQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      ats?: Ats | null;
      enableRetentionSinceCandidateHired: boolean;
      retentionDaysSinceCandidateHired: number;
      enableRetentionSinceCandidateRejected: boolean;
      retentionDaysSinceCandidateRejected: number;
      enableRetentionSinceLastInterview: boolean;
      retentionDaysSinceLastInterview: number;
      enableRetentionSinceInterview: boolean;
      retentionDaysSinceInterview: number;
      enableRedactionForGreenhouse: boolean;
      enableRedactionForLever: boolean;
      enableRedactionForSmartrecruiters: boolean;
      enableRedactionForAshby: boolean;
      enableAutomaticRetentionDeletion: boolean;
      retentionNotificationDays?: Array<string | null> | null;
      retentionEmailSendTime: any;
      retentionEmailSendTimezone: string;
      preventRedactionForTraining: boolean;
      organizationRetentionUser: Array<{
        __typename?: "OrganizationRetentionUser";
        userId: any;
      }>;
    };
  } | null;
};

export type OrganizationNameQueryVariables = Exact<{
  orgIdSlug: Scalars["ID"];
}>;

export type OrganizationNameQuery = {
  __typename?: "Query";
  organizationName?: { __typename?: "OrganizationName"; name: string } | null;
};

export type OrganizationRolesQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationRolesQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      planEnabled: boolean;
      userRoles: Array<{
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
      }>;
      positionRoles: Array<{
        __typename?: "PositionRole";
        id: any;
        name: PositionRoleName;
        formattedName?: string | null;
      }>;
      planUserRoles: Array<{
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
      }>;
    };
  } | null;
};

export type InternalOrganizationsListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InternalOrganizationsListQuery = {
  __typename?: "Query";
  internalOrganizationsList: Array<{
    __typename?: "OrganizationListItem";
    id: string;
    name: string;
  }>;
};

export type PlanCustomTemplateQueryVariables = Exact<{ [key: string]: never }>;

export type PlanCustomTemplateQuery = {
  __typename?: "Query";
  planCustomTemplate: {
    __typename?: "JobDescriptionTemplate";
    id: any;
    sections: Array<{
      __typename?: "JobDescriptionTemplateSection";
      id: any;
      sectionHeader: string;
      sectionText: string;
      order: number;
      type: JobDescriptionTemplateSectionType;
    }>;
  };
};

export type PositionCallGuidesQueryVariables = Exact<{
  id: Scalars["ID"];
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type PositionCallGuidesQuery = {
  __typename?: "Query";
  position?: {
    __typename?: "Position";
    id: any;
    paginatedCallGuides: {
      __typename?: "PaginatedCallGuides";
      pageInfo?: {
        __typename?: "OffsetPaginationInfo";
        totalPages?: number | null;
        totalRows?: number | null;
      } | null;
      results: Array<{
        __typename?: "CallGuide";
        canEdit: boolean;
        id: any;
        name?: string | null;
        isTemplate: boolean;
        visibility: CallGuideVisibility;
        createdAt: any;
        updatedAt: any;
        greenhouseId?: number | null;
        leverFeedbackTemplateId?: string | null;
        ashbyFeedbackFormId?: string | null;
        smartrecruitersCriteriaId?: string | null;
        atsId?: string | null;
        overallScoringEnabled: boolean;
        position?: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        } | null;
        creator?: { __typename?: "User"; id: any; fullName: string } | null;
        cues: Array<{
          __typename?: "CallGuideCue";
          id: any;
          cue: string;
          description: string;
          isHtmlType: boolean;
          position: number;
          scoringEnabled: boolean;
          type: GuideItemType;
          competencyId?: any | null;
          childItems: Array<{
            __typename?: "CallGuideItemChild";
            id: any;
            description: string;
            position: number;
          }>;
        }>;
        assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
        interviewStage?: {
          __typename?: "InterviewStage";
          id: any;
          title: string;
        } | null;
      }>;
    };
  } | null;
};

export type PositionCandidatesQueryVariables = Exact<{
  id: Scalars["ID"];
  pagination?: InputMaybe<OffsetPaginationDetails>;
  includeScores?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PositionCandidatesQuery = {
  __typename?: "Query";
  position?: {
    __typename?: "Position";
    id: any;
    paginatedCandidates: {
      __typename?: "PaginatedCandidates";
      pageInfo?: {
        __typename?: "OffsetPaginationInfo";
        totalPages?: number | null;
        totalRows?: number | null;
      } | null;
      results?: Array<{
        __typename?: "Candidate";
        id: any;
        fullName: string;
        firstName: string;
        lastName: string;
        defaultEmail?: string | null;
        defaultPhoneNumber?: string | null;
        rating?: number | null;
        skipRedaction: boolean;
        callCount?: number | null;
        validCallsCount?: number | null;
        recordingDisabled: boolean;
        positionAverageScore?: number | null;
        lastCall?: {
          __typename?: "Call";
          id: any;
          name?: string | null;
          type: CallType;
          startTime?: any | null;
          createdAt: any;
          duration?: number | null;
          status: CallStatus;
          transcriptionStatus?: TranscriptionStatus | null;
          recordingDisabled: boolean;
          rating: number;
          interviewerIds: Array<string>;
          interviewers: Array<{
            __typename?: "User";
            id: any;
            fullName: string;
          }>;
          position?: {
            __typename?: "Position";
            id: any;
            displayTitle: string;
          } | null;
        } | null;
      }> | null;
    };
  } | null;
};

export type PositionInfoQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PositionInfoQuery = {
  __typename?: "Query";
  position?: {
    __typename?: "Position";
    id: any;
    title: string;
    displayTitle: string;
    greenhouseId?: number | null;
    greenhouseLink?: string | null;
    leverPostingId?: string | null;
    smartrecruitersId?: string | null;
    smartrecruitersLink?: string | null;
    customAtsUrl?: string | null;
    client: { __typename?: "Client"; id: any; name: string };
  } | null;
};

export type PositionQueryVariables = Exact<{
  id: Scalars["ID"];
  includeScores?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PositionQuery = {
  __typename?: "Query";
  position?: {
    __typename?: "Position";
    id: any;
    title: string;
    displayTitle: string;
    createdAt: any;
    greenhouseId?: number | null;
    greenhouseLink?: string | null;
    leverPostingId?: string | null;
    mergeType?: Ats | null;
    client: { __typename?: "Client"; id: any; name: string };
    candidates: Array<{
      __typename?: "Candidate";
      id: any;
      fullName: string;
      firstName: string;
      lastName: string;
      defaultEmail?: string | null;
      defaultPhoneNumber?: string | null;
      rating?: number | null;
      skipRedaction: boolean;
      callCount?: number | null;
      validCallsCount?: number | null;
      recordingDisabled: boolean;
      positionAverageScore?: number | null;
      lastCall?: {
        __typename?: "Call";
        id: any;
        name?: string | null;
        type: CallType;
        startTime?: any | null;
        createdAt: any;
        duration?: number | null;
        status: CallStatus;
        transcriptionStatus?: TranscriptionStatus | null;
        recordingDisabled: boolean;
        rating: number;
        interviewerIds: Array<string>;
        interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
        position?: {
          __typename?: "Position";
          id: any;
          displayTitle: string;
        } | null;
      } | null;
    }>;
    callGuides: Array<{
      __typename?: "CallGuide";
      canEdit: boolean;
      id: any;
      name?: string | null;
      isTemplate: boolean;
      visibility: CallGuideVisibility;
      createdAt: any;
      updatedAt: any;
      greenhouseId?: number | null;
      leverFeedbackTemplateId?: string | null;
      ashbyFeedbackFormId?: string | null;
      smartrecruitersCriteriaId?: string | null;
      atsId?: string | null;
      overallScoringEnabled: boolean;
      position?: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      } | null;
      creator?: { __typename?: "User"; id: any; fullName: string } | null;
      cues: Array<{
        __typename?: "CallGuideCue";
        id: any;
        cue: string;
        description: string;
        isHtmlType: boolean;
        position: number;
        scoringEnabled: boolean;
        type: GuideItemType;
        competencyId?: any | null;
        childItems: Array<{
          __typename?: "CallGuideItemChild";
          id: any;
          description: string;
          position: number;
        }>;
      }>;
      assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
      interviewStage?: {
        __typename?: "InterviewStage";
        id: any;
        title: string;
      } | null;
    }>;
    videos: Array<{ __typename?: "Video"; url: string }>;
  } | null;
};

export type PrimaryCalendarEventsQueryVariables = Exact<{
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startBefore?: InputMaybe<Scalars["DateTime"]>;
}>;

export type PrimaryCalendarEventsQuery = {
  __typename?: "Query";
  primaryCalendarEvents: Array<{
    __typename?: "Event";
    id: any;
    summary?: string | null;
    start: any;
    end: any;
    importExcludeDecision?: boolean | null;
  }>;
};

export type RestApiSetupQueryVariables = Exact<{ [key: string]: never }>;

export type RestApiSetupQuery = {
  __typename?: "Query";
  restApiSetup?: {
    __typename?: "RestApiSetup";
    apiTokens: Array<{
      __typename?: "OrganizationApiToken";
      id: string;
      name?: string | null;
      tokenPrefix?: string | null;
    }>;
    webhooks: Array<{
      __typename?: "WebhookConfiguration";
      id: string;
      webhookUrl?: string | null;
      webhookSecret?: string | null;
      eventInterviewCompleted: boolean;
      eventCandidateUpdatedRecordingDisabled: boolean;
    }>;
  } | null;
};

export type RolePoliciesQueryVariables = Exact<{ [key: string]: never }>;

export type RolePoliciesQuery = {
  __typename?: "Query";
  rolePolicies: {
    __typename?: "RolePolicies";
    basicCanSeeOthersNotes: boolean;
    recruiterCanSeeScores: boolean;
    recruiterCanSeePublicCalls: boolean;
    recruiterCanSeeRestrictedCalls: boolean;
    recruiterCanManageHiringTeams: boolean;
    recruiterCanCreatePositions: boolean;
    recruiterCanSeeOthersNotes: boolean;
    recruiterCanCreateTrainings: boolean;
    recruiterCanManageAllTrainings: boolean;
    recruiterCanShareRecordingsExternally: boolean;
    recruiterCanViewInterviewerQualityReport: boolean;
    recruiterCanConfigureInterviewerQualityReport: boolean;
    recruiterCanDownloadRecordings: boolean;
    recruiterCanManageRedactions: boolean;
    recruiterCanManageCustomTemplates: boolean;
    siteAdminCanDownloadRecordings: boolean;
    hiringManagerCanSeePrivateCalls: boolean;
    hiringManagerCanSeeOthersNotes: boolean;
    hiringManagerCanCreateTrainings: boolean;
    hiringManagerCanShareRecordingsExternally: boolean;
    interviewerCanSeeOthersNotes: boolean;
    memberCanSeePublicCalls: boolean;
    memberCanSeeOthersNotes: boolean;
    defaultSelfSignUpRole: string;
    defaultScheduledInterviewerInviteRole: string;
    defaultHiringTeamRole: string;
    defaultPositionCreateRole: string;
    defaultRecruiterCallVisibility: CallVisibility;
    defaultPlanRole?: string | null;
  };
};

export type ScheduledInterviewerQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ScheduledInterviewerQuery = {
  __typename?: "Query";
  scheduledInterviewer?: {
    __typename?: "ScheduledInterviewer";
    id: string;
    recordingDisabled: boolean;
    scheduledInterview: {
      __typename?: "ScheduledInterview";
      id: string;
      name?: string | null;
    };
  } | null;
};

export type SearchApiKeyQueryVariables = Exact<{ [key: string]: never }>;

export type SearchApiKeyQuery = { __typename?: "Query"; searchApiKey: string };

export type SenseSetupQueryVariables = Exact<{ [key: string]: never }>;

export type SenseSetupQuery = {
  __typename?: "Query";
  senseSetup?: {
    __typename?: "SenseConfiguration";
    enabled: boolean;
    apiKey?: string | null;
  } | null;
};

export type SharedCallGuidesQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type SharedCallGuidesQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      callGuides: Array<{
        __typename?: "CallGuide";
        canEdit: boolean;
        id: any;
        name?: string | null;
        isTemplate: boolean;
        visibility: CallGuideVisibility;
        createdAt: any;
        updatedAt: any;
        greenhouseId?: number | null;
        leverFeedbackTemplateId?: string | null;
        ashbyFeedbackFormId?: string | null;
        smartrecruitersCriteriaId?: string | null;
        atsId?: string | null;
        overallScoringEnabled: boolean;
        position?: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        } | null;
        creator?: { __typename?: "User"; id: any; fullName: string } | null;
        cues: Array<{
          __typename?: "CallGuideCue";
          id: any;
          cue: string;
          description: string;
          isHtmlType: boolean;
          position: number;
          scoringEnabled: boolean;
          type: GuideItemType;
          competencyId?: any | null;
          childItems: Array<{
            __typename?: "CallGuideItemChild";
            id: any;
            description: string;
            position: number;
          }>;
        }>;
        assignedUsers: Array<{ __typename?: "CallGuideUser"; userId: any }>;
        interviewStage?: {
          __typename?: "InterviewStage";
          id: any;
          title: string;
        } | null;
      }>;
    };
  } | null;
};

export type SingleSignOnSetupQueryVariables = Exact<{ [key: string]: never }>;

export type SingleSignOnSetupQuery = {
  __typename?: "Query";
  singleSignOnSetup?: {
    __typename?: "SingleSignOnSetup";
    oktaDomain?: string | null;
    metadataUrl?: string | null;
    scimToken?: string | null;
  } | null;
};

export type SmartrecruitersSetupQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SmartrecruitersSetupQuery = {
  __typename?: "Query";
  smartrecruitersSetup?: {
    __typename?: "SmartrecruitersSetup";
    id: string;
    enabled: boolean;
    valid: boolean;
    authType?: SmartrecruitersAuthType | null;
  } | null;
};

export type TextMessageQueryVariables = Exact<{
  messageSid: Scalars["ID"];
}>;

export type TextMessageQuery = {
  __typename?: "Query";
  textMessage?: {
    __typename?: "TextMessage";
    messageSid: string;
    messageStatus: TextMessageStatus;
  } | null;
};

export type TextMessageTemplateQueryVariables = Exact<{
  templateType: TextMessageTemplateType;
}>;

export type TextMessageTemplateQuery = {
  __typename?: "Query";
  textMessageTemplate?: {
    __typename?: "TextMessageTemplate";
    id?: string | null;
    templateType: TextMessageTemplateType;
    content: string;
  } | null;
};

export type TextMessageTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TextMessageTemplatesQuery = {
  __typename?: "Query";
  textMessageTemplates: Array<{
    __typename?: "TextMessageTemplate";
    id?: string | null;
    templateType: TextMessageTemplateType;
    content: string;
  }>;
};

export type TimezonesQueryVariables = Exact<{ [key: string]: never }>;

export type TimezonesQuery = {
  __typename?: "Query";
  timezones: Array<{
    __typename?: "Timezone";
    name: string;
    abbrev: string;
    utcOffset: string;
    isDst: boolean;
  }>;
};

export type UserQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UserQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    email: string;
    profilePicUrl?: string | null;
    phoneNumber?: string | null;
    phoneNumberExtension?: string | null;
    phoneNumberVerified: boolean;
    isCalendarAuthorized: boolean;
    createdAt: any;
    deletedAt?: any | null;
    signUpMethod: UserSignUpMethod;
    signUpMethodLabel: string;
    signUpCompletedAt?: any | null;
    voipPhoneNumber?: string | null;
    userRole?: { __typename?: "UserRole"; id: any; name: UserRoleName } | null;
    memberships: Array<{
      __typename?: "Membership";
      createdAt: any;
      role: { __typename?: "PositionRole"; id: any };
      position: {
        __typename?: "Position";
        id: any;
        title: string;
        createdAt: any;
        displayTitle: string;
        client: { __typename?: "Client"; id: any; name: string };
      };
      user: { __typename?: "User"; id: any };
    }>;
    invitedBy?: {
      __typename?: "User";
      id: any;
      fullName: string;
      email: string;
    } | null;
  } | null;
};

export type UserZoomSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type UserZoomSettingsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    zoomUserType?: number | null;
    zoomSettings?: any | null;
  } | null;
};

export type VisibilityLevelDescriptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type VisibilityLevelDescriptionsQuery = {
  __typename?: "Query";
  visibilityLevelDescriptions: Array<{
    __typename?: "VisibilityLevelDescription";
    visibilityLevel: CallVisibility;
    description: string;
    userRoleDescription: string;
  }>;
};

export type VisibilityLevelDescriptionFragment = {
  __typename?: "VisibilityLevelDescription";
  visibilityLevel: CallVisibility;
  description: string;
  userRoleDescription: string;
};

export type ZoomHealthQueryVariables = Exact<{ [key: string]: never }>;

export type ZoomHealthQuery = {
  __typename?: "Query";
  badUsers?: Array<{
    __typename?: "OrganizationLocalRecordingFalseUser";
    id: string;
    name: string;
    email: string;
  }> | null;
};

export type ZoomJoinUrlByUuidQueryVariables = Exact<{
  uuid: Scalars["ID"];
}>;

export type ZoomJoinUrlByUuidQuery = {
  __typename?: "Query";
  zoomJoinUrlByUuid?: string | null;
};

export type AiSummaryTemplateQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AiSummaryTemplateQuery = {
  __typename?: "Query";
  aiSummaryTemplate?: {
    __typename?: "CallAiSummaryTemplate";
    id: any;
    name: string;
    isCreatorDefault: boolean;
    customType?: CustomTemplateCustomType | null;
    customPrompt?: string | null;
    model?: string | null;
    visibility: CallAiSummaryTemplateVisibility;
    isCurrentUserDefault: boolean;
    defaultFor: CallAiSummaryTemplateDefaultFor;
    defaultForPersona?: UserPersona | null;
    defaultForKeywords: Array<string>;
    defaultForMeetingType?: CustomTemplateType | null;
    canMakePublic: boolean;
    sections: Array<{
      __typename?: "CallAiSummaryTemplateSection";
      id: any;
      title: string;
      prompt: Array<string>;
    }>;
    creator: { __typename?: "User"; id: any; email: string };
  } | null;
};

export type AnalyticsAlertsQueryVariables = Exact<{
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsAlertsQuery = {
  __typename?: "Query";
  alerts: {
    __typename?: "AlertValues";
    id: string;
    alerts?: Array<{
      __typename?: "Alert";
      id: string;
      organizationId: string;
      message: string;
      alertWeight: number;
      category: string;
      alertType: string;
      alertValence: string;
      segment: string;
      aggregation: string;
      aggregationIds: Array<string>;
      alertOrder: number;
      isNew: boolean;
    }> | null;
  };
};

export type AnalyticsBenchmarksQueryVariables = Exact<{ [key: string]: never }>;

export type AnalyticsBenchmarksQuery = {
  __typename?: "Query";
  benchmarks: {
    __typename?: "BenchmarkValues";
    id: string;
    data: Array<{
      __typename?: "BenchmarkValue";
      id: string;
      metric: MetricName;
      upperBound: number;
      lowerBound: number;
    }>;
  };
};

export type AnalyticsCandidateMotivationCountsQueryVariables = Exact<{
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsCandidateMotivationCountsQuery = {
  __typename?: "Query";
  candidateMotivationCounts?: {
    __typename?: "CandidateMotivationsCountsResult";
    id: string;
    totalCalls: number;
    totalCandidates: number;
    counts: Array<{
      __typename?: "CandidateMotivationCount";
      topic: CandidateMotivationTopic;
      count: number;
    }>;
  } | null;
};

export type AnalyticsCandidateMotivationExamplesQueryVariables = Exact<{
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  motivationTopic?: InputMaybe<CandidateMotivationTopic>;
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsCandidateMotivationExamplesQuery = {
  __typename?: "Query";
  candidateMotivationExamples?: {
    __typename?: "CandidateMotivationExamplesResult";
    id: string;
    data: Array<{
      __typename?: "CandidateMotivationExample";
      exampleId: string;
      callId: string;
      callName?: string | null;
      startTime: any;
      positionId?: string | null;
      positionName?: string | null;
      candidateId: string;
      candidateFullName: string;
      summary: string;
      exampleStartTime: number;
      motivationTopics: string;
    }>;
  } | null;
};

export type AnalyticsCandidateMotivationExamplesCsvQueryVariables = Exact<{
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  motivationTopic?: InputMaybe<CandidateMotivationTopic>;
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsCandidateMotivationExamplesCsvQuery = {
  __typename?: "Query";
  candidateMotivationExamplesCsv?: {
    __typename?: "Csv";
    url?: string | null;
  } | null;
};

export type AnalyticsCandidateMotivationKeyThemesQueryVariables = Exact<{
  motivationTopic?: InputMaybe<CandidateMotivationTopic>;
  predictionIds: Array<Scalars["String"]> | Scalars["String"];
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsCandidateMotivationKeyThemesQuery = {
  __typename?: "Query";
  candidateMotivationKeyThemes?: {
    __typename?: "CandidateKeyThemeResult";
    id: string;
    data: Array<{
      __typename?: "CandidateKeyTheme";
      id: string;
      summary: string;
      themeName: string;
    }>;
  } | null;
};

export type AnalyticsCandidateQuestionKeyThemesQueryVariables = Exact<{
  questionTopic?: InputMaybe<CandidateQuestionTopic>;
  predictionIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsCandidateQuestionKeyThemesQuery = {
  __typename?: "Query";
  candidateQuestionKeyThemes?: {
    __typename?: "CandidateKeyThemeResult";
    id: string;
    data: Array<{
      __typename?: "CandidateKeyTheme";
      id: string;
      summary: string;
      themeName: string;
    }>;
  } | null;
};

export type AnalyticsCandidateQuestionTopicCountsQueryVariables = Exact<{
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsCandidateQuestionTopicCountsQuery = {
  __typename?: "Query";
  candidateQuestionTopicCounts?: {
    __typename?: "CandidateQuestionTopicCountsResult";
    id: string;
    totalCalls: number;
    totalCandidates: number;
    counts: Array<{
      __typename?: "CandidateQuestionTopicCount";
      topic: CandidateQuestionTopic;
      count: number;
    }>;
  } | null;
};

export type AnalyticsCandidateQuestionTopicExamplesQueryVariables = Exact<{
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  questionTopic?: InputMaybe<CandidateQuestionTopic>;
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsCandidateQuestionTopicExamplesQuery = {
  __typename?: "Query";
  candidateQuestionTopicExamples?: {
    __typename?: "CandidateQuestionTopicExamplesResult";
    id: string;
    data: Array<{
      __typename?: "CandidateQuestionTopicExample";
      exampleId: string;
      callId: string;
      callName?: string | null;
      startTime: any;
      positionId?: string | null;
      positionName?: string | null;
      candidateId: string;
      candidateFullName: string;
      exampleText: string;
      exampleStartTime: number;
      exampleTopic: string;
    }>;
  } | null;
};

export type AnalyticsCandidateQuestionTopicExamplesCsvQueryVariables = Exact<{
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  questionTopic?: InputMaybe<CandidateQuestionTopic>;
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsCandidateQuestionTopicExamplesCsvQuery = {
  __typename?: "Query";
  candidateQuestionTopicExamplesCsv?: {
    __typename?: "Csv";
    url?: string | null;
  } | null;
};

export type AnalyticsCompensationTrendsQueryVariables = Exact<{
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  currency?: InputMaybe<Scalars["String"]>;
  rate?: InputMaybe<CompensationExtractionRateType>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsCompensationTrendsQuery = {
  __typename?: "Query";
  compensationTrends?: {
    __typename?: "CompensationTrendsResult";
    id: string;
    totalCallCount: number;
    totalCandidateCount: number;
    data: Array<{
      __typename?: "CompensationTrendsDataPoint";
      callId: string;
      callName?: string | null;
      startTime: any;
      category: CompensationExtractionCategory;
      formattedValue: number;
      exactAmount?: number | null;
      minAmount?: number | null;
      maxAmount?: number | null;
      rate?: string | null;
      currency?: string | null;
      firstSegmentStartTime?: number | null;
    }>;
    currencyOptions: Array<{
      __typename?: "GenericSelectOption";
      label: string;
      value: string;
    }>;
  } | null;
};

export type AnalyticsCompensationTrendsExamplesQueryVariables = Exact<{
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  category?: InputMaybe<CompensationExtractionCategoryType>;
  currency?: InputMaybe<Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsCompensationTrendsExamplesQuery = {
  __typename?: "Query";
  compensationTrendsExamples?: {
    __typename?: "CompensationTrendsExamplesResult";
    id: string;
    totalCallCount: number;
    totalCandidateCount: number;
    data: Array<{
      __typename?: "CompensationTrendsExample";
      compensationExtractionId: string;
      callId: string;
      callName?: string | null;
      startTime: any;
      positionId?: string | null;
      positionName?: string | null;
      candidateId: string;
      candidateFullName: string;
      summary: string;
      category: CompensationExtractionCategory;
      rate?: string | null;
      currency?: string | null;
      minAmount?: number | null;
      maxAmount?: number | null;
      exactAmount?: number | null;
      firstSegmentStartTime: number;
    }>;
  } | null;
};

export type AnalyticsCompensationTrendsExamplesCsvQueryVariables = Exact<{
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  category?: InputMaybe<CompensationExtractionCategoryType>;
  currency?: InputMaybe<Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsCompensationTrendsExamplesCsvQuery = {
  __typename?: "Query";
  compensationTrendsExamplesCsv?: {
    __typename?: "Csv";
    url?: string | null;
  } | null;
};

export type AnalyticsDataQueryVariables = Exact<{
  metric: MetricName;
  primaryDimension: AnalyticsDimension;
  secondaryDimension: AnalyticsDimension;
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  dateRangeStart?: InputMaybe<Scalars["String"]>;
  dateRangeEnd?: InputMaybe<Scalars["String"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsDataQuery = {
  __typename?: "Query";
  analyticsData?: {
    __typename?: "QueryResult";
    id: string;
    data: Array<{
      __typename?: "DataPoint";
      id: string;
      dataId?: string | null;
      label?: string | null;
      segment?: string | null;
      value?: number | null;
      countDataPoints?: number | null;
      avgPerformance?: number | null;
    }>;
  } | null;
};

export type AnalyticsDataCsvQueryVariables = Exact<{
  metric: MetricName;
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  dateRangeStart?: InputMaybe<Scalars["String"]>;
  dateRangeEnd?: InputMaybe<Scalars["String"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsDataCsvQuery = {
  __typename?: "Query";
  analyticsDataCsv?: { __typename?: "Csv"; url?: string | null } | null;
};

export type AnalyticsFilterValuesQueryVariables = Exact<{
  metric?: InputMaybe<MetricName>;
  primaryDimension: AnalyticsDimension;
  secondaryDimension?: InputMaybe<AnalyticsDimension>;
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  dateRangeStart?: InputMaybe<Scalars["String"]>;
  dateRangeEnd?: InputMaybe<Scalars["String"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  forCompensation?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AnalyticsFilterValuesQuery = {
  __typename?: "Query";
  filterValues: {
    __typename?: "FilterValues";
    values: Array<{
      __typename?: "FilterValue";
      id: string;
      value: string;
      label?: string | null;
      count?: number | null;
    }>;
  };
};

export type AnalyticsInterviewStageFilterValuesQueryVariables = Exact<{
  positionIds: Array<Scalars["String"]> | Scalars["String"];
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
}>;

export type AnalyticsInterviewStageFilterValuesQuery = {
  __typename?: "Query";
  interviewStageFilterValues: {
    __typename?: "FilterValues";
    values: Array<{
      __typename?: "FilterValue";
      id: string;
      value: string;
      label?: string | null;
    }>;
  };
};

export type AnalyticsOrgInterviewTotalsQueryVariables = Exact<{
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
}>;

export type AnalyticsOrgInterviewTotalsQuery = {
  __typename?: "Query";
  orgInterviewTotals: {
    __typename?: "InterviewTotalsResult";
    id: string;
    values: {
      __typename?: "InterviewTotals";
      totalInterviews?: number | null;
      totalDuration?: number | null;
      totalInterviewers?: number | null;
    };
  };
};

export type AnalyticsQualityReportConfigQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AnalyticsQualityReportConfigQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      qualityReportConfig: {
        __typename?: "AnalyticsQualityReportConfig";
        agendaSetEnabled: boolean;
        candidateQuestionOpportunityEnabled: boolean;
        candidateTalkRatioEnabled: boolean;
        onTimeStartsEnabled: boolean;
        problematicQuestionsEnabled: boolean;
        longestMonologueEnabled: boolean;
        speakingRateEnabled: boolean;
        interactivityEnabled: boolean;
        questionsAskedEnabled: boolean;
        feedbackSubmissionTimeEnabled: boolean;
        feedbackSubmissionRateEnabled: boolean;
      };
    };
  } | null;
};

export type AnalyticsReportQueryVariables = Exact<{
  primaryDimension: AnalyticsDimension;
  secondaryDimension: AnalyticsDimension;
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsReportQuery = {
  __typename?: "Query";
  qualityReport: {
    __typename?: "ReportQueryResult";
    id: string;
    data: Array<{
      __typename?: "ReportDataPoint";
      id: string;
      organizationId?: string | null;
      xLabel?: string | null;
      totalCalls?: number | null;
      score?: number | null;
      interviewerIds?: Array<string> | null;
      onTimeStarts?: number | null;
      talkRatio?: number | null;
      longestMonologue?: number | null;
      isAgendaSet?: number | null;
      candidateQuestionOpportunity?: number | null;
      problematicQuestions?: number | null;
      speakingRate?: number | null;
      interactivity?: number | null;
      questionsAsked?: number | null;
      feedbackSubmissionTime?: number | null;
      feedbackSubmissionRate?: number | null;
    }>;
    config: Array<{
      __typename?: "ReportConfig";
      name: string;
      enabled: boolean;
    }>;
  };
};

export type AnalyticsReportCsvQueryVariables = Exact<{
  primaryDimension: AnalyticsDimension;
  secondaryDimension: AnalyticsDimension;
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsReportCsvQuery = {
  __typename?: "Query";
  qualityReportCsv?: { __typename?: "Csv"; url?: string | null } | null;
};

export type AnalyticsReportMyInsightsQueryVariables = Exact<{
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  includeRawResults?: InputMaybe<Scalars["Boolean"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsReportMyInsightsQuery = {
  __typename?: "Query";
  qualityReportTrends: {
    __typename?: "ReportTrendsQueryResult";
    id: string;
    bucketSize: number;
    bucketInterval: string;
    data: Array<{
      __typename?: "ReportDataPoint";
      id: string;
      organizationId?: string | null;
      xLabel?: string | null;
      totalCalls?: number | null;
      score?: number | null;
      onTimeStarts?: number | null;
      talkRatio?: number | null;
      longestMonologue?: number | null;
      isAgendaSet?: number | null;
      candidateQuestionOpportunity?: number | null;
      problematicQuestions?: number | null;
      speakingRate?: number | null;
      interactivity?: number | null;
      questionsAsked?: number | null;
      feedbackSubmissionTime?: number | null;
      feedbackSubmissionRate?: number | null;
      onTimeStartsRaw?: number | null;
      talkRatioRaw?: number | null;
      longestMonologueRaw?: number | null;
      isAgendaSetRaw?: number | null;
      candidateQuestionOpportunityRaw?: number | null;
      problematicQuestionsRaw?: number | null;
      speakingRateRaw?: number | null;
      interactivityRaw?: number | null;
      questionsAskedRaw?: number | null;
      feedbackSubmissionTimeRaw?: number | null;
      feedbackSubmissionRateRaw?: number | null;
    }>;
    comparisonData?: Array<{
      __typename?: "ReportDataPoint";
      id: string;
      organizationId?: string | null;
      xLabel?: string | null;
      totalCalls?: number | null;
      score?: number | null;
      onTimeStarts?: number | null;
      talkRatio?: number | null;
      longestMonologue?: number | null;
      isAgendaSet?: number | null;
      candidateQuestionOpportunity?: number | null;
      problematicQuestions?: number | null;
      speakingRate?: number | null;
      interactivity?: number | null;
      questionsAsked?: number | null;
      feedbackSubmissionTime?: number | null;
      feedbackSubmissionRate?: number | null;
      onTimeStartsRaw?: number | null;
      talkRatioRaw?: number | null;
      longestMonologueRaw?: number | null;
      isAgendaSetRaw?: number | null;
      candidateQuestionOpportunityRaw?: number | null;
      problematicQuestionsRaw?: number | null;
      speakingRateRaw?: number | null;
      interactivityRaw?: number | null;
      questionsAskedRaw?: number | null;
      feedbackSubmissionTimeRaw?: number | null;
      feedbackSubmissionRateRaw?: number | null;
    }> | null;
    config: Array<{
      __typename?: "ReportConfig";
      name: string;
      enabled: boolean;
    }>;
  };
};

export type AnalyticsReportRawQueryVariables = Exact<{
  primaryDimension: AnalyticsDimension;
  secondaryDimension: AnalyticsDimension;
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  includeRawResults?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AnalyticsReportRawQuery = {
  __typename?: "Query";
  qualityReport: {
    __typename?: "ReportQueryResult";
    id: string;
    data: Array<{
      __typename?: "ReportDataPoint";
      onTimeStartsRaw?: number | null;
      talkRatioRaw?: number | null;
      longestMonologueRaw?: number | null;
      isAgendaSetRaw?: number | null;
      candidateQuestionOpportunityRaw?: number | null;
      problematicQuestionsRaw?: number | null;
      speakingRateRaw?: number | null;
      interactivityRaw?: number | null;
      questionsAskedRaw?: number | null;
      feedbackSubmissionTimeRaw?: number | null;
      feedbackSubmissionRateRaw?: number | null;
      id: string;
      organizationId?: string | null;
      xLabel?: string | null;
      totalCalls?: number | null;
      score?: number | null;
      interviewerIds?: Array<string> | null;
      onTimeStarts?: number | null;
      talkRatio?: number | null;
      longestMonologue?: number | null;
      isAgendaSet?: number | null;
      candidateQuestionOpportunity?: number | null;
      problematicQuestions?: number | null;
      speakingRate?: number | null;
      interactivity?: number | null;
      questionsAsked?: number | null;
      feedbackSubmissionTime?: number | null;
      feedbackSubmissionRate?: number | null;
    }>;
    config: Array<{
      __typename?: "ReportConfig";
      name: string;
      enabled: boolean;
    }>;
  };
};

export type AnalyticsReportShareableUsersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AnalyticsReportShareableUsersQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      users: Array<{
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      }>;
    };
  } | null;
};

export type AnalyticsReportTrendsQueryVariables = Exact<{
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsReportTrendsQuery = {
  __typename?: "Query";
  qualityReportTrends: {
    __typename?: "ReportTrendsQueryResult";
    id: string;
    bucketSize: number;
    bucketInterval: string;
    data: Array<{
      __typename?: "ReportDataPoint";
      id: string;
      organizationId?: string | null;
      xLabel?: string | null;
      totalCalls?: number | null;
      score?: number | null;
      interviewerIds?: Array<string> | null;
      onTimeStarts?: number | null;
      talkRatio?: number | null;
      longestMonologue?: number | null;
      isAgendaSet?: number | null;
      candidateQuestionOpportunity?: number | null;
      problematicQuestions?: number | null;
      speakingRate?: number | null;
      interactivity?: number | null;
      questionsAsked?: number | null;
      feedbackSubmissionTime?: number | null;
      feedbackSubmissionRate?: number | null;
    }>;
    comparisonData?: Array<{
      __typename?: "ReportDataPoint";
      id: string;
      organizationId?: string | null;
      xLabel?: string | null;
      totalCalls?: number | null;
      score?: number | null;
      interviewerIds?: Array<string> | null;
      onTimeStarts?: number | null;
      talkRatio?: number | null;
      longestMonologue?: number | null;
      isAgendaSet?: number | null;
      candidateQuestionOpportunity?: number | null;
      problematicQuestions?: number | null;
      speakingRate?: number | null;
      interactivity?: number | null;
      questionsAsked?: number | null;
      feedbackSubmissionTime?: number | null;
      feedbackSubmissionRate?: number | null;
    }> | null;
    config: Array<{
      __typename?: "ReportConfig";
      name: string;
      enabled: boolean;
    }>;
  };
};

export type AnalyticsSkillDescriptionQueryVariables = Exact<{
  skillName: Scalars["String"];
}>;

export type AnalyticsSkillDescriptionQuery = {
  __typename?: "Query";
  skillDescription?: {
    __typename?: "SkillDescriptionQueryResult";
    description: string;
  } | null;
};

export type AnalyticsUserLifetimeValuesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
}>;

export type AnalyticsUserLifetimeValuesQuery = {
  __typename?: "Query";
  userInterviewTotals: {
    __typename?: "InterviewTotalsResult";
    id: string;
    values: {
      __typename?: "InterviewTotals";
      totalInterviews?: number | null;
      totalDuration?: number | null;
      totalCandidates?: number | null;
    };
  };
};

export type AtsDataStateQueryVariables = Exact<{ [key: string]: never }>;

export type AtsDataStateQuery = {
  __typename?: "Query";
  atsDataState: AtsDataState;
};

export type ClusteredDataQueryVariables = Exact<{
  topicsOrQuestions: Scalars["String"];
  countBy: Scalars["String"];
  positionDisplayTitle: Scalars["String"];
  organizationId: Scalars["String"];
  dateRangeStart?: InputMaybe<Scalars["String"]>;
  dateRangeEnd?: InputMaybe<Scalars["String"]>;
}>;

export type ClusteredDataQuery = {
  __typename?: "Query";
  clusteredData?: {
    __typename?: "QueryResult";
    id: string;
    data: Array<{
      __typename?: "DataPoint";
      id: string;
      dataId?: string | null;
      label?: string | null;
      segment?: string | null;
      value?: number | null;
      countDataPoints?: number | null;
      avgPerformance?: number | null;
    }>;
  } | null;
};

export type CustomTopicCountsQueryVariables = Exact<{
  keywords: Array<Scalars["String"]> | Scalars["String"];
  matchRule: CustomTopicMatchRule;
  speakerType: AnalyticsTopicSpeakerType;
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type CustomTopicCountsQuery = {
  __typename?: "Query";
  customTopicCounts?: {
    __typename?: "TopicCountsResult";
    id: string;
    bucketInterval: string;
    bucketSize: number;
    data: Array<{
      __typename?: "TopicCounts";
      xLabel: string;
      totalCalls?: number | null;
      topicDiscussed?: number | null;
    }>;
  } | null;
};

export type CustomTopicOccurrencesQueryVariables = Exact<{
  keywords: Array<Scalars["String"]> | Scalars["String"];
  matchRule: CustomTopicMatchRule;
  speakerType: AnalyticsTopicSpeakerType;
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type CustomTopicOccurrencesQuery = {
  __typename?: "Query";
  customTopicOccurrences?: {
    __typename?: "TopicOccurrencesResult";
    id: string;
    data: Array<{
      __typename?: "TopicOccurrences";
      callId: string;
      callName: string;
      callStartTime: string;
      segmentId: string;
      segmentText: string;
      segmentStartTime: number;
      speakerLabel: string;
      speakerType: string;
    }>;
  } | null;
};

export type CustomTopicOccurrencesCsvQueryVariables = Exact<{
  topicName: Scalars["String"];
  keywords: Array<Scalars["String"]> | Scalars["String"];
  matchRule: CustomTopicMatchRule;
  speakerType: AnalyticsTopicSpeakerType;
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
  positions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  interviewers?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  departments?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  stages?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  requestedOrganizationId?: InputMaybe<Scalars["String"]>;
}>;

export type CustomTopicOccurrencesCsvQuery = {
  __typename?: "Query";
  customTopicOccurrencesCsv?: {
    __typename?: "Csv";
    url?: string | null;
  } | null;
};

export type CustomTopicsQueryVariables = Exact<{ [key: string]: never }>;

export type CustomTopicsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      customTopics: Array<{
        __typename?: "AnalyticsCustomTopic";
        id: any;
        name: string;
        keywords: any;
        matchRule: CustomTopicMatchRule;
      }>;
    };
  } | null;
};

export type OrgGenderLabelsQueryVariables = Exact<{ [key: string]: never }>;

export type OrgGenderLabelsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      genderLabelMale?: string | null;
      genderLabelFemale?: string | null;
    };
  } | null;
};

export type OrgReportPositionOptionsQueryVariables = Exact<{
  atsOnly?: InputMaybe<Scalars["Boolean"]>;
  activeOnly?: InputMaybe<Scalars["Boolean"]>;
  pagination?: InputMaybe<OffsetPaginationDetails>;
  dateRangeStart: Scalars["String"];
  dateRangeEnd: Scalars["String"];
}>;

export type OrgReportPositionOptionsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      positions: {
        __typename?: "PaginatedPositions";
        pageInfo?: {
          __typename?: "OffsetPaginationInfo";
          totalPages?: number | null;
          totalRows?: number | null;
        } | null;
        results?: Array<{
          __typename?: "Position";
          id: any;
          title: string;
          createdAt: any;
          displayTitle: string;
          requisitionId?: string | null;
          atsStatus?: string | null;
          greenhouseId?: number | null;
          leverPostingId?: string | null;
          smartrecruitersId?: string | null;
          mergeId?: string | null;
          hasJobPostingDescription: boolean;
          client: { __typename?: "Client"; id: any; name: string };
        }> | null;
      };
    };
  } | null;
};

export type PositionJobDescriptionSkillsQueryVariables = Exact<{
  positionId: Scalars["ID"];
}>;

export type PositionJobDescriptionSkillsQuery = {
  __typename?: "Query";
  positionJobDescriptionSkills?: {
    __typename?: "PositionJobDescriptionSkills";
    skills: Array<{
      __typename?: "GeneratedSkill";
      name: string;
      description: string;
    }>;
  } | null;
};

export type SkillReportCsvQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SkillReportCsvQuery = {
  __typename?: "Query";
  skillReportCsv?: { __typename?: "Csv"; url?: string | null } | null;
};

export type SkillReportQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SkillReportQuery = {
  __typename?: "Query";
  skillReport?: {
    __typename?: "SkillReport";
    id: any;
    title: string;
    source: SkillReportSkillsSource;
    creatorId: any;
    minimumInterviewCountFilter?: number | null;
    interviewStageFilters?: any | null;
    dateRangeStart: any;
    dateRangeEnd: any;
    skills: Array<{
      __typename?: "SkillReportSkill";
      id: any;
      name: string;
      description?: string | null;
    }>;
    positions: Array<{
      __typename?: "Position";
      id: any;
      title: string;
      createdAt: any;
      displayTitle: string;
      requisitionId?: string | null;
      atsStatus?: string | null;
      greenhouseId?: number | null;
      leverPostingId?: string | null;
      smartrecruitersId?: string | null;
      mergeId?: string | null;
      hasJobPostingDescription: boolean;
      client: { __typename?: "Client"; id: any; name: string };
    }>;
    results: Array<{
      __typename?: "SkillReportResultItem";
      candidateId: any;
      candidateName: string;
      numCalls: number;
      coverage?: any | null;
    }>;
  } | null;
};

export type SkillReportStatusQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SkillReportStatusQuery = {
  __typename?: "Query";
  skillReport?: {
    __typename?: "SkillReport";
    id: any;
    processingStatus?: SkillReportProcessingStatus | null;
  } | null;
};

export type SkillReportsQueryVariables = Exact<{ [key: string]: never }>;

export type SkillReportsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      skillReports: Array<{
        __typename?: "SkillReport";
        id: any;
        creatorId: any;
        title: string;
        source: SkillReportSkillsSource;
        createdAt: any;
        dateRangeStart: any;
        dateRangeEnd: any;
        skills: Array<{
          __typename?: "SkillReportSkill";
          name: string;
          description?: string | null;
        }>;
        positions: Array<{
          __typename?: "Position";
          id: any;
          displayTitle: string;
        }>;
      }>;
    };
  } | null;
};

export type CallRecordingDownloadUrlQueryVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type CallRecordingDownloadUrlQuery = {
  __typename?: "Query";
  callRecordingDownloadUrl?: string | null;
};

export type CallThumbnailsQueryVariables = Exact<{
  callIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type CallThumbnailsQuery = {
  __typename?: "Query";
  callThumbnails: Array<{
    __typename?: "CallThumbnail";
    callId: string;
    thumbnailUrl?: string | null;
  }>;
};

export type CallsAiTagsStatusQueryVariables = Exact<{
  callIds: Array<Scalars["ID"]> | Scalars["ID"];
  backfill: Scalars["Boolean"];
}>;

export type CallsAiTagsStatusQuery = {
  __typename?: "Query";
  callsAiTagsStatus: Array<{
    __typename?: "CallAiTagsStatus";
    callId: string;
    tagsProcessingStatus?: TagsProcessingStatus | null;
    canGenerateTags?: boolean | null;
  } | null>;
};

export type IaCallStatusQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type IaCallStatusQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    twilioCallSid?: string | null;
    type: CallType;
    status: CallStatus;
    phoneNumber?: string | null;
    recordingDisabled: boolean;
    recordingPaused: boolean;
    recordingPauseInProgress: boolean;
    startTime?: any | null;
    iaVersion: number;
  } | null;
};

export type NotesForScorecardQueryVariables = Exact<{
  callId: Scalars["ID"];
  currentUserOnly?: InputMaybe<Scalars["Boolean"]>;
  tabUrl?: InputMaybe<Scalars["String"]>;
}>;

export type NotesForScorecardQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    aiNotesLoading: boolean;
    endTime?: any | null;
    aiScorecardCustomSummary?: string | null;
    generalNotes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      text: string;
      time: number;
      type: CallNoteType;
    }>;
    questions: Array<{
      __typename?: "CallNote";
      id: any;
      description?: string | null;
      leverFieldType?: LeverFeedbackTemplateFieldTypes | null;
      isAtsSingleLineInput: boolean;
      canAutofill: boolean;
      questionNotes: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        text: string;
        time: number;
        type: CallNoteType;
      }>;
    }>;
    aiNotesCustomFormat: {
      __typename?: "AiNotesFormat";
      format: CallAiSummaryFormat;
      customTemplateId?: string | null;
      customTemplateType?: CustomTemplateCustomType | null;
    };
    aiScorecardQuestionNotes: Array<{
      __typename?: "AiQuestionNote";
      id: string;
      scorecardQuestion?: string | null;
      aiQuestion: string;
      startTime: number;
      isAtsSingleLineInput: boolean;
      callAiNoteAnswerItems: Array<{
        __typename?: "AiQuestionNoteAnswerItem";
        id: string;
        currentText: string;
      }>;
    }>;
  } | null;
};

export type NotesForScorecardV3QueryVariables = Exact<{
  callId: Scalars["ID"];
  tabUrl?: InputMaybe<Scalars["String"]>;
  scorecardItems?: InputMaybe<
    Array<ExtensionScorecardItem> | ExtensionScorecardItem
  >;
}>;

export type NotesForScorecardV3Query = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    aiNotesLoading: boolean;
    aiScorecardCustomSummary?: string | null;
    notes: Array<{
      __typename?: "CallNote";
      scorecardQuestionId?: any | null;
      id: any;
      callId: any;
      text: string;
      time: number;
      type: CallNoteType;
    }>;
    generalNotes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      text: string;
      time: number;
      type: CallNoteType;
    }>;
    scorecard?: {
      __typename?: "Scorecard";
      id: any;
      questions: Array<{
        __typename?: "ScorecardItem";
        id: string;
        itemPlainText: string;
        leverFieldType?: LeverFeedbackTemplateFieldTypes | null;
        isAtsSingleLineInput: boolean;
        marked?: boolean | null;
        markedTime?: number | null;
        questionNotes: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          text: string;
          time: number;
          type: CallNoteType;
        }>;
      }>;
    } | null;
    draftScorecard?: {
      __typename?: "GreenhouseScorecardV3";
      id: any;
      processingStatus: GreenhouseScorecardV3ProcessingStatus;
      failureReason?: GreenhouseScorecardV3FailureReason | null;
    } | null;
    aiNotesCustomFormat: {
      __typename?: "AiNotesFormat";
      format: CallAiSummaryFormat;
      customTemplateId?: string | null;
      customTemplateType?: CustomTemplateCustomType | null;
    };
    aiScorecardQuestionNotes: Array<{
      __typename?: "AiQuestionNote";
      id: string;
      scorecardQuestion?: string | null;
      aiQuestion: string;
      startTime: number;
      isAtsSingleLineInput: boolean;
      callAiNoteAnswerItems: Array<{
        __typename?: "AiQuestionNoteAnswerItem";
        id: string;
        currentText: string;
      }>;
    }>;
    completedGreenhouseScorecard?: {
      __typename?: "GreenhouseScorecard";
      id: any;
      greenhouseId: number;
    } | null;
  } | null;
};

export type NotePartsFragment = {
  __typename?: "CallNote";
  id: any;
  callId: any;
  text: string;
  time: number;
  type: CallNoteType;
};

export type CallAiNotesQueryVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type CallAiNotesQuery = {
  __typename?: "Query";
  callAiNotes?: {
    __typename?: "CallAiNotes";
    callId: string;
    aiNotesProcessingStatus?: AiNotesProcessingStatus | null;
    questionsAndAnswers: Array<{
      __typename?: "QuestionAndAnswer";
      id: string;
      startTime: number;
      endTime: number;
      segmentIndex: number;
      question: string;
      answer: string;
      originalQuestion?: string | null;
      originalAnswer?: string | null;
      matchReason: string;
      error?: string | null;
      edited?: boolean | null;
      isCandidate: boolean;
      tags?: Array<string | null> | null;
      answerItems: Array<{
        __typename?: "AnswerItem";
        id: string;
        text: string;
        edited: boolean;
      }>;
    }>;
  } | null;
};

export type CallAiSummaryQueryVariables = Exact<{
  callId: Scalars["ID"];
  format: CallAiSummaryFormat;
  customTemplateId?: InputMaybe<Scalars["ID"]>;
}>;

export type CallAiSummaryQuery = {
  __typename?: "Query";
  callAiSummary?: {
    __typename?: "CallAiSummary";
    id: any;
    status: CallAiSummaryProcessingStatus;
    format: CallAiSummaryFormat;
    outputText?: string | null;
    customTemplateId?: any | null;
    processingStartedAt?: any | null;
    targetSpeakerTags: Array<number>;
    customTopics?: Array<{
      __typename?: "CallAiSummaryCustomTopic";
      title: string;
      questions: Array<string>;
    }> | null;
    headers?: Array<{
      __typename?: "CallAiSummaryHeader";
      id: any;
      text: string;
      notes?: Array<{
        __typename?: "CallAiSummaryNote";
        id: any;
        text: string;
        tag?: string | null;
        startTime: number;
        startTimes: Array<number | null>;
        variation1?: string | null;
        variation2?: string | null;
      }> | null;
    }> | null;
  } | null;
};

export type CallAiTopicsQueryVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type CallAiTopicsQuery = {
  __typename?: "Query";
  callAiTopics?: {
    __typename?: "CallAiTopics";
    callId: string;
    aiTopicsStatuses?: Array<{
      __typename?: "CallAiTopicStatus";
      id: string;
      topic: AiTopic;
      status: AiNotesProcessingStatus;
    }> | null;
    topics: Array<{
      __typename?: "TopicSummaries";
      topic: string;
      summary: Array<{
        __typename?: "TopicSummaryItem";
        id: string;
        text: string;
        startTime: number;
      } | null>;
    }>;
  } | null;
};

export type CallAutoSummaryBetaQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CallAutoSummaryBetaQuery = {
  __typename?: "Query";
  call?: { __typename?: "Call"; id: any; autoSummary?: string | null } | null;
};

export type CallBetaQueryVariables = Exact<{
  id: Scalars["ID"];
  redactedRecording?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CallBetaQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    aiNotesSupportedFormats: Array<CallAiSummaryFormat>;
    greenhouseScorecardLink?: string | null;
    greenhouseScorecardLinkWithId?: string | null;
    leverInterviewLink?: string | null;
    leverInterviewLinkWithId?: string | null;
    smartrecruitersInterviewLink?: string | null;
    ashbyFeedbackLinkWithId?: string | null;
    customAtsUrl?: string | null;
    transcriptionStatus?: TranscriptionStatus | null;
    transcriptLanguageCode?: string | null;
    aiNotesProcessingStatus?: AiNotesProcessingStatus | null;
    interviewerIds: Array<string>;
    isProcessing: boolean;
    iaVersion: number;
    duration?: number | null;
    canEdit: boolean;
    canShare: boolean;
    canShareExternal: boolean;
    clipsCount: number;
    type: CallType;
    recordingDisabled: boolean;
    status: CallStatus;
    startTime?: any | null;
    endTime?: any | null;
    name?: string | null;
    rating: number;
    visibility: CallVisibility;
    skipRedaction: boolean;
    canDelete: boolean;
    scoringEnabled: boolean;
    visibilityLevels: Array<CallVisibility>;
    streamableAudio?: { __typename?: "CallMedia"; url: string } | null;
    streamableVideo?: { __typename?: "CallMedia"; url: string } | null;
    aiNotesCustomFormat: {
      __typename?: "AiNotesFormat";
      format: CallAiSummaryFormat;
      customTemplateId?: string | null;
    };
    speakers: Array<{
      __typename?: "CallSpeaker";
      id: any;
      speakerTag: number;
      label: string;
      isCandidate: boolean;
      isInterviewer?: boolean | null;
      identified: boolean;
      talkTimePercentage: number;
      longestMonologue?: number | null;
    }>;
    candidate?: {
      __typename?: "Candidate";
      id: any;
      fullName: string;
      greenhouseLink?: string | null;
      lastLeverOpportunityId?: string | null;
      candidateLinks: Array<{
        __typename?: "CandidateLink";
        id: any;
        link: string;
        type: CandidateLinkType;
      }>;
    } | null;
    position?: {
      __typename?: "Position";
      id: any;
      displayTitle: string;
    } | null;
    interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
    viewers: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      profilePicUrl?: string | null;
      email: string;
    }>;
    externalViewers: Array<{
      __typename?: "ExternalUser";
      id: any;
      email: string;
    }>;
    aiTopicsStatuses?: Array<{
      __typename?: "CallAiTopicStatus";
      id: string;
      topic: AiTopic;
      status: AiNotesProcessingStatus;
    }> | null;
  } | null;
};

export type CallChaptersQueryVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type CallChaptersQuery = {
  __typename?: "Query";
  callChapters?: {
    __typename?: "CallChapters";
    callId: string;
    chapters: Array<{
      __typename?: "Chapter";
      id: string;
      callId: string;
      text: string;
      startTime: number;
      endTime: number;
      thumbnailUrl?: string | null;
      tags?: Array<string> | null;
      questionAndAnswer: {
        __typename?: "QuestionAndAnswer";
        id: string;
        startTime: number;
        endTime: number;
        segmentIndex: number;
        question: string;
        answer: string;
        originalQuestion?: string | null;
        originalAnswer?: string | null;
        matchReason: string;
        error?: string | null;
        edited?: boolean | null;
        isCandidate: boolean;
        answerItems: Array<{
          __typename?: "AnswerItem";
          id: string;
          text: string;
          edited: boolean;
        }>;
      };
    }>;
  } | null;
};

export type CallClipsQueryVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type CallClipsQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    callClips: Array<{
      __typename?: "Clip";
      id: any;
      name?: string | null;
      callId: any;
      startTime: number;
      endTime: number;
      thumbnailImageUrl?: string | null;
      canEdit: boolean;
      canDelete: boolean;
      visibility: CallVisibility;
      visibleVisibilityLevels: Array<CallVisibility>;
    }>;
  } | null;
};

export type CallNotesBetaQueryVariables = Exact<{
  callId: Scalars["ID"];
  notesForCurrentUserOnly: Scalars["Boolean"];
}>;

export type CallNotesBetaQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    questions: Array<{
      __typename?: "CallNote";
      id: any;
      description?: string | null;
      questionNotes: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        time: number;
        highlightStartTime?: number | null;
        highlightEndTime?: number | null;
        speakerTag?: number | null;
        text: string;
        type: CallNoteType;
        description?: string | null;
        isEdited: boolean;
        visibility: CallNoteVisibility;
        guideItemId?: any | null;
        parentGuideItemId?: any | null;
        scorecardQuestionId?: any | null;
        questionId?: any | null;
        createdAt: any;
        updatedAt: any;
        score?: number | null;
        user?: {
          __typename?: "User";
          id: any;
          profilePicUrl?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
        } | null;
        replies: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          clipId?: any | null;
          text: string;
          isEdited: boolean;
          createdAt: any;
          visibility: CallNoteVisibility;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
          } | null;
        }>;
      }>;
    }>;
    generalNotes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type CallQuestionFragment = {
  __typename?: "CallNote";
  id: any;
  description?: string | null;
  questionNotes: Array<{
    __typename?: "CallNote";
    id: any;
    callId: any;
    clipId?: any | null;
    time: number;
    highlightStartTime?: number | null;
    highlightEndTime?: number | null;
    speakerTag?: number | null;
    text: string;
    type: CallNoteType;
    description?: string | null;
    isEdited: boolean;
    visibility: CallNoteVisibility;
    guideItemId?: any | null;
    parentGuideItemId?: any | null;
    scorecardQuestionId?: any | null;
    questionId?: any | null;
    createdAt: any;
    updatedAt: any;
    score?: number | null;
    user?: {
      __typename?: "User";
      id: any;
      profilePicUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
    } | null;
    replies: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      text: string;
      isEdited: boolean;
      createdAt: any;
      visibility: CallNoteVisibility;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      } | null;
    }>;
  }>;
};

export type CallNotesV3QueryVariables = Exact<{
  id: Scalars["ID"];
  notesForCurrentUserOnly: Scalars["Boolean"];
}>;

export type CallNotesV3Query = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    notesScorecards?: Array<{
      __typename?: "NotesScorecard";
      id?: string | null;
      items: Array<{
        __typename?: "NotesScorecardItem";
        id: string;
        type?: GuideItemType | null;
        itemText: string;
        marked?: boolean | null;
        markedTime?: number | null;
        isHtmlType?: boolean | null;
        questionNotes: Array<{
          __typename?: "CallNote";
          updatedAt: any;
          id: any;
          callId: any;
          clipId?: any | null;
          time: number;
          highlightStartTime?: number | null;
          highlightEndTime?: number | null;
          speakerTag?: number | null;
          text: string;
          type: CallNoteType;
          description?: string | null;
          isEdited: boolean;
          visibility: CallNoteVisibility;
          guideItemId?: any | null;
          parentGuideItemId?: any | null;
          scorecardQuestionId?: any | null;
          questionId?: any | null;
          createdAt: any;
          score?: number | null;
          user?: {
            __typename?: "User";
            id: any;
            profilePicUrl?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
          } | null;
          replies: Array<{
            __typename?: "CallNote";
            id: any;
            callId: any;
            clipId?: any | null;
            text: string;
            isEdited: boolean;
            createdAt: any;
            visibility: CallNoteVisibility;
            user?: {
              __typename?: "User";
              id: any;
              firstName?: string | null;
              lastName?: string | null;
              fullName: string;
              profilePicUrl?: string | null;
            } | null;
          }>;
        }>;
        childItems: Array<{
          __typename?: "NotesScorecardItem";
          id: string;
          itemText: string;
          marked?: boolean | null;
          markedTime?: number | null;
          questionNotes: Array<{
            __typename?: "CallNote";
            updatedAt: any;
            id: any;
            callId: any;
            clipId?: any | null;
            time: number;
            highlightStartTime?: number | null;
            highlightEndTime?: number | null;
            speakerTag?: number | null;
            text: string;
            type: CallNoteType;
            description?: string | null;
            isEdited: boolean;
            visibility: CallNoteVisibility;
            guideItemId?: any | null;
            parentGuideItemId?: any | null;
            scorecardQuestionId?: any | null;
            questionId?: any | null;
            createdAt: any;
            score?: number | null;
            user?: {
              __typename?: "User";
              id: any;
              profilePicUrl?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              fullName: string;
            } | null;
            replies: Array<{
              __typename?: "CallNote";
              id: any;
              callId: any;
              clipId?: any | null;
              text: string;
              isEdited: boolean;
              createdAt: any;
              visibility: CallNoteVisibility;
              user?: {
                __typename?: "User";
                id: any;
                firstName?: string | null;
                lastName?: string | null;
                fullName: string;
                profilePicUrl?: string | null;
              } | null;
            }>;
          }>;
        }>;
      }>;
    }> | null;
    notes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
    generalNotes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type ScorecardItemNotesFragment = {
  __typename?: "NotesScorecardItem";
  id: string;
  type?: GuideItemType | null;
  itemText: string;
  marked?: boolean | null;
  markedTime?: number | null;
  isHtmlType?: boolean | null;
  questionNotes: Array<{
    __typename?: "CallNote";
    updatedAt: any;
    id: any;
    callId: any;
    clipId?: any | null;
    time: number;
    highlightStartTime?: number | null;
    highlightEndTime?: number | null;
    speakerTag?: number | null;
    text: string;
    type: CallNoteType;
    description?: string | null;
    isEdited: boolean;
    visibility: CallNoteVisibility;
    guideItemId?: any | null;
    parentGuideItemId?: any | null;
    scorecardQuestionId?: any | null;
    questionId?: any | null;
    createdAt: any;
    score?: number | null;
    user?: {
      __typename?: "User";
      id: any;
      profilePicUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
    } | null;
    replies: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      text: string;
      isEdited: boolean;
      createdAt: any;
      visibility: CallNoteVisibility;
      user?: {
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      } | null;
    }>;
  }>;
  childItems: Array<{
    __typename?: "NotesScorecardItem";
    id: string;
    itemText: string;
    marked?: boolean | null;
    markedTime?: number | null;
    questionNotes: Array<{
      __typename?: "CallNote";
      updatedAt: any;
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
  }>;
};

export type CallScorecardQueryVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type CallScorecardQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    canViewOtherScores: boolean;
    scorecards?: Array<{
      __typename?: "Scorecard";
      id: any;
      updatedAt: any;
      overallScoringEnabled: boolean;
      overallScore?: number | null;
      overallNotes?: string | null;
      interviewer: { __typename?: "User"; id: any; fullName: string };
      competencies: Array<{
        __typename?: "ScorecardItem";
        id: string;
        type?: GuideItemType | null;
        itemText: string;
        marked?: boolean | null;
        markedTime?: number | null;
        scoringEnabled?: boolean | null;
        score?: number | null;
        notes?: string | null;
        updatedAt?: any | null;
      }>;
    }> | null;
    currentUserScorecard?: {
      __typename?: "Scorecard";
      id: any;
      updatedAt: any;
      overallScoringEnabled: boolean;
      overallScore?: number | null;
      overallNotes?: string | null;
      interviewer: { __typename?: "User"; id: any; fullName: string };
      competencies: Array<{
        __typename?: "ScorecardItem";
        id: string;
        type?: GuideItemType | null;
        itemText: string;
        marked?: boolean | null;
        markedTime?: number | null;
        scoringEnabled?: boolean | null;
        score?: number | null;
        notes?: string | null;
        updatedAt?: any | null;
      }>;
    } | null;
    otherUsersScorecards?: Array<{
      __typename?: "Scorecard";
      id: any;
      updatedAt: any;
      overallScoringEnabled: boolean;
      overallScore?: number | null;
      overallNotes?: string | null;
      interviewer: { __typename?: "User"; id: any; fullName: string };
      competencies: Array<{
        __typename?: "ScorecardItem";
        id: string;
        type?: GuideItemType | null;
        itemText: string;
        marked?: boolean | null;
        markedTime?: number | null;
        scoringEnabled?: boolean | null;
        score?: number | null;
        notes?: string | null;
        updatedAt?: any | null;
      }>;
    }> | null;
  } | null;
};

export type CallTranscriptBetaQueryVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type CallTranscriptBetaQuery = {
  __typename?: "Query";
  call?: {
    __typename?: "Call";
    id: any;
    transcript: Array<{
      __typename?: "TranscriptSegment";
      id: any;
      speakerTag: number;
      startTime: number;
      endTime: number;
      languageCode?: string | null;
      words: Array<{
        __typename?: "TranscriptWord";
        word: string;
        startTime: number;
        endTime: number;
        confidence?: number | null;
        isRedacted?: boolean | null;
        rawWord?: string | null;
      }>;
    }>;
    speakers: Array<{ __typename?: "CallSpeaker"; id: any; label: string }>;
  } | null;
};

export type CandidatePositionChaptersQueryVariables = Exact<{
  candidateId: Scalars["ID"];
  positionId: Scalars["ID"];
}>;

export type CandidatePositionChaptersQuery = {
  __typename?: "Query";
  candidatePositionChapters: {
    __typename?: "CandidateChapters";
    candidateChaptersStatus?: ChaptersProcessingStatus | null;
    chapters: Array<{
      __typename?: "Chapter";
      id: string;
      callId: string;
      text: string;
      startTime: number;
      endTime: number;
      thumbnailUrl?: string | null;
      embedding?: Array<number> | null;
      questionEmbedding?: Array<number> | null;
      answerEmbedding?: Array<number> | null;
      chapterTitleEmbedding?: Array<number> | null;
      callName?: string | null;
      callTime?: any | null;
      streamableVideo?: {
        __typename?: "CallMedia";
        url: string;
        height?: number | null;
        width?: number | null;
      } | null;
      streamableAudio?: {
        __typename?: "CallMedia";
        url: string;
        height?: number | null;
        width?: number | null;
      } | null;
      callInterviewers?: Array<{
        __typename?: "User";
        id: any;
        fullName: string;
      }> | null;
      questionAndAnswer: {
        __typename?: "QuestionAndAnswer";
        id: string;
        startTime: number;
        endTime: number;
        segmentIndex: number;
        question: string;
        answer: string;
        originalQuestion?: string | null;
        originalAnswer?: string | null;
        matchReason: string;
        error?: string | null;
        edited?: boolean | null;
        isCandidate: boolean;
        answerItems: Array<{
          __typename?: "AnswerItem";
          id: string;
          text: string;
          edited: boolean;
        }>;
      };
    }>;
  };
};

export type CandidateSummaryQueryVariables = Exact<{
  candidateId: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
  templateId?: InputMaybe<Scalars["ID"]>;
}>;

export type CandidateSummaryQuery = {
  __typename?: "Query";
  candidateSummary?: {
    __typename?: "CandidateSummary";
    id: any;
    status: CandidateSummaryProcessingStatus;
    notes: Array<{
      __typename?: "CandidateSummaryNote";
      id: any;
      text: string;
      topic?: string | null;
      subtopic?: string | null;
      position: number;
      citations: Array<{
        __typename?: "CandidateSummaryCitation";
        id: any;
        callId: string;
        time: number;
      }>;
    }>;
    calls: Array<{ __typename?: "Call"; id: any }>;
  } | null;
};

export type CandidateSummaryCallCountQueryVariables = Exact<{
  candidateId: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
}>;

export type CandidateSummaryCallCountQuery = {
  __typename?: "Query";
  candidateSummaryCallCount?: {
    __typename?: "CandidateSummaryCallCountResponse";
    count: number;
  } | null;
};

export type CandidateSummaryPositionPermissionsQueryVariables = Exact<{
  positionId?: InputMaybe<Scalars["ID"]>;
}>;

export type CandidateSummaryPositionPermissionsQuery = {
  __typename?: "Query";
  candidateSummaryPositionPermissions?: {
    __typename?: "CandidateSummaryPositionPermissions";
    canViewSummaries?: boolean | null;
    canEditTemplates?: boolean | null;
  } | null;
};

export type CandidateSummaryTemplateQueryVariables = Exact<{
  templateId: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
}>;

export type CandidateSummaryTemplateQuery = {
  __typename?: "Query";
  candidateSummaryTemplate?: {
    __typename?: "CandidateSummaryTemplate";
    id: any;
    name: string;
    isOrgDefault: boolean;
    isPositionDefault?: boolean | null;
    processingStatus?: CandidateSummaryTemplateStatus | null;
    creator?: { __typename?: "User"; id: any; fullName: string } | null;
    sections: Array<{
      __typename?: "CandidateSummaryTemplateSection";
      id: any;
      position: number;
      title: string;
      prompt: Array<string | null>;
      subsections: Array<{
        __typename?: "CandidateSummaryTemplateSubsection";
        id: any;
        position: number;
        title: string;
        prompt: Array<string | null>;
      }>;
    }>;
    positions: Array<{
      __typename?: "Position";
      id: any;
      title: string;
      candidateSummaryTemplateId?: string | null;
    }>;
  } | null;
};

export type CandidateSummaryTemplatesQueryVariables = Exact<{
  candidateId: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
}>;

export type CandidateSummaryTemplatesQuery = {
  __typename?: "Query";
  candidateSummaryTemplates?: Array<{
    __typename?: "CandidateSummaryTemplate";
    id: any;
    name: string;
    isOrgDefault: boolean;
    isPositionDefault?: boolean | null;
    processingStatus?: CandidateSummaryTemplateStatus | null;
    creator?: { __typename?: "User"; id: any; fullName: string } | null;
    sections: Array<{
      __typename?: "CandidateSummaryTemplateSection";
      id: any;
      position: number;
      title: string;
      prompt: Array<string | null>;
      subsections: Array<{
        __typename?: "CandidateSummaryTemplateSubsection";
        id: any;
        position: number;
        title: string;
        prompt: Array<string | null>;
      }>;
    }>;
    positions: Array<{
      __typename?: "Position";
      id: any;
      title: string;
      candidateSummaryTemplateId?: string | null;
    }>;
  }> | null;
};

export type UserCandidateSummaryVisibilityQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserCandidateSummaryVisibilityQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    canViewCandidateSummaries: boolean;
    canEditCandidateSummaryTemplate: boolean;
  } | null;
};

export type CandidatesBySearchStringQueryVariables = Exact<{
  searchString: Scalars["String"];
}>;

export type CandidatesBySearchStringQuery = {
  __typename?: "Query";
  candidatesBySearchString?: Array<{
    __typename?: "Candidate";
    id: any;
    fullName: string;
    defaultEmail?: string | null;
    defaultPhoneNumber?: string | null;
  }> | null;
};

export type CandidateScoresQueryVariables = Exact<{
  candidateId: Scalars["ID"];
  positionId?: InputMaybe<Scalars["ID"]>;
}>;

export type CandidateScoresQuery = {
  __typename?: "Query";
  candidate?: {
    __typename?: "Candidate";
    id: any;
    scorecardsByPosition?: Array<{
      __typename?: "Scorecard";
      id: any;
      interviewName?: string | null;
      interviewDate: any;
      overallScoringEnabled: boolean;
      overallScore?: number | null;
      overallNotes?: string | null;
      interviewer: { __typename?: "User"; id: any; fullName: string };
      competencies: Array<{
        __typename?: "ScorecardItem";
        id: string;
        itemText: string;
        score?: number | null;
        notes?: string | null;
      }>;
    }> | null;
  } | null;
};

export type CandidatePositionScorecardFragment = {
  __typename?: "Scorecard";
  id: any;
  interviewName?: string | null;
  interviewDate: any;
  overallScoringEnabled: boolean;
  overallScore?: number | null;
  overallNotes?: string | null;
  interviewer: { __typename?: "User"; id: any; fullName: string };
  competencies: Array<{
    __typename?: "ScorecardItem";
    id: string;
    itemText: string;
    score?: number | null;
    notes?: string | null;
  }>;
};

export type CandidateSearchItemQueryVariables = Exact<{
  candidateId: Scalars["ID"];
}>;

export type CandidateSearchItemQuery = {
  __typename?: "Query";
  candidate?: {
    __typename?: "Candidate";
    id: any;
    fullName: string;
    defaultEmail?: string | null;
    defaultPhoneNumber?: string | null;
  } | null;
};

export type ClipBetaQueryVariables = Exact<{
  clipId: Scalars["ID"];
}>;

export type ClipBetaQuery = {
  __typename?: "Query";
  clip?: {
    __typename?: "Clip";
    id: any;
    name?: string | null;
    displayName: string;
    startTime: number;
    endTime: number;
    duration: number;
    callStartTime?: any | null;
    recordingStatus: ClipRecordingStatus;
    interviewerIds: Array<string>;
    canEdit: boolean;
    canDelete: boolean;
    canShare: boolean;
    canShareExternal: boolean;
    visibility: CallVisibility;
    visibleVisibilityLevels: Array<CallVisibility>;
    streamableAudio?: { __typename?: "CallMedia"; url: string } | null;
    streamableVideo?: { __typename?: "CallMedia"; url: string } | null;
    candidate?: { __typename?: "Candidate"; id: any; fullName: string } | null;
    position?: {
      __typename?: "Position";
      id: any;
      displayTitle: string;
    } | null;
    interviewers: Array<{ __typename?: "User"; id: any; fullName: string }>;
    viewers: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      profilePicUrl?: string | null;
      email: string;
    }>;
    externalViewers: Array<{
      __typename?: "ExternalUser";
      id: any;
      email: string;
    }>;
    speakers: Array<{
      __typename?: "CallSpeaker";
      id: any;
      speakerTag: number;
      label: string;
      isCandidate: boolean;
      isInterviewer?: boolean | null;
      identified: boolean;
      talkTimePercentage: number;
      longestMonologue?: number | null;
    }>;
  } | null;
};

export type ClipNotesQueryVariables = Exact<{
  clipId: Scalars["ID"];
}>;

export type ClipNotesQuery = {
  __typename?: "Query";
  clip?: {
    __typename?: "Clip";
    id: any;
    questions: Array<{
      __typename?: "CallNote";
      id: any;
      description?: string | null;
      questionNotes: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        time: number;
        highlightStartTime?: number | null;
        highlightEndTime?: number | null;
        speakerTag?: number | null;
        text: string;
        type: CallNoteType;
        description?: string | null;
        isEdited: boolean;
        visibility: CallNoteVisibility;
        guideItemId?: any | null;
        parentGuideItemId?: any | null;
        scorecardQuestionId?: any | null;
        questionId?: any | null;
        createdAt: any;
        updatedAt: any;
        score?: number | null;
        user?: {
          __typename?: "User";
          id: any;
          profilePicUrl?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
        } | null;
        replies: Array<{
          __typename?: "CallNote";
          id: any;
          callId: any;
          clipId?: any | null;
          text: string;
          isEdited: boolean;
          createdAt: any;
          visibility: CallNoteVisibility;
          user?: {
            __typename?: "User";
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            fullName: string;
            profilePicUrl?: string | null;
          } | null;
        }>;
      }>;
    }>;
    generalNotes: Array<{
      __typename?: "CallNote";
      id: any;
      callId: any;
      clipId?: any | null;
      time: number;
      highlightStartTime?: number | null;
      highlightEndTime?: number | null;
      speakerTag?: number | null;
      text: string;
      type: CallNoteType;
      description?: string | null;
      isEdited: boolean;
      visibility: CallNoteVisibility;
      guideItemId?: any | null;
      parentGuideItemId?: any | null;
      scorecardQuestionId?: any | null;
      questionId?: any | null;
      createdAt: any;
      updatedAt: any;
      score?: number | null;
      user?: {
        __typename?: "User";
        id: any;
        profilePicUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
      } | null;
      replies: Array<{
        __typename?: "CallNote";
        id: any;
        callId: any;
        clipId?: any | null;
        text: string;
        isEdited: boolean;
        createdAt: any;
        visibility: CallNoteVisibility;
        user?: {
          __typename?: "User";
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type ClipTranscriptBetaQueryVariables = Exact<{
  clipId: Scalars["ID"];
}>;

export type ClipTranscriptBetaQuery = {
  __typename?: "Query";
  clip?: {
    __typename?: "Clip";
    id: any;
    transcript: Array<{
      __typename?: "TranscriptSegment";
      id: any;
      speakerTag: number;
      startTime: number;
      endTime: number;
      languageCode?: string | null;
      words: Array<{
        __typename?: "TranscriptWord";
        word: string;
        startTime: number;
        endTime: number;
        confidence?: number | null;
        isRedacted?: boolean | null;
        rawWord?: string | null;
      }>;
    }>;
  } | null;
};

export type CurrentUserOnboardingStepsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserOnboardingStepsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    signUpCompletedAt?: any | null;
    onboardingSteps: Array<{
      __typename?: "UserOnboardingStep";
      step: OnboardingStep;
      rule: OnboardingStepRule;
      status?: OnboardingStepStatus | null;
    }>;
  } | null;
};

export type CurrentUserSharedWithMeCountsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type CurrentUserSharedWithMeCountsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    callsSharedWithMe: {
      __typename?: "PaginatedCallShares";
      pageInfo?: {
        __typename?: "OffsetPaginationInfo";
        totalRows?: number | null;
      } | null;
    };
    clipsSharedWithMe: {
      __typename?: "PaginatedClipShares";
      pageInfo?: {
        __typename?: "OffsetPaginationInfo";
        totalRows?: number | null;
      } | null;
    };
    playlistsSharedWithMe: {
      __typename?: "PaginatedPlaylistShares";
      pageInfo?: {
        __typename?: "OffsetPaginationInfo";
        totalRows?: number | null;
      } | null;
    };
  } | null;
};

export type CurrentUserSharedWithMeQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
  calls: Scalars["Boolean"];
  clips: Scalars["Boolean"];
  playlists: Scalars["Boolean"];
}>;

export type CurrentUserSharedWithMeQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    callsSharedWithMe?: {
      __typename?: "PaginatedCallShares";
      pageInfo?: {
        __typename?: "OffsetPaginationInfo";
        totalPages?: number | null;
        totalRows?: number | null;
      } | null;
      results?: Array<{
        __typename?: "CallShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        call: {
          __typename?: "Call";
          id: any;
          name?: string | null;
          rating: number;
          type: CallType;
          phoneNumber?: string | null;
          visibility: CallVisibility;
          visibleVisibilityLevels: Array<CallVisibility>;
          canEdit: boolean;
          canDelete: boolean;
          interviewerIds: Array<string>;
          skipRedaction: boolean;
          noRecordingReason?: NoRecordingReason | null;
          isInterviewer: boolean;
          greenhouseScorecardLink?: string | null;
          greenhouseScorecardLinkWithId?: string | null;
          greenhouseUserRating?: string | null;
          leverInterviewLink?: string | null;
          leverInterviewLinkWithId?: string | null;
          startTime?: any | null;
          createdAt: any;
          duration?: number | null;
          status: CallStatus;
          transcriptionStatus?: TranscriptionStatus | null;
          recordingDisabled: boolean;
          thumbnailImageUrl?: string | null;
          recordingProcessingStatus?: CallRecordingProcessingStatus | null;
          thumbnailsProcessingStatus?: CallThumbnailsProcessingStatus | null;
          aiNotesProcessingStatus?: AiNotesProcessingStatus | null;
          myScore?: number | null;
          averageScore?: number | null;
          needsCurrentUserScore?: boolean | null;
          hasPendingScores?: boolean | null;
          interviewers: Array<{
            __typename?: "User";
            id: any;
            fullName: string;
            firstName?: string | null;
            lastName?: string | null;
            profilePicUrl?: string | null;
          }>;
          candidate?: {
            __typename?: "Candidate";
            id: any;
            firstName: string;
            lastName: string;
            fullName: string;
            defaultPhoneNumber?: string | null;
            defaultEmail?: string | null;
            readOnly: boolean;
          } | null;
          position?: {
            __typename?: "Position";
            id: any;
            displayTitle: string;
          } | null;
          recordings: Array<{
            __typename?: "Recording";
            id: any;
            format?: RecordingFormat | null;
          }>;
        };
      }> | null;
    };
    clipsSharedWithMe?: {
      __typename?: "PaginatedClipShares";
      pageInfo?: {
        __typename?: "OffsetPaginationInfo";
        totalPages?: number | null;
        totalRows?: number | null;
      } | null;
      results?: Array<{
        __typename?: "ClipShare";
        id: any;
        createdAt: any;
        updatedAt: any;
        clip: {
          __typename?: "Clip";
          id: any;
          createdAt: any;
          updatedAt: any;
          canEdit: boolean;
          canDelete: boolean;
          startTime: number;
          endTime: number;
          thumbnailImageUrl?: string | null;
          name?: string | null;
          displayName: string;
          userId: any;
          callId: any;
          callName?: string | null;
          duration: number;
          durationFormatted: string;
          recordingReady: boolean;
          visibility: CallVisibility;
          visibleVisibilityLevels: Array<CallVisibility>;
          candidate?: {
            __typename?: "Candidate";
            id: any;
            fullName: string;
          } | null;
          position?: {
            __typename?: "Position";
            id: any;
            title: string;
            displayTitle: string;
          } | null;
        };
      }> | null;
    };
    playlistsSharedWithMe?: {
      __typename?: "PaginatedPlaylistShares";
      pageInfo?: {
        __typename?: "OffsetPaginationInfo";
        totalPages?: number | null;
        totalRows?: number | null;
      } | null;
      results?: Array<{
        __typename?: "PlaylistShare";
        id: any;
        relativeUrl: string;
        createdAt: any;
        playlist: {
          __typename?: "Playlist";
          id: any;
          title: string;
          createdBy: { __typename?: "User"; id: any; fullName: string };
        };
        firstClip: {
          __typename?: "Clip";
          id: any;
          displayName: string;
          duration: number;
          thumbnailImageUrl?: string | null;
        };
      }> | null;
    };
  } | null;
};

export type CurrentUserZoomAppQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserZoomAppQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    isZoomAppInstalled: boolean;
  } | null;
};

export type DataRetentionCountQueryVariables = Exact<{
  enableRetentionSinceCandidateHired?: InputMaybe<Scalars["Boolean"]>;
  retentionDaysSinceCandidateHired?: InputMaybe<Scalars["Int"]>;
  enableRetentionSinceCandidateRejected?: InputMaybe<Scalars["Boolean"]>;
  retentionDaysSinceCandidateRejected?: InputMaybe<Scalars["Int"]>;
  enableRetentionSinceLastInterview: Scalars["Boolean"];
  retentionDaysSinceLastInterview: Scalars["Int"];
  enableRetentionSinceInterview: Scalars["Boolean"];
  retentionDaysSinceInterview: Scalars["Int"];
  preventRedactionForTraining: Scalars["Boolean"];
}>;

export type DataRetentionCountQuery = {
  __typename?: "Query";
  previewCandidatesToRedact?: {
    __typename?: "RedactCandidateQueryResponse";
    totalCandidateCount?: number | null;
  } | null;
  previewCallsToRedact?: {
    __typename?: "RedactCallQueryResponse";
    count?: number | null;
  } | null;
};

export type OrganizationRedactionCandidatesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  candidateStatus?: InputMaybe<CandidateRedactionStatus>;
  sortBy?: InputMaybe<CandidateRedactionSortField>;
  sortDirection?: InputMaybe<SortDirection>;
}>;

export type OrganizationRedactionCandidatesQuery = {
  __typename?: "Query";
  candidatesToRedact?: {
    __typename?: "RedactCandidateQueryResponse";
    totalCandidateCount?: number | null;
    numPages?: number | null;
    candidates?: Array<{
      __typename?: "RedactCandidateItem";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
      hiredAt?: any | null;
      rejectedAt?: any | null;
      lastInterviewAt?: any | null;
      numInterviews?: number | null;
    }> | null;
  } | null;
};

export type RedactionCallListItemFragment = {
  __typename?: "Call";
  id: any;
  name?: string | null;
  startTime?: any | null;
  createdAt: any;
  candidate?: {
    __typename?: "Candidate";
    id: any;
    firstName: string;
    lastName: string;
    fullName: string;
  } | null;
};

export type OrganizationRedactionCallsQueryVariables = Exact<{
  count: Scalars["Int"];
}>;

export type OrganizationRedactionCallsQuery = {
  __typename?: "Query";
  callsToRedact?: {
    __typename?: "RedactCallQueryResponse";
    count?: number | null;
    calls?: Array<{
      __typename?: "Call";
      id: any;
      name?: string | null;
      startTime?: any | null;
      createdAt: any;
      candidate?: {
        __typename?: "Candidate";
        id: any;
        firstName: string;
        lastName: string;
        fullName: string;
      } | null;
    } | null> | null;
  } | null;
};

export type ExternalRecordingTrackerSegmentQueryVariables = Exact<{
  id: Scalars["ID"];
  shareType: Scalars["String"];
  index?: InputMaybe<Scalars["Int"]>;
  trackerKeywordId?: InputMaybe<Scalars["String"]>;
  trackerGroupId?: InputMaybe<Scalars["String"]>;
}>;

export type ExternalRecordingTrackerSegmentQuery = {
  __typename?: "Query";
  externalShareView?: {
    __typename?: "ExternalShareView";
    id: string;
    externalRecording:
      | {
          __typename?: "ExternalCall";
          id: string;
          canEdit: boolean;
          type: CallType;
          speakers: Array<{
            __typename?: "CallSpeaker";
            id: any;
            speakerTag: number;
            label: string;
            isCandidate: boolean;
            isInterviewer?: boolean | null;
            identified: boolean;
            talkTimePercentage: number;
            longestMonologue?: number | null;
          }>;
          poiSegments: Array<{
            __typename?: "TrackerSegment";
            keyword?: string | null;
            segments: Array<{
              __typename?: "TranscriptSegment";
              id: any;
              speakerTag: number;
              startTime: number;
              endTime: number;
              languageCode?: string | null;
              words: Array<{
                __typename?: "TranscriptWord";
                word: string;
                startTime: number;
                endTime: number;
                confidence?: number | null;
                isRedacted?: boolean | null;
                rawWord?: string | null;
              }>;
            }>;
          }>;
        }
      | {
          __typename?: "ExternalClip";
          id: string;
          canEdit: boolean;
          type: CallType;
          speakers: Array<{
            __typename?: "CallSpeaker";
            id: any;
            speakerTag: number;
            label: string;
            isCandidate: boolean;
            isInterviewer?: boolean | null;
            identified: boolean;
            talkTimePercentage: number;
            longestMonologue?: number | null;
          }>;
          poiSegments: Array<{
            __typename?: "TrackerSegment";
            keyword?: string | null;
            segments: Array<{
              __typename?: "TranscriptSegment";
              id: any;
              speakerTag: number;
              startTime: number;
              endTime: number;
              languageCode?: string | null;
              words: Array<{
                __typename?: "TranscriptWord";
                word: string;
                startTime: number;
                endTime: number;
                confidence?: number | null;
                isRedacted?: boolean | null;
                rawWord?: string | null;
              }>;
            }>;
          }>;
        };
  } | null;
};

export type ExternalRecordingTrackersQueryVariables = Exact<{
  id: Scalars["ID"];
  shareType: Scalars["String"];
  index?: InputMaybe<Scalars["Int"]>;
}>;

export type ExternalRecordingTrackersQuery = {
  __typename?: "Query";
  externalShareView?: {
    __typename?: "ExternalShareView";
    id: string;
    externalRecording:
      | {
          __typename?: "ExternalCall";
          id: string;
          speakers: Array<{
            __typename?: "CallSpeaker";
            id: any;
            speakerTag: number;
            label: string;
            isCandidate: boolean;
            isInterviewer?: boolean | null;
            identified: boolean;
            talkTimePercentage: number;
            longestMonologue?: number | null;
          }>;
          pointsOfInterest: Array<{
            __typename?: "TrackerGroup";
            id: any;
            name: string;
            count?: number | null;
            trackerKeywords: Array<{
              __typename?: "TrackerKeyword";
              id: any;
              name: string;
              count?: number | null;
              speakerCounts: Array<{
                __typename?: "TrackerSpeakerCount";
                speakerTag: number;
                count: number;
              }>;
            }>;
          }>;
        }
      | {
          __typename?: "ExternalClip";
          id: string;
          speakers: Array<{
            __typename?: "CallSpeaker";
            id: any;
            speakerTag: number;
            label: string;
            isCandidate: boolean;
            isInterviewer?: boolean | null;
            identified: boolean;
            talkTimePercentage: number;
            longestMonologue?: number | null;
          }>;
          pointsOfInterest: Array<{
            __typename?: "TrackerGroup";
            id: any;
            name: string;
            count?: number | null;
            trackerKeywords: Array<{
              __typename?: "TrackerKeyword";
              id: any;
              name: string;
              count?: number | null;
              speakerCounts: Array<{
                __typename?: "TrackerSpeakerCount";
                speakerTag: number;
                count: number;
              }>;
            }>;
          }>;
        };
  } | null;
};

export type ExternalShareAiNotesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ExternalShareAiNotesQuery = {
  __typename?: "Query";
  externalShareAiNotes?: {
    __typename?: "ExternalCallAiNotes";
    id: string;
    status?: string | null;
    type: string;
    headers: Array<{
      __typename?: "ExternalCallAiNoteHeader";
      id: string;
      text: string;
      startTime?: number | null;
      notes: Array<{
        __typename?: "ExternalCallAiNote";
        id: string;
        text: string;
        startTimes?: Array<number> | null;
      }>;
    }>;
  } | null;
};

export type ExternalShareViewQueryVariables = Exact<{
  id: Scalars["ID"];
  shareType: Scalars["String"];
  index?: InputMaybe<Scalars["Int"]>;
}>;

export type ExternalShareViewQuery = {
  __typename?: "Query";
  externalShareView?: {
    __typename?: "ExternalShareView";
    id: string;
    sharedByEmail: string;
    shareDaysRemaining: number;
    playlistNavigationInfo?: {
      __typename?: "PlaylistNavigationInfo";
      nextClipUrl?: string | null;
      previousClipUrl?: string | null;
      playlist: { __typename?: "Playlist"; title: string; clipCount: number };
    } | null;
    externalRecording:
      | {
          __typename?: "ExternalCall";
          id: string;
          iaVersion: number;
          canEdit: boolean;
          name?: string | null;
          duration: number;
          startTime?: any | null;
          endTime?: any | null;
          transcriptLanguageCode?: string | null;
          type: CallType;
          pointsOfInterest: Array<{
            __typename?: "TrackerGroup";
            id: any;
            name: string;
            count?: number | null;
            trackerKeywords: Array<{
              __typename?: "TrackerKeyword";
              id: any;
              name: string;
              count?: number | null;
              speakerCounts: Array<{
                __typename?: "TrackerSpeakerCount";
                speakerTag: number;
                count: number;
              }>;
            }>;
          }>;
          interviewers: Array<{
            __typename?: "User";
            id: any;
            fullName: string;
          }>;
          speakers: Array<{
            __typename?: "CallSpeaker";
            id: any;
            speakerTag: number;
            label: string;
            isCandidate: boolean;
            isInterviewer?: boolean | null;
            identified: boolean;
            talkTimePercentage: number;
            longestMonologue?: number | null;
          }>;
          streamableAudio?: {
            __typename?: "CallMedia";
            url: string;
            expiration: any;
          } | null;
          streamableVideo?: {
            __typename?: "CallMedia";
            url: string;
            expiration: any;
          } | null;
          transcript: Array<{
            __typename?: "TranscriptSegment";
            id: any;
            speakerTag: number;
            startTime: number;
            endTime: number;
            languageCode?: string | null;
            words: Array<{
              __typename?: "TranscriptWord";
              word: string;
              startTime: number;
              endTime: number;
              confidence?: number | null;
              isRedacted?: boolean | null;
              rawWord?: string | null;
            }>;
          }>;
          candidate?: {
            __typename?: "Candidate";
            id: any;
            fullName: string;
          } | null;
          position?: {
            __typename?: "Position";
            id: any;
            displayTitle: string;
          } | null;
        }
      | {
          __typename?: "ExternalClip";
          id: string;
          iaVersion: number;
          canEdit: boolean;
          name?: string | null;
          duration: number;
          endTime?: any | null;
          type: CallType;
          interviewers: Array<{
            __typename?: "User";
            id: any;
            fullName: string;
          }>;
          speakers: Array<{
            __typename?: "CallSpeaker";
            id: any;
            speakerTag: number;
            label: string;
            isCandidate: boolean;
            isInterviewer?: boolean | null;
            identified: boolean;
            talkTimePercentage: number;
            longestMonologue?: number | null;
          }>;
          streamableAudio?: {
            __typename?: "CallMedia";
            url: string;
            expiration: any;
          } | null;
          streamableVideo?: {
            __typename?: "CallMedia";
            url: string;
            expiration: any;
          } | null;
          transcript: Array<{
            __typename?: "TranscriptSegment";
            id: any;
            speakerTag: number;
            startTime: number;
            endTime: number;
            languageCode?: string | null;
            words: Array<{
              __typename?: "TranscriptWord";
              word: string;
              startTime: number;
              endTime: number;
              confidence?: number | null;
              isRedacted?: boolean | null;
              rawWord?: string | null;
            }>;
          }>;
          candidate?: {
            __typename?: "Candidate";
            id: any;
            fullName: string;
          } | null;
          position?: {
            __typename?: "Position";
            id: any;
            displayTitle: string;
          } | null;
          pointsOfInterest: Array<{
            __typename?: "TrackerGroup";
            id: any;
            name: string;
            count?: number | null;
            trackerKeywords: Array<{
              __typename?: "TrackerKeyword";
              id: any;
              name: string;
              count?: number | null;
              speakerCounts: Array<{
                __typename?: "TrackerSpeakerCount";
                speakerTag: number;
                count: number;
              }>;
            }>;
          }>;
        };
  } | null;
};

export type BotMeetingScheduledInterviewQueryVariables = Exact<{
  zoomMeetingId?: InputMaybe<Scalars["Float"]>;
  meetingUrl?: InputMaybe<Scalars["String"]>;
  botMeetingId?: InputMaybe<Scalars["String"]>;
}>;

export type BotMeetingScheduledInterviewQuery = {
  __typename?: "Query";
  botMeetingScheduledInterview?: {
    __typename?: "ScheduledInterview";
    id: string;
    name?: string | null;
    candidate?: { __typename?: "Candidate"; id: any } | null;
    position?: { __typename?: "Position"; id: any } | null;
    callGuide?: { __typename?: "CallGuide"; id: any } | null;
    scheduledInterviewers: Array<{
      __typename?: "ScheduledInterviewer";
      id: string;
      user?: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
      } | null;
    }>;
  } | null;
};

export type OrgSeatsQueryVariables = Exact<{ [key: string]: never }>;

export type OrgSeatsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      freemiumSeatCount: number;
      filledSeatsInfo: Array<string>;
    };
  } | null;
};

export type OrgZoomNotetakerSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrgZoomNotetakerSettingsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      zoomAppAutoOpenRule?: OrgZoomAppAutoOpenRule | null;
      zoomNotetakerEnabled: boolean;
      sendCancelRecordingChatMessage: boolean;
      zoomNotetakerDisclaimer?: string | null;
      zoomNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
      zoomNotetakerDisplayImageBucketPath?: string | null;
      zoomNotetakerDisplayName?: string | null;
      virtualOnsiteEnabledZoom: boolean;
    };
  } | null;
};

export type OrgZoomSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type OrgZoomSettingsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      zoomSettings: any;
      zoomNotetakerEnabled: boolean;
      automaticallyDeleteZoomRecordings: boolean;
      zoomAppAutoOpenRule?: OrgZoomAppAutoOpenRule | null;
    };
  } | null;
};

export type OrganizationCompetencyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationCompetencyQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      competencies: Array<{
        __typename?: "OrganizationCompetency";
        id: any;
        name: string;
        isMigrated: boolean;
        creatorId?: any | null;
      }>;
    };
  } | null;
};

export type OrganizationDataRedactionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationDataRedactionQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      dataRedactionEnabled: boolean;
      redactEeocFamilyEnabled: boolean;
      redactEeocSexEnabled: boolean;
      redactEeocRaceEnabled: boolean;
      redactEeocHighRiskQuestionsEnabled: boolean;
      redactCompensationEnabled: boolean;
    };
  } | null;
};

export type OrganizationRedactionPredictionsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
  topic: Scalars["String"];
}>;

export type OrganizationRedactionPredictionsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    organization: {
      __typename?: "Organization";
      id: any;
      redactionPredictions: {
        __typename?: "PaginatedRedactionPredictions";
        pageInfo?: {
          __typename?: "OffsetPaginationInfo";
          totalPages?: number | null;
          totalRows?: number | null;
        } | null;
        results?: Array<{
          __typename?: "RedactionPrediction";
          id: any;
          startTime: number;
          endTime: number;
          text: string;
          modelTopic: RedactionModelTopic;
          friendlyTopic: string;
          viewed: boolean;
          speaker?: { __typename?: "CallSpeaker"; label: string } | null;
          call?: {
            __typename?: "Call";
            id: any;
            name?: string | null;
            startTime?: any | null;
          } | null;
        }> | null;
        topicFilters?: Array<{
          __typename?: "RedactionTopicFilter";
          label: string;
          value: string;
        }> | null;
      };
    };
  } | null;
};

export type CurrentUserPlaylistsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationDetails>;
}>;

export type CurrentUserPlaylistsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    playlists: {
      __typename?: "PaginatedPlaylists";
      pageInfo?: {
        __typename?: "OffsetPaginationInfo";
        totalPages?: number | null;
        totalRows?: number | null;
      } | null;
      results?: Array<{
        __typename?: "Playlist";
        id: any;
        title: string;
      }> | null;
    };
  } | null;
};

export type PlaylistSidebarItemFragment = {
  __typename?: "Playlist";
  id: any;
  title: string;
};

export type PlaylistNavigationInfoQueryVariables = Exact<{
  playlistId: Scalars["ID"];
  clipId: Scalars["ID"];
  callId: Scalars["ID"];
}>;

export type PlaylistNavigationInfoQuery = {
  __typename?: "Query";
  playlistNavigationInfo?: {
    __typename?: "PlaylistNavigationInfo";
    nextClipUrl?: string | null;
    previousClipUrl?: string | null;
    currentClipIdx?: number | null;
    playlist: {
      __typename?: "Playlist";
      id: any;
      title: string;
      clipCount: number;
    };
  } | null;
};

export type PlaylistQueryVariables = Exact<{
  playlistId: Scalars["ID"];
}>;

export type PlaylistQuery = {
  __typename?: "Query";
  playlist?: {
    __typename?: "Playlist";
    id: any;
    title: string;
    createdAt: any;
    clipCount: number;
    duration: number;
    clips: Array<{
      __typename?: "Clip";
      id: any;
      callId: any;
      displayName: string;
      thumbnailImageUrl?: string | null;
      duration: number;
      canEdit: boolean;
      startTime: number;
      endTime: number;
      name?: string | null;
    }>;
    call?: { __typename?: "Call"; id: any; name?: string | null } | null;
  } | null;
};

export type PlaylistSharesQueryVariables = Exact<{
  playlistId: Scalars["ID"];
}>;

export type PlaylistSharesQuery = {
  __typename?: "Query";
  playlist?: {
    __typename?: "Playlist";
    id: any;
    shares: Array<{
      __typename?: "PlaylistShare";
      id: any;
      createdAt: any;
      updatedAt: any;
      sharedTo: {
        __typename?: "User";
        id: any;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      };
    }>;
    externalShares: Array<{
      __typename?: "ExternalPlaylistShare";
      id: any;
      createdAt: any;
      updatedAt: any;
      isExpired: boolean;
      daysRemaining: number;
      sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
    }>;
  } | null;
};

export type PlaylistShareFragment = {
  __typename?: "PlaylistShare";
  id: any;
  createdAt: any;
  updatedAt: any;
  sharedTo: {
    __typename?: "User";
    id: any;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    profilePicUrl?: string | null;
  };
};

export type ExternalPlaylistShareFragment = {
  __typename?: "ExternalPlaylistShare";
  id: any;
  createdAt: any;
  updatedAt: any;
  isExpired: boolean;
  daysRemaining: number;
  sharedTo: { __typename?: "ExternalUser"; id: any; email: string };
};

export type TaskDefinitionsQueryVariables = Exact<{
  type: TaskDefinitionType;
}>;

export type TaskDefinitionsQuery = {
  __typename?: "Query";
  taskDefinitions?: Array<{
    __typename?: "TaskDefinition";
    id: any;
    type: TaskDefinitionType;
    configuration: any;
  } | null> | null;
};

export type TaskRunQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TaskRunQuery = {
  __typename?: "Query";
  taskRun?: {
    __typename?: "TaskRun";
    id: any;
    output: any;
    status?: TaskRunStatus | null;
    statusMessage?: string | null;
  } | null;
};

export type CurrentUserCallTrainingQueryVariables = Exact<{
  callId: Scalars["ID"];
  trainingProgramId: Scalars["ID"];
  clipId?: InputMaybe<Scalars["ID"]>;
}>;

export type CurrentUserCallTrainingQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    callTraining?: {
      __typename?: "CallTraining";
      trainingProgramId: string;
      trainingProgramName: string;
      trainingProgramItemId: string;
      lastSubmittedAt?: string | null;
      canSubmit: boolean;
      questions: Array<{
        __typename?: "CallQuestionAnswer";
        questionId: string;
        question: string;
        answer: string;
      }>;
      deletedQuestions: Array<{
        __typename?: "CallQuestionAnswer";
        questionId: string;
        question: string;
        answer: string;
      }>;
    } | null;
  } | null;
};

export type TrainingProgramAddTraineesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TrainingProgramAddTraineesQuery = {
  __typename?: "Query";
  trainingProgram?: {
    __typename?: "TrainingProgram";
    id: any;
    trainableUsers: Array<{
      __typename?: "User";
      id: any;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
    }>;
  } | null;
};

export type TrainingProgramItemPreviewQueryVariables = Exact<{
  callId: Scalars["ID"];
  clipId?: InputMaybe<Scalars["ID"]>;
}>;

export type TrainingProgramItemPreviewQuery = {
  __typename?: "Query";
  trainingProgramItemPreview: {
    __typename?: "TrainingProgramItemPreview";
    id: string;
    description?: string | null;
    name?: string | null;
    order?: number | null;
    isClip: boolean;
    url: string;
    positionDisplayTitle?: string | null;
    createdAt: any;
    contentCreatedAt: any;
    contentRedactedAt?: any | null;
    hasRecordings: boolean;
    audioOnly: boolean;
    duration: number;
    thumbnailImageUrl?: string | null;
    viewed?: boolean | null;
    assessmentCompleted?: boolean | null;
    assessmentOutdated?: boolean | null;
    interviewers: Array<{
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      profilePicUrl?: string | null;
    }>;
  };
};

export type TrainingProgramManagementListItemsQueryVariables = Exact<{
  excludedCallId?: InputMaybe<Scalars["ID"]>;
  excludedClipId?: InputMaybe<Scalars["ID"]>;
  callId?: InputMaybe<Scalars["ID"]>;
  clipId?: InputMaybe<Scalars["ID"]>;
}>;

export type TrainingProgramManagementListItemsQuery = {
  __typename?: "Query";
  traineeCount: number;
  trainingProgramManagementListItems: Array<{
    __typename?: "TrainingProgramManagementListItem";
    id: string;
    createdAt: string;
    name: string;
    owner: string;
    draft: boolean;
    trainees: number;
  }>;
};

export type TrainingProgramQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TrainingProgramQuery = {
  __typename?: "Query";
  trainingProgram?: {
    __typename?: "TrainingProgram";
    id: any;
    name: string;
    description?: string | null;
    updatedAt: any;
    launchedAt?: any | null;
    assessmentEnabled: boolean;
    totalDuration?: number | null;
    trainingProgramQuestions: Array<{
      __typename?: "TrainingProgramQuestion";
      id: any;
      order: number;
      question: string;
      createdAt: any;
      updatedAt: any;
    }>;
    trainingProgramDeletedQuestions: Array<{
      __typename?: "TrainingProgramQuestion";
      id: any;
      order: number;
      question: string;
    }>;
    owner: {
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
    };
    trainingProgramItems: Array<{
      __typename?: "TrainingProgramItem";
      id: string;
      description?: string | null;
      name?: string | null;
      order?: number | null;
      isClip: boolean;
      url: string;
      positionDisplayTitle?: string | null;
      createdAt: any;
      contentCreatedAt: any;
      contentRedactedAt?: any | null;
      hasRecordings: boolean;
      audioOnly: boolean;
      duration: number;
      thumbnailImageUrl?: string | null;
      viewed?: boolean | null;
      assessmentCompleted?: boolean | null;
      assessmentOutdated?: boolean | null;
      interviewers: Array<{
        __typename?: "User";
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        profilePicUrl?: string | null;
      }>;
    }>;
    trainingProgramTrainees: Array<{
      __typename?: "TrainingProgramTrainee";
      id: any;
      createdAt: any;
      user: {
        __typename?: "User";
        id: any;
        fullName: string;
        email: string;
        profilePicUrl?: string | null;
      };
    }>;
  } | null;
};

export type TrainingProgramTraineeListItemsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TrainingProgramTraineeListItemsQuery = {
  __typename?: "Query";
  trainingProgramTraineeListItems: Array<{
    __typename?: "TrainingProgramTraineeListItem";
    id: string;
    name: string;
    completed: boolean;
  }>;
};

export type TrainingProgramTraineeStatusQueryVariables = Exact<{
  trainingProgramId?: InputMaybe<Scalars["ID"]>;
}>;

export type TrainingProgramTraineeStatusQuery = {
  __typename?: "Query";
  trainingProgramTrainees?: Array<{
    __typename?: "TrainingProgramTrainee";
    id: any;
    createdAt: any;
    markedCompletedAt?: any | null;
    trainingProgram: {
      __typename?: "TrainingProgram";
      id: any;
      name: string;
      assessmentEnabled: boolean;
    };
    itemStatuses: Array<{
      __typename?: "TrainingItemStatus";
      itemId: string;
      name?: string | null;
      viewed: boolean;
      url: string;
      contentRedactedAt?: string | null;
      questionsAndAnswers: Array<{
        __typename?: "TrainingItemQuestionAndAnswer";
        questionId: string;
        order: number;
        question: string;
        answer?: string | null;
      }>;
      deletedQuestionsAndAnswers: Array<{
        __typename?: "TrainingItemQuestionAndAnswer";
        questionId: string;
        order: number;
        question: string;
        answer?: string | null;
      }>;
    }>;
    user: {
      __typename?: "User";
      id: any;
      fullName: string;
      lastName?: string | null;
      email: string;
      profilePicUrl?: string | null;
    };
  }> | null;
};

export type TrainingProgramTraineesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TrainingProgramTraineesQuery = {
  __typename?: "Query";
  trainingProgram?: {
    __typename?: "TrainingProgram";
    id: any;
    trainingProgramTrainees: Array<{
      __typename?: "TrainingProgramTrainee";
      id: any;
      createdAt: any;
      markedCompletedAt?: any | null;
      trainingProgramAnswers: Array<{
        __typename?: "TrainingProgramAnswer";
        trainingProgramQuestionId: any;
        trainingProgramItemId: any;
        answer: string;
        createdAt: any;
      }>;
      user: {
        __typename?: "User";
        id: any;
        fullName: string;
        lastName?: string | null;
        email: string;
        profilePicUrl?: string | null;
        trainingProgramItemViews: Array<{
          __typename?: "TrainingProgramItemView";
          id: any;
          questionAnswer?: string | null;
          createdAt: any;
          trainingProgramItem: {
            __typename?: "TrainingProgramItem";
            id: string;
            trainingProgramId: any;
          };
        }>;
      };
    }>;
  } | null;
};

export type InProgressInterviewVirtualOnsiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InProgressInterviewVirtualOnsiteQuery = {
  __typename?: "Query";
  virtualOnsite: {
    __typename?: "VirtualOnsite";
    id: string;
    inProgressScheduledInterview?: {
      __typename?: "ScheduledInterview";
      id: string;
      isInterviewer: boolean;
      inProgressMeeting?: {
        __typename?: "NotetakerMeeting";
        id: string;
        callId?: string | null;
      } | null;
    } | null;
  };
};

export type VirtualOnsiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type VirtualOnsiteQuery = {
  __typename?: "Query";
  virtualOnsite: {
    __typename?: "VirtualOnsite";
    id: string;
    meetingUrl: string;
    nextScheduledInterview?: {
      __typename?: "ScheduledInterview";
      id: string;
    } | null;
    scheduledInterviews: Array<{
      __typename?: "ScheduledInterview";
      id: string;
      name?: string | null;
      isInterviewer: boolean;
      scheduledStart?: any | null;
      scheduledEnd?: any | null;
      scheduledInterviewers: Array<{
        __typename?: "ScheduledInterviewer";
        id: string;
        user?: {
          __typename?: "User";
          id: any;
          profilePicUrl?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      }>;
      inProgressMeeting?: {
        __typename?: "NotetakerMeeting";
        id: string;
        callId?: string | null;
      } | null;
      pendingMeeting?: { __typename?: "NotetakerMeeting"; id: string } | null;
      joiningMeeting?: { __typename?: "NotetakerMeeting"; id: string } | null;
      completedMeeting?: { __typename?: "NotetakerMeeting"; id: string } | null;
    }>;
  };
};

export const ActivityFeedListItemFragmentDoc = gql`
  fragment ActivityFeedListItem on ActivityFeed {
    id
    ownerId
    activity {
      action
      createdAt
      subject {
        id
        fullName
      }
      externalSubject {
        id
        email
      }
      call {
        id
        name
      }
      clip {
        id
        userId
        callId
        name
        displayName
      }
      callNote {
        id
        user {
          id
          firstName
          lastName
          fullName
        }
        highlightStartTime
      }
      callShare {
        id
        sharedBy {
          id
          firstName
          lastName
          fullName
        }
      }
      clipShare {
        id
        sharedBy {
          id
          firstName
          lastName
          fullName
        }
      }
      playlistShare {
        id
        absoluteUrl
        sharedByUser {
          id
          fullName
        }
      }
    }
    createdAt
  }
`;
export const CallAiSummaryTemplateFragmentDoc = gql`
  fragment CallAiSummaryTemplate on CallAiSummaryTemplate {
    id
    name
    isCreatorDefault
    sections {
      id
      title
      prompt
    }
    creator {
      id
      email
    }
    customType
    customPrompt
    model
    visibility
    isCurrentUserDefault
    defaultFor
    defaultForPersona
    defaultForKeywords
    defaultForMeetingType
    canMakePublic
  }
`;
export const CallAiSummaryTemplateListItemFragmentDoc = gql`
  fragment CallAiSummaryTemplateListItem on CallAiSummaryTemplate {
    id
    name
    createdAt
    visibility
    creator {
      id
      fullName
    }
    canEdit
    canDelete
    defaultFor
    defaultForPersona
    defaultForKeywords
    defaultForMeetingType
  }
`;
export const UserAvatarFragmentDoc = gql`
  fragment UserAvatar on User {
    id
    fullName
    firstName
    lastName
    profilePicUrl
  }
`;
export const CallListItemFragmentDoc = gql`
  fragment CallListItem on Call {
    id
    name
    rating
    type
    phoneNumber
    visibility
    visibleVisibilityLevels
    canEdit
    canDelete
    interviewerIds
    skipRedaction
    interviewers {
      ...UserAvatar
    }
    candidate {
      id
      firstName
      lastName
      fullName
      defaultPhoneNumber
      defaultEmail
      readOnly
    }
    position {
      id
      displayTitle
    }
    recordings {
      id
      format
    }
    noRecordingReason
    isInterviewer
    greenhouseScorecardLink
    greenhouseScorecardLinkWithId @client
    greenhouseUserRating
    leverInterviewLink
    leverInterviewLinkWithId @client
    startTime
    createdAt
    duration
    status
    transcriptionStatus
    recordingDisabled
    thumbnailImageUrl
    rating
    recordingProcessingStatus
    thumbnailsProcessingStatus
    aiNotesProcessingStatus
    myScore
    averageScore
    needsCurrentUserScore
    hasPendingScores
  }
  ${UserAvatarFragmentDoc}
`;
export const CallNoteReplyFragmentDoc = gql`
  fragment CallNoteReply on CallNote {
    id
    callId
    clipId
    text
    isEdited
    createdAt
    visibility
    user {
      id
      firstName
      lastName
      fullName
      profilePicUrl
    }
  }
`;
export const CallNoteFragmentDoc = gql`
  fragment CallNote on CallNote {
    id
    user {
      id
      profilePicUrl
      firstName
      lastName
      fullName
    }
    callId
    clipId
    time
    highlightStartTime
    highlightEndTime
    speakerTag
    text
    type
    description
    isEdited
    visibility
    guideItemId
    parentGuideItemId
    scorecardQuestionId
    questionId
    createdAt
    updatedAt
    score
    replies {
      ...CallNoteReply
    }
  }
  ${CallNoteReplyFragmentDoc}
`;
export const CallSpeakerFragmentDoc = gql`
  fragment CallSpeaker on CallSpeaker {
    id
    speakerTag
    label
    isCandidate
    isInterviewer
    identified
    talkTimePercentage
    longestMonologue
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    firstName
    lastName
    fullName
    email
    profilePicUrl
    phoneNumber
    phoneNumberExtension
    phoneNumberVerified
    isCalendarAuthorized
    createdAt
    deletedAt
    invitedBy {
      id
      fullName
      email
    }
    signUpMethod
    signUpMethodLabel
    signUpCompletedAt
    voipPhoneNumber
  }
`;
export const CallFragmentDoc = gql`
  fragment Call on Call {
    id
    name
    type
    status
    visibility
    canEdit
    canDelete
    startTime
    endTime
    createdAt
    duration
    rating
    phoneNumber
    useSafeNumber
    isProcessing
    transcriptionStatus
    recordingDisabled
    greenhouseScorecardLink
    leverInterviewLink
    smartrecruitersInterviewLink
    customAtsUrl
    interviewerIds
    skipRedaction
    interviewers {
      id
      fullName
    }
    candidate {
      id
      firstName
      lastName
      fullName
      greenhouseId
      greenhouseLink
      lastLeverOpportunityId
    }
    position {
      id
      displayTitle
    }
    streamableAudio {
      url
      expiration
    }
    streamableVideo {
      url
      expiration
    }
    notes(currentUserOnly: $notesForCurrentUserOnly) {
      ...CallNote
    }
    speakers {
      ...CallSpeaker
    }
    viewers {
      ...User
    }
    scheduledInterview {
      greenhouseInterviewId
      leverInterviewId
      customAtsUrl
    }
  }
  ${CallNoteFragmentDoc}
  ${CallSpeakerFragmentDoc}
  ${UserFragmentDoc}
`;
export const TranscriptSegmentFragmentDoc = gql`
  fragment TranscriptSegment on TranscriptSegment {
    id
    speakerTag
    startTime
    endTime
    languageCode
    words {
      word
      startTime
      endTime
      confidence
      isRedacted
      rawWord
    }
  }
`;
export const CallTranscriptFragmentDoc = gql`
  fragment CallTranscript on Call {
    ...Call
    organizationId
    canEdit
    transcript {
      ...TranscriptSegment
    }
  }
  ${CallFragmentDoc}
  ${TranscriptSegmentFragmentDoc}
`;
export const CandidateAlertListItemFragmentDoc = gql`
  fragment CandidateAlertListItem on CandidateAlert {
    id
    startTime
    text
    type
    category
    call {
      id
      startTime
    }
    candidate {
      id
      fullName
    }
    position {
      id
      title
    }
  }
`;
export const CandidateAlertFeedListItemFragmentDoc = gql`
  fragment CandidateAlertFeedListItem on CandidateAlertFeed {
    id
    readAt
    accuracyFeedback
    alert {
      ...CandidateAlertListItem
    }
  }
  ${CandidateAlertListItemFragmentDoc}
`;
export const PositionTitleFragmentDoc = gql`
  fragment PositionTitle on Position {
    id
    displayTitle
  }
`;
export const ApplicationFragmentDoc = gql`
  fragment Application on Application {
    id
    candidateId
    positionId
    atsStatus
    smartrecruitersLink
  }
`;
export const CandidateFragmentDoc = gql`
  fragment Candidate on Candidate {
    id
    firstName
    lastName
    fullName
    skipRedaction
    phoneNumbers {
      id
      type
      phoneNumber
    }
    emails
    defaultEmail
    defaultPhoneNumberObj {
      id
      type
      phoneNumber
    }
    rating
    ratingBreakdown {
      rating
      count
    }
    canEdit
    calls {
      id
      positionId
    }
    scheduledInterviews {
      id
      positionId
    }
    positions {
      ...PositionTitle
    }
    greenhouseId
    greenhouseLink
    smartrecruitersLink
    lastLeverOpportunityId
    customAtsUrl
    mergeRemoteId
    mergeType
    candidateLinks {
      id
      source
      link
      type
    }
    lastCall {
      id
      positionId
    }
    recordingDisabled
    pastRecordingDisabledScheduledInterviews {
      id
      scheduledStart
      scheduledInterviewers {
        id
        user {
          id
          fullName
        }
      }
    }
    applications {
      ...Application
    }
  }
  ${PositionTitleFragmentDoc}
  ${ApplicationFragmentDoc}
`;
export const ClipListItemFragmentDoc = gql`
  fragment ClipListItem on Clip {
    id
    createdAt
    updatedAt
    canEdit
    canDelete
    startTime
    endTime
    thumbnailImageUrl
    name
    displayName
    userId
    callId
    callName
    duration
    durationFormatted
    recordingReady
    visibility
    visibleVisibilityLevels
    candidate {
      id
      fullName
    }
    position {
      id
      title
      displayTitle
    }
  }
`;
export const ClipFragmentDoc = gql`
  fragment Clip on Clip {
    id
    callId
    userId
    startTime
    endTime
    recordingStatus
    failureReason
    createdAt
    updatedAt
    visibility
    visibleVisibilityLevels
    thumbnailImageUrl
    canDelete
    canEdit
    name
    displayName
    iaVersion
    interviewerIds
    callStartTime
    callEndTime
    callName
    callRecordingDisabled
    callPhoneNumber
    callType
    streamableAudio {
      url
      expiration
    }
    streamableVideo {
      url
      expiration
    }
    user {
      id
      fullName
    }
    viewers {
      ...User
    }
    callSpeakers {
      ...CallSpeaker
    }
    candidate {
      id
      firstName
      lastName
      fullName
      greenhouseId
      greenhouseLink
      lastLeverOpportunityId
    }
    position {
      id
      displayTitle
    }
  }
  ${UserFragmentDoc}
  ${CallSpeakerFragmentDoc}
`;
export const ClipTranscriptFragmentDoc = gql`
  fragment ClipTranscript on Clip {
    ...Clip
    notes(currentUserOnly: $notesForCurrentUserOnly) {
      ...CallNote
    }
    canEdit
    interviewers {
      id
      firstName
      lastName
      fullName
      email
    }
    transcript {
      ...TranscriptSegment
    }
  }
  ${ClipFragmentDoc}
  ${CallNoteFragmentDoc}
  ${TranscriptSegmentFragmentDoc}
`;
export const OrganizationFeatureAccessFragmentDoc = gql`
  fragment OrganizationFeatureAccess on OrganizationFeatureAccess {
    id
    name
    settingLevel
    isVisible
    showCta
    marketingRedirect
  }
`;
export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    freemium
    phoneNumber
    playCallDisclaimer
    isBullhornAuthorized
    isZoomAuthorized
    greenhouseScorecardAutoDraftRule
    greenhouseScorecardAutoDraftOnboardingRule
    allowsGreenhouseScorecardAutoDraft
    allowUserLevelImportRules
    requiresGreenhouseScorecardAutoDraft
    allowsZoomAppAutoOpen
    requiresZoomAppAutoOpen
    isZoomBotAuthorized
    zoomNotetakerEnabled
    recordingAllowed
    googleMeetDisplayName
    googleMeetDisplayImageBucketPath
    googleMeetEnabled
    virtualOnsiteEnabledGoogleMeet
    googleMeetRecordingDisclaimer
    googleMeetRecordingDisclaimerType
    externalShareDefaultDurationDays
    teamsNotetakerEnabled
    teamsNotetakerDisplayName
    virtualOnsiteEnabledMsTeams
    teamsNotetakerDisclaimer
    teamsNotetakerDisclaimerType
    teamsNotetakerDisplayImageBucketPath
    notetakerRecorderDisclaimerDefault
    audioOnlyViewing
    ratingDescriptions {
      oneStar
      twoStar
      threeStar
      fourStar
      fiveStar
    }
    competencyRatingDescriptions {
      oneStar
      twoStar
      threeStar
      fourStar
      fiveStar
    }
    allowSelfSignUp
    allowPhoneSignIn
    postNotesToAts
    hideOtherInterviewerNotesInExtension
    emailDomains
    inviteScheduledInterviewers
    assignGuidesToInterviewersEnabled
    requireActiveUserToImport
    allowCandidateOptOut
    candidateOptOutUrl
    allowInterviewerOptOut
    dataRedactionEnabled
    dataRedactionPredictionEnabled
    userRoles {
      id
      name
      permissions
      formattedName @client
      formattedPermissions @client
      canAccessPublicCalls @client
      canAccessRestrictedCalls @client
      canAccessPrivateCalls @client
    }
    positionRoles {
      id
      name
      permissions
      formattedName @client
      formattedPermissions @client
      canAccessTeamPublicCalls @client
      canAccessTeamRestrictedCalls @client
      canAccessTeamPrivateCalls @client
    }
    planEnabled
    planUserRoles {
      id
      name
      permissions
      formattedName @client
      formattedPermissions @client
    }
    ats
    showStarRatings
    slackAppInstalled
    allowManualScheduledInterviews
    allowInPersonMeetings
    scoringEnabled
    guideCompetencyScoringEnabledByDefault
    guideOverallScoringEnabledByDefault
    playInPersonRecordingDisclaimer
    includeTimestampsInNoteOutputs
    interviewTypes
    allowEmailSignIn
    textBeforeCallEnabled
    allowAiNotes
    maximumCallDurationForNotetakerToRejoin
    individualVoipNumbersEnabled
    callNameFieldIsDropdown
    atsOptInEnabled
    pasteAiNotesInScorecard
    allowAiCustomTemplates
    interviewEnabled
    defaultPlanJobDescriptionTone
    allowPlanJobDescriptionToneOverride
    callDropdownNames
    askEnabled
    createGuidesFromInterviewPlansEnabled
    featureAccess {
      ...OrganizationFeatureAccess
    }
    disableAnalyticsGenderSegmentation
  }
  ${OrganizationFeatureAccessFragmentDoc}
`;
export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on User {
    id
    internalUserRole
    internalUserRolePermissions
    firstName
    lastName
    fullName
    email
    emailAliases
    profilePicUrl
    persona
    phoneNumber
    phoneNumberExtension
    maskPersonalPhoneNumber
    phoneNumberVerified
    numUnreadAlerts
    voipPhoneNumber
    isImpersonated
    autofillGreenhouseScorecard
    zoomAppAutoOpenEnabled
    organization {
      ...Organization
    }
    defaultCallType
    intercomUserHash
    createdAt
    signUpCompletedAt
    signUpState
    requireCalendarSync
    enabledFeatures
    impersonatedFeatures
    userRole {
      id
      name
      formattedName @client
      permissions
      canManageOrganizationSettings @client
      canManageIntegrationSettings @client
      canManageHiringTeam @client
      canManageUserRoles @client
      canCreatePosition @client
      canDeleteCall @client
      canCreateTrainings @client
      canManageAllTrainings @client
      canDeleteCandidates @client
      canViewInsights @client
      canViewInsightsAlerts @client
      canManageIQReport @client
      canManageCustomTemplates @client
    }
    planUserRole {
      id
      name
      formattedName @client
      permissions
      canManageOrganizationSettings @client
      canManageUserRoles @client
    }
    memberships {
      position {
        id
        title
        displayTitle
      }
      role {
        id
        permissions
        canManageHiringTeam @client
        canCreateTrainings @client
        canViewInsights @client
      }
    }
    browserExtensionVersion
    showJoinWithBrighthire
    callCount
    lastCalledAt
    signUpMethod
    timezoneName
    hasViewedTranscriptionWarning
    interviewRecapSubscription
    interviewPrepSubscription
    weeklyRecapSubscription
    monthlyAnalyticsSubscription
    callShareSubscription
    callNoteSubscription
    morningDigestSubscription
    isZoomAppInstalled
    shouldReauthorizeZoomApp
    trainingProgramsCreatedCount
    trainingProgramsCompletedCount
    showCandidateActionItemAlerts
    isInternationalCallingEnabled
    defaultAiNoteFormat
  }
  ${OrganizationFragmentDoc}
`;
export const EventImportKeywordFragmentDoc = gql`
  fragment EventImportKeyword on EventImportKeyword {
    id
    keywordText
  }
`;
export const EventListItemFragmentDoc = gql`
  fragment EventListItem on Event {
    id
    htmlLink
    summary
    description
    start
    end
    location
    calendar {
      id
      name
    }
    attendees {
      email
      responseStatus
      displayName
    }
  }
`;
export const FeatureFlagFragmentDoc = gql`
  fragment FeatureFlag on FeatureFlag {
    id
    name
    description
    enabled
    updatedAt
  }
`;
export const FreemiumScheduledInterviewListItemFragmentDoc = gql`
  fragment FreemiumScheduledInterviewListItem on ScheduledInterview {
    id
    name
    scheduledStart
    scheduledEnd
    isExcluded
    isImported
    importDecision
    importDecisionReason
    greenhouseLink
    ghJobStageName
    interviewType
    isInterviewer
    recordingDisabled
    scheduledType
    candidate {
      id
      firstName
      lastName
      fullName
      callCount
      defaultPhoneNumber
      recordingDisabled
    }
    position {
      id
      displayTitle
    }
  }
`;
export const UserInfoFragmentDoc = gql`
  fragment UserInfo on User {
    id
    firstName
    lastName
    fullName
    email
    profilePicUrl
    phoneNumber
  }
`;
export const GoogleMeetMeetingParticipantFragmentDoc = gql`
  fragment GoogleMeetMeetingParticipant on GoogleMeetMeetingParticipant {
    name
    profilePicUrl
    user {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`;
export const GoogleMeetMeetingFragmentDoc = gql`
  fragment GoogleMeetMeeting on GoogleMeetMeeting {
    id
    meetingUrl
    recordingStarted
    autoRecordingSetting
    canBeImported
    canNotBeImportedReason
    excludeReason
    participants {
      ...GoogleMeetMeetingParticipant
    }
    call {
      id
      name
    }
  }
  ${GoogleMeetMeetingParticipantFragmentDoc}
`;
export const GreenhouseSetupFragmentDoc = gql`
  fragment GreenhouseSetup on GreenhouseSetup {
    id
    enabled
    apiKey
    host
    adminUserId
    valid
    invalidReason
    webhookSecretKey
    syncCallGuidesFromAts
    atsOptInEnabled
    greenhouseConsentKey
    permissionsStatus {
      permission
      isGranted
    }
  }
`;
export const GreenhouseUserFragmentDoc = gql`
  fragment GreenhouseUser on GreenhouseUser {
    id
    email
    greenhouseId
    siteAdmin
    disabled
  }
`;
export const CurrentCallScorecardItemFragmentDoc = gql`
  fragment CurrentCallScorecardItem on ScorecardItem {
    id
    type
    itemText
    isHtmlType
    scoringEnabled
    score
    notes
    marked
    markedTime
    questionNotes(currentUserOnly: true) @client {
      ...CallNote
    }
    childItems {
      id
      itemText
      isHtmlType
      scoringEnabled
      score
      notes
      marked
      markedTime
      questionNotes(currentUserOnly: true) @client {
        ...CallNote
      }
    }
  }
  ${CallNoteFragmentDoc}
`;
export const CurrentCallScorecardFragmentDoc = gql`
  fragment CurrentCallScorecard on Scorecard {
    id
    items {
      ...CurrentCallScorecardItem
    }
    overallScoringEnabled
    overallScore
    overallNotes
  }
  ${CurrentCallScorecardItemFragmentDoc}
`;
export const IaCallFragmentDoc = gql`
  fragment IACall on Call {
    id
    scorecard {
      ...CurrentCallScorecard
    }
    notes(currentUserOnly: true) {
      ...CallNote
    }
    generalNotes(currentUserOnly: true) @client {
      ...CallNote
    }
    interviewerIds
    duration
    scheduledEndTime
    endTime
    rating
    candidate {
      id
      firstName
      lastName
      fullName
      defaultEmail
      greenhouseLink
      lastLeverOpportunityId
      candidateLinks {
        link
        type
      }
      bullhornId
    }
    phoneNumber
    carrierType
    greenhouseScorecardLink
    greenhouseScorecardLinkWithId @client
    leverInterviewLink
    leverInterviewLinkWithId @client
    ashbyFeedbackLinkWithId
    bullhornCommentAction
    recordingDisabled
    startTime
    type
    callGuide {
      id
    }
    position {
      id
      displayTitle
    }
    useSafeNumber
    virtualOnsiteId
    scheduledInterview {
      id
      scheduledStart
      scheduledEnd
      virtualOnsiteId
      inProgressMeeting {
        id
        callId
      }
      scheduledInterviewers {
        id
        recordingDisabled
        name
        user {
          ...UserInfo
        }
      }
    }
  }
  ${CurrentCallScorecardFragmentDoc}
  ${CallNoteFragmentDoc}
  ${UserInfoFragmentDoc}
`;
export const ImportZoomMeetingFragmentDoc = gql`
  fragment ImportZoomMeeting on ZoomMeeting {
    id
    zoomId
    joinUrl
    importMeeting
    exclude
    excludeReason
    hostId
    callId
  }
`;
export const InterviewStageListItemFragmentDoc = gql`
  fragment InterviewStageListItem on InterviewStage {
    id
    title
    createdAt
  }
`;
export const NotetakerMeetingFragmentDoc = gql`
  fragment NotetakerMeeting on NotetakerMeeting {
    id
    type
    meetingUrl
    callId
  }
`;
export const OrgCallingMetricsFragmentDoc = gql`
  fragment OrgCallingMetrics on Organization {
    id
    users {
      id
      fullName
      callingMetrics(start: $start, end: $end) {
        numSignificantCalls
        totalDuration
        interviewerTalkRatio
        interviewerLongestMonologue
        interviewerWordsPerMinute
        callInteractivity
        weeklyCalls
        weeklyDuration
      }
    }
    callingMetrics(start: $start, end: $end) {
      numSignificantCalls
      totalDuration
      weeklyCalls
      weeklyDuration
    }
  }
`;
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on CursorPaginationInfo {
    hasNextPage
    hasPreviousPage
    nextPageStartId
    nextPageStartTimestamp
  }
`;
export const PlaylistShareListItemFragmentDoc = gql`
  fragment PlaylistShareListItem on PlaylistShare {
    id
    relativeUrl
    createdAt
    playlist {
      id
      title
      createdBy {
        id
        fullName
      }
    }
    firstClip {
      id
      displayName
      duration
      thumbnailImageUrl
    }
  }
`;
export const CandidateListItemFragmentDoc = gql`
  fragment CandidateListItem on Candidate {
    id
    fullName
    firstName
    lastName
    defaultEmail
    defaultPhoneNumber
    rating
    skipRedaction
    lastCall {
      id
      name
      type
      startTime
      createdAt
      duration
      status
      transcriptionStatus
      recordingDisabled
      rating
      interviewerIds
      interviewers {
        id
        fullName
      }
      position {
        id
        displayTitle
      }
    }
    callCount
    validCallsCount
    recordingDisabled
    positionAverageScore @include(if: $includeScores)
  }
`;
export const CallGuideItemChildFragmentDoc = gql`
  fragment CallGuideItemChild on CallGuideItemChild {
    id
    description
    position
  }
`;
export const CallGuideCueFragmentDoc = gql`
  fragment CallGuideCue on CallGuideCue {
    id
    cue
    description
    isHtmlType
    position
    scoringEnabled
    type
    competencyId
    childItems {
      ...CallGuideItemChild
    }
  }
  ${CallGuideItemChildFragmentDoc}
`;
export const InterviewStageItemFragmentDoc = gql`
  fragment InterviewStageItem on InterviewStage {
    id
    title
  }
`;
export const CallGuideListItemFragmentDoc = gql`
  fragment CallGuideListItem on CallGuide {
    canEdit
    id
    name
    isTemplate
    visibility
    createdAt
    updatedAt
    position {
      id
      title
      displayTitle
    }
    creator {
      id
      fullName
    }
    greenhouseId
    leverFeedbackTemplateId
    ashbyFeedbackFormId
    smartrecruitersCriteriaId
    atsId
    cues {
      ...CallGuideCue
    }
    assignedUsers {
      userId
    }
    overallScoringEnabled
    interviewStage {
      ...InterviewStageItem
    }
  }
  ${CallGuideCueFragmentDoc}
  ${InterviewStageItemFragmentDoc}
`;
export const PositionFragmentDoc = gql`
  fragment Position on Position {
    id
    title
    displayTitle
    createdAt
    client {
      id
      name
    }
    candidates {
      ...CandidateListItem
    }
    callGuides {
      ...CallGuideListItem
    }
    greenhouseId
    greenhouseLink
    leverPostingId
    mergeType
    videos {
      url
    }
  }
  ${CandidateListItemFragmentDoc}
  ${CallGuideListItemFragmentDoc}
`;
export const PositionJbpListItemFragmentDoc = gql`
  fragment PositionJBPListItem on Position {
    id
    title
    requisitionId
    locations
    createdAt
    displayTitle
    client {
      id
      name
      autoJoinAllNewPositions
    }
    greenhouseId
    greenhouseLink
    leverPostingId
    autoJoinInterview
    atsStatus
    requisitionId
  }
`;
export const PositionListItemFragmentDoc = gql`
  fragment PositionListItem on Position {
    id
    title
    createdAt
    displayTitle
    client {
      id
      name
    }
  }
`;
export const PositionPageListItemFragmentDoc = gql`
  fragment PositionPageListItem on Position {
    id
    title
    createdAt
    lastCallAt
    displayTitle
    client {
      id
      name
      autoJoinAllNewPositions
    }
    greenhouseId
    greenhouseLink
    leverPostingId
    autoJoinInterview
    atsStatus
    candidatesCount
    callGuidesCount
  }
`;
export const RedactionPredictionFragmentDoc = gql`
  fragment RedactionPrediction on RedactionPrediction {
    id
    startTime
    endTime
    text
    modelTopic
    friendlyTopic
    viewed
    speaker {
      label
    }
    call {
      id
      name
      startTime
    }
  }
`;
export const ScheduledInterviewListItemFragmentDoc = gql`
  fragment ScheduledInterviewListItem on ScheduledInterview {
    id
    name
    scheduledStart
    timezoneName
    scheduledEnd
    durationMinutes
    phoneNumber
    zoomId
    zoomJoinUrl
    zoomMeetingUrl
    zoomAppAutoOpenEnabled
    googleMeetMeetingUrl
    teamsMeetingUrl
    isVideo
    canAddBrighthire
    canAddBrighthireReason
    canLaunchMeeting
    canLaunchMeetingReason
    isExcluded
    isImported
    importDecision
    importDecisionReason
    greenhouseLink
    ghJobStageName
    optInUrl
    leverInterviewId
    smartrecruitersInterviewLink
    isInPersonInterview
    interviewType
    leverStage {
      id
      text
    }
    isInterviewer
    recordingDisabled
    scheduledType
    scheduledInterviewers {
      id
      recordingDisabled
      name
      user {
        ...UserInfo
      }
    }
    callGuide {
      id
    }
    candidate {
      id
      firstName
      lastName
      fullName
      callCount
      defaultPhoneNumber
      recordingDisabled
    }
    position {
      id
      displayTitle
    }
    googleMeetMeetings {
      id
    }
    teamsNotetakerMeetings {
      id
    }
  }
  ${UserInfoFragmentDoc}
`;
export const UserCallingMetricsFragmentDoc = gql`
  fragment UserCallingMetrics on User {
    id
    callingMetrics(start: $start, end: $end) {
      numSignificantCalls
      totalDuration
      interviewerTalkRatio
      interviewerLongestMonologue
      interviewerWordsPerMinute
      callInteractivity
      weeklyCalls
      weeklyDuration
    }
  }
`;
export const ViewableClipFragmentDoc = gql`
  fragment ViewableClip on Clip {
    ...Clip
    notes(currentUserOnly: $notesForCurrentUserOnly) {
      ...CallNote
    }
    canEditNotes
    canShare
    canShareExternal
    hasTraining
    questions {
      id
      description
      time
      questionNotes(clipId: $clipId) {
        ...CallNote
      }
    }
    generalNotes {
      ...CallNote
    }
    interviewers {
      id
      fullName
    }
    greenhouseScorecardLinkWithId @client
    leverInterviewLinkWithId @client
    skipRedaction @client
    rating @client
  }
  ${ClipFragmentDoc}
  ${CallNoteFragmentDoc}
`;
export const VirtualOnsiteFragmentDoc = gql`
  fragment VirtualOnsite on VirtualOnsite {
    id
    meetingUrl
    nextScheduledInterview {
      id
    }
    scheduledInterviews {
      id
      name
      isInterviewer
      scheduledStart
      scheduledEnd
      scheduledInterviewers {
        id
        user {
          id
          profilePicUrl
          firstName
          lastName
        }
      }
      inProgressMeeting {
        id
        callId
      }
      pendingMeeting {
        id
      }
      joiningMeeting {
        id
      }
      completedMeeting {
        id
      }
    }
  }
`;
export const MyInsightsDatumFragmentDoc = gql`
  fragment MyInsightsDatum on ReportDataPoint {
    id
    organizationId
    xLabel
    totalCalls
    score
    onTimeStarts
    talkRatio
    longestMonologue
    isAgendaSet
    candidateQuestionOpportunity
    problematicQuestions
    speakingRate
    interactivity
    questionsAsked
    feedbackSubmissionTime
    feedbackSubmissionRate
    onTimeStartsRaw
    talkRatioRaw
    longestMonologueRaw
    isAgendaSetRaw
    candidateQuestionOpportunityRaw
    problematicQuestionsRaw
    speakingRateRaw
    interactivityRaw
    questionsAskedRaw
    feedbackSubmissionTimeRaw
    feedbackSubmissionRateRaw
  }
`;
export const ReportDatumFragmentDoc = gql`
  fragment ReportDatum on ReportDataPoint {
    id
    organizationId
    xLabel
    totalCalls
    score
    interviewerIds
    onTimeStarts
    talkRatio
    longestMonologue
    isAgendaSet
    candidateQuestionOpportunity
    problematicQuestions
    speakingRate
    interactivity
    questionsAsked
    feedbackSubmissionTime
    feedbackSubmissionRate
  }
`;
export const ReportPositionOptionFragmentDoc = gql`
  fragment ReportPositionOption on Position {
    id
    title
    createdAt
    displayTitle
    requisitionId
    atsStatus
    client {
      id
      name
    }
    greenhouseId
    leverPostingId
    smartrecruitersId
    mergeId
    hasJobPostingDescription
  }
`;
export const SkillsReportWithResultsFragmentDoc = gql`
  fragment SkillsReportWithResults on SkillReport {
    id
    title
    source
    creatorId
    minimumInterviewCountFilter
    interviewStageFilters
    skills {
      id
      name
      description
    }
    dateRangeStart
    dateRangeEnd
    positions {
      ...ReportPositionOption
    }
    results {
      candidateId
      candidateName
      numCalls
      coverage
    }
  }
  ${ReportPositionOptionFragmentDoc}
`;
export const CallCandidateFragmentDoc = gql`
  fragment CallCandidate on Candidate {
    id
    fullName
    greenhouseLink
    lastLeverOpportunityId
    candidateLinks {
      id
      link
      type
    }
  }
`;
export const CallBetaFragmentDoc = gql`
  fragment CallBeta on Call {
    id
    streamableAudio(redacted: $redactedRecording) {
      url
    }
    streamableVideo(redacted: $redactedRecording) {
      url
    }
    aiNotesSupportedFormats
    aiNotesCustomFormat {
      format
      customTemplateId
    }
    speakers {
      ...CallSpeaker
    }
    candidate {
      ...CallCandidate
    }
    position {
      id
      displayTitle
    }
    interviewers {
      id
      fullName
    }
    viewers {
      id
      firstName
      lastName
      fullName
      profilePicUrl
      email
    }
    externalViewers {
      id
      email
    }
    greenhouseScorecardLink
    greenhouseScorecardLinkWithId @client
    leverInterviewLink
    leverInterviewLinkWithId @client
    smartrecruitersInterviewLink
    ashbyFeedbackLinkWithId
    customAtsUrl
    transcriptionStatus
    transcriptLanguageCode
    aiNotesProcessingStatus
    aiTopicsStatuses {
      id
      topic
      status
    }
    interviewerIds
    isProcessing
    iaVersion
    duration
    canEdit
    canShare
    canShareExternal
    clipsCount
    type
    recordingDisabled
    status
    startTime
    endTime
    name
    rating
    visibility
    visibilityLevels: visibleVisibilityLevels
    skipRedaction
    canDelete
    scoringEnabled
  }
  ${CallSpeakerFragmentDoc}
  ${CallCandidateFragmentDoc}
`;
export const CallClipFragmentDoc = gql`
  fragment CallClip on Clip {
    id
    name
    callId
    startTime
    endTime
    thumbnailImageUrl
    canEdit
    canDelete
    visibility
    visibleVisibilityLevels
  }
`;
export const IaCallStatusFragmentDoc = gql`
  fragment IaCallStatus on Call {
    id
    twilioCallSid
    type
    status
    phoneNumber
    recordingDisabled
    recordingPaused
    recordingPauseInProgress
    startTime
    iaVersion
  }
`;
export const InPersonScheduledInterviewFragmentDoc = gql`
  fragment InPersonScheduledInterview on ScheduledInterview {
    id
    name
    candidate {
      id
    }
    position {
      id
    }
    callGuide {
      id
    }
    scheduledInterviewers {
      id
      user {
        id
        fullName
        email
      }
    }
  }
`;
export const CandidateSummaryFragmentDoc = gql`
  fragment CandidateSummary on CandidateSummary {
    id
    status
    notes {
      id
      text
      topic
      subtopic
      position
      citations {
        id
        callId
        time
      }
    }
    calls {
      id
    }
  }
`;
export const CandidateSummaryTemplateFragmentDoc = gql`
  fragment CandidateSummaryTemplate on CandidateSummaryTemplate {
    id
    name
    isOrgDefault
    isPositionDefault(positionId: $positionId)
    processingStatus
    creator {
      id
      fullName
    }
    sections {
      id
      position
      title
      prompt
      subsections {
        id
        position
        title
        prompt
      }
    }
    positions {
      id
      title
      candidateSummaryTemplateId
    }
  }
`;
export const CallHighlightFragmentDoc = gql`
  fragment CallHighlight on CallHighlight {
    id
    thumbnailImageUrl
    startTime
    endTime
    text
    description
    ... on CallNoteHighlight {
      type
    }
    ... on AiNoteHighlight {
      tags
    }
  }
`;
export const CandidateHighlightFragmentDoc = gql`
  fragment CandidateHighlight on Call {
    id
    highlights(currentUserOnly: $currentUserOnly) {
      ...CallHighlight
    }
  }
  ${CallHighlightFragmentDoc}
`;
export const CandidateInterviewFragmentDoc = gql`
  fragment CandidateInterview on Call {
    id
    name
    createdAt
    startTime
    duration
    thumbnailImageUrl
    notes(currentUserOnly: $currentUserOnly) {
      id
      type
    }
    interviewers {
      id
      fullName
    }
    streamableAudio {
      url
    }
    streamableVideo {
      url
      height
      width
    }
    isInterviewer
    greenhouseScorecardLink
    greenhouseScorecardLinkWithId @client
    greenhouseUserRating
    leverInterviewLink
    leverInterviewLinkWithId @client
    canDelete
    positionId
    visibility
    visibleVisibilityLevels
    myScore
    averageScore
    needsCurrentUserScore
    hasPendingScores
  }
`;
export const CandidateSearchResultFragmentDoc = gql`
  fragment CandidateSearchResult on Candidate {
    id
    fullName
    defaultEmail
    defaultPhoneNumber
  }
`;
export const ClipBetaFragmentDoc = gql`
  fragment ClipBeta on Clip {
    id
    streamableAudio {
      url
    }
    streamableVideo {
      url
    }
    candidate {
      id
      fullName
    }
    position {
      id
      displayTitle
    }
    interviewers {
      id
      fullName
    }
    viewers {
      id
      firstName
      lastName
      fullName
      profilePicUrl
      email
    }
    externalViewers {
      id
      email
    }
    speakers: callSpeakers {
      ...CallSpeaker
    }
    name
    displayName
    startTime
    endTime
    duration
    callStartTime
    recordingStatus
    interviewerIds
    canEdit
    canDelete
    canShare
    canShareExternal
    visibility
    visibleVisibilityLevels
  }
  ${CallSpeakerFragmentDoc}
`;
export const TrackerGroupFragmentDoc = gql`
  fragment TrackerGroup on TrackerGroup {
    id
    name
    count
    trackerKeywords {
      id
      name
      count
      speakerCounts {
        speakerTag
        count
      }
    }
  }
`;
export const ExternalCallFragmentDoc = gql`
  fragment ExternalCall on ExternalCall {
    id
    iaVersion
    canEdit
    name
    duration
    startTime
    endTime
    transcriptLanguageCode
    pointsOfInterest {
      ...TrackerGroup
    }
    interviewers {
      id
      fullName
    }
    speakers {
      ...CallSpeaker
    }
    streamableAudio {
      url
      expiration
    }
    streamableVideo {
      url
      expiration
    }
    transcript {
      ...TranscriptSegment
    }
    candidate {
      id
      fullName
    }
    position {
      id
      displayTitle
    }
    type
  }
  ${TrackerGroupFragmentDoc}
  ${CallSpeakerFragmentDoc}
  ${TranscriptSegmentFragmentDoc}
`;
export const ExternalClipFragmentDoc = gql`
  fragment ExternalClip on ExternalClip {
    id
    iaVersion
    canEdit
    name
    duration
    endTime
    interviewers {
      id
      fullName
    }
    speakers {
      ...CallSpeaker
    }
    streamableAudio {
      url
      expiration
    }
    streamableVideo {
      url
      expiration
    }
    transcript {
      ...TranscriptSegment
    }
    candidate {
      id
      fullName
    }
    position {
      id
      displayTitle
    }
    type
    pointsOfInterest {
      ...TrackerGroup
    }
  }
  ${CallSpeakerFragmentDoc}
  ${TranscriptSegmentFragmentDoc}
  ${TrackerGroupFragmentDoc}
`;
export const CallGuideFragmentDoc = gql`
  fragment CallGuide on CallGuide {
    id
    position {
      id
      title
      displayTitle
      client {
        id
        name
      }
    }
    name
    visibility
    isTemplate
    canEdit
    canManageCallGuideAssignments
    createdAt
    updatedAt
    creator {
      id
      fullName
    }
    assignedUsers {
      createdAt
      userId
      user {
        id
        createdAt
        lastName
        fullName
        profilePicUrl
        email
      }
    }
    cues {
      ...CallGuideCue
    }
    greenhouseId
    leverFeedbackTemplateId
    customAtsId
    ashbyFeedbackFormId
    smartrecruitersCriteriaId
    atsId
    overallScoringEnabled
    organization {
      id
      scoringEnabled
    }
    interviewStage {
      ...InterviewStageItem
    }
  }
  ${CallGuideCueFragmentDoc}
  ${InterviewStageItemFragmentDoc}
`;
export const ScorecardItemFragmentDoc = gql`
  fragment ScorecardItem on ScorecardItem {
    id
    type
    itemText
    marked
    markedTime
    scoringEnabled
    score
    notes
    updatedAt
  }
`;
export const InterviewerScorecardFragmentDoc = gql`
  fragment InterviewerScorecard on Scorecard {
    id
    updatedAt
    overallScoringEnabled
    overallScore
    overallNotes
    interviewer {
      id
      fullName
    }
    competencies {
      ...ScorecardItem
    }
  }
  ${ScorecardItemFragmentDoc}
`;
export const ShareUserFragmentDoc = gql`
  fragment ShareUser on User {
    id
    email
    firstName
    lastName
    fullName
    profilePicUrl
  }
`;
export const CallShareFragmentDoc = gql`
  fragment CallShare on CallShare {
    id
    createdAt
    updatedAt
    sharedTo {
      ...ShareUser
    }
  }
  ${ShareUserFragmentDoc}
`;
export const ClipShareFragmentDoc = gql`
  fragment ClipShare on ClipShare {
    id
    createdAt
    updatedAt
    sharedTo {
      ...ShareUser
    }
  }
  ${ShareUserFragmentDoc}
`;
export const ExternalCallShareFragmentDoc = gql`
  fragment ExternalCallShare on ExternalCallShare {
    id
    createdAt
    updatedAt
    isExpired
    shareDaysRemaining
    daysRemaining: shareDaysRemaining
    sharedToExternalUser {
      id
      email
    }
    sharedTo: sharedToExternalUser {
      id
      email
    }
  }
`;
export const ExternalClipShareFragmentDoc = gql`
  fragment ExternalClipShare on ExternalClipShare {
    id
    createdAt
    updatedAt
    isExpired
    shareDaysRemaining
    daysRemaining: shareDaysRemaining
    sharedToExternalUser {
      id
      email
    }
    sharedTo: sharedToExternalUser {
      id
      email
    }
  }
`;
export const TaskDefinitionFragmentDoc = gql`
  fragment TaskDefinition on TaskDefinition {
    id
    type
    configuration
  }
`;
export const TaskRunFragmentDoc = gql`
  fragment TaskRun on TaskRun {
    id
    output
    status
    statusMessage
  }
`;
export const CallTrainingFragmentDoc = gql`
  fragment CallTraining on CallTraining {
    trainingProgramId
    trainingProgramName
    trainingProgramItemId
    lastSubmittedAt
    canSubmit
    questions {
      questionId
      question
      answer
    }
    deletedQuestions {
      questionId
      question
      answer
    }
  }
`;
export const TrainingProgramItemFragmentDoc = gql`
  fragment TrainingProgramItem on TrainingProgramItem {
    id
    description
    name
    order
    isClip
    url
    positionDisplayTitle
    createdAt
    contentCreatedAt
    contentRedactedAt
    hasRecordings
    audioOnly
    duration
    thumbnailImageUrl
    viewed
    assessmentCompleted
    assessmentOutdated
    interviewers {
      id
      firstName
      lastName
      profilePicUrl
    }
  }
`;
export const TrainingProgramFragmentDoc = gql`
  fragment TrainingProgram on TrainingProgram {
    id
    name
    description
    updatedAt
    launchedAt
    assessmentEnabled
    trainingProgramQuestions {
      id
      order
      question
      createdAt
      updatedAt
    }
    trainingProgramDeletedQuestions {
      id
      order
      question
    }
    owner {
      id
      firstName
      lastName
      fullName
      email
      profilePicUrl
    }
    trainingProgramItems {
      ...TrainingProgramItem
    }
    trainingProgramTrainees {
      id
      createdAt
      user {
        id
        fullName
        email
        profilePicUrl
      }
    }
    totalDuration
  }
  ${TrainingProgramItemFragmentDoc}
`;
export const TrainingProgramTraineeFragmentDoc = gql`
  fragment TrainingProgramTrainee on TrainingProgramTrainee {
    id
    createdAt
    markedCompletedAt
    trainingProgramAnswers {
      trainingProgramQuestionId
      trainingProgramItemId
      answer
      createdAt
    }
    user {
      id
      fullName
      lastName
      trainingProgramItemViews(trainingProgramId: $id) {
        id
        questionAnswer
        createdAt
        trainingProgramItem {
          id
          trainingProgramId
        }
      }
    }
  }
`;
export const BrowserExtensionDataFragmentDoc = gql`
  fragment BrowserExtensionData on BrowserExtensionData {
    calls(tabUrl: $tabUrl) {
      id
      iaVersion
    }
    candidate(tabUrl: $tabUrl, email: $email, phone: $phone) {
      id
      fullName
    }
    position(tabUrl: $tabUrl) {
      id
    }
    intent
    ats
  }
`;
export const RecordingVisibleToFragmentDoc = gql`
  fragment RecordingVisibleTo on PaginatedRecordingVisibleTo {
    resultCount
    results {
      id
      role
      user {
        ...ShareUser
      }
    }
  }
  ${ShareUserFragmentDoc}
`;
export const CandidatePageListItemFragmentDoc = gql`
  fragment CandidatePageListItem on Candidate {
    id
    fullName
    defaultPhoneNumber
    callCount
    lastCall {
      id
      name
      createdAt
      position {
        id
        displayTitle
      }
    }
    lastPositionAverageScore @include(if: $includeScores)
  }
`;
export const VisibilityLevelDescriptionFragmentDoc = gql`
  fragment VisibilityLevelDescription on VisibilityLevelDescription {
    visibilityLevel
    description
    userRoleDescription
  }
`;
export const NotePartsFragmentDoc = gql`
  fragment NoteParts on CallNote {
    id
    callId
    text
    time
    type
  }
`;
export const CallQuestionFragmentDoc = gql`
  fragment CallQuestion on CallNote {
    id
    description
    questionNotes(currentUserOnly: $notesForCurrentUserOnly) {
      ...CallNote
    }
  }
  ${CallNoteFragmentDoc}
`;
export const ScorecardItemNotesFragmentDoc = gql`
  fragment ScorecardItemNotes on NotesScorecardItem {
    id
    type
    itemText
    marked
    markedTime
    isHtmlType
    questionNotes(currentUserOnly: $notesForCurrentUserOnly) @client {
      ...CallNote
      updatedAt
    }
    childItems {
      id
      itemText
      marked
      markedTime
      questionNotes(currentUserOnly: $notesForCurrentUserOnly) @client {
        ...CallNote
        updatedAt
      }
    }
  }
  ${CallNoteFragmentDoc}
`;
export const CandidatePositionScorecardFragmentDoc = gql`
  fragment CandidatePositionScorecard on Scorecard {
    id
    interviewName
    interviewDate
    interviewer {
      id
      fullName
    }
    overallScoringEnabled
    overallScore
    overallNotes
    competencies(scoringEnabled: true) {
      id
      itemText
      score
      notes
    }
  }
`;
export const RedactionCallListItemFragmentDoc = gql`
  fragment RedactionCallListItem on Call {
    id
    name
    candidate {
      id
      firstName
      lastName
      fullName
    }
    startTime
    createdAt
  }
`;
export const PlaylistSidebarItemFragmentDoc = gql`
  fragment PlaylistSidebarItem on Playlist {
    id
    title
  }
`;
export const PlaylistShareFragmentDoc = gql`
  fragment PlaylistShare on PlaylistShare {
    id
    createdAt
    updatedAt
    sharedTo: sharedToUser {
      ...ShareUser
    }
  }
  ${ShareUserFragmentDoc}
`;
export const ExternalPlaylistShareFragmentDoc = gql`
  fragment ExternalPlaylistShare on ExternalPlaylistShare {
    id
    createdAt
    updatedAt
    isExpired
    daysRemaining: shareDaysRemaining
    sharedTo: sharedToExternalUser {
      id
      email
    }
  }
`;
export const AddAlertViewDocument = gql`
  mutation AddAlertView(
    $organizationId: ID!
    $type: String!
    $aggregation: String!
    $aggregationIds: [String!]!
    $valence: String!
    $category: String!
    $segment: String!
  ) {
    addAlertView(
      organizationId: $organizationId
      type: $type
      aggregation: $aggregation
      aggregationIds: $aggregationIds
      valence: $valence
      category: $category
      segment: $segment
    ) {
      success
    }
  }
`;

/**
 * __useAddAlertViewMutation__
 *
 * To run a mutation, you first call `useAddAlertViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAlertViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAlertViewMutation, { data, loading, error }] = useAddAlertViewMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      type: // value for 'type'
 *      aggregation: // value for 'aggregation'
 *      aggregationIds: // value for 'aggregationIds'
 *      valence: // value for 'valence'
 *      category: // value for 'category'
 *      segment: // value for 'segment'
 *   },
 * });
 */
export function useAddAlertViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAlertViewMutation,
    AddAlertViewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAlertViewMutation,
    AddAlertViewMutationVariables
  >(AddAlertViewDocument, options);
}
export type AddAlertViewMutationHookResult = ReturnType<
  typeof useAddAlertViewMutation
>;
export type AddAlertViewMutationResult =
  Apollo.MutationResult<AddAlertViewMutation>;
export type AddAlertViewMutationOptions = Apollo.BaseMutationOptions<
  AddAlertViewMutation,
  AddAlertViewMutationVariables
>;
export const AddBrightHireToInterviewsDocument = gql`
  mutation AddBrightHireToInterviews(
    $scheduledInterviewIds: [ID!]!
    $source: String!
  ) {
    addBrightHireToInterviews(
      scheduledInterviewIds: $scheduledInterviewIds
      source: $source
    ) {
      scheduledInterviews {
        ...ScheduledInterviewListItem
      }
      errors {
        id
        message
      }
      source
    }
  }
  ${ScheduledInterviewListItemFragmentDoc}
`;

/**
 * __useAddBrightHireToInterviewsMutation__
 *
 * To run a mutation, you first call `useAddBrightHireToInterviewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBrightHireToInterviewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBrightHireToInterviewsMutation, { data, loading, error }] = useAddBrightHireToInterviewsMutation({
 *   variables: {
 *      scheduledInterviewIds: // value for 'scheduledInterviewIds'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useAddBrightHireToInterviewsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBrightHireToInterviewsMutation,
    AddBrightHireToInterviewsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddBrightHireToInterviewsMutation,
    AddBrightHireToInterviewsMutationVariables
  >(AddBrightHireToInterviewsDocument, options);
}
export type AddBrightHireToInterviewsMutationHookResult = ReturnType<
  typeof useAddBrightHireToInterviewsMutation
>;
export type AddBrightHireToInterviewsMutationResult =
  Apollo.MutationResult<AddBrightHireToInterviewsMutation>;
export type AddBrightHireToInterviewsMutationOptions =
  Apollo.BaseMutationOptions<
    AddBrightHireToInterviewsMutation,
    AddBrightHireToInterviewsMutationVariables
  >;
export const AddBullhornCredentialsDocument = gql`
  mutation AddBullhornCredentials(
    $clientId: String!
    $clientSecret: String!
    $username: String!
    $password: String!
  ) {
    addBullhornCredentials(
      clientId: $clientId
      clientSecret: $clientSecret
      username: $username
      password: $password
    ) {
      authUrl
      currentUser {
        organization {
          isBullhornAuthorized
        }
      }
    }
  }
`;

/**
 * __useAddBullhornCredentialsMutation__
 *
 * To run a mutation, you first call `useAddBullhornCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBullhornCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBullhornCredentialsMutation, { data, loading, error }] = useAddBullhornCredentialsMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      clientSecret: // value for 'clientSecret'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAddBullhornCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBullhornCredentialsMutation,
    AddBullhornCredentialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddBullhornCredentialsMutation,
    AddBullhornCredentialsMutationVariables
  >(AddBullhornCredentialsDocument, options);
}
export type AddBullhornCredentialsMutationHookResult = ReturnType<
  typeof useAddBullhornCredentialsMutation
>;
export type AddBullhornCredentialsMutationResult =
  Apollo.MutationResult<AddBullhornCredentialsMutation>;
export type AddBullhornCredentialsMutationOptions = Apollo.BaseMutationOptions<
  AddBullhornCredentialsMutation,
  AddBullhornCredentialsMutationVariables
>;
export const AddCallDocument = gql`
  mutation AddCall(
    $name: String
    $phoneNumber: String!
    $candidateFirstName: String
    $candidateLastName: String
    $candidateEmail: String
    $candidateId: ID
    $callType: String!
    $callGuideId: ID
    $positionId: ID
    $greenhouseScorecardLink: String
    $bullhornCommentAction: String
    $leverInterviewLink: String
    $smartrecruitersInterviewLink: String
    $scheduledInterviewId: ID
    $iaVersion: Float
    $atsRequisitionId: String
  ) {
    addCall(
      name: $name
      phoneNumber: $phoneNumber
      candidateFirstName: $candidateFirstName
      candidateLastName: $candidateLastName
      candidateEmail: $candidateEmail
      candidateId: $candidateId
      callType: $callType
      callGuideId: $callGuideId
      positionId: $positionId
      greenhouseScorecardLink: $greenhouseScorecardLink
      bullhornCommentAction: $bullhornCommentAction
      leverInterviewLink: $leverInterviewLink
      smartrecruitersInterviewLink: $smartrecruitersInterviewLink
      scheduledInterviewId: $scheduledInterviewId
      iaVersion: $iaVersion
      atsRequisitionId: $atsRequisitionId
    ) {
      call {
        id
        type
      }
    }
  }
`;

/**
 * __useAddCallMutation__
 *
 * To run a mutation, you first call `useAddCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCallMutation, { data, loading, error }] = useAddCallMutation({
 *   variables: {
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      candidateFirstName: // value for 'candidateFirstName'
 *      candidateLastName: // value for 'candidateLastName'
 *      candidateEmail: // value for 'candidateEmail'
 *      candidateId: // value for 'candidateId'
 *      callType: // value for 'callType'
 *      callGuideId: // value for 'callGuideId'
 *      positionId: // value for 'positionId'
 *      greenhouseScorecardLink: // value for 'greenhouseScorecardLink'
 *      bullhornCommentAction: // value for 'bullhornCommentAction'
 *      leverInterviewLink: // value for 'leverInterviewLink'
 *      smartrecruitersInterviewLink: // value for 'smartrecruitersInterviewLink'
 *      scheduledInterviewId: // value for 'scheduledInterviewId'
 *      iaVersion: // value for 'iaVersion'
 *      atsRequisitionId: // value for 'atsRequisitionId'
 *   },
 * });
 */
export function useAddCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCallMutation,
    AddCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCallMutation, AddCallMutationVariables>(
    AddCallDocument,
    options
  );
}
export type AddCallMutationHookResult = ReturnType<typeof useAddCallMutation>;
export type AddCallMutationResult = Apollo.MutationResult<AddCallMutation>;
export type AddCallMutationOptions = Apollo.BaseMutationOptions<
  AddCallMutation,
  AddCallMutationVariables
>;
export const AddCallNoteHighlightFeedbackDocument = gql`
  mutation AddCallNoteHighlightFeedback($id: ID!, $response: String!) {
    addCallNoteHighlightFeedback(id: $id, response: $response) {
      callNote {
        id
        type
      }
    }
  }
`;

/**
 * __useAddCallNoteHighlightFeedbackMutation__
 *
 * To run a mutation, you first call `useAddCallNoteHighlightFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCallNoteHighlightFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCallNoteHighlightFeedbackMutation, { data, loading, error }] = useAddCallNoteHighlightFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useAddCallNoteHighlightFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCallNoteHighlightFeedbackMutation,
    AddCallNoteHighlightFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCallNoteHighlightFeedbackMutation,
    AddCallNoteHighlightFeedbackMutationVariables
  >(AddCallNoteHighlightFeedbackDocument, options);
}
export type AddCallNoteHighlightFeedbackMutationHookResult = ReturnType<
  typeof useAddCallNoteHighlightFeedbackMutation
>;
export type AddCallNoteHighlightFeedbackMutationResult =
  Apollo.MutationResult<AddCallNoteHighlightFeedbackMutation>;
export type AddCallNoteHighlightFeedbackMutationOptions =
  Apollo.BaseMutationOptions<
    AddCallNoteHighlightFeedbackMutation,
    AddCallNoteHighlightFeedbackMutationVariables
  >;
export const AddCallNoteReplyDocument = gql`
  mutation AddCallNoteReply(
    $parentCallNoteId: ID!
    $text: String!
    $visibility: CallNoteVisibility!
  ) {
    addCallNoteReply(
      parentCallNoteId: $parentCallNoteId
      text: $text
      visibility: $visibility
    ) {
      callNote {
        ...CallNoteReply
      }
    }
  }
  ${CallNoteReplyFragmentDoc}
`;

/**
 * __useAddCallNoteReplyMutation__
 *
 * To run a mutation, you first call `useAddCallNoteReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCallNoteReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCallNoteReplyMutation, { data, loading, error }] = useAddCallNoteReplyMutation({
 *   variables: {
 *      parentCallNoteId: // value for 'parentCallNoteId'
 *      text: // value for 'text'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useAddCallNoteReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCallNoteReplyMutation,
    AddCallNoteReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCallNoteReplyMutation,
    AddCallNoteReplyMutationVariables
  >(AddCallNoteReplyDocument, options);
}
export type AddCallNoteReplyMutationHookResult = ReturnType<
  typeof useAddCallNoteReplyMutation
>;
export type AddCallNoteReplyMutationResult =
  Apollo.MutationResult<AddCallNoteReplyMutation>;
export type AddCallNoteReplyMutationOptions = Apollo.BaseMutationOptions<
  AddCallNoteReplyMutation,
  AddCallNoteReplyMutationVariables
>;
export const AddCandidateAlertUserSubscriptionsDocument = gql`
  mutation AddCandidateAlertUserSubscriptions(
    $candidateIds: [ID!]!
    $positionIds: [ID!]!
  ) {
    addCandidateAlertUserSubscriptions(
      candidateIds: $candidateIds
      positionIds: $positionIds
    ) {
      subscriptions {
        id
        candidateSubscriptions {
          id
          candidateId
          mutedAt
        }
        positionSubscriptions {
          id
          positionId
          mutedAt
        }
      }
    }
  }
`;

/**
 * __useAddCandidateAlertUserSubscriptionsMutation__
 *
 * To run a mutation, you first call `useAddCandidateAlertUserSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCandidateAlertUserSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCandidateAlertUserSubscriptionsMutation, { data, loading, error }] = useAddCandidateAlertUserSubscriptionsMutation({
 *   variables: {
 *      candidateIds: // value for 'candidateIds'
 *      positionIds: // value for 'positionIds'
 *   },
 * });
 */
export function useAddCandidateAlertUserSubscriptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCandidateAlertUserSubscriptionsMutation,
    AddCandidateAlertUserSubscriptionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCandidateAlertUserSubscriptionsMutation,
    AddCandidateAlertUserSubscriptionsMutationVariables
  >(AddCandidateAlertUserSubscriptionsDocument, options);
}
export type AddCandidateAlertUserSubscriptionsMutationHookResult = ReturnType<
  typeof useAddCandidateAlertUserSubscriptionsMutation
>;
export type AddCandidateAlertUserSubscriptionsMutationResult =
  Apollo.MutationResult<AddCandidateAlertUserSubscriptionsMutation>;
export type AddCandidateAlertUserSubscriptionsMutationOptions =
  Apollo.BaseMutationOptions<
    AddCandidateAlertUserSubscriptionsMutation,
    AddCandidateAlertUserSubscriptionsMutationVariables
  >;
export const AddClientDocument = gql`
  mutation AddClient($name: String!) {
    addClient(name: $name) {
      client {
        id
        name
      }
    }
  }
`;

/**
 * __useAddClientMutation__
 *
 * To run a mutation, you first call `useAddClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientMutation, { data, loading, error }] = useAddClientMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddClientMutation,
    AddClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddClientMutation, AddClientMutationVariables>(
    AddClientDocument,
    options
  );
}
export type AddClientMutationHookResult = ReturnType<
  typeof useAddClientMutation
>;
export type AddClientMutationResult = Apollo.MutationResult<AddClientMutation>;
export type AddClientMutationOptions = Apollo.BaseMutationOptions<
  AddClientMutation,
  AddClientMutationVariables
>;
export const AddGuideToCallDocument = gql`
  mutation AddGuideToCall($callId: ID!, $callGuideId: ID!) {
    updateCall(id: $callId, callGuideId: $callGuideId) {
      call {
        id
        callGuide {
          ...CallGuide
        }
        scorecard {
          ...CurrentCallScorecard
        }
        notes(currentUserOnly: true) {
          ...CallNote
        }
        generalNotes(currentUserOnly: true) @client {
          ...CallNote
        }
      }
    }
  }
  ${CallGuideFragmentDoc}
  ${CurrentCallScorecardFragmentDoc}
  ${CallNoteFragmentDoc}
`;

/**
 * __useAddGuideToCallMutation__
 *
 * To run a mutation, you first call `useAddGuideToCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGuideToCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGuideToCallMutation, { data, loading, error }] = useAddGuideToCallMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      callGuideId: // value for 'callGuideId'
 *   },
 * });
 */
export function useAddGuideToCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGuideToCallMutation,
    AddGuideToCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGuideToCallMutation,
    AddGuideToCallMutationVariables
  >(AddGuideToCallDocument, options);
}
export type AddGuideToCallMutationHookResult = ReturnType<
  typeof useAddGuideToCallMutation
>;
export type AddGuideToCallMutationResult =
  Apollo.MutationResult<AddGuideToCallMutation>;
export type AddGuideToCallMutationOptions = Apollo.BaseMutationOptions<
  AddGuideToCallMutation,
  AddGuideToCallMutationVariables
>;
export const AddInterviewAssistantOpensDocument = gql`
  mutation AddInterviewAssistantOpens(
    $callId: ID!
    $entryPoint: InterviewAssistantEntryPoint!
  ) {
    addInterviewAssistantOpens(callId: $callId, entryPoint: $entryPoint) {
      success
    }
  }
`;

/**
 * __useAddInterviewAssistantOpensMutation__
 *
 * To run a mutation, you first call `useAddInterviewAssistantOpensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInterviewAssistantOpensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInterviewAssistantOpensMutation, { data, loading, error }] = useAddInterviewAssistantOpensMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      entryPoint: // value for 'entryPoint'
 *   },
 * });
 */
export function useAddInterviewAssistantOpensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddInterviewAssistantOpensMutation,
    AddInterviewAssistantOpensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddInterviewAssistantOpensMutation,
    AddInterviewAssistantOpensMutationVariables
  >(AddInterviewAssistantOpensDocument, options);
}
export type AddInterviewAssistantOpensMutationHookResult = ReturnType<
  typeof useAddInterviewAssistantOpensMutation
>;
export type AddInterviewAssistantOpensMutationResult =
  Apollo.MutationResult<AddInterviewAssistantOpensMutation>;
export type AddInterviewAssistantOpensMutationOptions =
  Apollo.BaseMutationOptions<
    AddInterviewAssistantOpensMutation,
    AddInterviewAssistantOpensMutationVariables
  >;
export const AddNewCallNoteDocument = gql`
  mutation AddNewCallNote(
    $callId: ID!
    $time: Float!
    $text: String!
    $type: CallNoteType!
    $visibility: CallNoteVisibility!
    $description: String
    $guideItemId: ID
    $clipId: ID
    $parentGuideItemId: ID
    $questionId: ID
    $scorecardQuestionId: ID
    $markQuestion: Boolean
  ) {
    addNewCallNote(
      callId: $callId
      time: $time
      text: $text
      type: $type
      visibility: $visibility
      description: $description
      guideItemId: $guideItemId
      clipId: $clipId
      parentGuideItemId: $parentGuideItemId
      questionId: $questionId
      markQuestion: $markQuestion
      scorecardQuestionId: $scorecardQuestionId
    ) {
      callNote {
        ...CallNote
      }
    }
  }
  ${CallNoteFragmentDoc}
`;

/**
 * __useAddNewCallNoteMutation__
 *
 * To run a mutation, you first call `useAddNewCallNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewCallNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewCallNoteMutation, { data, loading, error }] = useAddNewCallNoteMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      time: // value for 'time'
 *      text: // value for 'text'
 *      type: // value for 'type'
 *      visibility: // value for 'visibility'
 *      description: // value for 'description'
 *      guideItemId: // value for 'guideItemId'
 *      clipId: // value for 'clipId'
 *      parentGuideItemId: // value for 'parentGuideItemId'
 *      questionId: // value for 'questionId'
 *      scorecardQuestionId: // value for 'scorecardQuestionId'
 *      markQuestion: // value for 'markQuestion'
 *   },
 * });
 */
export function useAddNewCallNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNewCallNoteMutation,
    AddNewCallNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddNewCallNoteMutation,
    AddNewCallNoteMutationVariables
  >(AddNewCallNoteDocument, options);
}
export type AddNewCallNoteMutationHookResult = ReturnType<
  typeof useAddNewCallNoteMutation
>;
export type AddNewCallNoteMutationResult =
  Apollo.MutationResult<AddNewCallNoteMutation>;
export type AddNewCallNoteMutationOptions = Apollo.BaseMutationOptions<
  AddNewCallNoteMutation,
  AddNewCallNoteMutationVariables
>;
export const AddPositionDocument = gql`
  mutation AddPosition($clientId: ID!, $title: String!) {
    addPosition(clientId: $clientId, title: $title) {
      position {
        ...PositionListItem
      }
    }
  }
  ${PositionListItemFragmentDoc}
`;

/**
 * __useAddPositionMutation__
 *
 * To run a mutation, you first call `useAddPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPositionMutation, { data, loading, error }] = useAddPositionMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useAddPositionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPositionMutation,
    AddPositionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPositionMutation, AddPositionMutationVariables>(
    AddPositionDocument,
    options
  );
}
export type AddPositionMutationHookResult = ReturnType<
  typeof useAddPositionMutation
>;
export type AddPositionMutationResult =
  Apollo.MutationResult<AddPositionMutation>;
export type AddPositionMutationOptions = Apollo.BaseMutationOptions<
  AddPositionMutation,
  AddPositionMutationVariables
>;
export const AddInterviewStageDocument = gql`
  mutation AddInterviewStage($title: String!) {
    addInterviewStage(title: $title) {
      interviewStage {
        ...InterviewStageListItem
      }
    }
  }
  ${InterviewStageListItemFragmentDoc}
`;

/**
 * __useAddInterviewStageMutation__
 *
 * To run a mutation, you first call `useAddInterviewStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInterviewStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInterviewStageMutation, { data, loading, error }] = useAddInterviewStageMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useAddInterviewStageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddInterviewStageMutation,
    AddInterviewStageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddInterviewStageMutation,
    AddInterviewStageMutationVariables
  >(AddInterviewStageDocument, options);
}
export type AddInterviewStageMutationHookResult = ReturnType<
  typeof useAddInterviewStageMutation
>;
export type AddInterviewStageMutationResult =
  Apollo.MutationResult<AddInterviewStageMutation>;
export type AddInterviewStageMutationOptions = Apollo.BaseMutationOptions<
  AddInterviewStageMutation,
  AddInterviewStageMutationVariables
>;
export const AshbyCandidateParamsDocument = gql`
  mutation AshbyCandidateParams(
    $candidateId: String!
    $phoneNumber: String!
    $applicationId: String
  ) {
    ashbyCandidateParams(
      candidateId: $candidateId
      phoneNumber: $phoneNumber
      applicationId: $applicationId
    ) {
      params {
        candidateFirstName
        candidateLastName
        phoneNumber
        candidateEmail
        positionId
        name
      }
    }
  }
`;

/**
 * __useAshbyCandidateParamsMutation__
 *
 * To run a mutation, you first call `useAshbyCandidateParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAshbyCandidateParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ashbyCandidateParamsMutation, { data, loading, error }] = useAshbyCandidateParamsMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      phoneNumber: // value for 'phoneNumber'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useAshbyCandidateParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AshbyCandidateParamsMutation,
    AshbyCandidateParamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AshbyCandidateParamsMutation,
    AshbyCandidateParamsMutationVariables
  >(AshbyCandidateParamsDocument, options);
}
export type AshbyCandidateParamsMutationHookResult = ReturnType<
  typeof useAshbyCandidateParamsMutation
>;
export type AshbyCandidateParamsMutationResult =
  Apollo.MutationResult<AshbyCandidateParamsMutation>;
export type AshbyCandidateParamsMutationOptions = Apollo.BaseMutationOptions<
  AshbyCandidateParamsMutation,
  AshbyCandidateParamsMutationVariables
>;
export const AuthorizeZoomBotDocument = gql`
  mutation AuthorizeZoomBot($authCode: String!) {
    authorizeZoomBot(authCode: $authCode) {
      organization {
        id
        isZoomBotAuthorized
      }
    }
  }
`;

/**
 * __useAuthorizeZoomBotMutation__
 *
 * To run a mutation, you first call `useAuthorizeZoomBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeZoomBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeZoomBotMutation, { data, loading, error }] = useAuthorizeZoomBotMutation({
 *   variables: {
 *      authCode: // value for 'authCode'
 *   },
 * });
 */
export function useAuthorizeZoomBotMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorizeZoomBotMutation,
    AuthorizeZoomBotMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthorizeZoomBotMutation,
    AuthorizeZoomBotMutationVariables
  >(AuthorizeZoomBotDocument, options);
}
export type AuthorizeZoomBotMutationHookResult = ReturnType<
  typeof useAuthorizeZoomBotMutation
>;
export type AuthorizeZoomBotMutationResult =
  Apollo.MutationResult<AuthorizeZoomBotMutation>;
export type AuthorizeZoomBotMutationOptions = Apollo.BaseMutationOptions<
  AuthorizeZoomBotMutation,
  AuthorizeZoomBotMutationVariables
>;
export const AuthorizeZoomDocument = gql`
  mutation AuthorizeZoom($authCode: String!) {
    authorizeZoom(authCode: $authCode) {
      organization {
        id
        isZoomAuthorized
      }
    }
  }
`;

/**
 * __useAuthorizeZoomMutation__
 *
 * To run a mutation, you first call `useAuthorizeZoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeZoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeZoomMutation, { data, loading, error }] = useAuthorizeZoomMutation({
 *   variables: {
 *      authCode: // value for 'authCode'
 *   },
 * });
 */
export function useAuthorizeZoomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorizeZoomMutation,
    AuthorizeZoomMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthorizeZoomMutation,
    AuthorizeZoomMutationVariables
  >(AuthorizeZoomDocument, options);
}
export type AuthorizeZoomMutationHookResult = ReturnType<
  typeof useAuthorizeZoomMutation
>;
export type AuthorizeZoomMutationResult =
  Apollo.MutationResult<AuthorizeZoomMutation>;
export type AuthorizeZoomMutationOptions = Apollo.BaseMutationOptions<
  AuthorizeZoomMutation,
  AuthorizeZoomMutationVariables
>;
export const BullhornCallParamsDocument = gql`
  mutation BullhornCallParams(
    $candidateId: String!
    $userId: String
    $entityType: String
  ) {
    bullhornCallParams(
      entityType: $entityType
      candidateId: $candidateId
      userId: $userId
    ) {
      bullhornCallParams {
        phoneNumber
        candidateFirstName
        candidateLastName
        candidateEmail
        name
        positionId
        candidateId
      }
    }
  }
`;

/**
 * __useBullhornCallParamsMutation__
 *
 * To run a mutation, you first call `useBullhornCallParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBullhornCallParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bullhornCallParamsMutation, { data, loading, error }] = useBullhornCallParamsMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      userId: // value for 'userId'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useBullhornCallParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BullhornCallParamsMutation,
    BullhornCallParamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BullhornCallParamsMutation,
    BullhornCallParamsMutationVariables
  >(BullhornCallParamsDocument, options);
}
export type BullhornCallParamsMutationHookResult = ReturnType<
  typeof useBullhornCallParamsMutation
>;
export type BullhornCallParamsMutationResult =
  Apollo.MutationResult<BullhornCallParamsMutation>;
export type BullhornCallParamsMutationOptions = Apollo.BaseMutationOptions<
  BullhornCallParamsMutation,
  BullhornCallParamsMutationVariables
>;
export const CancelRecordingDocument = gql`
  mutation CancelRecording($slug: String!) {
    cancelRecording(slug: $slug) {
      success
    }
  }
`;

/**
 * __useCancelRecordingMutation__
 *
 * To run a mutation, you first call `useCancelRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRecordingMutation, { data, loading, error }] = useCancelRecordingMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCancelRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelRecordingMutation,
    CancelRecordingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelRecordingMutation,
    CancelRecordingMutationVariables
  >(CancelRecordingDocument, options);
}
export type CancelRecordingMutationHookResult = ReturnType<
  typeof useCancelRecordingMutation
>;
export type CancelRecordingMutationResult =
  Apollo.MutationResult<CancelRecordingMutation>;
export type CancelRecordingMutationOptions = Apollo.BaseMutationOptions<
  CancelRecordingMutation,
  CancelRecordingMutationVariables
>;
export const ClipCallDocument = gql`
  mutation ClipCall(
    $callId: ID!
    $clipStart: Float!
    $clipEnd: Float!
    $name: String
  ) {
    clipCall(
      callId: $callId
      clipStart: $clipStart
      clipEnd: $clipEnd
      name: $name
    ) {
      clip {
        ...Clip
      }
    }
  }
  ${ClipFragmentDoc}
`;

/**
 * __useClipCallMutation__
 *
 * To run a mutation, you first call `useClipCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClipCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clipCallMutation, { data, loading, error }] = useClipCallMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      clipStart: // value for 'clipStart'
 *      clipEnd: // value for 'clipEnd'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useClipCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClipCallMutation,
    ClipCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClipCallMutation, ClipCallMutationVariables>(
    ClipCallDocument,
    options
  );
}
export type ClipCallMutationHookResult = ReturnType<typeof useClipCallMutation>;
export type ClipCallMutationResult = Apollo.MutationResult<ClipCallMutation>;
export type ClipCallMutationOptions = Apollo.BaseMutationOptions<
  ClipCallMutation,
  ClipCallMutationVariables
>;
export const CreateAiNoteTagsDocument = gql`
  mutation CreateAiNoteTags($callId: ID!) {
    createAiNoteTags(callId: $callId) {
      success
    }
  }
`;

/**
 * __useCreateAiNoteTagsMutation__
 *
 * To run a mutation, you first call `useCreateAiNoteTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiNoteTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiNoteTagsMutation, { data, loading, error }] = useCreateAiNoteTagsMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCreateAiNoteTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAiNoteTagsMutation,
    CreateAiNoteTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAiNoteTagsMutation,
    CreateAiNoteTagsMutationVariables
  >(CreateAiNoteTagsDocument, options);
}
export type CreateAiNoteTagsMutationHookResult = ReturnType<
  typeof useCreateAiNoteTagsMutation
>;
export type CreateAiNoteTagsMutationResult =
  Apollo.MutationResult<CreateAiNoteTagsMutation>;
export type CreateAiNoteTagsMutationOptions = Apollo.BaseMutationOptions<
  CreateAiNoteTagsMutation,
  CreateAiNoteTagsMutationVariables
>;
export const CreateAiNotesDocument = gql`
  mutation CreateAiNotes($callId: ID!) {
    createAiNotes(callId: $callId) {
      success
    }
  }
`;

/**
 * __useCreateAiNotesMutation__
 *
 * To run a mutation, you first call `useCreateAiNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiNotesMutation, { data, loading, error }] = useCreateAiNotesMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCreateAiNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAiNotesMutation,
    CreateAiNotesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAiNotesMutation,
    CreateAiNotesMutationVariables
  >(CreateAiNotesDocument, options);
}
export type CreateAiNotesMutationHookResult = ReturnType<
  typeof useCreateAiNotesMutation
>;
export type CreateAiNotesMutationResult =
  Apollo.MutationResult<CreateAiNotesMutation>;
export type CreateAiNotesMutationOptions = Apollo.BaseMutationOptions<
  CreateAiNotesMutation,
  CreateAiNotesMutationVariables
>;
export const CreateAiTopicsDocument = gql`
  mutation CreateAiTopics($callId: ID!) {
    createAiTopics(callId: $callId) {
      success
    }
  }
`;

/**
 * __useCreateAiTopicsMutation__
 *
 * To run a mutation, you first call `useCreateAiTopicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiTopicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiTopicsMutation, { data, loading, error }] = useCreateAiTopicsMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCreateAiTopicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAiTopicsMutation,
    CreateAiTopicsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAiTopicsMutation,
    CreateAiTopicsMutationVariables
  >(CreateAiTopicsDocument, options);
}
export type CreateAiTopicsMutationHookResult = ReturnType<
  typeof useCreateAiTopicsMutation
>;
export type CreateAiTopicsMutationResult =
  Apollo.MutationResult<CreateAiTopicsMutation>;
export type CreateAiTopicsMutationOptions = Apollo.BaseMutationOptions<
  CreateAiTopicsMutation,
  CreateAiTopicsMutationVariables
>;
export const CreateExcludeStageDocument = gql`
  mutation CreateExcludeStage($excludeText: String!) {
    createExcludeStage(excludeText: $excludeText) {
      excludeStage {
        id
        excludeText
      }
    }
  }
`;

/**
 * __useCreateExcludeStageMutation__
 *
 * To run a mutation, you first call `useCreateExcludeStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExcludeStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExcludeStageMutation, { data, loading, error }] = useCreateExcludeStageMutation({
 *   variables: {
 *      excludeText: // value for 'excludeText'
 *   },
 * });
 */
export function useCreateExcludeStageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExcludeStageMutation,
    CreateExcludeStageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExcludeStageMutation,
    CreateExcludeStageMutationVariables
  >(CreateExcludeStageDocument, options);
}
export type CreateExcludeStageMutationHookResult = ReturnType<
  typeof useCreateExcludeStageMutation
>;
export type CreateExcludeStageMutationResult =
  Apollo.MutationResult<CreateExcludeStageMutation>;
export type CreateExcludeStageMutationOptions = Apollo.BaseMutationOptions<
  CreateExcludeStageMutation,
  CreateExcludeStageMutationVariables
>;
export const CreateLeverOauthRequestUrlDocument = gql`
  mutation CreateLeverOauthRequestUrl {
    createLeverOauthRequestUrl {
      leverOauthUrl
    }
  }
`;

/**
 * __useCreateLeverOauthRequestUrlMutation__
 *
 * To run a mutation, you first call `useCreateLeverOauthRequestUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeverOauthRequestUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeverOauthRequestUrlMutation, { data, loading, error }] = useCreateLeverOauthRequestUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateLeverOauthRequestUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLeverOauthRequestUrlMutation,
    CreateLeverOauthRequestUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLeverOauthRequestUrlMutation,
    CreateLeverOauthRequestUrlMutationVariables
  >(CreateLeverOauthRequestUrlDocument, options);
}
export type CreateLeverOauthRequestUrlMutationHookResult = ReturnType<
  typeof useCreateLeverOauthRequestUrlMutation
>;
export type CreateLeverOauthRequestUrlMutationResult =
  Apollo.MutationResult<CreateLeverOauthRequestUrlMutation>;
export type CreateLeverOauthRequestUrlMutationOptions =
  Apollo.BaseMutationOptions<
    CreateLeverOauthRequestUrlMutation,
    CreateLeverOauthRequestUrlMutationVariables
  >;
export const CreateMergeLinkTokenDocument = gql`
  mutation CreateMergeLinkToken {
    createMergeLinkToken {
      linkToken
    }
  }
`;

/**
 * __useCreateMergeLinkTokenMutation__
 *
 * To run a mutation, you first call `useCreateMergeLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMergeLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMergeLinkTokenMutation, { data, loading, error }] = useCreateMergeLinkTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateMergeLinkTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMergeLinkTokenMutation,
    CreateMergeLinkTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMergeLinkTokenMutation,
    CreateMergeLinkTokenMutationVariables
  >(CreateMergeLinkTokenDocument, options);
}
export type CreateMergeLinkTokenMutationHookResult = ReturnType<
  typeof useCreateMergeLinkTokenMutation
>;
export type CreateMergeLinkTokenMutationResult =
  Apollo.MutationResult<CreateMergeLinkTokenMutation>;
export type CreateMergeLinkTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateMergeLinkTokenMutation,
  CreateMergeLinkTokenMutationVariables
>;
export const CreateOrUpdateExcludedUserDocument = gql`
  mutation CreateOrUpdateExcludedUser($email: String!) {
    createOrUpdateExcludedUser(email: $email) {
      excludedUser {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useCreateOrUpdateExcludedUserMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateExcludedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateExcludedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateExcludedUserMutation, { data, loading, error }] = useCreateOrUpdateExcludedUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateOrUpdateExcludedUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateExcludedUserMutation,
    CreateOrUpdateExcludedUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateExcludedUserMutation,
    CreateOrUpdateExcludedUserMutationVariables
  >(CreateOrUpdateExcludedUserDocument, options);
}
export type CreateOrUpdateExcludedUserMutationHookResult = ReturnType<
  typeof useCreateOrUpdateExcludedUserMutation
>;
export type CreateOrUpdateExcludedUserMutationResult =
  Apollo.MutationResult<CreateOrUpdateExcludedUserMutation>;
export type CreateOrUpdateExcludedUserMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateExcludedUserMutation,
    CreateOrUpdateExcludedUserMutationVariables
  >;
export const CreateScheduledInterviewDocument = gql`
  mutation CreateScheduledInterview(
    $scheduledInterviewInput: ScheduledInterviewInput!
  ) {
    createScheduledInterview(
      scheduledInterviewInput: $scheduledInterviewInput
    ) {
      scheduledInterview {
        ...ScheduledInterviewListItem
      }
    }
  }
  ${ScheduledInterviewListItemFragmentDoc}
`;

/**
 * __useCreateScheduledInterviewMutation__
 *
 * To run a mutation, you first call `useCreateScheduledInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledInterviewMutation, { data, loading, error }] = useCreateScheduledInterviewMutation({
 *   variables: {
 *      scheduledInterviewInput: // value for 'scheduledInterviewInput'
 *   },
 * });
 */
export function useCreateScheduledInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduledInterviewMutation,
    CreateScheduledInterviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScheduledInterviewMutation,
    CreateScheduledInterviewMutationVariables
  >(CreateScheduledInterviewDocument, options);
}
export type CreateScheduledInterviewMutationHookResult = ReturnType<
  typeof useCreateScheduledInterviewMutation
>;
export type CreateScheduledInterviewMutationResult =
  Apollo.MutationResult<CreateScheduledInterviewMutation>;
export type CreateScheduledInterviewMutationOptions =
  Apollo.BaseMutationOptions<
    CreateScheduledInterviewMutation,
    CreateScheduledInterviewMutationVariables
  >;
export const CreateScheduledInterviewZoomNotetakerMeetingDocument = gql`
  mutation CreateScheduledInterviewZoomNotetakerMeeting(
    $scheduledInterviewInput: ScheduledInterviewInput!
  ) {
    createScheduledInterviewZoomNotetakerMeeting(
      scheduledInterviewInput: $scheduledInterviewInput
    ) {
      meeting {
        id
        meetingUrl
      }
    }
  }
`;

/**
 * __useCreateScheduledInterviewZoomNotetakerMeetingMutation__
 *
 * To run a mutation, you first call `useCreateScheduledInterviewZoomNotetakerMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledInterviewZoomNotetakerMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledInterviewZoomNotetakerMeetingMutation, { data, loading, error }] = useCreateScheduledInterviewZoomNotetakerMeetingMutation({
 *   variables: {
 *      scheduledInterviewInput: // value for 'scheduledInterviewInput'
 *   },
 * });
 */
export function useCreateScheduledInterviewZoomNotetakerMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduledInterviewZoomNotetakerMeetingMutation,
    CreateScheduledInterviewZoomNotetakerMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScheduledInterviewZoomNotetakerMeetingMutation,
    CreateScheduledInterviewZoomNotetakerMeetingMutationVariables
  >(CreateScheduledInterviewZoomNotetakerMeetingDocument, options);
}
export type CreateScheduledInterviewZoomNotetakerMeetingMutationHookResult =
  ReturnType<typeof useCreateScheduledInterviewZoomNotetakerMeetingMutation>;
export type CreateScheduledInterviewZoomNotetakerMeetingMutationResult =
  Apollo.MutationResult<CreateScheduledInterviewZoomNotetakerMeetingMutation>;
export type CreateScheduledInterviewZoomNotetakerMeetingMutationOptions =
  Apollo.BaseMutationOptions<
    CreateScheduledInterviewZoomNotetakerMeetingMutation,
    CreateScheduledInterviewZoomNotetakerMeetingMutationVariables
  >;
export const DeauthorizeLeverDocument = gql`
  mutation DeauthorizeLever {
    deauthorizeLever {
      success
    }
  }
`;

/**
 * __useDeauthorizeLeverMutation__
 *
 * To run a mutation, you first call `useDeauthorizeLeverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeauthorizeLeverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deauthorizeLeverMutation, { data, loading, error }] = useDeauthorizeLeverMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeauthorizeLeverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeauthorizeLeverMutation,
    DeauthorizeLeverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeauthorizeLeverMutation,
    DeauthorizeLeverMutationVariables
  >(DeauthorizeLeverDocument, options);
}
export type DeauthorizeLeverMutationHookResult = ReturnType<
  typeof useDeauthorizeLeverMutation
>;
export type DeauthorizeLeverMutationResult =
  Apollo.MutationResult<DeauthorizeLeverMutation>;
export type DeauthorizeLeverMutationOptions = Apollo.BaseMutationOptions<
  DeauthorizeLeverMutation,
  DeauthorizeLeverMutationVariables
>;
export const DeauthorizeMergeDocument = gql`
  mutation DeauthorizeMerge($accountId: String!) {
    deauthorizeMerge(accountId: $accountId) {
      success
    }
  }
`;

/**
 * __useDeauthorizeMergeMutation__
 *
 * To run a mutation, you first call `useDeauthorizeMergeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeauthorizeMergeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deauthorizeMergeMutation, { data, loading, error }] = useDeauthorizeMergeMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeauthorizeMergeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeauthorizeMergeMutation,
    DeauthorizeMergeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeauthorizeMergeMutation,
    DeauthorizeMergeMutationVariables
  >(DeauthorizeMergeDocument, options);
}
export type DeauthorizeMergeMutationHookResult = ReturnType<
  typeof useDeauthorizeMergeMutation
>;
export type DeauthorizeMergeMutationResult =
  Apollo.MutationResult<DeauthorizeMergeMutation>;
export type DeauthorizeMergeMutationOptions = Apollo.BaseMutationOptions<
  DeauthorizeMergeMutation,
  DeauthorizeMergeMutationVariables
>;
export const DeleteApiTokenDocument = gql`
  mutation DeleteApiToken($id: ID!) {
    deleteApiToken(id: $id) {
      setup {
        apiTokens {
          id
          name
          tokenPrefix
        }
        webhooks {
          id
          webhookUrl
          webhookSecret
          eventInterviewCompleted
          eventCandidateUpdatedRecordingDisabled
        }
      }
    }
  }
`;

/**
 * __useDeleteApiTokenMutation__
 *
 * To run a mutation, you first call `useDeleteApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiTokenMutation, { data, loading, error }] = useDeleteApiTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApiTokenMutation,
    DeleteApiTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApiTokenMutation,
    DeleteApiTokenMutationVariables
  >(DeleteApiTokenDocument, options);
}
export type DeleteApiTokenMutationHookResult = ReturnType<
  typeof useDeleteApiTokenMutation
>;
export type DeleteApiTokenMutationResult =
  Apollo.MutationResult<DeleteApiTokenMutation>;
export type DeleteApiTokenMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiTokenMutation,
  DeleteApiTokenMutationVariables
>;
export const DeleteCalendarOrgSettingsDocument = gql`
  mutation DeleteCalendarOrgSettings($id: ID!) {
    deleteCalendarOrgSettings(id: $id) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useDeleteCalendarOrgSettingsMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarOrgSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarOrgSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarOrgSettingsMutation, { data, loading, error }] = useDeleteCalendarOrgSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCalendarOrgSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCalendarOrgSettingsMutation,
    DeleteCalendarOrgSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCalendarOrgSettingsMutation,
    DeleteCalendarOrgSettingsMutationVariables
  >(DeleteCalendarOrgSettingsDocument, options);
}
export type DeleteCalendarOrgSettingsMutationHookResult = ReturnType<
  typeof useDeleteCalendarOrgSettingsMutation
>;
export type DeleteCalendarOrgSettingsMutationResult =
  Apollo.MutationResult<DeleteCalendarOrgSettingsMutation>;
export type DeleteCalendarOrgSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCalendarOrgSettingsMutation,
    DeleteCalendarOrgSettingsMutationVariables
  >;
export const DeleteCallNoteDocument = gql`
  mutation DeleteCallNote($id: ID!) {
    deleteCallNote(id: $id) {
      callNote {
        id
      }
    }
  }
`;

/**
 * __useDeleteCallNoteMutation__
 *
 * To run a mutation, you first call `useDeleteCallNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCallNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCallNoteMutation, { data, loading, error }] = useDeleteCallNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCallNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCallNoteMutation,
    DeleteCallNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCallNoteMutation,
    DeleteCallNoteMutationVariables
  >(DeleteCallNoteDocument, options);
}
export type DeleteCallNoteMutationHookResult = ReturnType<
  typeof useDeleteCallNoteMutation
>;
export type DeleteCallNoteMutationResult =
  Apollo.MutationResult<DeleteCallNoteMutation>;
export type DeleteCallNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteCallNoteMutation,
  DeleteCallNoteMutationVariables
>;
export const DeleteClipDocument = gql`
  mutation DeleteClip($id: ID!) {
    deleteClip(id: $id) {
      clip {
        id
        call {
          id
        }
      }
    }
  }
`;

/**
 * __useDeleteClipMutation__
 *
 * To run a mutation, you first call `useDeleteClipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClipMutation, { data, loading, error }] = useDeleteClipMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClipMutation,
    DeleteClipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteClipMutation, DeleteClipMutationVariables>(
    DeleteClipDocument,
    options
  );
}
export type DeleteClipMutationHookResult = ReturnType<
  typeof useDeleteClipMutation
>;
export type DeleteClipMutationResult =
  Apollo.MutationResult<DeleteClipMutation>;
export type DeleteClipMutationOptions = Apollo.BaseMutationOptions<
  DeleteClipMutation,
  DeleteClipMutationVariables
>;
export const DeleteExcludeStageDocument = gql`
  mutation DeleteExcludeStage($id: ID!) {
    deleteExcludeStage(id: $id) {
      excludeStage {
        id
        excludeText
      }
    }
  }
`;

/**
 * __useDeleteExcludeStageMutation__
 *
 * To run a mutation, you first call `useDeleteExcludeStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExcludeStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExcludeStageMutation, { data, loading, error }] = useDeleteExcludeStageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExcludeStageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExcludeStageMutation,
    DeleteExcludeStageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExcludeStageMutation,
    DeleteExcludeStageMutationVariables
  >(DeleteExcludeStageDocument, options);
}
export type DeleteExcludeStageMutationHookResult = ReturnType<
  typeof useDeleteExcludeStageMutation
>;
export type DeleteExcludeStageMutationResult =
  Apollo.MutationResult<DeleteExcludeStageMutation>;
export type DeleteExcludeStageMutationOptions = Apollo.BaseMutationOptions<
  DeleteExcludeStageMutation,
  DeleteExcludeStageMutationVariables
>;
export const DeleteScheduledInterviewDocument = gql`
  mutation DeleteScheduledInterview($scheduledInterviewId: ID!) {
    deleteScheduledInterview(scheduledInterviewId: $scheduledInterviewId) {
      scheduledInterview {
        id
      }
    }
  }
`;

/**
 * __useDeleteScheduledInterviewMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledInterviewMutation, { data, loading, error }] = useDeleteScheduledInterviewMutation({
 *   variables: {
 *      scheduledInterviewId: // value for 'scheduledInterviewId'
 *   },
 * });
 */
export function useDeleteScheduledInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScheduledInterviewMutation,
    DeleteScheduledInterviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScheduledInterviewMutation,
    DeleteScheduledInterviewMutationVariables
  >(DeleteScheduledInterviewDocument, options);
}
export type DeleteScheduledInterviewMutationHookResult = ReturnType<
  typeof useDeleteScheduledInterviewMutation
>;
export type DeleteScheduledInterviewMutationResult =
  Apollo.MutationResult<DeleteScheduledInterviewMutation>;
export type DeleteScheduledInterviewMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteScheduledInterviewMutation,
    DeleteScheduledInterviewMutationVariables
  >;
export const DeleteTrainingProgramTraineeDocument = gql`
  mutation DeleteTrainingProgramTrainee($id: ID!) {
    deleteTrainingProgramTrainee(id: $id) {
      deletedTraineeId
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useDeleteTrainingProgramTraineeMutation__
 *
 * To run a mutation, you first call `useDeleteTrainingProgramTraineeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrainingProgramTraineeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrainingProgramTraineeMutation, { data, loading, error }] = useDeleteTrainingProgramTraineeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrainingProgramTraineeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTrainingProgramTraineeMutation,
    DeleteTrainingProgramTraineeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTrainingProgramTraineeMutation,
    DeleteTrainingProgramTraineeMutationVariables
  >(DeleteTrainingProgramTraineeDocument, options);
}
export type DeleteTrainingProgramTraineeMutationHookResult = ReturnType<
  typeof useDeleteTrainingProgramTraineeMutation
>;
export type DeleteTrainingProgramTraineeMutationResult =
  Apollo.MutationResult<DeleteTrainingProgramTraineeMutation>;
export type DeleteTrainingProgramTraineeMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteTrainingProgramTraineeMutation,
    DeleteTrainingProgramTraineeMutationVariables
  >;
export const DeleteUsersDocument = gql`
  mutation DeleteUsers($ids: [ID!]!) {
    deleteUsers(ids: $ids) {
      success
    }
  }
`;

/**
 * __useDeleteUsersMutation__
 *
 * To run a mutation, you first call `useDeleteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersMutation, { data, loading, error }] = useDeleteUsersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUsersMutation,
    DeleteUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUsersMutation, DeleteUsersMutationVariables>(
    DeleteUsersDocument,
    options
  );
}
export type DeleteUsersMutationHookResult = ReturnType<
  typeof useDeleteUsersMutation
>;
export type DeleteUsersMutationResult =
  Apollo.MutationResult<DeleteUsersMutation>;
export type DeleteUsersMutationOptions = Apollo.BaseMutationOptions<
  DeleteUsersMutation,
  DeleteUsersMutationVariables
>;
export const DeleteWebhookDocument = gql`
  mutation DeleteWebhook($id: ID!) {
    deleteWebhook(id: $id) {
      setup {
        apiTokens {
          id
          name
          tokenPrefix
        }
        webhooks {
          id
          webhookUrl
          webhookSecret
          eventInterviewCompleted
          eventCandidateUpdatedRecordingDisabled
        }
      }
    }
  }
`;

/**
 * __useDeleteWebhookMutation__
 *
 * To run a mutation, you first call `useDeleteWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebhookMutation, { data, loading, error }] = useDeleteWebhookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWebhookMutation,
    DeleteWebhookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteWebhookMutation,
    DeleteWebhookMutationVariables
  >(DeleteWebhookDocument, options);
}
export type DeleteWebhookMutationHookResult = ReturnType<
  typeof useDeleteWebhookMutation
>;
export type DeleteWebhookMutationResult =
  Apollo.MutationResult<DeleteWebhookMutation>;
export type DeleteWebhookMutationOptions = Apollo.BaseMutationOptions<
  DeleteWebhookMutation,
  DeleteWebhookMutationVariables
>;
export const DisableCallRecordingDocument = gql`
  mutation DisableCallRecording($id: ID!) {
    updateCall(id: $id, recordingDisabled: true) {
      call {
        id
        recordingDisabled
      }
    }
  }
`;

/**
 * __useDisableCallRecordingMutation__
 *
 * To run a mutation, you first call `useDisableCallRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableCallRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableCallRecordingMutation, { data, loading, error }] = useDisableCallRecordingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableCallRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableCallRecordingMutation,
    DisableCallRecordingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableCallRecordingMutation,
    DisableCallRecordingMutationVariables
  >(DisableCallRecordingDocument, options);
}
export type DisableCallRecordingMutationHookResult = ReturnType<
  typeof useDisableCallRecordingMutation
>;
export type DisableCallRecordingMutationResult =
  Apollo.MutationResult<DisableCallRecordingMutation>;
export type DisableCallRecordingMutationOptions = Apollo.BaseMutationOptions<
  DisableCallRecordingMutation,
  DisableCallRecordingMutationVariables
>;
export const EndCallDocument = gql`
  mutation EndCall($callId: ID!) {
    endCall(callId: $callId) {
      call {
        id
        status
        endTime
        duration
      }
    }
  }
`;

/**
 * __useEndCallMutation__
 *
 * To run a mutation, you first call `useEndCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endCallMutation, { data, loading, error }] = useEndCallMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useEndCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndCallMutation,
    EndCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EndCallMutation, EndCallMutationVariables>(
    EndCallDocument,
    options
  );
}
export type EndCallMutationHookResult = ReturnType<typeof useEndCallMutation>;
export type EndCallMutationResult = Apollo.MutationResult<EndCallMutation>;
export type EndCallMutationOptions = Apollo.BaseMutationOptions<
  EndCallMutation,
  EndCallMutationVariables
>;
export const ExternalUserRequestAccessCodeDocument = gql`
  mutation ExternalUserRequestAccessCode($externalShareId: ID!) {
    externalUserRequestAccessCode(externalShareId: $externalShareId) {
      verificationSent
    }
  }
`;

/**
 * __useExternalUserRequestAccessCodeMutation__
 *
 * To run a mutation, you first call `useExternalUserRequestAccessCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalUserRequestAccessCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalUserRequestAccessCodeMutation, { data, loading, error }] = useExternalUserRequestAccessCodeMutation({
 *   variables: {
 *      externalShareId: // value for 'externalShareId'
 *   },
 * });
 */
export function useExternalUserRequestAccessCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExternalUserRequestAccessCodeMutation,
    ExternalUserRequestAccessCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExternalUserRequestAccessCodeMutation,
    ExternalUserRequestAccessCodeMutationVariables
  >(ExternalUserRequestAccessCodeDocument, options);
}
export type ExternalUserRequestAccessCodeMutationHookResult = ReturnType<
  typeof useExternalUserRequestAccessCodeMutation
>;
export type ExternalUserRequestAccessCodeMutationResult =
  Apollo.MutationResult<ExternalUserRequestAccessCodeMutation>;
export type ExternalUserRequestAccessCodeMutationOptions =
  Apollo.BaseMutationOptions<
    ExternalUserRequestAccessCodeMutation,
    ExternalUserRequestAccessCodeMutationVariables
  >;
export const ExternalUserRequestShareRenewalDocument = gql`
  mutation ExternalUserRequestShareRenewal($externalShareId: ID!) {
    externalUserRequestShareRenewal(externalShareId: $externalShareId) {
      requestSent
    }
  }
`;

/**
 * __useExternalUserRequestShareRenewalMutation__
 *
 * To run a mutation, you first call `useExternalUserRequestShareRenewalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalUserRequestShareRenewalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalUserRequestShareRenewalMutation, { data, loading, error }] = useExternalUserRequestShareRenewalMutation({
 *   variables: {
 *      externalShareId: // value for 'externalShareId'
 *   },
 * });
 */
export function useExternalUserRequestShareRenewalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExternalUserRequestShareRenewalMutation,
    ExternalUserRequestShareRenewalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExternalUserRequestShareRenewalMutation,
    ExternalUserRequestShareRenewalMutationVariables
  >(ExternalUserRequestShareRenewalDocument, options);
}
export type ExternalUserRequestShareRenewalMutationHookResult = ReturnType<
  typeof useExternalUserRequestShareRenewalMutation
>;
export type ExternalUserRequestShareRenewalMutationResult =
  Apollo.MutationResult<ExternalUserRequestShareRenewalMutation>;
export type ExternalUserRequestShareRenewalMutationOptions =
  Apollo.BaseMutationOptions<
    ExternalUserRequestShareRenewalMutation,
    ExternalUserRequestShareRenewalMutationVariables
  >;
export const ExternalUserViewCallDocument = gql`
  mutation ExternalUserViewCall($externalShareId: ID!) {
    externalUserViewCall(externalShareId: $externalShareId) {
      callViewed
      firstExternalView
    }
  }
`;

/**
 * __useExternalUserViewCallMutation__
 *
 * To run a mutation, you first call `useExternalUserViewCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalUserViewCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalUserViewCallMutation, { data, loading, error }] = useExternalUserViewCallMutation({
 *   variables: {
 *      externalShareId: // value for 'externalShareId'
 *   },
 * });
 */
export function useExternalUserViewCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExternalUserViewCallMutation,
    ExternalUserViewCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExternalUserViewCallMutation,
    ExternalUserViewCallMutationVariables
  >(ExternalUserViewCallDocument, options);
}
export type ExternalUserViewCallMutationHookResult = ReturnType<
  typeof useExternalUserViewCallMutation
>;
export type ExternalUserViewCallMutationResult =
  Apollo.MutationResult<ExternalUserViewCallMutation>;
export type ExternalUserViewCallMutationOptions = Apollo.BaseMutationOptions<
  ExternalUserViewCallMutation,
  ExternalUserViewCallMutationVariables
>;
export const ExternalUserViewClipDocument = gql`
  mutation ExternalUserViewClip($externalShareId: ID!) {
    externalUserViewClip(externalShareId: $externalShareId) {
      clipViewed
      firstExternalView
    }
  }
`;

/**
 * __useExternalUserViewClipMutation__
 *
 * To run a mutation, you first call `useExternalUserViewClipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalUserViewClipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalUserViewClipMutation, { data, loading, error }] = useExternalUserViewClipMutation({
 *   variables: {
 *      externalShareId: // value for 'externalShareId'
 *   },
 * });
 */
export function useExternalUserViewClipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExternalUserViewClipMutation,
    ExternalUserViewClipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExternalUserViewClipMutation,
    ExternalUserViewClipMutationVariables
  >(ExternalUserViewClipDocument, options);
}
export type ExternalUserViewClipMutationHookResult = ReturnType<
  typeof useExternalUserViewClipMutation
>;
export type ExternalUserViewClipMutationResult =
  Apollo.MutationResult<ExternalUserViewClipMutation>;
export type ExternalUserViewClipMutationOptions = Apollo.BaseMutationOptions<
  ExternalUserViewClipMutation,
  ExternalUserViewClipMutationVariables
>;
export const GenerateGreenhouseWebhookSecretDocument = gql`
  mutation GenerateGreenhouseWebhookSecret {
    generateGreenhouseWebhookSecret {
      webhookSecretKey
    }
  }
`;

/**
 * __useGenerateGreenhouseWebhookSecretMutation__
 *
 * To run a mutation, you first call `useGenerateGreenhouseWebhookSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateGreenhouseWebhookSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateGreenhouseWebhookSecretMutation, { data, loading, error }] = useGenerateGreenhouseWebhookSecretMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateGreenhouseWebhookSecretMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateGreenhouseWebhookSecretMutation,
    GenerateGreenhouseWebhookSecretMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateGreenhouseWebhookSecretMutation,
    GenerateGreenhouseWebhookSecretMutationVariables
  >(GenerateGreenhouseWebhookSecretDocument, options);
}
export type GenerateGreenhouseWebhookSecretMutationHookResult = ReturnType<
  typeof useGenerateGreenhouseWebhookSecretMutation
>;
export type GenerateGreenhouseWebhookSecretMutationResult =
  Apollo.MutationResult<GenerateGreenhouseWebhookSecretMutation>;
export type GenerateGreenhouseWebhookSecretMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateGreenhouseWebhookSecretMutation,
    GenerateGreenhouseWebhookSecretMutationVariables
  >;
export const GenerateTokenDocument = gql`
  mutation GenerateToken {
    generateToken {
      setup {
        oktaDomain
        metadataUrl
        scimToken
      }
    }
  }
`;

/**
 * __useGenerateTokenMutation__
 *
 * To run a mutation, you first call `useGenerateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTokenMutation, { data, loading, error }] = useGenerateTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateTokenMutation,
    GenerateTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateTokenMutation,
    GenerateTokenMutationVariables
  >(GenerateTokenDocument, options);
}
export type GenerateTokenMutationHookResult = ReturnType<
  typeof useGenerateTokenMutation
>;
export type GenerateTokenMutationResult =
  Apollo.MutationResult<GenerateTokenMutation>;
export type GenerateTokenMutationOptions = Apollo.BaseMutationOptions<
  GenerateTokenMutation,
  GenerateTokenMutationVariables
>;
export const GreenhouseCallParamsDocument = gql`
  mutation GreenhouseCallParams(
    $applicationId: String
    $interviewKitId: String
    $scorecardUrl: String
  ) {
    greenhouseCallParams(
      applicationId: $applicationId
      interviewKitId: $interviewKitId
      scorecardUrl: $scorecardUrl
    ) {
      params {
        name
        candidateFirstName
        candidateLastName
        phoneNumber
        callGuideId
        positionId
        greenhouseScorecardLink
        scheduledInterviewId
        candidateId
      }
    }
  }
`;

/**
 * __useGreenhouseCallParamsMutation__
 *
 * To run a mutation, you first call `useGreenhouseCallParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGreenhouseCallParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [greenhouseCallParamsMutation, { data, loading, error }] = useGreenhouseCallParamsMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      interviewKitId: // value for 'interviewKitId'
 *      scorecardUrl: // value for 'scorecardUrl'
 *   },
 * });
 */
export function useGreenhouseCallParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GreenhouseCallParamsMutation,
    GreenhouseCallParamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GreenhouseCallParamsMutation,
    GreenhouseCallParamsMutationVariables
  >(GreenhouseCallParamsDocument, options);
}
export type GreenhouseCallParamsMutationHookResult = ReturnType<
  typeof useGreenhouseCallParamsMutation
>;
export type GreenhouseCallParamsMutationResult =
  Apollo.MutationResult<GreenhouseCallParamsMutation>;
export type GreenhouseCallParamsMutationOptions = Apollo.BaseMutationOptions<
  GreenhouseCallParamsMutation,
  GreenhouseCallParamsMutationVariables
>;
export const GreenhouseCandidateParamsDocument = gql`
  mutation GreenhouseCandidateParams(
    $candidateId: String!
    $phoneNumber: String!
    $applicationId: String
  ) {
    greenhouseCandidateParams(
      candidateId: $candidateId
      phoneNumber: $phoneNumber
      applicationId: $applicationId
    ) {
      params {
        name
        candidateFirstName
        candidateLastName
        phoneNumber
        callGuideId
        positionId
        greenhouseScorecardLink
        scheduledInterviewId
        candidateId
      }
    }
  }
`;

/**
 * __useGreenhouseCandidateParamsMutation__
 *
 * To run a mutation, you first call `useGreenhouseCandidateParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGreenhouseCandidateParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [greenhouseCandidateParamsMutation, { data, loading, error }] = useGreenhouseCandidateParamsMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      phoneNumber: // value for 'phoneNumber'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGreenhouseCandidateParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GreenhouseCandidateParamsMutation,
    GreenhouseCandidateParamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GreenhouseCandidateParamsMutation,
    GreenhouseCandidateParamsMutationVariables
  >(GreenhouseCandidateParamsDocument, options);
}
export type GreenhouseCandidateParamsMutationHookResult = ReturnType<
  typeof useGreenhouseCandidateParamsMutation
>;
export type GreenhouseCandidateParamsMutationResult =
  Apollo.MutationResult<GreenhouseCandidateParamsMutation>;
export type GreenhouseCandidateParamsMutationOptions =
  Apollo.BaseMutationOptions<
    GreenhouseCandidateParamsMutation,
    GreenhouseCandidateParamsMutationVariables
  >;
export const GreenhouseFreemiumSyncDocument = gql`
  mutation GreenhouseFreemiumSync {
    greenhouseFreemiumSync {
      processed
    }
  }
`;

/**
 * __useGreenhouseFreemiumSyncMutation__
 *
 * To run a mutation, you first call `useGreenhouseFreemiumSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGreenhouseFreemiumSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [greenhouseFreemiumSyncMutation, { data, loading, error }] = useGreenhouseFreemiumSyncMutation({
 *   variables: {
 *   },
 * });
 */
export function useGreenhouseFreemiumSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GreenhouseFreemiumSyncMutation,
    GreenhouseFreemiumSyncMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GreenhouseFreemiumSyncMutation,
    GreenhouseFreemiumSyncMutationVariables
  >(GreenhouseFreemiumSyncDocument, options);
}
export type GreenhouseFreemiumSyncMutationHookResult = ReturnType<
  typeof useGreenhouseFreemiumSyncMutation
>;
export type GreenhouseFreemiumSyncMutationResult =
  Apollo.MutationResult<GreenhouseFreemiumSyncMutation>;
export type GreenhouseFreemiumSyncMutationOptions = Apollo.BaseMutationOptions<
  GreenhouseFreemiumSyncMutation,
  GreenhouseFreemiumSyncMutationVariables
>;
export const GreenhousePhoneNumberParamsDocument = gql`
  mutation GreenhousePhoneNumberParams(
    $phoneNumber: String!
    $scorecardUrl: String
  ) {
    greenhousePhoneNumberParams(
      phoneNumber: $phoneNumber
      scorecardUrl: $scorecardUrl
    ) {
      params {
        name
        candidateFirstName
        candidateLastName
        phoneNumber
        callGuideId
        positionId
        greenhouseScorecardLink
        scheduledInterviewId
        candidateId
      }
    }
  }
`;

/**
 * __useGreenhousePhoneNumberParamsMutation__
 *
 * To run a mutation, you first call `useGreenhousePhoneNumberParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGreenhousePhoneNumberParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [greenhousePhoneNumberParamsMutation, { data, loading, error }] = useGreenhousePhoneNumberParamsMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      scorecardUrl: // value for 'scorecardUrl'
 *   },
 * });
 */
export function useGreenhousePhoneNumberParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GreenhousePhoneNumberParamsMutation,
    GreenhousePhoneNumberParamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GreenhousePhoneNumberParamsMutation,
    GreenhousePhoneNumberParamsMutationVariables
  >(GreenhousePhoneNumberParamsDocument, options);
}
export type GreenhousePhoneNumberParamsMutationHookResult = ReturnType<
  typeof useGreenhousePhoneNumberParamsMutation
>;
export type GreenhousePhoneNumberParamsMutationResult =
  Apollo.MutationResult<GreenhousePhoneNumberParamsMutation>;
export type GreenhousePhoneNumberParamsMutationOptions =
  Apollo.BaseMutationOptions<
    GreenhousePhoneNumberParamsMutation,
    GreenhousePhoneNumberParamsMutationVariables
  >;
export const IcimsCandidateParamsDocument = gql`
  mutation IcimsCandidateParams(
    $phoneNumber: String!
    $candidateFullName: String
    $candidateEmail: String
    $atsCandidateId: String
  ) {
    icimsCandidateParams(
      phoneNumber: $phoneNumber
      candidateFullName: $candidateFullName
      candidateEmail: $candidateEmail
      atsCandidateId: $atsCandidateId
    ) {
      params {
        candidateFirstName
        candidateLastName
        phoneNumber
        candidateEmail
        positionId
        name
      }
    }
  }
`;

/**
 * __useIcimsCandidateParamsMutation__
 *
 * To run a mutation, you first call `useIcimsCandidateParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIcimsCandidateParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [icimsCandidateParamsMutation, { data, loading, error }] = useIcimsCandidateParamsMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      candidateFullName: // value for 'candidateFullName'
 *      candidateEmail: // value for 'candidateEmail'
 *      atsCandidateId: // value for 'atsCandidateId'
 *   },
 * });
 */
export function useIcimsCandidateParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IcimsCandidateParamsMutation,
    IcimsCandidateParamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IcimsCandidateParamsMutation,
    IcimsCandidateParamsMutationVariables
  >(IcimsCandidateParamsDocument, options);
}
export type IcimsCandidateParamsMutationHookResult = ReturnType<
  typeof useIcimsCandidateParamsMutation
>;
export type IcimsCandidateParamsMutationResult =
  Apollo.MutationResult<IcimsCandidateParamsMutation>;
export type IcimsCandidateParamsMutationOptions = Apollo.BaseMutationOptions<
  IcimsCandidateParamsMutation,
  IcimsCandidateParamsMutationVariables
>;
export const ImportGoogleMeetMeetingDocument = gql`
  mutation ImportGoogleMeetMeeting(
    $meetingUrl: String!
    $meetingId: String
    $entryPoint: InterviewAssistantEntryPoint
    $forceImport: Boolean
  ) {
    importGoogleMeetMeeting(
      meetingUrl: $meetingUrl
      meetingId: $meetingId
      entryPoint: $entryPoint
      forceImport: $forceImport
    ) {
      meeting {
        id
        importMeeting
        exclude
        excludeReason
        meetingUrl
        call {
          id
        }
      }
    }
  }
`;

/**
 * __useImportGoogleMeetMeetingMutation__
 *
 * To run a mutation, you first call `useImportGoogleMeetMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportGoogleMeetMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importGoogleMeetMeetingMutation, { data, loading, error }] = useImportGoogleMeetMeetingMutation({
 *   variables: {
 *      meetingUrl: // value for 'meetingUrl'
 *      meetingId: // value for 'meetingId'
 *      entryPoint: // value for 'entryPoint'
 *      forceImport: // value for 'forceImport'
 *   },
 * });
 */
export function useImportGoogleMeetMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportGoogleMeetMeetingMutation,
    ImportGoogleMeetMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportGoogleMeetMeetingMutation,
    ImportGoogleMeetMeetingMutationVariables
  >(ImportGoogleMeetMeetingDocument, options);
}
export type ImportGoogleMeetMeetingMutationHookResult = ReturnType<
  typeof useImportGoogleMeetMeetingMutation
>;
export type ImportGoogleMeetMeetingMutationResult =
  Apollo.MutationResult<ImportGoogleMeetMeetingMutation>;
export type ImportGoogleMeetMeetingMutationOptions = Apollo.BaseMutationOptions<
  ImportGoogleMeetMeetingMutation,
  ImportGoogleMeetMeetingMutationVariables
>;
export const ImportTeamsNotetakerMeetingDocument = gql`
  mutation ImportTeamsNotetakerMeeting(
    $meetingUrl: String!
    $meetingId: String
    $entryPoint: InterviewAssistantEntryPoint
    $forceImport: Boolean
  ) {
    importTeamsNotetakerMeeting(
      meetingUrl: $meetingUrl
      meetingId: $meetingId
      entryPoint: $entryPoint
      forceImport: $forceImport
    ) {
      meeting {
        id
        importMeeting
        exclude
        excludeReason
        meetingUrl
        call {
          id
        }
      }
    }
  }
`;

/**
 * __useImportTeamsNotetakerMeetingMutation__
 *
 * To run a mutation, you first call `useImportTeamsNotetakerMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTeamsNotetakerMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTeamsNotetakerMeetingMutation, { data, loading, error }] = useImportTeamsNotetakerMeetingMutation({
 *   variables: {
 *      meetingUrl: // value for 'meetingUrl'
 *      meetingId: // value for 'meetingId'
 *      entryPoint: // value for 'entryPoint'
 *      forceImport: // value for 'forceImport'
 *   },
 * });
 */
export function useImportTeamsNotetakerMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportTeamsNotetakerMeetingMutation,
    ImportTeamsNotetakerMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportTeamsNotetakerMeetingMutation,
    ImportTeamsNotetakerMeetingMutationVariables
  >(ImportTeamsNotetakerMeetingDocument, options);
}
export type ImportTeamsNotetakerMeetingMutationHookResult = ReturnType<
  typeof useImportTeamsNotetakerMeetingMutation
>;
export type ImportTeamsNotetakerMeetingMutationResult =
  Apollo.MutationResult<ImportTeamsNotetakerMeetingMutation>;
export type ImportTeamsNotetakerMeetingMutationOptions =
  Apollo.BaseMutationOptions<
    ImportTeamsNotetakerMeetingMutation,
    ImportTeamsNotetakerMeetingMutationVariables
  >;
export const ImportZoomMeetingDocument = gql`
  mutation ImportZoomMeeting(
    $meetingId: Float!
    $entryPoint: InterviewAssistantEntryPoint
    $forceImport: Boolean
  ) {
    importZoomMeeting(
      meetingId: $meetingId
      entryPoint: $entryPoint
      forceImport: $forceImport
    ) {
      meeting {
        ...ImportZoomMeeting
        zoomAppAutoOpenEnabled
      }
      notetakerMeeting {
        id
        importMeeting
        exclude
        excludeReason
        meetingUrl
        zoomId
        scheduledInterview {
          id
          isInterviewer
        }
        virtualOnsite {
          id
          isScheduledInterviewer
        }
        callId
        zoomAppAutoOpenEnabled
      }
    }
  }
  ${ImportZoomMeetingFragmentDoc}
`;

/**
 * __useImportZoomMeetingMutation__
 *
 * To run a mutation, you first call `useImportZoomMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportZoomMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importZoomMeetingMutation, { data, loading, error }] = useImportZoomMeetingMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      entryPoint: // value for 'entryPoint'
 *      forceImport: // value for 'forceImport'
 *   },
 * });
 */
export function useImportZoomMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportZoomMeetingMutation,
    ImportZoomMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportZoomMeetingMutation,
    ImportZoomMeetingMutationVariables
  >(ImportZoomMeetingDocument, options);
}
export type ImportZoomMeetingMutationHookResult = ReturnType<
  typeof useImportZoomMeetingMutation
>;
export type ImportZoomMeetingMutationResult =
  Apollo.MutationResult<ImportZoomMeetingMutation>;
export type ImportZoomMeetingMutationOptions = Apollo.BaseMutationOptions<
  ImportZoomMeetingMutation,
  ImportZoomMeetingMutationVariables
>;
export const InforCandidateParamsDocument = gql`
  mutation InforCandidateParams(
    $phoneNumber: String!
    $candidateFullName: String
    $candidateEmail: String
    $atsCandidateId: String
    $jobCode: String
    $jobCodeId: String
    $candidateType: String
    $requisitionId: String
  ) {
    inforCandidateParams(
      phoneNumber: $phoneNumber
      candidateFullName: $candidateFullName
      candidateEmail: $candidateEmail
      atsCandidateId: $atsCandidateId
      jobCode: $jobCode
      jobCodeId: $jobCodeId
      candidateType: $candidateType
      requisitionId: $requisitionId
    ) {
      params {
        candidateFirstName
        candidateLastName
        phoneNumber
        candidateEmail
        candidateId
        positionId
        name
        atsRequisitionId
      }
    }
  }
`;

/**
 * __useInforCandidateParamsMutation__
 *
 * To run a mutation, you first call `useInforCandidateParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInforCandidateParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inforCandidateParamsMutation, { data, loading, error }] = useInforCandidateParamsMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      candidateFullName: // value for 'candidateFullName'
 *      candidateEmail: // value for 'candidateEmail'
 *      atsCandidateId: // value for 'atsCandidateId'
 *      jobCode: // value for 'jobCode'
 *      jobCodeId: // value for 'jobCodeId'
 *      candidateType: // value for 'candidateType'
 *      requisitionId: // value for 'requisitionId'
 *   },
 * });
 */
export function useInforCandidateParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InforCandidateParamsMutation,
    InforCandidateParamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InforCandidateParamsMutation,
    InforCandidateParamsMutationVariables
  >(InforCandidateParamsDocument, options);
}
export type InforCandidateParamsMutationHookResult = ReturnType<
  typeof useInforCandidateParamsMutation
>;
export type InforCandidateParamsMutationResult =
  Apollo.MutationResult<InforCandidateParamsMutation>;
export type InforCandidateParamsMutationOptions = Apollo.BaseMutationOptions<
  InforCandidateParamsMutation,
  InforCandidateParamsMutationVariables
>;
export const InstallMergeIntegrationDocument = gql`
  mutation InstallMergeIntegration($publicToken: String!) {
    installMergeIntegration(publicToken: $publicToken) {
      setup {
        valid
      }
    }
  }
`;

/**
 * __useInstallMergeIntegrationMutation__
 *
 * To run a mutation, you first call `useInstallMergeIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstallMergeIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [installMergeIntegrationMutation, { data, loading, error }] = useInstallMergeIntegrationMutation({
 *   variables: {
 *      publicToken: // value for 'publicToken'
 *   },
 * });
 */
export function useInstallMergeIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstallMergeIntegrationMutation,
    InstallMergeIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstallMergeIntegrationMutation,
    InstallMergeIntegrationMutationVariables
  >(InstallMergeIntegrationDocument, options);
}
export type InstallMergeIntegrationMutationHookResult = ReturnType<
  typeof useInstallMergeIntegrationMutation
>;
export type InstallMergeIntegrationMutationResult =
  Apollo.MutationResult<InstallMergeIntegrationMutation>;
export type InstallMergeIntegrationMutationOptions = Apollo.BaseMutationOptions<
  InstallMergeIntegrationMutation,
  InstallMergeIntegrationMutationVariables
>;
export const InstallZoomNotetakerAppStatusDocument = gql`
  query InstallZoomNotetakerAppStatus {
    zoomBotInstallStatus
  }
`;

/**
 * __useInstallZoomNotetakerAppStatusQuery__
 *
 * To run a query within a React component, call `useInstallZoomNotetakerAppStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallZoomNotetakerAppStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallZoomNotetakerAppStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstallZoomNotetakerAppStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InstallZoomNotetakerAppStatusQuery,
    InstallZoomNotetakerAppStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstallZoomNotetakerAppStatusQuery,
    InstallZoomNotetakerAppStatusQueryVariables
  >(InstallZoomNotetakerAppStatusDocument, options);
}
export function useInstallZoomNotetakerAppStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstallZoomNotetakerAppStatusQuery,
    InstallZoomNotetakerAppStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstallZoomNotetakerAppStatusQuery,
    InstallZoomNotetakerAppStatusQueryVariables
  >(InstallZoomNotetakerAppStatusDocument, options);
}
export type InstallZoomNotetakerAppStatusQueryHookResult = ReturnType<
  typeof useInstallZoomNotetakerAppStatusQuery
>;
export type InstallZoomNotetakerAppStatusLazyQueryHookResult = ReturnType<
  typeof useInstallZoomNotetakerAppStatusLazyQuery
>;
export type InstallZoomNotetakerAppStatusQueryResult = Apollo.QueryResult<
  InstallZoomNotetakerAppStatusQuery,
  InstallZoomNotetakerAppStatusQueryVariables
>;
export const InviteUsersDocument = gql`
  mutation InviteUsers($users: [InviteUser]!) {
    inviteUsers(users: $users) {
      users {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useInviteUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUsersMutation,
    InviteUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(
    InviteUsersDocument,
    options
  );
}
export type InviteUsersMutationHookResult = ReturnType<
  typeof useInviteUsersMutation
>;
export type InviteUsersMutationResult =
  Apollo.MutationResult<InviteUsersMutation>;
export type InviteUsersMutationOptions = Apollo.BaseMutationOptions<
  InviteUsersMutation,
  InviteUsersMutationVariables
>;
export const JoinZoomNotetakerMeetingDocument = gql`
  mutation JoinZoomNotetakerMeeting($zoomNotetakerMeetingId: ID!) {
    joinZoomNotetakerMeeting(zoomNotetakerMeetingId: $zoomNotetakerMeetingId) {
      meeting {
        id
        callId
      }
    }
  }
`;

/**
 * __useJoinZoomNotetakerMeetingMutation__
 *
 * To run a mutation, you first call `useJoinZoomNotetakerMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinZoomNotetakerMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinZoomNotetakerMeetingMutation, { data, loading, error }] = useJoinZoomNotetakerMeetingMutation({
 *   variables: {
 *      zoomNotetakerMeetingId: // value for 'zoomNotetakerMeetingId'
 *   },
 * });
 */
export function useJoinZoomNotetakerMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinZoomNotetakerMeetingMutation,
    JoinZoomNotetakerMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinZoomNotetakerMeetingMutation,
    JoinZoomNotetakerMeetingMutationVariables
  >(JoinZoomNotetakerMeetingDocument, options);
}
export type JoinZoomNotetakerMeetingMutationHookResult = ReturnType<
  typeof useJoinZoomNotetakerMeetingMutation
>;
export type JoinZoomNotetakerMeetingMutationResult =
  Apollo.MutationResult<JoinZoomNotetakerMeetingMutation>;
export type JoinZoomNotetakerMeetingMutationOptions =
  Apollo.BaseMutationOptions<
    JoinZoomNotetakerMeetingMutation,
    JoinZoomNotetakerMeetingMutationVariables
  >;
export const KeepCallDocument = gql`
  mutation KeepCall($id: ID!) {
    keepCall(id: $id) {
      call {
        id
        skipRedaction
        canDelete
      }
      success
    }
  }
`;

/**
 * __useKeepCallMutation__
 *
 * To run a mutation, you first call `useKeepCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKeepCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [keepCallMutation, { data, loading, error }] = useKeepCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKeepCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    KeepCallMutation,
    KeepCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<KeepCallMutation, KeepCallMutationVariables>(
    KeepCallDocument,
    options
  );
}
export type KeepCallMutationHookResult = ReturnType<typeof useKeepCallMutation>;
export type KeepCallMutationResult = Apollo.MutationResult<KeepCallMutation>;
export type KeepCallMutationOptions = Apollo.BaseMutationOptions<
  KeepCallMutation,
  KeepCallMutationVariables
>;
export const KeepCandidateDocument = gql`
  mutation KeepCandidate($id: ID!) {
    keepCandidate(id: $id) {
      candidate {
        id
        fullName
        skipRedaction
      }
      success
    }
  }
`;

/**
 * __useKeepCandidateMutation__
 *
 * To run a mutation, you first call `useKeepCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKeepCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [keepCandidateMutation, { data, loading, error }] = useKeepCandidateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKeepCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    KeepCandidateMutation,
    KeepCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    KeepCandidateMutation,
    KeepCandidateMutationVariables
  >(KeepCandidateDocument, options);
}
export type KeepCandidateMutationHookResult = ReturnType<
  typeof useKeepCandidateMutation
>;
export type KeepCandidateMutationResult =
  Apollo.MutationResult<KeepCandidateMutation>;
export type KeepCandidateMutationOptions = Apollo.BaseMutationOptions<
  KeepCandidateMutation,
  KeepCandidateMutationVariables
>;
export const LeverAuthOnRedirectDocument = gql`
  mutation LeverAuthOnRedirect(
    $leverStateToken: String!
    $leverCode: String
    $responseError: String
    $responseErrorDescription: String
  ) {
    leverAuthOnRedirect(
      leverStateToken: $leverStateToken
      leverCode: $leverCode
      responseError: $responseError
      responseErrorDescription: $responseErrorDescription
    ) {
      leverOauthSetup {
        id
        useSandbox
        valid
        syncCallGuidesFromAts
        createSecretNotes
      }
    }
  }
`;

/**
 * __useLeverAuthOnRedirectMutation__
 *
 * To run a mutation, you first call `useLeverAuthOnRedirectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeverAuthOnRedirectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leverAuthOnRedirectMutation, { data, loading, error }] = useLeverAuthOnRedirectMutation({
 *   variables: {
 *      leverStateToken: // value for 'leverStateToken'
 *      leverCode: // value for 'leverCode'
 *      responseError: // value for 'responseError'
 *      responseErrorDescription: // value for 'responseErrorDescription'
 *   },
 * });
 */
export function useLeverAuthOnRedirectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeverAuthOnRedirectMutation,
    LeverAuthOnRedirectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeverAuthOnRedirectMutation,
    LeverAuthOnRedirectMutationVariables
  >(LeverAuthOnRedirectDocument, options);
}
export type LeverAuthOnRedirectMutationHookResult = ReturnType<
  typeof useLeverAuthOnRedirectMutation
>;
export type LeverAuthOnRedirectMutationResult =
  Apollo.MutationResult<LeverAuthOnRedirectMutation>;
export type LeverAuthOnRedirectMutationOptions = Apollo.BaseMutationOptions<
  LeverAuthOnRedirectMutation,
  LeverAuthOnRedirectMutationVariables
>;
export const LeverCallParamsDocument = gql`
  mutation LeverCallParams($interviewId: String!, $phoneNumber: String) {
    leverCallParams(interviewId: $interviewId, phoneNumber: $phoneNumber) {
      callParams {
        name
        candidateFirstName
        candidateLastName
        candidateEmail
        phoneNumber
        callGuideId
        positionId
        leverInterviewLink
        scheduledInterviewId
        candidateId
      }
    }
  }
`;

/**
 * __useLeverCallParamsMutation__
 *
 * To run a mutation, you first call `useLeverCallParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeverCallParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leverCallParamsMutation, { data, loading, error }] = useLeverCallParamsMutation({
 *   variables: {
 *      interviewId: // value for 'interviewId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useLeverCallParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeverCallParamsMutation,
    LeverCallParamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeverCallParamsMutation,
    LeverCallParamsMutationVariables
  >(LeverCallParamsDocument, options);
}
export type LeverCallParamsMutationHookResult = ReturnType<
  typeof useLeverCallParamsMutation
>;
export type LeverCallParamsMutationResult =
  Apollo.MutationResult<LeverCallParamsMutation>;
export type LeverCallParamsMutationOptions = Apollo.BaseMutationOptions<
  LeverCallParamsMutation,
  LeverCallParamsMutationVariables
>;
export const LeverCandidateParamsDocument = gql`
  mutation LeverCandidateParams($opportunityId: String!) {
    leverCandidateParams(opportunityId: $opportunityId) {
      callParams {
        candidateFirstName
        candidateLastName
        candidateEmail
        phoneNumber
        positionId
        scheduledInterviewId
        name
        candidateId
        callGuideId
      }
    }
  }
`;

/**
 * __useLeverCandidateParamsMutation__
 *
 * To run a mutation, you first call `useLeverCandidateParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeverCandidateParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leverCandidateParamsMutation, { data, loading, error }] = useLeverCandidateParamsMutation({
 *   variables: {
 *      opportunityId: // value for 'opportunityId'
 *   },
 * });
 */
export function useLeverCandidateParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeverCandidateParamsMutation,
    LeverCandidateParamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeverCandidateParamsMutation,
    LeverCandidateParamsMutationVariables
  >(LeverCandidateParamsDocument, options);
}
export type LeverCandidateParamsMutationHookResult = ReturnType<
  typeof useLeverCandidateParamsMutation
>;
export type LeverCandidateParamsMutationResult =
  Apollo.MutationResult<LeverCandidateParamsMutation>;
export type LeverCandidateParamsMutationOptions = Apollo.BaseMutationOptions<
  LeverCandidateParamsMutation,
  LeverCandidateParamsMutationVariables
>;
export const MakeOutgoingCallDocument = gql`
  mutation MakeOutgoingCall($callId: ID!) {
    makeOutgoingCall(callId: $callId) {
      call {
        id
        type
      }
    }
  }
`;

/**
 * __useMakeOutgoingCallMutation__
 *
 * To run a mutation, you first call `useMakeOutgoingCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeOutgoingCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeOutgoingCallMutation, { data, loading, error }] = useMakeOutgoingCallMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useMakeOutgoingCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeOutgoingCallMutation,
    MakeOutgoingCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakeOutgoingCallMutation,
    MakeOutgoingCallMutationVariables
  >(MakeOutgoingCallDocument, options);
}
export type MakeOutgoingCallMutationHookResult = ReturnType<
  typeof useMakeOutgoingCallMutation
>;
export type MakeOutgoingCallMutationResult =
  Apollo.MutationResult<MakeOutgoingCallMutation>;
export type MakeOutgoingCallMutationOptions = Apollo.BaseMutationOptions<
  MakeOutgoingCallMutation,
  MakeOutgoingCallMutationVariables
>;
export const MarkTrainingProgramTraineeCompleteDocument = gql`
  mutation MarkTrainingProgramTraineeComplete($id: ID!, $complete: Boolean!) {
    markTrainingProgramTraineeComplete(id: $id, complete: $complete) {
      trainingProgramTrainee {
        id
        markedCompletedAt
      }
    }
  }
`;

/**
 * __useMarkTrainingProgramTraineeCompleteMutation__
 *
 * To run a mutation, you first call `useMarkTrainingProgramTraineeCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTrainingProgramTraineeCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTrainingProgramTraineeCompleteMutation, { data, loading, error }] = useMarkTrainingProgramTraineeCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      complete: // value for 'complete'
 *   },
 * });
 */
export function useMarkTrainingProgramTraineeCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkTrainingProgramTraineeCompleteMutation,
    MarkTrainingProgramTraineeCompleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkTrainingProgramTraineeCompleteMutation,
    MarkTrainingProgramTraineeCompleteMutationVariables
  >(MarkTrainingProgramTraineeCompleteDocument, options);
}
export type MarkTrainingProgramTraineeCompleteMutationHookResult = ReturnType<
  typeof useMarkTrainingProgramTraineeCompleteMutation
>;
export type MarkTrainingProgramTraineeCompleteMutationResult =
  Apollo.MutationResult<MarkTrainingProgramTraineeCompleteMutation>;
export type MarkTrainingProgramTraineeCompleteMutationOptions =
  Apollo.BaseMutationOptions<
    MarkTrainingProgramTraineeCompleteMutation,
    MarkTrainingProgramTraineeCompleteMutationVariables
  >;
export const MuteCandidateAlertUserSubscriptionsDocument = gql`
  mutation muteCandidateAlertUserSubscriptions(
    $candidateIds: [ID!]!
    $positionIds: [ID!]!
  ) {
    muteCandidateAlertUserSubscriptions(
      candidateIds: $candidateIds
      positionIds: $positionIds
    ) {
      subscriptions {
        id
        candidateSubscriptions {
          id
          candidateId
          mutedAt
        }
        positionSubscriptions {
          id
          positionId
          mutedAt
        }
      }
    }
  }
`;

/**
 * __useMuteCandidateAlertUserSubscriptionsMutation__
 *
 * To run a mutation, you first call `useMuteCandidateAlertUserSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMuteCandidateAlertUserSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [muteCandidateAlertUserSubscriptionsMutation, { data, loading, error }] = useMuteCandidateAlertUserSubscriptionsMutation({
 *   variables: {
 *      candidateIds: // value for 'candidateIds'
 *      positionIds: // value for 'positionIds'
 *   },
 * });
 */
export function useMuteCandidateAlertUserSubscriptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MuteCandidateAlertUserSubscriptionsMutation,
    MuteCandidateAlertUserSubscriptionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MuteCandidateAlertUserSubscriptionsMutation,
    MuteCandidateAlertUserSubscriptionsMutationVariables
  >(MuteCandidateAlertUserSubscriptionsDocument, options);
}
export type MuteCandidateAlertUserSubscriptionsMutationHookResult = ReturnType<
  typeof useMuteCandidateAlertUserSubscriptionsMutation
>;
export type MuteCandidateAlertUserSubscriptionsMutationResult =
  Apollo.MutationResult<MuteCandidateAlertUserSubscriptionsMutation>;
export type MuteCandidateAlertUserSubscriptionsMutationOptions =
  Apollo.BaseMutationOptions<
    MuteCandidateAlertUserSubscriptionsMutation,
    MuteCandidateAlertUserSubscriptionsMutationVariables
  >;
export const OutlookWebAppCallParamsDocument = gql`
  mutation OutlookWebAppCallParams($phoneNumber: String!, $eventTitle: String) {
    outlookWebAppCallParams(
      phoneNumber: $phoneNumber
      eventTitle: $eventTitle
    ) {
      params {
        candidateFirstName
        candidateLastName
        phoneNumber
        candidateEmail
        candidateId
        name
      }
    }
  }
`;

/**
 * __useOutlookWebAppCallParamsMutation__
 *
 * To run a mutation, you first call `useOutlookWebAppCallParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOutlookWebAppCallParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [outlookWebAppCallParamsMutation, { data, loading, error }] = useOutlookWebAppCallParamsMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      eventTitle: // value for 'eventTitle'
 *   },
 * });
 */
export function useOutlookWebAppCallParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OutlookWebAppCallParamsMutation,
    OutlookWebAppCallParamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OutlookWebAppCallParamsMutation,
    OutlookWebAppCallParamsMutationVariables
  >(OutlookWebAppCallParamsDocument, options);
}
export type OutlookWebAppCallParamsMutationHookResult = ReturnType<
  typeof useOutlookWebAppCallParamsMutation
>;
export type OutlookWebAppCallParamsMutationResult =
  Apollo.MutationResult<OutlookWebAppCallParamsMutation>;
export type OutlookWebAppCallParamsMutationOptions = Apollo.BaseMutationOptions<
  OutlookWebAppCallParamsMutation,
  OutlookWebAppCallParamsMutationVariables
>;
export const PauseCallRecordingDocument = gql`
  mutation PauseCallRecording($callId: ID!, $pauseRecording: Boolean!) {
    pauseCallRecording(callId: $callId, pauseRecording: $pauseRecording) {
      call {
        id
        recordingPaused
        recordingPauseInProgress
      }
    }
  }
`;

/**
 * __usePauseCallRecordingMutation__
 *
 * To run a mutation, you first call `usePauseCallRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseCallRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseCallRecordingMutation, { data, loading, error }] = usePauseCallRecordingMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      pauseRecording: // value for 'pauseRecording'
 *   },
 * });
 */
export function usePauseCallRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PauseCallRecordingMutation,
    PauseCallRecordingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PauseCallRecordingMutation,
    PauseCallRecordingMutationVariables
  >(PauseCallRecordingDocument, options);
}
export type PauseCallRecordingMutationHookResult = ReturnType<
  typeof usePauseCallRecordingMutation
>;
export type PauseCallRecordingMutationResult =
  Apollo.MutationResult<PauseCallRecordingMutation>;
export type PauseCallRecordingMutationOptions = Apollo.BaseMutationOptions<
  PauseCallRecordingMutation,
  PauseCallRecordingMutationVariables
>;
export const ReTranscribeCallDocument = gql`
  mutation ReTranscribeCall($id: ID!, $language: String) {
    reTranscribeCall(id: $id, language: $language) {
      call {
        id
        isProcessing
        transcriptionStatus
      }
    }
  }
`;

/**
 * __useReTranscribeCallMutation__
 *
 * To run a mutation, you first call `useReTranscribeCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReTranscribeCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reTranscribeCallMutation, { data, loading, error }] = useReTranscribeCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useReTranscribeCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReTranscribeCallMutation,
    ReTranscribeCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReTranscribeCallMutation,
    ReTranscribeCallMutationVariables
  >(ReTranscribeCallDocument, options);
}
export type ReTranscribeCallMutationHookResult = ReturnType<
  typeof useReTranscribeCallMutation
>;
export type ReTranscribeCallMutationResult =
  Apollo.MutationResult<ReTranscribeCallMutation>;
export type ReTranscribeCallMutationOptions = Apollo.BaseMutationOptions<
  ReTranscribeCallMutation,
  ReTranscribeCallMutationVariables
>;
export const ReactivateUserDocument = gql`
  mutation ReactivateUser($id: ID!) {
    reactivateUser(id: $id) {
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useReactivateUserMutation__
 *
 * To run a mutation, you first call `useReactivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateUserMutation, { data, loading, error }] = useReactivateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReactivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactivateUserMutation,
    ReactivateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReactivateUserMutation,
    ReactivateUserMutationVariables
  >(ReactivateUserDocument, options);
}
export type ReactivateUserMutationHookResult = ReturnType<
  typeof useReactivateUserMutation
>;
export type ReactivateUserMutationResult =
  Apollo.MutationResult<ReactivateUserMutation>;
export type ReactivateUserMutationOptions = Apollo.BaseMutationOptions<
  ReactivateUserMutation,
  ReactivateUserMutationVariables
>;
export const RedactScheduledCallsDocument = gql`
  mutation RedactScheduledCalls {
    redactScheduledCalls {
      success
    }
  }
`;

/**
 * __useRedactScheduledCallsMutation__
 *
 * To run a mutation, you first call `useRedactScheduledCallsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedactScheduledCallsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redactScheduledCallsMutation, { data, loading, error }] = useRedactScheduledCallsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRedactScheduledCallsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedactScheduledCallsMutation,
    RedactScheduledCallsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RedactScheduledCallsMutation,
    RedactScheduledCallsMutationVariables
  >(RedactScheduledCallsDocument, options);
}
export type RedactScheduledCallsMutationHookResult = ReturnType<
  typeof useRedactScheduledCallsMutation
>;
export type RedactScheduledCallsMutationResult =
  Apollo.MutationResult<RedactScheduledCallsMutation>;
export type RedactScheduledCallsMutationOptions = Apollo.BaseMutationOptions<
  RedactScheduledCallsMutation,
  RedactScheduledCallsMutationVariables
>;
export const RedactScheduledCandidatesDocument = gql`
  mutation RedactScheduledCandidates(
    $candidateStatus: CandidateRedactionStatus
  ) {
    redactScheduledCandidates(candidateStatus: $candidateStatus) {
      success
    }
  }
`;

/**
 * __useRedactScheduledCandidatesMutation__
 *
 * To run a mutation, you first call `useRedactScheduledCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedactScheduledCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redactScheduledCandidatesMutation, { data, loading, error }] = useRedactScheduledCandidatesMutation({
 *   variables: {
 *      candidateStatus: // value for 'candidateStatus'
 *   },
 * });
 */
export function useRedactScheduledCandidatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedactScheduledCandidatesMutation,
    RedactScheduledCandidatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RedactScheduledCandidatesMutation,
    RedactScheduledCandidatesMutationVariables
  >(RedactScheduledCandidatesDocument, options);
}
export type RedactScheduledCandidatesMutationHookResult = ReturnType<
  typeof useRedactScheduledCandidatesMutation
>;
export type RedactScheduledCandidatesMutationResult =
  Apollo.MutationResult<RedactScheduledCandidatesMutation>;
export type RedactScheduledCandidatesMutationOptions =
  Apollo.BaseMutationOptions<
    RedactScheduledCandidatesMutation,
    RedactScheduledCandidatesMutationVariables
  >;
export const RedactCallDocument = gql`
  mutation RedactCall($id: ID!) {
    redactCall(id: $id) {
      success
    }
  }
`;

/**
 * __useRedactCallMutation__
 *
 * To run a mutation, you first call `useRedactCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedactCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redactCallMutation, { data, loading, error }] = useRedactCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRedactCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedactCallMutation,
    RedactCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RedactCallMutation, RedactCallMutationVariables>(
    RedactCallDocument,
    options
  );
}
export type RedactCallMutationHookResult = ReturnType<
  typeof useRedactCallMutation
>;
export type RedactCallMutationResult =
  Apollo.MutationResult<RedactCallMutation>;
export type RedactCallMutationOptions = Apollo.BaseMutationOptions<
  RedactCallMutation,
  RedactCallMutationVariables
>;
export const RedactCandidateDocument = gql`
  mutation RedactCandidate($id: ID!) {
    redactCandidate(id: $id) {
      success
    }
  }
`;

/**
 * __useRedactCandidateMutation__
 *
 * To run a mutation, you first call `useRedactCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedactCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redactCandidateMutation, { data, loading, error }] = useRedactCandidateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRedactCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedactCandidateMutation,
    RedactCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RedactCandidateMutation,
    RedactCandidateMutationVariables
  >(RedactCandidateDocument, options);
}
export type RedactCandidateMutationHookResult = ReturnType<
  typeof useRedactCandidateMutation
>;
export type RedactCandidateMutationResult =
  Apollo.MutationResult<RedactCandidateMutation>;
export type RedactCandidateMutationOptions = Apollo.BaseMutationOptions<
  RedactCandidateMutation,
  RedactCandidateMutationVariables
>;
export const RedialDocument = gql`
  mutation Redial($callId: ID!) {
    redial(callId: $callId) {
      call {
        id
        type
      }
    }
  }
`;

/**
 * __useRedialMutation__
 *
 * To run a mutation, you first call `useRedialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redialMutation, { data, loading, error }] = useRedialMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useRedialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedialMutation,
    RedialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RedialMutation, RedialMutationVariables>(
    RedialDocument,
    options
  );
}
export type RedialMutationHookResult = ReturnType<typeof useRedialMutation>;
export type RedialMutationResult = Apollo.MutationResult<RedialMutation>;
export type RedialMutationOptions = Apollo.BaseMutationOptions<
  RedialMutation,
  RedialMutationVariables
>;
export const RemoveBullhornCredentialsDocument = gql`
  mutation RemoveBullhornCredentials {
    removeBullhornCredentials {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useRemoveBullhornCredentialsMutation__
 *
 * To run a mutation, you first call `useRemoveBullhornCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBullhornCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBullhornCredentialsMutation, { data, loading, error }] = useRemoveBullhornCredentialsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveBullhornCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveBullhornCredentialsMutation,
    RemoveBullhornCredentialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveBullhornCredentialsMutation,
    RemoveBullhornCredentialsMutationVariables
  >(RemoveBullhornCredentialsDocument, options);
}
export type RemoveBullhornCredentialsMutationHookResult = ReturnType<
  typeof useRemoveBullhornCredentialsMutation
>;
export type RemoveBullhornCredentialsMutationResult =
  Apollo.MutationResult<RemoveBullhornCredentialsMutation>;
export type RemoveBullhornCredentialsMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveBullhornCredentialsMutation,
    RemoveBullhornCredentialsMutationVariables
  >;
export const RemoveClipShareDocument = gql`
  mutation RemoveClipShare($id: ID!) {
    removeClipShare(id: $id) {
      clip {
        id
        clipShares {
          ...ClipShare
        }
      }
    }
  }
  ${ClipShareFragmentDoc}
`;

/**
 * __useRemoveClipShareMutation__
 *
 * To run a mutation, you first call `useRemoveClipShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClipShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClipShareMutation, { data, loading, error }] = useRemoveClipShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClipShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClipShareMutation,
    RemoveClipShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveClipShareMutation,
    RemoveClipShareMutationVariables
  >(RemoveClipShareDocument, options);
}
export type RemoveClipShareMutationHookResult = ReturnType<
  typeof useRemoveClipShareMutation
>;
export type RemoveClipShareMutationResult =
  Apollo.MutationResult<RemoveClipShareMutation>;
export type RemoveClipShareMutationOptions = Apollo.BaseMutationOptions<
  RemoveClipShareMutation,
  RemoveClipShareMutationVariables
>;
export const RemoveGuideFromCallDocument = gql`
  mutation RemoveGuideFromCall($callId: ID!) {
    updateCall(id: $callId, callGuideRemoved: true) {
      call {
        id
        callGuide {
          ...CallGuide
        }
      }
    }
  }
  ${CallGuideFragmentDoc}
`;

/**
 * __useRemoveGuideFromCallMutation__
 *
 * To run a mutation, you first call `useRemoveGuideFromCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGuideFromCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGuideFromCallMutation, { data, loading, error }] = useRemoveGuideFromCallMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useRemoveGuideFromCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGuideFromCallMutation,
    RemoveGuideFromCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGuideFromCallMutation,
    RemoveGuideFromCallMutationVariables
  >(RemoveGuideFromCallDocument, options);
}
export type RemoveGuideFromCallMutationHookResult = ReturnType<
  typeof useRemoveGuideFromCallMutation
>;
export type RemoveGuideFromCallMutationResult =
  Apollo.MutationResult<RemoveGuideFromCallMutation>;
export type RemoveGuideFromCallMutationOptions = Apollo.BaseMutationOptions<
  RemoveGuideFromCallMutation,
  RemoveGuideFromCallMutationVariables
>;
export const RemoveShareDocument = gql`
  mutation RemoveShare($id: ID!) {
    removeShare(id: $id) {
      call {
        id
        callShares {
          ...CallShare
        }
      }
    }
  }
  ${CallShareFragmentDoc}
`;

/**
 * __useRemoveShareMutation__
 *
 * To run a mutation, you first call `useRemoveShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeShareMutation, { data, loading, error }] = useRemoveShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveShareMutation,
    RemoveShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveShareMutation, RemoveShareMutationVariables>(
    RemoveShareDocument,
    options
  );
}
export type RemoveShareMutationHookResult = ReturnType<
  typeof useRemoveShareMutation
>;
export type RemoveShareMutationResult =
  Apollo.MutationResult<RemoveShareMutation>;
export type RemoveShareMutationOptions = Apollo.BaseMutationOptions<
  RemoveShareMutation,
  RemoveShareMutationVariables
>;
export const RequestOrganizationDataRedactionAccessDocument = gql`
  mutation RequestOrganizationDataRedactionAccess {
    requestOrganizationDataRedactionAccess {
      success
    }
  }
`;

/**
 * __useRequestOrganizationDataRedactionAccessMutation__
 *
 * To run a mutation, you first call `useRequestOrganizationDataRedactionAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOrganizationDataRedactionAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOrganizationDataRedactionAccessMutation, { data, loading, error }] = useRequestOrganizationDataRedactionAccessMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestOrganizationDataRedactionAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestOrganizationDataRedactionAccessMutation,
    RequestOrganizationDataRedactionAccessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestOrganizationDataRedactionAccessMutation,
    RequestOrganizationDataRedactionAccessMutationVariables
  >(RequestOrganizationDataRedactionAccessDocument, options);
}
export type RequestOrganizationDataRedactionAccessMutationHookResult =
  ReturnType<typeof useRequestOrganizationDataRedactionAccessMutation>;
export type RequestOrganizationDataRedactionAccessMutationResult =
  Apollo.MutationResult<RequestOrganizationDataRedactionAccessMutation>;
export type RequestOrganizationDataRedactionAccessMutationOptions =
  Apollo.BaseMutationOptions<
    RequestOrganizationDataRedactionAccessMutation,
    RequestOrganizationDataRedactionAccessMutationVariables
  >;
export const RequestUpgradeDocument = gql`
  mutation RequestUpgrade($featureName: String!) {
    requestUpgrade(featureName: $featureName) {
      success
    }
  }
`;

/**
 * __useRequestUpgradeMutation__
 *
 * To run a mutation, you first call `useRequestUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUpgradeMutation, { data, loading, error }] = useRequestUpgradeMutation({
 *   variables: {
 *      featureName: // value for 'featureName'
 *   },
 * });
 */
export function useRequestUpgradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestUpgradeMutation,
    RequestUpgradeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestUpgradeMutation,
    RequestUpgradeMutationVariables
  >(RequestUpgradeDocument, options);
}
export type RequestUpgradeMutationHookResult = ReturnType<
  typeof useRequestUpgradeMutation
>;
export type RequestUpgradeMutationResult =
  Apollo.MutationResult<RequestUpgradeMutation>;
export type RequestUpgradeMutationOptions = Apollo.BaseMutationOptions<
  RequestUpgradeMutation,
  RequestUpgradeMutationVariables
>;
export const ResetAnalyticsQualityReportConfigDocument = gql`
  mutation ResetAnalyticsQualityReportConfig {
    resetAnalyticsQualityReportConfig {
      reportConfig {
        agendaSetEnabled
        candidateQuestionOpportunityEnabled
        candidateTalkRatioEnabled
        onTimeStartsEnabled
        problematicQuestionsEnabled
        longestMonologueEnabled
        speakingRateEnabled
        interactivityEnabled
        questionsAskedEnabled
      }
    }
  }
`;

/**
 * __useResetAnalyticsQualityReportConfigMutation__
 *
 * To run a mutation, you first call `useResetAnalyticsQualityReportConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAnalyticsQualityReportConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAnalyticsQualityReportConfigMutation, { data, loading, error }] = useResetAnalyticsQualityReportConfigMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetAnalyticsQualityReportConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetAnalyticsQualityReportConfigMutation,
    ResetAnalyticsQualityReportConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetAnalyticsQualityReportConfigMutation,
    ResetAnalyticsQualityReportConfigMutationVariables
  >(ResetAnalyticsQualityReportConfigDocument, options);
}
export type ResetAnalyticsQualityReportConfigMutationHookResult = ReturnType<
  typeof useResetAnalyticsQualityReportConfigMutation
>;
export type ResetAnalyticsQualityReportConfigMutationResult =
  Apollo.MutationResult<ResetAnalyticsQualityReportConfigMutation>;
export type ResetAnalyticsQualityReportConfigMutationOptions =
  Apollo.BaseMutationOptions<
    ResetAnalyticsQualityReportConfigMutation,
    ResetAnalyticsQualityReportConfigMutationVariables
  >;
export const RunSkillReportDocument = gql`
  mutation RunSkillReport($reportId: ID!, $regenerate: Boolean) {
    runSkillReport(reportId: $reportId, regenerate: $regenerate) {
      skillReport {
        id
        processingStatus
      }
    }
  }
`;

/**
 * __useRunSkillReportMutation__
 *
 * To run a mutation, you first call `useRunSkillReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunSkillReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runSkillReportMutation, { data, loading, error }] = useRunSkillReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      regenerate: // value for 'regenerate'
 *   },
 * });
 */
export function useRunSkillReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunSkillReportMutation,
    RunSkillReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunSkillReportMutation,
    RunSkillReportMutationVariables
  >(RunSkillReportDocument, options);
}
export type RunSkillReportMutationHookResult = ReturnType<
  typeof useRunSkillReportMutation
>;
export type RunSkillReportMutationResult =
  Apollo.MutationResult<RunSkillReportMutation>;
export type RunSkillReportMutationOptions = Apollo.BaseMutationOptions<
  RunSkillReportMutation,
  RunSkillReportMutationVariables
>;
export const ScheduledInterviewerOptOutDocument = gql`
  mutation ScheduledInterviewerOptOut($id: ID!, $recordingDisabled: Boolean!) {
    scheduledInterviewerOptOut(id: $id, recordingDisabled: $recordingDisabled) {
      scheduledInterview {
        id
        recordingDisabled
        scheduledInterviewers {
          id
          recordingDisabled
          user {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useScheduledInterviewerOptOutMutation__
 *
 * To run a mutation, you first call `useScheduledInterviewerOptOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduledInterviewerOptOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduledInterviewerOptOutMutation, { data, loading, error }] = useScheduledInterviewerOptOutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      recordingDisabled: // value for 'recordingDisabled'
 *   },
 * });
 */
export function useScheduledInterviewerOptOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScheduledInterviewerOptOutMutation,
    ScheduledInterviewerOptOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ScheduledInterviewerOptOutMutation,
    ScheduledInterviewerOptOutMutationVariables
  >(ScheduledInterviewerOptOutDocument, options);
}
export type ScheduledInterviewerOptOutMutationHookResult = ReturnType<
  typeof useScheduledInterviewerOptOutMutation
>;
export type ScheduledInterviewerOptOutMutationResult =
  Apollo.MutationResult<ScheduledInterviewerOptOutMutation>;
export type ScheduledInterviewerOptOutMutationOptions =
  Apollo.BaseMutationOptions<
    ScheduledInterviewerOptOutMutation,
    ScheduledInterviewerOptOutMutationVariables
  >;
export const SendCallBackTextMessageDocument = gql`
  mutation SendCallBackTextMessage($callId: ID!, $body: String!) {
    sendCallBackTextMessage(callId: $callId, body: $body) {
      textMessage {
        messageSid
        messageStatus
      }
    }
  }
`;

/**
 * __useSendCallBackTextMessageMutation__
 *
 * To run a mutation, you first call `useSendCallBackTextMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCallBackTextMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCallBackTextMessageMutation, { data, loading, error }] = useSendCallBackTextMessageMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSendCallBackTextMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendCallBackTextMessageMutation,
    SendCallBackTextMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendCallBackTextMessageMutation,
    SendCallBackTextMessageMutationVariables
  >(SendCallBackTextMessageDocument, options);
}
export type SendCallBackTextMessageMutationHookResult = ReturnType<
  typeof useSendCallBackTextMessageMutation
>;
export type SendCallBackTextMessageMutationResult =
  Apollo.MutationResult<SendCallBackTextMessageMutation>;
export type SendCallBackTextMessageMutationOptions = Apollo.BaseMutationOptions<
  SendCallBackTextMessageMutation,
  SendCallBackTextMessageMutationVariables
>;
export const SendCandidateEmailVerificationDocument = gql`
  mutation SendCandidateEmailVerification($orgId: ID!, $email: String!) {
    sendCandidateEmailVerification(orgId: $orgId, email: $email) {
      success
    }
  }
`;

/**
 * __useSendCandidateEmailVerificationMutation__
 *
 * To run a mutation, you first call `useSendCandidateEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCandidateEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCandidateEmailVerificationMutation, { data, loading, error }] = useSendCandidateEmailVerificationMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendCandidateEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendCandidateEmailVerificationMutation,
    SendCandidateEmailVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendCandidateEmailVerificationMutation,
    SendCandidateEmailVerificationMutationVariables
  >(SendCandidateEmailVerificationDocument, options);
}
export type SendCandidateEmailVerificationMutationHookResult = ReturnType<
  typeof useSendCandidateEmailVerificationMutation
>;
export type SendCandidateEmailVerificationMutationResult =
  Apollo.MutationResult<SendCandidateEmailVerificationMutation>;
export type SendCandidateEmailVerificationMutationOptions =
  Apollo.BaseMutationOptions<
    SendCandidateEmailVerificationMutation,
    SendCandidateEmailVerificationMutationVariables
  >;
export const SendInviteDocument = gql`
  mutation SendInvite($id: ID!) {
    sendInvite(userId: $id) {
      sent
    }
  }
`;

/**
 * __useSendInviteMutation__
 *
 * To run a mutation, you first call `useSendInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteMutation, { data, loading, error }] = useSendInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInviteMutation,
    SendInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendInviteMutation, SendInviteMutationVariables>(
    SendInviteDocument,
    options
  );
}
export type SendInviteMutationHookResult = ReturnType<
  typeof useSendInviteMutation
>;
export type SendInviteMutationResult =
  Apollo.MutationResult<SendInviteMutation>;
export type SendInviteMutationOptions = Apollo.BaseMutationOptions<
  SendInviteMutation,
  SendInviteMutationVariables
>;
export const SetClientMembershipsDocument = gql`
  mutation SetClientMemberships($users: [SetClientMembershipsInput!]!) {
    setClientMemberships(users: $users) {
      users {
        id
      }
    }
  }
`;

/**
 * __useSetClientMembershipsMutation__
 *
 * To run a mutation, you first call `useSetClientMembershipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientMembershipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientMembershipsMutation, { data, loading, error }] = useSetClientMembershipsMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useSetClientMembershipsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetClientMembershipsMutation,
    SetClientMembershipsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetClientMembershipsMutation,
    SetClientMembershipsMutationVariables
  >(SetClientMembershipsDocument, options);
}
export type SetClientMembershipsMutationHookResult = ReturnType<
  typeof useSetClientMembershipsMutation
>;
export type SetClientMembershipsMutationResult =
  Apollo.MutationResult<SetClientMembershipsMutation>;
export type SetClientMembershipsMutationOptions = Apollo.BaseMutationOptions<
  SetClientMembershipsMutation,
  SetClientMembershipsMutationVariables
>;
export const SetMembershipsDocument = gql`
  mutation SetMemberships($users: [SetMembershipsInput!]!) {
    setMemberships(users: $users) {
      positions {
        id
        membershipsCount
        memberships {
          role {
            id
            name
          }
          position {
            id
            title
          }
          createdAt
        }
      }
      users {
        id
        userRole {
          name
        }
        memberships {
          role {
            id
            name
          }
          position {
            id
            title
          }
          createdAt
        }
      }
    }
  }
`;

/**
 * __useSetMembershipsMutation__
 *
 * To run a mutation, you first call `useSetMembershipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMembershipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMembershipsMutation, { data, loading, error }] = useSetMembershipsMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useSetMembershipsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetMembershipsMutation,
    SetMembershipsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetMembershipsMutation,
    SetMembershipsMutationVariables
  >(SetMembershipsDocument, options);
}
export type SetMembershipsMutationHookResult = ReturnType<
  typeof useSetMembershipsMutation
>;
export type SetMembershipsMutationResult =
  Apollo.MutationResult<SetMembershipsMutation>;
export type SetMembershipsMutationOptions = Apollo.BaseMutationOptions<
  SetMembershipsMutation,
  SetMembershipsMutationVariables
>;
export const SetPlanUserRolesDocument = gql`
  mutation SetPlanUserRoles($users: [SetPlanUserRolesInput!]!) {
    setPlanUserRoles(users: $users) {
      users {
        ...User
        planUserRole {
          name
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useSetPlanUserRolesMutation__
 *
 * To run a mutation, you first call `useSetPlanUserRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPlanUserRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPlanUserRolesMutation, { data, loading, error }] = useSetPlanUserRolesMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useSetPlanUserRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPlanUserRolesMutation,
    SetPlanUserRolesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetPlanUserRolesMutation,
    SetPlanUserRolesMutationVariables
  >(SetPlanUserRolesDocument, options);
}
export type SetPlanUserRolesMutationHookResult = ReturnType<
  typeof useSetPlanUserRolesMutation
>;
export type SetPlanUserRolesMutationResult =
  Apollo.MutationResult<SetPlanUserRolesMutation>;
export type SetPlanUserRolesMutationOptions = Apollo.BaseMutationOptions<
  SetPlanUserRolesMutation,
  SetPlanUserRolesMutationVariables
>;
export const SetUserRolesDocument = gql`
  mutation SetUserRoles($users: [SetUserRolesInput!]!) {
    setUserRoles(users: $users) {
      users {
        ...User
        userRole {
          id
          name
        }
        memberships {
          role {
            id
            name
          }
          position {
            id
            title
          }
          createdAt
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useSetUserRolesMutation__
 *
 * To run a mutation, you first call `useSetUserRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserRolesMutation, { data, loading, error }] = useSetUserRolesMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useSetUserRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserRolesMutation,
    SetUserRolesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetUserRolesMutation,
    SetUserRolesMutationVariables
  >(SetUserRolesDocument, options);
}
export type SetUserRolesMutationHookResult = ReturnType<
  typeof useSetUserRolesMutation
>;
export type SetUserRolesMutationResult =
  Apollo.MutationResult<SetUserRolesMutation>;
export type SetUserRolesMutationOptions = Apollo.BaseMutationOptions<
  SetUserRolesMutation,
  SetUserRolesMutationVariables
>;
export const SetupGreenhouseDocument = gql`
  mutation SetupGreenhouse(
    $enabled: Boolean!
    $apiKey: String
    $host: String
    $adminUserId: BigInt!
    $syncCallGuidesFromAts: Boolean!
  ) {
    setupGreenhouse(
      enabled: $enabled
      apiKey: $apiKey
      host: $host
      adminUserId: $adminUserId
      syncCallGuidesFromAts: $syncCallGuidesFromAts
    ) {
      setup {
        ...GreenhouseSetup
      }
    }
  }
  ${GreenhouseSetupFragmentDoc}
`;

/**
 * __useSetupGreenhouseMutation__
 *
 * To run a mutation, you first call `useSetupGreenhouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupGreenhouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupGreenhouseMutation, { data, loading, error }] = useSetupGreenhouseMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *      apiKey: // value for 'apiKey'
 *      host: // value for 'host'
 *      adminUserId: // value for 'adminUserId'
 *      syncCallGuidesFromAts: // value for 'syncCallGuidesFromAts'
 *   },
 * });
 */
export function useSetupGreenhouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetupGreenhouseMutation,
    SetupGreenhouseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetupGreenhouseMutation,
    SetupGreenhouseMutationVariables
  >(SetupGreenhouseDocument, options);
}
export type SetupGreenhouseMutationHookResult = ReturnType<
  typeof useSetupGreenhouseMutation
>;
export type SetupGreenhouseMutationResult =
  Apollo.MutationResult<SetupGreenhouseMutation>;
export type SetupGreenhouseMutationOptions = Apollo.BaseMutationOptions<
  SetupGreenhouseMutation,
  SetupGreenhouseMutationVariables
>;
export const SetupGreenhouseWizardDocument = gql`
  mutation SetupGreenhouseWizard(
    $enabled: Boolean!
    $apiKey: String
    $host: String
    $adminUserId: BigInt
  ) {
    setupGreenhouseWizard(
      enabled: $enabled
      apiKey: $apiKey
      host: $host
      adminUserId: $adminUserId
    ) {
      setup {
        ...GreenhouseSetup
      }
    }
  }
  ${GreenhouseSetupFragmentDoc}
`;

/**
 * __useSetupGreenhouseWizardMutation__
 *
 * To run a mutation, you first call `useSetupGreenhouseWizardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupGreenhouseWizardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupGreenhouseWizardMutation, { data, loading, error }] = useSetupGreenhouseWizardMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *      apiKey: // value for 'apiKey'
 *      host: // value for 'host'
 *      adminUserId: // value for 'adminUserId'
 *   },
 * });
 */
export function useSetupGreenhouseWizardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetupGreenhouseWizardMutation,
    SetupGreenhouseWizardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetupGreenhouseWizardMutation,
    SetupGreenhouseWizardMutationVariables
  >(SetupGreenhouseWizardDocument, options);
}
export type SetupGreenhouseWizardMutationHookResult = ReturnType<
  typeof useSetupGreenhouseWizardMutation
>;
export type SetupGreenhouseWizardMutationResult =
  Apollo.MutationResult<SetupGreenhouseWizardMutation>;
export type SetupGreenhouseWizardMutationOptions = Apollo.BaseMutationOptions<
  SetupGreenhouseWizardMutation,
  SetupGreenhouseWizardMutationVariables
>;
export const SetupIcimsDocument = gql`
  mutation SetupIcims(
    $enabled: Boolean!
    $customerId: String
    $clientId: String
    $clientSecret: String
    $tokenUrl: String
  ) {
    setupIcims(
      enabled: $enabled
      customerId: $customerId
      clientId: $clientId
      clientSecret: $clientSecret
      tokenUrl: $tokenUrl
    ) {
      setup {
        id
        enabled
        valid
      }
    }
  }
`;

/**
 * __useSetupIcimsMutation__
 *
 * To run a mutation, you first call `useSetupIcimsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupIcimsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupIcimsMutation, { data, loading, error }] = useSetupIcimsMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *      customerId: // value for 'customerId'
 *      clientId: // value for 'clientId'
 *      clientSecret: // value for 'clientSecret'
 *      tokenUrl: // value for 'tokenUrl'
 *   },
 * });
 */
export function useSetupIcimsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetupIcimsMutation,
    SetupIcimsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetupIcimsMutation, SetupIcimsMutationVariables>(
    SetupIcimsDocument,
    options
  );
}
export type SetupIcimsMutationHookResult = ReturnType<
  typeof useSetupIcimsMutation
>;
export type SetupIcimsMutationResult =
  Apollo.MutationResult<SetupIcimsMutation>;
export type SetupIcimsMutationOptions = Apollo.BaseMutationOptions<
  SetupIcimsMutation,
  SetupIcimsMutationVariables
>;
export const SetupLeverDocument = gql`
  mutation SetupLever(
    $enabled: Boolean
    $apiKey: String
    $signatureTokenObj: String
    $syncCallGuidesFromAts: Boolean!
    $createSecretNotes: Boolean!
  ) {
    setupLever(
      enabled: $enabled
      apiKey: $apiKey
      signatureTokenObj: $signatureTokenObj
      syncCallGuidesFromAts: $syncCallGuidesFromAts
      createSecretNotes: $createSecretNotes
    ) {
      leverSetup {
        id
        enabled
        apiKey
        signatureTokenObj
        valid
        syncCallGuidesFromAts
        createSecretNotes
      }
    }
  }
`;

/**
 * __useSetupLeverMutation__
 *
 * To run a mutation, you first call `useSetupLeverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupLeverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupLeverMutation, { data, loading, error }] = useSetupLeverMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *      apiKey: // value for 'apiKey'
 *      signatureTokenObj: // value for 'signatureTokenObj'
 *      syncCallGuidesFromAts: // value for 'syncCallGuidesFromAts'
 *      createSecretNotes: // value for 'createSecretNotes'
 *   },
 * });
 */
export function useSetupLeverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetupLeverMutation,
    SetupLeverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetupLeverMutation, SetupLeverMutationVariables>(
    SetupLeverDocument,
    options
  );
}
export type SetupLeverMutationHookResult = ReturnType<
  typeof useSetupLeverMutation
>;
export type SetupLeverMutationResult =
  Apollo.MutationResult<SetupLeverMutation>;
export type SetupLeverMutationOptions = Apollo.BaseMutationOptions<
  SetupLeverMutation,
  SetupLeverMutationVariables
>;
export const SetupSenseDocument = gql`
  mutation SetupSense($enabled: Boolean!, $apiKey: String) {
    setupSense(enabled: $enabled, apiKey: $apiKey) {
      setup {
        enabled
        apiKey
      }
    }
  }
`;

/**
 * __useSetupSenseMutation__
 *
 * To run a mutation, you first call `useSetupSenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupSenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupSenseMutation, { data, loading, error }] = useSetupSenseMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *      apiKey: // value for 'apiKey'
 *   },
 * });
 */
export function useSetupSenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetupSenseMutation,
    SetupSenseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetupSenseMutation, SetupSenseMutationVariables>(
    SetupSenseDocument,
    options
  );
}
export type SetupSenseMutationHookResult = ReturnType<
  typeof useSetupSenseMutation
>;
export type SetupSenseMutationResult =
  Apollo.MutationResult<SetupSenseMutation>;
export type SetupSenseMutationOptions = Apollo.BaseMutationOptions<
  SetupSenseMutation,
  SetupSenseMutationVariables
>;
export const SetupSingleSignOnDocument = gql`
  mutation SetupSingleSignOn($oktaDomain: String!, $metadataUrl: String!) {
    setupSingleSignOn(oktaDomain: $oktaDomain, metadataUrl: $metadataUrl) {
      setup {
        oktaDomain
        metadataUrl
        scimToken
      }
    }
  }
`;

/**
 * __useSetupSingleSignOnMutation__
 *
 * To run a mutation, you first call `useSetupSingleSignOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupSingleSignOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupSingleSignOnMutation, { data, loading, error }] = useSetupSingleSignOnMutation({
 *   variables: {
 *      oktaDomain: // value for 'oktaDomain'
 *      metadataUrl: // value for 'metadataUrl'
 *   },
 * });
 */
export function useSetupSingleSignOnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetupSingleSignOnMutation,
    SetupSingleSignOnMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetupSingleSignOnMutation,
    SetupSingleSignOnMutationVariables
  >(SetupSingleSignOnDocument, options);
}
export type SetupSingleSignOnMutationHookResult = ReturnType<
  typeof useSetupSingleSignOnMutation
>;
export type SetupSingleSignOnMutationResult =
  Apollo.MutationResult<SetupSingleSignOnMutation>;
export type SetupSingleSignOnMutationOptions = Apollo.BaseMutationOptions<
  SetupSingleSignOnMutation,
  SetupSingleSignOnMutationVariables
>;
export const ShareAnalyticsReportDocument = gql`
  mutation ShareAnalyticsReport(
    $shareToUserIds: [ID!]!
    $message: String!
    $url: String!
  ) {
    shareAnalyticsReport(
      shareToUserIds: $shareToUserIds
      message: $message
      url: $url
    ) {
      success
    }
  }
`;

/**
 * __useShareAnalyticsReportMutation__
 *
 * To run a mutation, you first call `useShareAnalyticsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareAnalyticsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareAnalyticsReportMutation, { data, loading, error }] = useShareAnalyticsReportMutation({
 *   variables: {
 *      shareToUserIds: // value for 'shareToUserIds'
 *      message: // value for 'message'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useShareAnalyticsReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareAnalyticsReportMutation,
    ShareAnalyticsReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareAnalyticsReportMutation,
    ShareAnalyticsReportMutationVariables
  >(ShareAnalyticsReportDocument, options);
}
export type ShareAnalyticsReportMutationHookResult = ReturnType<
  typeof useShareAnalyticsReportMutation
>;
export type ShareAnalyticsReportMutationResult =
  Apollo.MutationResult<ShareAnalyticsReportMutation>;
export type ShareAnalyticsReportMutationOptions = Apollo.BaseMutationOptions<
  ShareAnalyticsReportMutation,
  ShareAnalyticsReportMutationVariables
>;
export const ShareCallDocument = gql`
  mutation ShareCall($id: ID!, $shareToUserIds: [ID!]!, $message: String) {
    shareCall(id: $id, shareToUserIds: $shareToUserIds, message: $message) {
      call {
        id
        callShares {
          ...CallShare
        }
      }
    }
  }
  ${CallShareFragmentDoc}
`;

/**
 * __useShareCallMutation__
 *
 * To run a mutation, you first call `useShareCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareCallMutation, { data, loading, error }] = useShareCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shareToUserIds: // value for 'shareToUserIds'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useShareCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareCallMutation,
    ShareCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShareCallMutation, ShareCallMutationVariables>(
    ShareCallDocument,
    options
  );
}
export type ShareCallMutationHookResult = ReturnType<
  typeof useShareCallMutation
>;
export type ShareCallMutationResult = Apollo.MutationResult<ShareCallMutation>;
export type ShareCallMutationOptions = Apollo.BaseMutationOptions<
  ShareCallMutation,
  ShareCallMutationVariables
>;
export const ShareClipDocument = gql`
  mutation ShareClip($clipId: ID!, $shareToUserIds: [ID!]!, $message: String) {
    shareClip(
      clipId: $clipId
      shareToUserIds: $shareToUserIds
      message: $message
    ) {
      clip {
        id
        clipShares {
          ...ClipShare
        }
      }
    }
  }
  ${ClipShareFragmentDoc}
`;

/**
 * __useShareClipMutation__
 *
 * To run a mutation, you first call `useShareClipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareClipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareClipMutation, { data, loading, error }] = useShareClipMutation({
 *   variables: {
 *      clipId: // value for 'clipId'
 *      shareToUserIds: // value for 'shareToUserIds'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useShareClipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareClipMutation,
    ShareClipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShareClipMutation, ShareClipMutationVariables>(
    ShareClipDocument,
    options
  );
}
export type ShareClipMutationHookResult = ReturnType<
  typeof useShareClipMutation
>;
export type ShareClipMutationResult = Apollo.MutationResult<ShareClipMutation>;
export type ShareClipMutationOptions = Apollo.BaseMutationOptions<
  ShareClipMutation,
  ShareClipMutationVariables
>;
export const SignInByEmailDocument = gql`
  mutation SignInByEmail($email: String!) {
    signInByEmail(email: $email) {
      verificationSent
    }
  }
`;

/**
 * __useSignInByEmailMutation__
 *
 * To run a mutation, you first call `useSignInByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInByEmailMutation, { data, loading, error }] = useSignInByEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSignInByEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInByEmailMutation,
    SignInByEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignInByEmailMutation,
    SignInByEmailMutationVariables
  >(SignInByEmailDocument, options);
}
export type SignInByEmailMutationHookResult = ReturnType<
  typeof useSignInByEmailMutation
>;
export type SignInByEmailMutationResult =
  Apollo.MutationResult<SignInByEmailMutation>;
export type SignInByEmailMutationOptions = Apollo.BaseMutationOptions<
  SignInByEmailMutation,
  SignInByEmailMutationVariables
>;
export const SignInByPhoneDocument = gql`
  mutation SignInByPhone(
    $phoneNumber: String!
    $phoneNumberExtension: String
    $channel: String!
  ) {
    signInByPhone(
      phoneNumber: $phoneNumber
      phoneNumberExtension: $phoneNumberExtension
      channel: $channel
    ) {
      verificationSent
    }
  }
`;

/**
 * __useSignInByPhoneMutation__
 *
 * To run a mutation, you first call `useSignInByPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInByPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInByPhoneMutation, { data, loading, error }] = useSignInByPhoneMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      phoneNumberExtension: // value for 'phoneNumberExtension'
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useSignInByPhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInByPhoneMutation,
    SignInByPhoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignInByPhoneMutation,
    SignInByPhoneMutationVariables
  >(SignInByPhoneDocument, options);
}
export type SignInByPhoneMutationHookResult = ReturnType<
  typeof useSignInByPhoneMutation
>;
export type SignInByPhoneMutationResult =
  Apollo.MutationResult<SignInByPhoneMutation>;
export type SignInByPhoneMutationOptions = Apollo.BaseMutationOptions<
  SignInByPhoneMutation,
  SignInByPhoneMutationVariables
>;
export const SignOutDocument = gql`
  mutation SignOut {
    signOut {
      isAuthenticated
    }
  }
`;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignOutMutation,
    SignOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(
    SignOutDocument,
    options
  );
}
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<
  SignOutMutation,
  SignOutMutationVariables
>;
export const SmartrecruitersAuthOnRedirectDocument = gql`
  mutation SmartrecruitersAuthOnRedirect(
    $state: String!
    $code: String
    $error: String
    $errorDescription: String
  ) {
    smartrecruitersAuthOnRedirect(
      state: $state
      code: $code
      error: $error
      errorDescription: $errorDescription
    ) {
      setup {
        id
        valid
      }
    }
  }
`;

/**
 * __useSmartrecruitersAuthOnRedirectMutation__
 *
 * To run a mutation, you first call `useSmartrecruitersAuthOnRedirectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSmartrecruitersAuthOnRedirectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [smartrecruitersAuthOnRedirectMutation, { data, loading, error }] = useSmartrecruitersAuthOnRedirectMutation({
 *   variables: {
 *      state: // value for 'state'
 *      code: // value for 'code'
 *      error: // value for 'error'
 *      errorDescription: // value for 'errorDescription'
 *   },
 * });
 */
export function useSmartrecruitersAuthOnRedirectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SmartrecruitersAuthOnRedirectMutation,
    SmartrecruitersAuthOnRedirectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SmartrecruitersAuthOnRedirectMutation,
    SmartrecruitersAuthOnRedirectMutationVariables
  >(SmartrecruitersAuthOnRedirectDocument, options);
}
export type SmartrecruitersAuthOnRedirectMutationHookResult = ReturnType<
  typeof useSmartrecruitersAuthOnRedirectMutation
>;
export type SmartrecruitersAuthOnRedirectMutationResult =
  Apollo.MutationResult<SmartrecruitersAuthOnRedirectMutation>;
export type SmartrecruitersAuthOnRedirectMutationOptions =
  Apollo.BaseMutationOptions<
    SmartrecruitersAuthOnRedirectMutation,
    SmartrecruitersAuthOnRedirectMutationVariables
  >;
export const CreateSmartrecruitersOauthRequestUrlDocument = gql`
  mutation CreateSmartrecruitersOauthRequestUrl {
    createSmartrecruitersOauthRequestUrl {
      oauthUrl
    }
  }
`;

/**
 * __useCreateSmartrecruitersOauthRequestUrlMutation__
 *
 * To run a mutation, you first call `useCreateSmartrecruitersOauthRequestUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSmartrecruitersOauthRequestUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSmartrecruitersOauthRequestUrlMutation, { data, loading, error }] = useCreateSmartrecruitersOauthRequestUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSmartrecruitersOauthRequestUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSmartrecruitersOauthRequestUrlMutation,
    CreateSmartrecruitersOauthRequestUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSmartrecruitersOauthRequestUrlMutation,
    CreateSmartrecruitersOauthRequestUrlMutationVariables
  >(CreateSmartrecruitersOauthRequestUrlDocument, options);
}
export type CreateSmartrecruitersOauthRequestUrlMutationHookResult = ReturnType<
  typeof useCreateSmartrecruitersOauthRequestUrlMutation
>;
export type CreateSmartrecruitersOauthRequestUrlMutationResult =
  Apollo.MutationResult<CreateSmartrecruitersOauthRequestUrlMutation>;
export type CreateSmartrecruitersOauthRequestUrlMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSmartrecruitersOauthRequestUrlMutation,
    CreateSmartrecruitersOauthRequestUrlMutationVariables
  >;
export const DeauthorizeSmartrecruitersDocument = gql`
  mutation DeauthorizeSmartrecruiters {
    deauthorizeSmartrecruiters {
      success
    }
  }
`;

/**
 * __useDeauthorizeSmartrecruitersMutation__
 *
 * To run a mutation, you first call `useDeauthorizeSmartrecruitersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeauthorizeSmartrecruitersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deauthorizeSmartrecruitersMutation, { data, loading, error }] = useDeauthorizeSmartrecruitersMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeauthorizeSmartrecruitersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeauthorizeSmartrecruitersMutation,
    DeauthorizeSmartrecruitersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeauthorizeSmartrecruitersMutation,
    DeauthorizeSmartrecruitersMutationVariables
  >(DeauthorizeSmartrecruitersDocument, options);
}
export type DeauthorizeSmartrecruitersMutationHookResult = ReturnType<
  typeof useDeauthorizeSmartrecruitersMutation
>;
export type DeauthorizeSmartrecruitersMutationResult =
  Apollo.MutationResult<DeauthorizeSmartrecruitersMutation>;
export type DeauthorizeSmartrecruitersMutationOptions =
  Apollo.BaseMutationOptions<
    DeauthorizeSmartrecruitersMutation,
    DeauthorizeSmartrecruitersMutationVariables
  >;
export const StartVirtualOnsiteBotMeetingDocument = gql`
  mutation StartVirtualOnsiteBotMeeting(
    $botMeetingId: ID!
    $addApprovedInterviewer: Boolean
  ) {
    startVirtualOnsiteBotMeeting(
      botMeetingId: $botMeetingId
      addApprovedInterviewer: $addApprovedInterviewer
    ) {
      meeting {
        id
        meetingUrl
      }
    }
  }
`;

/**
 * __useStartVirtualOnsiteBotMeetingMutation__
 *
 * To run a mutation, you first call `useStartVirtualOnsiteBotMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartVirtualOnsiteBotMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startVirtualOnsiteBotMeetingMutation, { data, loading, error }] = useStartVirtualOnsiteBotMeetingMutation({
 *   variables: {
 *      botMeetingId: // value for 'botMeetingId'
 *      addApprovedInterviewer: // value for 'addApprovedInterviewer'
 *   },
 * });
 */
export function useStartVirtualOnsiteBotMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartVirtualOnsiteBotMeetingMutation,
    StartVirtualOnsiteBotMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartVirtualOnsiteBotMeetingMutation,
    StartVirtualOnsiteBotMeetingMutationVariables
  >(StartVirtualOnsiteBotMeetingDocument, options);
}
export type StartVirtualOnsiteBotMeetingMutationHookResult = ReturnType<
  typeof useStartVirtualOnsiteBotMeetingMutation
>;
export type StartVirtualOnsiteBotMeetingMutationResult =
  Apollo.MutationResult<StartVirtualOnsiteBotMeetingMutation>;
export type StartVirtualOnsiteBotMeetingMutationOptions =
  Apollo.BaseMutationOptions<
    StartVirtualOnsiteBotMeetingMutation,
    StartVirtualOnsiteBotMeetingMutationVariables
  >;
export const TalemetryCandidateParamsDocument = gql`
  mutation TalemetryCandidateParams(
    $phoneNumber: String!
    $candidateFullName: String
    $candidateEmail: String
    $atsCandidateId: String
    $jobOpeningId: String
    $talemetryCandidateId: String
  ) {
    talemetryCandidateParams(
      phoneNumber: $phoneNumber
      candidateFullName: $candidateFullName
      candidateEmail: $candidateEmail
      atsCandidateId: $atsCandidateId
      jobOpeningId: $jobOpeningId
      talemetryCandidateId: $talemetryCandidateId
    ) {
      params {
        candidateFirstName
        candidateLastName
        phoneNumber
        candidateEmail
        positionId
        candidateId
        name
      }
    }
  }
`;

/**
 * __useTalemetryCandidateParamsMutation__
 *
 * To run a mutation, you first call `useTalemetryCandidateParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalemetryCandidateParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talemetryCandidateParamsMutation, { data, loading, error }] = useTalemetryCandidateParamsMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      candidateFullName: // value for 'candidateFullName'
 *      candidateEmail: // value for 'candidateEmail'
 *      atsCandidateId: // value for 'atsCandidateId'
 *      jobOpeningId: // value for 'jobOpeningId'
 *      talemetryCandidateId: // value for 'talemetryCandidateId'
 *   },
 * });
 */
export function useTalemetryCandidateParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TalemetryCandidateParamsMutation,
    TalemetryCandidateParamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TalemetryCandidateParamsMutation,
    TalemetryCandidateParamsMutationVariables
  >(TalemetryCandidateParamsDocument, options);
}
export type TalemetryCandidateParamsMutationHookResult = ReturnType<
  typeof useTalemetryCandidateParamsMutation
>;
export type TalemetryCandidateParamsMutationResult =
  Apollo.MutationResult<TalemetryCandidateParamsMutation>;
export type TalemetryCandidateParamsMutationOptions =
  Apollo.BaseMutationOptions<
    TalemetryCandidateParamsMutation,
    TalemetryCandidateParamsMutationVariables
  >;
export const UnimpersonateDocument = gql`
  mutation Unimpersonate {
    unimpersonate {
      currentUser {
        ...CurrentUser
      }
      redirectTo
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUnimpersonateMutation__
 *
 * To run a mutation, you first call `useUnimpersonateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnimpersonateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unimpersonateMutation, { data, loading, error }] = useUnimpersonateMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnimpersonateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnimpersonateMutation,
    UnimpersonateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnimpersonateMutation,
    UnimpersonateMutationVariables
  >(UnimpersonateDocument, options);
}
export type UnimpersonateMutationHookResult = ReturnType<
  typeof useUnimpersonateMutation
>;
export type UnimpersonateMutationResult =
  Apollo.MutationResult<UnimpersonateMutation>;
export type UnimpersonateMutationOptions = Apollo.BaseMutationOptions<
  UnimpersonateMutation,
  UnimpersonateMutationVariables
>;
export const UnkeepCallDocument = gql`
  mutation UnkeepCall($id: ID!) {
    unkeepCall(id: $id) {
      call {
        id
        skipRedaction
        canDelete
      }
      success
    }
  }
`;

/**
 * __useUnkeepCallMutation__
 *
 * To run a mutation, you first call `useUnkeepCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnkeepCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unkeepCallMutation, { data, loading, error }] = useUnkeepCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnkeepCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnkeepCallMutation,
    UnkeepCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnkeepCallMutation, UnkeepCallMutationVariables>(
    UnkeepCallDocument,
    options
  );
}
export type UnkeepCallMutationHookResult = ReturnType<
  typeof useUnkeepCallMutation
>;
export type UnkeepCallMutationResult =
  Apollo.MutationResult<UnkeepCallMutation>;
export type UnkeepCallMutationOptions = Apollo.BaseMutationOptions<
  UnkeepCallMutation,
  UnkeepCallMutationVariables
>;
export const UnkeepCandidateDocument = gql`
  mutation UnkeepCandidate($id: ID!) {
    unkeepCandidate(id: $id) {
      candidate {
        id
        fullName
        skipRedaction
      }
      success
    }
  }
`;

/**
 * __useUnkeepCandidateMutation__
 *
 * To run a mutation, you first call `useUnkeepCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnkeepCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unkeepCandidateMutation, { data, loading, error }] = useUnkeepCandidateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnkeepCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnkeepCandidateMutation,
    UnkeepCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnkeepCandidateMutation,
    UnkeepCandidateMutationVariables
  >(UnkeepCandidateDocument, options);
}
export type UnkeepCandidateMutationHookResult = ReturnType<
  typeof useUnkeepCandidateMutation
>;
export type UnkeepCandidateMutationResult =
  Apollo.MutationResult<UnkeepCandidateMutation>;
export type UnkeepCandidateMutationOptions = Apollo.BaseMutationOptions<
  UnkeepCandidateMutation,
  UnkeepCandidateMutationVariables
>;
export const UpdateAiAnswerDocument = gql`
  mutation UpdateAiAnswer($answerItemId: ID!, $text: String!) {
    updateAiAnswer(answerItemId: $answerItemId, text: $text) {
      answerItem {
        id
        text
        edited
      }
    }
  }
`;

/**
 * __useUpdateAiAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateAiAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAiAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAiAnswerMutation, { data, loading, error }] = useUpdateAiAnswerMutation({
 *   variables: {
 *      answerItemId: // value for 'answerItemId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateAiAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAiAnswerMutation,
    UpdateAiAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAiAnswerMutation,
    UpdateAiAnswerMutationVariables
  >(UpdateAiAnswerDocument, options);
}
export type UpdateAiAnswerMutationHookResult = ReturnType<
  typeof useUpdateAiAnswerMutation
>;
export type UpdateAiAnswerMutationResult =
  Apollo.MutationResult<UpdateAiAnswerMutation>;
export type UpdateAiAnswerMutationOptions = Apollo.BaseMutationOptions<
  UpdateAiAnswerMutation,
  UpdateAiAnswerMutationVariables
>;
export const UpdateAiQuestionDocument = gql`
  mutation UpdateAiQuestion($callAiNoteId: ID!, $text: String!) {
    updateAiQuestion(callAiNoteId: $callAiNoteId, text: $text) {
      callAiNote {
        id
        startTime
        endTime
        segmentIndex
        question
        answer
        originalQuestion
        originalAnswer
        matchReason
        error
        edited
        isCandidate
        answerItems {
          id
          text
          edited
        }
      }
    }
  }
`;

/**
 * __useUpdateAiQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateAiQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAiQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAiQuestionMutation, { data, loading, error }] = useUpdateAiQuestionMutation({
 *   variables: {
 *      callAiNoteId: // value for 'callAiNoteId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateAiQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAiQuestionMutation,
    UpdateAiQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAiQuestionMutation,
    UpdateAiQuestionMutationVariables
  >(UpdateAiQuestionDocument, options);
}
export type UpdateAiQuestionMutationHookResult = ReturnType<
  typeof useUpdateAiQuestionMutation
>;
export type UpdateAiQuestionMutationResult =
  Apollo.MutationResult<UpdateAiQuestionMutation>;
export type UpdateAiQuestionMutationOptions = Apollo.BaseMutationOptions<
  UpdateAiQuestionMutation,
  UpdateAiQuestionMutationVariables
>;
export const UpdateOrganizationGenderLabelsDocument = gql`
  mutation UpdateOrganizationGenderLabels(
    $genderLabelMale: String!
    $genderLabelFemale: String!
  ) {
    updateOrganizationGenderLabels(
      genderLabelMale: $genderLabelMale
      genderLabelFemale: $genderLabelFemale
    ) {
      organization {
        id
        genderLabelMale
        genderLabelFemale
      }
    }
  }
`;

/**
 * __useUpdateOrganizationGenderLabelsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationGenderLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationGenderLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationGenderLabelsMutation, { data, loading, error }] = useUpdateOrganizationGenderLabelsMutation({
 *   variables: {
 *      genderLabelMale: // value for 'genderLabelMale'
 *      genderLabelFemale: // value for 'genderLabelFemale'
 *   },
 * });
 */
export function useUpdateOrganizationGenderLabelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationGenderLabelsMutation,
    UpdateOrganizationGenderLabelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationGenderLabelsMutation,
    UpdateOrganizationGenderLabelsMutationVariables
  >(UpdateOrganizationGenderLabelsDocument, options);
}
export type UpdateOrganizationGenderLabelsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationGenderLabelsMutation
>;
export type UpdateOrganizationGenderLabelsMutationResult =
  Apollo.MutationResult<UpdateOrganizationGenderLabelsMutation>;
export type UpdateOrganizationGenderLabelsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationGenderLabelsMutation,
    UpdateOrganizationGenderLabelsMutationVariables
  >;
export const UpdateAnalyticsQualityReportConfigDocument = gql`
  mutation UpdateAnalyticsQualityReportConfig(
    $agendaSetEnabled: Boolean!
    $candidateQuestionOpportunityEnabled: Boolean!
    $candidateTalkRatioEnabled: Boolean!
    $onTimeStartsEnabled: Boolean!
    $problematicQuestionsEnabled: Boolean!
    $longestMonologueEnabled: Boolean!
    $speakingRateEnabled: Boolean!
    $interactivityEnabled: Boolean!
    $questionsAskedEnabled: Boolean!
    $feedbackSubmissionTimeEnabled: Boolean!
    $feedbackSubmissionRateEnabled: Boolean!
  ) {
    updateAnalyticsQualityReportConfig(
      agendaSetEnabled: $agendaSetEnabled
      candidateQuestionOpportunityEnabled: $candidateQuestionOpportunityEnabled
      candidateTalkRatioEnabled: $candidateTalkRatioEnabled
      onTimeStartsEnabled: $onTimeStartsEnabled
      problematicQuestionsEnabled: $problematicQuestionsEnabled
      longestMonologueEnabled: $longestMonologueEnabled
      speakingRateEnabled: $speakingRateEnabled
      interactivityEnabled: $interactivityEnabled
      questionsAskedEnabled: $questionsAskedEnabled
      feedbackSubmissionTimeEnabled: $feedbackSubmissionTimeEnabled
      feedbackSubmissionRateEnabled: $feedbackSubmissionRateEnabled
    ) {
      reportConfig {
        agendaSetEnabled
        candidateQuestionOpportunityEnabled
        candidateTalkRatioEnabled
        onTimeStartsEnabled
        problematicQuestionsEnabled
        longestMonologueEnabled
        speakingRateEnabled
        interactivityEnabled
        questionsAskedEnabled
        feedbackSubmissionTimeEnabled
        feedbackSubmissionRateEnabled
      }
    }
  }
`;

/**
 * __useUpdateAnalyticsQualityReportConfigMutation__
 *
 * To run a mutation, you first call `useUpdateAnalyticsQualityReportConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalyticsQualityReportConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalyticsQualityReportConfigMutation, { data, loading, error }] = useUpdateAnalyticsQualityReportConfigMutation({
 *   variables: {
 *      agendaSetEnabled: // value for 'agendaSetEnabled'
 *      candidateQuestionOpportunityEnabled: // value for 'candidateQuestionOpportunityEnabled'
 *      candidateTalkRatioEnabled: // value for 'candidateTalkRatioEnabled'
 *      onTimeStartsEnabled: // value for 'onTimeStartsEnabled'
 *      problematicQuestionsEnabled: // value for 'problematicQuestionsEnabled'
 *      longestMonologueEnabled: // value for 'longestMonologueEnabled'
 *      speakingRateEnabled: // value for 'speakingRateEnabled'
 *      interactivityEnabled: // value for 'interactivityEnabled'
 *      questionsAskedEnabled: // value for 'questionsAskedEnabled'
 *      feedbackSubmissionTimeEnabled: // value for 'feedbackSubmissionTimeEnabled'
 *      feedbackSubmissionRateEnabled: // value for 'feedbackSubmissionRateEnabled'
 *   },
 * });
 */
export function useUpdateAnalyticsQualityReportConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnalyticsQualityReportConfigMutation,
    UpdateAnalyticsQualityReportConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAnalyticsQualityReportConfigMutation,
    UpdateAnalyticsQualityReportConfigMutationVariables
  >(UpdateAnalyticsQualityReportConfigDocument, options);
}
export type UpdateAnalyticsQualityReportConfigMutationHookResult = ReturnType<
  typeof useUpdateAnalyticsQualityReportConfigMutation
>;
export type UpdateAnalyticsQualityReportConfigMutationResult =
  Apollo.MutationResult<UpdateAnalyticsQualityReportConfigMutation>;
export type UpdateAnalyticsQualityReportConfigMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAnalyticsQualityReportConfigMutation,
    UpdateAnalyticsQualityReportConfigMutationVariables
  >;
export const UpdateApiTokenDocument = gql`
  mutation UpdateApiToken($id: ID, $name: String!) {
    updateApiToken(id: $id, name: $name) {
      setup {
        apiTokens {
          id
          name
          tokenPrefix
        }
        webhooks {
          id
          webhookUrl
          webhookSecret
          eventInterviewCompleted
          eventCandidateUpdatedRecordingDisabled
        }
      }
    }
  }
`;

/**
 * __useUpdateApiTokenMutation__
 *
 * To run a mutation, you first call `useUpdateApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiTokenMutation, { data, loading, error }] = useUpdateApiTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateApiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApiTokenMutation,
    UpdateApiTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApiTokenMutation,
    UpdateApiTokenMutationVariables
  >(UpdateApiTokenDocument, options);
}
export type UpdateApiTokenMutationHookResult = ReturnType<
  typeof useUpdateApiTokenMutation
>;
export type UpdateApiTokenMutationResult =
  Apollo.MutationResult<UpdateApiTokenMutation>;
export type UpdateApiTokenMutationOptions = Apollo.BaseMutationOptions<
  UpdateApiTokenMutation,
  UpdateApiTokenMutationVariables
>;
export const UpdateAutoJoinAllNewPositionsDocument = gql`
  mutation UpdateAutoJoinAllNewPositions(
    $enable: Boolean!
    $allPositionGroups: Boolean!
    $positionGroupId: ID
  ) {
    updateAutoJoinAllNewPositions(
      enable: $enable
      allPositionGroups: $allPositionGroups
      positionGroupId: $positionGroupId
    ) {
      positions {
        ...PositionJBPListItem
      }
      organization {
        id
        autoJoinAllNewPositions
      }
    }
  }
  ${PositionJbpListItemFragmentDoc}
`;

/**
 * __useUpdateAutoJoinAllNewPositionsMutation__
 *
 * To run a mutation, you first call `useUpdateAutoJoinAllNewPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoJoinAllNewPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoJoinAllNewPositionsMutation, { data, loading, error }] = useUpdateAutoJoinAllNewPositionsMutation({
 *   variables: {
 *      enable: // value for 'enable'
 *      allPositionGroups: // value for 'allPositionGroups'
 *      positionGroupId: // value for 'positionGroupId'
 *   },
 * });
 */
export function useUpdateAutoJoinAllNewPositionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAutoJoinAllNewPositionsMutation,
    UpdateAutoJoinAllNewPositionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAutoJoinAllNewPositionsMutation,
    UpdateAutoJoinAllNewPositionsMutationVariables
  >(UpdateAutoJoinAllNewPositionsDocument, options);
}
export type UpdateAutoJoinAllNewPositionsMutationHookResult = ReturnType<
  typeof useUpdateAutoJoinAllNewPositionsMutation
>;
export type UpdateAutoJoinAllNewPositionsMutationResult =
  Apollo.MutationResult<UpdateAutoJoinAllNewPositionsMutation>;
export type UpdateAutoJoinAllNewPositionsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAutoJoinAllNewPositionsMutation,
    UpdateAutoJoinAllNewPositionsMutationVariables
  >;
export const UpdateAutoJoinExcludedUsersDocument = gql`
  mutation UpdateAutoJoinExcludedUsers($userIds: [ID!]!, $exclude: Boolean!) {
    updateAutoJoinExcludedUsers(userIds: $userIds, exclude: $exclude) {
      currentUser {
        id
        organization {
          id
          userAutoJoinExclusions {
            ...User
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUpdateAutoJoinExcludedUsersMutation__
 *
 * To run a mutation, you first call `useUpdateAutoJoinExcludedUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoJoinExcludedUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoJoinExcludedUsersMutation, { data, loading, error }] = useUpdateAutoJoinExcludedUsersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      exclude: // value for 'exclude'
 *   },
 * });
 */
export function useUpdateAutoJoinExcludedUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAutoJoinExcludedUsersMutation,
    UpdateAutoJoinExcludedUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAutoJoinExcludedUsersMutation,
    UpdateAutoJoinExcludedUsersMutationVariables
  >(UpdateAutoJoinExcludedUsersDocument, options);
}
export type UpdateAutoJoinExcludedUsersMutationHookResult = ReturnType<
  typeof useUpdateAutoJoinExcludedUsersMutation
>;
export type UpdateAutoJoinExcludedUsersMutationResult =
  Apollo.MutationResult<UpdateAutoJoinExcludedUsersMutation>;
export type UpdateAutoJoinExcludedUsersMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAutoJoinExcludedUsersMutation,
    UpdateAutoJoinExcludedUsersMutationVariables
  >;
export const UpdateCalendarOrgSettingsDocument = gql`
  mutation UpdateCalendarOrgSettings(
    $id: ID!
    $shouldImportVideoMeeting: String!
  ) {
    updateCalendarOrgSettings(
      id: $id
      shouldImportVideoMeeting: $shouldImportVideoMeeting
    ) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUpdateCalendarOrgSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarOrgSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarOrgSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarOrgSettingsMutation, { data, loading, error }] = useUpdateCalendarOrgSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shouldImportVideoMeeting: // value for 'shouldImportVideoMeeting'
 *   },
 * });
 */
export function useUpdateCalendarOrgSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCalendarOrgSettingsMutation,
    UpdateCalendarOrgSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCalendarOrgSettingsMutation,
    UpdateCalendarOrgSettingsMutationVariables
  >(UpdateCalendarOrgSettingsDocument, options);
}
export type UpdateCalendarOrgSettingsMutationHookResult = ReturnType<
  typeof useUpdateCalendarOrgSettingsMutation
>;
export type UpdateCalendarOrgSettingsMutationResult =
  Apollo.MutationResult<UpdateCalendarOrgSettingsMutation>;
export type UpdateCalendarOrgSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCalendarOrgSettingsMutation,
    UpdateCalendarOrgSettingsMutationVariables
  >;
export const UpdateCalendarSettingsDocument = gql`
  mutation UpdateCalendarSettings(
    $id: ID!
    $shouldImportVideoMeeting: String!
  ) {
    updateCalendarSettings(
      id: $id
      shouldImportVideoMeeting: $shouldImportVideoMeeting
    ) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUpdateCalendarSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarSettingsMutation, { data, loading, error }] = useUpdateCalendarSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shouldImportVideoMeeting: // value for 'shouldImportVideoMeeting'
 *   },
 * });
 */
export function useUpdateCalendarSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCalendarSettingsMutation,
    UpdateCalendarSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCalendarSettingsMutation,
    UpdateCalendarSettingsMutationVariables
  >(UpdateCalendarSettingsDocument, options);
}
export type UpdateCalendarSettingsMutationHookResult = ReturnType<
  typeof useUpdateCalendarSettingsMutation
>;
export type UpdateCalendarSettingsMutationResult =
  Apollo.MutationResult<UpdateCalendarSettingsMutation>;
export type UpdateCalendarSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateCalendarSettingsMutation,
  UpdateCalendarSettingsMutationVariables
>;
export const UpdateCallDocument = gql`
  mutation UpdateCall(
    $id: ID!
    $name: String
    $positionId: ID
    $rating: Int
    $bullhornCommentAction: String
    $candidateFirstName: String
    $candidateLastName: String
    $candidateEmail: String
    $candidateId: String
    $iaVersion: Float
  ) {
    updateCall(
      id: $id
      name: $name
      positionId: $positionId
      rating: $rating
      bullhornCommentAction: $bullhornCommentAction
      candidateFirstName: $candidateFirstName
      candidateLastName: $candidateLastName
      candidateEmail: $candidateEmail
      candidateId: $candidateId
      iaVersion: $iaVersion
    ) {
      call {
        id
        name
        iaVersion
        candidate {
          id
          firstName
          lastName
          fullName
        }
        position {
          id
          displayTitle
        }
        visibility
        rating
      }
    }
  }
`;

/**
 * __useUpdateCallMutation__
 *
 * To run a mutation, you first call `useUpdateCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallMutation, { data, loading, error }] = useUpdateCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      positionId: // value for 'positionId'
 *      rating: // value for 'rating'
 *      bullhornCommentAction: // value for 'bullhornCommentAction'
 *      candidateFirstName: // value for 'candidateFirstName'
 *      candidateLastName: // value for 'candidateLastName'
 *      candidateEmail: // value for 'candidateEmail'
 *      candidateId: // value for 'candidateId'
 *      iaVersion: // value for 'iaVersion'
 *   },
 * });
 */
export function useUpdateCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallMutation,
    UpdateCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCallMutation, UpdateCallMutationVariables>(
    UpdateCallDocument,
    options
  );
}
export type UpdateCallMutationHookResult = ReturnType<
  typeof useUpdateCallMutation
>;
export type UpdateCallMutationResult =
  Apollo.MutationResult<UpdateCallMutation>;
export type UpdateCallMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallMutation,
  UpdateCallMutationVariables
>;
export const UpdateCallNoteDocument = gql`
  mutation UpdateCallNote(
    $id: ID!
    $text: String
    $type: CallNoteType
    $visibility: CallNoteVisibility
    $clipId: ID
  ) {
    updateCallNote(
      id: $id
      text: $text
      type: $type
      visibility: $visibility
      clipId: $clipId
    ) {
      callNote {
        ...CallNote
      }
    }
  }
  ${CallNoteFragmentDoc}
`;

/**
 * __useUpdateCallNoteMutation__
 *
 * To run a mutation, you first call `useUpdateCallNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallNoteMutation, { data, loading, error }] = useUpdateCallNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      type: // value for 'type'
 *      visibility: // value for 'visibility'
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useUpdateCallNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallNoteMutation,
    UpdateCallNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallNoteMutation,
    UpdateCallNoteMutationVariables
  >(UpdateCallNoteDocument, options);
}
export type UpdateCallNoteMutationHookResult = ReturnType<
  typeof useUpdateCallNoteMutation
>;
export type UpdateCallNoteMutationResult =
  Apollo.MutationResult<UpdateCallNoteMutation>;
export type UpdateCallNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallNoteMutation,
  UpdateCallNoteMutationVariables
>;
export const UpdateCallPositionDocument = gql`
  mutation UpdateCallPosition($id: ID!, $positionId: String!) {
    updateCallPosition(id: $id, positionId: $positionId) {
      call {
        id
        position {
          id
          displayTitle
        }
      }
    }
  }
`;

/**
 * __useUpdateCallPositionMutation__
 *
 * To run a mutation, you first call `useUpdateCallPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallPositionMutation, { data, loading, error }] = useUpdateCallPositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useUpdateCallPositionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallPositionMutation,
    UpdateCallPositionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallPositionMutation,
    UpdateCallPositionMutationVariables
  >(UpdateCallPositionDocument, options);
}
export type UpdateCallPositionMutationHookResult = ReturnType<
  typeof useUpdateCallPositionMutation
>;
export type UpdateCallPositionMutationResult =
  Apollo.MutationResult<UpdateCallPositionMutation>;
export type UpdateCallPositionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallPositionMutation,
  UpdateCallPositionMutationVariables
>;
export const UpdateCallRatingDocument = gql`
  mutation UpdateCallRating($id: ID!, $rating: Int!) {
    updateCall(id: $id, rating: $rating) {
      call {
        id
        rating
      }
    }
  }
`;

/**
 * __useUpdateCallRatingMutation__
 *
 * To run a mutation, you first call `useUpdateCallRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallRatingMutation, { data, loading, error }] = useUpdateCallRatingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useUpdateCallRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallRatingMutation,
    UpdateCallRatingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallRatingMutation,
    UpdateCallRatingMutationVariables
  >(UpdateCallRatingDocument, options);
}
export type UpdateCallRatingMutationHookResult = ReturnType<
  typeof useUpdateCallRatingMutation
>;
export type UpdateCallRatingMutationResult =
  Apollo.MutationResult<UpdateCallRatingMutation>;
export type UpdateCallRatingMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallRatingMutation,
  UpdateCallRatingMutationVariables
>;
export const UpdateCallSpeakerDocument = gql`
  mutation UpdateCallSpeaker(
    $callId: ID!
    $speakerTag: Int!
    $speakerOptionId: String!
  ) {
    updateCallSpeaker(
      callId: $callId
      speakerTag: $speakerTag
      speakerOptionId: $speakerOptionId
    ) {
      call {
        id
        speakers {
          ...CallSpeaker
        }
        notes {
          ...CallNote
        }
      }
    }
  }
  ${CallSpeakerFragmentDoc}
  ${CallNoteFragmentDoc}
`;

/**
 * __useUpdateCallSpeakerMutation__
 *
 * To run a mutation, you first call `useUpdateCallSpeakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallSpeakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallSpeakerMutation, { data, loading, error }] = useUpdateCallSpeakerMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      speakerTag: // value for 'speakerTag'
 *      speakerOptionId: // value for 'speakerOptionId'
 *   },
 * });
 */
export function useUpdateCallSpeakerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallSpeakerMutation,
    UpdateCallSpeakerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallSpeakerMutation,
    UpdateCallSpeakerMutationVariables
  >(UpdateCallSpeakerDocument, options);
}
export type UpdateCallSpeakerMutationHookResult = ReturnType<
  typeof useUpdateCallSpeakerMutation
>;
export type UpdateCallSpeakerMutationResult =
  Apollo.MutationResult<UpdateCallSpeakerMutation>;
export type UpdateCallSpeakerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallSpeakerMutation,
  UpdateCallSpeakerMutationVariables
>;
export const UpdateCallStatusDocument = gql`
  mutation UpdateCallStatus($id: ID!, $status: String!) {
    updateCall(id: $id, status: $status) {
      call {
        id
        status
      }
    }
  }
`;

/**
 * __useUpdateCallStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCallStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallStatusMutation, { data, loading, error }] = useUpdateCallStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateCallStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallStatusMutation,
    UpdateCallStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallStatusMutation,
    UpdateCallStatusMutationVariables
  >(UpdateCallStatusDocument, options);
}
export type UpdateCallStatusMutationHookResult = ReturnType<
  typeof useUpdateCallStatusMutation
>;
export type UpdateCallStatusMutationResult =
  Apollo.MutationResult<UpdateCallStatusMutation>;
export type UpdateCallStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallStatusMutation,
  UpdateCallStatusMutationVariables
>;
export const UpdateCallVisibilityDocument = gql`
  mutation UpdateCallVisibility($id: ID!, $visibility: CallVisibility!) {
    updateCall(id: $id, visibility: $visibility) {
      call {
        id
        visibility
        visibleTo {
          ...RecordingVisibleTo
        }
      }
    }
  }
  ${RecordingVisibleToFragmentDoc}
`;

/**
 * __useUpdateCallVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdateCallVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallVisibilityMutation, { data, loading, error }] = useUpdateCallVisibilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useUpdateCallVisibilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallVisibilityMutation,
    UpdateCallVisibilityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallVisibilityMutation,
    UpdateCallVisibilityMutationVariables
  >(UpdateCallVisibilityDocument, options);
}
export type UpdateCallVisibilityMutationHookResult = ReturnType<
  typeof useUpdateCallVisibilityMutation
>;
export type UpdateCallVisibilityMutationResult =
  Apollo.MutationResult<UpdateCallVisibilityMutation>;
export type UpdateCallVisibilityMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallVisibilityMutation,
  UpdateCallVisibilityMutationVariables
>;
export const UpdateCandidateEmailOptOutDocument = gql`
  mutation UpdateCandidateEmailOptOut(
    $orgId: ID!
    $verificationCode: String!
    $recordingDisabled: Boolean!
  ) {
    updateCandidateEmailOptOut(
      orgId: $orgId
      verificationCode: $verificationCode
      recordingDisabled: $recordingDisabled
    ) {
      candidateEmailOptOut {
        verificationCode
        recordingDisabled
        organizationName
      }
    }
  }
`;

/**
 * __useUpdateCandidateEmailOptOutMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateEmailOptOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateEmailOptOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateEmailOptOutMutation, { data, loading, error }] = useUpdateCandidateEmailOptOutMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      verificationCode: // value for 'verificationCode'
 *      recordingDisabled: // value for 'recordingDisabled'
 *   },
 * });
 */
export function useUpdateCandidateEmailOptOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCandidateEmailOptOutMutation,
    UpdateCandidateEmailOptOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCandidateEmailOptOutMutation,
    UpdateCandidateEmailOptOutMutationVariables
  >(UpdateCandidateEmailOptOutDocument, options);
}
export type UpdateCandidateEmailOptOutMutationHookResult = ReturnType<
  typeof useUpdateCandidateEmailOptOutMutation
>;
export type UpdateCandidateEmailOptOutMutationResult =
  Apollo.MutationResult<UpdateCandidateEmailOptOutMutation>;
export type UpdateCandidateEmailOptOutMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCandidateEmailOptOutMutation,
    UpdateCandidateEmailOptOutMutationVariables
  >;
export const UpdateCandidateDocument = gql`
  mutation UpdateCandidate(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $email: String
    $phoneNumber: String
    $phoneType: CandidatePhoneType
  ) {
    updateCandidate(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      phoneType: $phoneType
    ) {
      candidate {
        id
        firstName
        lastName
        fullName
        defaultEmail
        emails
        defaultPhoneNumberObj {
          id
          type
          phoneNumber
        }
        phoneNumbers {
          id
          type
          phoneNumber
        }
      }
    }
  }
`;

/**
 * __useUpdateCandidateMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateMutation, { data, loading, error }] = useUpdateCandidateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      phoneType: // value for 'phoneType'
 *   },
 * });
 */
export function useUpdateCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCandidateMutation,
    UpdateCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCandidateMutation,
    UpdateCandidateMutationVariables
  >(UpdateCandidateDocument, options);
}
export type UpdateCandidateMutationHookResult = ReturnType<
  typeof useUpdateCandidateMutation
>;
export type UpdateCandidateMutationResult =
  Apollo.MutationResult<UpdateCandidateMutation>;
export type UpdateCandidateMutationOptions = Apollo.BaseMutationOptions<
  UpdateCandidateMutation,
  UpdateCandidateMutationVariables
>;
export const UpdateCandidateOptOutDocument = gql`
  mutation UpdateCandidateOptOut($id: ID!, $recordingDisabled: Boolean!) {
    updateCandidateOptOut(id: $id, recordingDisabled: $recordingDisabled) {
      candidate {
        id
        recordingDisabled
        scheduledInterviews {
          ...ScheduledInterviewListItem
        }
      }
    }
  }
  ${ScheduledInterviewListItemFragmentDoc}
`;

/**
 * __useUpdateCandidateOptOutMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateOptOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateOptOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateOptOutMutation, { data, loading, error }] = useUpdateCandidateOptOutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      recordingDisabled: // value for 'recordingDisabled'
 *   },
 * });
 */
export function useUpdateCandidateOptOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCandidateOptOutMutation,
    UpdateCandidateOptOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCandidateOptOutMutation,
    UpdateCandidateOptOutMutationVariables
  >(UpdateCandidateOptOutDocument, options);
}
export type UpdateCandidateOptOutMutationHookResult = ReturnType<
  typeof useUpdateCandidateOptOutMutation
>;
export type UpdateCandidateOptOutMutationResult =
  Apollo.MutationResult<UpdateCandidateOptOutMutation>;
export type UpdateCandidateOptOutMutationOptions = Apollo.BaseMutationOptions<
  UpdateCandidateOptOutMutation,
  UpdateCandidateOptOutMutationVariables
>;
export const UpdateClientDocument = gql`
  mutation UpdateClient($id: ID!, $name: String!) {
    updateClient(id: $id, name: $name) {
      client {
        id
        name
      }
    }
  }
`;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >(UpdateClientDocument, options);
}
export type UpdateClientMutationHookResult = ReturnType<
  typeof useUpdateClientMutation
>;
export type UpdateClientMutationResult =
  Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const UpdateClipDocument = gql`
  mutation updateClip(
    $clipId: ID!
    $visibility: CallVisibility
    $name: String
  ) {
    updateClip(clipId: $clipId, visibility: $visibility, name: $name) {
      clip {
        ...Clip
        visibleTo {
          ...RecordingVisibleTo
        }
        call {
          id
          callClips {
            ...Clip
          }
        }
      }
    }
  }
  ${ClipFragmentDoc}
  ${RecordingVisibleToFragmentDoc}
`;

/**
 * __useUpdateClipMutation__
 *
 * To run a mutation, you first call `useUpdateClipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClipMutation, { data, loading, error }] = useUpdateClipMutation({
 *   variables: {
 *      clipId: // value for 'clipId'
 *      visibility: // value for 'visibility'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateClipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClipMutation,
    UpdateClipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClipMutation, UpdateClipMutationVariables>(
    UpdateClipDocument,
    options
  );
}
export type UpdateClipMutationHookResult = ReturnType<
  typeof useUpdateClipMutation
>;
export type UpdateClipMutationResult =
  Apollo.MutationResult<UpdateClipMutation>;
export type UpdateClipMutationOptions = Apollo.BaseMutationOptions<
  UpdateClipMutation,
  UpdateClipMutationVariables
>;
export const UpdateCurrentUserDocument = gql`
  mutation UpdateCurrentUser(
    $firstName: String
    $lastName: String
    $emailAliases: String
    $phoneNumber: String
    $phoneNumberExtension: String
    $maskPersonalPhoneNumber: Boolean
    $verifyPhoneNumber: Boolean
    $phoneNumberSkipped: Boolean
    $calendarSyncSkipped: Boolean
    $autofillGreenhouseScorecard: Boolean
    $zoomAppAutoOpenEnabled: Boolean
    $browserExtensionVersion: String
    $timezoneName: String
    $persona: String
    $showJoinWithBrighthire: String
    $defaultAiNoteFormat: String
    $includeOnboardingSteps: Boolean = false
  ) {
    updateCurrentUser(
      firstName: $firstName
      lastName: $lastName
      emailAliases: $emailAliases
      phoneNumber: $phoneNumber
      phoneNumberExtension: $phoneNumberExtension
      maskPersonalPhoneNumber: $maskPersonalPhoneNumber
      verifyPhoneNumber: $verifyPhoneNumber
      phoneNumberSkipped: $phoneNumberSkipped
      calendarSyncSkipped: $calendarSyncSkipped
      autofillGreenhouseScorecard: $autofillGreenhouseScorecard
      zoomAppAutoOpenEnabled: $zoomAppAutoOpenEnabled
      browserExtensionVersion: $browserExtensionVersion
      timezoneName: $timezoneName
      persona: $persona
      defaultAiNoteFormat: $defaultAiNoteFormat
      showJoinWithBrighthire: $showJoinWithBrighthire
    ) {
      currentUser {
        ...CurrentUser
        onboardingSteps @include(if: $includeOnboardingSteps) {
          step
          rule
          status
        }
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUpdateCurrentUserMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserMutation, { data, loading, error }] = useUpdateCurrentUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      emailAliases: // value for 'emailAliases'
 *      phoneNumber: // value for 'phoneNumber'
 *      phoneNumberExtension: // value for 'phoneNumberExtension'
 *      maskPersonalPhoneNumber: // value for 'maskPersonalPhoneNumber'
 *      verifyPhoneNumber: // value for 'verifyPhoneNumber'
 *      phoneNumberSkipped: // value for 'phoneNumberSkipped'
 *      calendarSyncSkipped: // value for 'calendarSyncSkipped'
 *      autofillGreenhouseScorecard: // value for 'autofillGreenhouseScorecard'
 *      zoomAppAutoOpenEnabled: // value for 'zoomAppAutoOpenEnabled'
 *      browserExtensionVersion: // value for 'browserExtensionVersion'
 *      timezoneName: // value for 'timezoneName'
 *      persona: // value for 'persona'
 *      showJoinWithBrighthire: // value for 'showJoinWithBrighthire'
 *      defaultAiNoteFormat: // value for 'defaultAiNoteFormat'
 *      includeOnboardingSteps: // value for 'includeOnboardingSteps'
 *   },
 * });
 */
export function useUpdateCurrentUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserMutation,
    UpdateCurrentUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentUserMutation,
    UpdateCurrentUserMutationVariables
  >(UpdateCurrentUserDocument, options);
}
export type UpdateCurrentUserMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserMutation
>;
export type UpdateCurrentUserMutationResult =
  Apollo.MutationResult<UpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateCurrentUserMutation,
  UpdateCurrentUserMutationVariables
>;
export const UpdateCurrentUserSubscriptionsSettingsDocument = gql`
  mutation UpdateCurrentUserSubscriptionsSettings(
    $interviewRecapSubscription: UserSubscriptionSettings!
    $interviewPrepSubscription: UserSubscriptionSettings!
    $weeklyRecapSubscription: UserSubscriptionSettings!
    $monthlyAnalyticsSubscription: UserSubscriptionSettings!
    $callShareSubscription: UserSubscriptionSettings!
    $callNoteSubscription: UserSubscriptionSettings!
    $morningDigestSubscription: UserSubscriptionSettings!
  ) {
    updateCurrentUserSubscriptionsSettings(
      interviewRecapSubscription: $interviewRecapSubscription
      interviewPrepSubscription: $interviewPrepSubscription
      weeklyRecapSubscription: $weeklyRecapSubscription
      monthlyAnalyticsSubscription: $monthlyAnalyticsSubscription
      callShareSubscription: $callShareSubscription
      callNoteSubscription: $callNoteSubscription
      morningDigestSubscription: $morningDigestSubscription
    ) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUpdateCurrentUserSubscriptionsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserSubscriptionsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserSubscriptionsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserSubscriptionsSettingsMutation, { data, loading, error }] = useUpdateCurrentUserSubscriptionsSettingsMutation({
 *   variables: {
 *      interviewRecapSubscription: // value for 'interviewRecapSubscription'
 *      interviewPrepSubscription: // value for 'interviewPrepSubscription'
 *      weeklyRecapSubscription: // value for 'weeklyRecapSubscription'
 *      monthlyAnalyticsSubscription: // value for 'monthlyAnalyticsSubscription'
 *      callShareSubscription: // value for 'callShareSubscription'
 *      callNoteSubscription: // value for 'callNoteSubscription'
 *      morningDigestSubscription: // value for 'morningDigestSubscription'
 *   },
 * });
 */
export function useUpdateCurrentUserSubscriptionsSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserSubscriptionsSettingsMutation,
    UpdateCurrentUserSubscriptionsSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentUserSubscriptionsSettingsMutation,
    UpdateCurrentUserSubscriptionsSettingsMutationVariables
  >(UpdateCurrentUserSubscriptionsSettingsDocument, options);
}
export type UpdateCurrentUserSubscriptionsSettingsMutationHookResult =
  ReturnType<typeof useUpdateCurrentUserSubscriptionsSettingsMutation>;
export type UpdateCurrentUserSubscriptionsSettingsMutationResult =
  Apollo.MutationResult<UpdateCurrentUserSubscriptionsSettingsMutation>;
export type UpdateCurrentUserSubscriptionsSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentUserSubscriptionsSettingsMutation,
    UpdateCurrentUserSubscriptionsSettingsMutationVariables
  >;
export const UpdateCurrentUserShowActionItemsDocument = gql`
  mutation UpdateCurrentUserShowActionItems($show: Boolean!) {
    updateCurrentUserShowActionItems(show: $show) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUpdateCurrentUserShowActionItemsMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserShowActionItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserShowActionItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserShowActionItemsMutation, { data, loading, error }] = useUpdateCurrentUserShowActionItemsMutation({
 *   variables: {
 *      show: // value for 'show'
 *   },
 * });
 */
export function useUpdateCurrentUserShowActionItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserShowActionItemsMutation,
    UpdateCurrentUserShowActionItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentUserShowActionItemsMutation,
    UpdateCurrentUserShowActionItemsMutationVariables
  >(UpdateCurrentUserShowActionItemsDocument, options);
}
export type UpdateCurrentUserShowActionItemsMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserShowActionItemsMutation
>;
export type UpdateCurrentUserShowActionItemsMutationResult =
  Apollo.MutationResult<UpdateCurrentUserShowActionItemsMutation>;
export type UpdateCurrentUserShowActionItemsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentUserShowActionItemsMutation,
    UpdateCurrentUserShowActionItemsMutationVariables
  >;
export const UpdateCurrentUserViewedTranscriptionWarningDocument = gql`
  mutation UpdateCurrentUserViewedTranscriptionWarning(
    $hasViewedTranscriptionWarning: Boolean
  ) {
    updateCurrentUserViewedTranscriptionWarning(
      hasViewedTranscriptionWarning: $hasViewedTranscriptionWarning
    ) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUpdateCurrentUserViewedTranscriptionWarningMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserViewedTranscriptionWarningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserViewedTranscriptionWarningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserViewedTranscriptionWarningMutation, { data, loading, error }] = useUpdateCurrentUserViewedTranscriptionWarningMutation({
 *   variables: {
 *      hasViewedTranscriptionWarning: // value for 'hasViewedTranscriptionWarning'
 *   },
 * });
 */
export function useUpdateCurrentUserViewedTranscriptionWarningMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserViewedTranscriptionWarningMutation,
    UpdateCurrentUserViewedTranscriptionWarningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentUserViewedTranscriptionWarningMutation,
    UpdateCurrentUserViewedTranscriptionWarningMutationVariables
  >(UpdateCurrentUserViewedTranscriptionWarningDocument, options);
}
export type UpdateCurrentUserViewedTranscriptionWarningMutationHookResult =
  ReturnType<typeof useUpdateCurrentUserViewedTranscriptionWarningMutation>;
export type UpdateCurrentUserViewedTranscriptionWarningMutationResult =
  Apollo.MutationResult<UpdateCurrentUserViewedTranscriptionWarningMutation>;
export type UpdateCurrentUserViewedTranscriptionWarningMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentUserViewedTranscriptionWarningMutation,
    UpdateCurrentUserViewedTranscriptionWarningMutationVariables
  >;
export const UpdateInterviewOptInDocument = gql`
  mutation UpdateInterviewOptIn(
    $location: String!
    $optIn: Boolean!
    $isInterviewer: Boolean!
    $organizationId: String!
  ) {
    updateInterviewOptIn(
      location: $location
      optIn: $optIn
      isInterviewer: $isInterviewer
      organizationId: $organizationId
    ) {
      success
    }
  }
`;

/**
 * __useUpdateInterviewOptInMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewOptInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewOptInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewOptInMutation, { data, loading, error }] = useUpdateInterviewOptInMutation({
 *   variables: {
 *      location: // value for 'location'
 *      optIn: // value for 'optIn'
 *      isInterviewer: // value for 'isInterviewer'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUpdateInterviewOptInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInterviewOptInMutation,
    UpdateInterviewOptInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInterviewOptInMutation,
    UpdateInterviewOptInMutationVariables
  >(UpdateInterviewOptInDocument, options);
}
export type UpdateInterviewOptInMutationHookResult = ReturnType<
  typeof useUpdateInterviewOptInMutation
>;
export type UpdateInterviewOptInMutationResult =
  Apollo.MutationResult<UpdateInterviewOptInMutation>;
export type UpdateInterviewOptInMutationOptions = Apollo.BaseMutationOptions<
  UpdateInterviewOptInMutation,
  UpdateInterviewOptInMutationVariables
>;
export const UpdateLeverOauthIntegrationSettingsDocument = gql`
  mutation UpdateLeverOauthIntegrationSettings(
    $useSandbox: Boolean!
    $syncGuides: Boolean!
    $createSecretNotes: Boolean!
  ) {
    updateLeverOauthIntegrationSettings(
      useSandbox: $useSandbox
      syncGuides: $syncGuides
      createSecretNotes: $createSecretNotes
    ) {
      leverOauthSetup {
        id
        useSandbox
        valid
        syncCallGuidesFromAts
        createSecretNotes
      }
    }
  }
`;

/**
 * __useUpdateLeverOauthIntegrationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateLeverOauthIntegrationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeverOauthIntegrationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeverOauthIntegrationSettingsMutation, { data, loading, error }] = useUpdateLeverOauthIntegrationSettingsMutation({
 *   variables: {
 *      useSandbox: // value for 'useSandbox'
 *      syncGuides: // value for 'syncGuides'
 *      createSecretNotes: // value for 'createSecretNotes'
 *   },
 * });
 */
export function useUpdateLeverOauthIntegrationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLeverOauthIntegrationSettingsMutation,
    UpdateLeverOauthIntegrationSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLeverOauthIntegrationSettingsMutation,
    UpdateLeverOauthIntegrationSettingsMutationVariables
  >(UpdateLeverOauthIntegrationSettingsDocument, options);
}
export type UpdateLeverOauthIntegrationSettingsMutationHookResult = ReturnType<
  typeof useUpdateLeverOauthIntegrationSettingsMutation
>;
export type UpdateLeverOauthIntegrationSettingsMutationResult =
  Apollo.MutationResult<UpdateLeverOauthIntegrationSettingsMutation>;
export type UpdateLeverOauthIntegrationSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLeverOauthIntegrationSettingsMutation,
    UpdateLeverOauthIntegrationSettingsMutationVariables
  >;
export const UpdateMyInterviewRecordingOptionsDocument = gql`
  mutation UpdateMyInterviewRecordingOptions(
    $importRule: UserInterviewImportRule
  ) {
    updateMyInterviewRecordingOptions(importRule: $importRule) {
      currentUser {
        id
        interviewImportRule
      }
    }
  }
`;

/**
 * __useUpdateMyInterviewRecordingOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateMyInterviewRecordingOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyInterviewRecordingOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyInterviewRecordingOptionsMutation, { data, loading, error }] = useUpdateMyInterviewRecordingOptionsMutation({
 *   variables: {
 *      importRule: // value for 'importRule'
 *   },
 * });
 */
export function useUpdateMyInterviewRecordingOptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyInterviewRecordingOptionsMutation,
    UpdateMyInterviewRecordingOptionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyInterviewRecordingOptionsMutation,
    UpdateMyInterviewRecordingOptionsMutationVariables
  >(UpdateMyInterviewRecordingOptionsDocument, options);
}
export type UpdateMyInterviewRecordingOptionsMutationHookResult = ReturnType<
  typeof useUpdateMyInterviewRecordingOptionsMutation
>;
export type UpdateMyInterviewRecordingOptionsMutationResult =
  Apollo.MutationResult<UpdateMyInterviewRecordingOptionsMutation>;
export type UpdateMyInterviewRecordingOptionsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMyInterviewRecordingOptionsMutation,
    UpdateMyInterviewRecordingOptionsMutationVariables
  >;
export const UpdateOptInSetupDocument = gql`
  mutation UpdateOptInSetup(
    $whoIsPageText: String!
    $yesNoPageText: String
    $alwaysCandidate: Boolean!
    $learnMoreLink: String
    $heroImageUrl: String
  ) {
    updateOptInSetup(
      whoIsPageText: $whoIsPageText
      yesNoPageText: $yesNoPageText
      alwaysCandidate: $alwaysCandidate
      learnMoreLink: $learnMoreLink
      heroImageUrl: $heroImageUrl
    ) {
      success
    }
  }
`;

/**
 * __useUpdateOptInSetupMutation__
 *
 * To run a mutation, you first call `useUpdateOptInSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptInSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptInSetupMutation, { data, loading, error }] = useUpdateOptInSetupMutation({
 *   variables: {
 *      whoIsPageText: // value for 'whoIsPageText'
 *      yesNoPageText: // value for 'yesNoPageText'
 *      alwaysCandidate: // value for 'alwaysCandidate'
 *      learnMoreLink: // value for 'learnMoreLink'
 *      heroImageUrl: // value for 'heroImageUrl'
 *   },
 * });
 */
export function useUpdateOptInSetupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOptInSetupMutation,
    UpdateOptInSetupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOptInSetupMutation,
    UpdateOptInSetupMutationVariables
  >(UpdateOptInSetupDocument, options);
}
export type UpdateOptInSetupMutationHookResult = ReturnType<
  typeof useUpdateOptInSetupMutation
>;
export type UpdateOptInSetupMutationResult =
  Apollo.MutationResult<UpdateOptInSetupMutation>;
export type UpdateOptInSetupMutationOptions = Apollo.BaseMutationOptions<
  UpdateOptInSetupMutation,
  UpdateOptInSetupMutationVariables
>;
export const UpdateOrganizationAdvancedConfigurationsDocument = gql`
  mutation UpdateOrganizationAdvancedConfigurations(
    $hideOtherInterviewerNotesInExtension: Boolean!
    $assignGuidesToInterviewersEnabled: Boolean!
    $audioOnlyViewing: Boolean!
    $allowManualScheduledInterviews: Boolean!
    $allowInPersonMeetings: Boolean!
    $playInPersonRecordingDisclaimer: Boolean!
    $textBeforeCallEnabled: Boolean!
    $includeTimestampsInNoteOutputs: Boolean!
    $externalShareDefaultDurationDays: Float!
    $allowUserLevelImportRules: Boolean!
  ) {
    updateOrganizationAdvancedConfigurations(
      hideOtherInterviewerNotesInExtension: $hideOtherInterviewerNotesInExtension
      assignGuidesToInterviewersEnabled: $assignGuidesToInterviewersEnabled
      audioOnlyViewing: $audioOnlyViewing
      allowManualScheduledInterviews: $allowManualScheduledInterviews
      allowInPersonMeetings: $allowInPersonMeetings
      playInPersonRecordingDisclaimer: $playInPersonRecordingDisclaimer
      textBeforeCallEnabled: $textBeforeCallEnabled
      includeTimestampsInNoteOutputs: $includeTimestampsInNoteOutputs
      externalShareDefaultDurationDays: $externalShareDefaultDurationDays
      allowUserLevelImportRules: $allowUserLevelImportRules
    ) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUpdateOrganizationAdvancedConfigurationsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationAdvancedConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationAdvancedConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationAdvancedConfigurationsMutation, { data, loading, error }] = useUpdateOrganizationAdvancedConfigurationsMutation({
 *   variables: {
 *      hideOtherInterviewerNotesInExtension: // value for 'hideOtherInterviewerNotesInExtension'
 *      assignGuidesToInterviewersEnabled: // value for 'assignGuidesToInterviewersEnabled'
 *      audioOnlyViewing: // value for 'audioOnlyViewing'
 *      allowManualScheduledInterviews: // value for 'allowManualScheduledInterviews'
 *      allowInPersonMeetings: // value for 'allowInPersonMeetings'
 *      playInPersonRecordingDisclaimer: // value for 'playInPersonRecordingDisclaimer'
 *      textBeforeCallEnabled: // value for 'textBeforeCallEnabled'
 *      includeTimestampsInNoteOutputs: // value for 'includeTimestampsInNoteOutputs'
 *      externalShareDefaultDurationDays: // value for 'externalShareDefaultDurationDays'
 *      allowUserLevelImportRules: // value for 'allowUserLevelImportRules'
 *   },
 * });
 */
export function useUpdateOrganizationAdvancedConfigurationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationAdvancedConfigurationsMutation,
    UpdateOrganizationAdvancedConfigurationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationAdvancedConfigurationsMutation,
    UpdateOrganizationAdvancedConfigurationsMutationVariables
  >(UpdateOrganizationAdvancedConfigurationsDocument, options);
}
export type UpdateOrganizationAdvancedConfigurationsMutationHookResult =
  ReturnType<typeof useUpdateOrganizationAdvancedConfigurationsMutation>;
export type UpdateOrganizationAdvancedConfigurationsMutationResult =
  Apollo.MutationResult<UpdateOrganizationAdvancedConfigurationsMutation>;
export type UpdateOrganizationAdvancedConfigurationsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationAdvancedConfigurationsMutation,
    UpdateOrganizationAdvancedConfigurationsMutationVariables
  >;
export const UpdateOrganizationAiSettingsDocument = gql`
  mutation UpdateOrganizationAiSettings(
    $allowAiCustomTemplates: Boolean!
    $pasteAiNotesInScorecard: Boolean!
    $askEnabled: Boolean!
  ) {
    updateOrganizationAiSettings(
      allowAiCustomTemplates: $allowAiCustomTemplates
      pasteAiNotesInScorecard: $pasteAiNotesInScorecard
      askEnabled: $askEnabled
    ) {
      organization {
        id
        allowAiCustomTemplates
        pasteAiNotesInScorecard
        askEnabled
      }
    }
  }
`;

/**
 * __useUpdateOrganizationAiSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationAiSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationAiSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationAiSettingsMutation, { data, loading, error }] = useUpdateOrganizationAiSettingsMutation({
 *   variables: {
 *      allowAiCustomTemplates: // value for 'allowAiCustomTemplates'
 *      pasteAiNotesInScorecard: // value for 'pasteAiNotesInScorecard'
 *      askEnabled: // value for 'askEnabled'
 *   },
 * });
 */
export function useUpdateOrganizationAiSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationAiSettingsMutation,
    UpdateOrganizationAiSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationAiSettingsMutation,
    UpdateOrganizationAiSettingsMutationVariables
  >(UpdateOrganizationAiSettingsDocument, options);
}
export type UpdateOrganizationAiSettingsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationAiSettingsMutation
>;
export type UpdateOrganizationAiSettingsMutationResult =
  Apollo.MutationResult<UpdateOrganizationAiSettingsMutation>;
export type UpdateOrganizationAiSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationAiSettingsMutation,
    UpdateOrganizationAiSettingsMutationVariables
  >;
export const UpdateOrganizationAtsSettingsDocument = gql`
  mutation UpdateOrganizationAtsSettings($postNotesToAts: Boolean!) {
    updateOrganizationAtsSettings(postNotesToAts: $postNotesToAts) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUpdateOrganizationAtsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationAtsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationAtsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationAtsSettingsMutation, { data, loading, error }] = useUpdateOrganizationAtsSettingsMutation({
 *   variables: {
 *      postNotesToAts: // value for 'postNotesToAts'
 *   },
 * });
 */
export function useUpdateOrganizationAtsSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationAtsSettingsMutation,
    UpdateOrganizationAtsSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationAtsSettingsMutation,
    UpdateOrganizationAtsSettingsMutationVariables
  >(UpdateOrganizationAtsSettingsDocument, options);
}
export type UpdateOrganizationAtsSettingsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationAtsSettingsMutation
>;
export type UpdateOrganizationAtsSettingsMutationResult =
  Apollo.MutationResult<UpdateOrganizationAtsSettingsMutation>;
export type UpdateOrganizationAtsSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationAtsSettingsMutation,
    UpdateOrganizationAtsSettingsMutationVariables
  >;
export const UpdateOrganizationBetaDocument = gql`
  mutation UpdateOrganizationBeta(
    $allowSelfSignUp: Boolean!
    $allowPhoneSignIn: Boolean!
    $emailDomains: [String!]!
    $inviteScheduledInterviewers: Boolean!
    $requireActiveUserToImport: Boolean
  ) {
    updateOrganizationBeta(
      allowSelfSignUp: $allowSelfSignUp
      allowPhoneSignIn: $allowPhoneSignIn
      emailDomains: $emailDomains
      inviteScheduledInterviewers: $inviteScheduledInterviewers
      requireActiveUserToImport: $requireActiveUserToImport
    ) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUpdateOrganizationBetaMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationBetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationBetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationBetaMutation, { data, loading, error }] = useUpdateOrganizationBetaMutation({
 *   variables: {
 *      allowSelfSignUp: // value for 'allowSelfSignUp'
 *      allowPhoneSignIn: // value for 'allowPhoneSignIn'
 *      emailDomains: // value for 'emailDomains'
 *      inviteScheduledInterviewers: // value for 'inviteScheduledInterviewers'
 *      requireActiveUserToImport: // value for 'requireActiveUserToImport'
 *   },
 * });
 */
export function useUpdateOrganizationBetaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationBetaMutation,
    UpdateOrganizationBetaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationBetaMutation,
    UpdateOrganizationBetaMutationVariables
  >(UpdateOrganizationBetaDocument, options);
}
export type UpdateOrganizationBetaMutationHookResult = ReturnType<
  typeof useUpdateOrganizationBetaMutation
>;
export type UpdateOrganizationBetaMutationResult =
  Apollo.MutationResult<UpdateOrganizationBetaMutation>;
export type UpdateOrganizationBetaMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationBetaMutation,
  UpdateOrganizationBetaMutationVariables
>;
export const UpdateOrganizationGoogleMeetDocument = gql`
  mutation UpdateOrganizationGoogleMeet(
    $enabled: Boolean!
    $disclaimerType: NotetakerRecorderDisclaimerType!
    $disclaimer: String
    $virtualOnsiteEnabled: Boolean
    $displayName: String
    $displayImage: String
    $displayImageFilename: String
  ) {
    updateOrganizationGoogleMeet(
      enabled: $enabled
      disclaimerType: $disclaimerType
      disclaimer: $disclaimer
      displayName: $displayName
      displayImage: $displayImage
      displayImageFilename: $displayImageFilename
      virtualOnsiteEnabled: $virtualOnsiteEnabled
    ) {
      organization {
        ...Organization
      }
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useUpdateOrganizationGoogleMeetMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationGoogleMeetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationGoogleMeetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationGoogleMeetMutation, { data, loading, error }] = useUpdateOrganizationGoogleMeetMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *      disclaimerType: // value for 'disclaimerType'
 *      disclaimer: // value for 'disclaimer'
 *      virtualOnsiteEnabled: // value for 'virtualOnsiteEnabled'
 *      displayName: // value for 'displayName'
 *      displayImage: // value for 'displayImage'
 *      displayImageFilename: // value for 'displayImageFilename'
 *   },
 * });
 */
export function useUpdateOrganizationGoogleMeetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationGoogleMeetMutation,
    UpdateOrganizationGoogleMeetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationGoogleMeetMutation,
    UpdateOrganizationGoogleMeetMutationVariables
  >(UpdateOrganizationGoogleMeetDocument, options);
}
export type UpdateOrganizationGoogleMeetMutationHookResult = ReturnType<
  typeof useUpdateOrganizationGoogleMeetMutation
>;
export type UpdateOrganizationGoogleMeetMutationResult =
  Apollo.MutationResult<UpdateOrganizationGoogleMeetMutation>;
export type UpdateOrganizationGoogleMeetMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationGoogleMeetMutation,
    UpdateOrganizationGoogleMeetMutationVariables
  >;
export const UpdateOrganizationInterviewPlanSettingsDocument = gql`
  mutation UpdateOrganizationInterviewPlanSettings(
    $createGuidesFromInterviewPlansEnabled: Boolean!
  ) {
    updateOrganizationInterviewPlanSettings(
      createGuidesFromInterviewPlansEnabled: $createGuidesFromInterviewPlansEnabled
    ) {
      organization {
        ...Organization
      }
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useUpdateOrganizationInterviewPlanSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationInterviewPlanSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationInterviewPlanSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationInterviewPlanSettingsMutation, { data, loading, error }] = useUpdateOrganizationInterviewPlanSettingsMutation({
 *   variables: {
 *      createGuidesFromInterviewPlansEnabled: // value for 'createGuidesFromInterviewPlansEnabled'
 *   },
 * });
 */
export function useUpdateOrganizationInterviewPlanSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationInterviewPlanSettingsMutation,
    UpdateOrganizationInterviewPlanSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationInterviewPlanSettingsMutation,
    UpdateOrganizationInterviewPlanSettingsMutationVariables
  >(UpdateOrganizationInterviewPlanSettingsDocument, options);
}
export type UpdateOrganizationInterviewPlanSettingsMutationHookResult =
  ReturnType<typeof useUpdateOrganizationInterviewPlanSettingsMutation>;
export type UpdateOrganizationInterviewPlanSettingsMutationResult =
  Apollo.MutationResult<UpdateOrganizationInterviewPlanSettingsMutation>;
export type UpdateOrganizationInterviewPlanSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationInterviewPlanSettingsMutation,
    UpdateOrganizationInterviewPlanSettingsMutationVariables
  >;
export const UpdateOrganizationScoringDocument = gql`
  mutation UpdateOrganizationScoring(
    $isEnabled: Boolean!
    $guideOverallScoringEnabledByDefault: Boolean!
    $guideCompetencyScoringEnabledByDefault: Boolean!
    $oneStarRating: String!
    $twoStarRating: String!
    $threeStarRating: String!
    $fourStarRating: String!
    $fiveStarRating: String!
    $oneStarCompetencyRating: String!
    $twoStarCompetencyRating: String!
    $threeStarCompetencyRating: String!
    $fourStarCompetencyRating: String!
    $fiveStarCompetencyRating: String!
  ) {
    updateOrganizationScoring(
      isEnabled: $isEnabled
      guideOverallScoringEnabledByDefault: $guideOverallScoringEnabledByDefault
      guideCompetencyScoringEnabledByDefault: $guideCompetencyScoringEnabledByDefault
      oneStarRating: $oneStarRating
      twoStarRating: $twoStarRating
      threeStarRating: $threeStarRating
      fourStarRating: $fourStarRating
      fiveStarRating: $fiveStarRating
      oneStarCompetencyRating: $oneStarCompetencyRating
      twoStarCompetencyRating: $twoStarCompetencyRating
      threeStarCompetencyRating: $threeStarCompetencyRating
      fourStarCompetencyRating: $fourStarCompetencyRating
      fiveStarCompetencyRating: $fiveStarCompetencyRating
    ) {
      organization {
        ...Organization
      }
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useUpdateOrganizationScoringMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationScoringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationScoringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationScoringMutation, { data, loading, error }] = useUpdateOrganizationScoringMutation({
 *   variables: {
 *      isEnabled: // value for 'isEnabled'
 *      guideOverallScoringEnabledByDefault: // value for 'guideOverallScoringEnabledByDefault'
 *      guideCompetencyScoringEnabledByDefault: // value for 'guideCompetencyScoringEnabledByDefault'
 *      oneStarRating: // value for 'oneStarRating'
 *      twoStarRating: // value for 'twoStarRating'
 *      threeStarRating: // value for 'threeStarRating'
 *      fourStarRating: // value for 'fourStarRating'
 *      fiveStarRating: // value for 'fiveStarRating'
 *      oneStarCompetencyRating: // value for 'oneStarCompetencyRating'
 *      twoStarCompetencyRating: // value for 'twoStarCompetencyRating'
 *      threeStarCompetencyRating: // value for 'threeStarCompetencyRating'
 *      fourStarCompetencyRating: // value for 'fourStarCompetencyRating'
 *      fiveStarCompetencyRating: // value for 'fiveStarCompetencyRating'
 *   },
 * });
 */
export function useUpdateOrganizationScoringMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationScoringMutation,
    UpdateOrganizationScoringMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationScoringMutation,
    UpdateOrganizationScoringMutationVariables
  >(UpdateOrganizationScoringDocument, options);
}
export type UpdateOrganizationScoringMutationHookResult = ReturnType<
  typeof useUpdateOrganizationScoringMutation
>;
export type UpdateOrganizationScoringMutationResult =
  Apollo.MutationResult<UpdateOrganizationScoringMutation>;
export type UpdateOrganizationScoringMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationScoringMutation,
    UpdateOrganizationScoringMutationVariables
  >;
export const UpdateOrganizationTeamsNotetakerDocument = gql`
  mutation UpdateOrganizationTeamsNotetaker(
    $displayName: String
    $enabled: Boolean!
    $disclaimerType: NotetakerRecorderDisclaimerType!
    $disclaimer: String
    $virtualOnsiteEnabled: Boolean
    $displayImage: String
    $displayImageFilename: String
  ) {
    updateOrganizationTeamsNotetaker(
      displayName: $displayName
      enabled: $enabled
      disclaimerType: $disclaimerType
      disclaimer: $disclaimer
      displayImage: $displayImage
      displayImageFilename: $displayImageFilename
      virtualOnsiteEnabled: $virtualOnsiteEnabled
    ) {
      organization {
        ...Organization
      }
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useUpdateOrganizationTeamsNotetakerMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationTeamsNotetakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationTeamsNotetakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationTeamsNotetakerMutation, { data, loading, error }] = useUpdateOrganizationTeamsNotetakerMutation({
 *   variables: {
 *      displayName: // value for 'displayName'
 *      enabled: // value for 'enabled'
 *      disclaimerType: // value for 'disclaimerType'
 *      disclaimer: // value for 'disclaimer'
 *      virtualOnsiteEnabled: // value for 'virtualOnsiteEnabled'
 *      displayImage: // value for 'displayImage'
 *      displayImageFilename: // value for 'displayImageFilename'
 *   },
 * });
 */
export function useUpdateOrganizationTeamsNotetakerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationTeamsNotetakerMutation,
    UpdateOrganizationTeamsNotetakerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationTeamsNotetakerMutation,
    UpdateOrganizationTeamsNotetakerMutationVariables
  >(UpdateOrganizationTeamsNotetakerDocument, options);
}
export type UpdateOrganizationTeamsNotetakerMutationHookResult = ReturnType<
  typeof useUpdateOrganizationTeamsNotetakerMutation
>;
export type UpdateOrganizationTeamsNotetakerMutationResult =
  Apollo.MutationResult<UpdateOrganizationTeamsNotetakerMutation>;
export type UpdateOrganizationTeamsNotetakerMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationTeamsNotetakerMutation,
    UpdateOrganizationTeamsNotetakerMutationVariables
  >;
export const UpdateOrganizationVideoSettingsDocument = gql`
  mutation UpdateOrganizationVideoSettings(
    $maximumCallDurationForNotetakerToRejoin: Int
  ) {
    updateOrganizationVideoSettings(
      maximumCallDurationForNotetakerToRejoin: $maximumCallDurationForNotetakerToRejoin
    ) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUpdateOrganizationVideoSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationVideoSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationVideoSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationVideoSettingsMutation, { data, loading, error }] = useUpdateOrganizationVideoSettingsMutation({
 *   variables: {
 *      maximumCallDurationForNotetakerToRejoin: // value for 'maximumCallDurationForNotetakerToRejoin'
 *   },
 * });
 */
export function useUpdateOrganizationVideoSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationVideoSettingsMutation,
    UpdateOrganizationVideoSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationVideoSettingsMutation,
    UpdateOrganizationVideoSettingsMutationVariables
  >(UpdateOrganizationVideoSettingsDocument, options);
}
export type UpdateOrganizationVideoSettingsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationVideoSettingsMutation
>;
export type UpdateOrganizationVideoSettingsMutationResult =
  Apollo.MutationResult<UpdateOrganizationVideoSettingsMutation>;
export type UpdateOrganizationVideoSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationVideoSettingsMutation,
    UpdateOrganizationVideoSettingsMutationVariables
  >;
export const UpdateOrganizationZoomNotetakerDocument = gql`
  mutation UpdateOrganizationZoomNotetaker(
    $enabled: Boolean!
    $sendCancelRecordingChatMessage: Boolean!
    $disclaimerType: NotetakerRecorderDisclaimerType!
    $disclaimer: String
    $virtualOnsiteEnabled: Boolean
    $displayName: String
    $displayImage: String
    $displayImageFilename: String
    $zoomAppAutoOpenRule: OrgZoomAppAutoOpenRule!
  ) {
    updateOrganizationZoomNotetaker(
      enabled: $enabled
      sendCancelRecordingChatMessage: $sendCancelRecordingChatMessage
      disclaimerType: $disclaimerType
      disclaimer: $disclaimer
      virtualOnsiteEnabled: $virtualOnsiteEnabled
      displayName: $displayName
      displayImage: $displayImage
      displayImageFilename: $displayImageFilename
      zoomAppAutoOpenRule: $zoomAppAutoOpenRule
    ) {
      organization {
        id
        zoomNotetakerEnabled
        sendCancelRecordingChatMessage
        zoomNotetakerDisclaimer
        zoomNotetakerDisclaimerType
        zoomNotetakerDisplayImageBucketPath
        zoomNotetakerDisplayName
        virtualOnsiteEnabledZoom
        zoomAppAutoOpenRule
      }
    }
  }
`;

/**
 * __useUpdateOrganizationZoomNotetakerMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationZoomNotetakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationZoomNotetakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationZoomNotetakerMutation, { data, loading, error }] = useUpdateOrganizationZoomNotetakerMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *      sendCancelRecordingChatMessage: // value for 'sendCancelRecordingChatMessage'
 *      disclaimerType: // value for 'disclaimerType'
 *      disclaimer: // value for 'disclaimer'
 *      virtualOnsiteEnabled: // value for 'virtualOnsiteEnabled'
 *      displayName: // value for 'displayName'
 *      displayImage: // value for 'displayImage'
 *      displayImageFilename: // value for 'displayImageFilename'
 *      zoomAppAutoOpenRule: // value for 'zoomAppAutoOpenRule'
 *   },
 * });
 */
export function useUpdateOrganizationZoomNotetakerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationZoomNotetakerMutation,
    UpdateOrganizationZoomNotetakerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationZoomNotetakerMutation,
    UpdateOrganizationZoomNotetakerMutationVariables
  >(UpdateOrganizationZoomNotetakerDocument, options);
}
export type UpdateOrganizationZoomNotetakerMutationHookResult = ReturnType<
  typeof useUpdateOrganizationZoomNotetakerMutation
>;
export type UpdateOrganizationZoomNotetakerMutationResult =
  Apollo.MutationResult<UpdateOrganizationZoomNotetakerMutation>;
export type UpdateOrganizationZoomNotetakerMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationZoomNotetakerMutation,
    UpdateOrganizationZoomNotetakerMutationVariables
  >;
export const UpdateOrganizationZoomSettingsDocument = gql`
  mutation UpdateOrganizationZoomSettings(
    $automaticallyDeleteZoomRecordings: Boolean!
    $zoomAppAutoOpenRule: OrgZoomAppAutoOpenRule!
  ) {
    updateOrganizationZoomSettings(
      automaticallyDeleteZoomRecordings: $automaticallyDeleteZoomRecordings
      zoomAppAutoOpenRule: $zoomAppAutoOpenRule
    ) {
      currentUser {
        id
        organization {
          id
          automaticallyDeleteZoomRecordings
          zoomAppAutoOpenRule
        }
      }
    }
  }
`;

/**
 * __useUpdateOrganizationZoomSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationZoomSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationZoomSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationZoomSettingsMutation, { data, loading, error }] = useUpdateOrganizationZoomSettingsMutation({
 *   variables: {
 *      automaticallyDeleteZoomRecordings: // value for 'automaticallyDeleteZoomRecordings'
 *      zoomAppAutoOpenRule: // value for 'zoomAppAutoOpenRule'
 *   },
 * });
 */
export function useUpdateOrganizationZoomSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationZoomSettingsMutation,
    UpdateOrganizationZoomSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationZoomSettingsMutation,
    UpdateOrganizationZoomSettingsMutationVariables
  >(UpdateOrganizationZoomSettingsDocument, options);
}
export type UpdateOrganizationZoomSettingsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationZoomSettingsMutation
>;
export type UpdateOrganizationZoomSettingsMutationResult =
  Apollo.MutationResult<UpdateOrganizationZoomSettingsMutation>;
export type UpdateOrganizationZoomSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationZoomSettingsMutation,
    UpdateOrganizationZoomSettingsMutationVariables
  >;
export const UpdatePlanJobDescriptionSettingsDocument = gql`
  mutation UpdatePlanJobDescriptionSettings(
    $customTemplateId: ID!
    $sections: [CustomTemplateSection!]!
    $defaultPlanJobDescriptionTone: PlanJobDescriptionTone!
    $allowPlanJobDescriptionToneOverride: Boolean!
  ) {
    updatePlanJobDescriptionSettings(
      customTemplateId: $customTemplateId
      sections: $sections
      defaultPlanJobDescriptionTone: $defaultPlanJobDescriptionTone
      allowPlanJobDescriptionToneOverride: $allowPlanJobDescriptionToneOverride
    ) {
      planCustomTemplate {
        id
        sections {
          id
          sectionHeader
          sectionText
          order
          type
        }
      }
      organization {
        id
        defaultPlanJobDescriptionTone
        allowPlanJobDescriptionToneOverride
      }
    }
  }
`;

/**
 * __useUpdatePlanJobDescriptionSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePlanJobDescriptionSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanJobDescriptionSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanJobDescriptionSettingsMutation, { data, loading, error }] = useUpdatePlanJobDescriptionSettingsMutation({
 *   variables: {
 *      customTemplateId: // value for 'customTemplateId'
 *      sections: // value for 'sections'
 *      defaultPlanJobDescriptionTone: // value for 'defaultPlanJobDescriptionTone'
 *      allowPlanJobDescriptionToneOverride: // value for 'allowPlanJobDescriptionToneOverride'
 *   },
 * });
 */
export function useUpdatePlanJobDescriptionSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlanJobDescriptionSettingsMutation,
    UpdatePlanJobDescriptionSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlanJobDescriptionSettingsMutation,
    UpdatePlanJobDescriptionSettingsMutationVariables
  >(UpdatePlanJobDescriptionSettingsDocument, options);
}
export type UpdatePlanJobDescriptionSettingsMutationHookResult = ReturnType<
  typeof useUpdatePlanJobDescriptionSettingsMutation
>;
export type UpdatePlanJobDescriptionSettingsMutationResult =
  Apollo.MutationResult<UpdatePlanJobDescriptionSettingsMutation>;
export type UpdatePlanJobDescriptionSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePlanJobDescriptionSettingsMutation,
    UpdatePlanJobDescriptionSettingsMutationVariables
  >;
export const UpdatePositionDocument = gql`
  mutation UpdatePosition(
    $id: ID!
    $clientId: ID
    $title: String
    $videos: [VideoInput!]
  ) {
    updatePosition(
      id: $id
      clientId: $clientId
      title: $title
      videos: $videos
    ) {
      position {
        ...PositionListItem
        videos {
          url
        }
      }
    }
  }
  ${PositionListItemFragmentDoc}
`;

/**
 * __useUpdatePositionMutation__
 *
 * To run a mutation, you first call `useUpdatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionMutation, { data, loading, error }] = useUpdatePositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clientId: // value for 'clientId'
 *      title: // value for 'title'
 *      videos: // value for 'videos'
 *   },
 * });
 */
export function useUpdatePositionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePositionMutation,
    UpdatePositionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePositionMutation,
    UpdatePositionMutationVariables
  >(UpdatePositionDocument, options);
}
export type UpdatePositionMutationHookResult = ReturnType<
  typeof useUpdatePositionMutation
>;
export type UpdatePositionMutationResult =
  Apollo.MutationResult<UpdatePositionMutation>;
export type UpdatePositionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePositionMutation,
  UpdatePositionMutationVariables
>;
export const UpdatePositionsAutoJoinDocument = gql`
  mutation UpdatePositionsAutoJoin(
    $positionIds: [ID!]!
    $autoJoinInterview: Boolean!
  ) {
    updatePositionsAutoJoin(
      positionIds: $positionIds
      autoJoinInterview: $autoJoinInterview
    ) {
      positions {
        ...PositionJBPListItem
      }
    }
  }
  ${PositionJbpListItemFragmentDoc}
`;

/**
 * __useUpdatePositionsAutoJoinMutation__
 *
 * To run a mutation, you first call `useUpdatePositionsAutoJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionsAutoJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionsAutoJoinMutation, { data, loading, error }] = useUpdatePositionsAutoJoinMutation({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *      autoJoinInterview: // value for 'autoJoinInterview'
 *   },
 * });
 */
export function useUpdatePositionsAutoJoinMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePositionsAutoJoinMutation,
    UpdatePositionsAutoJoinMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePositionsAutoJoinMutation,
    UpdatePositionsAutoJoinMutationVariables
  >(UpdatePositionsAutoJoinDocument, options);
}
export type UpdatePositionsAutoJoinMutationHookResult = ReturnType<
  typeof useUpdatePositionsAutoJoinMutation
>;
export type UpdatePositionsAutoJoinMutationResult =
  Apollo.MutationResult<UpdatePositionsAutoJoinMutation>;
export type UpdatePositionsAutoJoinMutationOptions = Apollo.BaseMutationOptions<
  UpdatePositionsAutoJoinMutation,
  UpdatePositionsAutoJoinMutationVariables
>;
export const UpdateRolePoliciesDocument = gql`
  mutation UpdateRolePolicies(
    $basicCanSeeOthersNotes: Boolean!
    $recruiterCanSeeScores: Boolean!
    $recruiterCanSeePublicCalls: Boolean!
    $recruiterCanSeeRestrictedCalls: Boolean!
    $recruiterCanManageHiringTeams: Boolean!
    $recruiterCanCreatePositions: Boolean!
    $recruiterCanSeeOthersNotes: Boolean!
    $recruiterCanCreateTrainings: Boolean!
    $recruiterCanManageAllTrainings: Boolean!
    $recruiterCanShareRecordingsExternally: Boolean!
    $recruiterCanViewInterviewerQualityReport: Boolean!
    $recruiterCanConfigureInterviewerQualityReport: Boolean!
    $recruiterCanDownloadRecordings: Boolean!
    $recruiterCanManageRedactions: Boolean!
    $recruiterCanManageCustomTemplates: Boolean!
    $siteAdminCanDownloadRecordings: Boolean!
    $hiringManagerCanSeePrivateCalls: Boolean!
    $hiringManagerCanSeeOthersNotes: Boolean!
    $hiringManagerCanCreateTrainings: Boolean!
    $hiringManagerCanShareRecordingsExternally: Boolean!
    $interviewerCanSeeOthersNotes: Boolean!
    $memberCanSeePublicCalls: Boolean!
    $memberCanSeeOthersNotes: Boolean!
    $defaultSelfSignUpRole: ID!
    $defaultScheduledInterviewerInviteRole: ID!
    $defaultHiringTeamRole: ID!
    $defaultPositionCreateRole: ID!
    $defaultRecruiterCallVisibility: CallVisibility!
    $defaultPlanRole: ID
  ) {
    updateRolePolicies(
      basicCanSeeOthersNotes: $basicCanSeeOthersNotes
      recruiterCanSeeScores: $recruiterCanSeeScores
      recruiterCanSeePublicCalls: $recruiterCanSeePublicCalls
      recruiterCanSeeRestrictedCalls: $recruiterCanSeeRestrictedCalls
      recruiterCanManageHiringTeams: $recruiterCanManageHiringTeams
      recruiterCanCreatePositions: $recruiterCanCreatePositions
      recruiterCanSeeOthersNotes: $recruiterCanSeeOthersNotes
      recruiterCanCreateTrainings: $recruiterCanCreateTrainings
      recruiterCanManageAllTrainings: $recruiterCanManageAllTrainings
      recruiterCanShareRecordingsExternally: $recruiterCanShareRecordingsExternally
      recruiterCanViewInterviewerQualityReport: $recruiterCanViewInterviewerQualityReport
      recruiterCanConfigureInterviewerQualityReport: $recruiterCanConfigureInterviewerQualityReport
      recruiterCanDownloadRecordings: $recruiterCanDownloadRecordings
      recruiterCanManageRedactions: $recruiterCanManageRedactions
      recruiterCanManageCustomTemplates: $recruiterCanManageCustomTemplates
      siteAdminCanDownloadRecordings: $siteAdminCanDownloadRecordings
      hiringManagerCanSeePrivateCalls: $hiringManagerCanSeePrivateCalls
      hiringManagerCanSeeOthersNotes: $hiringManagerCanSeeOthersNotes
      hiringManagerCanCreateTrainings: $hiringManagerCanCreateTrainings
      hiringManagerCanShareRecordingsExternally: $hiringManagerCanShareRecordingsExternally
      interviewerCanSeeOthersNotes: $interviewerCanSeeOthersNotes
      memberCanSeePublicCalls: $memberCanSeePublicCalls
      memberCanSeeOthersNotes: $memberCanSeeOthersNotes
      defaultSelfSignUpRole: $defaultSelfSignUpRole
      defaultScheduledInterviewerInviteRole: $defaultScheduledInterviewerInviteRole
      defaultHiringTeamRole: $defaultHiringTeamRole
      defaultPositionCreateRole: $defaultPositionCreateRole
      defaultRecruiterCallVisibility: $defaultRecruiterCallVisibility
      defaultPlanRole: $defaultPlanRole
    ) {
      rolePolicies {
        basicCanSeeOthersNotes
        recruiterCanSeeScores
        recruiterCanSeePublicCalls
        recruiterCanSeeRestrictedCalls
        recruiterCanManageHiringTeams
        recruiterCanCreatePositions
        recruiterCanSeeOthersNotes
        recruiterCanCreateTrainings
        recruiterCanManageAllTrainings
        recruiterCanShareRecordingsExternally
        recruiterCanViewInterviewerQualityReport
        recruiterCanConfigureInterviewerQualityReport
        recruiterCanManageRedactions
        recruiterCanManageCustomTemplates
        siteAdminCanDownloadRecordings
        hiringManagerCanSeePrivateCalls
        hiringManagerCanSeeOthersNotes
        hiringManagerCanCreateTrainings
        hiringManagerCanShareRecordingsExternally
        interviewerCanSeeOthersNotes
        memberCanSeePublicCalls
        memberCanSeeOthersNotes
        defaultSelfSignUpRole
        defaultScheduledInterviewerInviteRole
        defaultHiringTeamRole
        defaultPositionCreateRole
        defaultRecruiterCallVisibility
        defaultPlanRole
      }
      currentUser {
        id
        organization {
          id
          userRoles {
            id
            name
            permissions
          }
          positionRoles {
            id
            name
            permissions
          }
        }
      }
    }
  }
`;

/**
 * __useUpdateRolePoliciesMutation__
 *
 * To run a mutation, you first call `useUpdateRolePoliciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolePoliciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolePoliciesMutation, { data, loading, error }] = useUpdateRolePoliciesMutation({
 *   variables: {
 *      basicCanSeeOthersNotes: // value for 'basicCanSeeOthersNotes'
 *      recruiterCanSeeScores: // value for 'recruiterCanSeeScores'
 *      recruiterCanSeePublicCalls: // value for 'recruiterCanSeePublicCalls'
 *      recruiterCanSeeRestrictedCalls: // value for 'recruiterCanSeeRestrictedCalls'
 *      recruiterCanManageHiringTeams: // value for 'recruiterCanManageHiringTeams'
 *      recruiterCanCreatePositions: // value for 'recruiterCanCreatePositions'
 *      recruiterCanSeeOthersNotes: // value for 'recruiterCanSeeOthersNotes'
 *      recruiterCanCreateTrainings: // value for 'recruiterCanCreateTrainings'
 *      recruiterCanManageAllTrainings: // value for 'recruiterCanManageAllTrainings'
 *      recruiterCanShareRecordingsExternally: // value for 'recruiterCanShareRecordingsExternally'
 *      recruiterCanViewInterviewerQualityReport: // value for 'recruiterCanViewInterviewerQualityReport'
 *      recruiterCanConfigureInterviewerQualityReport: // value for 'recruiterCanConfigureInterviewerQualityReport'
 *      recruiterCanDownloadRecordings: // value for 'recruiterCanDownloadRecordings'
 *      recruiterCanManageRedactions: // value for 'recruiterCanManageRedactions'
 *      recruiterCanManageCustomTemplates: // value for 'recruiterCanManageCustomTemplates'
 *      siteAdminCanDownloadRecordings: // value for 'siteAdminCanDownloadRecordings'
 *      hiringManagerCanSeePrivateCalls: // value for 'hiringManagerCanSeePrivateCalls'
 *      hiringManagerCanSeeOthersNotes: // value for 'hiringManagerCanSeeOthersNotes'
 *      hiringManagerCanCreateTrainings: // value for 'hiringManagerCanCreateTrainings'
 *      hiringManagerCanShareRecordingsExternally: // value for 'hiringManagerCanShareRecordingsExternally'
 *      interviewerCanSeeOthersNotes: // value for 'interviewerCanSeeOthersNotes'
 *      memberCanSeePublicCalls: // value for 'memberCanSeePublicCalls'
 *      memberCanSeeOthersNotes: // value for 'memberCanSeeOthersNotes'
 *      defaultSelfSignUpRole: // value for 'defaultSelfSignUpRole'
 *      defaultScheduledInterviewerInviteRole: // value for 'defaultScheduledInterviewerInviteRole'
 *      defaultHiringTeamRole: // value for 'defaultHiringTeamRole'
 *      defaultPositionCreateRole: // value for 'defaultPositionCreateRole'
 *      defaultRecruiterCallVisibility: // value for 'defaultRecruiterCallVisibility'
 *      defaultPlanRole: // value for 'defaultPlanRole'
 *   },
 * });
 */
export function useUpdateRolePoliciesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRolePoliciesMutation,
    UpdateRolePoliciesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRolePoliciesMutation,
    UpdateRolePoliciesMutationVariables
  >(UpdateRolePoliciesDocument, options);
}
export type UpdateRolePoliciesMutationHookResult = ReturnType<
  typeof useUpdateRolePoliciesMutation
>;
export type UpdateRolePoliciesMutationResult =
  Apollo.MutationResult<UpdateRolePoliciesMutation>;
export type UpdateRolePoliciesMutationOptions = Apollo.BaseMutationOptions<
  UpdateRolePoliciesMutation,
  UpdateRolePoliciesMutationVariables
>;
export const UpdateScheduledInterviewDocument = gql`
  mutation UpdateScheduledInterview(
    $id: ID!
    $name: String
    $interviewerIds: [ID!]!
    $scheduledStartTime: DateTime!
    $timezoneName: String!
    $durationMinutes: Int!
    $zoomJoinUrl: String
    $googleMeetMeetingUrl: String
    $teamsMeetingUrl: String
    $phoneNumber: String
    $positionId: ID
    $callGuideId: ID
  ) {
    updateScheduledInterview(
      id: $id
      name: $name
      interviewerIds: $interviewerIds
      scheduledStartTime: $scheduledStartTime
      timezoneName: $timezoneName
      durationMinutes: $durationMinutes
      zoomJoinUrl: $zoomJoinUrl
      googleMeetMeetingUrl: $googleMeetMeetingUrl
      teamsMeetingUrl: $teamsMeetingUrl
      phoneNumber: $phoneNumber
      positionId: $positionId
      callGuideId: $callGuideId
    ) {
      scheduledInterview {
        ...ScheduledInterviewListItem
      }
    }
  }
  ${ScheduledInterviewListItemFragmentDoc}
`;

/**
 * __useUpdateScheduledInterviewMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledInterviewMutation, { data, loading, error }] = useUpdateScheduledInterviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      interviewerIds: // value for 'interviewerIds'
 *      scheduledStartTime: // value for 'scheduledStartTime'
 *      timezoneName: // value for 'timezoneName'
 *      durationMinutes: // value for 'durationMinutes'
 *      zoomJoinUrl: // value for 'zoomJoinUrl'
 *      googleMeetMeetingUrl: // value for 'googleMeetMeetingUrl'
 *      teamsMeetingUrl: // value for 'teamsMeetingUrl'
 *      phoneNumber: // value for 'phoneNumber'
 *      positionId: // value for 'positionId'
 *      callGuideId: // value for 'callGuideId'
 *   },
 * });
 */
export function useUpdateScheduledInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduledInterviewMutation,
    UpdateScheduledInterviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScheduledInterviewMutation,
    UpdateScheduledInterviewMutationVariables
  >(UpdateScheduledInterviewDocument, options);
}
export type UpdateScheduledInterviewMutationHookResult = ReturnType<
  typeof useUpdateScheduledInterviewMutation
>;
export type UpdateScheduledInterviewMutationResult =
  Apollo.MutationResult<UpdateScheduledInterviewMutation>;
export type UpdateScheduledInterviewMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateScheduledInterviewMutation,
    UpdateScheduledInterviewMutationVariables
  >;
export const UpdateScorecardCompetencyDocument = gql`
  mutation UpdateScorecardCompetency(
    $scorecardCompetencyId: ID!
    $score: Int!
    $notes: String
  ) {
    updateScorecardCompetency(
      scorecardCompetencyId: $scorecardCompetencyId
      score: $score
      notes: $notes
    ) {
      scorecardItem {
        id
        score
        notes
        updatedAt
      }
    }
  }
`;

/**
 * __useUpdateScorecardCompetencyMutation__
 *
 * To run a mutation, you first call `useUpdateScorecardCompetencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScorecardCompetencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScorecardCompetencyMutation, { data, loading, error }] = useUpdateScorecardCompetencyMutation({
 *   variables: {
 *      scorecardCompetencyId: // value for 'scorecardCompetencyId'
 *      score: // value for 'score'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateScorecardCompetencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScorecardCompetencyMutation,
    UpdateScorecardCompetencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScorecardCompetencyMutation,
    UpdateScorecardCompetencyMutationVariables
  >(UpdateScorecardCompetencyDocument, options);
}
export type UpdateScorecardCompetencyMutationHookResult = ReturnType<
  typeof useUpdateScorecardCompetencyMutation
>;
export type UpdateScorecardCompetencyMutationResult =
  Apollo.MutationResult<UpdateScorecardCompetencyMutation>;
export type UpdateScorecardCompetencyMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateScorecardCompetencyMutation,
    UpdateScorecardCompetencyMutationVariables
  >;
export const UpdateScorecardDocument = gql`
  mutation UpdateScorecard(
    $scorecardId: ID!
    $overallScore: Int!
    $overallNotes: String
  ) {
    updateScorecard(
      scorecardId: $scorecardId
      overallScore: $overallScore
      overallNotes: $overallNotes
    ) {
      scorecard {
        id
        overallScore
        updatedAt
        overallNotes
      }
    }
  }
`;

/**
 * __useUpdateScorecardMutation__
 *
 * To run a mutation, you first call `useUpdateScorecardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScorecardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScorecardMutation, { data, loading, error }] = useUpdateScorecardMutation({
 *   variables: {
 *      scorecardId: // value for 'scorecardId'
 *      overallScore: // value for 'overallScore'
 *      overallNotes: // value for 'overallNotes'
 *   },
 * });
 */
export function useUpdateScorecardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScorecardMutation,
    UpdateScorecardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScorecardMutation,
    UpdateScorecardMutationVariables
  >(UpdateScorecardDocument, options);
}
export type UpdateScorecardMutationHookResult = ReturnType<
  typeof useUpdateScorecardMutation
>;
export type UpdateScorecardMutationResult =
  Apollo.MutationResult<UpdateScorecardMutation>;
export type UpdateScorecardMutationOptions = Apollo.BaseMutationOptions<
  UpdateScorecardMutation,
  UpdateScorecardMutationVariables
>;
export const UpdateSummaryNoteFeedbackDocument = gql`
  mutation UpdateSummaryNoteFeedback(
    $noteId: ID!
    $feedback: CandidateSummaryNoteAccuracyFeedback!
  ) {
    updateSummaryNoteFeedback(noteId: $noteId, feedback: $feedback) {
      feedbackRecord {
        id
        accuracyFeedback
      }
    }
  }
`;

/**
 * __useUpdateSummaryNoteFeedbackMutation__
 *
 * To run a mutation, you first call `useUpdateSummaryNoteFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSummaryNoteFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSummaryNoteFeedbackMutation, { data, loading, error }] = useUpdateSummaryNoteFeedbackMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useUpdateSummaryNoteFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSummaryNoteFeedbackMutation,
    UpdateSummaryNoteFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSummaryNoteFeedbackMutation,
    UpdateSummaryNoteFeedbackMutationVariables
  >(UpdateSummaryNoteFeedbackDocument, options);
}
export type UpdateSummaryNoteFeedbackMutationHookResult = ReturnType<
  typeof useUpdateSummaryNoteFeedbackMutation
>;
export type UpdateSummaryNoteFeedbackMutationResult =
  Apollo.MutationResult<UpdateSummaryNoteFeedbackMutation>;
export type UpdateSummaryNoteFeedbackMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSummaryNoteFeedbackMutation,
    UpdateSummaryNoteFeedbackMutationVariables
  >;
export const UpdateTextMessageTemplateDocument = gql`
  mutation UpdateTextMessageTemplate(
    $templateType: TextMessageTemplateType!
    $content: String!
  ) {
    updateTextMessageTemplate(templateType: $templateType, content: $content) {
      textMessageTemplate {
        id
        templateType
        content
      }
    }
  }
`;

/**
 * __useUpdateTextMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTextMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTextMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTextMessageTemplateMutation, { data, loading, error }] = useUpdateTextMessageTemplateMutation({
 *   variables: {
 *      templateType: // value for 'templateType'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateTextMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTextMessageTemplateMutation,
    UpdateTextMessageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTextMessageTemplateMutation,
    UpdateTextMessageTemplateMutationVariables
  >(UpdateTextMessageTemplateDocument, options);
}
export type UpdateTextMessageTemplateMutationHookResult = ReturnType<
  typeof useUpdateTextMessageTemplateMutation
>;
export type UpdateTextMessageTemplateMutationResult =
  Apollo.MutationResult<UpdateTextMessageTemplateMutation>;
export type UpdateTextMessageTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTextMessageTemplateMutation,
    UpdateTextMessageTemplateMutationVariables
  >;
export const UpdateUserRoleDocument = gql`
  mutation UpdateUserRole($id: ID!, $userRoleId: ID!) {
    updateUserRole(id: $id, userRoleId: $userRoleId) {
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userRoleId: // value for 'userRoleId'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserRoleMutation,
    UpdateUserRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserRoleMutation,
    UpdateUserRoleMutationVariables
  >(UpdateUserRoleDocument, options);
}
export type UpdateUserRoleMutationHookResult = ReturnType<
  typeof useUpdateUserRoleMutation
>;
export type UpdateUserRoleMutationResult =
  Apollo.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables
>;
export const UpdateWebhookDocument = gql`
  mutation UpdateWebhook(
    $id: ID
    $url: String!
    $enableInterviewCompleted: Boolean!
    $enableCandidateUpdated: Boolean!
  ) {
    updateWebhook(
      id: $id
      webhookUrl: $url
      eventInterviewCompleted: $enableInterviewCompleted
      eventCandidateUpdated: $enableCandidateUpdated
    ) {
      setup {
        apiTokens {
          id
          name
          tokenPrefix
        }
        webhooks {
          id
          webhookUrl
          webhookSecret
          eventInterviewCompleted
          eventCandidateUpdatedRecordingDisabled
        }
      }
    }
  }
`;

/**
 * __useUpdateWebhookMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookMutation, { data, loading, error }] = useUpdateWebhookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      url: // value for 'url'
 *      enableInterviewCompleted: // value for 'enableInterviewCompleted'
 *      enableCandidateUpdated: // value for 'enableCandidateUpdated'
 *   },
 * });
 */
export function useUpdateWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWebhookMutation,
    UpdateWebhookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWebhookMutation,
    UpdateWebhookMutationVariables
  >(UpdateWebhookDocument, options);
}
export type UpdateWebhookMutationHookResult = ReturnType<
  typeof useUpdateWebhookMutation
>;
export type UpdateWebhookMutationResult =
  Apollo.MutationResult<UpdateWebhookMutation>;
export type UpdateWebhookMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebhookMutation,
  UpdateWebhookMutationVariables
>;
export const UpdateZoomNotetakerMeetingIdDocument = gql`
  mutation UpdateZoomNotetakerMeetingId(
    $meetingUuid: String!
    $meetingId: Float!
  ) {
    updateZoomNotetakerMeetingId(
      meetingUuid: $meetingUuid
      meetingId: $meetingId
    ) {
      success
    }
  }
`;

/**
 * __useUpdateZoomNotetakerMeetingIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomNotetakerMeetingIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomNotetakerMeetingIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomNotetakerMeetingIdMutation, { data, loading, error }] = useUpdateZoomNotetakerMeetingIdMutation({
 *   variables: {
 *      meetingUuid: // value for 'meetingUuid'
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useUpdateZoomNotetakerMeetingIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomNotetakerMeetingIdMutation,
    UpdateZoomNotetakerMeetingIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateZoomNotetakerMeetingIdMutation,
    UpdateZoomNotetakerMeetingIdMutationVariables
  >(UpdateZoomNotetakerMeetingIdDocument, options);
}
export type UpdateZoomNotetakerMeetingIdMutationHookResult = ReturnType<
  typeof useUpdateZoomNotetakerMeetingIdMutation
>;
export type UpdateZoomNotetakerMeetingIdMutationResult =
  Apollo.MutationResult<UpdateZoomNotetakerMeetingIdMutation>;
export type UpdateZoomNotetakerMeetingIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateZoomNotetakerMeetingIdMutation,
    UpdateZoomNotetakerMeetingIdMutationVariables
  >;
export const VerifyEmailSignInDocument = gql`
  mutation VerifyEmailSignIn($email: String!, $verificationCode: String!) {
    verifyEmailSignIn(email: $email, verificationCode: $verificationCode) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useVerifyEmailSignInMutation__
 *
 * To run a mutation, you first call `useVerifyEmailSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailSignInMutation, { data, loading, error }] = useVerifyEmailSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useVerifyEmailSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailSignInMutation,
    VerifyEmailSignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyEmailSignInMutation,
    VerifyEmailSignInMutationVariables
  >(VerifyEmailSignInDocument, options);
}
export type VerifyEmailSignInMutationHookResult = ReturnType<
  typeof useVerifyEmailSignInMutation
>;
export type VerifyEmailSignInMutationResult =
  Apollo.MutationResult<VerifyEmailSignInMutation>;
export type VerifyEmailSignInMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailSignInMutation,
  VerifyEmailSignInMutationVariables
>;
export const VerifyExternalUserAccessCodeDocument = gql`
  mutation VerifyExternalUserAccessCode(
    $externalShareId: ID!
    $verificationCode: String!
  ) {
    verifyExternalUserAccessCode(
      externalShareId: $externalShareId
      verificationCode: $verificationCode
    ) {
      currentUser {
        id
      }
    }
  }
`;

/**
 * __useVerifyExternalUserAccessCodeMutation__
 *
 * To run a mutation, you first call `useVerifyExternalUserAccessCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyExternalUserAccessCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyExternalUserAccessCodeMutation, { data, loading, error }] = useVerifyExternalUserAccessCodeMutation({
 *   variables: {
 *      externalShareId: // value for 'externalShareId'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useVerifyExternalUserAccessCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyExternalUserAccessCodeMutation,
    VerifyExternalUserAccessCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyExternalUserAccessCodeMutation,
    VerifyExternalUserAccessCodeMutationVariables
  >(VerifyExternalUserAccessCodeDocument, options);
}
export type VerifyExternalUserAccessCodeMutationHookResult = ReturnType<
  typeof useVerifyExternalUserAccessCodeMutation
>;
export type VerifyExternalUserAccessCodeMutationResult =
  Apollo.MutationResult<VerifyExternalUserAccessCodeMutation>;
export type VerifyExternalUserAccessCodeMutationOptions =
  Apollo.BaseMutationOptions<
    VerifyExternalUserAccessCodeMutation,
    VerifyExternalUserAccessCodeMutationVariables
  >;
export const VerifyPhoneNumberDocument = gql`
  mutation VerifyPhoneNumber(
    $phoneNumber: String!
    $phoneNumberExtension: String!
  ) {
    verifyPhoneNumber(
      phoneNumber: $phoneNumber
      phoneNumberExtension: $phoneNumberExtension
    ) {
      validationCode
      verified
    }
  }
`;

/**
 * __useVerifyPhoneNumberMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneNumberMutation, { data, loading, error }] = useVerifyPhoneNumberMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      phoneNumberExtension: // value for 'phoneNumberExtension'
 *   },
 * });
 */
export function useVerifyPhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyPhoneNumberMutation,
    VerifyPhoneNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyPhoneNumberMutation,
    VerifyPhoneNumberMutationVariables
  >(VerifyPhoneNumberDocument, options);
}
export type VerifyPhoneNumberMutationHookResult = ReturnType<
  typeof useVerifyPhoneNumberMutation
>;
export type VerifyPhoneNumberMutationResult =
  Apollo.MutationResult<VerifyPhoneNumberMutation>;
export type VerifyPhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  VerifyPhoneNumberMutation,
  VerifyPhoneNumberMutationVariables
>;
export const VerifyPhoneSignInDocument = gql`
  mutation VerifyPhoneSignIn(
    $phoneNumber: String!
    $phoneNumberExtension: String
    $verificationCode: String!
  ) {
    verifyPhoneSignIn(
      phoneNumber: $phoneNumber
      phoneNumberExtension: $phoneNumberExtension
      verificationCode: $verificationCode
    ) {
      currentUser {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useVerifyPhoneSignInMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneSignInMutation, { data, loading, error }] = useVerifyPhoneSignInMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      phoneNumberExtension: // value for 'phoneNumberExtension'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useVerifyPhoneSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyPhoneSignInMutation,
    VerifyPhoneSignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyPhoneSignInMutation,
    VerifyPhoneSignInMutationVariables
  >(VerifyPhoneSignInDocument, options);
}
export type VerifyPhoneSignInMutationHookResult = ReturnType<
  typeof useVerifyPhoneSignInMutation
>;
export type VerifyPhoneSignInMutationResult =
  Apollo.MutationResult<VerifyPhoneSignInMutation>;
export type VerifyPhoneSignInMutationOptions = Apollo.BaseMutationOptions<
  VerifyPhoneSignInMutation,
  VerifyPhoneSignInMutationVariables
>;
export const ViewCallDocument = gql`
  mutation ViewCall($callId: ID!) {
    viewCall(callId: $callId) {
      callViewed
    }
  }
`;

/**
 * __useViewCallMutation__
 *
 * To run a mutation, you first call `useViewCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewCallMutation, { data, loading, error }] = useViewCallMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useViewCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewCallMutation,
    ViewCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ViewCallMutation, ViewCallMutationVariables>(
    ViewCallDocument,
    options
  );
}
export type ViewCallMutationHookResult = ReturnType<typeof useViewCallMutation>;
export type ViewCallMutationResult = Apollo.MutationResult<ViewCallMutation>;
export type ViewCallMutationOptions = Apollo.BaseMutationOptions<
  ViewCallMutation,
  ViewCallMutationVariables
>;
export const ViewCandidateDocument = gql`
  mutation ViewCandidate($candidateId: ID!) {
    viewCandidate(candidateId: $candidateId) {
      candidateViewed
    }
  }
`;

/**
 * __useViewCandidateMutation__
 *
 * To run a mutation, you first call `useViewCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewCandidateMutation, { data, loading, error }] = useViewCandidateMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useViewCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewCandidateMutation,
    ViewCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ViewCandidateMutation,
    ViewCandidateMutationVariables
  >(ViewCandidateDocument, options);
}
export type ViewCandidateMutationHookResult = ReturnType<
  typeof useViewCandidateMutation
>;
export type ViewCandidateMutationResult =
  Apollo.MutationResult<ViewCandidateMutation>;
export type ViewCandidateMutationOptions = Apollo.BaseMutationOptions<
  ViewCandidateMutation,
  ViewCandidateMutationVariables
>;
export const ViewClipDocument = gql`
  mutation ViewClip($clipId: ID!, $playlistId: ID) {
    viewClip(clipId: $clipId, playlistId: $playlistId) {
      clipViewed
    }
  }
`;

/**
 * __useViewClipMutation__
 *
 * To run a mutation, you first call `useViewClipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewClipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewClipMutation, { data, loading, error }] = useViewClipMutation({
 *   variables: {
 *      clipId: // value for 'clipId'
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function useViewClipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewClipMutation,
    ViewClipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ViewClipMutation, ViewClipMutationVariables>(
    ViewClipDocument,
    options
  );
}
export type ViewClipMutationHookResult = ReturnType<typeof useViewClipMutation>;
export type ViewClipMutationResult = Apollo.MutationResult<ViewClipMutation>;
export type ViewClipMutationOptions = Apollo.BaseMutationOptions<
  ViewClipMutation,
  ViewClipMutationVariables
>;
export const ViewRedactionPredictionDocument = gql`
  mutation ViewRedactionPrediction($callId: ID!, $predictionId: ID!) {
    viewRedactionPrediction(callId: $callId, predictionId: $predictionId) {
      redactionPrediction {
        id
        viewed
      }
    }
  }
`;

/**
 * __useViewRedactionPredictionMutation__
 *
 * To run a mutation, you first call `useViewRedactionPredictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewRedactionPredictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewRedactionPredictionMutation, { data, loading, error }] = useViewRedactionPredictionMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      predictionId: // value for 'predictionId'
 *   },
 * });
 */
export function useViewRedactionPredictionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewRedactionPredictionMutation,
    ViewRedactionPredictionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ViewRedactionPredictionMutation,
    ViewRedactionPredictionMutationVariables
  >(ViewRedactionPredictionDocument, options);
}
export type ViewRedactionPredictionMutationHookResult = ReturnType<
  typeof useViewRedactionPredictionMutation
>;
export type ViewRedactionPredictionMutationResult =
  Apollo.MutationResult<ViewRedactionPredictionMutation>;
export type ViewRedactionPredictionMutationOptions = Apollo.BaseMutationOptions<
  ViewRedactionPredictionMutation,
  ViewRedactionPredictionMutationVariables
>;
export const ViewCallScorecardDocument = gql`
  mutation ViewCallScorecard($callId: ID!, $scorecardId: ID!) {
    viewCallScorecard(callId: $callId, scorecardId: $scorecardId) {
      scorecardViewed
    }
  }
`;

/**
 * __useViewCallScorecardMutation__
 *
 * To run a mutation, you first call `useViewCallScorecardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewCallScorecardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewCallScorecardMutation, { data, loading, error }] = useViewCallScorecardMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      scorecardId: // value for 'scorecardId'
 *   },
 * });
 */
export function useViewCallScorecardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewCallScorecardMutation,
    ViewCallScorecardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ViewCallScorecardMutation,
    ViewCallScorecardMutationVariables
  >(ViewCallScorecardDocument, options);
}
export type ViewCallScorecardMutationHookResult = ReturnType<
  typeof useViewCallScorecardMutation
>;
export type ViewCallScorecardMutationResult =
  Apollo.MutationResult<ViewCallScorecardMutation>;
export type ViewCallScorecardMutationOptions = Apollo.BaseMutationOptions<
  ViewCallScorecardMutation,
  ViewCallScorecardMutationVariables
>;
export const CreateAiSummaryTemplateDocument = gql`
  mutation CreateAiSummaryTemplate(
    $name: String!
    $isCreatorDefault: Boolean
    $sections: [AiSummaryTemplateSectionInput!]!
    $customType: CustomTemplateCustomType
    $customPrompt: String
    $model: String
    $visibility: CallAiSummaryTemplateVisibility
    $isCurrentUserDefault: Boolean
    $defaultFor: CallAiSummaryTemplateDefaultFor
    $defaultForPersona: UserPersona
    $defaultForKeywords: [String!]
    $defaultForMeetingType: CustomTemplateType
  ) {
    createAiSummaryTemplate(
      name: $name
      isCreatorDefault: $isCreatorDefault
      sections: $sections
      customType: $customType
      customPrompt: $customPrompt
      model: $model
      visibility: $visibility
      isCurrentUserDefault: $isCurrentUserDefault
      defaultFor: $defaultFor
      defaultForPersona: $defaultForPersona
      defaultForKeywords: $defaultForKeywords
      defaultForMeetingType: $defaultForMeetingType
    ) {
      template {
        id
      }
      validationError
    }
  }
`;

/**
 * __useCreateAiSummaryTemplateMutation__
 *
 * To run a mutation, you first call `useCreateAiSummaryTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiSummaryTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiSummaryTemplateMutation, { data, loading, error }] = useCreateAiSummaryTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isCreatorDefault: // value for 'isCreatorDefault'
 *      sections: // value for 'sections'
 *      customType: // value for 'customType'
 *      customPrompt: // value for 'customPrompt'
 *      model: // value for 'model'
 *      visibility: // value for 'visibility'
 *      isCurrentUserDefault: // value for 'isCurrentUserDefault'
 *      defaultFor: // value for 'defaultFor'
 *      defaultForPersona: // value for 'defaultForPersona'
 *      defaultForKeywords: // value for 'defaultForKeywords'
 *      defaultForMeetingType: // value for 'defaultForMeetingType'
 *   },
 * });
 */
export function useCreateAiSummaryTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAiSummaryTemplateMutation,
    CreateAiSummaryTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAiSummaryTemplateMutation,
    CreateAiSummaryTemplateMutationVariables
  >(CreateAiSummaryTemplateDocument, options);
}
export type CreateAiSummaryTemplateMutationHookResult = ReturnType<
  typeof useCreateAiSummaryTemplateMutation
>;
export type CreateAiSummaryTemplateMutationResult =
  Apollo.MutationResult<CreateAiSummaryTemplateMutation>;
export type CreateAiSummaryTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateAiSummaryTemplateMutation,
  CreateAiSummaryTemplateMutationVariables
>;
export const DeleteAiSummaryTemplateDocument = gql`
  mutation DeleteAiSummaryTemplate($id: ID!) {
    deleteAiSummaryTemplate(id: $id) {
      template {
        id
      }
    }
  }
`;

/**
 * __useDeleteAiSummaryTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteAiSummaryTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAiSummaryTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAiSummaryTemplateMutation, { data, loading, error }] = useDeleteAiSummaryTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAiSummaryTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAiSummaryTemplateMutation,
    DeleteAiSummaryTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAiSummaryTemplateMutation,
    DeleteAiSummaryTemplateMutationVariables
  >(DeleteAiSummaryTemplateDocument, options);
}
export type DeleteAiSummaryTemplateMutationHookResult = ReturnType<
  typeof useDeleteAiSummaryTemplateMutation
>;
export type DeleteAiSummaryTemplateMutationResult =
  Apollo.MutationResult<DeleteAiSummaryTemplateMutation>;
export type DeleteAiSummaryTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteAiSummaryTemplateMutation,
  DeleteAiSummaryTemplateMutationVariables
>;
export const DuplicateAiSummaryTemplateDocument = gql`
  mutation DuplicateAiSummaryTemplate($id: ID!) {
    duplicateAiSummaryTemplate(id: $id) {
      template {
        ...CallAiSummaryTemplateListItem
      }
    }
  }
  ${CallAiSummaryTemplateListItemFragmentDoc}
`;

/**
 * __useDuplicateAiSummaryTemplateMutation__
 *
 * To run a mutation, you first call `useDuplicateAiSummaryTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateAiSummaryTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateAiSummaryTemplateMutation, { data, loading, error }] = useDuplicateAiSummaryTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateAiSummaryTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateAiSummaryTemplateMutation,
    DuplicateAiSummaryTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateAiSummaryTemplateMutation,
    DuplicateAiSummaryTemplateMutationVariables
  >(DuplicateAiSummaryTemplateDocument, options);
}
export type DuplicateAiSummaryTemplateMutationHookResult = ReturnType<
  typeof useDuplicateAiSummaryTemplateMutation
>;
export type DuplicateAiSummaryTemplateMutationResult =
  Apollo.MutationResult<DuplicateAiSummaryTemplateMutation>;
export type DuplicateAiSummaryTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    DuplicateAiSummaryTemplateMutation,
    DuplicateAiSummaryTemplateMutationVariables
  >;
export const UpdateAiSummaryTemplateDocument = gql`
  mutation UpdateAiSummaryTemplate(
    $id: ID!
    $name: String!
    $isCreatorDefault: Boolean
    $sections: [AiSummaryTemplateSectionInput!]!
    $customType: CustomTemplateCustomType
    $customPrompt: String
    $model: String
    $visibility: CallAiSummaryTemplateVisibility
    $isCurrentUserDefault: Boolean
    $defaultFor: CallAiSummaryTemplateDefaultFor
    $defaultForPersona: UserPersona
    $defaultForKeywords: [String!]
    $defaultForMeetingType: CustomTemplateType
  ) {
    updateAiSummaryTemplate(
      id: $id
      name: $name
      isCreatorDefault: $isCreatorDefault
      sections: $sections
      customType: $customType
      customPrompt: $customPrompt
      model: $model
      visibility: $visibility
      isCurrentUserDefault: $isCurrentUserDefault
      defaultFor: $defaultFor
      defaultForPersona: $defaultForPersona
      defaultForKeywords: $defaultForKeywords
      defaultForMeetingType: $defaultForMeetingType
    ) {
      template {
        id
      }
      validationError
    }
  }
`;

/**
 * __useUpdateAiSummaryTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateAiSummaryTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAiSummaryTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAiSummaryTemplateMutation, { data, loading, error }] = useUpdateAiSummaryTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      isCreatorDefault: // value for 'isCreatorDefault'
 *      sections: // value for 'sections'
 *      customType: // value for 'customType'
 *      customPrompt: // value for 'customPrompt'
 *      model: // value for 'model'
 *      visibility: // value for 'visibility'
 *      isCurrentUserDefault: // value for 'isCurrentUserDefault'
 *      defaultFor: // value for 'defaultFor'
 *      defaultForPersona: // value for 'defaultForPersona'
 *      defaultForKeywords: // value for 'defaultForKeywords'
 *      defaultForMeetingType: // value for 'defaultForMeetingType'
 *   },
 * });
 */
export function useUpdateAiSummaryTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAiSummaryTemplateMutation,
    UpdateAiSummaryTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAiSummaryTemplateMutation,
    UpdateAiSummaryTemplateMutationVariables
  >(UpdateAiSummaryTemplateDocument, options);
}
export type UpdateAiSummaryTemplateMutationHookResult = ReturnType<
  typeof useUpdateAiSummaryTemplateMutation
>;
export type UpdateAiSummaryTemplateMutationResult =
  Apollo.MutationResult<UpdateAiSummaryTemplateMutation>;
export type UpdateAiSummaryTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateAiSummaryTemplateMutation,
  UpdateAiSummaryTemplateMutationVariables
>;
export const AddInPersonCallDocument = gql`
  mutation AddInPersonCall(
    $interviewerIds: [ID!]
    $scheduledInterviewId: ID
    $name: String
    $candidateId: ID
    $candidateFirstName: String
    $candidateLastName: String
    $candidateEmail: String
    $positionId: ID
    $callGuideId: ID
    $recordingContentType: String!
    $iaVersion: Float
  ) {
    addInPersonCall(
      interviewerIds: $interviewerIds
      scheduledInterviewId: $scheduledInterviewId
      name: $name
      candidateId: $candidateId
      candidateFirstName: $candidateFirstName
      candidateLastName: $candidateLastName
      candidateEmail: $candidateEmail
      positionId: $positionId
      callGuideId: $callGuideId
      recordingContentType: $recordingContentType
      iaVersion: $iaVersion
    ) {
      recordingUploadUrl
      call {
        id
      }
    }
  }
`;

/**
 * __useAddInPersonCallMutation__
 *
 * To run a mutation, you first call `useAddInPersonCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInPersonCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInPersonCallMutation, { data, loading, error }] = useAddInPersonCallMutation({
 *   variables: {
 *      interviewerIds: // value for 'interviewerIds'
 *      scheduledInterviewId: // value for 'scheduledInterviewId'
 *      name: // value for 'name'
 *      candidateId: // value for 'candidateId'
 *      candidateFirstName: // value for 'candidateFirstName'
 *      candidateLastName: // value for 'candidateLastName'
 *      candidateEmail: // value for 'candidateEmail'
 *      positionId: // value for 'positionId'
 *      callGuideId: // value for 'callGuideId'
 *      recordingContentType: // value for 'recordingContentType'
 *      iaVersion: // value for 'iaVersion'
 *   },
 * });
 */
export function useAddInPersonCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddInPersonCallMutation,
    AddInPersonCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddInPersonCallMutation,
    AddInPersonCallMutationVariables
  >(AddInPersonCallDocument, options);
}
export type AddInPersonCallMutationHookResult = ReturnType<
  typeof useAddInPersonCallMutation
>;
export type AddInPersonCallMutationResult =
  Apollo.MutationResult<AddInPersonCallMutation>;
export type AddInPersonCallMutationOptions = Apollo.BaseMutationOptions<
  AddInPersonCallMutation,
  AddInPersonCallMutationVariables
>;
export const DeleteAiSummaryCustomTopicDocument = gql`
  mutation DeleteAiSummaryCustomTopic($customTopicNoteId: ID!) {
    deleteAiSummaryCustomTopic(customTopicNoteId: $customTopicNoteId) {
      summary {
        id
        status
        format
        processingStartedAt
        targetSpeakerTags
        customTopics {
          title
          questions
        }
        headers {
          id
          text
          notes {
            id
            text
            tag
            startTime
            startTimes
            variation1
            variation2
          }
        }
      }
    }
  }
`;

/**
 * __useDeleteAiSummaryCustomTopicMutation__
 *
 * To run a mutation, you first call `useDeleteAiSummaryCustomTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAiSummaryCustomTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAiSummaryCustomTopicMutation, { data, loading, error }] = useDeleteAiSummaryCustomTopicMutation({
 *   variables: {
 *      customTopicNoteId: // value for 'customTopicNoteId'
 *   },
 * });
 */
export function useDeleteAiSummaryCustomTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAiSummaryCustomTopicMutation,
    DeleteAiSummaryCustomTopicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAiSummaryCustomTopicMutation,
    DeleteAiSummaryCustomTopicMutationVariables
  >(DeleteAiSummaryCustomTopicDocument, options);
}
export type DeleteAiSummaryCustomTopicMutationHookResult = ReturnType<
  typeof useDeleteAiSummaryCustomTopicMutation
>;
export type DeleteAiSummaryCustomTopicMutationResult =
  Apollo.MutationResult<DeleteAiSummaryCustomTopicMutation>;
export type DeleteAiSummaryCustomTopicMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteAiSummaryCustomTopicMutation,
    DeleteAiSummaryCustomTopicMutationVariables
  >;
export const EndInPersonCallDocument = gql`
  mutation EndInPersonCall($callId: ID!) {
    endInPersonCall(callId: $callId) {
      call {
        id
        status
        endTime
        duration
      }
    }
  }
`;

/**
 * __useEndInPersonCallMutation__
 *
 * To run a mutation, you first call `useEndInPersonCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndInPersonCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endInPersonCallMutation, { data, loading, error }] = useEndInPersonCallMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useEndInPersonCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndInPersonCallMutation,
    EndInPersonCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EndInPersonCallMutation,
    EndInPersonCallMutationVariables
  >(EndInPersonCallDocument, options);
}
export type EndInPersonCallMutationHookResult = ReturnType<
  typeof useEndInPersonCallMutation
>;
export type EndInPersonCallMutationResult =
  Apollo.MutationResult<EndInPersonCallMutation>;
export type EndInPersonCallMutationOptions = Apollo.BaseMutationOptions<
  EndInPersonCallMutation,
  EndInPersonCallMutationVariables
>;
export const ProcessInPersonRecordingDocument = gql`
  mutation ProcessInPersonRecording($callId: ID!) {
    processInPersonRecording(callId: $callId) {
      success
    }
  }
`;

/**
 * __useProcessInPersonRecordingMutation__
 *
 * To run a mutation, you first call `useProcessInPersonRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessInPersonRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processInPersonRecordingMutation, { data, loading, error }] = useProcessInPersonRecordingMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useProcessInPersonRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessInPersonRecordingMutation,
    ProcessInPersonRecordingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProcessInPersonRecordingMutation,
    ProcessInPersonRecordingMutationVariables
  >(ProcessInPersonRecordingDocument, options);
}
export type ProcessInPersonRecordingMutationHookResult = ReturnType<
  typeof useProcessInPersonRecordingMutation
>;
export type ProcessInPersonRecordingMutationResult =
  Apollo.MutationResult<ProcessInPersonRecordingMutation>;
export type ProcessInPersonRecordingMutationOptions =
  Apollo.BaseMutationOptions<
    ProcessInPersonRecordingMutation,
    ProcessInPersonRecordingMutationVariables
  >;
export const SendBeforeCallTextMessageDocument = gql`
  mutation SendBeforeCallTextMessage(
    $body: String!
    $candidatePhone: String!
    $candidateFirstName: String
    $candidateLastName: String
  ) {
    sendBeforeCallTextMessage(
      body: $body
      candidatePhone: $candidatePhone
      candidateFirstName: $candidateFirstName
      candidateLastName: $candidateLastName
    ) {
      textMessage {
        messageSid
        messageStatus
      }
    }
  }
`;

/**
 * __useSendBeforeCallTextMessageMutation__
 *
 * To run a mutation, you first call `useSendBeforeCallTextMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBeforeCallTextMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBeforeCallTextMessageMutation, { data, loading, error }] = useSendBeforeCallTextMessageMutation({
 *   variables: {
 *      body: // value for 'body'
 *      candidatePhone: // value for 'candidatePhone'
 *      candidateFirstName: // value for 'candidateFirstName'
 *      candidateLastName: // value for 'candidateLastName'
 *   },
 * });
 */
export function useSendBeforeCallTextMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendBeforeCallTextMessageMutation,
    SendBeforeCallTextMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendBeforeCallTextMessageMutation,
    SendBeforeCallTextMessageMutationVariables
  >(SendBeforeCallTextMessageDocument, options);
}
export type SendBeforeCallTextMessageMutationHookResult = ReturnType<
  typeof useSendBeforeCallTextMessageMutation
>;
export type SendBeforeCallTextMessageMutationResult =
  Apollo.MutationResult<SendBeforeCallTextMessageMutation>;
export type SendBeforeCallTextMessageMutationOptions =
  Apollo.BaseMutationOptions<
    SendBeforeCallTextMessageMutation,
    SendBeforeCallTextMessageMutationVariables
  >;
export const UpdateCallAiSummaryFormatDocument = gql`
  mutation UpdateCallAISummaryFormat(
    $callId: ID!
    $format: CallAiSummaryFormat
    $customTemplateId: ID
    $targetSpeakerTags: [Int!]!
    $forceRegenerate: Boolean
    $customTopics: [CallAiSummaryCustomTopicInput!]!
  ) {
    updateCallAiSummaryFormat(
      callId: $callId
      format: $format
      customTemplateId: $customTemplateId
      targetSpeakerTags: $targetSpeakerTags
      customTopics: $customTopics
      forceRegenerate: $forceRegenerate
    ) {
      summary {
        id
        status
      }
    }
  }
`;

/**
 * __useUpdateCallAiSummaryFormatMutation__
 *
 * To run a mutation, you first call `useUpdateCallAiSummaryFormatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallAiSummaryFormatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallAiSummaryFormatMutation, { data, loading, error }] = useUpdateCallAiSummaryFormatMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      format: // value for 'format'
 *      customTemplateId: // value for 'customTemplateId'
 *      targetSpeakerTags: // value for 'targetSpeakerTags'
 *      forceRegenerate: // value for 'forceRegenerate'
 *      customTopics: // value for 'customTopics'
 *   },
 * });
 */
export function useUpdateCallAiSummaryFormatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallAiSummaryFormatMutation,
    UpdateCallAiSummaryFormatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallAiSummaryFormatMutation,
    UpdateCallAiSummaryFormatMutationVariables
  >(UpdateCallAiSummaryFormatDocument, options);
}
export type UpdateCallAiSummaryFormatMutationHookResult = ReturnType<
  typeof useUpdateCallAiSummaryFormatMutation
>;
export type UpdateCallAiSummaryFormatMutationResult =
  Apollo.MutationResult<UpdateCallAiSummaryFormatMutation>;
export type UpdateCallAiSummaryFormatMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCallAiSummaryFormatMutation,
    UpdateCallAiSummaryFormatMutationVariables
  >;
export const UpdateCallCandidateDocument = gql`
  mutation UpdateCallCandidate($callId: ID!, $candidateId: ID) {
    updateCallCandidate(callId: $callId, candidateId: $candidateId) {
      call {
        id
        candidate {
          ...CallCandidate
          firstName
          lastName
          defaultEmail
          bullhornId
        }
      }
    }
  }
  ${CallCandidateFragmentDoc}
`;

/**
 * __useUpdateCallCandidateMutation__
 *
 * To run a mutation, you first call `useUpdateCallCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallCandidateMutation, { data, loading, error }] = useUpdateCallCandidateMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useUpdateCallCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallCandidateMutation,
    UpdateCallCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallCandidateMutation,
    UpdateCallCandidateMutationVariables
  >(UpdateCallCandidateDocument, options);
}
export type UpdateCallCandidateMutationHookResult = ReturnType<
  typeof useUpdateCallCandidateMutation
>;
export type UpdateCallCandidateMutationResult =
  Apollo.MutationResult<UpdateCallCandidateMutation>;
export type UpdateCallCandidateMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallCandidateMutation,
  UpdateCallCandidateMutationVariables
>;
export const AutoGenerateCandidateSummaryIfMissingDocument = gql`
  mutation AutoGenerateCandidateSummaryIfMissing(
    $candidateId: ID!
    $positionId: ID
    $templateId: ID!
  ) {
    autoGenerateCandidateSummaryIfMissing(
      candidateId: $candidateId
      positionId: $positionId
      templateId: $templateId
    ) {
      created
    }
  }
`;

/**
 * __useAutoGenerateCandidateSummaryIfMissingMutation__
 *
 * To run a mutation, you first call `useAutoGenerateCandidateSummaryIfMissingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoGenerateCandidateSummaryIfMissingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoGenerateCandidateSummaryIfMissingMutation, { data, loading, error }] = useAutoGenerateCandidateSummaryIfMissingMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      positionId: // value for 'positionId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useAutoGenerateCandidateSummaryIfMissingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AutoGenerateCandidateSummaryIfMissingMutation,
    AutoGenerateCandidateSummaryIfMissingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AutoGenerateCandidateSummaryIfMissingMutation,
    AutoGenerateCandidateSummaryIfMissingMutationVariables
  >(AutoGenerateCandidateSummaryIfMissingDocument, options);
}
export type AutoGenerateCandidateSummaryIfMissingMutationHookResult =
  ReturnType<typeof useAutoGenerateCandidateSummaryIfMissingMutation>;
export type AutoGenerateCandidateSummaryIfMissingMutationResult =
  Apollo.MutationResult<AutoGenerateCandidateSummaryIfMissingMutation>;
export type AutoGenerateCandidateSummaryIfMissingMutationOptions =
  Apollo.BaseMutationOptions<
    AutoGenerateCandidateSummaryIfMissingMutation,
    AutoGenerateCandidateSummaryIfMissingMutationVariables
  >;
export const GenerateCandidateSummaryDocument = gql`
  mutation GenerateCandidateSummary(
    $candidateId: ID!
    $positionId: ID
    $templateId: ID!
  ) {
    generateCandidateSummary(
      candidateId: $candidateId
      positionId: $positionId
      templateId: $templateId
    ) {
      summary {
        ...CandidateSummary
      }
    }
  }
  ${CandidateSummaryFragmentDoc}
`;

/**
 * __useGenerateCandidateSummaryMutation__
 *
 * To run a mutation, you first call `useGenerateCandidateSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCandidateSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCandidateSummaryMutation, { data, loading, error }] = useGenerateCandidateSummaryMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      positionId: // value for 'positionId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGenerateCandidateSummaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateCandidateSummaryMutation,
    GenerateCandidateSummaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateCandidateSummaryMutation,
    GenerateCandidateSummaryMutationVariables
  >(GenerateCandidateSummaryDocument, options);
}
export type GenerateCandidateSummaryMutationHookResult = ReturnType<
  typeof useGenerateCandidateSummaryMutation
>;
export type GenerateCandidateSummaryMutationResult =
  Apollo.MutationResult<GenerateCandidateSummaryMutation>;
export type GenerateCandidateSummaryMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateCandidateSummaryMutation,
    GenerateCandidateSummaryMutationVariables
  >;
export const UpdateCandidateSummaryTemplateDocument = gql`
  mutation UpdateCandidateSummaryTemplate(
    $templateId: ID!
    $sections: [CandidateSummaryTemplateSectionInput!]!
    $isDefault: Boolean!
    $positionId: ID
    $newTemplateName: String
    $asNewTemplate: Boolean
  ) {
    updateCandidateSummaryTemplate(
      templateId: $templateId
      sections: $sections
      positionId: $positionId
      isDefault: $isDefault
      newTemplateName: $newTemplateName
      asNewTemplate: $asNewTemplate
    ) {
      template {
        ...CandidateSummaryTemplate
      }
    }
  }
  ${CandidateSummaryTemplateFragmentDoc}
`;

/**
 * __useUpdateCandidateSummaryTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateSummaryTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateSummaryTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateSummaryTemplateMutation, { data, loading, error }] = useUpdateCandidateSummaryTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      sections: // value for 'sections'
 *      isDefault: // value for 'isDefault'
 *      positionId: // value for 'positionId'
 *      newTemplateName: // value for 'newTemplateName'
 *      asNewTemplate: // value for 'asNewTemplate'
 *   },
 * });
 */
export function useUpdateCandidateSummaryTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCandidateSummaryTemplateMutation,
    UpdateCandidateSummaryTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCandidateSummaryTemplateMutation,
    UpdateCandidateSummaryTemplateMutationVariables
  >(UpdateCandidateSummaryTemplateDocument, options);
}
export type UpdateCandidateSummaryTemplateMutationHookResult = ReturnType<
  typeof useUpdateCandidateSummaryTemplateMutation
>;
export type UpdateCandidateSummaryTemplateMutationResult =
  Apollo.MutationResult<UpdateCandidateSummaryTemplateMutation>;
export type UpdateCandidateSummaryTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCandidateSummaryTemplateMutation,
    UpdateCandidateSummaryTemplateMutationVariables
  >;
export const ViewHighlightDocument = gql`
  mutation ViewHighlight(
    $callId: ID!
    $highlightId: ID!
    $highlightType: String!
  ) {
    viewHighlight(
      callId: $callId
      highlightId: $highlightId
      highlightType: $highlightType
    ) {
      viewed
    }
  }
`;

/**
 * __useViewHighlightMutation__
 *
 * To run a mutation, you first call `useViewHighlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewHighlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewHighlightMutation, { data, loading, error }] = useViewHighlightMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      highlightId: // value for 'highlightId'
 *      highlightType: // value for 'highlightType'
 *   },
 * });
 */
export function useViewHighlightMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewHighlightMutation,
    ViewHighlightMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ViewHighlightMutation,
    ViewHighlightMutationVariables
  >(ViewHighlightDocument, options);
}
export type ViewHighlightMutationHookResult = ReturnType<
  typeof useViewHighlightMutation
>;
export type ViewHighlightMutationResult =
  Apollo.MutationResult<ViewHighlightMutation>;
export type ViewHighlightMutationOptions = Apollo.BaseMutationOptions<
  ViewHighlightMutation,
  ViewHighlightMutationVariables
>;
export const MarkCandidateAlertFeedReadDocument = gql`
  mutation MarkCandidateAlertFeedRead(
    $candidateAlertFeedIds: [ID!]
    $alertFilters: [CandidateAlertFeedFilter!]
  ) {
    markCandidateAlertFeedRead(
      candidateAlertFeedIds: $candidateAlertFeedIds
      alertFilters: $alertFilters
    ) {
      markedIds
      readAt
      currentUser {
        id
        numUnreadAlerts
      }
    }
  }
`;

/**
 * __useMarkCandidateAlertFeedReadMutation__
 *
 * To run a mutation, you first call `useMarkCandidateAlertFeedReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCandidateAlertFeedReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCandidateAlertFeedReadMutation, { data, loading, error }] = useMarkCandidateAlertFeedReadMutation({
 *   variables: {
 *      candidateAlertFeedIds: // value for 'candidateAlertFeedIds'
 *      alertFilters: // value for 'alertFilters'
 *   },
 * });
 */
export function useMarkCandidateAlertFeedReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkCandidateAlertFeedReadMutation,
    MarkCandidateAlertFeedReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkCandidateAlertFeedReadMutation,
    MarkCandidateAlertFeedReadMutationVariables
  >(MarkCandidateAlertFeedReadDocument, options);
}
export type MarkCandidateAlertFeedReadMutationHookResult = ReturnType<
  typeof useMarkCandidateAlertFeedReadMutation
>;
export type MarkCandidateAlertFeedReadMutationResult =
  Apollo.MutationResult<MarkCandidateAlertFeedReadMutation>;
export type MarkCandidateAlertFeedReadMutationOptions =
  Apollo.BaseMutationOptions<
    MarkCandidateAlertFeedReadMutation,
    MarkCandidateAlertFeedReadMutationVariables
  >;
export const UpdateCandidateAlertAccuracyFeedbackDocument = gql`
  mutation UpdateCandidateAlertAccuracyFeedback(
    $candidateAlertFeedIds: [ID!]!
    $feedback: CandidateAlertAccuracyFeedback!
  ) {
    updateCandidateAlertAccuracyFeedback(
      candidateAlertFeedIds: $candidateAlertFeedIds
      feedback: $feedback
    ) {
      updatedIds
      feedback
    }
  }
`;

/**
 * __useUpdateCandidateAlertAccuracyFeedbackMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateAlertAccuracyFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateAlertAccuracyFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateAlertAccuracyFeedbackMutation, { data, loading, error }] = useUpdateCandidateAlertAccuracyFeedbackMutation({
 *   variables: {
 *      candidateAlertFeedIds: // value for 'candidateAlertFeedIds'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useUpdateCandidateAlertAccuracyFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCandidateAlertAccuracyFeedbackMutation,
    UpdateCandidateAlertAccuracyFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCandidateAlertAccuracyFeedbackMutation,
    UpdateCandidateAlertAccuracyFeedbackMutationVariables
  >(UpdateCandidateAlertAccuracyFeedbackDocument, options);
}
export type UpdateCandidateAlertAccuracyFeedbackMutationHookResult = ReturnType<
  typeof useUpdateCandidateAlertAccuracyFeedbackMutation
>;
export type UpdateCandidateAlertAccuracyFeedbackMutationResult =
  Apollo.MutationResult<UpdateCandidateAlertAccuracyFeedbackMutation>;
export type UpdateCandidateAlertAccuracyFeedbackMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCandidateAlertAccuracyFeedbackMutation,
    UpdateCandidateAlertAccuracyFeedbackMutationVariables
  >;
export const UpdateCurrentUserOnboardingStepDocument = gql`
  mutation UpdateCurrentUserOnboardingStep($steps: [UpdateOnboardingSteps!]!) {
    updateCurrentUserOnboardingStep(steps: $steps) {
      currentUser {
        id
        signUpCompletedAt
        onboardingSteps {
          step
          rule
          status
        }
      }
    }
  }
`;

/**
 * __useUpdateCurrentUserOnboardingStepMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserOnboardingStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserOnboardingStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserOnboardingStepMutation, { data, loading, error }] = useUpdateCurrentUserOnboardingStepMutation({
 *   variables: {
 *      steps: // value for 'steps'
 *   },
 * });
 */
export function useUpdateCurrentUserOnboardingStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserOnboardingStepMutation,
    UpdateCurrentUserOnboardingStepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentUserOnboardingStepMutation,
    UpdateCurrentUserOnboardingStepMutationVariables
  >(UpdateCurrentUserOnboardingStepDocument, options);
}
export type UpdateCurrentUserOnboardingStepMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserOnboardingStepMutation
>;
export type UpdateCurrentUserOnboardingStepMutationResult =
  Apollo.MutationResult<UpdateCurrentUserOnboardingStepMutation>;
export type UpdateCurrentUserOnboardingStepMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentUserOnboardingStepMutation,
    UpdateCurrentUserOnboardingStepMutationVariables
  >;
export const CreateEventImportKeywordDocument = gql`
  mutation CreateEventImportKeyword($keywords: [String!]!) {
    createEventImportKeyword(keywords: $keywords) {
      eventImportKeywords {
        ...EventImportKeyword
      }
    }
  }
  ${EventImportKeywordFragmentDoc}
`;

/**
 * __useCreateEventImportKeywordMutation__
 *
 * To run a mutation, you first call `useCreateEventImportKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventImportKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventImportKeywordMutation, { data, loading, error }] = useCreateEventImportKeywordMutation({
 *   variables: {
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useCreateEventImportKeywordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventImportKeywordMutation,
    CreateEventImportKeywordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEventImportKeywordMutation,
    CreateEventImportKeywordMutationVariables
  >(CreateEventImportKeywordDocument, options);
}
export type CreateEventImportKeywordMutationHookResult = ReturnType<
  typeof useCreateEventImportKeywordMutation
>;
export type CreateEventImportKeywordMutationResult =
  Apollo.MutationResult<CreateEventImportKeywordMutation>;
export type CreateEventImportKeywordMutationOptions =
  Apollo.BaseMutationOptions<
    CreateEventImportKeywordMutation,
    CreateEventImportKeywordMutationVariables
  >;
export const DeleteEventImportKeywordDocument = gql`
  mutation DeleteEventImportKeyword($keywords: [String!]!) {
    deleteEventImportKeyword(keywords: $keywords) {
      success
    }
  }
`;

/**
 * __useDeleteEventImportKeywordMutation__
 *
 * To run a mutation, you first call `useDeleteEventImportKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventImportKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventImportKeywordMutation, { data, loading, error }] = useDeleteEventImportKeywordMutation({
 *   variables: {
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useDeleteEventImportKeywordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventImportKeywordMutation,
    DeleteEventImportKeywordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEventImportKeywordMutation,
    DeleteEventImportKeywordMutationVariables
  >(DeleteEventImportKeywordDocument, options);
}
export type DeleteEventImportKeywordMutationHookResult = ReturnType<
  typeof useDeleteEventImportKeywordMutation
>;
export type DeleteEventImportKeywordMutationResult =
  Apollo.MutationResult<DeleteEventImportKeywordMutation>;
export type DeleteEventImportKeywordMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteEventImportKeywordMutation,
    DeleteEventImportKeywordMutationVariables
  >;
export const RemoveExternalCallShareDocument = gql`
  mutation RemoveExternalCallShare($id: ID!) {
    removeExternalCallShare(id: $id) {
      call {
        id
        externalCallShares {
          ...ExternalCallShare
        }
      }
    }
  }
  ${ExternalCallShareFragmentDoc}
`;

/**
 * __useRemoveExternalCallShareMutation__
 *
 * To run a mutation, you first call `useRemoveExternalCallShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExternalCallShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExternalCallShareMutation, { data, loading, error }] = useRemoveExternalCallShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveExternalCallShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveExternalCallShareMutation,
    RemoveExternalCallShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveExternalCallShareMutation,
    RemoveExternalCallShareMutationVariables
  >(RemoveExternalCallShareDocument, options);
}
export type RemoveExternalCallShareMutationHookResult = ReturnType<
  typeof useRemoveExternalCallShareMutation
>;
export type RemoveExternalCallShareMutationResult =
  Apollo.MutationResult<RemoveExternalCallShareMutation>;
export type RemoveExternalCallShareMutationOptions = Apollo.BaseMutationOptions<
  RemoveExternalCallShareMutation,
  RemoveExternalCallShareMutationVariables
>;
export const RemoveExternalClipShareDocument = gql`
  mutation RemoveExternalClipShare($id: ID!) {
    removeExternalClipShare(id: $id) {
      clip {
        id
        externalClipShares {
          ...ExternalClipShare
        }
      }
    }
  }
  ${ExternalClipShareFragmentDoc}
`;

/**
 * __useRemoveExternalClipShareMutation__
 *
 * To run a mutation, you first call `useRemoveExternalClipShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExternalClipShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExternalClipShareMutation, { data, loading, error }] = useRemoveExternalClipShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveExternalClipShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveExternalClipShareMutation,
    RemoveExternalClipShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveExternalClipShareMutation,
    RemoveExternalClipShareMutationVariables
  >(RemoveExternalClipShareDocument, options);
}
export type RemoveExternalClipShareMutationHookResult = ReturnType<
  typeof useRemoveExternalClipShareMutation
>;
export type RemoveExternalClipShareMutationResult =
  Apollo.MutationResult<RemoveExternalClipShareMutation>;
export type RemoveExternalClipShareMutationOptions = Apollo.BaseMutationOptions<
  RemoveExternalClipShareMutation,
  RemoveExternalClipShareMutationVariables
>;
export const RenewExternalCallShareDocument = gql`
  mutation RenewExternalCallShare($id: ID!) {
    renewExternalCallShare(id: $id) {
      call {
        id
        externalCallShares {
          ...ExternalCallShare
        }
      }
    }
  }
  ${ExternalCallShareFragmentDoc}
`;

/**
 * __useRenewExternalCallShareMutation__
 *
 * To run a mutation, you first call `useRenewExternalCallShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewExternalCallShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewExternalCallShareMutation, { data, loading, error }] = useRenewExternalCallShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRenewExternalCallShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenewExternalCallShareMutation,
    RenewExternalCallShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RenewExternalCallShareMutation,
    RenewExternalCallShareMutationVariables
  >(RenewExternalCallShareDocument, options);
}
export type RenewExternalCallShareMutationHookResult = ReturnType<
  typeof useRenewExternalCallShareMutation
>;
export type RenewExternalCallShareMutationResult =
  Apollo.MutationResult<RenewExternalCallShareMutation>;
export type RenewExternalCallShareMutationOptions = Apollo.BaseMutationOptions<
  RenewExternalCallShareMutation,
  RenewExternalCallShareMutationVariables
>;
export const RenewExternalClipShareDocument = gql`
  mutation RenewExternalClipShare($id: ID!) {
    renewExternalClipShare(id: $id) {
      clip {
        id
        externalClipShares {
          ...ExternalClipShare
        }
      }
    }
  }
  ${ExternalClipShareFragmentDoc}
`;

/**
 * __useRenewExternalClipShareMutation__
 *
 * To run a mutation, you first call `useRenewExternalClipShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewExternalClipShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewExternalClipShareMutation, { data, loading, error }] = useRenewExternalClipShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRenewExternalClipShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenewExternalClipShareMutation,
    RenewExternalClipShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RenewExternalClipShareMutation,
    RenewExternalClipShareMutationVariables
  >(RenewExternalClipShareDocument, options);
}
export type RenewExternalClipShareMutationHookResult = ReturnType<
  typeof useRenewExternalClipShareMutation
>;
export type RenewExternalClipShareMutationResult =
  Apollo.MutationResult<RenewExternalClipShareMutation>;
export type RenewExternalClipShareMutationOptions = Apollo.BaseMutationOptions<
  RenewExternalClipShareMutation,
  RenewExternalClipShareMutationVariables
>;
export const ShareCallExternallyDocument = gql`
  mutation ShareCallExternally(
    $callId: ID!
    $shareToEmails: [String!]!
    $message: String
  ) {
    shareCallExternally(
      callId: $callId
      shareToEmails: $shareToEmails
      message: $message
    ) {
      call {
        id
        externalCallShares {
          ...ExternalCallShare
        }
      }
    }
  }
  ${ExternalCallShareFragmentDoc}
`;

/**
 * __useShareCallExternallyMutation__
 *
 * To run a mutation, you first call `useShareCallExternallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareCallExternallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareCallExternallyMutation, { data, loading, error }] = useShareCallExternallyMutation({
 *   variables: {
 *      callId: // value for 'callId'
 *      shareToEmails: // value for 'shareToEmails'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useShareCallExternallyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareCallExternallyMutation,
    ShareCallExternallyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareCallExternallyMutation,
    ShareCallExternallyMutationVariables
  >(ShareCallExternallyDocument, options);
}
export type ShareCallExternallyMutationHookResult = ReturnType<
  typeof useShareCallExternallyMutation
>;
export type ShareCallExternallyMutationResult =
  Apollo.MutationResult<ShareCallExternallyMutation>;
export type ShareCallExternallyMutationOptions = Apollo.BaseMutationOptions<
  ShareCallExternallyMutation,
  ShareCallExternallyMutationVariables
>;
export const ShareClipExternallyDocument = gql`
  mutation ShareClipExternally(
    $clipId: ID!
    $shareToEmails: [String!]!
    $message: String
  ) {
    shareClipExternally(
      clipId: $clipId
      shareToEmails: $shareToEmails
      message: $message
    ) {
      clip {
        id
        externalClipShares {
          ...ExternalClipShare
        }
      }
    }
  }
  ${ExternalClipShareFragmentDoc}
`;

/**
 * __useShareClipExternallyMutation__
 *
 * To run a mutation, you first call `useShareClipExternallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareClipExternallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareClipExternallyMutation, { data, loading, error }] = useShareClipExternallyMutation({
 *   variables: {
 *      clipId: // value for 'clipId'
 *      shareToEmails: // value for 'shareToEmails'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useShareClipExternallyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareClipExternallyMutation,
    ShareClipExternallyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareClipExternallyMutation,
    ShareClipExternallyMutationVariables
  >(ShareClipExternallyDocument, options);
}
export type ShareClipExternallyMutationHookResult = ReturnType<
  typeof useShareClipExternallyMutation
>;
export type ShareClipExternallyMutationResult =
  Apollo.MutationResult<ShareClipExternallyMutation>;
export type ShareClipExternallyMutationOptions = Apollo.BaseMutationOptions<
  ShareClipExternallyMutation,
  ShareClipExternallyMutationVariables
>;
export const AddCallGuideCueDocument = gql`
  mutation AddCallGuideCue(
    $callGuideId: ID!
    $cue: String!
    $description: String!
  ) {
    addCallGuideCue(
      callGuideId: $callGuideId
      cue: $cue
      description: $description
    ) {
      callGuideCue {
        ...CallGuideCue
      }
    }
  }
  ${CallGuideCueFragmentDoc}
`;

/**
 * __useAddCallGuideCueMutation__
 *
 * To run a mutation, you first call `useAddCallGuideCueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCallGuideCueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCallGuideCueMutation, { data, loading, error }] = useAddCallGuideCueMutation({
 *   variables: {
 *      callGuideId: // value for 'callGuideId'
 *      cue: // value for 'cue'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddCallGuideCueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCallGuideCueMutation,
    AddCallGuideCueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCallGuideCueMutation,
    AddCallGuideCueMutationVariables
  >(AddCallGuideCueDocument, options);
}
export type AddCallGuideCueMutationHookResult = ReturnType<
  typeof useAddCallGuideCueMutation
>;
export type AddCallGuideCueMutationResult =
  Apollo.MutationResult<AddCallGuideCueMutation>;
export type AddCallGuideCueMutationOptions = Apollo.BaseMutationOptions<
  AddCallGuideCueMutation,
  AddCallGuideCueMutationVariables
>;
export const AddCallGuideDocument = gql`
  mutation AddCallGuide(
    $name: String!
    $positionId: ID
    $interviewStageId: ID
    $assignedUserIds: [ID!]
  ) {
    addCallGuide(
      name: $name
      positionId: $positionId
      interviewStageId: $interviewStageId
      assignedUserIds: $assignedUserIds
    ) {
      callGuide {
        ...CallGuide
      }
    }
  }
  ${CallGuideFragmentDoc}
`;

/**
 * __useAddCallGuideMutation__
 *
 * To run a mutation, you first call `useAddCallGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCallGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCallGuideMutation, { data, loading, error }] = useAddCallGuideMutation({
 *   variables: {
 *      name: // value for 'name'
 *      positionId: // value for 'positionId'
 *      interviewStageId: // value for 'interviewStageId'
 *      assignedUserIds: // value for 'assignedUserIds'
 *   },
 * });
 */
export function useAddCallGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCallGuideMutation,
    AddCallGuideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCallGuideMutation,
    AddCallGuideMutationVariables
  >(AddCallGuideDocument, options);
}
export type AddCallGuideMutationHookResult = ReturnType<
  typeof useAddCallGuideMutation
>;
export type AddCallGuideMutationResult =
  Apollo.MutationResult<AddCallGuideMutation>;
export type AddCallGuideMutationOptions = Apollo.BaseMutationOptions<
  AddCallGuideMutation,
  AddCallGuideMutationVariables
>;
export const AddCompetencyDocument = gql`
  mutation AddCompetency($callGuideId: ID!, $name: String!) {
    addCompetency(callGuideId: $callGuideId, name: $name) {
      competency {
        id
        name
        creatorId
        isMigrated
      }
    }
  }
`;

/**
 * __useAddCompetencyMutation__
 *
 * To run a mutation, you first call `useAddCompetencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompetencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompetencyMutation, { data, loading, error }] = useAddCompetencyMutation({
 *   variables: {
 *      callGuideId: // value for 'callGuideId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddCompetencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCompetencyMutation,
    AddCompetencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCompetencyMutation,
    AddCompetencyMutationVariables
  >(AddCompetencyDocument, options);
}
export type AddCompetencyMutationHookResult = ReturnType<
  typeof useAddCompetencyMutation
>;
export type AddCompetencyMutationResult =
  Apollo.MutationResult<AddCompetencyMutation>;
export type AddCompetencyMutationOptions = Apollo.BaseMutationOptions<
  AddCompetencyMutation,
  AddCompetencyMutationVariables
>;
export const DeleteCallGuideCueDocument = gql`
  mutation DeleteCallGuideCue($id: ID!) {
    deleteCallGuideCue(id: $id) {
      callGuideCue {
        id
      }
    }
  }
`;

/**
 * __useDeleteCallGuideCueMutation__
 *
 * To run a mutation, you first call `useDeleteCallGuideCueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCallGuideCueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCallGuideCueMutation, { data, loading, error }] = useDeleteCallGuideCueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCallGuideCueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCallGuideCueMutation,
    DeleteCallGuideCueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCallGuideCueMutation,
    DeleteCallGuideCueMutationVariables
  >(DeleteCallGuideCueDocument, options);
}
export type DeleteCallGuideCueMutationHookResult = ReturnType<
  typeof useDeleteCallGuideCueMutation
>;
export type DeleteCallGuideCueMutationResult =
  Apollo.MutationResult<DeleteCallGuideCueMutation>;
export type DeleteCallGuideCueMutationOptions = Apollo.BaseMutationOptions<
  DeleteCallGuideCueMutation,
  DeleteCallGuideCueMutationVariables
>;
export const DeleteCallGuideDocument = gql`
  mutation DeleteCallGuide($id: ID!) {
    deleteCallGuide(id: $id) {
      callGuide {
        id
        positionId
      }
    }
  }
`;

/**
 * __useDeleteCallGuideMutation__
 *
 * To run a mutation, you first call `useDeleteCallGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCallGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCallGuideMutation, { data, loading, error }] = useDeleteCallGuideMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCallGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCallGuideMutation,
    DeleteCallGuideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCallGuideMutation,
    DeleteCallGuideMutationVariables
  >(DeleteCallGuideDocument, options);
}
export type DeleteCallGuideMutationHookResult = ReturnType<
  typeof useDeleteCallGuideMutation
>;
export type DeleteCallGuideMutationResult =
  Apollo.MutationResult<DeleteCallGuideMutation>;
export type DeleteCallGuideMutationOptions = Apollo.BaseMutationOptions<
  DeleteCallGuideMutation,
  DeleteCallGuideMutationVariables
>;
export const DuplicateCallGuideDocument = gql`
  mutation DuplicateCallGuide($id: ID!) {
    duplicateCallGuide(id: $id) {
      callGuide {
        ...CallGuideListItem
      }
    }
  }
  ${CallGuideListItemFragmentDoc}
`;

/**
 * __useDuplicateCallGuideMutation__
 *
 * To run a mutation, you first call `useDuplicateCallGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCallGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCallGuideMutation, { data, loading, error }] = useDuplicateCallGuideMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateCallGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateCallGuideMutation,
    DuplicateCallGuideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateCallGuideMutation,
    DuplicateCallGuideMutationVariables
  >(DuplicateCallGuideDocument, options);
}
export type DuplicateCallGuideMutationHookResult = ReturnType<
  typeof useDuplicateCallGuideMutation
>;
export type DuplicateCallGuideMutationResult =
  Apollo.MutationResult<DuplicateCallGuideMutation>;
export type DuplicateCallGuideMutationOptions = Apollo.BaseMutationOptions<
  DuplicateCallGuideMutation,
  DuplicateCallGuideMutationVariables
>;
export const DuplicateCallGuidesToPositionDocument = gql`
  mutation DuplicateCallGuidesToPosition(
    $toPositionId: String!
    $fromPositionId: String!
    $callGuideIds: [String!]!
  ) {
    duplicateCallGuidesToPosition(
      toPositionId: $toPositionId
      fromPositionId: $fromPositionId
      callGuideIds: $callGuideIds
    ) {
      position {
        id
      }
    }
  }
`;

/**
 * __useDuplicateCallGuidesToPositionMutation__
 *
 * To run a mutation, you first call `useDuplicateCallGuidesToPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCallGuidesToPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCallGuidesToPositionMutation, { data, loading, error }] = useDuplicateCallGuidesToPositionMutation({
 *   variables: {
 *      toPositionId: // value for 'toPositionId'
 *      fromPositionId: // value for 'fromPositionId'
 *      callGuideIds: // value for 'callGuideIds'
 *   },
 * });
 */
export function useDuplicateCallGuidesToPositionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateCallGuidesToPositionMutation,
    DuplicateCallGuidesToPositionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateCallGuidesToPositionMutation,
    DuplicateCallGuidesToPositionMutationVariables
  >(DuplicateCallGuidesToPositionDocument, options);
}
export type DuplicateCallGuidesToPositionMutationHookResult = ReturnType<
  typeof useDuplicateCallGuidesToPositionMutation
>;
export type DuplicateCallGuidesToPositionMutationResult =
  Apollo.MutationResult<DuplicateCallGuidesToPositionMutation>;
export type DuplicateCallGuidesToPositionMutationOptions =
  Apollo.BaseMutationOptions<
    DuplicateCallGuidesToPositionMutation,
    DuplicateCallGuidesToPositionMutationVariables
  >;
export const OverrideCallGuideCueDocument = gql`
  mutation OverrideCallGuideCue($id: ID!, $cue: String) {
    overrideCallGuideCue(id: $id, cue: $cue) {
      callGuideCue {
        ...CallGuideCue
      }
    }
  }
  ${CallGuideCueFragmentDoc}
`;

/**
 * __useOverrideCallGuideCueMutation__
 *
 * To run a mutation, you first call `useOverrideCallGuideCueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverrideCallGuideCueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overrideCallGuideCueMutation, { data, loading, error }] = useOverrideCallGuideCueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cue: // value for 'cue'
 *   },
 * });
 */
export function useOverrideCallGuideCueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OverrideCallGuideCueMutation,
    OverrideCallGuideCueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OverrideCallGuideCueMutation,
    OverrideCallGuideCueMutationVariables
  >(OverrideCallGuideCueDocument, options);
}
export type OverrideCallGuideCueMutationHookResult = ReturnType<
  typeof useOverrideCallGuideCueMutation
>;
export type OverrideCallGuideCueMutationResult =
  Apollo.MutationResult<OverrideCallGuideCueMutation>;
export type OverrideCallGuideCueMutationOptions = Apollo.BaseMutationOptions<
  OverrideCallGuideCueMutation,
  OverrideCallGuideCueMutationVariables
>;
export const RemoveCallGuideAssignedUserDocument = gql`
  mutation RemoveCallGuideAssignedUser($callGuideId: ID!, $userId: ID!) {
    removeCallGuideAssignedUser(callGuideId: $callGuideId, userId: $userId) {
      callGuide {
        ...CallGuideListItem
      }
    }
  }
  ${CallGuideListItemFragmentDoc}
`;

/**
 * __useRemoveCallGuideAssignedUserMutation__
 *
 * To run a mutation, you first call `useRemoveCallGuideAssignedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCallGuideAssignedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCallGuideAssignedUserMutation, { data, loading, error }] = useRemoveCallGuideAssignedUserMutation({
 *   variables: {
 *      callGuideId: // value for 'callGuideId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveCallGuideAssignedUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCallGuideAssignedUserMutation,
    RemoveCallGuideAssignedUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCallGuideAssignedUserMutation,
    RemoveCallGuideAssignedUserMutationVariables
  >(RemoveCallGuideAssignedUserDocument, options);
}
export type RemoveCallGuideAssignedUserMutationHookResult = ReturnType<
  typeof useRemoveCallGuideAssignedUserMutation
>;
export type RemoveCallGuideAssignedUserMutationResult =
  Apollo.MutationResult<RemoveCallGuideAssignedUserMutation>;
export type RemoveCallGuideAssignedUserMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveCallGuideAssignedUserMutation,
    RemoveCallGuideAssignedUserMutationVariables
  >;
export const UpdateCallGuideAssignedUsersDocument = gql`
  mutation UpdateCallGuideAssignedUsers($id: ID!, $assignedUserIds: [ID!]!) {
    updateCallGuideAssignedUsers(id: $id, assignedUserIds: $assignedUserIds) {
      callGuide {
        ...CallGuideListItem
      }
    }
  }
  ${CallGuideListItemFragmentDoc}
`;

/**
 * __useUpdateCallGuideAssignedUsersMutation__
 *
 * To run a mutation, you first call `useUpdateCallGuideAssignedUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallGuideAssignedUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallGuideAssignedUsersMutation, { data, loading, error }] = useUpdateCallGuideAssignedUsersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      assignedUserIds: // value for 'assignedUserIds'
 *   },
 * });
 */
export function useUpdateCallGuideAssignedUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallGuideAssignedUsersMutation,
    UpdateCallGuideAssignedUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallGuideAssignedUsersMutation,
    UpdateCallGuideAssignedUsersMutationVariables
  >(UpdateCallGuideAssignedUsersDocument, options);
}
export type UpdateCallGuideAssignedUsersMutationHookResult = ReturnType<
  typeof useUpdateCallGuideAssignedUsersMutation
>;
export type UpdateCallGuideAssignedUsersMutationResult =
  Apollo.MutationResult<UpdateCallGuideAssignedUsersMutation>;
export type UpdateCallGuideAssignedUsersMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCallGuideAssignedUsersMutation,
    UpdateCallGuideAssignedUsersMutationVariables
  >;
export const UpdateCallGuideCueDocument = gql`
  mutation UpdateCallGuideCue(
    $id: ID!
    $cue: String
    $description: String
    $index: Int
  ) {
    updateCallGuideCue(
      id: $id
      cue: $cue
      description: $description
      index: $index
    ) {
      callGuideCue {
        ...CallGuideCue
      }
    }
  }
  ${CallGuideCueFragmentDoc}
`;

/**
 * __useUpdateCallGuideCueMutation__
 *
 * To run a mutation, you first call `useUpdateCallGuideCueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallGuideCueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallGuideCueMutation, { data, loading, error }] = useUpdateCallGuideCueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cue: // value for 'cue'
 *      description: // value for 'description'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useUpdateCallGuideCueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallGuideCueMutation,
    UpdateCallGuideCueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallGuideCueMutation,
    UpdateCallGuideCueMutationVariables
  >(UpdateCallGuideCueDocument, options);
}
export type UpdateCallGuideCueMutationHookResult = ReturnType<
  typeof useUpdateCallGuideCueMutation
>;
export type UpdateCallGuideCueMutationResult =
  Apollo.MutationResult<UpdateCallGuideCueMutation>;
export type UpdateCallGuideCueMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallGuideCueMutation,
  UpdateCallGuideCueMutationVariables
>;
export const UpdateCallGuideDocument = gql`
  mutation UpdateCallGuide(
    $id: ID!
    $positionId: ID
    $interviewStageId: ID
    $assignedUserIds: [ID!]
    $name: String
    $visibility: String
    $isTemplate: Boolean
    $overallScoringEnabled: Boolean
    $guideItems: [CallGuideItemInput!]
  ) {
    updateCallGuide(
      id: $id
      positionId: $positionId
      interviewStageId: $interviewStageId
      assignedUserIds: $assignedUserIds
      name: $name
      visibility: $visibility
      isTemplate: $isTemplate
      overallScoringEnabled: $overallScoringEnabled
      guideItems: $guideItems
    ) {
      callGuide {
        ...CallGuideListItem
      }
    }
  }
  ${CallGuideListItemFragmentDoc}
`;

/**
 * __useUpdateCallGuideMutation__
 *
 * To run a mutation, you first call `useUpdateCallGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallGuideMutation, { data, loading, error }] = useUpdateCallGuideMutation({
 *   variables: {
 *      id: // value for 'id'
 *      positionId: // value for 'positionId'
 *      interviewStageId: // value for 'interviewStageId'
 *      assignedUserIds: // value for 'assignedUserIds'
 *      name: // value for 'name'
 *      visibility: // value for 'visibility'
 *      isTemplate: // value for 'isTemplate'
 *      overallScoringEnabled: // value for 'overallScoringEnabled'
 *      guideItems: // value for 'guideItems'
 *   },
 * });
 */
export function useUpdateCallGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallGuideMutation,
    UpdateCallGuideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallGuideMutation,
    UpdateCallGuideMutationVariables
  >(UpdateCallGuideDocument, options);
}
export type UpdateCallGuideMutationHookResult = ReturnType<
  typeof useUpdateCallGuideMutation
>;
export type UpdateCallGuideMutationResult =
  Apollo.MutationResult<UpdateCallGuideMutation>;
export type UpdateCallGuideMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallGuideMutation,
  UpdateCallGuideMutationVariables
>;
export const UpdateCallGuidesDocument = gql`
  mutation UpdateCallGuides(
    $ids: [ID!]!
    $positionId: ID
    $assignedUserIds: [ID!]
  ) {
    updateCallGuides(
      ids: $ids
      positionId: $positionId
      assignedUserIds: $assignedUserIds
    ) {
      callGuides {
        ...CallGuideListItem
      }
    }
  }
  ${CallGuideListItemFragmentDoc}
`;

/**
 * __useUpdateCallGuidesMutation__
 *
 * To run a mutation, you first call `useUpdateCallGuidesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallGuidesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallGuidesMutation, { data, loading, error }] = useUpdateCallGuidesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      positionId: // value for 'positionId'
 *      assignedUserIds: // value for 'assignedUserIds'
 *   },
 * });
 */
export function useUpdateCallGuidesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallGuidesMutation,
    UpdateCallGuidesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallGuidesMutation,
    UpdateCallGuidesMutationVariables
  >(UpdateCallGuidesDocument, options);
}
export type UpdateCallGuidesMutationHookResult = ReturnType<
  typeof useUpdateCallGuidesMutation
>;
export type UpdateCallGuidesMutationResult =
  Apollo.MutationResult<UpdateCallGuidesMutation>;
export type UpdateCallGuidesMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallGuidesMutation,
  UpdateCallGuidesMutationVariables
>;
export const UpdateOrganizationComplianceSettingsDocument = gql`
  mutation UpdateOrganizationComplianceSettings(
    $playCallDisclaimer: Boolean!
    $allowCandidateOptOut: Boolean!
    $allowInterviewerOptOut: Boolean!
    $dataRedactionEnabled: Boolean
    $dataRedactionPredictionEnabled: Boolean
    $redactEeocFamilyEnabled: Boolean
    $redactEeocSexEnabled: Boolean
    $redactEeocRaceEnabled: Boolean
    $redactEeocHighRiskQuestionsEnabled: Boolean
    $redactCompensationEnabled: Boolean
  ) {
    updateOrganizationComplianceSettings(
      playCallDisclaimer: $playCallDisclaimer
      allowCandidateOptOut: $allowCandidateOptOut
      allowInterviewerOptOut: $allowInterviewerOptOut
      dataRedactionEnabled: $dataRedactionEnabled
      dataRedactionPredictionEnabled: $dataRedactionPredictionEnabled
      redactEeocFamilyEnabled: $redactEeocFamilyEnabled
      redactEeocSexEnabled: $redactEeocSexEnabled
      redactEeocRaceEnabled: $redactEeocRaceEnabled
      redactEeocHighRiskQuestionsEnabled: $redactEeocHighRiskQuestionsEnabled
      redactCompensationEnabled: $redactCompensationEnabled
    ) {
      organization {
        id
        dataRedactionEnabled
        dataRedactionPredictionEnabled
        redactEeocFamilyEnabled
        redactEeocSexEnabled
        redactEeocRaceEnabled
        redactEeocHighRiskQuestionsEnabled
        redactCompensationEnabled
        playCallDisclaimer
        allowCandidateOptOut
        allowInterviewerOptOut
      }
    }
  }
`;

/**
 * __useUpdateOrganizationComplianceSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationComplianceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationComplianceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationComplianceSettingsMutation, { data, loading, error }] = useUpdateOrganizationComplianceSettingsMutation({
 *   variables: {
 *      playCallDisclaimer: // value for 'playCallDisclaimer'
 *      allowCandidateOptOut: // value for 'allowCandidateOptOut'
 *      allowInterviewerOptOut: // value for 'allowInterviewerOptOut'
 *      dataRedactionEnabled: // value for 'dataRedactionEnabled'
 *      dataRedactionPredictionEnabled: // value for 'dataRedactionPredictionEnabled'
 *      redactEeocFamilyEnabled: // value for 'redactEeocFamilyEnabled'
 *      redactEeocSexEnabled: // value for 'redactEeocSexEnabled'
 *      redactEeocRaceEnabled: // value for 'redactEeocRaceEnabled'
 *      redactEeocHighRiskQuestionsEnabled: // value for 'redactEeocHighRiskQuestionsEnabled'
 *      redactCompensationEnabled: // value for 'redactCompensationEnabled'
 *   },
 * });
 */
export function useUpdateOrganizationComplianceSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationComplianceSettingsMutation,
    UpdateOrganizationComplianceSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationComplianceSettingsMutation,
    UpdateOrganizationComplianceSettingsMutationVariables
  >(UpdateOrganizationComplianceSettingsDocument, options);
}
export type UpdateOrganizationComplianceSettingsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationComplianceSettingsMutation
>;
export type UpdateOrganizationComplianceSettingsMutationResult =
  Apollo.MutationResult<UpdateOrganizationComplianceSettingsMutation>;
export type UpdateOrganizationComplianceSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationComplianceSettingsMutation,
    UpdateOrganizationComplianceSettingsMutationVariables
  >;
export const UpdateOrganizationDataRetentionDocument = gql`
  mutation UpdateOrganizationDataRetention(
    $enableRetentionSinceCandidateHired: Boolean
    $retentionDaysSinceCandidateHired: Int
    $enableRetentionSinceCandidateRejected: Boolean
    $retentionDaysSinceCandidateRejected: Int
    $enableRetentionSinceLastInterview: Boolean!
    $retentionDaysSinceLastInterview: Int!
    $enableRetentionSinceInterview: Boolean!
    $retentionDaysSinceInterview: Int!
    $enableRedactionForGreenhouse: Boolean
    $enableRedactionForLever: Boolean
    $enableRedactionForSmartrecruiters: Boolean
    $enableRedactionForAshby: Boolean
    $enableAutomaticRetentionDeletion: Boolean
    $retentionNotificationDays: [String]!
    $retentionEmailList: [String]!
    $retentionEmailSendTime: String!
    $retentionEmailSendTimezone: String!
    $preventRedactionForTraining: Boolean!
  ) {
    updateOrganizationDataRetention(
      enableRetentionSinceCandidateHired: $enableRetentionSinceCandidateHired
      retentionDaysSinceCandidateHired: $retentionDaysSinceCandidateHired
      enableRetentionSinceCandidateRejected: $enableRetentionSinceCandidateRejected
      retentionDaysSinceCandidateRejected: $retentionDaysSinceCandidateRejected
      enableRetentionSinceLastInterview: $enableRetentionSinceLastInterview
      retentionDaysSinceLastInterview: $retentionDaysSinceLastInterview
      enableRetentionSinceInterview: $enableRetentionSinceInterview
      retentionDaysSinceInterview: $retentionDaysSinceInterview
      enableRedactionForGreenhouse: $enableRedactionForGreenhouse
      enableRedactionForLever: $enableRedactionForLever
      enableRedactionForSmartrecruiters: $enableRedactionForSmartrecruiters
      enableRedactionForAshby: $enableRedactionForAshby
      enableAutomaticRetentionDeletion: $enableAutomaticRetentionDeletion
      retentionNotificationDays: $retentionNotificationDays
      retentionEmailList: $retentionEmailList
      retentionEmailSendTime: $retentionEmailSendTime
      retentionEmailSendTimezone: $retentionEmailSendTimezone
      preventRedactionForTraining: $preventRedactionForTraining
    ) {
      organization {
        id
        ats
        enableRetentionSinceCandidateHired
        retentionDaysSinceCandidateHired
        enableRetentionSinceCandidateRejected
        retentionDaysSinceCandidateRejected
        enableRetentionSinceLastInterview
        retentionDaysSinceLastInterview
        enableRetentionSinceInterview
        retentionDaysSinceInterview
        enableRedactionForGreenhouse
        enableRedactionForLever
        enableRedactionForSmartrecruiters
        enableRedactionForAshby
        enableAutomaticRetentionDeletion
        retentionNotificationDays
        organizationRetentionUser {
          userId
        }
        retentionEmailSendTime
        retentionEmailSendTimezone
        preventRedactionForTraining
      }
    }
  }
`;

/**
 * __useUpdateOrganizationDataRetentionMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationDataRetentionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationDataRetentionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationDataRetentionMutation, { data, loading, error }] = useUpdateOrganizationDataRetentionMutation({
 *   variables: {
 *      enableRetentionSinceCandidateHired: // value for 'enableRetentionSinceCandidateHired'
 *      retentionDaysSinceCandidateHired: // value for 'retentionDaysSinceCandidateHired'
 *      enableRetentionSinceCandidateRejected: // value for 'enableRetentionSinceCandidateRejected'
 *      retentionDaysSinceCandidateRejected: // value for 'retentionDaysSinceCandidateRejected'
 *      enableRetentionSinceLastInterview: // value for 'enableRetentionSinceLastInterview'
 *      retentionDaysSinceLastInterview: // value for 'retentionDaysSinceLastInterview'
 *      enableRetentionSinceInterview: // value for 'enableRetentionSinceInterview'
 *      retentionDaysSinceInterview: // value for 'retentionDaysSinceInterview'
 *      enableRedactionForGreenhouse: // value for 'enableRedactionForGreenhouse'
 *      enableRedactionForLever: // value for 'enableRedactionForLever'
 *      enableRedactionForSmartrecruiters: // value for 'enableRedactionForSmartrecruiters'
 *      enableRedactionForAshby: // value for 'enableRedactionForAshby'
 *      enableAutomaticRetentionDeletion: // value for 'enableAutomaticRetentionDeletion'
 *      retentionNotificationDays: // value for 'retentionNotificationDays'
 *      retentionEmailList: // value for 'retentionEmailList'
 *      retentionEmailSendTime: // value for 'retentionEmailSendTime'
 *      retentionEmailSendTimezone: // value for 'retentionEmailSendTimezone'
 *      preventRedactionForTraining: // value for 'preventRedactionForTraining'
 *   },
 * });
 */
export function useUpdateOrganizationDataRetentionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationDataRetentionMutation,
    UpdateOrganizationDataRetentionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationDataRetentionMutation,
    UpdateOrganizationDataRetentionMutationVariables
  >(UpdateOrganizationDataRetentionDocument, options);
}
export type UpdateOrganizationDataRetentionMutationHookResult = ReturnType<
  typeof useUpdateOrganizationDataRetentionMutation
>;
export type UpdateOrganizationDataRetentionMutationResult =
  Apollo.MutationResult<UpdateOrganizationDataRetentionMutation>;
export type UpdateOrganizationDataRetentionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationDataRetentionMutation,
    UpdateOrganizationDataRetentionMutationVariables
  >;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization(
    $greenhouseScorecardAutoDraftRule: OrgScorecardAutoDraftRule
    $greenhouseScorecardAutoDraftOnboardingRule: OrgScorecardAutoDraftOnboardingRule
  ) {
    updateOrganization(
      greenhouseScorecardAutoDraftRule: $greenhouseScorecardAutoDraftRule
      greenhouseScorecardAutoDraftOnboardingRule: $greenhouseScorecardAutoDraftOnboardingRule
    ) {
      currentUser {
        id
        autofillGreenhouseScorecard
      }
      organization {
        id
        greenhouseScorecardAutoDraftRule
        greenhouseScorecardAutoDraftOnboardingRule
        allowsGreenhouseScorecardAutoDraft
        requiresGreenhouseScorecardAutoDraft
      }
    }
  }
`;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      greenhouseScorecardAutoDraftRule: // value for 'greenhouseScorecardAutoDraftRule'
 *      greenhouseScorecardAutoDraftOnboardingRule: // value for 'greenhouseScorecardAutoDraftOnboardingRule'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationHookResult = ReturnType<
  typeof useUpdateOrganizationMutation
>;
export type UpdateOrganizationMutationResult =
  Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const UpdateOrganizationNameDocument = gql`
  mutation UpdateOrganizationName($name: String!) {
    updateOrganizationName(name: $name) {
      organization {
        id
        name
      }
      currentUser {
        id
        onboardingSteps {
          step
          status
          rule
        }
      }
    }
  }
`;

/**
 * __useUpdateOrganizationNameMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationNameMutation, { data, loading, error }] = useUpdateOrganizationNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateOrganizationNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationNameMutation,
    UpdateOrganizationNameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationNameMutation,
    UpdateOrganizationNameMutationVariables
  >(UpdateOrganizationNameDocument, options);
}
export type UpdateOrganizationNameMutationHookResult = ReturnType<
  typeof useUpdateOrganizationNameMutation
>;
export type UpdateOrganizationNameMutationResult =
  Apollo.MutationResult<UpdateOrganizationNameMutation>;
export type UpdateOrganizationNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationNameMutation,
  UpdateOrganizationNameMutationVariables
>;
export const CreateAndSharePlaylistExternallyDocument = gql`
  mutation CreateAndSharePlaylistExternally(
    $clipIds: [ID!]!
    $title: String
    $description: String
    $shareMessage: String!
    $shareToEmails: [String!]!
  ) {
    createAndSharePlaylistExternally(
      clipIds: $clipIds
      title: $title
      description: $description
      shareMessage: $shareMessage
      shareToEmails: $shareToEmails
    ) {
      playlist {
        ...PlaylistSidebarItem
      }
    }
  }
  ${PlaylistSidebarItemFragmentDoc}
`;

/**
 * __useCreateAndSharePlaylistExternallyMutation__
 *
 * To run a mutation, you first call `useCreateAndSharePlaylistExternallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndSharePlaylistExternallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndSharePlaylistExternallyMutation, { data, loading, error }] = useCreateAndSharePlaylistExternallyMutation({
 *   variables: {
 *      clipIds: // value for 'clipIds'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shareMessage: // value for 'shareMessage'
 *      shareToEmails: // value for 'shareToEmails'
 *   },
 * });
 */
export function useCreateAndSharePlaylistExternallyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAndSharePlaylistExternallyMutation,
    CreateAndSharePlaylistExternallyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAndSharePlaylistExternallyMutation,
    CreateAndSharePlaylistExternallyMutationVariables
  >(CreateAndSharePlaylistExternallyDocument, options);
}
export type CreateAndSharePlaylistExternallyMutationHookResult = ReturnType<
  typeof useCreateAndSharePlaylistExternallyMutation
>;
export type CreateAndSharePlaylistExternallyMutationResult =
  Apollo.MutationResult<CreateAndSharePlaylistExternallyMutation>;
export type CreateAndSharePlaylistExternallyMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAndSharePlaylistExternallyMutation,
    CreateAndSharePlaylistExternallyMutationVariables
  >;
export const CreateAndSharePlaylistDocument = gql`
  mutation CreateAndSharePlaylist(
    $clipIds: [ID!]!
    $title: String
    $description: String
    $shareMessage: String!
    $shareToUserIds: [ID!]!
  ) {
    createAndSharePlaylist(
      clipIds: $clipIds
      title: $title
      description: $description
      shareMessage: $shareMessage
      shareToUserIds: $shareToUserIds
    ) {
      playlist {
        ...PlaylistSidebarItem
      }
    }
  }
  ${PlaylistSidebarItemFragmentDoc}
`;

/**
 * __useCreateAndSharePlaylistMutation__
 *
 * To run a mutation, you first call `useCreateAndSharePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndSharePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndSharePlaylistMutation, { data, loading, error }] = useCreateAndSharePlaylistMutation({
 *   variables: {
 *      clipIds: // value for 'clipIds'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shareMessage: // value for 'shareMessage'
 *      shareToUserIds: // value for 'shareToUserIds'
 *   },
 * });
 */
export function useCreateAndSharePlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAndSharePlaylistMutation,
    CreateAndSharePlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAndSharePlaylistMutation,
    CreateAndSharePlaylistMutationVariables
  >(CreateAndSharePlaylistDocument, options);
}
export type CreateAndSharePlaylistMutationHookResult = ReturnType<
  typeof useCreateAndSharePlaylistMutation
>;
export type CreateAndSharePlaylistMutationResult =
  Apollo.MutationResult<CreateAndSharePlaylistMutation>;
export type CreateAndSharePlaylistMutationOptions = Apollo.BaseMutationOptions<
  CreateAndSharePlaylistMutation,
  CreateAndSharePlaylistMutationVariables
>;
export const CreateExternalPlaylistShareDocument = gql`
  mutation CreateExternalPlaylistShare(
    $playlistId: ID!
    $shareMessage: String
    $shareToEmails: [String!]!
  ) {
    sharePlaylistExternally(
      playlistId: $playlistId
      shareMessage: $shareMessage
      shareToEmails: $shareToEmails
    ) {
      playlist {
        id
        externalShares {
          ...ExternalPlaylistShare
        }
      }
    }
  }
  ${ExternalPlaylistShareFragmentDoc}
`;

/**
 * __useCreateExternalPlaylistShareMutation__
 *
 * To run a mutation, you first call `useCreateExternalPlaylistShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalPlaylistShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalPlaylistShareMutation, { data, loading, error }] = useCreateExternalPlaylistShareMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      shareMessage: // value for 'shareMessage'
 *      shareToEmails: // value for 'shareToEmails'
 *   },
 * });
 */
export function useCreateExternalPlaylistShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExternalPlaylistShareMutation,
    CreateExternalPlaylistShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExternalPlaylistShareMutation,
    CreateExternalPlaylistShareMutationVariables
  >(CreateExternalPlaylistShareDocument, options);
}
export type CreateExternalPlaylistShareMutationHookResult = ReturnType<
  typeof useCreateExternalPlaylistShareMutation
>;
export type CreateExternalPlaylistShareMutationResult =
  Apollo.MutationResult<CreateExternalPlaylistShareMutation>;
export type CreateExternalPlaylistShareMutationOptions =
  Apollo.BaseMutationOptions<
    CreateExternalPlaylistShareMutation,
    CreateExternalPlaylistShareMutationVariables
  >;
export const CreatePlaylistDocument = gql`
  mutation CreatePlaylist(
    $clipIds: [ID!]!
    $title: String
    $description: String
  ) {
    createPlaylist(
      clipIds: $clipIds
      title: $title
      description: $description
    ) {
      playlist {
        id
      }
    }
  }
`;

/**
 * __useCreatePlaylistMutation__
 *
 * To run a mutation, you first call `useCreatePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaylistMutation, { data, loading, error }] = useCreatePlaylistMutation({
 *   variables: {
 *      clipIds: // value for 'clipIds'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreatePlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlaylistMutation,
    CreatePlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePlaylistMutation,
    CreatePlaylistMutationVariables
  >(CreatePlaylistDocument, options);
}
export type CreatePlaylistMutationHookResult = ReturnType<
  typeof useCreatePlaylistMutation
>;
export type CreatePlaylistMutationResult =
  Apollo.MutationResult<CreatePlaylistMutation>;
export type CreatePlaylistMutationOptions = Apollo.BaseMutationOptions<
  CreatePlaylistMutation,
  CreatePlaylistMutationVariables
>;
export const CreatePlaylistShareDocument = gql`
  mutation CreatePlaylistShare(
    $playlistId: ID!
    $shareMessage: String!
    $shareToUserIds: [ID!]!
  ) {
    sharePlaylist(
      message: $shareMessage
      shareToUserIds: $shareToUserIds
      playlistId: $playlistId
    ) {
      playlist {
        id
        shares {
          ...PlaylistShare
        }
      }
    }
  }
  ${PlaylistShareFragmentDoc}
`;

/**
 * __useCreatePlaylistShareMutation__
 *
 * To run a mutation, you first call `useCreatePlaylistShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaylistShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaylistShareMutation, { data, loading, error }] = useCreatePlaylistShareMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      shareMessage: // value for 'shareMessage'
 *      shareToUserIds: // value for 'shareToUserIds'
 *   },
 * });
 */
export function useCreatePlaylistShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlaylistShareMutation,
    CreatePlaylistShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePlaylistShareMutation,
    CreatePlaylistShareMutationVariables
  >(CreatePlaylistShareDocument, options);
}
export type CreatePlaylistShareMutationHookResult = ReturnType<
  typeof useCreatePlaylistShareMutation
>;
export type CreatePlaylistShareMutationResult =
  Apollo.MutationResult<CreatePlaylistShareMutation>;
export type CreatePlaylistShareMutationOptions = Apollo.BaseMutationOptions<
  CreatePlaylistShareMutation,
  CreatePlaylistShareMutationVariables
>;
export const DeletePlaylistDocument = gql`
  mutation DeletePlaylist($playlistId: ID!) {
    deletePlaylist(id: $playlistId) {
      playlistId
    }
  }
`;

/**
 * __useDeletePlaylistMutation__
 *
 * To run a mutation, you first call `useDeletePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaylistMutation, { data, loading, error }] = useDeletePlaylistMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function useDeletePlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePlaylistMutation,
    DeletePlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePlaylistMutation,
    DeletePlaylistMutationVariables
  >(DeletePlaylistDocument, options);
}
export type DeletePlaylistMutationHookResult = ReturnType<
  typeof useDeletePlaylistMutation
>;
export type DeletePlaylistMutationResult =
  Apollo.MutationResult<DeletePlaylistMutation>;
export type DeletePlaylistMutationOptions = Apollo.BaseMutationOptions<
  DeletePlaylistMutation,
  DeletePlaylistMutationVariables
>;
export const RemoveClipFromPlaylistDocument = gql`
  mutation RemoveClipFromPlaylist($playlistId: ID!, $clipId: ID!) {
    removeClipFromPlaylist(playlistId: $playlistId, clipId: $clipId) {
      playlist {
        id
        clips {
          id
          callId
          displayName
          duration
        }
      }
    }
  }
`;

/**
 * __useRemoveClipFromPlaylistMutation__
 *
 * To run a mutation, you first call `useRemoveClipFromPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClipFromPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClipFromPlaylistMutation, { data, loading, error }] = useRemoveClipFromPlaylistMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useRemoveClipFromPlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClipFromPlaylistMutation,
    RemoveClipFromPlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveClipFromPlaylistMutation,
    RemoveClipFromPlaylistMutationVariables
  >(RemoveClipFromPlaylistDocument, options);
}
export type RemoveClipFromPlaylistMutationHookResult = ReturnType<
  typeof useRemoveClipFromPlaylistMutation
>;
export type RemoveClipFromPlaylistMutationResult =
  Apollo.MutationResult<RemoveClipFromPlaylistMutation>;
export type RemoveClipFromPlaylistMutationOptions = Apollo.BaseMutationOptions<
  RemoveClipFromPlaylistMutation,
  RemoveClipFromPlaylistMutationVariables
>;
export const RemoveExternalPlaylistShareDocument = gql`
  mutation RemoveExternalPlaylistShare($shareId: ID!) {
    removeExternalPlaylistShare(id: $shareId) {
      playlistShare {
        id
      }
    }
  }
`;

/**
 * __useRemoveExternalPlaylistShareMutation__
 *
 * To run a mutation, you first call `useRemoveExternalPlaylistShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExternalPlaylistShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExternalPlaylistShareMutation, { data, loading, error }] = useRemoveExternalPlaylistShareMutation({
 *   variables: {
 *      shareId: // value for 'shareId'
 *   },
 * });
 */
export function useRemoveExternalPlaylistShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveExternalPlaylistShareMutation,
    RemoveExternalPlaylistShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveExternalPlaylistShareMutation,
    RemoveExternalPlaylistShareMutationVariables
  >(RemoveExternalPlaylistShareDocument, options);
}
export type RemoveExternalPlaylistShareMutationHookResult = ReturnType<
  typeof useRemoveExternalPlaylistShareMutation
>;
export type RemoveExternalPlaylistShareMutationResult =
  Apollo.MutationResult<RemoveExternalPlaylistShareMutation>;
export type RemoveExternalPlaylistShareMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveExternalPlaylistShareMutation,
    RemoveExternalPlaylistShareMutationVariables
  >;
export const RemovePlaylistShareDocument = gql`
  mutation RemovePlaylistShare($shareId: ID!) {
    removePlaylistShare(id: $shareId) {
      playlistShare {
        id
      }
    }
  }
`;

/**
 * __useRemovePlaylistShareMutation__
 *
 * To run a mutation, you first call `useRemovePlaylistShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlaylistShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlaylistShareMutation, { data, loading, error }] = useRemovePlaylistShareMutation({
 *   variables: {
 *      shareId: // value for 'shareId'
 *   },
 * });
 */
export function useRemovePlaylistShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePlaylistShareMutation,
    RemovePlaylistShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePlaylistShareMutation,
    RemovePlaylistShareMutationVariables
  >(RemovePlaylistShareDocument, options);
}
export type RemovePlaylistShareMutationHookResult = ReturnType<
  typeof useRemovePlaylistShareMutation
>;
export type RemovePlaylistShareMutationResult =
  Apollo.MutationResult<RemovePlaylistShareMutation>;
export type RemovePlaylistShareMutationOptions = Apollo.BaseMutationOptions<
  RemovePlaylistShareMutation,
  RemovePlaylistShareMutationVariables
>;
export const RenamePlaylistDocument = gql`
  mutation RenamePlaylist($playlistId: ID!, $title: String!) {
    renamePlaylist(id: $playlistId, name: $title) {
      playlist {
        id
        title
      }
    }
  }
`;

/**
 * __useRenamePlaylistMutation__
 *
 * To run a mutation, you first call `useRenamePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenamePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renamePlaylistMutation, { data, loading, error }] = useRenamePlaylistMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useRenamePlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenamePlaylistMutation,
    RenamePlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RenamePlaylistMutation,
    RenamePlaylistMutationVariables
  >(RenamePlaylistDocument, options);
}
export type RenamePlaylistMutationHookResult = ReturnType<
  typeof useRenamePlaylistMutation
>;
export type RenamePlaylistMutationResult =
  Apollo.MutationResult<RenamePlaylistMutation>;
export type RenamePlaylistMutationOptions = Apollo.BaseMutationOptions<
  RenamePlaylistMutation,
  RenamePlaylistMutationVariables
>;
export const RenewExternalPlaylistShareDocument = gql`
  mutation RenewExternalPlaylistShare($id: ID!) {
    renewExternalPlaylistShare(id: $id) {
      externalPlaylistShare {
        ...ExternalPlaylistShare
      }
    }
  }
  ${ExternalPlaylistShareFragmentDoc}
`;

/**
 * __useRenewExternalPlaylistShareMutation__
 *
 * To run a mutation, you first call `useRenewExternalPlaylistShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewExternalPlaylistShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewExternalPlaylistShareMutation, { data, loading, error }] = useRenewExternalPlaylistShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRenewExternalPlaylistShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenewExternalPlaylistShareMutation,
    RenewExternalPlaylistShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RenewExternalPlaylistShareMutation,
    RenewExternalPlaylistShareMutationVariables
  >(RenewExternalPlaylistShareDocument, options);
}
export type RenewExternalPlaylistShareMutationHookResult = ReturnType<
  typeof useRenewExternalPlaylistShareMutation
>;
export type RenewExternalPlaylistShareMutationResult =
  Apollo.MutationResult<RenewExternalPlaylistShareMutation>;
export type RenewExternalPlaylistShareMutationOptions =
  Apollo.BaseMutationOptions<
    RenewExternalPlaylistShareMutation,
    RenewExternalPlaylistShareMutationVariables
  >;
export const ReorderPlaylistClipsDocument = gql`
  mutation ReorderPlaylistClips($playlistId: ID!, $clipIds: [ID!]!) {
    reorderPlaylistClips(playlistId: $playlistId, clipIds: $clipIds) {
      playlist {
        id
        clips {
          id
          callId
          displayName
          duration
        }
      }
    }
  }
`;

/**
 * __useReorderPlaylistClipsMutation__
 *
 * To run a mutation, you first call `useReorderPlaylistClipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderPlaylistClipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderPlaylistClipsMutation, { data, loading, error }] = useReorderPlaylistClipsMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      clipIds: // value for 'clipIds'
 *   },
 * });
 */
export function useReorderPlaylistClipsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderPlaylistClipsMutation,
    ReorderPlaylistClipsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderPlaylistClipsMutation,
    ReorderPlaylistClipsMutationVariables
  >(ReorderPlaylistClipsDocument, options);
}
export type ReorderPlaylistClipsMutationHookResult = ReturnType<
  typeof useReorderPlaylistClipsMutation
>;
export type ReorderPlaylistClipsMutationResult =
  Apollo.MutationResult<ReorderPlaylistClipsMutation>;
export type ReorderPlaylistClipsMutationOptions = Apollo.BaseMutationOptions<
  ReorderPlaylistClipsMutation,
  ReorderPlaylistClipsMutationVariables
>;
export const MarkScorecardQuestionDocument = gql`
  mutation MarkScorecardQuestion(
    $scorecardQuestionId: ID!
    $markedTime: Float!
  ) {
    markScorecardQuestion(
      scorecardQuestionId: $scorecardQuestionId
      markedTime: $markedTime
    ) {
      scorecardItem {
        id
        marked
        markedTime
      }
    }
  }
`;

/**
 * __useMarkScorecardQuestionMutation__
 *
 * To run a mutation, you first call `useMarkScorecardQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkScorecardQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markScorecardQuestionMutation, { data, loading, error }] = useMarkScorecardQuestionMutation({
 *   variables: {
 *      scorecardQuestionId: // value for 'scorecardQuestionId'
 *      markedTime: // value for 'markedTime'
 *   },
 * });
 */
export function useMarkScorecardQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkScorecardQuestionMutation,
    MarkScorecardQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkScorecardQuestionMutation,
    MarkScorecardQuestionMutationVariables
  >(MarkScorecardQuestionDocument, options);
}
export type MarkScorecardQuestionMutationHookResult = ReturnType<
  typeof useMarkScorecardQuestionMutation
>;
export type MarkScorecardQuestionMutationResult =
  Apollo.MutationResult<MarkScorecardQuestionMutation>;
export type MarkScorecardQuestionMutationOptions = Apollo.BaseMutationOptions<
  MarkScorecardQuestionMutation,
  MarkScorecardQuestionMutationVariables
>;
export const UnmarkScorecardQuestionDocument = gql`
  mutation UnmarkScorecardQuestion($scorecardQuestionId: ID!) {
    unMarkScorecardQuestion(scorecardQuestionId: $scorecardQuestionId) {
      scorecardItem {
        id
        marked
        markedTime
      }
    }
  }
`;

/**
 * __useUnmarkScorecardQuestionMutation__
 *
 * To run a mutation, you first call `useUnmarkScorecardQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmarkScorecardQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmarkScorecardQuestionMutation, { data, loading, error }] = useUnmarkScorecardQuestionMutation({
 *   variables: {
 *      scorecardQuestionId: // value for 'scorecardQuestionId'
 *   },
 * });
 */
export function useUnmarkScorecardQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnmarkScorecardQuestionMutation,
    UnmarkScorecardQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnmarkScorecardQuestionMutation,
    UnmarkScorecardQuestionMutationVariables
  >(UnmarkScorecardQuestionDocument, options);
}
export type UnmarkScorecardQuestionMutationHookResult = ReturnType<
  typeof useUnmarkScorecardQuestionMutation
>;
export type UnmarkScorecardQuestionMutationResult =
  Apollo.MutationResult<UnmarkScorecardQuestionMutation>;
export type UnmarkScorecardQuestionMutationOptions = Apollo.BaseMutationOptions<
  UnmarkScorecardQuestionMutation,
  UnmarkScorecardQuestionMutationVariables
>;
export const CreateSkillReportDocument = gql`
  mutation CreateSkillReport(
    $positionIds: [ID!]!
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $skills: [SkillReportSkillInput!]!
    $interviewStageFilters: JSONString!
    $minimumInterviewCountFilter: Int!
    $source: SkillReportSkillsSource!
    $title: String!
  ) {
    createSkillReport(
      positionIds: $positionIds
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      skills: $skills
      interviewStageFilters: $interviewStageFilters
      minimumInterviewCountFilter: $minimumInterviewCountFilter
      source: $source
      title: $title
    ) {
      skillReport {
        id
      }
    }
  }
`;

/**
 * __useCreateSkillReportMutation__
 *
 * To run a mutation, you first call `useCreateSkillReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkillReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkillReportMutation, { data, loading, error }] = useCreateSkillReportMutation({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      skills: // value for 'skills'
 *      interviewStageFilters: // value for 'interviewStageFilters'
 *      minimumInterviewCountFilter: // value for 'minimumInterviewCountFilter'
 *      source: // value for 'source'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateSkillReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSkillReportMutation,
    CreateSkillReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSkillReportMutation,
    CreateSkillReportMutationVariables
  >(CreateSkillReportDocument, options);
}
export type CreateSkillReportMutationHookResult = ReturnType<
  typeof useCreateSkillReportMutation
>;
export type CreateSkillReportMutationResult =
  Apollo.MutationResult<CreateSkillReportMutation>;
export type CreateSkillReportMutationOptions = Apollo.BaseMutationOptions<
  CreateSkillReportMutation,
  CreateSkillReportMutationVariables
>;
export const DeleteSkillReportDocument = gql`
  mutation DeleteSkillReport($reportId: ID!) {
    deleteSkillReport(reportId: $reportId) {
      success
    }
  }
`;

/**
 * __useDeleteSkillReportMutation__
 *
 * To run a mutation, you first call `useDeleteSkillReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkillReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkillReportMutation, { data, loading, error }] = useDeleteSkillReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useDeleteSkillReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSkillReportMutation,
    DeleteSkillReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSkillReportMutation,
    DeleteSkillReportMutationVariables
  >(DeleteSkillReportDocument, options);
}
export type DeleteSkillReportMutationHookResult = ReturnType<
  typeof useDeleteSkillReportMutation
>;
export type DeleteSkillReportMutationResult =
  Apollo.MutationResult<DeleteSkillReportMutation>;
export type DeleteSkillReportMutationOptions = Apollo.BaseMutationOptions<
  DeleteSkillReportMutation,
  DeleteSkillReportMutationVariables
>;
export const DuplicateSkillReportDocument = gql`
  mutation DuplicateSkillReport($reportId: ID!) {
    duplicateSkillReport(reportId: $reportId) {
      skillReport {
        id
      }
    }
  }
`;

/**
 * __useDuplicateSkillReportMutation__
 *
 * To run a mutation, you first call `useDuplicateSkillReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateSkillReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateSkillReportMutation, { data, loading, error }] = useDuplicateSkillReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useDuplicateSkillReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateSkillReportMutation,
    DuplicateSkillReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateSkillReportMutation,
    DuplicateSkillReportMutationVariables
  >(DuplicateSkillReportDocument, options);
}
export type DuplicateSkillReportMutationHookResult = ReturnType<
  typeof useDuplicateSkillReportMutation
>;
export type DuplicateSkillReportMutationResult =
  Apollo.MutationResult<DuplicateSkillReportMutation>;
export type DuplicateSkillReportMutationOptions = Apollo.BaseMutationOptions<
  DuplicateSkillReportMutation,
  DuplicateSkillReportMutationVariables
>;
export const EditSkillReportDocument = gql`
  mutation EditSkillReport(
    $reportId: ID!
    $positionIds: [ID!]!
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $skills: [SkillReportSkillInput!]!
    $interviewStageFilters: JSONString!
    $minimumInterviewCountFilter: Int!
    $source: SkillReportSkillsSource!
    $title: String!
  ) {
    editSkillReport(
      reportId: $reportId
      positionIds: $positionIds
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      skills: $skills
      interviewStageFilters: $interviewStageFilters
      minimumInterviewCountFilter: $minimumInterviewCountFilter
      source: $source
      title: $title
    ) {
      skillReport {
        ...SkillsReportWithResults
      }
    }
  }
  ${SkillsReportWithResultsFragmentDoc}
`;

/**
 * __useEditSkillReportMutation__
 *
 * To run a mutation, you first call `useEditSkillReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSkillReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSkillReportMutation, { data, loading, error }] = useEditSkillReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      positionIds: // value for 'positionIds'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      skills: // value for 'skills'
 *      interviewStageFilters: // value for 'interviewStageFilters'
 *      minimumInterviewCountFilter: // value for 'minimumInterviewCountFilter'
 *      source: // value for 'source'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useEditSkillReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditSkillReportMutation,
    EditSkillReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditSkillReportMutation,
    EditSkillReportMutationVariables
  >(EditSkillReportDocument, options);
}
export type EditSkillReportMutationHookResult = ReturnType<
  typeof useEditSkillReportMutation
>;
export type EditSkillReportMutationResult =
  Apollo.MutationResult<EditSkillReportMutation>;
export type EditSkillReportMutationOptions = Apollo.BaseMutationOptions<
  EditSkillReportMutation,
  EditSkillReportMutationVariables
>;
export const CreateOrUpdateTaskDefinitionDocument = gql`
  mutation CreateOrUpdateTaskDefinition(
    $id: ID
    $type: TaskDefinitionType!
    $configurationJson: JSONString!
  ) {
    createOrUpdateTaskDefinition(
      id: $id
      type: $type
      configurationJson: $configurationJson
    ) {
      taskDefinition {
        ...TaskDefinition
      }
    }
  }
  ${TaskDefinitionFragmentDoc}
`;

/**
 * __useCreateOrUpdateTaskDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTaskDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTaskDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTaskDefinitionMutation, { data, loading, error }] = useCreateOrUpdateTaskDefinitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      configurationJson: // value for 'configurationJson'
 *   },
 * });
 */
export function useCreateOrUpdateTaskDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateTaskDefinitionMutation,
    CreateOrUpdateTaskDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateTaskDefinitionMutation,
    CreateOrUpdateTaskDefinitionMutationVariables
  >(CreateOrUpdateTaskDefinitionDocument, options);
}
export type CreateOrUpdateTaskDefinitionMutationHookResult = ReturnType<
  typeof useCreateOrUpdateTaskDefinitionMutation
>;
export type CreateOrUpdateTaskDefinitionMutationResult =
  Apollo.MutationResult<CreateOrUpdateTaskDefinitionMutation>;
export type CreateOrUpdateTaskDefinitionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateTaskDefinitionMutation,
    CreateOrUpdateTaskDefinitionMutationVariables
  >;
export const PreviewTaskRunDocument = gql`
  mutation PreviewTaskRun($id: ID!, $taskInputJson: JSONString!) {
    previewTaskRun(id: $id, taskInputJson: $taskInputJson) {
      taskRun {
        ...TaskRun
      }
    }
  }
  ${TaskRunFragmentDoc}
`;

/**
 * __usePreviewTaskRunMutation__
 *
 * To run a mutation, you first call `usePreviewTaskRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewTaskRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewTaskRunMutation, { data, loading, error }] = usePreviewTaskRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      taskInputJson: // value for 'taskInputJson'
 *   },
 * });
 */
export function usePreviewTaskRunMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PreviewTaskRunMutation,
    PreviewTaskRunMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PreviewTaskRunMutation,
    PreviewTaskRunMutationVariables
  >(PreviewTaskRunDocument, options);
}
export type PreviewTaskRunMutationHookResult = ReturnType<
  typeof usePreviewTaskRunMutation
>;
export type PreviewTaskRunMutationResult =
  Apollo.MutationResult<PreviewTaskRunMutation>;
export type PreviewTaskRunMutationOptions = Apollo.BaseMutationOptions<
  PreviewTaskRunMutation,
  PreviewTaskRunMutationVariables
>;
export const CreateCustomTopicDocument = gql`
  mutation CreateCustomTopic(
    $name: String!
    $keywords: [String!]!
    $matchRule: CustomTopicMatchRule!
  ) {
    createCustomTopic(name: $name, keywords: $keywords, matchRule: $matchRule) {
      topic {
        id
      }
    }
  }
`;

/**
 * __useCreateCustomTopicMutation__
 *
 * To run a mutation, you first call `useCreateCustomTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomTopicMutation, { data, loading, error }] = useCreateCustomTopicMutation({
 *   variables: {
 *      name: // value for 'name'
 *      keywords: // value for 'keywords'
 *      matchRule: // value for 'matchRule'
 *   },
 * });
 */
export function useCreateCustomTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomTopicMutation,
    CreateCustomTopicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomTopicMutation,
    CreateCustomTopicMutationVariables
  >(CreateCustomTopicDocument, options);
}
export type CreateCustomTopicMutationHookResult = ReturnType<
  typeof useCreateCustomTopicMutation
>;
export type CreateCustomTopicMutationResult =
  Apollo.MutationResult<CreateCustomTopicMutation>;
export type CreateCustomTopicMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomTopicMutation,
  CreateCustomTopicMutationVariables
>;
export const DeleteCustomTopicDocument = gql`
  mutation DeleteCustomTopic($topicId: ID!) {
    deleteCustomTopic(topicId: $topicId) {
      success
    }
  }
`;

/**
 * __useDeleteCustomTopicMutation__
 *
 * To run a mutation, you first call `useDeleteCustomTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomTopicMutation, { data, loading, error }] = useDeleteCustomTopicMutation({
 *   variables: {
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useDeleteCustomTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomTopicMutation,
    DeleteCustomTopicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCustomTopicMutation,
    DeleteCustomTopicMutationVariables
  >(DeleteCustomTopicDocument, options);
}
export type DeleteCustomTopicMutationHookResult = ReturnType<
  typeof useDeleteCustomTopicMutation
>;
export type DeleteCustomTopicMutationResult =
  Apollo.MutationResult<DeleteCustomTopicMutation>;
export type DeleteCustomTopicMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomTopicMutation,
  DeleteCustomTopicMutationVariables
>;
export const EditCustomTopicDocument = gql`
  mutation EditCustomTopic(
    $topicId: ID!
    $name: String!
    $keywords: [String!]!
    $matchRule: CustomTopicMatchRule!
  ) {
    editCustomTopic(
      topicId: $topicId
      name: $name
      keywords: $keywords
      matchRule: $matchRule
    ) {
      topic {
        id
        name
        keywords
        matchRule
      }
    }
  }
`;

/**
 * __useEditCustomTopicMutation__
 *
 * To run a mutation, you first call `useEditCustomTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomTopicMutation, { data, loading, error }] = useEditCustomTopicMutation({
 *   variables: {
 *      topicId: // value for 'topicId'
 *      name: // value for 'name'
 *      keywords: // value for 'keywords'
 *      matchRule: // value for 'matchRule'
 *   },
 * });
 */
export function useEditCustomTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditCustomTopicMutation,
    EditCustomTopicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditCustomTopicMutation,
    EditCustomTopicMutationVariables
  >(EditCustomTopicDocument, options);
}
export type EditCustomTopicMutationHookResult = ReturnType<
  typeof useEditCustomTopicMutation
>;
export type EditCustomTopicMutationResult =
  Apollo.MutationResult<EditCustomTopicMutation>;
export type EditCustomTopicMutationOptions = Apollo.BaseMutationOptions<
  EditCustomTopicMutation,
  EditCustomTopicMutationVariables
>;
export const AddClipsToTrainingProgramDocument = gql`
  mutation AddClipsToTrainingProgram(
    $trainingProgramId: ID!
    $description: String
    $callId: ID!
    $callTimestamp: Int
    $clipIds: [ID]!
  ) {
    addClipsToTrainingProgram(
      trainingProgramId: $trainingProgramId
      clipIds: $clipIds
      description: $description
      callId: $callId
      callTimestamp: $callTimestamp
    ) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useAddClipsToTrainingProgramMutation__
 *
 * To run a mutation, you first call `useAddClipsToTrainingProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClipsToTrainingProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClipsToTrainingProgramMutation, { data, loading, error }] = useAddClipsToTrainingProgramMutation({
 *   variables: {
 *      trainingProgramId: // value for 'trainingProgramId'
 *      description: // value for 'description'
 *      callId: // value for 'callId'
 *      callTimestamp: // value for 'callTimestamp'
 *      clipIds: // value for 'clipIds'
 *   },
 * });
 */
export function useAddClipsToTrainingProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddClipsToTrainingProgramMutation,
    AddClipsToTrainingProgramMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddClipsToTrainingProgramMutation,
    AddClipsToTrainingProgramMutationVariables
  >(AddClipsToTrainingProgramDocument, options);
}
export type AddClipsToTrainingProgramMutationHookResult = ReturnType<
  typeof useAddClipsToTrainingProgramMutation
>;
export type AddClipsToTrainingProgramMutationResult =
  Apollo.MutationResult<AddClipsToTrainingProgramMutation>;
export type AddClipsToTrainingProgramMutationOptions =
  Apollo.BaseMutationOptions<
    AddClipsToTrainingProgramMutation,
    AddClipsToTrainingProgramMutationVariables
  >;
export const AddTrainingProgramQuestionDocument = gql`
  mutation AddTrainingProgramQuestion(
    $trainingProgramId: ID!
    $question: String!
    $order: Int!
  ) {
    addTrainingProgramQuestion(
      trainingProgramId: $trainingProgramId
      question: $question
      order: $order
    ) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useAddTrainingProgramQuestionMutation__
 *
 * To run a mutation, you first call `useAddTrainingProgramQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingProgramQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingProgramQuestionMutation, { data, loading, error }] = useAddTrainingProgramQuestionMutation({
 *   variables: {
 *      trainingProgramId: // value for 'trainingProgramId'
 *      question: // value for 'question'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddTrainingProgramQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTrainingProgramQuestionMutation,
    AddTrainingProgramQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTrainingProgramQuestionMutation,
    AddTrainingProgramQuestionMutationVariables
  >(AddTrainingProgramQuestionDocument, options);
}
export type AddTrainingProgramQuestionMutationHookResult = ReturnType<
  typeof useAddTrainingProgramQuestionMutation
>;
export type AddTrainingProgramQuestionMutationResult =
  Apollo.MutationResult<AddTrainingProgramQuestionMutation>;
export type AddTrainingProgramQuestionMutationOptions =
  Apollo.BaseMutationOptions<
    AddTrainingProgramQuestionMutation,
    AddTrainingProgramQuestionMutationVariables
  >;
export const AddTrainingProgramTraineesDocument = gql`
  mutation AddTrainingProgramTrainees(
    $trainingProgramId: ID!
    $userIds: [ID!]!
  ) {
    addTrainingProgramTrainees(
      trainingProgramId: $trainingProgramId
      userIds: $userIds
    ) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useAddTrainingProgramTraineesMutation__
 *
 * To run a mutation, you first call `useAddTrainingProgramTraineesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingProgramTraineesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingProgramTraineesMutation, { data, loading, error }] = useAddTrainingProgramTraineesMutation({
 *   variables: {
 *      trainingProgramId: // value for 'trainingProgramId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useAddTrainingProgramTraineesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTrainingProgramTraineesMutation,
    AddTrainingProgramTraineesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTrainingProgramTraineesMutation,
    AddTrainingProgramTraineesMutationVariables
  >(AddTrainingProgramTraineesDocument, options);
}
export type AddTrainingProgramTraineesMutationHookResult = ReturnType<
  typeof useAddTrainingProgramTraineesMutation
>;
export type AddTrainingProgramTraineesMutationResult =
  Apollo.MutationResult<AddTrainingProgramTraineesMutation>;
export type AddTrainingProgramTraineesMutationOptions =
  Apollo.BaseMutationOptions<
    AddTrainingProgramTraineesMutation,
    AddTrainingProgramTraineesMutationVariables
  >;
export const AnswerTrainingProgramQuestionsDocument = gql`
  mutation AnswerTrainingProgramQuestions(
    $answers: [TrainingProgramAnswerInput!]!
  ) {
    answerTrainingProgramQuestions(answers: $answers) {
      success
    }
  }
`;

/**
 * __useAnswerTrainingProgramQuestionsMutation__
 *
 * To run a mutation, you first call `useAnswerTrainingProgramQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerTrainingProgramQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerTrainingProgramQuestionsMutation, { data, loading, error }] = useAnswerTrainingProgramQuestionsMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useAnswerTrainingProgramQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnswerTrainingProgramQuestionsMutation,
    AnswerTrainingProgramQuestionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnswerTrainingProgramQuestionsMutation,
    AnswerTrainingProgramQuestionsMutationVariables
  >(AnswerTrainingProgramQuestionsDocument, options);
}
export type AnswerTrainingProgramQuestionsMutationHookResult = ReturnType<
  typeof useAnswerTrainingProgramQuestionsMutation
>;
export type AnswerTrainingProgramQuestionsMutationResult =
  Apollo.MutationResult<AnswerTrainingProgramQuestionsMutation>;
export type AnswerTrainingProgramQuestionsMutationOptions =
  Apollo.BaseMutationOptions<
    AnswerTrainingProgramQuestionsMutation,
    AnswerTrainingProgramQuestionsMutationVariables
  >;
export const CreateTrainingProgramItemDocument = gql`
  mutation CreateTrainingProgramItem(
    $trainingProgramId: ID!
    $description: String
    $callId: ID!
    $callTimestamp: Int
    $clipId: ID
  ) {
    createTrainingProgramItem(
      trainingProgramId: $trainingProgramId
      clipId: $clipId
      description: $description
      callId: $callId
      callTimestamp: $callTimestamp
    ) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useCreateTrainingProgramItemMutation__
 *
 * To run a mutation, you first call `useCreateTrainingProgramItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainingProgramItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainingProgramItemMutation, { data, loading, error }] = useCreateTrainingProgramItemMutation({
 *   variables: {
 *      trainingProgramId: // value for 'trainingProgramId'
 *      description: // value for 'description'
 *      callId: // value for 'callId'
 *      callTimestamp: // value for 'callTimestamp'
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useCreateTrainingProgramItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTrainingProgramItemMutation,
    CreateTrainingProgramItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTrainingProgramItemMutation,
    CreateTrainingProgramItemMutationVariables
  >(CreateTrainingProgramItemDocument, options);
}
export type CreateTrainingProgramItemMutationHookResult = ReturnType<
  typeof useCreateTrainingProgramItemMutation
>;
export type CreateTrainingProgramItemMutationResult =
  Apollo.MutationResult<CreateTrainingProgramItemMutation>;
export type CreateTrainingProgramItemMutationOptions =
  Apollo.BaseMutationOptions<
    CreateTrainingProgramItemMutation,
    CreateTrainingProgramItemMutationVariables
  >;
export const CreateTrainingProgramDocument = gql`
  mutation CreateTrainingProgram($name: String!, $description: String) {
    createTrainingProgram(name: $name, description: $description) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useCreateTrainingProgramMutation__
 *
 * To run a mutation, you first call `useCreateTrainingProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainingProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainingProgramMutation, { data, loading, error }] = useCreateTrainingProgramMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateTrainingProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTrainingProgramMutation,
    CreateTrainingProgramMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTrainingProgramMutation,
    CreateTrainingProgramMutationVariables
  >(CreateTrainingProgramDocument, options);
}
export type CreateTrainingProgramMutationHookResult = ReturnType<
  typeof useCreateTrainingProgramMutation
>;
export type CreateTrainingProgramMutationResult =
  Apollo.MutationResult<CreateTrainingProgramMutation>;
export type CreateTrainingProgramMutationOptions = Apollo.BaseMutationOptions<
  CreateTrainingProgramMutation,
  CreateTrainingProgramMutationVariables
>;
export const DeleteAllTrainingProgramTraineesDocument = gql`
  mutation DeleteAllTrainingProgramTrainees($id: ID!) {
    deleteAllTrainingProgramTrainees(id: $id) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useDeleteAllTrainingProgramTraineesMutation__
 *
 * To run a mutation, you first call `useDeleteAllTrainingProgramTraineesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllTrainingProgramTraineesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllTrainingProgramTraineesMutation, { data, loading, error }] = useDeleteAllTrainingProgramTraineesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAllTrainingProgramTraineesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAllTrainingProgramTraineesMutation,
    DeleteAllTrainingProgramTraineesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAllTrainingProgramTraineesMutation,
    DeleteAllTrainingProgramTraineesMutationVariables
  >(DeleteAllTrainingProgramTraineesDocument, options);
}
export type DeleteAllTrainingProgramTraineesMutationHookResult = ReturnType<
  typeof useDeleteAllTrainingProgramTraineesMutation
>;
export type DeleteAllTrainingProgramTraineesMutationResult =
  Apollo.MutationResult<DeleteAllTrainingProgramTraineesMutation>;
export type DeleteAllTrainingProgramTraineesMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteAllTrainingProgramTraineesMutation,
    DeleteAllTrainingProgramTraineesMutationVariables
  >;
export const DeleteTrainingProgramItemDocument = gql`
  mutation DeleteTrainingProgramItem($id: ID!) {
    deleteTrainingProgramItem(id: $id) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useDeleteTrainingProgramItemMutation__
 *
 * To run a mutation, you first call `useDeleteTrainingProgramItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrainingProgramItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrainingProgramItemMutation, { data, loading, error }] = useDeleteTrainingProgramItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrainingProgramItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTrainingProgramItemMutation,
    DeleteTrainingProgramItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTrainingProgramItemMutation,
    DeleteTrainingProgramItemMutationVariables
  >(DeleteTrainingProgramItemDocument, options);
}
export type DeleteTrainingProgramItemMutationHookResult = ReturnType<
  typeof useDeleteTrainingProgramItemMutation
>;
export type DeleteTrainingProgramItemMutationResult =
  Apollo.MutationResult<DeleteTrainingProgramItemMutation>;
export type DeleteTrainingProgramItemMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteTrainingProgramItemMutation,
    DeleteTrainingProgramItemMutationVariables
  >;
export const DeleteTrainingProgramDocument = gql`
  mutation DeleteTrainingProgram($id: ID!) {
    deleteTrainingProgram(id: $id) {
      trainingProgramId
    }
  }
`;

/**
 * __useDeleteTrainingProgramMutation__
 *
 * To run a mutation, you first call `useDeleteTrainingProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrainingProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrainingProgramMutation, { data, loading, error }] = useDeleteTrainingProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrainingProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTrainingProgramMutation,
    DeleteTrainingProgramMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTrainingProgramMutation,
    DeleteTrainingProgramMutationVariables
  >(DeleteTrainingProgramDocument, options);
}
export type DeleteTrainingProgramMutationHookResult = ReturnType<
  typeof useDeleteTrainingProgramMutation
>;
export type DeleteTrainingProgramMutationResult =
  Apollo.MutationResult<DeleteTrainingProgramMutation>;
export type DeleteTrainingProgramMutationOptions = Apollo.BaseMutationOptions<
  DeleteTrainingProgramMutation,
  DeleteTrainingProgramMutationVariables
>;
export const DeleteTrainingProgramQuestionDocument = gql`
  mutation DeleteTrainingProgramQuestion($id: ID!) {
    deleteTrainingProgramQuestion(id: $id) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useDeleteTrainingProgramQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteTrainingProgramQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrainingProgramQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrainingProgramQuestionMutation, { data, loading, error }] = useDeleteTrainingProgramQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrainingProgramQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTrainingProgramQuestionMutation,
    DeleteTrainingProgramQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTrainingProgramQuestionMutation,
    DeleteTrainingProgramQuestionMutationVariables
  >(DeleteTrainingProgramQuestionDocument, options);
}
export type DeleteTrainingProgramQuestionMutationHookResult = ReturnType<
  typeof useDeleteTrainingProgramQuestionMutation
>;
export type DeleteTrainingProgramQuestionMutationResult =
  Apollo.MutationResult<DeleteTrainingProgramQuestionMutation>;
export type DeleteTrainingProgramQuestionMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteTrainingProgramQuestionMutation,
    DeleteTrainingProgramQuestionMutationVariables
  >;
export const DescribeTrainingProgramItemDocument = gql`
  mutation DescribeTrainingProgramItem($id: ID!, $description: String!) {
    describeTrainingProgramItem(id: $id, description: $description) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useDescribeTrainingProgramItemMutation__
 *
 * To run a mutation, you first call `useDescribeTrainingProgramItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDescribeTrainingProgramItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [describeTrainingProgramItemMutation, { data, loading, error }] = useDescribeTrainingProgramItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useDescribeTrainingProgramItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DescribeTrainingProgramItemMutation,
    DescribeTrainingProgramItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DescribeTrainingProgramItemMutation,
    DescribeTrainingProgramItemMutationVariables
  >(DescribeTrainingProgramItemDocument, options);
}
export type DescribeTrainingProgramItemMutationHookResult = ReturnType<
  typeof useDescribeTrainingProgramItemMutation
>;
export type DescribeTrainingProgramItemMutationResult =
  Apollo.MutationResult<DescribeTrainingProgramItemMutation>;
export type DescribeTrainingProgramItemMutationOptions =
  Apollo.BaseMutationOptions<
    DescribeTrainingProgramItemMutation,
    DescribeTrainingProgramItemMutationVariables
  >;
export const EnableTrainingProgramAssessmentDocument = gql`
  mutation EnableTrainingProgramAssessment($id: ID!, $enabled: Boolean!) {
    enableTrainingProgramAssessment(id: $id, enabled: $enabled) {
      trainingProgram {
        id
        assessmentEnabled
        trainingProgramQuestions {
          id
          createdAt
          updatedAt
          order
          question
        }
      }
    }
  }
`;

/**
 * __useEnableTrainingProgramAssessmentMutation__
 *
 * To run a mutation, you first call `useEnableTrainingProgramAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableTrainingProgramAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableTrainingProgramAssessmentMutation, { data, loading, error }] = useEnableTrainingProgramAssessmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useEnableTrainingProgramAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableTrainingProgramAssessmentMutation,
    EnableTrainingProgramAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EnableTrainingProgramAssessmentMutation,
    EnableTrainingProgramAssessmentMutationVariables
  >(EnableTrainingProgramAssessmentDocument, options);
}
export type EnableTrainingProgramAssessmentMutationHookResult = ReturnType<
  typeof useEnableTrainingProgramAssessmentMutation
>;
export type EnableTrainingProgramAssessmentMutationResult =
  Apollo.MutationResult<EnableTrainingProgramAssessmentMutation>;
export type EnableTrainingProgramAssessmentMutationOptions =
  Apollo.BaseMutationOptions<
    EnableTrainingProgramAssessmentMutation,
    EnableTrainingProgramAssessmentMutationVariables
  >;
export const LaunchTrainingProgramDocument = gql`
  mutation LaunchTrainingProgram($id: ID!) {
    launchTrainingProgram(id: $id) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useLaunchTrainingProgramMutation__
 *
 * To run a mutation, you first call `useLaunchTrainingProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchTrainingProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchTrainingProgramMutation, { data, loading, error }] = useLaunchTrainingProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLaunchTrainingProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LaunchTrainingProgramMutation,
    LaunchTrainingProgramMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LaunchTrainingProgramMutation,
    LaunchTrainingProgramMutationVariables
  >(LaunchTrainingProgramDocument, options);
}
export type LaunchTrainingProgramMutationHookResult = ReturnType<
  typeof useLaunchTrainingProgramMutation
>;
export type LaunchTrainingProgramMutationResult =
  Apollo.MutationResult<LaunchTrainingProgramMutation>;
export type LaunchTrainingProgramMutationOptions = Apollo.BaseMutationOptions<
  LaunchTrainingProgramMutation,
  LaunchTrainingProgramMutationVariables
>;
export const NameTrainingProgramDocument = gql`
  mutation NameTrainingProgram($id: ID!, $name: String!, $description: String) {
    nameTrainingProgram(id: $id, name: $name, description: $description) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useNameTrainingProgramMutation__
 *
 * To run a mutation, you first call `useNameTrainingProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNameTrainingProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nameTrainingProgramMutation, { data, loading, error }] = useNameTrainingProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useNameTrainingProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NameTrainingProgramMutation,
    NameTrainingProgramMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NameTrainingProgramMutation,
    NameTrainingProgramMutationVariables
  >(NameTrainingProgramDocument, options);
}
export type NameTrainingProgramMutationHookResult = ReturnType<
  typeof useNameTrainingProgramMutation
>;
export type NameTrainingProgramMutationResult =
  Apollo.MutationResult<NameTrainingProgramMutation>;
export type NameTrainingProgramMutationOptions = Apollo.BaseMutationOptions<
  NameTrainingProgramMutation,
  NameTrainingProgramMutationVariables
>;
export const ReorderTrainingProgramItemsDocument = gql`
  mutation ReorderTrainingProgramItems(
    $trainingProgramId: ID!
    $trainingProgramItemIds: [ID!]!
  ) {
    reorderTrainingProgramItems(
      trainingProgramId: $trainingProgramId
      trainingProgramItemIds: $trainingProgramItemIds
    ) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useReorderTrainingProgramItemsMutation__
 *
 * To run a mutation, you first call `useReorderTrainingProgramItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderTrainingProgramItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderTrainingProgramItemsMutation, { data, loading, error }] = useReorderTrainingProgramItemsMutation({
 *   variables: {
 *      trainingProgramId: // value for 'trainingProgramId'
 *      trainingProgramItemIds: // value for 'trainingProgramItemIds'
 *   },
 * });
 */
export function useReorderTrainingProgramItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderTrainingProgramItemsMutation,
    ReorderTrainingProgramItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderTrainingProgramItemsMutation,
    ReorderTrainingProgramItemsMutationVariables
  >(ReorderTrainingProgramItemsDocument, options);
}
export type ReorderTrainingProgramItemsMutationHookResult = ReturnType<
  typeof useReorderTrainingProgramItemsMutation
>;
export type ReorderTrainingProgramItemsMutationResult =
  Apollo.MutationResult<ReorderTrainingProgramItemsMutation>;
export type ReorderTrainingProgramItemsMutationOptions =
  Apollo.BaseMutationOptions<
    ReorderTrainingProgramItemsMutation,
    ReorderTrainingProgramItemsMutationVariables
  >;
export const ReorderTrainingProgramQuestionsDocument = gql`
  mutation ReorderTrainingProgramQuestions(
    $trainingProgramId: ID!
    $trainingProgramQuestionIds: [ID!]!
  ) {
    reorderTrainingProgramQuestions(
      trainingProgramId: $trainingProgramId
      trainingProgramQuestionIds: $trainingProgramQuestionIds
    ) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useReorderTrainingProgramQuestionsMutation__
 *
 * To run a mutation, you first call `useReorderTrainingProgramQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderTrainingProgramQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderTrainingProgramQuestionsMutation, { data, loading, error }] = useReorderTrainingProgramQuestionsMutation({
 *   variables: {
 *      trainingProgramId: // value for 'trainingProgramId'
 *      trainingProgramQuestionIds: // value for 'trainingProgramQuestionIds'
 *   },
 * });
 */
export function useReorderTrainingProgramQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderTrainingProgramQuestionsMutation,
    ReorderTrainingProgramQuestionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderTrainingProgramQuestionsMutation,
    ReorderTrainingProgramQuestionsMutationVariables
  >(ReorderTrainingProgramQuestionsDocument, options);
}
export type ReorderTrainingProgramQuestionsMutationHookResult = ReturnType<
  typeof useReorderTrainingProgramQuestionsMutation
>;
export type ReorderTrainingProgramQuestionsMutationResult =
  Apollo.MutationResult<ReorderTrainingProgramQuestionsMutation>;
export type ReorderTrainingProgramQuestionsMutationOptions =
  Apollo.BaseMutationOptions<
    ReorderTrainingProgramQuestionsMutation,
    ReorderTrainingProgramQuestionsMutationVariables
  >;
export const ResetTrainingProgramQuestionsDocument = gql`
  mutation ResetTrainingProgramQuestions($trainingProgramId: ID!) {
    resetTrainingProgramQuestions(trainingProgramId: $trainingProgramId) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useResetTrainingProgramQuestionsMutation__
 *
 * To run a mutation, you first call `useResetTrainingProgramQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTrainingProgramQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTrainingProgramQuestionsMutation, { data, loading, error }] = useResetTrainingProgramQuestionsMutation({
 *   variables: {
 *      trainingProgramId: // value for 'trainingProgramId'
 *   },
 * });
 */
export function useResetTrainingProgramQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetTrainingProgramQuestionsMutation,
    ResetTrainingProgramQuestionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetTrainingProgramQuestionsMutation,
    ResetTrainingProgramQuestionsMutationVariables
  >(ResetTrainingProgramQuestionsDocument, options);
}
export type ResetTrainingProgramQuestionsMutationHookResult = ReturnType<
  typeof useResetTrainingProgramQuestionsMutation
>;
export type ResetTrainingProgramQuestionsMutationResult =
  Apollo.MutationResult<ResetTrainingProgramQuestionsMutation>;
export type ResetTrainingProgramQuestionsMutationOptions =
  Apollo.BaseMutationOptions<
    ResetTrainingProgramQuestionsMutation,
    ResetTrainingProgramQuestionsMutationVariables
  >;
export const UpdateTrainingProgramQuestionDocument = gql`
  mutation UpdateTrainingProgramQuestion($id: ID!, $question: String!) {
    updateTrainingProgramQuestion(id: $id, question: $question) {
      trainingProgram {
        ...TrainingProgram
      }
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useUpdateTrainingProgramQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingProgramQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingProgramQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingProgramQuestionMutation, { data, loading, error }] = useUpdateTrainingProgramQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useUpdateTrainingProgramQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTrainingProgramQuestionMutation,
    UpdateTrainingProgramQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTrainingProgramQuestionMutation,
    UpdateTrainingProgramQuestionMutationVariables
  >(UpdateTrainingProgramQuestionDocument, options);
}
export type UpdateTrainingProgramQuestionMutationHookResult = ReturnType<
  typeof useUpdateTrainingProgramQuestionMutation
>;
export type UpdateTrainingProgramQuestionMutationResult =
  Apollo.MutationResult<UpdateTrainingProgramQuestionMutation>;
export type UpdateTrainingProgramQuestionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTrainingProgramQuestionMutation,
    UpdateTrainingProgramQuestionMutationVariables
  >;
export const ActivityFeedDocument = gql`
  query ActivityFeed(
    $pagination: CursorPaginationDetails
    $types: [ActivityType!]
  ) {
    activityFeed(pagination: $pagination, types: $types) {
      pageInfo {
        ...PageInfo
      }
      results {
        ...ActivityFeedListItem
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${ActivityFeedListItemFragmentDoc}
`;

/**
 * __useActivityFeedQuery__
 *
 * To run a query within a React component, call `useActivityFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityFeedQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useActivityFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActivityFeedQuery,
    ActivityFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityFeedQuery, ActivityFeedQueryVariables>(
    ActivityFeedDocument,
    options
  );
}
export function useActivityFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityFeedQuery,
    ActivityFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivityFeedQuery, ActivityFeedQueryVariables>(
    ActivityFeedDocument,
    options
  );
}
export type ActivityFeedQueryHookResult = ReturnType<
  typeof useActivityFeedQuery
>;
export type ActivityFeedLazyQueryHookResult = ReturnType<
  typeof useActivityFeedLazyQuery
>;
export type ActivityFeedQueryResult = Apollo.QueryResult<
  ActivityFeedQuery,
  ActivityFeedQueryVariables
>;
export const AllUsersWithAssignedCallGuidesDocument = gql`
  query AllUsersWithAssignedCallGuides($query: String) {
    currentUser {
      id
      organization {
        id
        usersWithGuideAssignments(query: $query) {
          id
          firstName
          lastName
          fullName
          email
          profilePicUrl
          callGuideAssignments {
            id
            dateAssigned
            callGuide {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAllUsersWithAssignedCallGuidesQuery__
 *
 * To run a query within a React component, call `useAllUsersWithAssignedCallGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersWithAssignedCallGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersWithAssignedCallGuidesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUsersWithAssignedCallGuidesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllUsersWithAssignedCallGuidesQuery,
    AllUsersWithAssignedCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllUsersWithAssignedCallGuidesQuery,
    AllUsersWithAssignedCallGuidesQueryVariables
  >(AllUsersWithAssignedCallGuidesDocument, options);
}
export function useAllUsersWithAssignedCallGuidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUsersWithAssignedCallGuidesQuery,
    AllUsersWithAssignedCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllUsersWithAssignedCallGuidesQuery,
    AllUsersWithAssignedCallGuidesQueryVariables
  >(AllUsersWithAssignedCallGuidesDocument, options);
}
export type AllUsersWithAssignedCallGuidesQueryHookResult = ReturnType<
  typeof useAllUsersWithAssignedCallGuidesQuery
>;
export type AllUsersWithAssignedCallGuidesLazyQueryHookResult = ReturnType<
  typeof useAllUsersWithAssignedCallGuidesLazyQuery
>;
export type AllUsersWithAssignedCallGuidesQueryResult = Apollo.QueryResult<
  AllUsersWithAssignedCallGuidesQuery,
  AllUsersWithAssignedCallGuidesQueryVariables
>;
export const AuditLogItemExistsDocument = gql`
  query AuditLogItemExists($id: ID!, $name: String!, $property: String!) {
    auditLogItemExists(id: $id, name: $name, property: $property)
  }
`;

/**
 * __useAuditLogItemExistsQuery__
 *
 * To run a query within a React component, call `useAuditLogItemExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogItemExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogItemExistsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      property: // value for 'property'
 *   },
 * });
 */
export function useAuditLogItemExistsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AuditLogItemExistsQuery,
    AuditLogItemExistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AuditLogItemExistsQuery,
    AuditLogItemExistsQueryVariables
  >(AuditLogItemExistsDocument, options);
}
export function useAuditLogItemExistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuditLogItemExistsQuery,
    AuditLogItemExistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AuditLogItemExistsQuery,
    AuditLogItemExistsQueryVariables
  >(AuditLogItemExistsDocument, options);
}
export type AuditLogItemExistsQueryHookResult = ReturnType<
  typeof useAuditLogItemExistsQuery
>;
export type AuditLogItemExistsLazyQueryHookResult = ReturnType<
  typeof useAuditLogItemExistsLazyQuery
>;
export type AuditLogItemExistsQueryResult = Apollo.QueryResult<
  AuditLogItemExistsQuery,
  AuditLogItemExistsQueryVariables
>;
export const AuditLogDocument = gql`
  query AuditLog {
    auditLog {
      user {
        id
        email
      }
      name
      details
      createdAt
    }
  }
`;

/**
 * __useAuditLogQuery__
 *
 * To run a query within a React component, call `useAuditLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuditLogQuery(
  baseOptions?: Apollo.QueryHookOptions<AuditLogQuery, AuditLogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditLogQuery, AuditLogQueryVariables>(
    AuditLogDocument,
    options
  );
}
export function useAuditLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuditLogQuery,
    AuditLogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditLogQuery, AuditLogQueryVariables>(
    AuditLogDocument,
    options
  );
}
export type AuditLogQueryHookResult = ReturnType<typeof useAuditLogQuery>;
export type AuditLogLazyQueryHookResult = ReturnType<
  typeof useAuditLogLazyQuery
>;
export type AuditLogQueryResult = Apollo.QueryResult<
  AuditLogQuery,
  AuditLogQueryVariables
>;
export const BrowserExtensionCallsDocument = gql`
  query BrowserExtensionCalls($tabUrl: String!) {
    browserExtensionCalls(tabUrl: $tabUrl) {
      id
      name
      duration
      status
      startTime
      createdAt
    }
  }
`;

/**
 * __useBrowserExtensionCallsQuery__
 *
 * To run a query within a React component, call `useBrowserExtensionCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowserExtensionCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowserExtensionCallsQuery({
 *   variables: {
 *      tabUrl: // value for 'tabUrl'
 *   },
 * });
 */
export function useBrowserExtensionCallsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BrowserExtensionCallsQuery,
    BrowserExtensionCallsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BrowserExtensionCallsQuery,
    BrowserExtensionCallsQueryVariables
  >(BrowserExtensionCallsDocument, options);
}
export function useBrowserExtensionCallsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BrowserExtensionCallsQuery,
    BrowserExtensionCallsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BrowserExtensionCallsQuery,
    BrowserExtensionCallsQueryVariables
  >(BrowserExtensionCallsDocument, options);
}
export type BrowserExtensionCallsQueryHookResult = ReturnType<
  typeof useBrowserExtensionCallsQuery
>;
export type BrowserExtensionCallsLazyQueryHookResult = ReturnType<
  typeof useBrowserExtensionCallsLazyQuery
>;
export type BrowserExtensionCallsQueryResult = Apollo.QueryResult<
  BrowserExtensionCallsQuery,
  BrowserExtensionCallsQueryVariables
>;
export const BrowserExtensionDataDocument = gql`
  query BrowserExtensionData(
    $tabUrl: String!
    $email: String
    $phone: String
    $intent: BrowserExtensionIntent
  ) {
    browserExtensionData(
      tabUrl: $tabUrl
      email: $email
      phone: $phone
      intent: $intent
    ) {
      ...BrowserExtensionData
    }
  }
  ${BrowserExtensionDataFragmentDoc}
`;

/**
 * __useBrowserExtensionDataQuery__
 *
 * To run a query within a React component, call `useBrowserExtensionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowserExtensionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowserExtensionDataQuery({
 *   variables: {
 *      tabUrl: // value for 'tabUrl'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      intent: // value for 'intent'
 *   },
 * });
 */
export function useBrowserExtensionDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    BrowserExtensionDataQuery,
    BrowserExtensionDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BrowserExtensionDataQuery,
    BrowserExtensionDataQueryVariables
  >(BrowserExtensionDataDocument, options);
}
export function useBrowserExtensionDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BrowserExtensionDataQuery,
    BrowserExtensionDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BrowserExtensionDataQuery,
    BrowserExtensionDataQueryVariables
  >(BrowserExtensionDataDocument, options);
}
export type BrowserExtensionDataQueryHookResult = ReturnType<
  typeof useBrowserExtensionDataQuery
>;
export type BrowserExtensionDataLazyQueryHookResult = ReturnType<
  typeof useBrowserExtensionDataLazyQuery
>;
export type BrowserExtensionDataQueryResult = Apollo.QueryResult<
  BrowserExtensionDataQuery,
  BrowserExtensionDataQueryVariables
>;
export const BullhornAuthDocument = gql`
  query BullhornAuth {
    bullhornAuth {
      isAuthorized
      authUrl
    }
  }
`;

/**
 * __useBullhornAuthQuery__
 *
 * To run a query within a React component, call `useBullhornAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useBullhornAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBullhornAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useBullhornAuthQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BullhornAuthQuery,
    BullhornAuthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BullhornAuthQuery, BullhornAuthQueryVariables>(
    BullhornAuthDocument,
    options
  );
}
export function useBullhornAuthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BullhornAuthQuery,
    BullhornAuthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BullhornAuthQuery, BullhornAuthQueryVariables>(
    BullhornAuthDocument,
    options
  );
}
export type BullhornAuthQueryHookResult = ReturnType<
  typeof useBullhornAuthQuery
>;
export type BullhornAuthLazyQueryHookResult = ReturnType<
  typeof useBullhornAuthLazyQuery
>;
export type BullhornAuthQueryResult = Apollo.QueryResult<
  BullhornAuthQuery,
  BullhornAuthQueryVariables
>;
export const BullhornCommentActionsDocument = gql`
  query BullhornCommentActions {
    bullhornCommentActions
  }
`;

/**
 * __useBullhornCommentActionsQuery__
 *
 * To run a query within a React component, call `useBullhornCommentActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBullhornCommentActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBullhornCommentActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBullhornCommentActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BullhornCommentActionsQuery,
    BullhornCommentActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BullhornCommentActionsQuery,
    BullhornCommentActionsQueryVariables
  >(BullhornCommentActionsDocument, options);
}
export function useBullhornCommentActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BullhornCommentActionsQuery,
    BullhornCommentActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BullhornCommentActionsQuery,
    BullhornCommentActionsQueryVariables
  >(BullhornCommentActionsDocument, options);
}
export type BullhornCommentActionsQueryHookResult = ReturnType<
  typeof useBullhornCommentActionsQuery
>;
export type BullhornCommentActionsLazyQueryHookResult = ReturnType<
  typeof useBullhornCommentActionsLazyQuery
>;
export type BullhornCommentActionsQueryResult = Apollo.QueryResult<
  BullhornCommentActionsQuery,
  BullhornCommentActionsQueryVariables
>;
export const CallGuideDocument = gql`
  query CallGuide($id: ID!) {
    callGuide(id: $id) {
      ...CallGuide
    }
  }
  ${CallGuideFragmentDoc}
`;

/**
 * __useCallGuideQuery__
 *
 * To run a query within a React component, call `useCallGuideQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallGuideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallGuideQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCallGuideQuery(
  baseOptions: Apollo.QueryHookOptions<CallGuideQuery, CallGuideQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallGuideQuery, CallGuideQueryVariables>(
    CallGuideDocument,
    options
  );
}
export function useCallGuideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallGuideQuery,
    CallGuideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallGuideQuery, CallGuideQueryVariables>(
    CallGuideDocument,
    options
  );
}
export type CallGuideQueryHookResult = ReturnType<typeof useCallGuideQuery>;
export type CallGuideLazyQueryHookResult = ReturnType<
  typeof useCallGuideLazyQuery
>;
export type CallGuideQueryResult = Apollo.QueryResult<
  CallGuideQuery,
  CallGuideQueryVariables
>;
export const CallInProgressDocument = gql`
  query CallInProgress {
    callInProgress {
      id
      type
      phoneNumber
      candidate {
        fullName
      }
    }
  }
`;

/**
 * __useCallInProgressQuery__
 *
 * To run a query within a React component, call `useCallInProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallInProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallInProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useCallInProgressQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CallInProgressQuery,
    CallInProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallInProgressQuery, CallInProgressQueryVariables>(
    CallInProgressDocument,
    options
  );
}
export function useCallInProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallInProgressQuery,
    CallInProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallInProgressQuery, CallInProgressQueryVariables>(
    CallInProgressDocument,
    options
  );
}
export type CallInProgressQueryHookResult = ReturnType<
  typeof useCallInProgressQuery
>;
export type CallInProgressLazyQueryHookResult = ReturnType<
  typeof useCallInProgressLazyQuery
>;
export type CallInProgressQueryResult = Apollo.QueryResult<
  CallInProgressQuery,
  CallInProgressQueryVariables
>;
export const CallDocument = gql`
  query Call(
    $id: ID!
    $twilioCallSid: String
    $notesForCurrentUserOnly: Boolean
  ) {
    call(id: $id, twilioCallSid: $twilioCallSid) {
      ...Call
      autoSummary
      iaVersion
      canEdit
      canDelete
      canEditNotes
      canShare
      canShareExternal
      visibleVisibilityLevels
      coverImageUrl
      callClips {
        ...Clip
      }
      clipsCount
      greenhouseScorecardLinkWithId @client
      leverInterviewLinkWithId @client
      questions {
        id
        description
        time
        questionNotes {
          ...CallNote
        }
      }
      generalNotes {
        ...CallNote
      }
      externalViewers {
        id
        email
      }
    }
  }
  ${CallFragmentDoc}
  ${ClipFragmentDoc}
  ${CallNoteFragmentDoc}
`;

/**
 * __useCallQuery__
 *
 * To run a query within a React component, call `useCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallQuery({
 *   variables: {
 *      id: // value for 'id'
 *      twilioCallSid: // value for 'twilioCallSid'
 *      notesForCurrentUserOnly: // value for 'notesForCurrentUserOnly'
 *   },
 * });
 */
export function useCallQuery(
  baseOptions: Apollo.QueryHookOptions<CallQuery, CallQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallQuery, CallQueryVariables>(CallDocument, options);
}
export function useCallLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CallQuery, CallQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallQuery, CallQueryVariables>(
    CallDocument,
    options
  );
}
export type CallQueryHookResult = ReturnType<typeof useCallQuery>;
export type CallLazyQueryHookResult = ReturnType<typeof useCallLazyQuery>;
export type CallQueryResult = Apollo.QueryResult<CallQuery, CallQueryVariables>;
export const CallShareDocument = gql`
  query CallShare($id: ID!, $forPlaylist: Boolean = false) {
    call(id: $id) {
      id
      name
      candidate {
        id
        fullName
      }
      canShare
      canShareExternal
      shareableUsers {
        ...ShareUser
      }
      trainingProgramCount @skip(if: $forPlaylist)
      callShares @skip(if: $forPlaylist) {
        ...CallShare
      }
      externalCallShares @skip(if: $forPlaylist) {
        ...ExternalCallShare
      }
      visibleTo @skip(if: $forPlaylist) {
        ...RecordingVisibleTo
      }
    }
  }
  ${ShareUserFragmentDoc}
  ${CallShareFragmentDoc}
  ${ExternalCallShareFragmentDoc}
  ${RecordingVisibleToFragmentDoc}
`;

/**
 * __useCallShareQuery__
 *
 * To run a query within a React component, call `useCallShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallShareQuery({
 *   variables: {
 *      id: // value for 'id'
 *      forPlaylist: // value for 'forPlaylist'
 *   },
 * });
 */
export function useCallShareQuery(
  baseOptions: Apollo.QueryHookOptions<CallShareQuery, CallShareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallShareQuery, CallShareQueryVariables>(
    CallShareDocument,
    options
  );
}
export function useCallShareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallShareQuery,
    CallShareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallShareQuery, CallShareQueryVariables>(
    CallShareDocument,
    options
  );
}
export type CallShareQueryHookResult = ReturnType<typeof useCallShareQuery>;
export type CallShareLazyQueryHookResult = ReturnType<
  typeof useCallShareLazyQuery
>;
export type CallShareQueryResult = Apollo.QueryResult<
  CallShareQuery,
  CallShareQueryVariables
>;
export const CallSpeakerOptionsDocument = gql`
  query CallSpeakerOptions($id: ID!) {
    call(id: $id) {
      id
      speakerOptions {
        id
        label
        profilePicUrl
      }
    }
  }
`;

/**
 * __useCallSpeakerOptionsQuery__
 *
 * To run a query within a React component, call `useCallSpeakerOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallSpeakerOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallSpeakerOptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCallSpeakerOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallSpeakerOptionsQuery,
    CallSpeakerOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CallSpeakerOptionsQuery,
    CallSpeakerOptionsQueryVariables
  >(CallSpeakerOptionsDocument, options);
}
export function useCallSpeakerOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallSpeakerOptionsQuery,
    CallSpeakerOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CallSpeakerOptionsQuery,
    CallSpeakerOptionsQueryVariables
  >(CallSpeakerOptionsDocument, options);
}
export type CallSpeakerOptionsQueryHookResult = ReturnType<
  typeof useCallSpeakerOptionsQuery
>;
export type CallSpeakerOptionsLazyQueryHookResult = ReturnType<
  typeof useCallSpeakerOptionsLazyQuery
>;
export type CallSpeakerOptionsQueryResult = Apollo.QueryResult<
  CallSpeakerOptionsQuery,
  CallSpeakerOptionsQueryVariables
>;
export const CallTokenDocument = gql`
  query CallToken {
    callToken
  }
`;

/**
 * __useCallTokenQuery__
 *
 * To run a query within a React component, call `useCallTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useCallTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<CallTokenQuery, CallTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallTokenQuery, CallTokenQueryVariables>(
    CallTokenDocument,
    options
  );
}
export function useCallTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallTokenQuery,
    CallTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallTokenQuery, CallTokenQueryVariables>(
    CallTokenDocument,
    options
  );
}
export type CallTokenQueryHookResult = ReturnType<typeof useCallTokenQuery>;
export type CallTokenLazyQueryHookResult = ReturnType<
  typeof useCallTokenLazyQuery
>;
export type CallTokenQueryResult = Apollo.QueryResult<
  CallTokenQuery,
  CallTokenQueryVariables
>;
export const CallTrackerSegmentDocument = gql`
  query CallTrackerSegment(
    $id: ID!
    $trackerKeywordId: String
    $trackerGroupId: String
  ) {
    call(id: $id) {
      id
      canEdit
      type
      speakers {
        ...CallSpeaker
      }
      trackerSegments(
        trackerKeywordId: $trackerKeywordId
        trackerGroupId: $trackerGroupId
      ) {
        keyword
        segments {
          ...TranscriptSegment
        }
      }
    }
  }
  ${CallSpeakerFragmentDoc}
  ${TranscriptSegmentFragmentDoc}
`;

/**
 * __useCallTrackerSegmentQuery__
 *
 * To run a query within a React component, call `useCallTrackerSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallTrackerSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallTrackerSegmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      trackerKeywordId: // value for 'trackerKeywordId'
 *      trackerGroupId: // value for 'trackerGroupId'
 *   },
 * });
 */
export function useCallTrackerSegmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallTrackerSegmentQuery,
    CallTrackerSegmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CallTrackerSegmentQuery,
    CallTrackerSegmentQueryVariables
  >(CallTrackerSegmentDocument, options);
}
export function useCallTrackerSegmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallTrackerSegmentQuery,
    CallTrackerSegmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CallTrackerSegmentQuery,
    CallTrackerSegmentQueryVariables
  >(CallTrackerSegmentDocument, options);
}
export type CallTrackerSegmentQueryHookResult = ReturnType<
  typeof useCallTrackerSegmentQuery
>;
export type CallTrackerSegmentLazyQueryHookResult = ReturnType<
  typeof useCallTrackerSegmentLazyQuery
>;
export type CallTrackerSegmentQueryResult = Apollo.QueryResult<
  CallTrackerSegmentQuery,
  CallTrackerSegmentQueryVariables
>;
export const CallTrackersDocument = gql`
  query CallTrackers($callId: ID!) {
    call(id: $callId) {
      id
      speakers {
        ...CallSpeaker
      }
      trackers {
        ...TrackerGroup
      }
    }
  }
  ${CallSpeakerFragmentDoc}
  ${TrackerGroupFragmentDoc}
`;

/**
 * __useCallTrackersQuery__
 *
 * To run a query within a React component, call `useCallTrackersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallTrackersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallTrackersQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCallTrackersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallTrackersQuery,
    CallTrackersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallTrackersQuery, CallTrackersQueryVariables>(
    CallTrackersDocument,
    options
  );
}
export function useCallTrackersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallTrackersQuery,
    CallTrackersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallTrackersQuery, CallTrackersQueryVariables>(
    CallTrackersDocument,
    options
  );
}
export type CallTrackersQueryHookResult = ReturnType<
  typeof useCallTrackersQuery
>;
export type CallTrackersLazyQueryHookResult = ReturnType<
  typeof useCallTrackersLazyQuery
>;
export type CallTrackersQueryResult = Apollo.QueryResult<
  CallTrackersQuery,
  CallTrackersQueryVariables
>;
export const CallTranscriptDocument = gql`
  query CallTranscript($id: ID!, $notesForCurrentUserOnly: Boolean = false) {
    call(id: $id) {
      ...CallTranscript
    }
  }
  ${CallTranscriptFragmentDoc}
`;

/**
 * __useCallTranscriptQuery__
 *
 * To run a query within a React component, call `useCallTranscriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallTranscriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallTranscriptQuery({
 *   variables: {
 *      id: // value for 'id'
 *      notesForCurrentUserOnly: // value for 'notesForCurrentUserOnly'
 *   },
 * });
 */
export function useCallTranscriptQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallTranscriptQuery,
    CallTranscriptQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallTranscriptQuery, CallTranscriptQueryVariables>(
    CallTranscriptDocument,
    options
  );
}
export function useCallTranscriptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallTranscriptQuery,
    CallTranscriptQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallTranscriptQuery, CallTranscriptQueryVariables>(
    CallTranscriptDocument,
    options
  );
}
export type CallTranscriptQueryHookResult = ReturnType<
  typeof useCallTranscriptQuery
>;
export type CallTranscriptLazyQueryHookResult = ReturnType<
  typeof useCallTranscriptLazyQuery
>;
export type CallTranscriptQueryResult = Apollo.QueryResult<
  CallTranscriptQuery,
  CallTranscriptQueryVariables
>;
export const CallsByIdDocument = gql`
  query CallsById($callIds: [ID!]) {
    callsById(callIds: $callIds) {
      id
      name
      duration
      status
      startTime
      createdAt
    }
  }
`;

/**
 * __useCallsByIdQuery__
 *
 * To run a query within a React component, call `useCallsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsByIdQuery({
 *   variables: {
 *      callIds: // value for 'callIds'
 *   },
 * });
 */
export function useCallsByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<CallsByIdQuery, CallsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallsByIdQuery, CallsByIdQueryVariables>(
    CallsByIdDocument,
    options
  );
}
export function useCallsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallsByIdQuery,
    CallsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallsByIdQuery, CallsByIdQueryVariables>(
    CallsByIdDocument,
    options
  );
}
export type CallsByIdQueryHookResult = ReturnType<typeof useCallsByIdQuery>;
export type CallsByIdLazyQueryHookResult = ReturnType<
  typeof useCallsByIdLazyQuery
>;
export type CallsByIdQueryResult = Apollo.QueryResult<
  CallsByIdQuery,
  CallsByIdQueryVariables
>;
export const CandidateAlertFeedDocument = gql`
  query CandidateAlertFeed(
    $pagination: CursorPaginationDetails
    $alertFilters: [CandidateAlertFeedFilter!]
  ) {
    candidateAlertFeed(pagination: $pagination, alertFilters: $alertFilters) {
      id
      pageInfo {
        ...PageInfo
      }
      results {
        ...CandidateAlertFeedListItem
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${CandidateAlertFeedListItemFragmentDoc}
`;

/**
 * __useCandidateAlertFeedQuery__
 *
 * To run a query within a React component, call `useCandidateAlertFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateAlertFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateAlertFeedQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      alertFilters: // value for 'alertFilters'
 *   },
 * });
 */
export function useCandidateAlertFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CandidateAlertFeedQuery,
    CandidateAlertFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateAlertFeedQuery,
    CandidateAlertFeedQueryVariables
  >(CandidateAlertFeedDocument, options);
}
export function useCandidateAlertFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateAlertFeedQuery,
    CandidateAlertFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateAlertFeedQuery,
    CandidateAlertFeedQueryVariables
  >(CandidateAlertFeedDocument, options);
}
export type CandidateAlertFeedQueryHookResult = ReturnType<
  typeof useCandidateAlertFeedQuery
>;
export type CandidateAlertFeedLazyQueryHookResult = ReturnType<
  typeof useCandidateAlertFeedLazyQuery
>;
export type CandidateAlertFeedQueryResult = Apollo.QueryResult<
  CandidateAlertFeedQuery,
  CandidateAlertFeedQueryVariables
>;
export const CandidateAlertDocument = gql`
  query CandidateAlert(
    $candidateId: ID!
    $pagination: CursorPaginationDetails
  ) {
    candidateAlert(candidateId: $candidateId, pagination: $pagination) {
      id
      pageInfo {
        ...PageInfo
      }
      results {
        ...CandidateAlertListItem
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${CandidateAlertListItemFragmentDoc}
`;

/**
 * __useCandidateAlertQuery__
 *
 * To run a query within a React component, call `useCandidateAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateAlertQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCandidateAlertQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateAlertQuery,
    CandidateAlertQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidateAlertQuery, CandidateAlertQueryVariables>(
    CandidateAlertDocument,
    options
  );
}
export function useCandidateAlertLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateAlertQuery,
    CandidateAlertQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CandidateAlertQuery, CandidateAlertQueryVariables>(
    CandidateAlertDocument,
    options
  );
}
export type CandidateAlertQueryHookResult = ReturnType<
  typeof useCandidateAlertQuery
>;
export type CandidateAlertLazyQueryHookResult = ReturnType<
  typeof useCandidateAlertLazyQuery
>;
export type CandidateAlertQueryResult = Apollo.QueryResult<
  CandidateAlertQuery,
  CandidateAlertQueryVariables
>;
export const CandidateAlertUserSubscriptionsDocument = gql`
  query CandidateAlertUserSubscriptions {
    candidateAlertUserSubscriptions {
      id
      candidateSubscriptions {
        id
        candidateId
        mutedAt
      }
      positionSubscriptions {
        id
        positionId
        mutedAt
      }
    }
  }
`;

/**
 * __useCandidateAlertUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useCandidateAlertUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateAlertUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateAlertUserSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCandidateAlertUserSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CandidateAlertUserSubscriptionsQuery,
    CandidateAlertUserSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateAlertUserSubscriptionsQuery,
    CandidateAlertUserSubscriptionsQueryVariables
  >(CandidateAlertUserSubscriptionsDocument, options);
}
export function useCandidateAlertUserSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateAlertUserSubscriptionsQuery,
    CandidateAlertUserSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateAlertUserSubscriptionsQuery,
    CandidateAlertUserSubscriptionsQueryVariables
  >(CandidateAlertUserSubscriptionsDocument, options);
}
export type CandidateAlertUserSubscriptionsQueryHookResult = ReturnType<
  typeof useCandidateAlertUserSubscriptionsQuery
>;
export type CandidateAlertUserSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useCandidateAlertUserSubscriptionsLazyQuery
>;
export type CandidateAlertUserSubscriptionsQueryResult = Apollo.QueryResult<
  CandidateAlertUserSubscriptionsQuery,
  CandidateAlertUserSubscriptionsQueryVariables
>;
export const CandidateAskDocument = gql`
  query CandidateAsk($candidateId: ID!, $text: String!) {
    candidateAsk(candidateId: $candidateId, text: $text) {
      id
      response
    }
  }
`;

/**
 * __useCandidateAskQuery__
 *
 * To run a query within a React component, call `useCandidateAskQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateAskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateAskQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCandidateAskQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateAskQuery,
    CandidateAskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidateAskQuery, CandidateAskQueryVariables>(
    CandidateAskDocument,
    options
  );
}
export function useCandidateAskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateAskQuery,
    CandidateAskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CandidateAskQuery, CandidateAskQueryVariables>(
    CandidateAskDocument,
    options
  );
}
export type CandidateAskQueryHookResult = ReturnType<
  typeof useCandidateAskQuery
>;
export type CandidateAskLazyQueryHookResult = ReturnType<
  typeof useCandidateAskLazyQuery
>;
export type CandidateAskQueryResult = Apollo.QueryResult<
  CandidateAskQuery,
  CandidateAskQueryVariables
>;
export const CandidateByPhoneDocument = gql`
  query CandidateByPhone($phoneNumber: String!) {
    candidateByPhone(phoneNumber: $phoneNumber) {
      id
      firstName
      lastName
    }
  }
`;

/**
 * __useCandidateByPhoneQuery__
 *
 * To run a query within a React component, call `useCandidateByPhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateByPhoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateByPhoneQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useCandidateByPhoneQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateByPhoneQuery,
    CandidateByPhoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidateByPhoneQuery, CandidateByPhoneQueryVariables>(
    CandidateByPhoneDocument,
    options
  );
}
export function useCandidateByPhoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateByPhoneQuery,
    CandidateByPhoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateByPhoneQuery,
    CandidateByPhoneQueryVariables
  >(CandidateByPhoneDocument, options);
}
export type CandidateByPhoneQueryHookResult = ReturnType<
  typeof useCandidateByPhoneQuery
>;
export type CandidateByPhoneLazyQueryHookResult = ReturnType<
  typeof useCandidateByPhoneLazyQuery
>;
export type CandidateByPhoneQueryResult = Apollo.QueryResult<
  CandidateByPhoneQuery,
  CandidateByPhoneQueryVariables
>;
export const CandidateCompareCandidatesDocument = gql`
  query CandidateCompareCandidates($id: ID!) {
    position(id: $id) {
      id
      candidates {
        id
        callCount
        fullName
        validCallsCount
      }
    }
  }
`;

/**
 * __useCandidateCompareCandidatesQuery__
 *
 * To run a query within a React component, call `useCandidateCompareCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateCompareCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateCompareCandidatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateCompareCandidatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateCompareCandidatesQuery,
    CandidateCompareCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateCompareCandidatesQuery,
    CandidateCompareCandidatesQueryVariables
  >(CandidateCompareCandidatesDocument, options);
}
export function useCandidateCompareCandidatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateCompareCandidatesQuery,
    CandidateCompareCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateCompareCandidatesQuery,
    CandidateCompareCandidatesQueryVariables
  >(CandidateCompareCandidatesDocument, options);
}
export type CandidateCompareCandidatesQueryHookResult = ReturnType<
  typeof useCandidateCompareCandidatesQuery
>;
export type CandidateCompareCandidatesLazyQueryHookResult = ReturnType<
  typeof useCandidateCompareCandidatesLazyQuery
>;
export type CandidateCompareCandidatesQueryResult = Apollo.QueryResult<
  CandidateCompareCandidatesQuery,
  CandidateCompareCandidatesQueryVariables
>;
export const CandidateEmailOptOutDocument = gql`
  query CandidateEmailOptOut($orgId: ID!, $verificationCode: String!) {
    candidateEmailOptOut(orgId: $orgId, verificationCode: $verificationCode) {
      verificationCode
      recordingDisabled
      organizationName
      videoUrls
    }
  }
`;

/**
 * __useCandidateEmailOptOutQuery__
 *
 * To run a query within a React component, call `useCandidateEmailOptOutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateEmailOptOutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateEmailOptOutQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useCandidateEmailOptOutQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateEmailOptOutQuery,
    CandidateEmailOptOutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateEmailOptOutQuery,
    CandidateEmailOptOutQueryVariables
  >(CandidateEmailOptOutDocument, options);
}
export function useCandidateEmailOptOutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateEmailOptOutQuery,
    CandidateEmailOptOutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateEmailOptOutQuery,
    CandidateEmailOptOutQueryVariables
  >(CandidateEmailOptOutDocument, options);
}
export type CandidateEmailOptOutQueryHookResult = ReturnType<
  typeof useCandidateEmailOptOutQuery
>;
export type CandidateEmailOptOutLazyQueryHookResult = ReturnType<
  typeof useCandidateEmailOptOutLazyQuery
>;
export type CandidateEmailOptOutQueryResult = Apollo.QueryResult<
  CandidateEmailOptOutQuery,
  CandidateEmailOptOutQueryVariables
>;
export const CandidateLastCallByInterviewerPositionDocument = gql`
  query CandidateLastCallByInterviewerPosition($id: ID!, $positionId: ID!) {
    candidate(id: $id) {
      id
      lastCallByInterviewerPosition(positionId: $positionId) {
        id
        greenhouseScorecardLink
      }
    }
  }
`;

/**
 * __useCandidateLastCallByInterviewerPositionQuery__
 *
 * To run a query within a React component, call `useCandidateLastCallByInterviewerPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateLastCallByInterviewerPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateLastCallByInterviewerPositionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useCandidateLastCallByInterviewerPositionQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateLastCallByInterviewerPositionQuery,
    CandidateLastCallByInterviewerPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateLastCallByInterviewerPositionQuery,
    CandidateLastCallByInterviewerPositionQueryVariables
  >(CandidateLastCallByInterviewerPositionDocument, options);
}
export function useCandidateLastCallByInterviewerPositionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateLastCallByInterviewerPositionQuery,
    CandidateLastCallByInterviewerPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateLastCallByInterviewerPositionQuery,
    CandidateLastCallByInterviewerPositionQueryVariables
  >(CandidateLastCallByInterviewerPositionDocument, options);
}
export type CandidateLastCallByInterviewerPositionQueryHookResult = ReturnType<
  typeof useCandidateLastCallByInterviewerPositionQuery
>;
export type CandidateLastCallByInterviewerPositionLazyQueryHookResult =
  ReturnType<typeof useCandidateLastCallByInterviewerPositionLazyQuery>;
export type CandidateLastCallByInterviewerPositionQueryResult =
  Apollo.QueryResult<
    CandidateLastCallByInterviewerPositionQuery,
    CandidateLastCallByInterviewerPositionQueryVariables
  >;
export const CandidateNamesDocument = gql`
  query CandidateNames($pagination: OffsetPaginationDetails) {
    candidates(pagination: $pagination) {
      pageInfo {
        totalPages
        totalRows
      }
      results {
        id
        fullName
      }
    }
  }
`;

/**
 * __useCandidateNamesQuery__
 *
 * To run a query within a React component, call `useCandidateNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateNamesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCandidateNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CandidateNamesQuery,
    CandidateNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidateNamesQuery, CandidateNamesQueryVariables>(
    CandidateNamesDocument,
    options
  );
}
export function useCandidateNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateNamesQuery,
    CandidateNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CandidateNamesQuery, CandidateNamesQueryVariables>(
    CandidateNamesDocument,
    options
  );
}
export type CandidateNamesQueryHookResult = ReturnType<
  typeof useCandidateNamesQuery
>;
export type CandidateNamesLazyQueryHookResult = ReturnType<
  typeof useCandidateNamesLazyQuery
>;
export type CandidateNamesQueryResult = Apollo.QueryResult<
  CandidateNamesQuery,
  CandidateNamesQueryVariables
>;
export const CandidatePositionHighlightsDocument = gql`
  query CandidatePositionHighlights(
    $id: ID!
    $positionId: ID
    $currentUserOnly: Boolean
  ) {
    candidate(id: $id) {
      id
      callsByPosition(positionId: $positionId) {
        ...CandidateHighlight
      }
    }
  }
  ${CandidateHighlightFragmentDoc}
`;

/**
 * __useCandidatePositionHighlightsQuery__
 *
 * To run a query within a React component, call `useCandidatePositionHighlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatePositionHighlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatePositionHighlightsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      positionId: // value for 'positionId'
 *      currentUserOnly: // value for 'currentUserOnly'
 *   },
 * });
 */
export function useCandidatePositionHighlightsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidatePositionHighlightsQuery,
    CandidatePositionHighlightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidatePositionHighlightsQuery,
    CandidatePositionHighlightsQueryVariables
  >(CandidatePositionHighlightsDocument, options);
}
export function useCandidatePositionHighlightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatePositionHighlightsQuery,
    CandidatePositionHighlightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidatePositionHighlightsQuery,
    CandidatePositionHighlightsQueryVariables
  >(CandidatePositionHighlightsDocument, options);
}
export type CandidatePositionHighlightsQueryHookResult = ReturnType<
  typeof useCandidatePositionHighlightsQuery
>;
export type CandidatePositionHighlightsLazyQueryHookResult = ReturnType<
  typeof useCandidatePositionHighlightsLazyQuery
>;
export type CandidatePositionHighlightsQueryResult = Apollo.QueryResult<
  CandidatePositionHighlightsQuery,
  CandidatePositionHighlightsQueryVariables
>;
export const CandidatePositionInterviewsDocument = gql`
  query CandidatePositionInterviews(
    $id: ID!
    $positionId: ID
    $currentUserOnly: Boolean
  ) {
    candidate(id: $id) {
      id
      callsByPosition(positionId: $positionId) {
        ...CandidateInterview
      }
      scheduledInterviewsByPosition(positionId: $positionId) {
        ...ScheduledInterviewListItem
      }
    }
  }
  ${CandidateInterviewFragmentDoc}
  ${ScheduledInterviewListItemFragmentDoc}
`;

/**
 * __useCandidatePositionInterviewsQuery__
 *
 * To run a query within a React component, call `useCandidatePositionInterviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatePositionInterviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatePositionInterviewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      positionId: // value for 'positionId'
 *      currentUserOnly: // value for 'currentUserOnly'
 *   },
 * });
 */
export function useCandidatePositionInterviewsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidatePositionInterviewsQuery,
    CandidatePositionInterviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidatePositionInterviewsQuery,
    CandidatePositionInterviewsQueryVariables
  >(CandidatePositionInterviewsDocument, options);
}
export function useCandidatePositionInterviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatePositionInterviewsQuery,
    CandidatePositionInterviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidatePositionInterviewsQuery,
    CandidatePositionInterviewsQueryVariables
  >(CandidatePositionInterviewsDocument, options);
}
export type CandidatePositionInterviewsQueryHookResult = ReturnType<
  typeof useCandidatePositionInterviewsQuery
>;
export type CandidatePositionInterviewsLazyQueryHookResult = ReturnType<
  typeof useCandidatePositionInterviewsLazyQuery
>;
export type CandidatePositionInterviewsQueryResult = Apollo.QueryResult<
  CandidatePositionInterviewsQuery,
  CandidatePositionInterviewsQueryVariables
>;
export const CandidateDocument = gql`
  query Candidate($id: ID!) {
    candidate(id: $id) {
      ...Candidate
    }
  }
  ${CandidateFragmentDoc}
`;

/**
 * __useCandidateQuery__
 *
 * To run a query within a React component, call `useCandidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateQuery(
  baseOptions: Apollo.QueryHookOptions<CandidateQuery, CandidateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidateQuery, CandidateQueryVariables>(
    CandidateDocument,
    options
  );
}
export function useCandidateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateQuery,
    CandidateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CandidateQuery, CandidateQueryVariables>(
    CandidateDocument,
    options
  );
}
export type CandidateQueryHookResult = ReturnType<typeof useCandidateQuery>;
export type CandidateLazyQueryHookResult = ReturnType<
  typeof useCandidateLazyQuery
>;
export type CandidateQueryResult = Apollo.QueryResult<
  CandidateQuery,
  CandidateQueryVariables
>;
export const CandidateRediscoveryProspectsDocument = gql`
  query CandidateRediscoveryProspects($positionId: ID!) {
    candidateRediscoveryProspects(positionId: $positionId) {
      prospects {
        candidateId
        countSkillsMet
        candidateName
        linkedinUrl
        hasAtsData
        lastAppliedPositionId
        lastAppliedPosition
        lastAppliedDate
        lastInterviewStage
        rejectionReason
        output {
          tldr
          categories {
            categoryName
            tldr
            indicesSkillsMet
            bullets
          }
        }
      }
    }
  }
`;

/**
 * __useCandidateRediscoveryProspectsQuery__
 *
 * To run a query within a React component, call `useCandidateRediscoveryProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateRediscoveryProspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateRediscoveryProspectsQuery({
 *   variables: {
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useCandidateRediscoveryProspectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateRediscoveryProspectsQuery,
    CandidateRediscoveryProspectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateRediscoveryProspectsQuery,
    CandidateRediscoveryProspectsQueryVariables
  >(CandidateRediscoveryProspectsDocument, options);
}
export function useCandidateRediscoveryProspectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateRediscoveryProspectsQuery,
    CandidateRediscoveryProspectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateRediscoveryProspectsQuery,
    CandidateRediscoveryProspectsQueryVariables
  >(CandidateRediscoveryProspectsDocument, options);
}
export type CandidateRediscoveryProspectsQueryHookResult = ReturnType<
  typeof useCandidateRediscoveryProspectsQuery
>;
export type CandidateRediscoveryProspectsLazyQueryHookResult = ReturnType<
  typeof useCandidateRediscoveryProspectsLazyQuery
>;
export type CandidateRediscoveryProspectsQueryResult = Apollo.QueryResult<
  CandidateRediscoveryProspectsQuery,
  CandidateRediscoveryProspectsQueryVariables
>;
export const CandidatesDocument = gql`
  query Candidates(
    $pagination: OffsetPaginationDetails
    $includeScores: Boolean = false
  ) {
    candidates(pagination: $pagination) {
      pageInfo {
        totalPages
        totalRows
      }
      results {
        ...CandidatePageListItem
      }
    }
  }
  ${CandidatePageListItemFragmentDoc}
`;

/**
 * __useCandidatesQuery__
 *
 * To run a query within a React component, call `useCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      includeScores: // value for 'includeScores'
 *   },
 * });
 */
export function useCandidatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CandidatesQuery,
    CandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidatesQuery, CandidatesQueryVariables>(
    CandidatesDocument,
    options
  );
}
export function useCandidatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatesQuery,
    CandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CandidatesQuery, CandidatesQueryVariables>(
    CandidatesDocument,
    options
  );
}
export type CandidatesQueryHookResult = ReturnType<typeof useCandidatesQuery>;
export type CandidatesLazyQueryHookResult = ReturnType<
  typeof useCandidatesLazyQuery
>;
export type CandidatesQueryResult = Apollo.QueryResult<
  CandidatesQuery,
  CandidatesQueryVariables
>;
export const ClipDocument = gql`
  query Clip($clipId: ID!, $notesForCurrentUserOnly: Boolean) {
    clip(clipId: $clipId) {
      ...ViewableClip
    }
  }
  ${ViewableClipFragmentDoc}
`;

/**
 * __useClipQuery__
 *
 * To run a query within a React component, call `useClipQuery` and pass it any options that fit your needs.
 * When your component renders, `useClipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClipQuery({
 *   variables: {
 *      clipId: // value for 'clipId'
 *      notesForCurrentUserOnly: // value for 'notesForCurrentUserOnly'
 *   },
 * });
 */
export function useClipQuery(
  baseOptions: Apollo.QueryHookOptions<ClipQuery, ClipQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClipQuery, ClipQueryVariables>(ClipDocument, options);
}
export function useClipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClipQuery, ClipQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClipQuery, ClipQueryVariables>(
    ClipDocument,
    options
  );
}
export type ClipQueryHookResult = ReturnType<typeof useClipQuery>;
export type ClipLazyQueryHookResult = ReturnType<typeof useClipLazyQuery>;
export type ClipQueryResult = Apollo.QueryResult<ClipQuery, ClipQueryVariables>;
export const ClipShareDocument = gql`
  query ClipShare($clipId: ID!) {
    clip(clipId: $clipId) {
      id
      canShare
      canShareExternal
      trainingProgramCount
      shareableUsers {
        ...ShareUser
      }
      clipShares {
        ...ClipShare
      }
      externalClipShares {
        ...ExternalClipShare
      }
      visibleTo {
        ...RecordingVisibleTo
      }
    }
  }
  ${ShareUserFragmentDoc}
  ${ClipShareFragmentDoc}
  ${ExternalClipShareFragmentDoc}
  ${RecordingVisibleToFragmentDoc}
`;

/**
 * __useClipShareQuery__
 *
 * To run a query within a React component, call `useClipShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useClipShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClipShareQuery({
 *   variables: {
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useClipShareQuery(
  baseOptions: Apollo.QueryHookOptions<ClipShareQuery, ClipShareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClipShareQuery, ClipShareQueryVariables>(
    ClipShareDocument,
    options
  );
}
export function useClipShareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClipShareQuery,
    ClipShareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClipShareQuery, ClipShareQueryVariables>(
    ClipShareDocument,
    options
  );
}
export type ClipShareQueryHookResult = ReturnType<typeof useClipShareQuery>;
export type ClipShareLazyQueryHookResult = ReturnType<
  typeof useClipShareLazyQuery
>;
export type ClipShareQueryResult = Apollo.QueryResult<
  ClipShareQuery,
  ClipShareQueryVariables
>;
export const ClipSpeakerOptionsDocument = gql`
  query ClipSpeakerOptions($clipId: ID!) {
    clip(clipId: $clipId) {
      id
      callSpeakerOptions {
        id
        label
      }
    }
  }
`;

/**
 * __useClipSpeakerOptionsQuery__
 *
 * To run a query within a React component, call `useClipSpeakerOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClipSpeakerOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClipSpeakerOptionsQuery({
 *   variables: {
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useClipSpeakerOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClipSpeakerOptionsQuery,
    ClipSpeakerOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClipSpeakerOptionsQuery,
    ClipSpeakerOptionsQueryVariables
  >(ClipSpeakerOptionsDocument, options);
}
export function useClipSpeakerOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClipSpeakerOptionsQuery,
    ClipSpeakerOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClipSpeakerOptionsQuery,
    ClipSpeakerOptionsQueryVariables
  >(ClipSpeakerOptionsDocument, options);
}
export type ClipSpeakerOptionsQueryHookResult = ReturnType<
  typeof useClipSpeakerOptionsQuery
>;
export type ClipSpeakerOptionsLazyQueryHookResult = ReturnType<
  typeof useClipSpeakerOptionsLazyQuery
>;
export type ClipSpeakerOptionsQueryResult = Apollo.QueryResult<
  ClipSpeakerOptionsQuery,
  ClipSpeakerOptionsQueryVariables
>;
export const ClipTrackerSegmentDocument = gql`
  query ClipTrackerSegment(
    $clipId: ID!
    $trackerKeywordId: String
    $trackerGroupId: String
  ) {
    clip(clipId: $clipId) {
      id
      callType
      callSpeakers {
        ...CallSpeaker
      }
      trackerSegments(
        trackerKeywordId: $trackerKeywordId
        trackerGroupId: $trackerGroupId
      ) {
        keyword
        segments {
          ...TranscriptSegment
        }
      }
    }
  }
  ${CallSpeakerFragmentDoc}
  ${TranscriptSegmentFragmentDoc}
`;

/**
 * __useClipTrackerSegmentQuery__
 *
 * To run a query within a React component, call `useClipTrackerSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useClipTrackerSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClipTrackerSegmentQuery({
 *   variables: {
 *      clipId: // value for 'clipId'
 *      trackerKeywordId: // value for 'trackerKeywordId'
 *      trackerGroupId: // value for 'trackerGroupId'
 *   },
 * });
 */
export function useClipTrackerSegmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClipTrackerSegmentQuery,
    ClipTrackerSegmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClipTrackerSegmentQuery,
    ClipTrackerSegmentQueryVariables
  >(ClipTrackerSegmentDocument, options);
}
export function useClipTrackerSegmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClipTrackerSegmentQuery,
    ClipTrackerSegmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClipTrackerSegmentQuery,
    ClipTrackerSegmentQueryVariables
  >(ClipTrackerSegmentDocument, options);
}
export type ClipTrackerSegmentQueryHookResult = ReturnType<
  typeof useClipTrackerSegmentQuery
>;
export type ClipTrackerSegmentLazyQueryHookResult = ReturnType<
  typeof useClipTrackerSegmentLazyQuery
>;
export type ClipTrackerSegmentQueryResult = Apollo.QueryResult<
  ClipTrackerSegmentQuery,
  ClipTrackerSegmentQueryVariables
>;
export const ClipTrackersDocument = gql`
  query ClipTrackers($clipId: ID!) {
    clip(clipId: $clipId) {
      id
      callSpeakers {
        ...CallSpeaker
      }
      trackers {
        ...TrackerGroup
      }
    }
  }
  ${CallSpeakerFragmentDoc}
  ${TrackerGroupFragmentDoc}
`;

/**
 * __useClipTrackersQuery__
 *
 * To run a query within a React component, call `useClipTrackersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClipTrackersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClipTrackersQuery({
 *   variables: {
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useClipTrackersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClipTrackersQuery,
    ClipTrackersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClipTrackersQuery, ClipTrackersQueryVariables>(
    ClipTrackersDocument,
    options
  );
}
export function useClipTrackersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClipTrackersQuery,
    ClipTrackersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClipTrackersQuery, ClipTrackersQueryVariables>(
    ClipTrackersDocument,
    options
  );
}
export type ClipTrackersQueryHookResult = ReturnType<
  typeof useClipTrackersQuery
>;
export type ClipTrackersLazyQueryHookResult = ReturnType<
  typeof useClipTrackersLazyQuery
>;
export type ClipTrackersQueryResult = Apollo.QueryResult<
  ClipTrackersQuery,
  ClipTrackersQueryVariables
>;
export const ClipTranscriptDocument = gql`
  query ClipTranscript($clipId: ID!, $notesForCurrentUserOnly: Boolean) {
    clip(clipId: $clipId) {
      ...ClipTranscript
    }
  }
  ${ClipTranscriptFragmentDoc}
`;

/**
 * __useClipTranscriptQuery__
 *
 * To run a query within a React component, call `useClipTranscriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useClipTranscriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClipTranscriptQuery({
 *   variables: {
 *      clipId: // value for 'clipId'
 *      notesForCurrentUserOnly: // value for 'notesForCurrentUserOnly'
 *   },
 * });
 */
export function useClipTranscriptQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClipTranscriptQuery,
    ClipTranscriptQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClipTranscriptQuery, ClipTranscriptQueryVariables>(
    ClipTranscriptDocument,
    options
  );
}
export function useClipTranscriptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClipTranscriptQuery,
    ClipTranscriptQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClipTranscriptQuery, ClipTranscriptQueryVariables>(
    ClipTranscriptDocument,
    options
  );
}
export type ClipTranscriptQueryHookResult = ReturnType<
  typeof useClipTranscriptQuery
>;
export type ClipTranscriptLazyQueryHookResult = ReturnType<
  typeof useClipTranscriptLazyQuery
>;
export type ClipTranscriptQueryResult = Apollo.QueryResult<
  ClipTranscriptQuery,
  ClipTranscriptQueryVariables
>;
export const CurrentUserAiSummaryTemplatesDocument = gql`
  query CurrentUserAiSummaryTemplates($additionalTemplateId: ID) {
    currentUser {
      id
      callAiSummaryTemplates(additionalTemplateId: $additionalTemplateId) {
        id
        name
        isCreatorDefault
        sections {
          id
          title
          prompt
        }
        customType
        customPrompt
        model
        createdBy
        visibility
        isCurrentUserDefault
        defaultFor
        defaultForPersona
        defaultForKeywords
        defaultForMeetingType
        canEdit
        canDelete
      }
    }
  }
`;

/**
 * __useCurrentUserAiSummaryTemplatesQuery__
 *
 * To run a query within a React component, call `useCurrentUserAiSummaryTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserAiSummaryTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserAiSummaryTemplatesQuery({
 *   variables: {
 *      additionalTemplateId: // value for 'additionalTemplateId'
 *   },
 * });
 */
export function useCurrentUserAiSummaryTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserAiSummaryTemplatesQuery,
    CurrentUserAiSummaryTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserAiSummaryTemplatesQuery,
    CurrentUserAiSummaryTemplatesQueryVariables
  >(CurrentUserAiSummaryTemplatesDocument, options);
}
export function useCurrentUserAiSummaryTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserAiSummaryTemplatesQuery,
    CurrentUserAiSummaryTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserAiSummaryTemplatesQuery,
    CurrentUserAiSummaryTemplatesQueryVariables
  >(CurrentUserAiSummaryTemplatesDocument, options);
}
export type CurrentUserAiSummaryTemplatesQueryHookResult = ReturnType<
  typeof useCurrentUserAiSummaryTemplatesQuery
>;
export type CurrentUserAiSummaryTemplatesLazyQueryHookResult = ReturnType<
  typeof useCurrentUserAiSummaryTemplatesLazyQuery
>;
export type CurrentUserAiSummaryTemplatesQueryResult = Apollo.QueryResult<
  CurrentUserAiSummaryTemplatesQuery,
  CurrentUserAiSummaryTemplatesQueryVariables
>;
export const CurrentUserCalendarDocument = gql`
  query CurrentUserCalendar($authOnly: Boolean = false) {
    currentUser {
      id
      calendars @skip(if: $authOnly) {
        id
        resourceId
        name
        videoMeetingImportStrategy
        canSetVideoMeetingImportStrategy
        primary
      }
      organization @skip(if: $authOnly) {
        id
        isZoomAuthorized
        isZoomBotAuthorized
        googleMeetEnabled
        zoomNotetakerEnabled
      }
      googleUserId
      isCalendarAuthorized
      isGcalAuthorized
      isMsftCalAuthorized
    }
  }
`;

/**
 * __useCurrentUserCalendarQuery__
 *
 * To run a query within a React component, call `useCurrentUserCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserCalendarQuery({
 *   variables: {
 *      authOnly: // value for 'authOnly'
 *   },
 * });
 */
export function useCurrentUserCalendarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserCalendarQuery,
    CurrentUserCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserCalendarQuery,
    CurrentUserCalendarQueryVariables
  >(CurrentUserCalendarDocument, options);
}
export function useCurrentUserCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserCalendarQuery,
    CurrentUserCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserCalendarQuery,
    CurrentUserCalendarQueryVariables
  >(CurrentUserCalendarDocument, options);
}
export type CurrentUserCalendarQueryHookResult = ReturnType<
  typeof useCurrentUserCalendarQuery
>;
export type CurrentUserCalendarLazyQueryHookResult = ReturnType<
  typeof useCurrentUserCalendarLazyQuery
>;
export type CurrentUserCalendarQueryResult = Apollo.QueryResult<
  CurrentUserCalendarQuery,
  CurrentUserCalendarQueryVariables
>;
export const CurrentUserCallGuidesDocument = gql`
  query CurrentUserCallGuides($query: String, $limit: Int) {
    currentUser {
      id
      callGuides(query: $query, limit: $limit) {
        ...CallGuideListItem
      }
    }
  }
  ${CallGuideListItemFragmentDoc}
`;

/**
 * __useCurrentUserCallGuidesQuery__
 *
 * To run a query within a React component, call `useCurrentUserCallGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserCallGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserCallGuidesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCurrentUserCallGuidesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserCallGuidesQuery,
    CurrentUserCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserCallGuidesQuery,
    CurrentUserCallGuidesQueryVariables
  >(CurrentUserCallGuidesDocument, options);
}
export function useCurrentUserCallGuidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserCallGuidesQuery,
    CurrentUserCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserCallGuidesQuery,
    CurrentUserCallGuidesQueryVariables
  >(CurrentUserCallGuidesDocument, options);
}
export type CurrentUserCallGuidesQueryHookResult = ReturnType<
  typeof useCurrentUserCallGuidesQuery
>;
export type CurrentUserCallGuidesLazyQueryHookResult = ReturnType<
  typeof useCurrentUserCallGuidesLazyQuery
>;
export type CurrentUserCallGuidesQueryResult = Apollo.QueryResult<
  CurrentUserCallGuidesQuery,
  CurrentUserCallGuidesQueryVariables
>;
export const CurrentUserCallingMetricsDocument = gql`
  query CurrentUserCallingMetrics($start: Date!, $end: Date) {
    currentUser {
      id
      ...UserCallingMetrics
    }
  }
  ${UserCallingMetricsFragmentDoc}
`;

/**
 * __useCurrentUserCallingMetricsQuery__
 *
 * To run a query within a React component, call `useCurrentUserCallingMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserCallingMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserCallingMetricsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useCurrentUserCallingMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentUserCallingMetricsQuery,
    CurrentUserCallingMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserCallingMetricsQuery,
    CurrentUserCallingMetricsQueryVariables
  >(CurrentUserCallingMetricsDocument, options);
}
export function useCurrentUserCallingMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserCallingMetricsQuery,
    CurrentUserCallingMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserCallingMetricsQuery,
    CurrentUserCallingMetricsQueryVariables
  >(CurrentUserCallingMetricsDocument, options);
}
export type CurrentUserCallingMetricsQueryHookResult = ReturnType<
  typeof useCurrentUserCallingMetricsQuery
>;
export type CurrentUserCallingMetricsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserCallingMetricsLazyQuery
>;
export type CurrentUserCallingMetricsQueryResult = Apollo.QueryResult<
  CurrentUserCallingMetricsQuery,
  CurrentUserCallingMetricsQueryVariables
>;
export const CurrentUserCallsDocument = gql`
  query CurrentUserCalls(
    $start: DateTime
    $end: DateTime
    $positionId: String
    $pagination: CursorPaginationDetails
  ) {
    currentUser {
      id
      organization {
        id
        googleMeetEnabled
        isZoomAuthorized
        zoomNotetakerEnabled
      }
      calls(
        start: $start
        end: $end
        positionId: $positionId
        pagination: $pagination
      ) {
        pageInfo {
          ...PageInfo
        }
        results {
          ...CallListItem
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${CallListItemFragmentDoc}
`;

/**
 * __useCurrentUserCallsQuery__
 *
 * To run a query within a React component, call `useCurrentUserCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserCallsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      positionId: // value for 'positionId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCurrentUserCallsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserCallsQuery,
    CurrentUserCallsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserCallsQuery, CurrentUserCallsQueryVariables>(
    CurrentUserCallsDocument,
    options
  );
}
export function useCurrentUserCallsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserCallsQuery,
    CurrentUserCallsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserCallsQuery,
    CurrentUserCallsQueryVariables
  >(CurrentUserCallsDocument, options);
}
export type CurrentUserCallsQueryHookResult = ReturnType<
  typeof useCurrentUserCallsQuery
>;
export type CurrentUserCallsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserCallsLazyQuery
>;
export type CurrentUserCallsQueryResult = Apollo.QueryResult<
  CurrentUserCallsQuery,
  CurrentUserCallsQueryVariables
>;
export const CurrentUserClipsDocument = gql`
  query CurrentUserClips($pagination: OffsetPaginationDetails) {
    currentUser {
      id
      clips(pagination: $pagination) {
        pageInfo {
          totalPages
          totalRows
        }
        results {
          ...ClipListItem
        }
      }
    }
  }
  ${ClipListItemFragmentDoc}
`;

/**
 * __useCurrentUserClipsQuery__
 *
 * To run a query within a React component, call `useCurrentUserClipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserClipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserClipsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCurrentUserClipsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserClipsQuery,
    CurrentUserClipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserClipsQuery, CurrentUserClipsQueryVariables>(
    CurrentUserClipsDocument,
    options
  );
}
export function useCurrentUserClipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserClipsQuery,
    CurrentUserClipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserClipsQuery,
    CurrentUserClipsQueryVariables
  >(CurrentUserClipsDocument, options);
}
export type CurrentUserClipsQueryHookResult = ReturnType<
  typeof useCurrentUserClipsQuery
>;
export type CurrentUserClipsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserClipsLazyQuery
>;
export type CurrentUserClipsQueryResult = Apollo.QueryResult<
  CurrentUserClipsQuery,
  CurrentUserClipsQueryVariables
>;
export const CurrentUserEventImportKeywordsDocument = gql`
  query CurrentUserEventImportKeywords {
    currentUser {
      id
      eventImportKeywords {
        ...EventImportKeyword
      }
    }
  }
  ${EventImportKeywordFragmentDoc}
`;

/**
 * __useCurrentUserEventImportKeywordsQuery__
 *
 * To run a query within a React component, call `useCurrentUserEventImportKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserEventImportKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserEventImportKeywordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserEventImportKeywordsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserEventImportKeywordsQuery,
    CurrentUserEventImportKeywordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserEventImportKeywordsQuery,
    CurrentUserEventImportKeywordsQueryVariables
  >(CurrentUserEventImportKeywordsDocument, options);
}
export function useCurrentUserEventImportKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserEventImportKeywordsQuery,
    CurrentUserEventImportKeywordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserEventImportKeywordsQuery,
    CurrentUserEventImportKeywordsQueryVariables
  >(CurrentUserEventImportKeywordsDocument, options);
}
export type CurrentUserEventImportKeywordsQueryHookResult = ReturnType<
  typeof useCurrentUserEventImportKeywordsQuery
>;
export type CurrentUserEventImportKeywordsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserEventImportKeywordsLazyQuery
>;
export type CurrentUserEventImportKeywordsQueryResult = Apollo.QueryResult<
  CurrentUserEventImportKeywordsQuery,
  CurrentUserEventImportKeywordsQueryVariables
>;
export const CurrentUserEventsDocument = gql`
  query CurrentUserEvents {
    currentUser {
      id
      isCalendarAuthorized
      events {
        ...EventListItem
      }
    }
  }
  ${EventListItemFragmentDoc}
`;

/**
 * __useCurrentUserEventsQuery__
 *
 * To run a query within a React component, call `useCurrentUserEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserEventsQuery,
    CurrentUserEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserEventsQuery,
    CurrentUserEventsQueryVariables
  >(CurrentUserEventsDocument, options);
}
export function useCurrentUserEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserEventsQuery,
    CurrentUserEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserEventsQuery,
    CurrentUserEventsQueryVariables
  >(CurrentUserEventsDocument, options);
}
export type CurrentUserEventsQueryHookResult = ReturnType<
  typeof useCurrentUserEventsQuery
>;
export type CurrentUserEventsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserEventsLazyQuery
>;
export type CurrentUserEventsQueryResult = Apollo.QueryResult<
  CurrentUserEventsQuery,
  CurrentUserEventsQueryVariables
>;
export const CurrentUserFreemiumScheduledInterviewsDocument = gql`
  query CurrentUserFreemiumScheduledInterviews(
    $start: DateTime
    $end: DateTime
    $positionId: String
    $pagination: CursorPaginationDetails
  ) {
    currentUser {
      id
      scheduledInterviews(
        start: $start
        end: $end
        positionId: $positionId
        pagination: $pagination
      ) {
        pageInfo {
          ...PageInfo
        }
        results {
          ...FreemiumScheduledInterviewListItem
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${FreemiumScheduledInterviewListItemFragmentDoc}
`;

/**
 * __useCurrentUserFreemiumScheduledInterviewsQuery__
 *
 * To run a query within a React component, call `useCurrentUserFreemiumScheduledInterviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserFreemiumScheduledInterviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserFreemiumScheduledInterviewsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      positionId: // value for 'positionId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCurrentUserFreemiumScheduledInterviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserFreemiumScheduledInterviewsQuery,
    CurrentUserFreemiumScheduledInterviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserFreemiumScheduledInterviewsQuery,
    CurrentUserFreemiumScheduledInterviewsQueryVariables
  >(CurrentUserFreemiumScheduledInterviewsDocument, options);
}
export function useCurrentUserFreemiumScheduledInterviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserFreemiumScheduledInterviewsQuery,
    CurrentUserFreemiumScheduledInterviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserFreemiumScheduledInterviewsQuery,
    CurrentUserFreemiumScheduledInterviewsQueryVariables
  >(CurrentUserFreemiumScheduledInterviewsDocument, options);
}
export type CurrentUserFreemiumScheduledInterviewsQueryHookResult = ReturnType<
  typeof useCurrentUserFreemiumScheduledInterviewsQuery
>;
export type CurrentUserFreemiumScheduledInterviewsLazyQueryHookResult =
  ReturnType<typeof useCurrentUserFreemiumScheduledInterviewsLazyQuery>;
export type CurrentUserFreemiumScheduledInterviewsQueryResult =
  Apollo.QueryResult<
    CurrentUserFreemiumScheduledInterviewsQuery,
    CurrentUserFreemiumScheduledInterviewsQueryVariables
  >;
export const CurrentUserInternationalPhoneVoipNumbersDocument = gql`
  query CurrentUserInternationalPhoneVoipNumbers {
    currentUser {
      id
      organization {
        id
        internationalPhoneVoipNumbers {
          countryCode
          orgPhoneNumber
        }
      }
    }
  }
`;

/**
 * __useCurrentUserInternationalPhoneVoipNumbersQuery__
 *
 * To run a query within a React component, call `useCurrentUserInternationalPhoneVoipNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserInternationalPhoneVoipNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserInternationalPhoneVoipNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserInternationalPhoneVoipNumbersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserInternationalPhoneVoipNumbersQuery,
    CurrentUserInternationalPhoneVoipNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserInternationalPhoneVoipNumbersQuery,
    CurrentUserInternationalPhoneVoipNumbersQueryVariables
  >(CurrentUserInternationalPhoneVoipNumbersDocument, options);
}
export function useCurrentUserInternationalPhoneVoipNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserInternationalPhoneVoipNumbersQuery,
    CurrentUserInternationalPhoneVoipNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserInternationalPhoneVoipNumbersQuery,
    CurrentUserInternationalPhoneVoipNumbersQueryVariables
  >(CurrentUserInternationalPhoneVoipNumbersDocument, options);
}
export type CurrentUserInternationalPhoneVoipNumbersQueryHookResult =
  ReturnType<typeof useCurrentUserInternationalPhoneVoipNumbersQuery>;
export type CurrentUserInternationalPhoneVoipNumbersLazyQueryHookResult =
  ReturnType<typeof useCurrentUserInternationalPhoneVoipNumbersLazyQuery>;
export type CurrentUserInternationalPhoneVoipNumbersQueryResult =
  Apollo.QueryResult<
    CurrentUserInternationalPhoneVoipNumbersQuery,
    CurrentUserInternationalPhoneVoipNumbersQueryVariables
  >;
export const CurrentUserInterviewImportRuleDocument = gql`
  query CurrentUserInterviewImportRule {
    currentUser {
      id
      interviewImportRule
    }
  }
`;

/**
 * __useCurrentUserInterviewImportRuleQuery__
 *
 * To run a query within a React component, call `useCurrentUserInterviewImportRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserInterviewImportRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserInterviewImportRuleQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserInterviewImportRuleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserInterviewImportRuleQuery,
    CurrentUserInterviewImportRuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserInterviewImportRuleQuery,
    CurrentUserInterviewImportRuleQueryVariables
  >(CurrentUserInterviewImportRuleDocument, options);
}
export function useCurrentUserInterviewImportRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserInterviewImportRuleQuery,
    CurrentUserInterviewImportRuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserInterviewImportRuleQuery,
    CurrentUserInterviewImportRuleQueryVariables
  >(CurrentUserInterviewImportRuleDocument, options);
}
export type CurrentUserInterviewImportRuleQueryHookResult = ReturnType<
  typeof useCurrentUserInterviewImportRuleQuery
>;
export type CurrentUserInterviewImportRuleLazyQueryHookResult = ReturnType<
  typeof useCurrentUserInterviewImportRuleLazyQuery
>;
export type CurrentUserInterviewImportRuleQueryResult = Apollo.QueryResult<
  CurrentUserInterviewImportRuleQuery,
  CurrentUserInterviewImportRuleQueryVariables
>;
export const CurrentUserMyCallSummaryTemplatesDocument = gql`
  query CurrentUserMyCallSummaryTemplates(
    $pagination: OffsetPaginationDetails
  ) {
    currentUser {
      id
      organization {
        id
        callAiSummaryTemplates(
          pagination: $pagination
          currentUserOnly: true
          sharedOnly: false
        ) {
          pageInfo {
            totalPages
            totalRows
          }
          results {
            ...CallAiSummaryTemplateListItem
          }
        }
      }
    }
  }
  ${CallAiSummaryTemplateListItemFragmentDoc}
`;

/**
 * __useCurrentUserMyCallSummaryTemplatesQuery__
 *
 * To run a query within a React component, call `useCurrentUserMyCallSummaryTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserMyCallSummaryTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserMyCallSummaryTemplatesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCurrentUserMyCallSummaryTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserMyCallSummaryTemplatesQuery,
    CurrentUserMyCallSummaryTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserMyCallSummaryTemplatesQuery,
    CurrentUserMyCallSummaryTemplatesQueryVariables
  >(CurrentUserMyCallSummaryTemplatesDocument, options);
}
export function useCurrentUserMyCallSummaryTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserMyCallSummaryTemplatesQuery,
    CurrentUserMyCallSummaryTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserMyCallSummaryTemplatesQuery,
    CurrentUserMyCallSummaryTemplatesQueryVariables
  >(CurrentUserMyCallSummaryTemplatesDocument, options);
}
export type CurrentUserMyCallSummaryTemplatesQueryHookResult = ReturnType<
  typeof useCurrentUserMyCallSummaryTemplatesQuery
>;
export type CurrentUserMyCallSummaryTemplatesLazyQueryHookResult = ReturnType<
  typeof useCurrentUserMyCallSummaryTemplatesLazyQuery
>;
export type CurrentUserMyCallSummaryTemplatesQueryResult = Apollo.QueryResult<
  CurrentUserMyCallSummaryTemplatesQuery,
  CurrentUserMyCallSummaryTemplatesQueryVariables
>;
export const CurrentUserPhoneNumberVerifiedDocument = gql`
  query CurrentUserPhoneNumberVerified {
    currentUser {
      id
      phoneNumber
      phoneNumberExtension
      phoneNumberVerified
    }
  }
`;

/**
 * __useCurrentUserPhoneNumberVerifiedQuery__
 *
 * To run a query within a React component, call `useCurrentUserPhoneNumberVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserPhoneNumberVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserPhoneNumberVerifiedQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserPhoneNumberVerifiedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserPhoneNumberVerifiedQuery,
    CurrentUserPhoneNumberVerifiedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserPhoneNumberVerifiedQuery,
    CurrentUserPhoneNumberVerifiedQueryVariables
  >(CurrentUserPhoneNumberVerifiedDocument, options);
}
export function useCurrentUserPhoneNumberVerifiedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserPhoneNumberVerifiedQuery,
    CurrentUserPhoneNumberVerifiedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserPhoneNumberVerifiedQuery,
    CurrentUserPhoneNumberVerifiedQueryVariables
  >(CurrentUserPhoneNumberVerifiedDocument, options);
}
export type CurrentUserPhoneNumberVerifiedQueryHookResult = ReturnType<
  typeof useCurrentUserPhoneNumberVerifiedQuery
>;
export type CurrentUserPhoneNumberVerifiedLazyQueryHookResult = ReturnType<
  typeof useCurrentUserPhoneNumberVerifiedLazyQuery
>;
export type CurrentUserPhoneNumberVerifiedQueryResult = Apollo.QueryResult<
  CurrentUserPhoneNumberVerifiedQuery,
  CurrentUserPhoneNumberVerifiedQueryVariables
>;
export const CurrentUserPositionsDocument = gql`
  query CurrentUserPositions($pagination: OffsetPaginationDetails) {
    currentUser {
      id
      positions(pagination: $pagination) {
        pageInfo {
          totalPages
          totalRows
        }
        results {
          ...PositionPageListItem
        }
      }
    }
  }
  ${PositionPageListItemFragmentDoc}
`;

/**
 * __useCurrentUserPositionsQuery__
 *
 * To run a query within a React component, call `useCurrentUserPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserPositionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCurrentUserPositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserPositionsQuery,
    CurrentUserPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserPositionsQuery,
    CurrentUserPositionsQueryVariables
  >(CurrentUserPositionsDocument, options);
}
export function useCurrentUserPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserPositionsQuery,
    CurrentUserPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserPositionsQuery,
    CurrentUserPositionsQueryVariables
  >(CurrentUserPositionsDocument, options);
}
export type CurrentUserPositionsQueryHookResult = ReturnType<
  typeof useCurrentUserPositionsQuery
>;
export type CurrentUserPositionsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserPositionsLazyQuery
>;
export type CurrentUserPositionsQueryResult = Apollo.QueryResult<
  CurrentUserPositionsQuery,
  CurrentUserPositionsQueryVariables
>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const CurrentUserScheduledInterviewsNotImportedDocument = gql`
  query CurrentUserScheduledInterviewsNotImported(
    $start: DateTime
    $end: DateTime
    $positionId: String
  ) {
    currentUser {
      id
      scheduledInterviewsNotImported(
        start: $start
        end: $end
        positionId: $positionId
      ) {
        ...ScheduledInterviewListItem
      }
    }
  }
  ${ScheduledInterviewListItemFragmentDoc}
`;

/**
 * __useCurrentUserScheduledInterviewsNotImportedQuery__
 *
 * To run a query within a React component, call `useCurrentUserScheduledInterviewsNotImportedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserScheduledInterviewsNotImportedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserScheduledInterviewsNotImportedQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useCurrentUserScheduledInterviewsNotImportedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserScheduledInterviewsNotImportedQuery,
    CurrentUserScheduledInterviewsNotImportedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserScheduledInterviewsNotImportedQuery,
    CurrentUserScheduledInterviewsNotImportedQueryVariables
  >(CurrentUserScheduledInterviewsNotImportedDocument, options);
}
export function useCurrentUserScheduledInterviewsNotImportedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserScheduledInterviewsNotImportedQuery,
    CurrentUserScheduledInterviewsNotImportedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserScheduledInterviewsNotImportedQuery,
    CurrentUserScheduledInterviewsNotImportedQueryVariables
  >(CurrentUserScheduledInterviewsNotImportedDocument, options);
}
export type CurrentUserScheduledInterviewsNotImportedQueryHookResult =
  ReturnType<typeof useCurrentUserScheduledInterviewsNotImportedQuery>;
export type CurrentUserScheduledInterviewsNotImportedLazyQueryHookResult =
  ReturnType<typeof useCurrentUserScheduledInterviewsNotImportedLazyQuery>;
export type CurrentUserScheduledInterviewsNotImportedQueryResult =
  Apollo.QueryResult<
    CurrentUserScheduledInterviewsNotImportedQuery,
    CurrentUserScheduledInterviewsNotImportedQueryVariables
  >;
export const CurrentUserScheduledInterviewsDocument = gql`
  query CurrentUserScheduledInterviews(
    $start: DateTime
    $end: DateTime
    $positionId: String
    $pagination: CursorPaginationDetails
  ) {
    currentUser {
      id
      scheduledInterviews(
        start: $start
        end: $end
        positionId: $positionId
        pagination: $pagination
      ) {
        pageInfo {
          ...PageInfo
        }
        results {
          ...ScheduledInterviewListItem
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${ScheduledInterviewListItemFragmentDoc}
`;

/**
 * __useCurrentUserScheduledInterviewsQuery__
 *
 * To run a query within a React component, call `useCurrentUserScheduledInterviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserScheduledInterviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserScheduledInterviewsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      positionId: // value for 'positionId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCurrentUserScheduledInterviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserScheduledInterviewsQuery,
    CurrentUserScheduledInterviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserScheduledInterviewsQuery,
    CurrentUserScheduledInterviewsQueryVariables
  >(CurrentUserScheduledInterviewsDocument, options);
}
export function useCurrentUserScheduledInterviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserScheduledInterviewsQuery,
    CurrentUserScheduledInterviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserScheduledInterviewsQuery,
    CurrentUserScheduledInterviewsQueryVariables
  >(CurrentUserScheduledInterviewsDocument, options);
}
export type CurrentUserScheduledInterviewsQueryHookResult = ReturnType<
  typeof useCurrentUserScheduledInterviewsQuery
>;
export type CurrentUserScheduledInterviewsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserScheduledInterviewsLazyQuery
>;
export type CurrentUserScheduledInterviewsQueryResult = Apollo.QueryResult<
  CurrentUserScheduledInterviewsQuery,
  CurrentUserScheduledInterviewsQueryVariables
>;
export const CurrentUserSharedCallGuidesDocument = gql`
  query CurrentUserSharedCallGuides($query: String, $limit: Int) {
    currentUser {
      id
      sharedCallGuides(query: $query, limit: $limit) {
        ...CallGuideListItem
      }
    }
  }
  ${CallGuideListItemFragmentDoc}
`;

/**
 * __useCurrentUserSharedCallGuidesQuery__
 *
 * To run a query within a React component, call `useCurrentUserSharedCallGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSharedCallGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSharedCallGuidesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCurrentUserSharedCallGuidesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserSharedCallGuidesQuery,
    CurrentUserSharedCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserSharedCallGuidesQuery,
    CurrentUserSharedCallGuidesQueryVariables
  >(CurrentUserSharedCallGuidesDocument, options);
}
export function useCurrentUserSharedCallGuidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserSharedCallGuidesQuery,
    CurrentUserSharedCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserSharedCallGuidesQuery,
    CurrentUserSharedCallGuidesQueryVariables
  >(CurrentUserSharedCallGuidesDocument, options);
}
export type CurrentUserSharedCallGuidesQueryHookResult = ReturnType<
  typeof useCurrentUserSharedCallGuidesQuery
>;
export type CurrentUserSharedCallGuidesLazyQueryHookResult = ReturnType<
  typeof useCurrentUserSharedCallGuidesLazyQuery
>;
export type CurrentUserSharedCallGuidesQueryResult = Apollo.QueryResult<
  CurrentUserSharedCallGuidesQuery,
  CurrentUserSharedCallGuidesQueryVariables
>;
export const CurrentUserSharedCallSummaryTemplatesDocument = gql`
  query CurrentUserSharedCallSummaryTemplates(
    $pagination: OffsetPaginationDetails
  ) {
    currentUser {
      id
      organization {
        id
        callAiSummaryTemplates(
          pagination: $pagination
          currentUserOnly: false
          sharedOnly: true
        ) {
          pageInfo {
            totalPages
            totalRows
          }
          results {
            ...CallAiSummaryTemplateListItem
          }
        }
      }
    }
  }
  ${CallAiSummaryTemplateListItemFragmentDoc}
`;

/**
 * __useCurrentUserSharedCallSummaryTemplatesQuery__
 *
 * To run a query within a React component, call `useCurrentUserSharedCallSummaryTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSharedCallSummaryTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSharedCallSummaryTemplatesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCurrentUserSharedCallSummaryTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserSharedCallSummaryTemplatesQuery,
    CurrentUserSharedCallSummaryTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserSharedCallSummaryTemplatesQuery,
    CurrentUserSharedCallSummaryTemplatesQueryVariables
  >(CurrentUserSharedCallSummaryTemplatesDocument, options);
}
export function useCurrentUserSharedCallSummaryTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserSharedCallSummaryTemplatesQuery,
    CurrentUserSharedCallSummaryTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserSharedCallSummaryTemplatesQuery,
    CurrentUserSharedCallSummaryTemplatesQueryVariables
  >(CurrentUserSharedCallSummaryTemplatesDocument, options);
}
export type CurrentUserSharedCallSummaryTemplatesQueryHookResult = ReturnType<
  typeof useCurrentUserSharedCallSummaryTemplatesQuery
>;
export type CurrentUserSharedCallSummaryTemplatesLazyQueryHookResult =
  ReturnType<typeof useCurrentUserSharedCallSummaryTemplatesLazyQuery>;
export type CurrentUserSharedCallSummaryTemplatesQueryResult =
  Apollo.QueryResult<
    CurrentUserSharedCallSummaryTemplatesQuery,
    CurrentUserSharedCallSummaryTemplatesQueryVariables
  >;
export const ExcludeStagesDocument = gql`
  query ExcludeStages {
    excludeStages {
      id
      excludeText
    }
  }
`;

/**
 * __useExcludeStagesQuery__
 *
 * To run a query within a React component, call `useExcludeStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExcludeStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExcludeStagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExcludeStagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExcludeStagesQuery,
    ExcludeStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExcludeStagesQuery, ExcludeStagesQueryVariables>(
    ExcludeStagesDocument,
    options
  );
}
export function useExcludeStagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExcludeStagesQuery,
    ExcludeStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExcludeStagesQuery, ExcludeStagesQueryVariables>(
    ExcludeStagesDocument,
    options
  );
}
export type ExcludeStagesQueryHookResult = ReturnType<
  typeof useExcludeStagesQuery
>;
export type ExcludeStagesLazyQueryHookResult = ReturnType<
  typeof useExcludeStagesLazyQuery
>;
export type ExcludeStagesQueryResult = Apollo.QueryResult<
  ExcludeStagesQuery,
  ExcludeStagesQueryVariables
>;
export const FeatureFlagDocument = gql`
  query FeatureFlag($featureFlag: String!) {
    featureFlag(featureFlag: $featureFlag) {
      ...FeatureFlag
    }
  }
  ${FeatureFlagFragmentDoc}
`;

/**
 * __useFeatureFlagQuery__
 *
 * To run a query within a React component, call `useFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagQuery({
 *   variables: {
 *      featureFlag: // value for 'featureFlag'
 *   },
 * });
 */
export function useFeatureFlagQuery(
  baseOptions: Apollo.QueryHookOptions<
    FeatureFlagQuery,
    FeatureFlagQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(
    FeatureFlagDocument,
    options
  );
}
export function useFeatureFlagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureFlagQuery,
    FeatureFlagQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(
    FeatureFlagDocument,
    options
  );
}
export type FeatureFlagQueryHookResult = ReturnType<typeof useFeatureFlagQuery>;
export type FeatureFlagLazyQueryHookResult = ReturnType<
  typeof useFeatureFlagLazyQuery
>;
export type FeatureFlagQueryResult = Apollo.QueryResult<
  FeatureFlagQuery,
  FeatureFlagQueryVariables
>;
export const FeatureFlagValueDocument = gql`
  query FeatureFlagValue($featureFlag: String!) {
    featureFlag(featureFlag: $featureFlag) {
      name
      value
    }
  }
`;

/**
 * __useFeatureFlagValueQuery__
 *
 * To run a query within a React component, call `useFeatureFlagValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagValueQuery({
 *   variables: {
 *      featureFlag: // value for 'featureFlag'
 *   },
 * });
 */
export function useFeatureFlagValueQuery(
  baseOptions: Apollo.QueryHookOptions<
    FeatureFlagValueQuery,
    FeatureFlagValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeatureFlagValueQuery, FeatureFlagValueQueryVariables>(
    FeatureFlagValueDocument,
    options
  );
}
export function useFeatureFlagValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureFlagValueQuery,
    FeatureFlagValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureFlagValueQuery,
    FeatureFlagValueQueryVariables
  >(FeatureFlagValueDocument, options);
}
export type FeatureFlagValueQueryHookResult = ReturnType<
  typeof useFeatureFlagValueQuery
>;
export type FeatureFlagValueLazyQueryHookResult = ReturnType<
  typeof useFeatureFlagValueLazyQuery
>;
export type FeatureFlagValueQueryResult = Apollo.QueryResult<
  FeatureFlagValueQuery,
  FeatureFlagValueQueryVariables
>;
export const FindGreenhouseUserDocument = gql`
  query FindGreenhouseUser($email: String!) {
    findGreenhouseUser(email: $email) {
      ...GreenhouseUser
    }
  }
  ${GreenhouseUserFragmentDoc}
`;

/**
 * __useFindGreenhouseUserQuery__
 *
 * To run a query within a React component, call `useFindGreenhouseUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindGreenhouseUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindGreenhouseUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindGreenhouseUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindGreenhouseUserQuery,
    FindGreenhouseUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindGreenhouseUserQuery,
    FindGreenhouseUserQueryVariables
  >(FindGreenhouseUserDocument, options);
}
export function useFindGreenhouseUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindGreenhouseUserQuery,
    FindGreenhouseUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindGreenhouseUserQuery,
    FindGreenhouseUserQueryVariables
  >(FindGreenhouseUserDocument, options);
}
export type FindGreenhouseUserQueryHookResult = ReturnType<
  typeof useFindGreenhouseUserQuery
>;
export type FindGreenhouseUserLazyQueryHookResult = ReturnType<
  typeof useFindGreenhouseUserLazyQuery
>;
export type FindGreenhouseUserQueryResult = Apollo.QueryResult<
  FindGreenhouseUserQuery,
  FindGreenhouseUserQueryVariables
>;
export const GreenhouseCurrentUserDocument = gql`
  query GreenhouseCurrentUser {
    greenhouseCurrentUser {
      ...GreenhouseUser
    }
  }
  ${GreenhouseUserFragmentDoc}
`;

/**
 * __useGreenhouseCurrentUserQuery__
 *
 * To run a query within a React component, call `useGreenhouseCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGreenhouseCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGreenhouseCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGreenhouseCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GreenhouseCurrentUserQuery,
    GreenhouseCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GreenhouseCurrentUserQuery,
    GreenhouseCurrentUserQueryVariables
  >(GreenhouseCurrentUserDocument, options);
}
export function useGreenhouseCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GreenhouseCurrentUserQuery,
    GreenhouseCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GreenhouseCurrentUserQuery,
    GreenhouseCurrentUserQueryVariables
  >(GreenhouseCurrentUserDocument, options);
}
export type GreenhouseCurrentUserQueryHookResult = ReturnType<
  typeof useGreenhouseCurrentUserQuery
>;
export type GreenhouseCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGreenhouseCurrentUserLazyQuery
>;
export type GreenhouseCurrentUserQueryResult = Apollo.QueryResult<
  GreenhouseCurrentUserQuery,
  GreenhouseCurrentUserQueryVariables
>;
export const GreenhouseFreemiumSyncStatusDocument = gql`
  query GreenhouseFreemiumSyncStatus {
    greenhouseFreemiumSyncStatus {
      id
      lastSyncAt
      status
    }
  }
`;

/**
 * __useGreenhouseFreemiumSyncStatusQuery__
 *
 * To run a query within a React component, call `useGreenhouseFreemiumSyncStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGreenhouseFreemiumSyncStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGreenhouseFreemiumSyncStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGreenhouseFreemiumSyncStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GreenhouseFreemiumSyncStatusQuery,
    GreenhouseFreemiumSyncStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GreenhouseFreemiumSyncStatusQuery,
    GreenhouseFreemiumSyncStatusQueryVariables
  >(GreenhouseFreemiumSyncStatusDocument, options);
}
export function useGreenhouseFreemiumSyncStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GreenhouseFreemiumSyncStatusQuery,
    GreenhouseFreemiumSyncStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GreenhouseFreemiumSyncStatusQuery,
    GreenhouseFreemiumSyncStatusQueryVariables
  >(GreenhouseFreemiumSyncStatusDocument, options);
}
export type GreenhouseFreemiumSyncStatusQueryHookResult = ReturnType<
  typeof useGreenhouseFreemiumSyncStatusQuery
>;
export type GreenhouseFreemiumSyncStatusLazyQueryHookResult = ReturnType<
  typeof useGreenhouseFreemiumSyncStatusLazyQuery
>;
export type GreenhouseFreemiumSyncStatusQueryResult = Apollo.QueryResult<
  GreenhouseFreemiumSyncStatusQuery,
  GreenhouseFreemiumSyncStatusQueryVariables
>;
export const GreenhouseSetupDocument = gql`
  query GreenhouseSetup(
    $verifyAdminUserId: Boolean
    $verifyApiPermissions: Boolean
  ) {
    greenhouseSetup(
      verifyAdminUserId: $verifyAdminUserId
      verifyApiPermissions: $verifyApiPermissions
    ) {
      ...GreenhouseSetup
    }
  }
  ${GreenhouseSetupFragmentDoc}
`;

/**
 * __useGreenhouseSetupQuery__
 *
 * To run a query within a React component, call `useGreenhouseSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGreenhouseSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGreenhouseSetupQuery({
 *   variables: {
 *      verifyAdminUserId: // value for 'verifyAdminUserId'
 *      verifyApiPermissions: // value for 'verifyApiPermissions'
 *   },
 * });
 */
export function useGreenhouseSetupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GreenhouseSetupQuery,
    GreenhouseSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GreenhouseSetupQuery, GreenhouseSetupQueryVariables>(
    GreenhouseSetupDocument,
    options
  );
}
export function useGreenhouseSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GreenhouseSetupQuery,
    GreenhouseSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GreenhouseSetupQuery,
    GreenhouseSetupQueryVariables
  >(GreenhouseSetupDocument, options);
}
export type GreenhouseSetupQueryHookResult = ReturnType<
  typeof useGreenhouseSetupQuery
>;
export type GreenhouseSetupLazyQueryHookResult = ReturnType<
  typeof useGreenhouseSetupLazyQuery
>;
export type GreenhouseSetupQueryResult = Apollo.QueryResult<
  GreenhouseSetupQuery,
  GreenhouseSetupQueryVariables
>;
export const IaCallGuidesDocument = gql`
  query IACallGuides($query: String, $defaultCallGuideId: ID, $limit: Int) {
    currentUser {
      id
      callGuides(
        query: $query
        defaultCallGuideId: $defaultCallGuideId
        limit: $limit
      ) {
        ...CallGuideListItem
      }
      recentCallGuides(query: $query, limit: $limit) {
        id
      }
      sharedCallGuides(query: $query, limit: $limit) {
        ...CallGuideListItem
      }
      assignedCallGuides {
        id
      }
    }
  }
  ${CallGuideListItemFragmentDoc}
`;

/**
 * __useIaCallGuidesQuery__
 *
 * To run a query within a React component, call `useIaCallGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIaCallGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIaCallGuidesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      defaultCallGuideId: // value for 'defaultCallGuideId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useIaCallGuidesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IaCallGuidesQuery,
    IaCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IaCallGuidesQuery, IaCallGuidesQueryVariables>(
    IaCallGuidesDocument,
    options
  );
}
export function useIaCallGuidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IaCallGuidesQuery,
    IaCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IaCallGuidesQuery, IaCallGuidesQueryVariables>(
    IaCallGuidesDocument,
    options
  );
}
export type IaCallGuidesQueryHookResult = ReturnType<
  typeof useIaCallGuidesQuery
>;
export type IaCallGuidesLazyQueryHookResult = ReturnType<
  typeof useIaCallGuidesLazyQuery
>;
export type IaCallGuidesQueryResult = Apollo.QueryResult<
  IaCallGuidesQuery,
  IaCallGuidesQueryVariables
>;
export const IaCallDocument = gql`
  query IACall($id: ID!) {
    call(id: $id) {
      ...IACall
    }
  }
  ${IaCallFragmentDoc}
`;

/**
 * __useIaCallQuery__
 *
 * To run a query within a React component, call `useIaCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useIaCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIaCallQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIaCallQuery(
  baseOptions: Apollo.QueryHookOptions<IaCallQuery, IaCallQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IaCallQuery, IaCallQueryVariables>(
    IaCallDocument,
    options
  );
}
export function useIaCallLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IaCallQuery, IaCallQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IaCallQuery, IaCallQueryVariables>(
    IaCallDocument,
    options
  );
}
export type IaCallQueryHookResult = ReturnType<typeof useIaCallQuery>;
export type IaCallLazyQueryHookResult = ReturnType<typeof useIaCallLazyQuery>;
export type IaCallQueryResult = Apollo.QueryResult<
  IaCallQuery,
  IaCallQueryVariables
>;
export const IcimsSetupDocument = gql`
  query IcimsSetup {
    icimsSetup {
      id
      enabled
      valid
      customerId
      clientId
      clientSecret
      tokenUrl
    }
  }
`;

/**
 * __useIcimsSetupQuery__
 *
 * To run a query within a React component, call `useIcimsSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useIcimsSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIcimsSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useIcimsSetupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IcimsSetupQuery,
    IcimsSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IcimsSetupQuery, IcimsSetupQueryVariables>(
    IcimsSetupDocument,
    options
  );
}
export function useIcimsSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IcimsSetupQuery,
    IcimsSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IcimsSetupQuery, IcimsSetupQueryVariables>(
    IcimsSetupDocument,
    options
  );
}
export type IcimsSetupQueryHookResult = ReturnType<typeof useIcimsSetupQuery>;
export type IcimsSetupLazyQueryHookResult = ReturnType<
  typeof useIcimsSetupLazyQuery
>;
export type IcimsSetupQueryResult = Apollo.QueryResult<
  IcimsSetupQuery,
  IcimsSetupQueryVariables
>;
export const InterviewOptInDocument = gql`
  query InterviewOptIn($location: String!, $organizationId: String!) {
    interviewOptIn(location: $location, organizationId: $organizationId) {
      exists
      willRecord
      whoIsPageText
      yesNoPageText
      errorMessage
      alwaysCandidate
      learnMoreLink
      heroImageUrl
    }
  }
`;

/**
 * __useInterviewOptInQuery__
 *
 * To run a query within a React component, call `useInterviewOptInQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewOptInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewOptInQuery({
 *   variables: {
 *      location: // value for 'location'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useInterviewOptInQuery(
  baseOptions: Apollo.QueryHookOptions<
    InterviewOptInQuery,
    InterviewOptInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InterviewOptInQuery, InterviewOptInQueryVariables>(
    InterviewOptInDocument,
    options
  );
}
export function useInterviewOptInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InterviewOptInQuery,
    InterviewOptInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InterviewOptInQuery, InterviewOptInQueryVariables>(
    InterviewOptInDocument,
    options
  );
}
export type InterviewOptInQueryHookResult = ReturnType<
  typeof useInterviewOptInQuery
>;
export type InterviewOptInLazyQueryHookResult = ReturnType<
  typeof useInterviewOptInLazyQuery
>;
export type InterviewOptInQueryResult = Apollo.QueryResult<
  InterviewOptInQuery,
  InterviewOptInQueryVariables
>;
export const InterviewersDocument = gql`
  query Interviewers(
    $query: String
    $deleted: Boolean
    $signUpCompleted: Boolean
  ) {
    currentUser {
      id
      organization {
        id
        users(
          query: $query
          deleted: $deleted
          signUpCompleted: $signUpCompleted
        ) {
          ...UserInfo
        }
      }
    }
  }
  ${UserInfoFragmentDoc}
`;

/**
 * __useInterviewersQuery__
 *
 * To run a query within a React component, call `useInterviewersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      deleted: // value for 'deleted'
 *      signUpCompleted: // value for 'signUpCompleted'
 *   },
 * });
 */
export function useInterviewersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InterviewersQuery,
    InterviewersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InterviewersQuery, InterviewersQueryVariables>(
    InterviewersDocument,
    options
  );
}
export function useInterviewersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InterviewersQuery,
    InterviewersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InterviewersQuery, InterviewersQueryVariables>(
    InterviewersDocument,
    options
  );
}
export type InterviewersQueryHookResult = ReturnType<
  typeof useInterviewersQuery
>;
export type InterviewersLazyQueryHookResult = ReturnType<
  typeof useInterviewersLazyQuery
>;
export type InterviewersQueryResult = Apollo.QueryResult<
  InterviewersQuery,
  InterviewersQueryVariables
>;
export const LeverOauthSetupDocument = gql`
  query LeverOauthSetup {
    leverOauthSetup {
      id
      useSandbox
      valid
      syncCallGuidesFromAts
      createSecretNotes
    }
  }
`;

/**
 * __useLeverOauthSetupQuery__
 *
 * To run a query within a React component, call `useLeverOauthSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeverOauthSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeverOauthSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useLeverOauthSetupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LeverOauthSetupQuery,
    LeverOauthSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeverOauthSetupQuery, LeverOauthSetupQueryVariables>(
    LeverOauthSetupDocument,
    options
  );
}
export function useLeverOauthSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeverOauthSetupQuery,
    LeverOauthSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LeverOauthSetupQuery,
    LeverOauthSetupQueryVariables
  >(LeverOauthSetupDocument, options);
}
export type LeverOauthSetupQueryHookResult = ReturnType<
  typeof useLeverOauthSetupQuery
>;
export type LeverOauthSetupLazyQueryHookResult = ReturnType<
  typeof useLeverOauthSetupLazyQuery
>;
export type LeverOauthSetupQueryResult = Apollo.QueryResult<
  LeverOauthSetupQuery,
  LeverOauthSetupQueryVariables
>;
export const LeverSetupDocument = gql`
  query LeverSetup {
    leverSetup {
      id
      enabled
      apiKey
      signatureTokenObj
      valid
      syncCallGuidesFromAts
      createSecretNotes
    }
  }
`;

/**
 * __useLeverSetupQuery__
 *
 * To run a query within a React component, call `useLeverSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeverSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeverSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useLeverSetupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LeverSetupQuery,
    LeverSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeverSetupQuery, LeverSetupQueryVariables>(
    LeverSetupDocument,
    options
  );
}
export function useLeverSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeverSetupQuery,
    LeverSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeverSetupQuery, LeverSetupQueryVariables>(
    LeverSetupDocument,
    options
  );
}
export type LeverSetupQueryHookResult = ReturnType<typeof useLeverSetupQuery>;
export type LeverSetupLazyQueryHookResult = ReturnType<
  typeof useLeverSetupLazyQuery
>;
export type LeverSetupQueryResult = Apollo.QueryResult<
  LeverSetupQuery,
  LeverSetupQueryVariables
>;
export const MergeSetupDocument = gql`
  query MergeSetup {
    mergeSetup {
      id
      enabled
      valid
      category
      slug
    }
  }
`;

/**
 * __useMergeSetupQuery__
 *
 * To run a query within a React component, call `useMergeSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useMergeSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMergeSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useMergeSetupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MergeSetupQuery,
    MergeSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MergeSetupQuery, MergeSetupQueryVariables>(
    MergeSetupDocument,
    options
  );
}
export function useMergeSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MergeSetupQuery,
    MergeSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MergeSetupQuery, MergeSetupQueryVariables>(
    MergeSetupDocument,
    options
  );
}
export type MergeSetupQueryHookResult = ReturnType<typeof useMergeSetupQuery>;
export type MergeSetupLazyQueryHookResult = ReturnType<
  typeof useMergeSetupLazyQuery
>;
export type MergeSetupQueryResult = Apollo.QueryResult<
  MergeSetupQuery,
  MergeSetupQueryVariables
>;
export const OptInSetupDocument = gql`
  query OptInSetup {
    optInSetup {
      id
      whoIsPageText
      yesNoPageText
      optInUrlStub
      alwaysCandidate
      heroImageUrl
      learnMoreLink
    }
  }
`;

/**
 * __useOptInSetupQuery__
 *
 * To run a query within a React component, call `useOptInSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptInSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptInSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useOptInSetupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OptInSetupQuery,
    OptInSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OptInSetupQuery, OptInSetupQueryVariables>(
    OptInSetupDocument,
    options
  );
}
export function useOptInSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OptInSetupQuery,
    OptInSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OptInSetupQuery, OptInSetupQueryVariables>(
    OptInSetupDocument,
    options
  );
}
export type OptInSetupQueryHookResult = ReturnType<typeof useOptInSetupQuery>;
export type OptInSetupLazyQueryHookResult = ReturnType<
  typeof useOptInSetupLazyQuery
>;
export type OptInSetupQueryResult = Apollo.QueryResult<
  OptInSetupQuery,
  OptInSetupQueryVariables
>;
export const OrgCallingMetricsDocument = gql`
  query OrgCallingMetrics($start: Date!, $end: Date) {
    currentUser {
      id
      organization {
        ...OrgCallingMetrics
      }
    }
  }
  ${OrgCallingMetricsFragmentDoc}
`;

/**
 * __useOrgCallingMetricsQuery__
 *
 * To run a query within a React component, call `useOrgCallingMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgCallingMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgCallingMetricsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useOrgCallingMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgCallingMetricsQuery,
    OrgCallingMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgCallingMetricsQuery,
    OrgCallingMetricsQueryVariables
  >(OrgCallingMetricsDocument, options);
}
export function useOrgCallingMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgCallingMetricsQuery,
    OrgCallingMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgCallingMetricsQuery,
    OrgCallingMetricsQueryVariables
  >(OrgCallingMetricsDocument, options);
}
export type OrgCallingMetricsQueryHookResult = ReturnType<
  typeof useOrgCallingMetricsQuery
>;
export type OrgCallingMetricsLazyQueryHookResult = ReturnType<
  typeof useOrgCallingMetricsLazyQuery
>;
export type OrgCallingMetricsQueryResult = Apollo.QueryResult<
  OrgCallingMetricsQuery,
  OrgCallingMetricsQueryVariables
>;
export const OrgClientsDocument = gql`
  query OrgClients {
    currentUser {
      id
      organization {
        id
        clients {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useOrgClientsQuery__
 *
 * To run a query within a React component, call `useOrgClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgClientsQuery,
    OrgClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgClientsQuery, OrgClientsQueryVariables>(
    OrgClientsDocument,
    options
  );
}
export function useOrgClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgClientsQuery,
    OrgClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgClientsQuery, OrgClientsQueryVariables>(
    OrgClientsDocument,
    options
  );
}
export type OrgClientsQueryHookResult = ReturnType<typeof useOrgClientsQuery>;
export type OrgClientsLazyQueryHookResult = ReturnType<
  typeof useOrgClientsLazyQuery
>;
export type OrgClientsQueryResult = Apollo.QueryResult<
  OrgClientsQuery,
  OrgClientsQueryVariables
>;
export const OrgHiringTeamGroupDocument = gql`
  query OrgHiringTeamGroup($id: ID!) {
    client(id: $id) {
      id
      name
      createdAt
      clientMemberships {
        positionRoleId
        user {
          id
          email
          fullName
        }
      }
      positions {
        id
        title
        displayTitle
        createdAt
      }
    }
  }
`;

/**
 * __useOrgHiringTeamGroupQuery__
 *
 * To run a query within a React component, call `useOrgHiringTeamGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgHiringTeamGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgHiringTeamGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrgHiringTeamGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgHiringTeamGroupQuery,
    OrgHiringTeamGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgHiringTeamGroupQuery,
    OrgHiringTeamGroupQueryVariables
  >(OrgHiringTeamGroupDocument, options);
}
export function useOrgHiringTeamGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgHiringTeamGroupQuery,
    OrgHiringTeamGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgHiringTeamGroupQuery,
    OrgHiringTeamGroupQueryVariables
  >(OrgHiringTeamGroupDocument, options);
}
export type OrgHiringTeamGroupQueryHookResult = ReturnType<
  typeof useOrgHiringTeamGroupQuery
>;
export type OrgHiringTeamGroupLazyQueryHookResult = ReturnType<
  typeof useOrgHiringTeamGroupLazyQuery
>;
export type OrgHiringTeamGroupQueryResult = Apollo.QueryResult<
  OrgHiringTeamGroupQuery,
  OrgHiringTeamGroupQueryVariables
>;
export const OrgHiringTeamGroupsDocument = gql`
  query OrgHiringTeamGroups($pagination: OffsetPaginationDetails) {
    currentUser {
      id
      organization {
        id
        clientsPag(pagination: $pagination) {
          pageInfo {
            totalPages
          }
          results {
            id
            name
            createdAt
            positionsCount
            clientMembershipsCount
          }
        }
      }
    }
  }
`;

/**
 * __useOrgHiringTeamGroupsQuery__
 *
 * To run a query within a React component, call `useOrgHiringTeamGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgHiringTeamGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgHiringTeamGroupsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrgHiringTeamGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgHiringTeamGroupsQuery,
    OrgHiringTeamGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgHiringTeamGroupsQuery,
    OrgHiringTeamGroupsQueryVariables
  >(OrgHiringTeamGroupsDocument, options);
}
export function useOrgHiringTeamGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgHiringTeamGroupsQuery,
    OrgHiringTeamGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgHiringTeamGroupsQuery,
    OrgHiringTeamGroupsQueryVariables
  >(OrgHiringTeamGroupsDocument, options);
}
export type OrgHiringTeamGroupsQueryHookResult = ReturnType<
  typeof useOrgHiringTeamGroupsQuery
>;
export type OrgHiringTeamGroupsLazyQueryHookResult = ReturnType<
  typeof useOrgHiringTeamGroupsLazyQuery
>;
export type OrgHiringTeamGroupsQueryResult = Apollo.QueryResult<
  OrgHiringTeamGroupsQuery,
  OrgHiringTeamGroupsQueryVariables
>;
export const OrgHiringTeamDocument = gql`
  query OrgHiringTeam($id: ID!) {
    position(id: $id) {
      ...PositionListItem
      memberships {
        user {
          id
          email
          fullName
        }
        role {
          id
          name
        }
        position {
          id
          title
        }
        createdAt
      }
    }
  }
  ${PositionListItemFragmentDoc}
`;

/**
 * __useOrgHiringTeamQuery__
 *
 * To run a query within a React component, call `useOrgHiringTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgHiringTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgHiringTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrgHiringTeamQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgHiringTeamQuery,
    OrgHiringTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgHiringTeamQuery, OrgHiringTeamQueryVariables>(
    OrgHiringTeamDocument,
    options
  );
}
export function useOrgHiringTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgHiringTeamQuery,
    OrgHiringTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgHiringTeamQuery, OrgHiringTeamQueryVariables>(
    OrgHiringTeamDocument,
    options
  );
}
export type OrgHiringTeamQueryHookResult = ReturnType<
  typeof useOrgHiringTeamQuery
>;
export type OrgHiringTeamLazyQueryHookResult = ReturnType<
  typeof useOrgHiringTeamLazyQuery
>;
export type OrgHiringTeamQueryResult = Apollo.QueryResult<
  OrgHiringTeamQuery,
  OrgHiringTeamQueryVariables
>;
export const OrgHiringTeamsDocument = gql`
  query OrgHiringTeams($pagination: OffsetPaginationDetails) {
    currentUser {
      id
      organization {
        id
        positions(pagination: $pagination) {
          pageInfo {
            totalPages
          }
          results {
            ...PositionListItem
            membershipsCount
          }
        }
      }
    }
  }
  ${PositionListItemFragmentDoc}
`;

/**
 * __useOrgHiringTeamsQuery__
 *
 * To run a query within a React component, call `useOrgHiringTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgHiringTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgHiringTeamsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrgHiringTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgHiringTeamsQuery,
    OrgHiringTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgHiringTeamsQuery, OrgHiringTeamsQueryVariables>(
    OrgHiringTeamsDocument,
    options
  );
}
export function useOrgHiringTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgHiringTeamsQuery,
    OrgHiringTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgHiringTeamsQuery, OrgHiringTeamsQueryVariables>(
    OrgHiringTeamsDocument,
    options
  );
}
export type OrgHiringTeamsQueryHookResult = ReturnType<
  typeof useOrgHiringTeamsQuery
>;
export type OrgHiringTeamsLazyQueryHookResult = ReturnType<
  typeof useOrgHiringTeamsLazyQuery
>;
export type OrgHiringTeamsQueryResult = Apollo.QueryResult<
  OrgHiringTeamsQuery,
  OrgHiringTeamsQueryVariables
>;
export const OrgPositionsJbpDocument = gql`
  query OrgPositionsJBP(
    $atsOnly: Boolean
    $pagination: OffsetPaginationDetails
  ) {
    currentUser {
      id
      organization {
        id
        positions(atsOnly: $atsOnly, pagination: $pagination) {
          pageInfo {
            totalPages
            totalRows
          }
          results {
            ...PositionJBPListItem
          }
        }
        autoJoinAllNewPositions
      }
    }
  }
  ${PositionJbpListItemFragmentDoc}
`;

/**
 * __useOrgPositionsJbpQuery__
 *
 * To run a query within a React component, call `useOrgPositionsJbpQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgPositionsJbpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgPositionsJbpQuery({
 *   variables: {
 *      atsOnly: // value for 'atsOnly'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrgPositionsJbpQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgPositionsJbpQuery,
    OrgPositionsJbpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgPositionsJbpQuery, OrgPositionsJbpQueryVariables>(
    OrgPositionsJbpDocument,
    options
  );
}
export function useOrgPositionsJbpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgPositionsJbpQuery,
    OrgPositionsJbpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgPositionsJbpQuery,
    OrgPositionsJbpQueryVariables
  >(OrgPositionsJbpDocument, options);
}
export type OrgPositionsJbpQueryHookResult = ReturnType<
  typeof useOrgPositionsJbpQuery
>;
export type OrgPositionsJbpLazyQueryHookResult = ReturnType<
  typeof useOrgPositionsJbpLazyQuery
>;
export type OrgPositionsJbpQueryResult = Apollo.QueryResult<
  OrgPositionsJbpQuery,
  OrgPositionsJbpQueryVariables
>;
export const OrgPositionsPageDocument = gql`
  query OrgPositionsPage(
    $atsOnly: Boolean
    $pagination: OffsetPaginationDetails
  ) {
    currentUser {
      id
      organization {
        id
        positions(atsOnly: $atsOnly, pagination: $pagination) {
          pageInfo {
            totalPages
            totalRows
          }
          results {
            ...PositionPageListItem
          }
        }
        autoJoinAllNewPositions
      }
    }
  }
  ${PositionPageListItemFragmentDoc}
`;

/**
 * __useOrgPositionsPageQuery__
 *
 * To run a query within a React component, call `useOrgPositionsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgPositionsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgPositionsPageQuery({
 *   variables: {
 *      atsOnly: // value for 'atsOnly'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrgPositionsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgPositionsPageQuery,
    OrgPositionsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgPositionsPageQuery, OrgPositionsPageQueryVariables>(
    OrgPositionsPageDocument,
    options
  );
}
export function useOrgPositionsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgPositionsPageQuery,
    OrgPositionsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgPositionsPageQuery,
    OrgPositionsPageQueryVariables
  >(OrgPositionsPageDocument, options);
}
export type OrgPositionsPageQueryHookResult = ReturnType<
  typeof useOrgPositionsPageQuery
>;
export type OrgPositionsPageLazyQueryHookResult = ReturnType<
  typeof useOrgPositionsPageLazyQuery
>;
export type OrgPositionsPageQueryResult = Apollo.QueryResult<
  OrgPositionsPageQuery,
  OrgPositionsPageQueryVariables
>;
export const OrgPositionsDocument = gql`
  query OrgPositions(
    $atsOnly: Boolean
    $activeOnly: Boolean
    $pagination: OffsetPaginationDetails
  ) {
    currentUser {
      id
      organization {
        id
        positions(
          atsOnly: $atsOnly
          activeOnly: $activeOnly
          pagination: $pagination
        ) {
          pageInfo {
            totalPages
            totalRows
          }
          results {
            ...PositionListItem
          }
        }
      }
    }
  }
  ${PositionListItemFragmentDoc}
`;

/**
 * __useOrgPositionsQuery__
 *
 * To run a query within a React component, call `useOrgPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgPositionsQuery({
 *   variables: {
 *      atsOnly: // value for 'atsOnly'
 *      activeOnly: // value for 'activeOnly'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrgPositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgPositionsQuery,
    OrgPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgPositionsQuery, OrgPositionsQueryVariables>(
    OrgPositionsDocument,
    options
  );
}
export function useOrgPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgPositionsQuery,
    OrgPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgPositionsQuery, OrgPositionsQueryVariables>(
    OrgPositionsDocument,
    options
  );
}
export type OrgPositionsQueryHookResult = ReturnType<
  typeof useOrgPositionsQuery
>;
export type OrgPositionsLazyQueryHookResult = ReturnType<
  typeof useOrgPositionsLazyQuery
>;
export type OrgPositionsQueryResult = Apollo.QueryResult<
  OrgPositionsQuery,
  OrgPositionsQueryVariables
>;
export const OrgInterviewStagesDocument = gql`
  query OrgInterviewStages(
    $atsOnly: Boolean
    $pagination: OffsetPaginationDetails
  ) {
    currentUser {
      id
      organization {
        id
        interviewStages(atsOnly: $atsOnly, pagination: $pagination) {
          pageInfo {
            totalPages
            totalRows
          }
          results {
            ...InterviewStageListItem
          }
        }
      }
    }
  }
  ${InterviewStageListItemFragmentDoc}
`;

/**
 * __useOrgInterviewStagesQuery__
 *
 * To run a query within a React component, call `useOrgInterviewStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgInterviewStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgInterviewStagesQuery({
 *   variables: {
 *      atsOnly: // value for 'atsOnly'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrgInterviewStagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgInterviewStagesQuery,
    OrgInterviewStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgInterviewStagesQuery,
    OrgInterviewStagesQueryVariables
  >(OrgInterviewStagesDocument, options);
}
export function useOrgInterviewStagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgInterviewStagesQuery,
    OrgInterviewStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgInterviewStagesQuery,
    OrgInterviewStagesQueryVariables
  >(OrgInterviewStagesDocument, options);
}
export type OrgInterviewStagesQueryHookResult = ReturnType<
  typeof useOrgInterviewStagesQuery
>;
export type OrgInterviewStagesLazyQueryHookResult = ReturnType<
  typeof useOrgInterviewStagesLazyQuery
>;
export type OrgInterviewStagesQueryResult = Apollo.QueryResult<
  OrgInterviewStagesQuery,
  OrgInterviewStagesQueryVariables
>;
export const OrgUserAutoJoinExclusionsDocument = gql`
  query OrgUserAutoJoinExclusions {
    currentUser {
      id
      organization {
        id
        userAutoJoinExclusions {
          ...User
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useOrgUserAutoJoinExclusionsQuery__
 *
 * To run a query within a React component, call `useOrgUserAutoJoinExclusionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUserAutoJoinExclusionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUserAutoJoinExclusionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgUserAutoJoinExclusionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgUserAutoJoinExclusionsQuery,
    OrgUserAutoJoinExclusionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgUserAutoJoinExclusionsQuery,
    OrgUserAutoJoinExclusionsQueryVariables
  >(OrgUserAutoJoinExclusionsDocument, options);
}
export function useOrgUserAutoJoinExclusionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUserAutoJoinExclusionsQuery,
    OrgUserAutoJoinExclusionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgUserAutoJoinExclusionsQuery,
    OrgUserAutoJoinExclusionsQueryVariables
  >(OrgUserAutoJoinExclusionsDocument, options);
}
export type OrgUserAutoJoinExclusionsQueryHookResult = ReturnType<
  typeof useOrgUserAutoJoinExclusionsQuery
>;
export type OrgUserAutoJoinExclusionsLazyQueryHookResult = ReturnType<
  typeof useOrgUserAutoJoinExclusionsLazyQuery
>;
export type OrgUserAutoJoinExclusionsQueryResult = Apollo.QueryResult<
  OrgUserAutoJoinExclusionsQuery,
  OrgUserAutoJoinExclusionsQueryVariables
>;
export const OrgUsersOnlyDocument = gql`
  query OrgUsersOnly($query: String) {
    currentUser {
      id
      organization {
        id
        users(query: $query, deleted: false, signUpCompleted: false) {
          id
          firstName
          lastName
          fullName
          email
          userRole {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useOrgUsersOnlyQuery__
 *
 * To run a query within a React component, call `useOrgUsersOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUsersOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUsersOnlyQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useOrgUsersOnlyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgUsersOnlyQuery,
    OrgUsersOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUsersOnlyQuery, OrgUsersOnlyQueryVariables>(
    OrgUsersOnlyDocument,
    options
  );
}
export function useOrgUsersOnlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUsersOnlyQuery,
    OrgUsersOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgUsersOnlyQuery, OrgUsersOnlyQueryVariables>(
    OrgUsersOnlyDocument,
    options
  );
}
export type OrgUsersOnlyQueryHookResult = ReturnType<
  typeof useOrgUsersOnlyQuery
>;
export type OrgUsersOnlyLazyQueryHookResult = ReturnType<
  typeof useOrgUsersOnlyLazyQuery
>;
export type OrgUsersOnlyQueryResult = Apollo.QueryResult<
  OrgUsersOnlyQuery,
  OrgUsersOnlyQueryVariables
>;
export const OrgUsersDocument = gql`
  query OrgUsers(
    $query: String
    $deleted: Boolean
    $signUpCompleted: Boolean
    $loginPermitted: Boolean
  ) {
    currentUser {
      id
      organization {
        id
        planEnabled
        freemium
        freemiumSeatCount
        users(
          query: $query
          deleted: $deleted
          signUpCompleted: $signUpCompleted
          loginPermitted: $loginPermitted
        ) {
          ...User
          userRole {
            id
            name
          }
          planUserRole {
            id
            name
          }
          memberships {
            role {
              id
              name
              formattedName @client
            }
            position {
              id
              title
            }
            createdAt
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useOrgUsersQuery__
 *
 * To run a query within a React component, call `useOrgUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      deleted: // value for 'deleted'
 *      signUpCompleted: // value for 'signUpCompleted'
 *      loginPermitted: // value for 'loginPermitted'
 *   },
 * });
 */
export function useOrgUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<OrgUsersQuery, OrgUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUsersQuery, OrgUsersQueryVariables>(
    OrgUsersDocument,
    options
  );
}
export function useOrgUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUsersQuery,
    OrgUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgUsersQuery, OrgUsersQueryVariables>(
    OrgUsersDocument,
    options
  );
}
export type OrgUsersQueryHookResult = ReturnType<typeof useOrgUsersQuery>;
export type OrgUsersLazyQueryHookResult = ReturnType<
  typeof useOrgUsersLazyQuery
>;
export type OrgUsersQueryResult = Apollo.QueryResult<
  OrgUsersQuery,
  OrgUsersQueryVariables
>;
export const OrganizationDataRetentionDocument = gql`
  query OrganizationDataRetention {
    currentUser {
      id
      organization {
        id
        ats
        enableRetentionSinceCandidateHired
        retentionDaysSinceCandidateHired
        enableRetentionSinceCandidateRejected
        retentionDaysSinceCandidateRejected
        enableRetentionSinceLastInterview
        retentionDaysSinceLastInterview
        enableRetentionSinceInterview
        retentionDaysSinceInterview
        enableRedactionForGreenhouse
        enableRedactionForLever
        enableRedactionForSmartrecruiters
        enableRedactionForAshby
        enableAutomaticRetentionDeletion
        retentionNotificationDays
        organizationRetentionUser {
          userId
        }
        retentionEmailSendTime
        retentionEmailSendTimezone
        preventRedactionForTraining
      }
    }
  }
`;

/**
 * __useOrganizationDataRetentionQuery__
 *
 * To run a query within a React component, call `useOrganizationDataRetentionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDataRetentionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDataRetentionQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationDataRetentionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationDataRetentionQuery,
    OrganizationDataRetentionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationDataRetentionQuery,
    OrganizationDataRetentionQueryVariables
  >(OrganizationDataRetentionDocument, options);
}
export function useOrganizationDataRetentionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationDataRetentionQuery,
    OrganizationDataRetentionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationDataRetentionQuery,
    OrganizationDataRetentionQueryVariables
  >(OrganizationDataRetentionDocument, options);
}
export type OrganizationDataRetentionQueryHookResult = ReturnType<
  typeof useOrganizationDataRetentionQuery
>;
export type OrganizationDataRetentionLazyQueryHookResult = ReturnType<
  typeof useOrganizationDataRetentionLazyQuery
>;
export type OrganizationDataRetentionQueryResult = Apollo.QueryResult<
  OrganizationDataRetentionQuery,
  OrganizationDataRetentionQueryVariables
>;
export const OrganizationNameDocument = gql`
  query OrganizationName($orgIdSlug: ID!) {
    organizationName(orgIdSlug: $orgIdSlug) {
      name
    }
  }
`;

/**
 * __useOrganizationNameQuery__
 *
 * To run a query within a React component, call `useOrganizationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationNameQuery({
 *   variables: {
 *      orgIdSlug: // value for 'orgIdSlug'
 *   },
 * });
 */
export function useOrganizationNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationNameQuery,
    OrganizationNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationNameQuery, OrganizationNameQueryVariables>(
    OrganizationNameDocument,
    options
  );
}
export function useOrganizationNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationNameQuery,
    OrganizationNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationNameQuery,
    OrganizationNameQueryVariables
  >(OrganizationNameDocument, options);
}
export type OrganizationNameQueryHookResult = ReturnType<
  typeof useOrganizationNameQuery
>;
export type OrganizationNameLazyQueryHookResult = ReturnType<
  typeof useOrganizationNameLazyQuery
>;
export type OrganizationNameQueryResult = Apollo.QueryResult<
  OrganizationNameQuery,
  OrganizationNameQueryVariables
>;
export const OrganizationRolesDocument = gql`
  query OrganizationRoles {
    currentUser {
      id
      organization {
        id
        planEnabled
        userRoles {
          id
          name
          formattedName @client
        }
        positionRoles {
          id
          name
          formattedName @client
        }
        planUserRoles {
          id
          name
          formattedName @client
        }
      }
    }
  }
`;

/**
 * __useOrganizationRolesQuery__
 *
 * To run a query within a React component, call `useOrganizationRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationRolesQuery,
    OrganizationRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationRolesQuery,
    OrganizationRolesQueryVariables
  >(OrganizationRolesDocument, options);
}
export function useOrganizationRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationRolesQuery,
    OrganizationRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationRolesQuery,
    OrganizationRolesQueryVariables
  >(OrganizationRolesDocument, options);
}
export type OrganizationRolesQueryHookResult = ReturnType<
  typeof useOrganizationRolesQuery
>;
export type OrganizationRolesLazyQueryHookResult = ReturnType<
  typeof useOrganizationRolesLazyQuery
>;
export type OrganizationRolesQueryResult = Apollo.QueryResult<
  OrganizationRolesQuery,
  OrganizationRolesQueryVariables
>;
export const InternalOrganizationsListDocument = gql`
  query InternalOrganizationsList {
    internalOrganizationsList {
      id
      name
    }
  }
`;

/**
 * __useInternalOrganizationsListQuery__
 *
 * To run a query within a React component, call `useInternalOrganizationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalOrganizationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalOrganizationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useInternalOrganizationsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InternalOrganizationsListQuery,
    InternalOrganizationsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InternalOrganizationsListQuery,
    InternalOrganizationsListQueryVariables
  >(InternalOrganizationsListDocument, options);
}
export function useInternalOrganizationsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InternalOrganizationsListQuery,
    InternalOrganizationsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InternalOrganizationsListQuery,
    InternalOrganizationsListQueryVariables
  >(InternalOrganizationsListDocument, options);
}
export type InternalOrganizationsListQueryHookResult = ReturnType<
  typeof useInternalOrganizationsListQuery
>;
export type InternalOrganizationsListLazyQueryHookResult = ReturnType<
  typeof useInternalOrganizationsListLazyQuery
>;
export type InternalOrganizationsListQueryResult = Apollo.QueryResult<
  InternalOrganizationsListQuery,
  InternalOrganizationsListQueryVariables
>;
export const PlanCustomTemplateDocument = gql`
  query PlanCustomTemplate {
    planCustomTemplate {
      id
      sections {
        id
        sectionHeader
        sectionText
        order
        type
      }
    }
  }
`;

/**
 * __usePlanCustomTemplateQuery__
 *
 * To run a query within a React component, call `usePlanCustomTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanCustomTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanCustomTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanCustomTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PlanCustomTemplateQuery,
    PlanCustomTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlanCustomTemplateQuery,
    PlanCustomTemplateQueryVariables
  >(PlanCustomTemplateDocument, options);
}
export function usePlanCustomTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlanCustomTemplateQuery,
    PlanCustomTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlanCustomTemplateQuery,
    PlanCustomTemplateQueryVariables
  >(PlanCustomTemplateDocument, options);
}
export type PlanCustomTemplateQueryHookResult = ReturnType<
  typeof usePlanCustomTemplateQuery
>;
export type PlanCustomTemplateLazyQueryHookResult = ReturnType<
  typeof usePlanCustomTemplateLazyQuery
>;
export type PlanCustomTemplateQueryResult = Apollo.QueryResult<
  PlanCustomTemplateQuery,
  PlanCustomTemplateQueryVariables
>;
export const PositionCallGuidesDocument = gql`
  query PositionCallGuides($id: ID!, $pagination: OffsetPaginationDetails) {
    position(id: $id) {
      id
      paginatedCallGuides(pagination: $pagination) {
        pageInfo {
          totalPages
          totalRows
        }
        results {
          ...CallGuideListItem
        }
      }
    }
  }
  ${CallGuideListItemFragmentDoc}
`;

/**
 * __usePositionCallGuidesQuery__
 *
 * To run a query within a React component, call `usePositionCallGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionCallGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionCallGuidesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePositionCallGuidesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PositionCallGuidesQuery,
    PositionCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PositionCallGuidesQuery,
    PositionCallGuidesQueryVariables
  >(PositionCallGuidesDocument, options);
}
export function usePositionCallGuidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PositionCallGuidesQuery,
    PositionCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PositionCallGuidesQuery,
    PositionCallGuidesQueryVariables
  >(PositionCallGuidesDocument, options);
}
export type PositionCallGuidesQueryHookResult = ReturnType<
  typeof usePositionCallGuidesQuery
>;
export type PositionCallGuidesLazyQueryHookResult = ReturnType<
  typeof usePositionCallGuidesLazyQuery
>;
export type PositionCallGuidesQueryResult = Apollo.QueryResult<
  PositionCallGuidesQuery,
  PositionCallGuidesQueryVariables
>;
export const PositionCandidatesDocument = gql`
  query PositionCandidates(
    $id: ID!
    $pagination: OffsetPaginationDetails
    $includeScores: Boolean = false
  ) {
    position(id: $id) {
      id
      paginatedCandidates(pagination: $pagination) {
        pageInfo {
          totalPages
          totalRows
        }
        results {
          ...CandidateListItem
        }
      }
    }
  }
  ${CandidateListItemFragmentDoc}
`;

/**
 * __usePositionCandidatesQuery__
 *
 * To run a query within a React component, call `usePositionCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionCandidatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pagination: // value for 'pagination'
 *      includeScores: // value for 'includeScores'
 *   },
 * });
 */
export function usePositionCandidatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PositionCandidatesQuery,
    PositionCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PositionCandidatesQuery,
    PositionCandidatesQueryVariables
  >(PositionCandidatesDocument, options);
}
export function usePositionCandidatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PositionCandidatesQuery,
    PositionCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PositionCandidatesQuery,
    PositionCandidatesQueryVariables
  >(PositionCandidatesDocument, options);
}
export type PositionCandidatesQueryHookResult = ReturnType<
  typeof usePositionCandidatesQuery
>;
export type PositionCandidatesLazyQueryHookResult = ReturnType<
  typeof usePositionCandidatesLazyQuery
>;
export type PositionCandidatesQueryResult = Apollo.QueryResult<
  PositionCandidatesQuery,
  PositionCandidatesQueryVariables
>;
export const PositionInfoDocument = gql`
  query PositionInfo($id: ID!) {
    position(id: $id) {
      id
      title
      displayTitle
      greenhouseId
      greenhouseLink
      leverPostingId
      smartrecruitersId
      smartrecruitersLink
      customAtsUrl
      client {
        id
        name
      }
    }
  }
`;

/**
 * __usePositionInfoQuery__
 *
 * To run a query within a React component, call `usePositionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePositionInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    PositionInfoQuery,
    PositionInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PositionInfoQuery, PositionInfoQueryVariables>(
    PositionInfoDocument,
    options
  );
}
export function usePositionInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PositionInfoQuery,
    PositionInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PositionInfoQuery, PositionInfoQueryVariables>(
    PositionInfoDocument,
    options
  );
}
export type PositionInfoQueryHookResult = ReturnType<
  typeof usePositionInfoQuery
>;
export type PositionInfoLazyQueryHookResult = ReturnType<
  typeof usePositionInfoLazyQuery
>;
export type PositionInfoQueryResult = Apollo.QueryResult<
  PositionInfoQuery,
  PositionInfoQueryVariables
>;
export const PositionDocument = gql`
  query Position($id: ID!, $includeScores: Boolean = false) {
    position(id: $id) {
      ...Position
    }
  }
  ${PositionFragmentDoc}
`;

/**
 * __usePositionQuery__
 *
 * To run a query within a React component, call `usePositionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeScores: // value for 'includeScores'
 *   },
 * });
 */
export function usePositionQuery(
  baseOptions: Apollo.QueryHookOptions<PositionQuery, PositionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PositionQuery, PositionQueryVariables>(
    PositionDocument,
    options
  );
}
export function usePositionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PositionQuery,
    PositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PositionQuery, PositionQueryVariables>(
    PositionDocument,
    options
  );
}
export type PositionQueryHookResult = ReturnType<typeof usePositionQuery>;
export type PositionLazyQueryHookResult = ReturnType<
  typeof usePositionLazyQuery
>;
export type PositionQueryResult = Apollo.QueryResult<
  PositionQuery,
  PositionQueryVariables
>;
export const PrimaryCalendarEventsDocument = gql`
  query PrimaryCalendarEvents($startAfter: DateTime, $startBefore: DateTime) {
    primaryCalendarEvents(startAfter: $startAfter, startBefore: $startBefore) {
      id
      summary
      start
      end
      importExcludeDecision
    }
  }
`;

/**
 * __usePrimaryCalendarEventsQuery__
 *
 * To run a query within a React component, call `usePrimaryCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrimaryCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrimaryCalendarEventsQuery({
 *   variables: {
 *      startAfter: // value for 'startAfter'
 *      startBefore: // value for 'startBefore'
 *   },
 * });
 */
export function usePrimaryCalendarEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PrimaryCalendarEventsQuery,
    PrimaryCalendarEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PrimaryCalendarEventsQuery,
    PrimaryCalendarEventsQueryVariables
  >(PrimaryCalendarEventsDocument, options);
}
export function usePrimaryCalendarEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrimaryCalendarEventsQuery,
    PrimaryCalendarEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PrimaryCalendarEventsQuery,
    PrimaryCalendarEventsQueryVariables
  >(PrimaryCalendarEventsDocument, options);
}
export type PrimaryCalendarEventsQueryHookResult = ReturnType<
  typeof usePrimaryCalendarEventsQuery
>;
export type PrimaryCalendarEventsLazyQueryHookResult = ReturnType<
  typeof usePrimaryCalendarEventsLazyQuery
>;
export type PrimaryCalendarEventsQueryResult = Apollo.QueryResult<
  PrimaryCalendarEventsQuery,
  PrimaryCalendarEventsQueryVariables
>;
export const RestApiSetupDocument = gql`
  query RestApiSetup {
    restApiSetup {
      apiTokens {
        id
        name
        tokenPrefix
      }
      webhooks {
        id
        webhookUrl
        webhookSecret
        eventInterviewCompleted
        eventCandidateUpdatedRecordingDisabled
      }
    }
  }
`;

/**
 * __useRestApiSetupQuery__
 *
 * To run a query within a React component, call `useRestApiSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestApiSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestApiSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useRestApiSetupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RestApiSetupQuery,
    RestApiSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RestApiSetupQuery, RestApiSetupQueryVariables>(
    RestApiSetupDocument,
    options
  );
}
export function useRestApiSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RestApiSetupQuery,
    RestApiSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RestApiSetupQuery, RestApiSetupQueryVariables>(
    RestApiSetupDocument,
    options
  );
}
export type RestApiSetupQueryHookResult = ReturnType<
  typeof useRestApiSetupQuery
>;
export type RestApiSetupLazyQueryHookResult = ReturnType<
  typeof useRestApiSetupLazyQuery
>;
export type RestApiSetupQueryResult = Apollo.QueryResult<
  RestApiSetupQuery,
  RestApiSetupQueryVariables
>;
export const RolePoliciesDocument = gql`
  query RolePolicies {
    rolePolicies {
      basicCanSeeOthersNotes
      recruiterCanSeeScores
      recruiterCanSeePublicCalls
      recruiterCanSeeRestrictedCalls
      recruiterCanManageHiringTeams
      recruiterCanCreatePositions
      recruiterCanSeeOthersNotes
      recruiterCanCreateTrainings
      recruiterCanManageAllTrainings
      recruiterCanShareRecordingsExternally
      recruiterCanViewInterviewerQualityReport
      recruiterCanConfigureInterviewerQualityReport
      recruiterCanDownloadRecordings
      recruiterCanManageRedactions
      recruiterCanManageCustomTemplates
      siteAdminCanDownloadRecordings
      hiringManagerCanSeePrivateCalls
      hiringManagerCanSeeOthersNotes
      hiringManagerCanCreateTrainings
      hiringManagerCanShareRecordingsExternally
      interviewerCanSeeOthersNotes
      memberCanSeePublicCalls
      memberCanSeeOthersNotes
      defaultSelfSignUpRole
      defaultScheduledInterviewerInviteRole
      defaultHiringTeamRole
      defaultPositionCreateRole
      defaultRecruiterCallVisibility
      defaultPlanRole
    }
  }
`;

/**
 * __useRolePoliciesQuery__
 *
 * To run a query within a React component, call `useRolePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolePoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolePoliciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RolePoliciesQuery,
    RolePoliciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RolePoliciesQuery, RolePoliciesQueryVariables>(
    RolePoliciesDocument,
    options
  );
}
export function useRolePoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RolePoliciesQuery,
    RolePoliciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RolePoliciesQuery, RolePoliciesQueryVariables>(
    RolePoliciesDocument,
    options
  );
}
export type RolePoliciesQueryHookResult = ReturnType<
  typeof useRolePoliciesQuery
>;
export type RolePoliciesLazyQueryHookResult = ReturnType<
  typeof useRolePoliciesLazyQuery
>;
export type RolePoliciesQueryResult = Apollo.QueryResult<
  RolePoliciesQuery,
  RolePoliciesQueryVariables
>;
export const ScheduledInterviewerDocument = gql`
  query ScheduledInterviewer($id: ID!) {
    scheduledInterviewer(id: $id) {
      id
      recordingDisabled
      scheduledInterview {
        id
        name
      }
    }
  }
`;

/**
 * __useScheduledInterviewerQuery__
 *
 * To run a query within a React component, call `useScheduledInterviewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledInterviewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledInterviewerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScheduledInterviewerQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScheduledInterviewerQuery,
    ScheduledInterviewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScheduledInterviewerQuery,
    ScheduledInterviewerQueryVariables
  >(ScheduledInterviewerDocument, options);
}
export function useScheduledInterviewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduledInterviewerQuery,
    ScheduledInterviewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScheduledInterviewerQuery,
    ScheduledInterviewerQueryVariables
  >(ScheduledInterviewerDocument, options);
}
export type ScheduledInterviewerQueryHookResult = ReturnType<
  typeof useScheduledInterviewerQuery
>;
export type ScheduledInterviewerLazyQueryHookResult = ReturnType<
  typeof useScheduledInterviewerLazyQuery
>;
export type ScheduledInterviewerQueryResult = Apollo.QueryResult<
  ScheduledInterviewerQuery,
  ScheduledInterviewerQueryVariables
>;
export const SearchApiKeyDocument = gql`
  query SearchApiKey {
    searchApiKey
  }
`;

/**
 * __useSearchApiKeyQuery__
 *
 * To run a query within a React component, call `useSearchApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchApiKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchApiKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchApiKeyQuery,
    SearchApiKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchApiKeyQuery, SearchApiKeyQueryVariables>(
    SearchApiKeyDocument,
    options
  );
}
export function useSearchApiKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchApiKeyQuery,
    SearchApiKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchApiKeyQuery, SearchApiKeyQueryVariables>(
    SearchApiKeyDocument,
    options
  );
}
export type SearchApiKeyQueryHookResult = ReturnType<
  typeof useSearchApiKeyQuery
>;
export type SearchApiKeyLazyQueryHookResult = ReturnType<
  typeof useSearchApiKeyLazyQuery
>;
export type SearchApiKeyQueryResult = Apollo.QueryResult<
  SearchApiKeyQuery,
  SearchApiKeyQueryVariables
>;
export const SenseSetupDocument = gql`
  query SenseSetup {
    senseSetup {
      enabled
      apiKey
    }
  }
`;

/**
 * __useSenseSetupQuery__
 *
 * To run a query within a React component, call `useSenseSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSenseSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSenseSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useSenseSetupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SenseSetupQuery,
    SenseSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SenseSetupQuery, SenseSetupQueryVariables>(
    SenseSetupDocument,
    options
  );
}
export function useSenseSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SenseSetupQuery,
    SenseSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SenseSetupQuery, SenseSetupQueryVariables>(
    SenseSetupDocument,
    options
  );
}
export type SenseSetupQueryHookResult = ReturnType<typeof useSenseSetupQuery>;
export type SenseSetupLazyQueryHookResult = ReturnType<
  typeof useSenseSetupLazyQuery
>;
export type SenseSetupQueryResult = Apollo.QueryResult<
  SenseSetupQuery,
  SenseSetupQueryVariables
>;
export const SharedCallGuidesDocument = gql`
  query SharedCallGuides($query: String, $limit: Int) {
    currentUser {
      id
      organization {
        id
        callGuides(query: $query, limit: $limit) {
          ...CallGuideListItem
        }
      }
    }
  }
  ${CallGuideListItemFragmentDoc}
`;

/**
 * __useSharedCallGuidesQuery__
 *
 * To run a query within a React component, call `useSharedCallGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedCallGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedCallGuidesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSharedCallGuidesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SharedCallGuidesQuery,
    SharedCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SharedCallGuidesQuery, SharedCallGuidesQueryVariables>(
    SharedCallGuidesDocument,
    options
  );
}
export function useSharedCallGuidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SharedCallGuidesQuery,
    SharedCallGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SharedCallGuidesQuery,
    SharedCallGuidesQueryVariables
  >(SharedCallGuidesDocument, options);
}
export type SharedCallGuidesQueryHookResult = ReturnType<
  typeof useSharedCallGuidesQuery
>;
export type SharedCallGuidesLazyQueryHookResult = ReturnType<
  typeof useSharedCallGuidesLazyQuery
>;
export type SharedCallGuidesQueryResult = Apollo.QueryResult<
  SharedCallGuidesQuery,
  SharedCallGuidesQueryVariables
>;
export const SingleSignOnSetupDocument = gql`
  query SingleSignOnSetup {
    singleSignOnSetup {
      oktaDomain
      metadataUrl
      scimToken
    }
  }
`;

/**
 * __useSingleSignOnSetupQuery__
 *
 * To run a query within a React component, call `useSingleSignOnSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleSignOnSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleSignOnSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useSingleSignOnSetupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SingleSignOnSetupQuery,
    SingleSignOnSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SingleSignOnSetupQuery,
    SingleSignOnSetupQueryVariables
  >(SingleSignOnSetupDocument, options);
}
export function useSingleSignOnSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SingleSignOnSetupQuery,
    SingleSignOnSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SingleSignOnSetupQuery,
    SingleSignOnSetupQueryVariables
  >(SingleSignOnSetupDocument, options);
}
export type SingleSignOnSetupQueryHookResult = ReturnType<
  typeof useSingleSignOnSetupQuery
>;
export type SingleSignOnSetupLazyQueryHookResult = ReturnType<
  typeof useSingleSignOnSetupLazyQuery
>;
export type SingleSignOnSetupQueryResult = Apollo.QueryResult<
  SingleSignOnSetupQuery,
  SingleSignOnSetupQueryVariables
>;
export const SmartrecruitersSetupDocument = gql`
  query SmartrecruitersSetup {
    smartrecruitersSetup {
      id
      enabled
      valid
      authType
    }
  }
`;

/**
 * __useSmartrecruitersSetupQuery__
 *
 * To run a query within a React component, call `useSmartrecruitersSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartrecruitersSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartrecruitersSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useSmartrecruitersSetupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SmartrecruitersSetupQuery,
    SmartrecruitersSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SmartrecruitersSetupQuery,
    SmartrecruitersSetupQueryVariables
  >(SmartrecruitersSetupDocument, options);
}
export function useSmartrecruitersSetupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SmartrecruitersSetupQuery,
    SmartrecruitersSetupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SmartrecruitersSetupQuery,
    SmartrecruitersSetupQueryVariables
  >(SmartrecruitersSetupDocument, options);
}
export type SmartrecruitersSetupQueryHookResult = ReturnType<
  typeof useSmartrecruitersSetupQuery
>;
export type SmartrecruitersSetupLazyQueryHookResult = ReturnType<
  typeof useSmartrecruitersSetupLazyQuery
>;
export type SmartrecruitersSetupQueryResult = Apollo.QueryResult<
  SmartrecruitersSetupQuery,
  SmartrecruitersSetupQueryVariables
>;
export const TextMessageDocument = gql`
  query TextMessage($messageSid: ID!) {
    textMessage(messageSid: $messageSid) {
      messageSid
      messageStatus
    }
  }
`;

/**
 * __useTextMessageQuery__
 *
 * To run a query within a React component, call `useTextMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextMessageQuery({
 *   variables: {
 *      messageSid: // value for 'messageSid'
 *   },
 * });
 */
export function useTextMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    TextMessageQuery,
    TextMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TextMessageQuery, TextMessageQueryVariables>(
    TextMessageDocument,
    options
  );
}
export function useTextMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TextMessageQuery,
    TextMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TextMessageQuery, TextMessageQueryVariables>(
    TextMessageDocument,
    options
  );
}
export type TextMessageQueryHookResult = ReturnType<typeof useTextMessageQuery>;
export type TextMessageLazyQueryHookResult = ReturnType<
  typeof useTextMessageLazyQuery
>;
export type TextMessageQueryResult = Apollo.QueryResult<
  TextMessageQuery,
  TextMessageQueryVariables
>;
export const TextMessageTemplateDocument = gql`
  query TextMessageTemplate($templateType: TextMessageTemplateType!) {
    textMessageTemplate(templateType: $templateType) {
      id
      templateType
      content
    }
  }
`;

/**
 * __useTextMessageTemplateQuery__
 *
 * To run a query within a React component, call `useTextMessageTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextMessageTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextMessageTemplateQuery({
 *   variables: {
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useTextMessageTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    TextMessageTemplateQuery,
    TextMessageTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TextMessageTemplateQuery,
    TextMessageTemplateQueryVariables
  >(TextMessageTemplateDocument, options);
}
export function useTextMessageTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TextMessageTemplateQuery,
    TextMessageTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TextMessageTemplateQuery,
    TextMessageTemplateQueryVariables
  >(TextMessageTemplateDocument, options);
}
export type TextMessageTemplateQueryHookResult = ReturnType<
  typeof useTextMessageTemplateQuery
>;
export type TextMessageTemplateLazyQueryHookResult = ReturnType<
  typeof useTextMessageTemplateLazyQuery
>;
export type TextMessageTemplateQueryResult = Apollo.QueryResult<
  TextMessageTemplateQuery,
  TextMessageTemplateQueryVariables
>;
export const TextMessageTemplatesDocument = gql`
  query TextMessageTemplates {
    textMessageTemplates {
      id
      templateType
      content
    }
  }
`;

/**
 * __useTextMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useTextMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextMessageTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTextMessageTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TextMessageTemplatesQuery,
    TextMessageTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TextMessageTemplatesQuery,
    TextMessageTemplatesQueryVariables
  >(TextMessageTemplatesDocument, options);
}
export function useTextMessageTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TextMessageTemplatesQuery,
    TextMessageTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TextMessageTemplatesQuery,
    TextMessageTemplatesQueryVariables
  >(TextMessageTemplatesDocument, options);
}
export type TextMessageTemplatesQueryHookResult = ReturnType<
  typeof useTextMessageTemplatesQuery
>;
export type TextMessageTemplatesLazyQueryHookResult = ReturnType<
  typeof useTextMessageTemplatesLazyQuery
>;
export type TextMessageTemplatesQueryResult = Apollo.QueryResult<
  TextMessageTemplatesQuery,
  TextMessageTemplatesQueryVariables
>;
export const TimezonesDocument = gql`
  query Timezones {
    timezones {
      name
      abbrev
      utcOffset
      isDst
    }
  }
`;

/**
 * __useTimezonesQuery__
 *
 * To run a query within a React component, call `useTimezonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimezonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimezonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimezonesQuery(
  baseOptions?: Apollo.QueryHookOptions<TimezonesQuery, TimezonesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimezonesQuery, TimezonesQueryVariables>(
    TimezonesDocument,
    options
  );
}
export function useTimezonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimezonesQuery,
    TimezonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimezonesQuery, TimezonesQueryVariables>(
    TimezonesDocument,
    options
  );
}
export type TimezonesQueryHookResult = ReturnType<typeof useTimezonesQuery>;
export type TimezonesLazyQueryHookResult = ReturnType<
  typeof useTimezonesLazyQuery
>;
export type TimezonesQueryResult = Apollo.QueryResult<
  TimezonesQuery,
  TimezonesQueryVariables
>;
export const UserDocument = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...User
      userRole {
        id
        name
      }
      memberships {
        role {
          id
        }
        position {
          ...PositionListItem
        }
        user {
          id
        }
        createdAt
      }
    }
  }
  ${UserFragmentDoc}
  ${PositionListItemFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserZoomSettingsDocument = gql`
  query UserZoomSettings {
    currentUser {
      id
      zoomUserType
      zoomSettings
    }
  }
`;

/**
 * __useUserZoomSettingsQuery__
 *
 * To run a query within a React component, call `useUserZoomSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserZoomSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserZoomSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserZoomSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserZoomSettingsQuery,
    UserZoomSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserZoomSettingsQuery, UserZoomSettingsQueryVariables>(
    UserZoomSettingsDocument,
    options
  );
}
export function useUserZoomSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserZoomSettingsQuery,
    UserZoomSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserZoomSettingsQuery,
    UserZoomSettingsQueryVariables
  >(UserZoomSettingsDocument, options);
}
export type UserZoomSettingsQueryHookResult = ReturnType<
  typeof useUserZoomSettingsQuery
>;
export type UserZoomSettingsLazyQueryHookResult = ReturnType<
  typeof useUserZoomSettingsLazyQuery
>;
export type UserZoomSettingsQueryResult = Apollo.QueryResult<
  UserZoomSettingsQuery,
  UserZoomSettingsQueryVariables
>;
export const VisibilityLevelDescriptionsDocument = gql`
  query VisibilityLevelDescriptions {
    visibilityLevelDescriptions {
      ...VisibilityLevelDescription
    }
  }
  ${VisibilityLevelDescriptionFragmentDoc}
`;

/**
 * __useVisibilityLevelDescriptionsQuery__
 *
 * To run a query within a React component, call `useVisibilityLevelDescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisibilityLevelDescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisibilityLevelDescriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVisibilityLevelDescriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VisibilityLevelDescriptionsQuery,
    VisibilityLevelDescriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VisibilityLevelDescriptionsQuery,
    VisibilityLevelDescriptionsQueryVariables
  >(VisibilityLevelDescriptionsDocument, options);
}
export function useVisibilityLevelDescriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisibilityLevelDescriptionsQuery,
    VisibilityLevelDescriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VisibilityLevelDescriptionsQuery,
    VisibilityLevelDescriptionsQueryVariables
  >(VisibilityLevelDescriptionsDocument, options);
}
export type VisibilityLevelDescriptionsQueryHookResult = ReturnType<
  typeof useVisibilityLevelDescriptionsQuery
>;
export type VisibilityLevelDescriptionsLazyQueryHookResult = ReturnType<
  typeof useVisibilityLevelDescriptionsLazyQuery
>;
export type VisibilityLevelDescriptionsQueryResult = Apollo.QueryResult<
  VisibilityLevelDescriptionsQuery,
  VisibilityLevelDescriptionsQueryVariables
>;
export const ZoomHealthDocument = gql`
  query ZoomHealth {
    badUsers {
      id
      name
      email
    }
  }
`;

/**
 * __useZoomHealthQuery__
 *
 * To run a query within a React component, call `useZoomHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomHealthQuery({
 *   variables: {
 *   },
 * });
 */
export function useZoomHealthQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ZoomHealthQuery,
    ZoomHealthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoomHealthQuery, ZoomHealthQueryVariables>(
    ZoomHealthDocument,
    options
  );
}
export function useZoomHealthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoomHealthQuery,
    ZoomHealthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZoomHealthQuery, ZoomHealthQueryVariables>(
    ZoomHealthDocument,
    options
  );
}
export type ZoomHealthQueryHookResult = ReturnType<typeof useZoomHealthQuery>;
export type ZoomHealthLazyQueryHookResult = ReturnType<
  typeof useZoomHealthLazyQuery
>;
export type ZoomHealthQueryResult = Apollo.QueryResult<
  ZoomHealthQuery,
  ZoomHealthQueryVariables
>;
export const ZoomJoinUrlByUuidDocument = gql`
  query ZoomJoinUrlByUUID($uuid: ID!) {
    zoomJoinUrlByUuid(uuid: $uuid)
  }
`;

/**
 * __useZoomJoinUrlByUuidQuery__
 *
 * To run a query within a React component, call `useZoomJoinUrlByUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomJoinUrlByUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomJoinUrlByUuidQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useZoomJoinUrlByUuidQuery(
  baseOptions: Apollo.QueryHookOptions<
    ZoomJoinUrlByUuidQuery,
    ZoomJoinUrlByUuidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ZoomJoinUrlByUuidQuery,
    ZoomJoinUrlByUuidQueryVariables
  >(ZoomJoinUrlByUuidDocument, options);
}
export function useZoomJoinUrlByUuidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoomJoinUrlByUuidQuery,
    ZoomJoinUrlByUuidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ZoomJoinUrlByUuidQuery,
    ZoomJoinUrlByUuidQueryVariables
  >(ZoomJoinUrlByUuidDocument, options);
}
export type ZoomJoinUrlByUuidQueryHookResult = ReturnType<
  typeof useZoomJoinUrlByUuidQuery
>;
export type ZoomJoinUrlByUuidLazyQueryHookResult = ReturnType<
  typeof useZoomJoinUrlByUuidLazyQuery
>;
export type ZoomJoinUrlByUuidQueryResult = Apollo.QueryResult<
  ZoomJoinUrlByUuidQuery,
  ZoomJoinUrlByUuidQueryVariables
>;
export const AiSummaryTemplateDocument = gql`
  query AiSummaryTemplate($id: ID!) {
    aiSummaryTemplate(id: $id) {
      ...CallAiSummaryTemplate
    }
  }
  ${CallAiSummaryTemplateFragmentDoc}
`;

/**
 * __useAiSummaryTemplateQuery__
 *
 * To run a query within a React component, call `useAiSummaryTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiSummaryTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiSummaryTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAiSummaryTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    AiSummaryTemplateQuery,
    AiSummaryTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AiSummaryTemplateQuery,
    AiSummaryTemplateQueryVariables
  >(AiSummaryTemplateDocument, options);
}
export function useAiSummaryTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AiSummaryTemplateQuery,
    AiSummaryTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AiSummaryTemplateQuery,
    AiSummaryTemplateQueryVariables
  >(AiSummaryTemplateDocument, options);
}
export type AiSummaryTemplateQueryHookResult = ReturnType<
  typeof useAiSummaryTemplateQuery
>;
export type AiSummaryTemplateLazyQueryHookResult = ReturnType<
  typeof useAiSummaryTemplateLazyQuery
>;
export type AiSummaryTemplateQueryResult = Apollo.QueryResult<
  AiSummaryTemplateQuery,
  AiSummaryTemplateQueryVariables
>;
export const AnalyticsAlertsDocument = gql`
  query AnalyticsAlerts($requestedOrganizationId: String) {
    alerts(requestedOrganizationId: $requestedOrganizationId) {
      id
      alerts {
        id
        organizationId
        message
        alertWeight
        category
        alertType
        alertValence
        segment
        aggregation
        aggregationIds
        alertOrder
        isNew
      }
    }
  }
`;

/**
 * __useAnalyticsAlertsQuery__
 *
 * To run a query within a React component, call `useAnalyticsAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsAlertsQuery({
 *   variables: {
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsAlertsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AnalyticsAlertsQuery,
    AnalyticsAlertsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnalyticsAlertsQuery, AnalyticsAlertsQueryVariables>(
    AnalyticsAlertsDocument,
    options
  );
}
export function useAnalyticsAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsAlertsQuery,
    AnalyticsAlertsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsAlertsQuery,
    AnalyticsAlertsQueryVariables
  >(AnalyticsAlertsDocument, options);
}
export type AnalyticsAlertsQueryHookResult = ReturnType<
  typeof useAnalyticsAlertsQuery
>;
export type AnalyticsAlertsLazyQueryHookResult = ReturnType<
  typeof useAnalyticsAlertsLazyQuery
>;
export type AnalyticsAlertsQueryResult = Apollo.QueryResult<
  AnalyticsAlertsQuery,
  AnalyticsAlertsQueryVariables
>;
export const AnalyticsBenchmarksDocument = gql`
  query AnalyticsBenchmarks {
    benchmarks {
      id
      data {
        id
        metric
        upperBound
        lowerBound
      }
    }
  }
`;

/**
 * __useAnalyticsBenchmarksQuery__
 *
 * To run a query within a React component, call `useAnalyticsBenchmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsBenchmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsBenchmarksQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsBenchmarksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AnalyticsBenchmarksQuery,
    AnalyticsBenchmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsBenchmarksQuery,
    AnalyticsBenchmarksQueryVariables
  >(AnalyticsBenchmarksDocument, options);
}
export function useAnalyticsBenchmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsBenchmarksQuery,
    AnalyticsBenchmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsBenchmarksQuery,
    AnalyticsBenchmarksQueryVariables
  >(AnalyticsBenchmarksDocument, options);
}
export type AnalyticsBenchmarksQueryHookResult = ReturnType<
  typeof useAnalyticsBenchmarksQuery
>;
export type AnalyticsBenchmarksLazyQueryHookResult = ReturnType<
  typeof useAnalyticsBenchmarksLazyQuery
>;
export type AnalyticsBenchmarksQueryResult = Apollo.QueryResult<
  AnalyticsBenchmarksQuery,
  AnalyticsBenchmarksQueryVariables
>;
export const AnalyticsCandidateMotivationCountsDocument = gql`
  query AnalyticsCandidateMotivationCounts(
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $requestedOrganizationId: String
  ) {
    candidateMotivationCounts(
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      totalCalls
      totalCandidates
      counts {
        topic
        count
      }
    }
  }
`;

/**
 * __useAnalyticsCandidateMotivationCountsQuery__
 *
 * To run a query within a React component, call `useAnalyticsCandidateMotivationCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsCandidateMotivationCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsCandidateMotivationCountsQuery({
 *   variables: {
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsCandidateMotivationCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsCandidateMotivationCountsQuery,
    AnalyticsCandidateMotivationCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsCandidateMotivationCountsQuery,
    AnalyticsCandidateMotivationCountsQueryVariables
  >(AnalyticsCandidateMotivationCountsDocument, options);
}
export function useAnalyticsCandidateMotivationCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsCandidateMotivationCountsQuery,
    AnalyticsCandidateMotivationCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsCandidateMotivationCountsQuery,
    AnalyticsCandidateMotivationCountsQueryVariables
  >(AnalyticsCandidateMotivationCountsDocument, options);
}
export type AnalyticsCandidateMotivationCountsQueryHookResult = ReturnType<
  typeof useAnalyticsCandidateMotivationCountsQuery
>;
export type AnalyticsCandidateMotivationCountsLazyQueryHookResult = ReturnType<
  typeof useAnalyticsCandidateMotivationCountsLazyQuery
>;
export type AnalyticsCandidateMotivationCountsQueryResult = Apollo.QueryResult<
  AnalyticsCandidateMotivationCountsQuery,
  AnalyticsCandidateMotivationCountsQueryVariables
>;
export const AnalyticsCandidateMotivationExamplesDocument = gql`
  query AnalyticsCandidateMotivationExamples(
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $motivationTopic: CandidateMotivationTopic
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $requestedOrganizationId: String
  ) {
    candidateMotivationExamples(
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      motivationTopic: $motivationTopic
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      data {
        exampleId
        callId
        callName
        startTime
        positionId
        positionName
        candidateId
        candidateFullName
        summary
        exampleStartTime
        motivationTopics
      }
    }
  }
`;

/**
 * __useAnalyticsCandidateMotivationExamplesQuery__
 *
 * To run a query within a React component, call `useAnalyticsCandidateMotivationExamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsCandidateMotivationExamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsCandidateMotivationExamplesQuery({
 *   variables: {
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      motivationTopic: // value for 'motivationTopic'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsCandidateMotivationExamplesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsCandidateMotivationExamplesQuery,
    AnalyticsCandidateMotivationExamplesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsCandidateMotivationExamplesQuery,
    AnalyticsCandidateMotivationExamplesQueryVariables
  >(AnalyticsCandidateMotivationExamplesDocument, options);
}
export function useAnalyticsCandidateMotivationExamplesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsCandidateMotivationExamplesQuery,
    AnalyticsCandidateMotivationExamplesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsCandidateMotivationExamplesQuery,
    AnalyticsCandidateMotivationExamplesQueryVariables
  >(AnalyticsCandidateMotivationExamplesDocument, options);
}
export type AnalyticsCandidateMotivationExamplesQueryHookResult = ReturnType<
  typeof useAnalyticsCandidateMotivationExamplesQuery
>;
export type AnalyticsCandidateMotivationExamplesLazyQueryHookResult =
  ReturnType<typeof useAnalyticsCandidateMotivationExamplesLazyQuery>;
export type AnalyticsCandidateMotivationExamplesQueryResult =
  Apollo.QueryResult<
    AnalyticsCandidateMotivationExamplesQuery,
    AnalyticsCandidateMotivationExamplesQueryVariables
  >;
export const AnalyticsCandidateMotivationExamplesCsvDocument = gql`
  query AnalyticsCandidateMotivationExamplesCsv(
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $motivationTopic: CandidateMotivationTopic
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $requestedOrganizationId: String
  ) {
    candidateMotivationExamplesCsv(
      motivationTopic: $motivationTopic
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      requestedOrganizationId: $requestedOrganizationId
    ) {
      url
    }
  }
`;

/**
 * __useAnalyticsCandidateMotivationExamplesCsvQuery__
 *
 * To run a query within a React component, call `useAnalyticsCandidateMotivationExamplesCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsCandidateMotivationExamplesCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsCandidateMotivationExamplesCsvQuery({
 *   variables: {
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      motivationTopic: // value for 'motivationTopic'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsCandidateMotivationExamplesCsvQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsCandidateMotivationExamplesCsvQuery,
    AnalyticsCandidateMotivationExamplesCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsCandidateMotivationExamplesCsvQuery,
    AnalyticsCandidateMotivationExamplesCsvQueryVariables
  >(AnalyticsCandidateMotivationExamplesCsvDocument, options);
}
export function useAnalyticsCandidateMotivationExamplesCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsCandidateMotivationExamplesCsvQuery,
    AnalyticsCandidateMotivationExamplesCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsCandidateMotivationExamplesCsvQuery,
    AnalyticsCandidateMotivationExamplesCsvQueryVariables
  >(AnalyticsCandidateMotivationExamplesCsvDocument, options);
}
export type AnalyticsCandidateMotivationExamplesCsvQueryHookResult = ReturnType<
  typeof useAnalyticsCandidateMotivationExamplesCsvQuery
>;
export type AnalyticsCandidateMotivationExamplesCsvLazyQueryHookResult =
  ReturnType<typeof useAnalyticsCandidateMotivationExamplesCsvLazyQuery>;
export type AnalyticsCandidateMotivationExamplesCsvQueryResult =
  Apollo.QueryResult<
    AnalyticsCandidateMotivationExamplesCsvQuery,
    AnalyticsCandidateMotivationExamplesCsvQueryVariables
  >;
export const AnalyticsCandidateMotivationKeyThemesDocument = gql`
  query AnalyticsCandidateMotivationKeyThemes(
    $motivationTopic: CandidateMotivationTopic
    $predictionIds: [String!]!
    $requestedOrganizationId: String
  ) {
    candidateMotivationKeyThemes(
      motivationTopic: $motivationTopic
      predictionIds: $predictionIds
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      data {
        id
        summary
        themeName
      }
    }
  }
`;

/**
 * __useAnalyticsCandidateMotivationKeyThemesQuery__
 *
 * To run a query within a React component, call `useAnalyticsCandidateMotivationKeyThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsCandidateMotivationKeyThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsCandidateMotivationKeyThemesQuery({
 *   variables: {
 *      motivationTopic: // value for 'motivationTopic'
 *      predictionIds: // value for 'predictionIds'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsCandidateMotivationKeyThemesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsCandidateMotivationKeyThemesQuery,
    AnalyticsCandidateMotivationKeyThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsCandidateMotivationKeyThemesQuery,
    AnalyticsCandidateMotivationKeyThemesQueryVariables
  >(AnalyticsCandidateMotivationKeyThemesDocument, options);
}
export function useAnalyticsCandidateMotivationKeyThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsCandidateMotivationKeyThemesQuery,
    AnalyticsCandidateMotivationKeyThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsCandidateMotivationKeyThemesQuery,
    AnalyticsCandidateMotivationKeyThemesQueryVariables
  >(AnalyticsCandidateMotivationKeyThemesDocument, options);
}
export type AnalyticsCandidateMotivationKeyThemesQueryHookResult = ReturnType<
  typeof useAnalyticsCandidateMotivationKeyThemesQuery
>;
export type AnalyticsCandidateMotivationKeyThemesLazyQueryHookResult =
  ReturnType<typeof useAnalyticsCandidateMotivationKeyThemesLazyQuery>;
export type AnalyticsCandidateMotivationKeyThemesQueryResult =
  Apollo.QueryResult<
    AnalyticsCandidateMotivationKeyThemesQuery,
    AnalyticsCandidateMotivationKeyThemesQueryVariables
  >;
export const AnalyticsCandidateQuestionKeyThemesDocument = gql`
  query AnalyticsCandidateQuestionKeyThemes(
    $questionTopic: CandidateQuestionTopic
    $predictionIds: [String!]
    $requestedOrganizationId: String
  ) {
    candidateQuestionKeyThemes(
      questionTopic: $questionTopic
      predictionIds: $predictionIds
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      data {
        id
        summary
        themeName
      }
    }
  }
`;

/**
 * __useAnalyticsCandidateQuestionKeyThemesQuery__
 *
 * To run a query within a React component, call `useAnalyticsCandidateQuestionKeyThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsCandidateQuestionKeyThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsCandidateQuestionKeyThemesQuery({
 *   variables: {
 *      questionTopic: // value for 'questionTopic'
 *      predictionIds: // value for 'predictionIds'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsCandidateQuestionKeyThemesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AnalyticsCandidateQuestionKeyThemesQuery,
    AnalyticsCandidateQuestionKeyThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsCandidateQuestionKeyThemesQuery,
    AnalyticsCandidateQuestionKeyThemesQueryVariables
  >(AnalyticsCandidateQuestionKeyThemesDocument, options);
}
export function useAnalyticsCandidateQuestionKeyThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsCandidateQuestionKeyThemesQuery,
    AnalyticsCandidateQuestionKeyThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsCandidateQuestionKeyThemesQuery,
    AnalyticsCandidateQuestionKeyThemesQueryVariables
  >(AnalyticsCandidateQuestionKeyThemesDocument, options);
}
export type AnalyticsCandidateQuestionKeyThemesQueryHookResult = ReturnType<
  typeof useAnalyticsCandidateQuestionKeyThemesQuery
>;
export type AnalyticsCandidateQuestionKeyThemesLazyQueryHookResult = ReturnType<
  typeof useAnalyticsCandidateQuestionKeyThemesLazyQuery
>;
export type AnalyticsCandidateQuestionKeyThemesQueryResult = Apollo.QueryResult<
  AnalyticsCandidateQuestionKeyThemesQuery,
  AnalyticsCandidateQuestionKeyThemesQueryVariables
>;
export const AnalyticsCandidateQuestionTopicCountsDocument = gql`
  query AnalyticsCandidateQuestionTopicCounts(
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $requestedOrganizationId: String
  ) {
    candidateQuestionTopicCounts(
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      totalCalls
      totalCandidates
      counts {
        topic
        count
      }
    }
  }
`;

/**
 * __useAnalyticsCandidateQuestionTopicCountsQuery__
 *
 * To run a query within a React component, call `useAnalyticsCandidateQuestionTopicCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsCandidateQuestionTopicCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsCandidateQuestionTopicCountsQuery({
 *   variables: {
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsCandidateQuestionTopicCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsCandidateQuestionTopicCountsQuery,
    AnalyticsCandidateQuestionTopicCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsCandidateQuestionTopicCountsQuery,
    AnalyticsCandidateQuestionTopicCountsQueryVariables
  >(AnalyticsCandidateQuestionTopicCountsDocument, options);
}
export function useAnalyticsCandidateQuestionTopicCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsCandidateQuestionTopicCountsQuery,
    AnalyticsCandidateQuestionTopicCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsCandidateQuestionTopicCountsQuery,
    AnalyticsCandidateQuestionTopicCountsQueryVariables
  >(AnalyticsCandidateQuestionTopicCountsDocument, options);
}
export type AnalyticsCandidateQuestionTopicCountsQueryHookResult = ReturnType<
  typeof useAnalyticsCandidateQuestionTopicCountsQuery
>;
export type AnalyticsCandidateQuestionTopicCountsLazyQueryHookResult =
  ReturnType<typeof useAnalyticsCandidateQuestionTopicCountsLazyQuery>;
export type AnalyticsCandidateQuestionTopicCountsQueryResult =
  Apollo.QueryResult<
    AnalyticsCandidateQuestionTopicCountsQuery,
    AnalyticsCandidateQuestionTopicCountsQueryVariables
  >;
export const AnalyticsCandidateQuestionTopicExamplesDocument = gql`
  query AnalyticsCandidateQuestionTopicExamples(
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $questionTopic: CandidateQuestionTopic
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $requestedOrganizationId: String
  ) {
    candidateQuestionTopicExamples(
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      questionTopic: $questionTopic
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      data {
        exampleId
        callId
        callName
        startTime
        positionId
        positionName
        candidateId
        candidateFullName
        exampleText
        exampleStartTime
        exampleTopic
      }
    }
  }
`;

/**
 * __useAnalyticsCandidateQuestionTopicExamplesQuery__
 *
 * To run a query within a React component, call `useAnalyticsCandidateQuestionTopicExamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsCandidateQuestionTopicExamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsCandidateQuestionTopicExamplesQuery({
 *   variables: {
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      questionTopic: // value for 'questionTopic'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsCandidateQuestionTopicExamplesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsCandidateQuestionTopicExamplesQuery,
    AnalyticsCandidateQuestionTopicExamplesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsCandidateQuestionTopicExamplesQuery,
    AnalyticsCandidateQuestionTopicExamplesQueryVariables
  >(AnalyticsCandidateQuestionTopicExamplesDocument, options);
}
export function useAnalyticsCandidateQuestionTopicExamplesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsCandidateQuestionTopicExamplesQuery,
    AnalyticsCandidateQuestionTopicExamplesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsCandidateQuestionTopicExamplesQuery,
    AnalyticsCandidateQuestionTopicExamplesQueryVariables
  >(AnalyticsCandidateQuestionTopicExamplesDocument, options);
}
export type AnalyticsCandidateQuestionTopicExamplesQueryHookResult = ReturnType<
  typeof useAnalyticsCandidateQuestionTopicExamplesQuery
>;
export type AnalyticsCandidateQuestionTopicExamplesLazyQueryHookResult =
  ReturnType<typeof useAnalyticsCandidateQuestionTopicExamplesLazyQuery>;
export type AnalyticsCandidateQuestionTopicExamplesQueryResult =
  Apollo.QueryResult<
    AnalyticsCandidateQuestionTopicExamplesQuery,
    AnalyticsCandidateQuestionTopicExamplesQueryVariables
  >;
export const AnalyticsCandidateQuestionTopicExamplesCsvDocument = gql`
  query AnalyticsCandidateQuestionTopicExamplesCsv(
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $questionTopic: CandidateQuestionTopic
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $requestedOrganizationId: String
  ) {
    candidateQuestionTopicExamplesCsv(
      questionTopic: $questionTopic
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      requestedOrganizationId: $requestedOrganizationId
    ) {
      url
    }
  }
`;

/**
 * __useAnalyticsCandidateQuestionTopicExamplesCsvQuery__
 *
 * To run a query within a React component, call `useAnalyticsCandidateQuestionTopicExamplesCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsCandidateQuestionTopicExamplesCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsCandidateQuestionTopicExamplesCsvQuery({
 *   variables: {
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      questionTopic: // value for 'questionTopic'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsCandidateQuestionTopicExamplesCsvQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsCandidateQuestionTopicExamplesCsvQuery,
    AnalyticsCandidateQuestionTopicExamplesCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsCandidateQuestionTopicExamplesCsvQuery,
    AnalyticsCandidateQuestionTopicExamplesCsvQueryVariables
  >(AnalyticsCandidateQuestionTopicExamplesCsvDocument, options);
}
export function useAnalyticsCandidateQuestionTopicExamplesCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsCandidateQuestionTopicExamplesCsvQuery,
    AnalyticsCandidateQuestionTopicExamplesCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsCandidateQuestionTopicExamplesCsvQuery,
    AnalyticsCandidateQuestionTopicExamplesCsvQueryVariables
  >(AnalyticsCandidateQuestionTopicExamplesCsvDocument, options);
}
export type AnalyticsCandidateQuestionTopicExamplesCsvQueryHookResult =
  ReturnType<typeof useAnalyticsCandidateQuestionTopicExamplesCsvQuery>;
export type AnalyticsCandidateQuestionTopicExamplesCsvLazyQueryHookResult =
  ReturnType<typeof useAnalyticsCandidateQuestionTopicExamplesCsvLazyQuery>;
export type AnalyticsCandidateQuestionTopicExamplesCsvQueryResult =
  Apollo.QueryResult<
    AnalyticsCandidateQuestionTopicExamplesCsvQuery,
    AnalyticsCandidateQuestionTopicExamplesCsvQueryVariables
  >;
export const AnalyticsCompensationTrendsDocument = gql`
  query AnalyticsCompensationTrends(
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $positions: [String!]
    $locations: [String!]
    $departments: [String!]
    $currency: String
    $rate: CompensationExtractionRateType
    $requestedOrganizationId: String
  ) {
    compensationTrends(
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      positions: $positions
      locations: $locations
      departments: $departments
      currency: $currency
      rate: $rate
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      data {
        callId
        callName
        startTime
        category
        formattedValue
        exactAmount
        minAmount
        maxAmount
        rate
        currency
        firstSegmentStartTime
      }
      totalCallCount
      totalCandidateCount
      currencyOptions {
        label
        value
      }
    }
  }
`;

/**
 * __useAnalyticsCompensationTrendsQuery__
 *
 * To run a query within a React component, call `useAnalyticsCompensationTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsCompensationTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsCompensationTrendsQuery({
 *   variables: {
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      departments: // value for 'departments'
 *      currency: // value for 'currency'
 *      rate: // value for 'rate'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsCompensationTrendsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsCompensationTrendsQuery,
    AnalyticsCompensationTrendsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsCompensationTrendsQuery,
    AnalyticsCompensationTrendsQueryVariables
  >(AnalyticsCompensationTrendsDocument, options);
}
export function useAnalyticsCompensationTrendsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsCompensationTrendsQuery,
    AnalyticsCompensationTrendsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsCompensationTrendsQuery,
    AnalyticsCompensationTrendsQueryVariables
  >(AnalyticsCompensationTrendsDocument, options);
}
export type AnalyticsCompensationTrendsQueryHookResult = ReturnType<
  typeof useAnalyticsCompensationTrendsQuery
>;
export type AnalyticsCompensationTrendsLazyQueryHookResult = ReturnType<
  typeof useAnalyticsCompensationTrendsLazyQuery
>;
export type AnalyticsCompensationTrendsQueryResult = Apollo.QueryResult<
  AnalyticsCompensationTrendsQuery,
  AnalyticsCompensationTrendsQueryVariables
>;
export const AnalyticsCompensationTrendsExamplesDocument = gql`
  query AnalyticsCompensationTrendsExamples(
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $positions: [String!]
    $locations: [String!]
    $departments: [String!]
    $category: CompensationExtractionCategoryType
    $currency: String
    $requestedOrganizationId: String
  ) {
    compensationTrendsExamples(
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      positions: $positions
      locations: $locations
      departments: $departments
      category: $category
      currency: $currency
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      totalCallCount
      totalCandidateCount
      data {
        compensationExtractionId
        callId
        callName
        startTime
        positionId
        positionName
        candidateId
        candidateFullName
        summary
        category
        rate
        currency
        minAmount
        maxAmount
        exactAmount
        firstSegmentStartTime
      }
    }
  }
`;

/**
 * __useAnalyticsCompensationTrendsExamplesQuery__
 *
 * To run a query within a React component, call `useAnalyticsCompensationTrendsExamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsCompensationTrendsExamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsCompensationTrendsExamplesQuery({
 *   variables: {
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      departments: // value for 'departments'
 *      category: // value for 'category'
 *      currency: // value for 'currency'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsCompensationTrendsExamplesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsCompensationTrendsExamplesQuery,
    AnalyticsCompensationTrendsExamplesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsCompensationTrendsExamplesQuery,
    AnalyticsCompensationTrendsExamplesQueryVariables
  >(AnalyticsCompensationTrendsExamplesDocument, options);
}
export function useAnalyticsCompensationTrendsExamplesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsCompensationTrendsExamplesQuery,
    AnalyticsCompensationTrendsExamplesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsCompensationTrendsExamplesQuery,
    AnalyticsCompensationTrendsExamplesQueryVariables
  >(AnalyticsCompensationTrendsExamplesDocument, options);
}
export type AnalyticsCompensationTrendsExamplesQueryHookResult = ReturnType<
  typeof useAnalyticsCompensationTrendsExamplesQuery
>;
export type AnalyticsCompensationTrendsExamplesLazyQueryHookResult = ReturnType<
  typeof useAnalyticsCompensationTrendsExamplesLazyQuery
>;
export type AnalyticsCompensationTrendsExamplesQueryResult = Apollo.QueryResult<
  AnalyticsCompensationTrendsExamplesQuery,
  AnalyticsCompensationTrendsExamplesQueryVariables
>;
export const AnalyticsCompensationTrendsExamplesCsvDocument = gql`
  query AnalyticsCompensationTrendsExamplesCsv(
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $positions: [String!]
    $locations: [String!]
    $departments: [String!]
    $category: CompensationExtractionCategoryType
    $currency: String
    $requestedOrganizationId: String
  ) {
    compensationTrendsExamplesCsv(
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      positions: $positions
      locations: $locations
      departments: $departments
      category: $category
      currency: $currency
      requestedOrganizationId: $requestedOrganizationId
    ) {
      url
    }
  }
`;

/**
 * __useAnalyticsCompensationTrendsExamplesCsvQuery__
 *
 * To run a query within a React component, call `useAnalyticsCompensationTrendsExamplesCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsCompensationTrendsExamplesCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsCompensationTrendsExamplesCsvQuery({
 *   variables: {
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      departments: // value for 'departments'
 *      category: // value for 'category'
 *      currency: // value for 'currency'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsCompensationTrendsExamplesCsvQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsCompensationTrendsExamplesCsvQuery,
    AnalyticsCompensationTrendsExamplesCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsCompensationTrendsExamplesCsvQuery,
    AnalyticsCompensationTrendsExamplesCsvQueryVariables
  >(AnalyticsCompensationTrendsExamplesCsvDocument, options);
}
export function useAnalyticsCompensationTrendsExamplesCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsCompensationTrendsExamplesCsvQuery,
    AnalyticsCompensationTrendsExamplesCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsCompensationTrendsExamplesCsvQuery,
    AnalyticsCompensationTrendsExamplesCsvQueryVariables
  >(AnalyticsCompensationTrendsExamplesCsvDocument, options);
}
export type AnalyticsCompensationTrendsExamplesCsvQueryHookResult = ReturnType<
  typeof useAnalyticsCompensationTrendsExamplesCsvQuery
>;
export type AnalyticsCompensationTrendsExamplesCsvLazyQueryHookResult =
  ReturnType<typeof useAnalyticsCompensationTrendsExamplesCsvLazyQuery>;
export type AnalyticsCompensationTrendsExamplesCsvQueryResult =
  Apollo.QueryResult<
    AnalyticsCompensationTrendsExamplesCsvQuery,
    AnalyticsCompensationTrendsExamplesCsvQueryVariables
  >;
export const AnalyticsDataDocument = gql`
  query AnalyticsData(
    $metric: MetricName!
    $primaryDimension: AnalyticsDimension!
    $secondaryDimension: AnalyticsDimension!
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationId: String
  ) {
    analyticsData(
      metric: $metric
      primaryDimension: $primaryDimension
      secondaryDimension: $secondaryDimension
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      organizationId: $organizationId
    ) {
      id
      data {
        id
        dataId
        label
        segment
        value
        countDataPoints
        avgPerformance
      }
    }
  }
`;

/**
 * __useAnalyticsDataQuery__
 *
 * To run a query within a React component, call `useAnalyticsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsDataQuery({
 *   variables: {
 *      metric: // value for 'metric'
 *      primaryDimension: // value for 'primaryDimension'
 *      secondaryDimension: // value for 'secondaryDimension'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useAnalyticsDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsDataQuery,
    AnalyticsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnalyticsDataQuery, AnalyticsDataQueryVariables>(
    AnalyticsDataDocument,
    options
  );
}
export function useAnalyticsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsDataQuery,
    AnalyticsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AnalyticsDataQuery, AnalyticsDataQueryVariables>(
    AnalyticsDataDocument,
    options
  );
}
export type AnalyticsDataQueryHookResult = ReturnType<
  typeof useAnalyticsDataQuery
>;
export type AnalyticsDataLazyQueryHookResult = ReturnType<
  typeof useAnalyticsDataLazyQuery
>;
export type AnalyticsDataQueryResult = Apollo.QueryResult<
  AnalyticsDataQuery,
  AnalyticsDataQueryVariables
>;
export const AnalyticsDataCsvDocument = gql`
  query AnalyticsDataCsv(
    $metric: MetricName!
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationId: String
  ) {
    analyticsDataCsv(
      metric: $metric
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      organizationId: $organizationId
    ) {
      url
    }
  }
`;

/**
 * __useAnalyticsDataCsvQuery__
 *
 * To run a query within a React component, call `useAnalyticsDataCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsDataCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsDataCsvQuery({
 *   variables: {
 *      metric: // value for 'metric'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useAnalyticsDataCsvQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsDataCsvQuery,
    AnalyticsDataCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnalyticsDataCsvQuery, AnalyticsDataCsvQueryVariables>(
    AnalyticsDataCsvDocument,
    options
  );
}
export function useAnalyticsDataCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsDataCsvQuery,
    AnalyticsDataCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsDataCsvQuery,
    AnalyticsDataCsvQueryVariables
  >(AnalyticsDataCsvDocument, options);
}
export type AnalyticsDataCsvQueryHookResult = ReturnType<
  typeof useAnalyticsDataCsvQuery
>;
export type AnalyticsDataCsvLazyQueryHookResult = ReturnType<
  typeof useAnalyticsDataCsvLazyQuery
>;
export type AnalyticsDataCsvQueryResult = Apollo.QueryResult<
  AnalyticsDataCsvQuery,
  AnalyticsDataCsvQueryVariables
>;
export const AnalyticsFilterValuesDocument = gql`
  query AnalyticsFilterValues(
    $metric: MetricName
    $primaryDimension: AnalyticsDimension!
    $secondaryDimension: AnalyticsDimension
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationId: String
    $forCompensation: Boolean
  ) {
    filterValues(
      metric: $metric
      primaryDimension: $primaryDimension
      secondaryDimension: $secondaryDimension
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      organizationId: $organizationId
      forCompensation: $forCompensation
    ) {
      values {
        id
        value
        label
        count
      }
    }
  }
`;

/**
 * __useAnalyticsFilterValuesQuery__
 *
 * To run a query within a React component, call `useAnalyticsFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsFilterValuesQuery({
 *   variables: {
 *      metric: // value for 'metric'
 *      primaryDimension: // value for 'primaryDimension'
 *      secondaryDimension: // value for 'secondaryDimension'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      organizationId: // value for 'organizationId'
 *      forCompensation: // value for 'forCompensation'
 *   },
 * });
 */
export function useAnalyticsFilterValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsFilterValuesQuery,
    AnalyticsFilterValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsFilterValuesQuery,
    AnalyticsFilterValuesQueryVariables
  >(AnalyticsFilterValuesDocument, options);
}
export function useAnalyticsFilterValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsFilterValuesQuery,
    AnalyticsFilterValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsFilterValuesQuery,
    AnalyticsFilterValuesQueryVariables
  >(AnalyticsFilterValuesDocument, options);
}
export type AnalyticsFilterValuesQueryHookResult = ReturnType<
  typeof useAnalyticsFilterValuesQuery
>;
export type AnalyticsFilterValuesLazyQueryHookResult = ReturnType<
  typeof useAnalyticsFilterValuesLazyQuery
>;
export type AnalyticsFilterValuesQueryResult = Apollo.QueryResult<
  AnalyticsFilterValuesQuery,
  AnalyticsFilterValuesQueryVariables
>;
export const AnalyticsInterviewStageFilterValuesDocument = gql`
  query AnalyticsInterviewStageFilterValues(
    $positionIds: [String!]!
    $dateRangeStart: String!
    $dateRangeEnd: String!
  ) {
    interviewStageFilterValues(
      positionIds: $positionIds
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
    ) {
      values {
        id
        value
        label
      }
    }
  }
`;

/**
 * __useAnalyticsInterviewStageFilterValuesQuery__
 *
 * To run a query within a React component, call `useAnalyticsInterviewStageFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsInterviewStageFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsInterviewStageFilterValuesQuery({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *   },
 * });
 */
export function useAnalyticsInterviewStageFilterValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsInterviewStageFilterValuesQuery,
    AnalyticsInterviewStageFilterValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsInterviewStageFilterValuesQuery,
    AnalyticsInterviewStageFilterValuesQueryVariables
  >(AnalyticsInterviewStageFilterValuesDocument, options);
}
export function useAnalyticsInterviewStageFilterValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsInterviewStageFilterValuesQuery,
    AnalyticsInterviewStageFilterValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsInterviewStageFilterValuesQuery,
    AnalyticsInterviewStageFilterValuesQueryVariables
  >(AnalyticsInterviewStageFilterValuesDocument, options);
}
export type AnalyticsInterviewStageFilterValuesQueryHookResult = ReturnType<
  typeof useAnalyticsInterviewStageFilterValuesQuery
>;
export type AnalyticsInterviewStageFilterValuesLazyQueryHookResult = ReturnType<
  typeof useAnalyticsInterviewStageFilterValuesLazyQuery
>;
export type AnalyticsInterviewStageFilterValuesQueryResult = Apollo.QueryResult<
  AnalyticsInterviewStageFilterValuesQuery,
  AnalyticsInterviewStageFilterValuesQueryVariables
>;
export const AnalyticsOrgInterviewTotalsDocument = gql`
  query AnalyticsOrgInterviewTotals(
    $requestedOrganizationId: String
    $dateRangeStart: String!
    $dateRangeEnd: String!
  ) {
    orgInterviewTotals(
      requestedOrganizationId: $requestedOrganizationId
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
    ) {
      id
      values {
        totalInterviews
        totalDuration
        totalInterviewers
      }
    }
  }
`;

/**
 * __useAnalyticsOrgInterviewTotalsQuery__
 *
 * To run a query within a React component, call `useAnalyticsOrgInterviewTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsOrgInterviewTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsOrgInterviewTotalsQuery({
 *   variables: {
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *   },
 * });
 */
export function useAnalyticsOrgInterviewTotalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsOrgInterviewTotalsQuery,
    AnalyticsOrgInterviewTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsOrgInterviewTotalsQuery,
    AnalyticsOrgInterviewTotalsQueryVariables
  >(AnalyticsOrgInterviewTotalsDocument, options);
}
export function useAnalyticsOrgInterviewTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsOrgInterviewTotalsQuery,
    AnalyticsOrgInterviewTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsOrgInterviewTotalsQuery,
    AnalyticsOrgInterviewTotalsQueryVariables
  >(AnalyticsOrgInterviewTotalsDocument, options);
}
export type AnalyticsOrgInterviewTotalsQueryHookResult = ReturnType<
  typeof useAnalyticsOrgInterviewTotalsQuery
>;
export type AnalyticsOrgInterviewTotalsLazyQueryHookResult = ReturnType<
  typeof useAnalyticsOrgInterviewTotalsLazyQuery
>;
export type AnalyticsOrgInterviewTotalsQueryResult = Apollo.QueryResult<
  AnalyticsOrgInterviewTotalsQuery,
  AnalyticsOrgInterviewTotalsQueryVariables
>;
export const AnalyticsQualityReportConfigDocument = gql`
  query AnalyticsQualityReportConfig {
    currentUser {
      id
      organization {
        id
        qualityReportConfig {
          agendaSetEnabled
          candidateQuestionOpportunityEnabled
          candidateTalkRatioEnabled
          onTimeStartsEnabled
          problematicQuestionsEnabled
          longestMonologueEnabled
          speakingRateEnabled
          interactivityEnabled
          questionsAskedEnabled
          feedbackSubmissionTimeEnabled
          feedbackSubmissionRateEnabled
        }
      }
    }
  }
`;

/**
 * __useAnalyticsQualityReportConfigQuery__
 *
 * To run a query within a React component, call `useAnalyticsQualityReportConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsQualityReportConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsQualityReportConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsQualityReportConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AnalyticsQualityReportConfigQuery,
    AnalyticsQualityReportConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsQualityReportConfigQuery,
    AnalyticsQualityReportConfigQueryVariables
  >(AnalyticsQualityReportConfigDocument, options);
}
export function useAnalyticsQualityReportConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsQualityReportConfigQuery,
    AnalyticsQualityReportConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsQualityReportConfigQuery,
    AnalyticsQualityReportConfigQueryVariables
  >(AnalyticsQualityReportConfigDocument, options);
}
export type AnalyticsQualityReportConfigQueryHookResult = ReturnType<
  typeof useAnalyticsQualityReportConfigQuery
>;
export type AnalyticsQualityReportConfigLazyQueryHookResult = ReturnType<
  typeof useAnalyticsQualityReportConfigLazyQuery
>;
export type AnalyticsQualityReportConfigQueryResult = Apollo.QueryResult<
  AnalyticsQualityReportConfigQuery,
  AnalyticsQualityReportConfigQueryVariables
>;
export const AnalyticsReportDocument = gql`
  query AnalyticsReport(
    $primaryDimension: AnalyticsDimension!
    $secondaryDimension: AnalyticsDimension!
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $requestedOrganizationId: String
  ) {
    qualityReport(
      primaryDimension: $primaryDimension
      secondaryDimension: $secondaryDimension
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      data {
        ...ReportDatum
      }
      config {
        name
        enabled
      }
    }
  }
  ${ReportDatumFragmentDoc}
`;

/**
 * __useAnalyticsReportQuery__
 *
 * To run a query within a React component, call `useAnalyticsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsReportQuery({
 *   variables: {
 *      primaryDimension: // value for 'primaryDimension'
 *      secondaryDimension: // value for 'secondaryDimension'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsReportQuery,
    AnalyticsReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnalyticsReportQuery, AnalyticsReportQueryVariables>(
    AnalyticsReportDocument,
    options
  );
}
export function useAnalyticsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsReportQuery,
    AnalyticsReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsReportQuery,
    AnalyticsReportQueryVariables
  >(AnalyticsReportDocument, options);
}
export type AnalyticsReportQueryHookResult = ReturnType<
  typeof useAnalyticsReportQuery
>;
export type AnalyticsReportLazyQueryHookResult = ReturnType<
  typeof useAnalyticsReportLazyQuery
>;
export type AnalyticsReportQueryResult = Apollo.QueryResult<
  AnalyticsReportQuery,
  AnalyticsReportQueryVariables
>;
export const AnalyticsReportCsvDocument = gql`
  query AnalyticsReportCsv(
    $primaryDimension: AnalyticsDimension!
    $secondaryDimension: AnalyticsDimension!
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $requestedOrganizationId: String
  ) {
    qualityReportCsv(
      primaryDimension: $primaryDimension
      secondaryDimension: $secondaryDimension
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      requestedOrganizationId: $requestedOrganizationId
    ) {
      url
    }
  }
`;

/**
 * __useAnalyticsReportCsvQuery__
 *
 * To run a query within a React component, call `useAnalyticsReportCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsReportCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsReportCsvQuery({
 *   variables: {
 *      primaryDimension: // value for 'primaryDimension'
 *      secondaryDimension: // value for 'secondaryDimension'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsReportCsvQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsReportCsvQuery,
    AnalyticsReportCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsReportCsvQuery,
    AnalyticsReportCsvQueryVariables
  >(AnalyticsReportCsvDocument, options);
}
export function useAnalyticsReportCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsReportCsvQuery,
    AnalyticsReportCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsReportCsvQuery,
    AnalyticsReportCsvQueryVariables
  >(AnalyticsReportCsvDocument, options);
}
export type AnalyticsReportCsvQueryHookResult = ReturnType<
  typeof useAnalyticsReportCsvQuery
>;
export type AnalyticsReportCsvLazyQueryHookResult = ReturnType<
  typeof useAnalyticsReportCsvLazyQuery
>;
export type AnalyticsReportCsvQueryResult = Apollo.QueryResult<
  AnalyticsReportCsvQuery,
  AnalyticsReportCsvQueryVariables
>;
export const AnalyticsReportMyInsightsDocument = gql`
  query AnalyticsReportMyInsights(
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $includeRawResults: Boolean
    $requestedOrganizationId: String
  ) {
    qualityReportTrends(
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      includeRawResults: $includeRawResults
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      data {
        ...MyInsightsDatum
      }
      comparisonData {
        ...MyInsightsDatum
      }
      config {
        name
        enabled
      }
      bucketSize
      bucketInterval
    }
  }
  ${MyInsightsDatumFragmentDoc}
`;

/**
 * __useAnalyticsReportMyInsightsQuery__
 *
 * To run a query within a React component, call `useAnalyticsReportMyInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsReportMyInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsReportMyInsightsQuery({
 *   variables: {
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      includeRawResults: // value for 'includeRawResults'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsReportMyInsightsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsReportMyInsightsQuery,
    AnalyticsReportMyInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsReportMyInsightsQuery,
    AnalyticsReportMyInsightsQueryVariables
  >(AnalyticsReportMyInsightsDocument, options);
}
export function useAnalyticsReportMyInsightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsReportMyInsightsQuery,
    AnalyticsReportMyInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsReportMyInsightsQuery,
    AnalyticsReportMyInsightsQueryVariables
  >(AnalyticsReportMyInsightsDocument, options);
}
export type AnalyticsReportMyInsightsQueryHookResult = ReturnType<
  typeof useAnalyticsReportMyInsightsQuery
>;
export type AnalyticsReportMyInsightsLazyQueryHookResult = ReturnType<
  typeof useAnalyticsReportMyInsightsLazyQuery
>;
export type AnalyticsReportMyInsightsQueryResult = Apollo.QueryResult<
  AnalyticsReportMyInsightsQuery,
  AnalyticsReportMyInsightsQueryVariables
>;
export const AnalyticsReportRawDocument = gql`
  query AnalyticsReportRaw(
    $primaryDimension: AnalyticsDimension!
    $secondaryDimension: AnalyticsDimension!
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $includeRawResults: Boolean
  ) {
    qualityReport(
      primaryDimension: $primaryDimension
      secondaryDimension: $secondaryDimension
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      includeRawResults: $includeRawResults
    ) {
      id
      data {
        ...ReportDatum
        onTimeStartsRaw
        talkRatioRaw
        longestMonologueRaw
        isAgendaSetRaw
        candidateQuestionOpportunityRaw
        problematicQuestionsRaw
        speakingRateRaw
        interactivityRaw
        questionsAskedRaw
        feedbackSubmissionTimeRaw
        feedbackSubmissionRateRaw
      }
      config {
        name
        enabled
      }
    }
  }
  ${ReportDatumFragmentDoc}
`;

/**
 * __useAnalyticsReportRawQuery__
 *
 * To run a query within a React component, call `useAnalyticsReportRawQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsReportRawQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsReportRawQuery({
 *   variables: {
 *      primaryDimension: // value for 'primaryDimension'
 *      secondaryDimension: // value for 'secondaryDimension'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      includeRawResults: // value for 'includeRawResults'
 *   },
 * });
 */
export function useAnalyticsReportRawQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsReportRawQuery,
    AnalyticsReportRawQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsReportRawQuery,
    AnalyticsReportRawQueryVariables
  >(AnalyticsReportRawDocument, options);
}
export function useAnalyticsReportRawLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsReportRawQuery,
    AnalyticsReportRawQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsReportRawQuery,
    AnalyticsReportRawQueryVariables
  >(AnalyticsReportRawDocument, options);
}
export type AnalyticsReportRawQueryHookResult = ReturnType<
  typeof useAnalyticsReportRawQuery
>;
export type AnalyticsReportRawLazyQueryHookResult = ReturnType<
  typeof useAnalyticsReportRawLazyQuery
>;
export type AnalyticsReportRawQueryResult = Apollo.QueryResult<
  AnalyticsReportRawQuery,
  AnalyticsReportRawQueryVariables
>;
export const AnalyticsReportShareableUsersDocument = gql`
  query AnalyticsReportShareableUsers {
    currentUser {
      id
      organization {
        id
        users(canViewAnalyticsReport: true) {
          id
          firstName
          lastName
          fullName
          email
          profilePicUrl
        }
      }
    }
  }
`;

/**
 * __useAnalyticsReportShareableUsersQuery__
 *
 * To run a query within a React component, call `useAnalyticsReportShareableUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsReportShareableUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsReportShareableUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsReportShareableUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AnalyticsReportShareableUsersQuery,
    AnalyticsReportShareableUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsReportShareableUsersQuery,
    AnalyticsReportShareableUsersQueryVariables
  >(AnalyticsReportShareableUsersDocument, options);
}
export function useAnalyticsReportShareableUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsReportShareableUsersQuery,
    AnalyticsReportShareableUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsReportShareableUsersQuery,
    AnalyticsReportShareableUsersQueryVariables
  >(AnalyticsReportShareableUsersDocument, options);
}
export type AnalyticsReportShareableUsersQueryHookResult = ReturnType<
  typeof useAnalyticsReportShareableUsersQuery
>;
export type AnalyticsReportShareableUsersLazyQueryHookResult = ReturnType<
  typeof useAnalyticsReportShareableUsersLazyQuery
>;
export type AnalyticsReportShareableUsersQueryResult = Apollo.QueryResult<
  AnalyticsReportShareableUsersQuery,
  AnalyticsReportShareableUsersQueryVariables
>;
export const AnalyticsReportTrendsDocument = gql`
  query AnalyticsReportTrends(
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $requestedOrganizationId: String
  ) {
    qualityReportTrends(
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      data {
        ...ReportDatum
      }
      comparisonData {
        ...ReportDatum
      }
      config {
        name
        enabled
      }
      bucketSize
      bucketInterval
    }
  }
  ${ReportDatumFragmentDoc}
`;

/**
 * __useAnalyticsReportTrendsQuery__
 *
 * To run a query within a React component, call `useAnalyticsReportTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsReportTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsReportTrendsQuery({
 *   variables: {
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useAnalyticsReportTrendsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsReportTrendsQuery,
    AnalyticsReportTrendsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsReportTrendsQuery,
    AnalyticsReportTrendsQueryVariables
  >(AnalyticsReportTrendsDocument, options);
}
export function useAnalyticsReportTrendsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsReportTrendsQuery,
    AnalyticsReportTrendsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsReportTrendsQuery,
    AnalyticsReportTrendsQueryVariables
  >(AnalyticsReportTrendsDocument, options);
}
export type AnalyticsReportTrendsQueryHookResult = ReturnType<
  typeof useAnalyticsReportTrendsQuery
>;
export type AnalyticsReportTrendsLazyQueryHookResult = ReturnType<
  typeof useAnalyticsReportTrendsLazyQuery
>;
export type AnalyticsReportTrendsQueryResult = Apollo.QueryResult<
  AnalyticsReportTrendsQuery,
  AnalyticsReportTrendsQueryVariables
>;
export const AnalyticsSkillDescriptionDocument = gql`
  query AnalyticsSkillDescription($skillName: String!) {
    skillDescription(skillName: $skillName) {
      description
    }
  }
`;

/**
 * __useAnalyticsSkillDescriptionQuery__
 *
 * To run a query within a React component, call `useAnalyticsSkillDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsSkillDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsSkillDescriptionQuery({
 *   variables: {
 *      skillName: // value for 'skillName'
 *   },
 * });
 */
export function useAnalyticsSkillDescriptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsSkillDescriptionQuery,
    AnalyticsSkillDescriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsSkillDescriptionQuery,
    AnalyticsSkillDescriptionQueryVariables
  >(AnalyticsSkillDescriptionDocument, options);
}
export function useAnalyticsSkillDescriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsSkillDescriptionQuery,
    AnalyticsSkillDescriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsSkillDescriptionQuery,
    AnalyticsSkillDescriptionQueryVariables
  >(AnalyticsSkillDescriptionDocument, options);
}
export type AnalyticsSkillDescriptionQueryHookResult = ReturnType<
  typeof useAnalyticsSkillDescriptionQuery
>;
export type AnalyticsSkillDescriptionLazyQueryHookResult = ReturnType<
  typeof useAnalyticsSkillDescriptionLazyQuery
>;
export type AnalyticsSkillDescriptionQueryResult = Apollo.QueryResult<
  AnalyticsSkillDescriptionQuery,
  AnalyticsSkillDescriptionQueryVariables
>;
export const AnalyticsUserLifetimeValuesDocument = gql`
  query AnalyticsUserLifetimeValues($userId: String) {
    userInterviewTotals(userId: $userId) {
      id
      values {
        totalInterviews
        totalDuration
        totalCandidates
      }
    }
  }
`;

/**
 * __useAnalyticsUserLifetimeValuesQuery__
 *
 * To run a query within a React component, call `useAnalyticsUserLifetimeValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsUserLifetimeValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsUserLifetimeValuesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAnalyticsUserLifetimeValuesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AnalyticsUserLifetimeValuesQuery,
    AnalyticsUserLifetimeValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalyticsUserLifetimeValuesQuery,
    AnalyticsUserLifetimeValuesQueryVariables
  >(AnalyticsUserLifetimeValuesDocument, options);
}
export function useAnalyticsUserLifetimeValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsUserLifetimeValuesQuery,
    AnalyticsUserLifetimeValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsUserLifetimeValuesQuery,
    AnalyticsUserLifetimeValuesQueryVariables
  >(AnalyticsUserLifetimeValuesDocument, options);
}
export type AnalyticsUserLifetimeValuesQueryHookResult = ReturnType<
  typeof useAnalyticsUserLifetimeValuesQuery
>;
export type AnalyticsUserLifetimeValuesLazyQueryHookResult = ReturnType<
  typeof useAnalyticsUserLifetimeValuesLazyQuery
>;
export type AnalyticsUserLifetimeValuesQueryResult = Apollo.QueryResult<
  AnalyticsUserLifetimeValuesQuery,
  AnalyticsUserLifetimeValuesQueryVariables
>;
export const AtsDataStateDocument = gql`
  query AtsDataState {
    atsDataState
  }
`;

/**
 * __useAtsDataStateQuery__
 *
 * To run a query within a React component, call `useAtsDataStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtsDataStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtsDataStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useAtsDataStateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AtsDataStateQuery,
    AtsDataStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AtsDataStateQuery, AtsDataStateQueryVariables>(
    AtsDataStateDocument,
    options
  );
}
export function useAtsDataStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AtsDataStateQuery,
    AtsDataStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AtsDataStateQuery, AtsDataStateQueryVariables>(
    AtsDataStateDocument,
    options
  );
}
export type AtsDataStateQueryHookResult = ReturnType<
  typeof useAtsDataStateQuery
>;
export type AtsDataStateLazyQueryHookResult = ReturnType<
  typeof useAtsDataStateLazyQuery
>;
export type AtsDataStateQueryResult = Apollo.QueryResult<
  AtsDataStateQuery,
  AtsDataStateQueryVariables
>;
export const ClusteredDataDocument = gql`
  query ClusteredData(
    $topicsOrQuestions: String!
    $countBy: String!
    $positionDisplayTitle: String!
    $organizationId: String!
    $dateRangeStart: String
    $dateRangeEnd: String
  ) {
    clusteredData(
      topicsOrQuestions: $topicsOrQuestions
      countBy: $countBy
      positionDisplayTitle: $positionDisplayTitle
      organizationId: $organizationId
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
    ) {
      id
      data {
        id
        dataId
        label
        segment
        value
        countDataPoints
        avgPerformance
      }
    }
  }
`;

/**
 * __useClusteredDataQuery__
 *
 * To run a query within a React component, call `useClusteredDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClusteredDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClusteredDataQuery({
 *   variables: {
 *      topicsOrQuestions: // value for 'topicsOrQuestions'
 *      countBy: // value for 'countBy'
 *      positionDisplayTitle: // value for 'positionDisplayTitle'
 *      organizationId: // value for 'organizationId'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *   },
 * });
 */
export function useClusteredDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClusteredDataQuery,
    ClusteredDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClusteredDataQuery, ClusteredDataQueryVariables>(
    ClusteredDataDocument,
    options
  );
}
export function useClusteredDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClusteredDataQuery,
    ClusteredDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClusteredDataQuery, ClusteredDataQueryVariables>(
    ClusteredDataDocument,
    options
  );
}
export type ClusteredDataQueryHookResult = ReturnType<
  typeof useClusteredDataQuery
>;
export type ClusteredDataLazyQueryHookResult = ReturnType<
  typeof useClusteredDataLazyQuery
>;
export type ClusteredDataQueryResult = Apollo.QueryResult<
  ClusteredDataQuery,
  ClusteredDataQueryVariables
>;
export const CustomTopicCountsDocument = gql`
  query CustomTopicCounts(
    $keywords: [String!]!
    $matchRule: CustomTopicMatchRule!
    $speakerType: AnalyticsTopicSpeakerType!
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $requestedOrganizationId: String
  ) {
    customTopicCounts(
      keywords: $keywords
      matchRule: $matchRule
      speakerType: $speakerType
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      data {
        xLabel
        totalCalls
        topicDiscussed
      }
      bucketInterval
      bucketSize
    }
  }
`;

/**
 * __useCustomTopicCountsQuery__
 *
 * To run a query within a React component, call `useCustomTopicCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomTopicCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomTopicCountsQuery({
 *   variables: {
 *      keywords: // value for 'keywords'
 *      matchRule: // value for 'matchRule'
 *      speakerType: // value for 'speakerType'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useCustomTopicCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomTopicCountsQuery,
    CustomTopicCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomTopicCountsQuery,
    CustomTopicCountsQueryVariables
  >(CustomTopicCountsDocument, options);
}
export function useCustomTopicCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomTopicCountsQuery,
    CustomTopicCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomTopicCountsQuery,
    CustomTopicCountsQueryVariables
  >(CustomTopicCountsDocument, options);
}
export type CustomTopicCountsQueryHookResult = ReturnType<
  typeof useCustomTopicCountsQuery
>;
export type CustomTopicCountsLazyQueryHookResult = ReturnType<
  typeof useCustomTopicCountsLazyQuery
>;
export type CustomTopicCountsQueryResult = Apollo.QueryResult<
  CustomTopicCountsQuery,
  CustomTopicCountsQueryVariables
>;
export const CustomTopicOccurrencesDocument = gql`
  query CustomTopicOccurrences(
    $keywords: [String!]!
    $matchRule: CustomTopicMatchRule!
    $speakerType: AnalyticsTopicSpeakerType!
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $requestedOrganizationId: String
  ) {
    customTopicOccurrences(
      keywords: $keywords
      matchRule: $matchRule
      speakerType: $speakerType
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      requestedOrganizationId: $requestedOrganizationId
    ) {
      id
      data {
        callId
        callName
        callStartTime
        segmentId
        segmentText
        segmentStartTime
        speakerLabel
        speakerType
      }
    }
  }
`;

/**
 * __useCustomTopicOccurrencesQuery__
 *
 * To run a query within a React component, call `useCustomTopicOccurrencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomTopicOccurrencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomTopicOccurrencesQuery({
 *   variables: {
 *      keywords: // value for 'keywords'
 *      matchRule: // value for 'matchRule'
 *      speakerType: // value for 'speakerType'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useCustomTopicOccurrencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomTopicOccurrencesQuery,
    CustomTopicOccurrencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomTopicOccurrencesQuery,
    CustomTopicOccurrencesQueryVariables
  >(CustomTopicOccurrencesDocument, options);
}
export function useCustomTopicOccurrencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomTopicOccurrencesQuery,
    CustomTopicOccurrencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomTopicOccurrencesQuery,
    CustomTopicOccurrencesQueryVariables
  >(CustomTopicOccurrencesDocument, options);
}
export type CustomTopicOccurrencesQueryHookResult = ReturnType<
  typeof useCustomTopicOccurrencesQuery
>;
export type CustomTopicOccurrencesLazyQueryHookResult = ReturnType<
  typeof useCustomTopicOccurrencesLazyQuery
>;
export type CustomTopicOccurrencesQueryResult = Apollo.QueryResult<
  CustomTopicOccurrencesQuery,
  CustomTopicOccurrencesQueryVariables
>;
export const CustomTopicOccurrencesCsvDocument = gql`
  query CustomTopicOccurrencesCsv(
    $topicName: String!
    $keywords: [String!]!
    $matchRule: CustomTopicMatchRule!
    $speakerType: AnalyticsTopicSpeakerType!
    $dateRangeStart: String!
    $dateRangeEnd: String!
    $positions: [String!]
    $locations: [String!]
    $interviewers: [String!]
    $departments: [String!]
    $stages: [String!]
    $requestedOrganizationId: String
  ) {
    customTopicOccurrencesCsv(
      topicName: $topicName
      keywords: $keywords
      matchRule: $matchRule
      speakerType: $speakerType
      dateRangeStart: $dateRangeStart
      dateRangeEnd: $dateRangeEnd
      positions: $positions
      locations: $locations
      interviewers: $interviewers
      departments: $departments
      stages: $stages
      requestedOrganizationId: $requestedOrganizationId
    ) {
      url
    }
  }
`;

/**
 * __useCustomTopicOccurrencesCsvQuery__
 *
 * To run a query within a React component, call `useCustomTopicOccurrencesCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomTopicOccurrencesCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomTopicOccurrencesCsvQuery({
 *   variables: {
 *      topicName: // value for 'topicName'
 *      keywords: // value for 'keywords'
 *      matchRule: // value for 'matchRule'
 *      speakerType: // value for 'speakerType'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      positions: // value for 'positions'
 *      locations: // value for 'locations'
 *      interviewers: // value for 'interviewers'
 *      departments: // value for 'departments'
 *      stages: // value for 'stages'
 *      requestedOrganizationId: // value for 'requestedOrganizationId'
 *   },
 * });
 */
export function useCustomTopicOccurrencesCsvQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomTopicOccurrencesCsvQuery,
    CustomTopicOccurrencesCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomTopicOccurrencesCsvQuery,
    CustomTopicOccurrencesCsvQueryVariables
  >(CustomTopicOccurrencesCsvDocument, options);
}
export function useCustomTopicOccurrencesCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomTopicOccurrencesCsvQuery,
    CustomTopicOccurrencesCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomTopicOccurrencesCsvQuery,
    CustomTopicOccurrencesCsvQueryVariables
  >(CustomTopicOccurrencesCsvDocument, options);
}
export type CustomTopicOccurrencesCsvQueryHookResult = ReturnType<
  typeof useCustomTopicOccurrencesCsvQuery
>;
export type CustomTopicOccurrencesCsvLazyQueryHookResult = ReturnType<
  typeof useCustomTopicOccurrencesCsvLazyQuery
>;
export type CustomTopicOccurrencesCsvQueryResult = Apollo.QueryResult<
  CustomTopicOccurrencesCsvQuery,
  CustomTopicOccurrencesCsvQueryVariables
>;
export const CustomTopicsDocument = gql`
  query CustomTopics {
    currentUser {
      id
      organization {
        id
        customTopics {
          id
          name
          keywords
          matchRule
        }
      }
    }
  }
`;

/**
 * __useCustomTopicsQuery__
 *
 * To run a query within a React component, call `useCustomTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomTopicsQuery,
    CustomTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomTopicsQuery, CustomTopicsQueryVariables>(
    CustomTopicsDocument,
    options
  );
}
export function useCustomTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomTopicsQuery,
    CustomTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomTopicsQuery, CustomTopicsQueryVariables>(
    CustomTopicsDocument,
    options
  );
}
export type CustomTopicsQueryHookResult = ReturnType<
  typeof useCustomTopicsQuery
>;
export type CustomTopicsLazyQueryHookResult = ReturnType<
  typeof useCustomTopicsLazyQuery
>;
export type CustomTopicsQueryResult = Apollo.QueryResult<
  CustomTopicsQuery,
  CustomTopicsQueryVariables
>;
export const OrgGenderLabelsDocument = gql`
  query OrgGenderLabels {
    currentUser {
      id
      organization {
        id
        genderLabelMale
        genderLabelFemale
      }
    }
  }
`;

/**
 * __useOrgGenderLabelsQuery__
 *
 * To run a query within a React component, call `useOrgGenderLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgGenderLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgGenderLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgGenderLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgGenderLabelsQuery,
    OrgGenderLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgGenderLabelsQuery, OrgGenderLabelsQueryVariables>(
    OrgGenderLabelsDocument,
    options
  );
}
export function useOrgGenderLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgGenderLabelsQuery,
    OrgGenderLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgGenderLabelsQuery,
    OrgGenderLabelsQueryVariables
  >(OrgGenderLabelsDocument, options);
}
export type OrgGenderLabelsQueryHookResult = ReturnType<
  typeof useOrgGenderLabelsQuery
>;
export type OrgGenderLabelsLazyQueryHookResult = ReturnType<
  typeof useOrgGenderLabelsLazyQuery
>;
export type OrgGenderLabelsQueryResult = Apollo.QueryResult<
  OrgGenderLabelsQuery,
  OrgGenderLabelsQueryVariables
>;
export const OrgReportPositionOptionsDocument = gql`
  query OrgReportPositionOptions(
    $atsOnly: Boolean
    $activeOnly: Boolean
    $pagination: OffsetPaginationDetails
    $dateRangeStart: String!
    $dateRangeEnd: String!
  ) {
    currentUser {
      id
      organization {
        id
        positions(
          atsOnly: $atsOnly
          activeOnly: $activeOnly
          pagination: $pagination
          dateRangeStart: $dateRangeStart
          dateRangeEnd: $dateRangeEnd
        ) {
          pageInfo {
            totalPages
            totalRows
          }
          results {
            ...ReportPositionOption
          }
        }
      }
    }
  }
  ${ReportPositionOptionFragmentDoc}
`;

/**
 * __useOrgReportPositionOptionsQuery__
 *
 * To run a query within a React component, call `useOrgReportPositionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgReportPositionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgReportPositionOptionsQuery({
 *   variables: {
 *      atsOnly: // value for 'atsOnly'
 *      activeOnly: // value for 'activeOnly'
 *      pagination: // value for 'pagination'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *   },
 * });
 */
export function useOrgReportPositionOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgReportPositionOptionsQuery,
    OrgReportPositionOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgReportPositionOptionsQuery,
    OrgReportPositionOptionsQueryVariables
  >(OrgReportPositionOptionsDocument, options);
}
export function useOrgReportPositionOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgReportPositionOptionsQuery,
    OrgReportPositionOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgReportPositionOptionsQuery,
    OrgReportPositionOptionsQueryVariables
  >(OrgReportPositionOptionsDocument, options);
}
export type OrgReportPositionOptionsQueryHookResult = ReturnType<
  typeof useOrgReportPositionOptionsQuery
>;
export type OrgReportPositionOptionsLazyQueryHookResult = ReturnType<
  typeof useOrgReportPositionOptionsLazyQuery
>;
export type OrgReportPositionOptionsQueryResult = Apollo.QueryResult<
  OrgReportPositionOptionsQuery,
  OrgReportPositionOptionsQueryVariables
>;
export const PositionJobDescriptionSkillsDocument = gql`
  query PositionJobDescriptionSkills($positionId: ID!) {
    positionJobDescriptionSkills(positionId: $positionId) {
      skills {
        name
        description
      }
    }
  }
`;

/**
 * __usePositionJobDescriptionSkillsQuery__
 *
 * To run a query within a React component, call `usePositionJobDescriptionSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionJobDescriptionSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionJobDescriptionSkillsQuery({
 *   variables: {
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function usePositionJobDescriptionSkillsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PositionJobDescriptionSkillsQuery,
    PositionJobDescriptionSkillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PositionJobDescriptionSkillsQuery,
    PositionJobDescriptionSkillsQueryVariables
  >(PositionJobDescriptionSkillsDocument, options);
}
export function usePositionJobDescriptionSkillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PositionJobDescriptionSkillsQuery,
    PositionJobDescriptionSkillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PositionJobDescriptionSkillsQuery,
    PositionJobDescriptionSkillsQueryVariables
  >(PositionJobDescriptionSkillsDocument, options);
}
export type PositionJobDescriptionSkillsQueryHookResult = ReturnType<
  typeof usePositionJobDescriptionSkillsQuery
>;
export type PositionJobDescriptionSkillsLazyQueryHookResult = ReturnType<
  typeof usePositionJobDescriptionSkillsLazyQuery
>;
export type PositionJobDescriptionSkillsQueryResult = Apollo.QueryResult<
  PositionJobDescriptionSkillsQuery,
  PositionJobDescriptionSkillsQueryVariables
>;
export const SkillReportCsvDocument = gql`
  query SkillReportCsv($id: ID!) {
    skillReportCsv(id: $id) {
      url
    }
  }
`;

/**
 * __useSkillReportCsvQuery__
 *
 * To run a query within a React component, call `useSkillReportCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillReportCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillReportCsvQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSkillReportCsvQuery(
  baseOptions: Apollo.QueryHookOptions<
    SkillReportCsvQuery,
    SkillReportCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SkillReportCsvQuery, SkillReportCsvQueryVariables>(
    SkillReportCsvDocument,
    options
  );
}
export function useSkillReportCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SkillReportCsvQuery,
    SkillReportCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SkillReportCsvQuery, SkillReportCsvQueryVariables>(
    SkillReportCsvDocument,
    options
  );
}
export type SkillReportCsvQueryHookResult = ReturnType<
  typeof useSkillReportCsvQuery
>;
export type SkillReportCsvLazyQueryHookResult = ReturnType<
  typeof useSkillReportCsvLazyQuery
>;
export type SkillReportCsvQueryResult = Apollo.QueryResult<
  SkillReportCsvQuery,
  SkillReportCsvQueryVariables
>;
export const SkillReportDocument = gql`
  query SkillReport($id: ID!) {
    skillReport(id: $id) {
      ...SkillsReportWithResults
    }
  }
  ${SkillsReportWithResultsFragmentDoc}
`;

/**
 * __useSkillReportQuery__
 *
 * To run a query within a React component, call `useSkillReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSkillReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    SkillReportQuery,
    SkillReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SkillReportQuery, SkillReportQueryVariables>(
    SkillReportDocument,
    options
  );
}
export function useSkillReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SkillReportQuery,
    SkillReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SkillReportQuery, SkillReportQueryVariables>(
    SkillReportDocument,
    options
  );
}
export type SkillReportQueryHookResult = ReturnType<typeof useSkillReportQuery>;
export type SkillReportLazyQueryHookResult = ReturnType<
  typeof useSkillReportLazyQuery
>;
export type SkillReportQueryResult = Apollo.QueryResult<
  SkillReportQuery,
  SkillReportQueryVariables
>;
export const SkillReportStatusDocument = gql`
  query SkillReportStatus($id: ID!) {
    skillReport(id: $id) {
      id
      processingStatus
    }
  }
`;

/**
 * __useSkillReportStatusQuery__
 *
 * To run a query within a React component, call `useSkillReportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillReportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillReportStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSkillReportStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    SkillReportStatusQuery,
    SkillReportStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SkillReportStatusQuery,
    SkillReportStatusQueryVariables
  >(SkillReportStatusDocument, options);
}
export function useSkillReportStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SkillReportStatusQuery,
    SkillReportStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SkillReportStatusQuery,
    SkillReportStatusQueryVariables
  >(SkillReportStatusDocument, options);
}
export type SkillReportStatusQueryHookResult = ReturnType<
  typeof useSkillReportStatusQuery
>;
export type SkillReportStatusLazyQueryHookResult = ReturnType<
  typeof useSkillReportStatusLazyQuery
>;
export type SkillReportStatusQueryResult = Apollo.QueryResult<
  SkillReportStatusQuery,
  SkillReportStatusQueryVariables
>;
export const SkillReportsDocument = gql`
  query SkillReports {
    currentUser {
      id
      organization {
        id
        skillReports {
          id
          creatorId
          title
          source
          createdAt
          skills {
            name
            description
          }
          dateRangeStart
          dateRangeEnd
          positions {
            id
            displayTitle
          }
        }
      }
    }
  }
`;

/**
 * __useSkillReportsQuery__
 *
 * To run a query within a React component, call `useSkillReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkillReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SkillReportsQuery,
    SkillReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SkillReportsQuery, SkillReportsQueryVariables>(
    SkillReportsDocument,
    options
  );
}
export function useSkillReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SkillReportsQuery,
    SkillReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SkillReportsQuery, SkillReportsQueryVariables>(
    SkillReportsDocument,
    options
  );
}
export type SkillReportsQueryHookResult = ReturnType<
  typeof useSkillReportsQuery
>;
export type SkillReportsLazyQueryHookResult = ReturnType<
  typeof useSkillReportsLazyQuery
>;
export type SkillReportsQueryResult = Apollo.QueryResult<
  SkillReportsQuery,
  SkillReportsQueryVariables
>;
export const CallRecordingDownloadUrlDocument = gql`
  query CallRecordingDownloadUrl($callId: ID!) {
    callRecordingDownloadUrl(callId: $callId)
  }
`;

/**
 * __useCallRecordingDownloadUrlQuery__
 *
 * To run a query within a React component, call `useCallRecordingDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallRecordingDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallRecordingDownloadUrlQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCallRecordingDownloadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallRecordingDownloadUrlQuery,
    CallRecordingDownloadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CallRecordingDownloadUrlQuery,
    CallRecordingDownloadUrlQueryVariables
  >(CallRecordingDownloadUrlDocument, options);
}
export function useCallRecordingDownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallRecordingDownloadUrlQuery,
    CallRecordingDownloadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CallRecordingDownloadUrlQuery,
    CallRecordingDownloadUrlQueryVariables
  >(CallRecordingDownloadUrlDocument, options);
}
export type CallRecordingDownloadUrlQueryHookResult = ReturnType<
  typeof useCallRecordingDownloadUrlQuery
>;
export type CallRecordingDownloadUrlLazyQueryHookResult = ReturnType<
  typeof useCallRecordingDownloadUrlLazyQuery
>;
export type CallRecordingDownloadUrlQueryResult = Apollo.QueryResult<
  CallRecordingDownloadUrlQuery,
  CallRecordingDownloadUrlQueryVariables
>;
export const CallThumbnailsDocument = gql`
  query CallThumbnails($callIds: [ID!]!) {
    callThumbnails(callIds: $callIds) {
      callId
      thumbnailUrl
    }
  }
`;

/**
 * __useCallThumbnailsQuery__
 *
 * To run a query within a React component, call `useCallThumbnailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallThumbnailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallThumbnailsQuery({
 *   variables: {
 *      callIds: // value for 'callIds'
 *   },
 * });
 */
export function useCallThumbnailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallThumbnailsQuery,
    CallThumbnailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallThumbnailsQuery, CallThumbnailsQueryVariables>(
    CallThumbnailsDocument,
    options
  );
}
export function useCallThumbnailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallThumbnailsQuery,
    CallThumbnailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallThumbnailsQuery, CallThumbnailsQueryVariables>(
    CallThumbnailsDocument,
    options
  );
}
export type CallThumbnailsQueryHookResult = ReturnType<
  typeof useCallThumbnailsQuery
>;
export type CallThumbnailsLazyQueryHookResult = ReturnType<
  typeof useCallThumbnailsLazyQuery
>;
export type CallThumbnailsQueryResult = Apollo.QueryResult<
  CallThumbnailsQuery,
  CallThumbnailsQueryVariables
>;
export const CallsAiTagsStatusDocument = gql`
  query CallsAiTagsStatus($callIds: [ID!]!, $backfill: Boolean!) {
    callsAiTagsStatus(callIds: $callIds, backfill: $backfill) {
      callId
      tagsProcessingStatus
      canGenerateTags
    }
  }
`;

/**
 * __useCallsAiTagsStatusQuery__
 *
 * To run a query within a React component, call `useCallsAiTagsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsAiTagsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsAiTagsStatusQuery({
 *   variables: {
 *      callIds: // value for 'callIds'
 *      backfill: // value for 'backfill'
 *   },
 * });
 */
export function useCallsAiTagsStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallsAiTagsStatusQuery,
    CallsAiTagsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CallsAiTagsStatusQuery,
    CallsAiTagsStatusQueryVariables
  >(CallsAiTagsStatusDocument, options);
}
export function useCallsAiTagsStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallsAiTagsStatusQuery,
    CallsAiTagsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CallsAiTagsStatusQuery,
    CallsAiTagsStatusQueryVariables
  >(CallsAiTagsStatusDocument, options);
}
export type CallsAiTagsStatusQueryHookResult = ReturnType<
  typeof useCallsAiTagsStatusQuery
>;
export type CallsAiTagsStatusLazyQueryHookResult = ReturnType<
  typeof useCallsAiTagsStatusLazyQuery
>;
export type CallsAiTagsStatusQueryResult = Apollo.QueryResult<
  CallsAiTagsStatusQuery,
  CallsAiTagsStatusQueryVariables
>;
export const IaCallStatusDocument = gql`
  query IaCallStatus($id: ID!) {
    call(id: $id) {
      ...IaCallStatus
    }
  }
  ${IaCallStatusFragmentDoc}
`;

/**
 * __useIaCallStatusQuery__
 *
 * To run a query within a React component, call `useIaCallStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useIaCallStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIaCallStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIaCallStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    IaCallStatusQuery,
    IaCallStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IaCallStatusQuery, IaCallStatusQueryVariables>(
    IaCallStatusDocument,
    options
  );
}
export function useIaCallStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IaCallStatusQuery,
    IaCallStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IaCallStatusQuery, IaCallStatusQueryVariables>(
    IaCallStatusDocument,
    options
  );
}
export type IaCallStatusQueryHookResult = ReturnType<
  typeof useIaCallStatusQuery
>;
export type IaCallStatusLazyQueryHookResult = ReturnType<
  typeof useIaCallStatusLazyQuery
>;
export type IaCallStatusQueryResult = Apollo.QueryResult<
  IaCallStatusQuery,
  IaCallStatusQueryVariables
>;
export const NotesForScorecardDocument = gql`
  query NotesForScorecard(
    $callId: ID!
    $currentUserOnly: Boolean
    $tabUrl: String
  ) {
    call(id: $callId) {
      id
      aiNotesLoading
      generalNotes(currentUserOnly: $currentUserOnly) {
        ...NoteParts
      }
      endTime
      questions {
        id
        description
        leverFieldType
        isAtsSingleLineInput @client
        canAutofill @client
        questionNotes(currentUserOnly: $currentUserOnly, forAutofill: true) {
          ...NoteParts
        }
      }
      aiNotesCustomFormat {
        format
        customTemplateId
        customTemplateType
      }
      aiScorecardQuestionNotes(tabUrl: $tabUrl) {
        id
        scorecardQuestion
        aiQuestion
        callAiNoteAnswerItems {
          id
          currentText
        }
        startTime
        isAtsSingleLineInput
      }
      aiScorecardCustomSummary
    }
  }
  ${NotePartsFragmentDoc}
`;

/**
 * __useNotesForScorecardQuery__
 *
 * To run a query within a React component, call `useNotesForScorecardQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesForScorecardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesForScorecardQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *      currentUserOnly: // value for 'currentUserOnly'
 *      tabUrl: // value for 'tabUrl'
 *   },
 * });
 */
export function useNotesForScorecardQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotesForScorecardQuery,
    NotesForScorecardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotesForScorecardQuery,
    NotesForScorecardQueryVariables
  >(NotesForScorecardDocument, options);
}
export function useNotesForScorecardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotesForScorecardQuery,
    NotesForScorecardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotesForScorecardQuery,
    NotesForScorecardQueryVariables
  >(NotesForScorecardDocument, options);
}
export type NotesForScorecardQueryHookResult = ReturnType<
  typeof useNotesForScorecardQuery
>;
export type NotesForScorecardLazyQueryHookResult = ReturnType<
  typeof useNotesForScorecardLazyQuery
>;
export type NotesForScorecardQueryResult = Apollo.QueryResult<
  NotesForScorecardQuery,
  NotesForScorecardQueryVariables
>;
export const NotesForScorecardV3Document = gql`
  query NotesForScorecardV3(
    $callId: ID!
    $tabUrl: String
    $scorecardItems: [ExtensionScorecardItem!]
  ) {
    call(id: $callId) {
      id
      aiNotesLoading
      notes(currentUserOnly: true) {
        ...NoteParts
        scorecardQuestionId
      }
      generalNotes(currentUserOnly: true) @client {
        ...NoteParts
      }
      scorecard {
        id
        questions {
          id
          itemPlainText
          leverFieldType
          isAtsSingleLineInput @client
          marked
          markedTime
          questionNotes(currentUserOnly: true) @client {
            ...NoteParts
          }
        }
      }
      draftScorecard {
        id
        processingStatus
        failureReason
      }
      aiNotesCustomFormat {
        format
        customTemplateId
        customTemplateType
      }
      aiScorecardQuestionNotes(
        tabUrl: $tabUrl
        scorecardItems: $scorecardItems
      ) {
        id
        scorecardQuestion
        aiQuestion
        callAiNoteAnswerItems {
          id
          currentText
        }
        startTime
        isAtsSingleLineInput
      }
      aiScorecardCustomSummary
      completedGreenhouseScorecard {
        id
        greenhouseId
      }
    }
  }
  ${NotePartsFragmentDoc}
`;

/**
 * __useNotesForScorecardV3Query__
 *
 * To run a query within a React component, call `useNotesForScorecardV3Query` and pass it any options that fit your needs.
 * When your component renders, `useNotesForScorecardV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesForScorecardV3Query({
 *   variables: {
 *      callId: // value for 'callId'
 *      tabUrl: // value for 'tabUrl'
 *      scorecardItems: // value for 'scorecardItems'
 *   },
 * });
 */
export function useNotesForScorecardV3Query(
  baseOptions: Apollo.QueryHookOptions<
    NotesForScorecardV3Query,
    NotesForScorecardV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotesForScorecardV3Query,
    NotesForScorecardV3QueryVariables
  >(NotesForScorecardV3Document, options);
}
export function useNotesForScorecardV3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotesForScorecardV3Query,
    NotesForScorecardV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotesForScorecardV3Query,
    NotesForScorecardV3QueryVariables
  >(NotesForScorecardV3Document, options);
}
export type NotesForScorecardV3QueryHookResult = ReturnType<
  typeof useNotesForScorecardV3Query
>;
export type NotesForScorecardV3LazyQueryHookResult = ReturnType<
  typeof useNotesForScorecardV3LazyQuery
>;
export type NotesForScorecardV3QueryResult = Apollo.QueryResult<
  NotesForScorecardV3Query,
  NotesForScorecardV3QueryVariables
>;
export const CallAiNotesDocument = gql`
  query CallAiNotes($callId: ID!) {
    callAiNotes(callId: $callId) {
      callId
      aiNotesProcessingStatus
      questionsAndAnswers {
        id
        startTime
        endTime
        segmentIndex
        question
        answer
        originalQuestion
        originalAnswer
        matchReason
        error
        edited
        isCandidate
        answerItems {
          id
          text
          edited
        }
        tags
      }
    }
  }
`;

/**
 * __useCallAiNotesQuery__
 *
 * To run a query within a React component, call `useCallAiNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallAiNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallAiNotesQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCallAiNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallAiNotesQuery,
    CallAiNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallAiNotesQuery, CallAiNotesQueryVariables>(
    CallAiNotesDocument,
    options
  );
}
export function useCallAiNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallAiNotesQuery,
    CallAiNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallAiNotesQuery, CallAiNotesQueryVariables>(
    CallAiNotesDocument,
    options
  );
}
export type CallAiNotesQueryHookResult = ReturnType<typeof useCallAiNotesQuery>;
export type CallAiNotesLazyQueryHookResult = ReturnType<
  typeof useCallAiNotesLazyQuery
>;
export type CallAiNotesQueryResult = Apollo.QueryResult<
  CallAiNotesQuery,
  CallAiNotesQueryVariables
>;
export const CallAiSummaryDocument = gql`
  query CallAiSummary(
    $callId: ID!
    $format: CallAiSummaryFormat!
    $customTemplateId: ID
  ) {
    callAiSummary(
      callId: $callId
      format: $format
      customTemplateId: $customTemplateId
    ) {
      id
      status
      format
      outputText
      customTemplateId
      processingStartedAt
      targetSpeakerTags
      customTopics {
        title
        questions
      }
      headers {
        id
        text
        notes {
          id
          text
          tag
          startTime
          startTimes
          variation1
          variation2
        }
      }
    }
  }
`;

/**
 * __useCallAiSummaryQuery__
 *
 * To run a query within a React component, call `useCallAiSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallAiSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallAiSummaryQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *      format: // value for 'format'
 *      customTemplateId: // value for 'customTemplateId'
 *   },
 * });
 */
export function useCallAiSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallAiSummaryQuery,
    CallAiSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallAiSummaryQuery, CallAiSummaryQueryVariables>(
    CallAiSummaryDocument,
    options
  );
}
export function useCallAiSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallAiSummaryQuery,
    CallAiSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallAiSummaryQuery, CallAiSummaryQueryVariables>(
    CallAiSummaryDocument,
    options
  );
}
export type CallAiSummaryQueryHookResult = ReturnType<
  typeof useCallAiSummaryQuery
>;
export type CallAiSummaryLazyQueryHookResult = ReturnType<
  typeof useCallAiSummaryLazyQuery
>;
export type CallAiSummaryQueryResult = Apollo.QueryResult<
  CallAiSummaryQuery,
  CallAiSummaryQueryVariables
>;
export const CallAiTopicsDocument = gql`
  query CallAiTopics($callId: ID!) {
    callAiTopics(callId: $callId) {
      callId
      aiTopicsStatuses {
        id
        topic
        status
      }
      topics {
        topic
        summary {
          id
          text
          startTime
        }
      }
    }
  }
`;

/**
 * __useCallAiTopicsQuery__
 *
 * To run a query within a React component, call `useCallAiTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallAiTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallAiTopicsQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCallAiTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallAiTopicsQuery,
    CallAiTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallAiTopicsQuery, CallAiTopicsQueryVariables>(
    CallAiTopicsDocument,
    options
  );
}
export function useCallAiTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallAiTopicsQuery,
    CallAiTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallAiTopicsQuery, CallAiTopicsQueryVariables>(
    CallAiTopicsDocument,
    options
  );
}
export type CallAiTopicsQueryHookResult = ReturnType<
  typeof useCallAiTopicsQuery
>;
export type CallAiTopicsLazyQueryHookResult = ReturnType<
  typeof useCallAiTopicsLazyQuery
>;
export type CallAiTopicsQueryResult = Apollo.QueryResult<
  CallAiTopicsQuery,
  CallAiTopicsQueryVariables
>;
export const CallAutoSummaryBetaDocument = gql`
  query CallAutoSummaryBeta($id: ID!) {
    call(id: $id) {
      id
      autoSummary
    }
  }
`;

/**
 * __useCallAutoSummaryBetaQuery__
 *
 * To run a query within a React component, call `useCallAutoSummaryBetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallAutoSummaryBetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallAutoSummaryBetaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCallAutoSummaryBetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallAutoSummaryBetaQuery,
    CallAutoSummaryBetaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CallAutoSummaryBetaQuery,
    CallAutoSummaryBetaQueryVariables
  >(CallAutoSummaryBetaDocument, options);
}
export function useCallAutoSummaryBetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallAutoSummaryBetaQuery,
    CallAutoSummaryBetaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CallAutoSummaryBetaQuery,
    CallAutoSummaryBetaQueryVariables
  >(CallAutoSummaryBetaDocument, options);
}
export type CallAutoSummaryBetaQueryHookResult = ReturnType<
  typeof useCallAutoSummaryBetaQuery
>;
export type CallAutoSummaryBetaLazyQueryHookResult = ReturnType<
  typeof useCallAutoSummaryBetaLazyQuery
>;
export type CallAutoSummaryBetaQueryResult = Apollo.QueryResult<
  CallAutoSummaryBetaQuery,
  CallAutoSummaryBetaQueryVariables
>;
export const CallBetaDocument = gql`
  query CallBeta($id: ID!, $redactedRecording: Boolean) {
    call(id: $id) {
      ...CallBeta
    }
  }
  ${CallBetaFragmentDoc}
`;

/**
 * __useCallBetaQuery__
 *
 * To run a query within a React component, call `useCallBetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallBetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallBetaQuery({
 *   variables: {
 *      id: // value for 'id'
 *      redactedRecording: // value for 'redactedRecording'
 *   },
 * });
 */
export function useCallBetaQuery(
  baseOptions: Apollo.QueryHookOptions<CallBetaQuery, CallBetaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallBetaQuery, CallBetaQueryVariables>(
    CallBetaDocument,
    options
  );
}
export function useCallBetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallBetaQuery,
    CallBetaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallBetaQuery, CallBetaQueryVariables>(
    CallBetaDocument,
    options
  );
}
export type CallBetaQueryHookResult = ReturnType<typeof useCallBetaQuery>;
export type CallBetaLazyQueryHookResult = ReturnType<
  typeof useCallBetaLazyQuery
>;
export type CallBetaQueryResult = Apollo.QueryResult<
  CallBetaQuery,
  CallBetaQueryVariables
>;
export const CallChaptersDocument = gql`
  query CallChapters($callId: ID!) {
    callChapters(callId: $callId) {
      callId
      chapters {
        id
        callId
        text
        startTime
        endTime
        thumbnailUrl
        questionAndAnswer {
          id
          startTime
          endTime
          segmentIndex
          question
          answer
          originalQuestion
          originalAnswer
          matchReason
          error
          edited
          isCandidate
          answerItems {
            id
            text
            edited
          }
        }
        tags
      }
    }
  }
`;

/**
 * __useCallChaptersQuery__
 *
 * To run a query within a React component, call `useCallChaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallChaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallChaptersQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCallChaptersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallChaptersQuery,
    CallChaptersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallChaptersQuery, CallChaptersQueryVariables>(
    CallChaptersDocument,
    options
  );
}
export function useCallChaptersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallChaptersQuery,
    CallChaptersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallChaptersQuery, CallChaptersQueryVariables>(
    CallChaptersDocument,
    options
  );
}
export type CallChaptersQueryHookResult = ReturnType<
  typeof useCallChaptersQuery
>;
export type CallChaptersLazyQueryHookResult = ReturnType<
  typeof useCallChaptersLazyQuery
>;
export type CallChaptersQueryResult = Apollo.QueryResult<
  CallChaptersQuery,
  CallChaptersQueryVariables
>;
export const CallClipsDocument = gql`
  query CallClips($callId: ID!) {
    call(id: $callId) {
      id
      callClips {
        ...CallClip
      }
    }
  }
  ${CallClipFragmentDoc}
`;

/**
 * __useCallClipsQuery__
 *
 * To run a query within a React component, call `useCallClipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallClipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallClipsQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCallClipsQuery(
  baseOptions: Apollo.QueryHookOptions<CallClipsQuery, CallClipsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallClipsQuery, CallClipsQueryVariables>(
    CallClipsDocument,
    options
  );
}
export function useCallClipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallClipsQuery,
    CallClipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallClipsQuery, CallClipsQueryVariables>(
    CallClipsDocument,
    options
  );
}
export type CallClipsQueryHookResult = ReturnType<typeof useCallClipsQuery>;
export type CallClipsLazyQueryHookResult = ReturnType<
  typeof useCallClipsLazyQuery
>;
export type CallClipsQueryResult = Apollo.QueryResult<
  CallClipsQuery,
  CallClipsQueryVariables
>;
export const CallNotesBetaDocument = gql`
  query CallNotesBeta($callId: ID!, $notesForCurrentUserOnly: Boolean!) {
    call(id: $callId) {
      id
      questions {
        ...CallQuestion
      }
      generalNotes(currentUserOnly: $notesForCurrentUserOnly) {
        ...CallNote
      }
    }
  }
  ${CallQuestionFragmentDoc}
  ${CallNoteFragmentDoc}
`;

/**
 * __useCallNotesBetaQuery__
 *
 * To run a query within a React component, call `useCallNotesBetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallNotesBetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallNotesBetaQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *      notesForCurrentUserOnly: // value for 'notesForCurrentUserOnly'
 *   },
 * });
 */
export function useCallNotesBetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallNotesBetaQuery,
    CallNotesBetaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallNotesBetaQuery, CallNotesBetaQueryVariables>(
    CallNotesBetaDocument,
    options
  );
}
export function useCallNotesBetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallNotesBetaQuery,
    CallNotesBetaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallNotesBetaQuery, CallNotesBetaQueryVariables>(
    CallNotesBetaDocument,
    options
  );
}
export type CallNotesBetaQueryHookResult = ReturnType<
  typeof useCallNotesBetaQuery
>;
export type CallNotesBetaLazyQueryHookResult = ReturnType<
  typeof useCallNotesBetaLazyQuery
>;
export type CallNotesBetaQueryResult = Apollo.QueryResult<
  CallNotesBetaQuery,
  CallNotesBetaQueryVariables
>;
export const CallNotesV3Document = gql`
  query CallNotesV3($id: ID!, $notesForCurrentUserOnly: Boolean!) {
    call(id: $id) {
      id
      notesScorecards {
        id
        items {
          ...ScorecardItemNotes
        }
      }
      notes(currentUserOnly: $notesForCurrentUserOnly) {
        ...CallNote
      }
      generalNotes(currentUserOnly: $notesForCurrentUserOnly) @client {
        ...CallNote
      }
    }
  }
  ${ScorecardItemNotesFragmentDoc}
  ${CallNoteFragmentDoc}
`;

/**
 * __useCallNotesV3Query__
 *
 * To run a query within a React component, call `useCallNotesV3Query` and pass it any options that fit your needs.
 * When your component renders, `useCallNotesV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallNotesV3Query({
 *   variables: {
 *      id: // value for 'id'
 *      notesForCurrentUserOnly: // value for 'notesForCurrentUserOnly'
 *   },
 * });
 */
export function useCallNotesV3Query(
  baseOptions: Apollo.QueryHookOptions<
    CallNotesV3Query,
    CallNotesV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallNotesV3Query, CallNotesV3QueryVariables>(
    CallNotesV3Document,
    options
  );
}
export function useCallNotesV3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallNotesV3Query,
    CallNotesV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallNotesV3Query, CallNotesV3QueryVariables>(
    CallNotesV3Document,
    options
  );
}
export type CallNotesV3QueryHookResult = ReturnType<typeof useCallNotesV3Query>;
export type CallNotesV3LazyQueryHookResult = ReturnType<
  typeof useCallNotesV3LazyQuery
>;
export type CallNotesV3QueryResult = Apollo.QueryResult<
  CallNotesV3Query,
  CallNotesV3QueryVariables
>;
export const CallScorecardDocument = gql`
  query CallScorecard($callId: ID!) {
    call(id: $callId) {
      id
      canViewOtherScores
      scorecards {
        ...InterviewerScorecard
      }
      currentUserScorecard @client {
        ...InterviewerScorecard
      }
      otherUsersScorecards @client {
        ...InterviewerScorecard
      }
    }
  }
  ${InterviewerScorecardFragmentDoc}
`;

/**
 * __useCallScorecardQuery__
 *
 * To run a query within a React component, call `useCallScorecardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallScorecardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallScorecardQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCallScorecardQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallScorecardQuery,
    CallScorecardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallScorecardQuery, CallScorecardQueryVariables>(
    CallScorecardDocument,
    options
  );
}
export function useCallScorecardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallScorecardQuery,
    CallScorecardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallScorecardQuery, CallScorecardQueryVariables>(
    CallScorecardDocument,
    options
  );
}
export type CallScorecardQueryHookResult = ReturnType<
  typeof useCallScorecardQuery
>;
export type CallScorecardLazyQueryHookResult = ReturnType<
  typeof useCallScorecardLazyQuery
>;
export type CallScorecardQueryResult = Apollo.QueryResult<
  CallScorecardQuery,
  CallScorecardQueryVariables
>;
export const CallTranscriptBetaDocument = gql`
  query CallTranscriptBeta($callId: ID!) {
    call(id: $callId) {
      id
      transcript {
        ...TranscriptSegment
      }
      speakers {
        id
        label
      }
    }
  }
  ${TranscriptSegmentFragmentDoc}
`;

/**
 * __useCallTranscriptBetaQuery__
 *
 * To run a query within a React component, call `useCallTranscriptBetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallTranscriptBetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallTranscriptBetaQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCallTranscriptBetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallTranscriptBetaQuery,
    CallTranscriptBetaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CallTranscriptBetaQuery,
    CallTranscriptBetaQueryVariables
  >(CallTranscriptBetaDocument, options);
}
export function useCallTranscriptBetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallTranscriptBetaQuery,
    CallTranscriptBetaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CallTranscriptBetaQuery,
    CallTranscriptBetaQueryVariables
  >(CallTranscriptBetaDocument, options);
}
export type CallTranscriptBetaQueryHookResult = ReturnType<
  typeof useCallTranscriptBetaQuery
>;
export type CallTranscriptBetaLazyQueryHookResult = ReturnType<
  typeof useCallTranscriptBetaLazyQuery
>;
export type CallTranscriptBetaQueryResult = Apollo.QueryResult<
  CallTranscriptBetaQuery,
  CallTranscriptBetaQueryVariables
>;
export const CandidatePositionChaptersDocument = gql`
  query CandidatePositionChapters($candidateId: ID!, $positionId: ID!) {
    candidatePositionChapters(
      candidateId: $candidateId
      positionId: $positionId
    ) {
      chapters {
        id
        callId
        text
        startTime
        endTime
        thumbnailUrl
        embedding
        questionEmbedding
        answerEmbedding
        chapterTitleEmbedding
        callName
        callTime
        streamableVideo {
          url
          height
          width
        }
        streamableAudio {
          url
          height
          width
        }
        callInterviewers {
          id
          fullName
        }
        questionAndAnswer {
          id
          startTime
          endTime
          segmentIndex
          question
          answer
          originalQuestion
          originalAnswer
          matchReason
          error
          edited
          isCandidate
          answerItems {
            id
            text
            edited
          }
        }
      }
      candidateChaptersStatus
    }
  }
`;

/**
 * __useCandidatePositionChaptersQuery__
 *
 * To run a query within a React component, call `useCandidatePositionChaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatePositionChaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatePositionChaptersQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useCandidatePositionChaptersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidatePositionChaptersQuery,
    CandidatePositionChaptersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidatePositionChaptersQuery,
    CandidatePositionChaptersQueryVariables
  >(CandidatePositionChaptersDocument, options);
}
export function useCandidatePositionChaptersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatePositionChaptersQuery,
    CandidatePositionChaptersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidatePositionChaptersQuery,
    CandidatePositionChaptersQueryVariables
  >(CandidatePositionChaptersDocument, options);
}
export type CandidatePositionChaptersQueryHookResult = ReturnType<
  typeof useCandidatePositionChaptersQuery
>;
export type CandidatePositionChaptersLazyQueryHookResult = ReturnType<
  typeof useCandidatePositionChaptersLazyQuery
>;
export type CandidatePositionChaptersQueryResult = Apollo.QueryResult<
  CandidatePositionChaptersQuery,
  CandidatePositionChaptersQueryVariables
>;
export const CandidateSummaryDocument = gql`
  query CandidateSummary($candidateId: ID!, $positionId: ID, $templateId: ID) {
    candidateSummary(
      id: $candidateId
      positionId: $positionId
      templateId: $templateId
    ) {
      ...CandidateSummary
    }
  }
  ${CandidateSummaryFragmentDoc}
`;

/**
 * __useCandidateSummaryQuery__
 *
 * To run a query within a React component, call `useCandidateSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateSummaryQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      positionId: // value for 'positionId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useCandidateSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateSummaryQuery,
    CandidateSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidateSummaryQuery, CandidateSummaryQueryVariables>(
    CandidateSummaryDocument,
    options
  );
}
export function useCandidateSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateSummaryQuery,
    CandidateSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateSummaryQuery,
    CandidateSummaryQueryVariables
  >(CandidateSummaryDocument, options);
}
export type CandidateSummaryQueryHookResult = ReturnType<
  typeof useCandidateSummaryQuery
>;
export type CandidateSummaryLazyQueryHookResult = ReturnType<
  typeof useCandidateSummaryLazyQuery
>;
export type CandidateSummaryQueryResult = Apollo.QueryResult<
  CandidateSummaryQuery,
  CandidateSummaryQueryVariables
>;
export const CandidateSummaryCallCountDocument = gql`
  query CandidateSummaryCallCount($candidateId: ID!, $positionId: ID) {
    candidateSummaryCallCount(
      candidateId: $candidateId
      positionId: $positionId
    ) {
      count
    }
  }
`;

/**
 * __useCandidateSummaryCallCountQuery__
 *
 * To run a query within a React component, call `useCandidateSummaryCallCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateSummaryCallCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateSummaryCallCountQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useCandidateSummaryCallCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateSummaryCallCountQuery,
    CandidateSummaryCallCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateSummaryCallCountQuery,
    CandidateSummaryCallCountQueryVariables
  >(CandidateSummaryCallCountDocument, options);
}
export function useCandidateSummaryCallCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateSummaryCallCountQuery,
    CandidateSummaryCallCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateSummaryCallCountQuery,
    CandidateSummaryCallCountQueryVariables
  >(CandidateSummaryCallCountDocument, options);
}
export type CandidateSummaryCallCountQueryHookResult = ReturnType<
  typeof useCandidateSummaryCallCountQuery
>;
export type CandidateSummaryCallCountLazyQueryHookResult = ReturnType<
  typeof useCandidateSummaryCallCountLazyQuery
>;
export type CandidateSummaryCallCountQueryResult = Apollo.QueryResult<
  CandidateSummaryCallCountQuery,
  CandidateSummaryCallCountQueryVariables
>;
export const CandidateSummaryPositionPermissionsDocument = gql`
  query CandidateSummaryPositionPermissions($positionId: ID) {
    candidateSummaryPositionPermissions(positionId: $positionId) {
      canViewSummaries
      canEditTemplates
    }
  }
`;

/**
 * __useCandidateSummaryPositionPermissionsQuery__
 *
 * To run a query within a React component, call `useCandidateSummaryPositionPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateSummaryPositionPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateSummaryPositionPermissionsQuery({
 *   variables: {
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useCandidateSummaryPositionPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CandidateSummaryPositionPermissionsQuery,
    CandidateSummaryPositionPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateSummaryPositionPermissionsQuery,
    CandidateSummaryPositionPermissionsQueryVariables
  >(CandidateSummaryPositionPermissionsDocument, options);
}
export function useCandidateSummaryPositionPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateSummaryPositionPermissionsQuery,
    CandidateSummaryPositionPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateSummaryPositionPermissionsQuery,
    CandidateSummaryPositionPermissionsQueryVariables
  >(CandidateSummaryPositionPermissionsDocument, options);
}
export type CandidateSummaryPositionPermissionsQueryHookResult = ReturnType<
  typeof useCandidateSummaryPositionPermissionsQuery
>;
export type CandidateSummaryPositionPermissionsLazyQueryHookResult = ReturnType<
  typeof useCandidateSummaryPositionPermissionsLazyQuery
>;
export type CandidateSummaryPositionPermissionsQueryResult = Apollo.QueryResult<
  CandidateSummaryPositionPermissionsQuery,
  CandidateSummaryPositionPermissionsQueryVariables
>;
export const CandidateSummaryTemplateDocument = gql`
  query CandidateSummaryTemplate($templateId: ID!, $positionId: ID) {
    candidateSummaryTemplate(templateId: $templateId, positionId: $positionId) {
      ...CandidateSummaryTemplate
    }
  }
  ${CandidateSummaryTemplateFragmentDoc}
`;

/**
 * __useCandidateSummaryTemplateQuery__
 *
 * To run a query within a React component, call `useCandidateSummaryTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateSummaryTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateSummaryTemplateQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useCandidateSummaryTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateSummaryTemplateQuery,
    CandidateSummaryTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateSummaryTemplateQuery,
    CandidateSummaryTemplateQueryVariables
  >(CandidateSummaryTemplateDocument, options);
}
export function useCandidateSummaryTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateSummaryTemplateQuery,
    CandidateSummaryTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateSummaryTemplateQuery,
    CandidateSummaryTemplateQueryVariables
  >(CandidateSummaryTemplateDocument, options);
}
export type CandidateSummaryTemplateQueryHookResult = ReturnType<
  typeof useCandidateSummaryTemplateQuery
>;
export type CandidateSummaryTemplateLazyQueryHookResult = ReturnType<
  typeof useCandidateSummaryTemplateLazyQuery
>;
export type CandidateSummaryTemplateQueryResult = Apollo.QueryResult<
  CandidateSummaryTemplateQuery,
  CandidateSummaryTemplateQueryVariables
>;
export const CandidateSummaryTemplatesDocument = gql`
  query CandidateSummaryTemplates($candidateId: ID!, $positionId: ID) {
    candidateSummaryTemplates(
      candidateId: $candidateId
      positionId: $positionId
    ) {
      ...CandidateSummaryTemplate
    }
  }
  ${CandidateSummaryTemplateFragmentDoc}
`;

/**
 * __useCandidateSummaryTemplatesQuery__
 *
 * To run a query within a React component, call `useCandidateSummaryTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateSummaryTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateSummaryTemplatesQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useCandidateSummaryTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateSummaryTemplatesQuery,
    CandidateSummaryTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateSummaryTemplatesQuery,
    CandidateSummaryTemplatesQueryVariables
  >(CandidateSummaryTemplatesDocument, options);
}
export function useCandidateSummaryTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateSummaryTemplatesQuery,
    CandidateSummaryTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateSummaryTemplatesQuery,
    CandidateSummaryTemplatesQueryVariables
  >(CandidateSummaryTemplatesDocument, options);
}
export type CandidateSummaryTemplatesQueryHookResult = ReturnType<
  typeof useCandidateSummaryTemplatesQuery
>;
export type CandidateSummaryTemplatesLazyQueryHookResult = ReturnType<
  typeof useCandidateSummaryTemplatesLazyQuery
>;
export type CandidateSummaryTemplatesQueryResult = Apollo.QueryResult<
  CandidateSummaryTemplatesQuery,
  CandidateSummaryTemplatesQueryVariables
>;
export const UserCandidateSummaryVisibilityDocument = gql`
  query UserCandidateSummaryVisibility {
    currentUser {
      id
      canViewCandidateSummaries
      canEditCandidateSummaryTemplate
    }
  }
`;

/**
 * __useUserCandidateSummaryVisibilityQuery__
 *
 * To run a query within a React component, call `useUserCandidateSummaryVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCandidateSummaryVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCandidateSummaryVisibilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCandidateSummaryVisibilityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserCandidateSummaryVisibilityQuery,
    UserCandidateSummaryVisibilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserCandidateSummaryVisibilityQuery,
    UserCandidateSummaryVisibilityQueryVariables
  >(UserCandidateSummaryVisibilityDocument, options);
}
export function useUserCandidateSummaryVisibilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCandidateSummaryVisibilityQuery,
    UserCandidateSummaryVisibilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserCandidateSummaryVisibilityQuery,
    UserCandidateSummaryVisibilityQueryVariables
  >(UserCandidateSummaryVisibilityDocument, options);
}
export type UserCandidateSummaryVisibilityQueryHookResult = ReturnType<
  typeof useUserCandidateSummaryVisibilityQuery
>;
export type UserCandidateSummaryVisibilityLazyQueryHookResult = ReturnType<
  typeof useUserCandidateSummaryVisibilityLazyQuery
>;
export type UserCandidateSummaryVisibilityQueryResult = Apollo.QueryResult<
  UserCandidateSummaryVisibilityQuery,
  UserCandidateSummaryVisibilityQueryVariables
>;
export const CandidatesBySearchStringDocument = gql`
  query CandidatesBySearchString($searchString: String!) {
    candidatesBySearchString(searchString: $searchString) {
      ...CandidateSearchResult
    }
  }
  ${CandidateSearchResultFragmentDoc}
`;

/**
 * __useCandidatesBySearchStringQuery__
 *
 * To run a query within a React component, call `useCandidatesBySearchStringQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesBySearchStringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesBySearchStringQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useCandidatesBySearchStringQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidatesBySearchStringQuery,
    CandidatesBySearchStringQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidatesBySearchStringQuery,
    CandidatesBySearchStringQueryVariables
  >(CandidatesBySearchStringDocument, options);
}
export function useCandidatesBySearchStringLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatesBySearchStringQuery,
    CandidatesBySearchStringQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidatesBySearchStringQuery,
    CandidatesBySearchStringQueryVariables
  >(CandidatesBySearchStringDocument, options);
}
export type CandidatesBySearchStringQueryHookResult = ReturnType<
  typeof useCandidatesBySearchStringQuery
>;
export type CandidatesBySearchStringLazyQueryHookResult = ReturnType<
  typeof useCandidatesBySearchStringLazyQuery
>;
export type CandidatesBySearchStringQueryResult = Apollo.QueryResult<
  CandidatesBySearchStringQuery,
  CandidatesBySearchStringQueryVariables
>;
export const CandidateScoresDocument = gql`
  query CandidateScores($candidateId: ID!, $positionId: ID) {
    candidate(id: $candidateId) {
      id
      scorecardsByPosition(positionId: $positionId) {
        ...CandidatePositionScorecard
      }
    }
  }
  ${CandidatePositionScorecardFragmentDoc}
`;

/**
 * __useCandidateScoresQuery__
 *
 * To run a query within a React component, call `useCandidateScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateScoresQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useCandidateScoresQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateScoresQuery,
    CandidateScoresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidateScoresQuery, CandidateScoresQueryVariables>(
    CandidateScoresDocument,
    options
  );
}
export function useCandidateScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateScoresQuery,
    CandidateScoresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateScoresQuery,
    CandidateScoresQueryVariables
  >(CandidateScoresDocument, options);
}
export type CandidateScoresQueryHookResult = ReturnType<
  typeof useCandidateScoresQuery
>;
export type CandidateScoresLazyQueryHookResult = ReturnType<
  typeof useCandidateScoresLazyQuery
>;
export type CandidateScoresQueryResult = Apollo.QueryResult<
  CandidateScoresQuery,
  CandidateScoresQueryVariables
>;
export const CandidateSearchItemDocument = gql`
  query CandidateSearchItem($candidateId: ID!) {
    candidate(id: $candidateId) {
      ...CandidateSearchResult
    }
  }
  ${CandidateSearchResultFragmentDoc}
`;

/**
 * __useCandidateSearchItemQuery__
 *
 * To run a query within a React component, call `useCandidateSearchItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateSearchItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateSearchItemQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useCandidateSearchItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateSearchItemQuery,
    CandidateSearchItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateSearchItemQuery,
    CandidateSearchItemQueryVariables
  >(CandidateSearchItemDocument, options);
}
export function useCandidateSearchItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateSearchItemQuery,
    CandidateSearchItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateSearchItemQuery,
    CandidateSearchItemQueryVariables
  >(CandidateSearchItemDocument, options);
}
export type CandidateSearchItemQueryHookResult = ReturnType<
  typeof useCandidateSearchItemQuery
>;
export type CandidateSearchItemLazyQueryHookResult = ReturnType<
  typeof useCandidateSearchItemLazyQuery
>;
export type CandidateSearchItemQueryResult = Apollo.QueryResult<
  CandidateSearchItemQuery,
  CandidateSearchItemQueryVariables
>;
export const ClipBetaDocument = gql`
  query ClipBeta($clipId: ID!) {
    clip(clipId: $clipId) {
      ...ClipBeta
    }
  }
  ${ClipBetaFragmentDoc}
`;

/**
 * __useClipBetaQuery__
 *
 * To run a query within a React component, call `useClipBetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useClipBetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClipBetaQuery({
 *   variables: {
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useClipBetaQuery(
  baseOptions: Apollo.QueryHookOptions<ClipBetaQuery, ClipBetaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClipBetaQuery, ClipBetaQueryVariables>(
    ClipBetaDocument,
    options
  );
}
export function useClipBetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClipBetaQuery,
    ClipBetaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClipBetaQuery, ClipBetaQueryVariables>(
    ClipBetaDocument,
    options
  );
}
export type ClipBetaQueryHookResult = ReturnType<typeof useClipBetaQuery>;
export type ClipBetaLazyQueryHookResult = ReturnType<
  typeof useClipBetaLazyQuery
>;
export type ClipBetaQueryResult = Apollo.QueryResult<
  ClipBetaQuery,
  ClipBetaQueryVariables
>;
export const ClipNotesDocument = gql`
  query ClipNotes($clipId: ID!) {
    clip(clipId: $clipId) {
      id
      questions {
        id
        description
        questionNotes(clipId: $clipId) {
          ...CallNote
        }
      }
      generalNotes {
        ...CallNote
      }
    }
  }
  ${CallNoteFragmentDoc}
`;

/**
 * __useClipNotesQuery__
 *
 * To run a query within a React component, call `useClipNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClipNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClipNotesQuery({
 *   variables: {
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useClipNotesQuery(
  baseOptions: Apollo.QueryHookOptions<ClipNotesQuery, ClipNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClipNotesQuery, ClipNotesQueryVariables>(
    ClipNotesDocument,
    options
  );
}
export function useClipNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClipNotesQuery,
    ClipNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClipNotesQuery, ClipNotesQueryVariables>(
    ClipNotesDocument,
    options
  );
}
export type ClipNotesQueryHookResult = ReturnType<typeof useClipNotesQuery>;
export type ClipNotesLazyQueryHookResult = ReturnType<
  typeof useClipNotesLazyQuery
>;
export type ClipNotesQueryResult = Apollo.QueryResult<
  ClipNotesQuery,
  ClipNotesQueryVariables
>;
export const ClipTranscriptBetaDocument = gql`
  query ClipTranscriptBeta($clipId: ID!) {
    clip(clipId: $clipId) {
      id
      transcript {
        ...TranscriptSegment
      }
    }
  }
  ${TranscriptSegmentFragmentDoc}
`;

/**
 * __useClipTranscriptBetaQuery__
 *
 * To run a query within a React component, call `useClipTranscriptBetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useClipTranscriptBetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClipTranscriptBetaQuery({
 *   variables: {
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useClipTranscriptBetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClipTranscriptBetaQuery,
    ClipTranscriptBetaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClipTranscriptBetaQuery,
    ClipTranscriptBetaQueryVariables
  >(ClipTranscriptBetaDocument, options);
}
export function useClipTranscriptBetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClipTranscriptBetaQuery,
    ClipTranscriptBetaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClipTranscriptBetaQuery,
    ClipTranscriptBetaQueryVariables
  >(ClipTranscriptBetaDocument, options);
}
export type ClipTranscriptBetaQueryHookResult = ReturnType<
  typeof useClipTranscriptBetaQuery
>;
export type ClipTranscriptBetaLazyQueryHookResult = ReturnType<
  typeof useClipTranscriptBetaLazyQuery
>;
export type ClipTranscriptBetaQueryResult = Apollo.QueryResult<
  ClipTranscriptBetaQuery,
  ClipTranscriptBetaQueryVariables
>;
export const CurrentUserOnboardingStepsDocument = gql`
  query CurrentUserOnboardingSteps {
    currentUser {
      id
      signUpCompletedAt
      onboardingSteps {
        step
        rule
        status
      }
    }
  }
`;

/**
 * __useCurrentUserOnboardingStepsQuery__
 *
 * To run a query within a React component, call `useCurrentUserOnboardingStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserOnboardingStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserOnboardingStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserOnboardingStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserOnboardingStepsQuery,
    CurrentUserOnboardingStepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserOnboardingStepsQuery,
    CurrentUserOnboardingStepsQueryVariables
  >(CurrentUserOnboardingStepsDocument, options);
}
export function useCurrentUserOnboardingStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserOnboardingStepsQuery,
    CurrentUserOnboardingStepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserOnboardingStepsQuery,
    CurrentUserOnboardingStepsQueryVariables
  >(CurrentUserOnboardingStepsDocument, options);
}
export type CurrentUserOnboardingStepsQueryHookResult = ReturnType<
  typeof useCurrentUserOnboardingStepsQuery
>;
export type CurrentUserOnboardingStepsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserOnboardingStepsLazyQuery
>;
export type CurrentUserOnboardingStepsQueryResult = Apollo.QueryResult<
  CurrentUserOnboardingStepsQuery,
  CurrentUserOnboardingStepsQueryVariables
>;
export const CurrentUserSharedWithMeCountsDocument = gql`
  query CurrentUserSharedWithMeCounts($pagination: OffsetPaginationDetails) {
    currentUser {
      id
      callsSharedWithMe(pagination: $pagination) {
        pageInfo {
          totalRows
        }
      }
      clipsSharedWithMe(pagination: $pagination) {
        pageInfo {
          totalRows
        }
      }
      playlistsSharedWithMe(pagination: $pagination) {
        pageInfo {
          totalRows
        }
      }
    }
  }
`;

/**
 * __useCurrentUserSharedWithMeCountsQuery__
 *
 * To run a query within a React component, call `useCurrentUserSharedWithMeCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSharedWithMeCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSharedWithMeCountsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCurrentUserSharedWithMeCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserSharedWithMeCountsQuery,
    CurrentUserSharedWithMeCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserSharedWithMeCountsQuery,
    CurrentUserSharedWithMeCountsQueryVariables
  >(CurrentUserSharedWithMeCountsDocument, options);
}
export function useCurrentUserSharedWithMeCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserSharedWithMeCountsQuery,
    CurrentUserSharedWithMeCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserSharedWithMeCountsQuery,
    CurrentUserSharedWithMeCountsQueryVariables
  >(CurrentUserSharedWithMeCountsDocument, options);
}
export type CurrentUserSharedWithMeCountsQueryHookResult = ReturnType<
  typeof useCurrentUserSharedWithMeCountsQuery
>;
export type CurrentUserSharedWithMeCountsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserSharedWithMeCountsLazyQuery
>;
export type CurrentUserSharedWithMeCountsQueryResult = Apollo.QueryResult<
  CurrentUserSharedWithMeCountsQuery,
  CurrentUserSharedWithMeCountsQueryVariables
>;
export const CurrentUserSharedWithMeDocument = gql`
  query CurrentUserSharedWithMe(
    $pagination: OffsetPaginationDetails
    $calls: Boolean!
    $clips: Boolean!
    $playlists: Boolean!
  ) {
    currentUser {
      id
      callsSharedWithMe(pagination: $pagination) @include(if: $calls) {
        pageInfo {
          totalPages
          totalRows
        }
        results {
          id
          createdAt
          updatedAt
          call {
            ...CallListItem
          }
        }
      }
      clipsSharedWithMe(pagination: $pagination) @include(if: $clips) {
        pageInfo {
          totalPages
          totalRows
        }
        results {
          id
          createdAt
          updatedAt
          clip {
            ...ClipListItem
          }
        }
      }
      playlistsSharedWithMe(pagination: $pagination) @include(if: $playlists) {
        pageInfo {
          totalPages
          totalRows
        }
        results {
          ...PlaylistShareListItem
        }
      }
    }
  }
  ${CallListItemFragmentDoc}
  ${ClipListItemFragmentDoc}
  ${PlaylistShareListItemFragmentDoc}
`;

/**
 * __useCurrentUserSharedWithMeQuery__
 *
 * To run a query within a React component, call `useCurrentUserSharedWithMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSharedWithMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSharedWithMeQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      calls: // value for 'calls'
 *      clips: // value for 'clips'
 *      playlists: // value for 'playlists'
 *   },
 * });
 */
export function useCurrentUserSharedWithMeQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentUserSharedWithMeQuery,
    CurrentUserSharedWithMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserSharedWithMeQuery,
    CurrentUserSharedWithMeQueryVariables
  >(CurrentUserSharedWithMeDocument, options);
}
export function useCurrentUserSharedWithMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserSharedWithMeQuery,
    CurrentUserSharedWithMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserSharedWithMeQuery,
    CurrentUserSharedWithMeQueryVariables
  >(CurrentUserSharedWithMeDocument, options);
}
export type CurrentUserSharedWithMeQueryHookResult = ReturnType<
  typeof useCurrentUserSharedWithMeQuery
>;
export type CurrentUserSharedWithMeLazyQueryHookResult = ReturnType<
  typeof useCurrentUserSharedWithMeLazyQuery
>;
export type CurrentUserSharedWithMeQueryResult = Apollo.QueryResult<
  CurrentUserSharedWithMeQuery,
  CurrentUserSharedWithMeQueryVariables
>;
export const CurrentUserZoomAppDocument = gql`
  query CurrentUserZoomApp {
    currentUser {
      id
      isZoomAppInstalled
    }
  }
`;

/**
 * __useCurrentUserZoomAppQuery__
 *
 * To run a query within a React component, call `useCurrentUserZoomAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserZoomAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserZoomAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserZoomAppQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserZoomAppQuery,
    CurrentUserZoomAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserZoomAppQuery,
    CurrentUserZoomAppQueryVariables
  >(CurrentUserZoomAppDocument, options);
}
export function useCurrentUserZoomAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserZoomAppQuery,
    CurrentUserZoomAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserZoomAppQuery,
    CurrentUserZoomAppQueryVariables
  >(CurrentUserZoomAppDocument, options);
}
export type CurrentUserZoomAppQueryHookResult = ReturnType<
  typeof useCurrentUserZoomAppQuery
>;
export type CurrentUserZoomAppLazyQueryHookResult = ReturnType<
  typeof useCurrentUserZoomAppLazyQuery
>;
export type CurrentUserZoomAppQueryResult = Apollo.QueryResult<
  CurrentUserZoomAppQuery,
  CurrentUserZoomAppQueryVariables
>;
export const DataRetentionCountDocument = gql`
  query DataRetentionCount(
    $enableRetentionSinceCandidateHired: Boolean
    $retentionDaysSinceCandidateHired: Int
    $enableRetentionSinceCandidateRejected: Boolean
    $retentionDaysSinceCandidateRejected: Int
    $enableRetentionSinceLastInterview: Boolean!
    $retentionDaysSinceLastInterview: Int!
    $enableRetentionSinceInterview: Boolean!
    $retentionDaysSinceInterview: Int!
    $preventRedactionForTraining: Boolean!
  ) {
    previewCandidatesToRedact(
      enableRetentionSinceCandidateHired: $enableRetentionSinceCandidateHired
      retentionDaysSinceCandidateHired: $retentionDaysSinceCandidateHired
      enableRetentionSinceCandidateRejected: $enableRetentionSinceCandidateRejected
      retentionDaysSinceCandidateRejected: $retentionDaysSinceCandidateRejected
      enableRetentionSinceLastInterview: $enableRetentionSinceLastInterview
      retentionDaysSinceLastInterview: $retentionDaysSinceLastInterview
      preventRedactionForTraining: $preventRedactionForTraining
    ) {
      totalCandidateCount
    }
    previewCallsToRedact(
      enableRetentionSinceInterview: $enableRetentionSinceInterview
      retentionDaysSinceInterview: $retentionDaysSinceInterview
      preventRedactionForTraining: $preventRedactionForTraining
    ) {
      count
    }
  }
`;

/**
 * __useDataRetentionCountQuery__
 *
 * To run a query within a React component, call `useDataRetentionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataRetentionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataRetentionCountQuery({
 *   variables: {
 *      enableRetentionSinceCandidateHired: // value for 'enableRetentionSinceCandidateHired'
 *      retentionDaysSinceCandidateHired: // value for 'retentionDaysSinceCandidateHired'
 *      enableRetentionSinceCandidateRejected: // value for 'enableRetentionSinceCandidateRejected'
 *      retentionDaysSinceCandidateRejected: // value for 'retentionDaysSinceCandidateRejected'
 *      enableRetentionSinceLastInterview: // value for 'enableRetentionSinceLastInterview'
 *      retentionDaysSinceLastInterview: // value for 'retentionDaysSinceLastInterview'
 *      enableRetentionSinceInterview: // value for 'enableRetentionSinceInterview'
 *      retentionDaysSinceInterview: // value for 'retentionDaysSinceInterview'
 *      preventRedactionForTraining: // value for 'preventRedactionForTraining'
 *   },
 * });
 */
export function useDataRetentionCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    DataRetentionCountQuery,
    DataRetentionCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DataRetentionCountQuery,
    DataRetentionCountQueryVariables
  >(DataRetentionCountDocument, options);
}
export function useDataRetentionCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DataRetentionCountQuery,
    DataRetentionCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DataRetentionCountQuery,
    DataRetentionCountQueryVariables
  >(DataRetentionCountDocument, options);
}
export type DataRetentionCountQueryHookResult = ReturnType<
  typeof useDataRetentionCountQuery
>;
export type DataRetentionCountLazyQueryHookResult = ReturnType<
  typeof useDataRetentionCountLazyQuery
>;
export type DataRetentionCountQueryResult = Apollo.QueryResult<
  DataRetentionCountQuery,
  DataRetentionCountQueryVariables
>;
export const OrganizationRedactionCandidatesDocument = gql`
  query OrganizationRedactionCandidates(
    $page: Int
    $candidateStatus: CandidateRedactionStatus
    $sortBy: CandidateRedactionSortField
    $sortDirection: SortDirection = DESC
  ) {
    candidatesToRedact(
      page: $page
      candidateStatus: $candidateStatus
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      totalCandidateCount
      numPages
      candidates {
        id
        firstName
        lastName
        fullName
        hiredAt
        rejectedAt
        lastInterviewAt
        numInterviews
      }
    }
  }
`;

/**
 * __useOrganizationRedactionCandidatesQuery__
 *
 * To run a query within a React component, call `useOrganizationRedactionCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRedactionCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationRedactionCandidatesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      candidateStatus: // value for 'candidateStatus'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useOrganizationRedactionCandidatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationRedactionCandidatesQuery,
    OrganizationRedactionCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationRedactionCandidatesQuery,
    OrganizationRedactionCandidatesQueryVariables
  >(OrganizationRedactionCandidatesDocument, options);
}
export function useOrganizationRedactionCandidatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationRedactionCandidatesQuery,
    OrganizationRedactionCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationRedactionCandidatesQuery,
    OrganizationRedactionCandidatesQueryVariables
  >(OrganizationRedactionCandidatesDocument, options);
}
export type OrganizationRedactionCandidatesQueryHookResult = ReturnType<
  typeof useOrganizationRedactionCandidatesQuery
>;
export type OrganizationRedactionCandidatesLazyQueryHookResult = ReturnType<
  typeof useOrganizationRedactionCandidatesLazyQuery
>;
export type OrganizationRedactionCandidatesQueryResult = Apollo.QueryResult<
  OrganizationRedactionCandidatesQuery,
  OrganizationRedactionCandidatesQueryVariables
>;
export const OrganizationRedactionCallsDocument = gql`
  query OrganizationRedactionCalls($count: Int!) {
    callsToRedact(count: $count) {
      count
      calls {
        ...RedactionCallListItem
      }
    }
  }
  ${RedactionCallListItemFragmentDoc}
`;

/**
 * __useOrganizationRedactionCallsQuery__
 *
 * To run a query within a React component, call `useOrganizationRedactionCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRedactionCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationRedactionCallsQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useOrganizationRedactionCallsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationRedactionCallsQuery,
    OrganizationRedactionCallsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationRedactionCallsQuery,
    OrganizationRedactionCallsQueryVariables
  >(OrganizationRedactionCallsDocument, options);
}
export function useOrganizationRedactionCallsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationRedactionCallsQuery,
    OrganizationRedactionCallsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationRedactionCallsQuery,
    OrganizationRedactionCallsQueryVariables
  >(OrganizationRedactionCallsDocument, options);
}
export type OrganizationRedactionCallsQueryHookResult = ReturnType<
  typeof useOrganizationRedactionCallsQuery
>;
export type OrganizationRedactionCallsLazyQueryHookResult = ReturnType<
  typeof useOrganizationRedactionCallsLazyQuery
>;
export type OrganizationRedactionCallsQueryResult = Apollo.QueryResult<
  OrganizationRedactionCallsQuery,
  OrganizationRedactionCallsQueryVariables
>;
export const ExternalRecordingTrackerSegmentDocument = gql`
  query ExternalRecordingTrackerSegment(
    $id: ID!
    $shareType: String!
    $index: Int
    $trackerKeywordId: String
    $trackerGroupId: String
  ) {
    externalShareView(id: $id, shareType: $shareType, index: $index) {
      id
      externalRecording {
        ... on ExternalCall {
          id
          canEdit
          type
          speakers {
            ...CallSpeaker
          }
          poiSegments(
            trackerKeywordId: $trackerKeywordId
            trackerGroupId: $trackerGroupId
          ) {
            keyword
            segments {
              ...TranscriptSegment
            }
          }
        }
        ... on ExternalClip {
          id
          canEdit
          type
          speakers {
            ...CallSpeaker
          }
          poiSegments(
            trackerKeywordId: $trackerKeywordId
            trackerGroupId: $trackerGroupId
          ) {
            keyword
            segments {
              ...TranscriptSegment
            }
          }
        }
      }
    }
  }
  ${CallSpeakerFragmentDoc}
  ${TranscriptSegmentFragmentDoc}
`;

/**
 * __useExternalRecordingTrackerSegmentQuery__
 *
 * To run a query within a React component, call `useExternalRecordingTrackerSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalRecordingTrackerSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalRecordingTrackerSegmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      shareType: // value for 'shareType'
 *      index: // value for 'index'
 *      trackerKeywordId: // value for 'trackerKeywordId'
 *      trackerGroupId: // value for 'trackerGroupId'
 *   },
 * });
 */
export function useExternalRecordingTrackerSegmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalRecordingTrackerSegmentQuery,
    ExternalRecordingTrackerSegmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalRecordingTrackerSegmentQuery,
    ExternalRecordingTrackerSegmentQueryVariables
  >(ExternalRecordingTrackerSegmentDocument, options);
}
export function useExternalRecordingTrackerSegmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalRecordingTrackerSegmentQuery,
    ExternalRecordingTrackerSegmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalRecordingTrackerSegmentQuery,
    ExternalRecordingTrackerSegmentQueryVariables
  >(ExternalRecordingTrackerSegmentDocument, options);
}
export type ExternalRecordingTrackerSegmentQueryHookResult = ReturnType<
  typeof useExternalRecordingTrackerSegmentQuery
>;
export type ExternalRecordingTrackerSegmentLazyQueryHookResult = ReturnType<
  typeof useExternalRecordingTrackerSegmentLazyQuery
>;
export type ExternalRecordingTrackerSegmentQueryResult = Apollo.QueryResult<
  ExternalRecordingTrackerSegmentQuery,
  ExternalRecordingTrackerSegmentQueryVariables
>;
export const ExternalRecordingTrackersDocument = gql`
  query ExternalRecordingTrackers($id: ID!, $shareType: String!, $index: Int) {
    externalShareView(id: $id, shareType: $shareType, index: $index) {
      id
      externalRecording {
        ... on ExternalCall {
          id
          speakers {
            ...CallSpeaker
          }
          pointsOfInterest {
            ...TrackerGroup
          }
        }
        ... on ExternalClip {
          id
          speakers {
            ...CallSpeaker
          }
          pointsOfInterest {
            ...TrackerGroup
          }
        }
      }
    }
  }
  ${CallSpeakerFragmentDoc}
  ${TrackerGroupFragmentDoc}
`;

/**
 * __useExternalRecordingTrackersQuery__
 *
 * To run a query within a React component, call `useExternalRecordingTrackersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalRecordingTrackersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalRecordingTrackersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      shareType: // value for 'shareType'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useExternalRecordingTrackersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalRecordingTrackersQuery,
    ExternalRecordingTrackersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalRecordingTrackersQuery,
    ExternalRecordingTrackersQueryVariables
  >(ExternalRecordingTrackersDocument, options);
}
export function useExternalRecordingTrackersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalRecordingTrackersQuery,
    ExternalRecordingTrackersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalRecordingTrackersQuery,
    ExternalRecordingTrackersQueryVariables
  >(ExternalRecordingTrackersDocument, options);
}
export type ExternalRecordingTrackersQueryHookResult = ReturnType<
  typeof useExternalRecordingTrackersQuery
>;
export type ExternalRecordingTrackersLazyQueryHookResult = ReturnType<
  typeof useExternalRecordingTrackersLazyQuery
>;
export type ExternalRecordingTrackersQueryResult = Apollo.QueryResult<
  ExternalRecordingTrackersQuery,
  ExternalRecordingTrackersQueryVariables
>;
export const ExternalShareAiNotesDocument = gql`
  query ExternalShareAiNotes($id: ID!) {
    externalShareAiNotes(id: $id) {
      id
      status
      type
      headers {
        id
        text
        startTime
        notes {
          id
          text
          startTimes
        }
      }
    }
  }
`;

/**
 * __useExternalShareAiNotesQuery__
 *
 * To run a query within a React component, call `useExternalShareAiNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalShareAiNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalShareAiNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExternalShareAiNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalShareAiNotesQuery,
    ExternalShareAiNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalShareAiNotesQuery,
    ExternalShareAiNotesQueryVariables
  >(ExternalShareAiNotesDocument, options);
}
export function useExternalShareAiNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalShareAiNotesQuery,
    ExternalShareAiNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalShareAiNotesQuery,
    ExternalShareAiNotesQueryVariables
  >(ExternalShareAiNotesDocument, options);
}
export type ExternalShareAiNotesQueryHookResult = ReturnType<
  typeof useExternalShareAiNotesQuery
>;
export type ExternalShareAiNotesLazyQueryHookResult = ReturnType<
  typeof useExternalShareAiNotesLazyQuery
>;
export type ExternalShareAiNotesQueryResult = Apollo.QueryResult<
  ExternalShareAiNotesQuery,
  ExternalShareAiNotesQueryVariables
>;
export const ExternalShareViewDocument = gql`
  query ExternalShareView($id: ID!, $shareType: String!, $index: Int) {
    externalShareView(id: $id, shareType: $shareType, index: $index) {
      id
      sharedByEmail
      shareDaysRemaining
      playlistNavigationInfo {
        nextClipUrl
        previousClipUrl
        playlist {
          title
          clipCount
        }
      }
      externalRecording {
        ... on ExternalCall {
          ...ExternalCall
        }
        ... on ExternalClip {
          ...ExternalClip
        }
      }
    }
  }
  ${ExternalCallFragmentDoc}
  ${ExternalClipFragmentDoc}
`;

/**
 * __useExternalShareViewQuery__
 *
 * To run a query within a React component, call `useExternalShareViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalShareViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalShareViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      shareType: // value for 'shareType'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useExternalShareViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalShareViewQuery,
    ExternalShareViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalShareViewQuery,
    ExternalShareViewQueryVariables
  >(ExternalShareViewDocument, options);
}
export function useExternalShareViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalShareViewQuery,
    ExternalShareViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalShareViewQuery,
    ExternalShareViewQueryVariables
  >(ExternalShareViewDocument, options);
}
export type ExternalShareViewQueryHookResult = ReturnType<
  typeof useExternalShareViewQuery
>;
export type ExternalShareViewLazyQueryHookResult = ReturnType<
  typeof useExternalShareViewLazyQuery
>;
export type ExternalShareViewQueryResult = Apollo.QueryResult<
  ExternalShareViewQuery,
  ExternalShareViewQueryVariables
>;
export const BotMeetingScheduledInterviewDocument = gql`
  query BotMeetingScheduledInterview(
    $zoomMeetingId: Float
    $meetingUrl: String
    $botMeetingId: String
  ) {
    botMeetingScheduledInterview(
      zoomMeetingId: $zoomMeetingId
      meetingUrl: $meetingUrl
      botMeetingId: $botMeetingId
    ) {
      ...InPersonScheduledInterview
    }
  }
  ${InPersonScheduledInterviewFragmentDoc}
`;

/**
 * __useBotMeetingScheduledInterviewQuery__
 *
 * To run a query within a React component, call `useBotMeetingScheduledInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBotMeetingScheduledInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBotMeetingScheduledInterviewQuery({
 *   variables: {
 *      zoomMeetingId: // value for 'zoomMeetingId'
 *      meetingUrl: // value for 'meetingUrl'
 *      botMeetingId: // value for 'botMeetingId'
 *   },
 * });
 */
export function useBotMeetingScheduledInterviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BotMeetingScheduledInterviewQuery,
    BotMeetingScheduledInterviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BotMeetingScheduledInterviewQuery,
    BotMeetingScheduledInterviewQueryVariables
  >(BotMeetingScheduledInterviewDocument, options);
}
export function useBotMeetingScheduledInterviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BotMeetingScheduledInterviewQuery,
    BotMeetingScheduledInterviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BotMeetingScheduledInterviewQuery,
    BotMeetingScheduledInterviewQueryVariables
  >(BotMeetingScheduledInterviewDocument, options);
}
export type BotMeetingScheduledInterviewQueryHookResult = ReturnType<
  typeof useBotMeetingScheduledInterviewQuery
>;
export type BotMeetingScheduledInterviewLazyQueryHookResult = ReturnType<
  typeof useBotMeetingScheduledInterviewLazyQuery
>;
export type BotMeetingScheduledInterviewQueryResult = Apollo.QueryResult<
  BotMeetingScheduledInterviewQuery,
  BotMeetingScheduledInterviewQueryVariables
>;
export const OrgSeatsDocument = gql`
  query OrgSeats {
    currentUser {
      id
      organization {
        id
        freemiumSeatCount
        filledSeatsInfo
      }
    }
  }
`;

/**
 * __useOrgSeatsQuery__
 *
 * To run a query within a React component, call `useOrgSeatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgSeatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgSeatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgSeatsQuery(
  baseOptions?: Apollo.QueryHookOptions<OrgSeatsQuery, OrgSeatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgSeatsQuery, OrgSeatsQueryVariables>(
    OrgSeatsDocument,
    options
  );
}
export function useOrgSeatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgSeatsQuery,
    OrgSeatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgSeatsQuery, OrgSeatsQueryVariables>(
    OrgSeatsDocument,
    options
  );
}
export type OrgSeatsQueryHookResult = ReturnType<typeof useOrgSeatsQuery>;
export type OrgSeatsLazyQueryHookResult = ReturnType<
  typeof useOrgSeatsLazyQuery
>;
export type OrgSeatsQueryResult = Apollo.QueryResult<
  OrgSeatsQuery,
  OrgSeatsQueryVariables
>;
export const OrgZoomNotetakerSettingsDocument = gql`
  query OrgZoomNotetakerSettings {
    currentUser {
      id
      organization {
        id
        zoomAppAutoOpenRule
        zoomNotetakerEnabled
        sendCancelRecordingChatMessage
        zoomNotetakerDisclaimer
        zoomNotetakerDisclaimerType
        zoomNotetakerDisplayImageBucketPath
        zoomNotetakerDisplayName
        virtualOnsiteEnabledZoom
      }
    }
  }
`;

/**
 * __useOrgZoomNotetakerSettingsQuery__
 *
 * To run a query within a React component, call `useOrgZoomNotetakerSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgZoomNotetakerSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgZoomNotetakerSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgZoomNotetakerSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgZoomNotetakerSettingsQuery,
    OrgZoomNotetakerSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgZoomNotetakerSettingsQuery,
    OrgZoomNotetakerSettingsQueryVariables
  >(OrgZoomNotetakerSettingsDocument, options);
}
export function useOrgZoomNotetakerSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgZoomNotetakerSettingsQuery,
    OrgZoomNotetakerSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgZoomNotetakerSettingsQuery,
    OrgZoomNotetakerSettingsQueryVariables
  >(OrgZoomNotetakerSettingsDocument, options);
}
export type OrgZoomNotetakerSettingsQueryHookResult = ReturnType<
  typeof useOrgZoomNotetakerSettingsQuery
>;
export type OrgZoomNotetakerSettingsLazyQueryHookResult = ReturnType<
  typeof useOrgZoomNotetakerSettingsLazyQuery
>;
export type OrgZoomNotetakerSettingsQueryResult = Apollo.QueryResult<
  OrgZoomNotetakerSettingsQuery,
  OrgZoomNotetakerSettingsQueryVariables
>;
export const OrgZoomSettingsDocument = gql`
  query OrgZoomSettings {
    currentUser {
      id
      organization {
        id
        zoomSettings
        zoomNotetakerEnabled
        automaticallyDeleteZoomRecordings
        zoomAppAutoOpenRule
      }
    }
  }
`;

/**
 * __useOrgZoomSettingsQuery__
 *
 * To run a query within a React component, call `useOrgZoomSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgZoomSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgZoomSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgZoomSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgZoomSettingsQuery,
    OrgZoomSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgZoomSettingsQuery, OrgZoomSettingsQueryVariables>(
    OrgZoomSettingsDocument,
    options
  );
}
export function useOrgZoomSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgZoomSettingsQuery,
    OrgZoomSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgZoomSettingsQuery,
    OrgZoomSettingsQueryVariables
  >(OrgZoomSettingsDocument, options);
}
export type OrgZoomSettingsQueryHookResult = ReturnType<
  typeof useOrgZoomSettingsQuery
>;
export type OrgZoomSettingsLazyQueryHookResult = ReturnType<
  typeof useOrgZoomSettingsLazyQuery
>;
export type OrgZoomSettingsQueryResult = Apollo.QueryResult<
  OrgZoomSettingsQuery,
  OrgZoomSettingsQueryVariables
>;
export const OrganizationCompetencyDocument = gql`
  query OrganizationCompetency {
    currentUser {
      id
      organization {
        id
        competencies {
          id
          name
          isMigrated
          creatorId
        }
      }
    }
  }
`;

/**
 * __useOrganizationCompetencyQuery__
 *
 * To run a query within a React component, call `useOrganizationCompetencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCompetencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCompetencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationCompetencyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationCompetencyQuery,
    OrganizationCompetencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationCompetencyQuery,
    OrganizationCompetencyQueryVariables
  >(OrganizationCompetencyDocument, options);
}
export function useOrganizationCompetencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationCompetencyQuery,
    OrganizationCompetencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationCompetencyQuery,
    OrganizationCompetencyQueryVariables
  >(OrganizationCompetencyDocument, options);
}
export type OrganizationCompetencyQueryHookResult = ReturnType<
  typeof useOrganizationCompetencyQuery
>;
export type OrganizationCompetencyLazyQueryHookResult = ReturnType<
  typeof useOrganizationCompetencyLazyQuery
>;
export type OrganizationCompetencyQueryResult = Apollo.QueryResult<
  OrganizationCompetencyQuery,
  OrganizationCompetencyQueryVariables
>;
export const OrganizationDataRedactionDocument = gql`
  query OrganizationDataRedaction {
    currentUser {
      id
      organization {
        id
        dataRedactionEnabled
        redactEeocFamilyEnabled
        redactEeocSexEnabled
        redactEeocRaceEnabled
        redactEeocHighRiskQuestionsEnabled
        redactCompensationEnabled
      }
    }
  }
`;

/**
 * __useOrganizationDataRedactionQuery__
 *
 * To run a query within a React component, call `useOrganizationDataRedactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDataRedactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDataRedactionQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationDataRedactionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationDataRedactionQuery,
    OrganizationDataRedactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationDataRedactionQuery,
    OrganizationDataRedactionQueryVariables
  >(OrganizationDataRedactionDocument, options);
}
export function useOrganizationDataRedactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationDataRedactionQuery,
    OrganizationDataRedactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationDataRedactionQuery,
    OrganizationDataRedactionQueryVariables
  >(OrganizationDataRedactionDocument, options);
}
export type OrganizationDataRedactionQueryHookResult = ReturnType<
  typeof useOrganizationDataRedactionQuery
>;
export type OrganizationDataRedactionLazyQueryHookResult = ReturnType<
  typeof useOrganizationDataRedactionLazyQuery
>;
export type OrganizationDataRedactionQueryResult = Apollo.QueryResult<
  OrganizationDataRedactionQuery,
  OrganizationDataRedactionQueryVariables
>;
export const OrganizationRedactionPredictionsDocument = gql`
  query OrganizationRedactionPredictions(
    $pagination: OffsetPaginationDetails
    $topic: String!
  ) {
    currentUser {
      id
      organization {
        id
        redactionPredictions(pagination: $pagination, topic: $topic) {
          pageInfo {
            totalPages
            totalRows
          }
          results {
            ...RedactionPrediction
          }
          topicFilters {
            label
            value
          }
        }
      }
    }
  }
  ${RedactionPredictionFragmentDoc}
`;

/**
 * __useOrganizationRedactionPredictionsQuery__
 *
 * To run a query within a React component, call `useOrganizationRedactionPredictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRedactionPredictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationRedactionPredictionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useOrganizationRedactionPredictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationRedactionPredictionsQuery,
    OrganizationRedactionPredictionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationRedactionPredictionsQuery,
    OrganizationRedactionPredictionsQueryVariables
  >(OrganizationRedactionPredictionsDocument, options);
}
export function useOrganizationRedactionPredictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationRedactionPredictionsQuery,
    OrganizationRedactionPredictionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationRedactionPredictionsQuery,
    OrganizationRedactionPredictionsQueryVariables
  >(OrganizationRedactionPredictionsDocument, options);
}
export type OrganizationRedactionPredictionsQueryHookResult = ReturnType<
  typeof useOrganizationRedactionPredictionsQuery
>;
export type OrganizationRedactionPredictionsLazyQueryHookResult = ReturnType<
  typeof useOrganizationRedactionPredictionsLazyQuery
>;
export type OrganizationRedactionPredictionsQueryResult = Apollo.QueryResult<
  OrganizationRedactionPredictionsQuery,
  OrganizationRedactionPredictionsQueryVariables
>;
export const CurrentUserPlaylistsDocument = gql`
  query CurrentUserPlaylists($pagination: OffsetPaginationDetails) {
    currentUser {
      id
      playlists(pagination: $pagination) {
        pageInfo {
          totalPages
          totalRows
        }
        results {
          ...PlaylistSidebarItem
        }
      }
    }
  }
  ${PlaylistSidebarItemFragmentDoc}
`;

/**
 * __useCurrentUserPlaylistsQuery__
 *
 * To run a query within a React component, call `useCurrentUserPlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserPlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserPlaylistsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCurrentUserPlaylistsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserPlaylistsQuery,
    CurrentUserPlaylistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserPlaylistsQuery,
    CurrentUserPlaylistsQueryVariables
  >(CurrentUserPlaylistsDocument, options);
}
export function useCurrentUserPlaylistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserPlaylistsQuery,
    CurrentUserPlaylistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserPlaylistsQuery,
    CurrentUserPlaylistsQueryVariables
  >(CurrentUserPlaylistsDocument, options);
}
export type CurrentUserPlaylistsQueryHookResult = ReturnType<
  typeof useCurrentUserPlaylistsQuery
>;
export type CurrentUserPlaylistsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserPlaylistsLazyQuery
>;
export type CurrentUserPlaylistsQueryResult = Apollo.QueryResult<
  CurrentUserPlaylistsQuery,
  CurrentUserPlaylistsQueryVariables
>;
export const PlaylistNavigationInfoDocument = gql`
  query PlaylistNavigationInfo($playlistId: ID!, $clipId: ID!, $callId: ID!) {
    playlistNavigationInfo(
      playlistId: $playlistId
      clipId: $clipId
      callId: $callId
    ) {
      playlist {
        id
        title
        clipCount
      }
      nextClipUrl
      previousClipUrl
      currentClipIdx
    }
  }
`;

/**
 * __usePlaylistNavigationInfoQuery__
 *
 * To run a query within a React component, call `usePlaylistNavigationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistNavigationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistNavigationInfoQuery({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      clipId: // value for 'clipId'
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function usePlaylistNavigationInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlaylistNavigationInfoQuery,
    PlaylistNavigationInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlaylistNavigationInfoQuery,
    PlaylistNavigationInfoQueryVariables
  >(PlaylistNavigationInfoDocument, options);
}
export function usePlaylistNavigationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistNavigationInfoQuery,
    PlaylistNavigationInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlaylistNavigationInfoQuery,
    PlaylistNavigationInfoQueryVariables
  >(PlaylistNavigationInfoDocument, options);
}
export type PlaylistNavigationInfoQueryHookResult = ReturnType<
  typeof usePlaylistNavigationInfoQuery
>;
export type PlaylistNavigationInfoLazyQueryHookResult = ReturnType<
  typeof usePlaylistNavigationInfoLazyQuery
>;
export type PlaylistNavigationInfoQueryResult = Apollo.QueryResult<
  PlaylistNavigationInfoQuery,
  PlaylistNavigationInfoQueryVariables
>;
export const PlaylistDocument = gql`
  query Playlist($playlistId: ID!) {
    playlist(id: $playlistId) {
      id
      title
      createdAt
      clipCount
      duration
      clips {
        id
        callId
        displayName
        thumbnailImageUrl
        duration
        canEdit
        startTime
        endTime
        name
      }
      call {
        id
        name
      }
    }
  }
`;

/**
 * __usePlaylistQuery__
 *
 * To run a query within a React component, call `usePlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistQuery({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function usePlaylistQuery(
  baseOptions: Apollo.QueryHookOptions<PlaylistQuery, PlaylistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlaylistQuery, PlaylistQueryVariables>(
    PlaylistDocument,
    options
  );
}
export function usePlaylistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistQuery,
    PlaylistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlaylistQuery, PlaylistQueryVariables>(
    PlaylistDocument,
    options
  );
}
export type PlaylistQueryHookResult = ReturnType<typeof usePlaylistQuery>;
export type PlaylistLazyQueryHookResult = ReturnType<
  typeof usePlaylistLazyQuery
>;
export type PlaylistQueryResult = Apollo.QueryResult<
  PlaylistQuery,
  PlaylistQueryVariables
>;
export const PlaylistSharesDocument = gql`
  query PlaylistShares($playlistId: ID!) {
    playlist(id: $playlistId) {
      id
      shares {
        ...PlaylistShare
      }
      externalShares {
        ...ExternalPlaylistShare
      }
    }
  }
  ${PlaylistShareFragmentDoc}
  ${ExternalPlaylistShareFragmentDoc}
`;

/**
 * __usePlaylistSharesQuery__
 *
 * To run a query within a React component, call `usePlaylistSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistSharesQuery({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function usePlaylistSharesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlaylistSharesQuery,
    PlaylistSharesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlaylistSharesQuery, PlaylistSharesQueryVariables>(
    PlaylistSharesDocument,
    options
  );
}
export function usePlaylistSharesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistSharesQuery,
    PlaylistSharesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlaylistSharesQuery, PlaylistSharesQueryVariables>(
    PlaylistSharesDocument,
    options
  );
}
export type PlaylistSharesQueryHookResult = ReturnType<
  typeof usePlaylistSharesQuery
>;
export type PlaylistSharesLazyQueryHookResult = ReturnType<
  typeof usePlaylistSharesLazyQuery
>;
export type PlaylistSharesQueryResult = Apollo.QueryResult<
  PlaylistSharesQuery,
  PlaylistSharesQueryVariables
>;
export const TaskDefinitionsDocument = gql`
  query TaskDefinitions($type: TaskDefinitionType!) {
    taskDefinitions(type: $type) {
      ...TaskDefinition
    }
  }
  ${TaskDefinitionFragmentDoc}
`;

/**
 * __useTaskDefinitionsQuery__
 *
 * To run a query within a React component, call `useTaskDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDefinitionsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useTaskDefinitionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskDefinitionsQuery,
    TaskDefinitionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskDefinitionsQuery, TaskDefinitionsQueryVariables>(
    TaskDefinitionsDocument,
    options
  );
}
export function useTaskDefinitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskDefinitionsQuery,
    TaskDefinitionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaskDefinitionsQuery,
    TaskDefinitionsQueryVariables
  >(TaskDefinitionsDocument, options);
}
export type TaskDefinitionsQueryHookResult = ReturnType<
  typeof useTaskDefinitionsQuery
>;
export type TaskDefinitionsLazyQueryHookResult = ReturnType<
  typeof useTaskDefinitionsLazyQuery
>;
export type TaskDefinitionsQueryResult = Apollo.QueryResult<
  TaskDefinitionsQuery,
  TaskDefinitionsQueryVariables
>;
export const TaskRunDocument = gql`
  query TaskRun($id: ID!) {
    taskRun(id: $id) {
      ...TaskRun
    }
  }
  ${TaskRunFragmentDoc}
`;

/**
 * __useTaskRunQuery__
 *
 * To run a query within a React component, call `useTaskRunQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskRunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskRunQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskRunQuery(
  baseOptions: Apollo.QueryHookOptions<TaskRunQuery, TaskRunQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskRunQuery, TaskRunQueryVariables>(
    TaskRunDocument,
    options
  );
}
export function useTaskRunLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskRunQuery, TaskRunQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskRunQuery, TaskRunQueryVariables>(
    TaskRunDocument,
    options
  );
}
export type TaskRunQueryHookResult = ReturnType<typeof useTaskRunQuery>;
export type TaskRunLazyQueryHookResult = ReturnType<typeof useTaskRunLazyQuery>;
export type TaskRunQueryResult = Apollo.QueryResult<
  TaskRunQuery,
  TaskRunQueryVariables
>;
export const CurrentUserCallTrainingDocument = gql`
  query CurrentUserCallTraining(
    $callId: ID!
    $trainingProgramId: ID!
    $clipId: ID
  ) {
    currentUser {
      id
      callTraining(
        callId: $callId
        trainingProgramId: $trainingProgramId
        clipId: $clipId
      ) {
        ...CallTraining
      }
    }
  }
  ${CallTrainingFragmentDoc}
`;

/**
 * __useCurrentUserCallTrainingQuery__
 *
 * To run a query within a React component, call `useCurrentUserCallTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserCallTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserCallTrainingQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *      trainingProgramId: // value for 'trainingProgramId'
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useCurrentUserCallTrainingQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentUserCallTrainingQuery,
    CurrentUserCallTrainingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserCallTrainingQuery,
    CurrentUserCallTrainingQueryVariables
  >(CurrentUserCallTrainingDocument, options);
}
export function useCurrentUserCallTrainingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserCallTrainingQuery,
    CurrentUserCallTrainingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserCallTrainingQuery,
    CurrentUserCallTrainingQueryVariables
  >(CurrentUserCallTrainingDocument, options);
}
export type CurrentUserCallTrainingQueryHookResult = ReturnType<
  typeof useCurrentUserCallTrainingQuery
>;
export type CurrentUserCallTrainingLazyQueryHookResult = ReturnType<
  typeof useCurrentUserCallTrainingLazyQuery
>;
export type CurrentUserCallTrainingQueryResult = Apollo.QueryResult<
  CurrentUserCallTrainingQuery,
  CurrentUserCallTrainingQueryVariables
>;
export const TrainingProgramAddTraineesDocument = gql`
  query TrainingProgramAddTrainees($id: ID!) {
    trainingProgram(id: $id) {
      id
      trainableUsers {
        id
        fullName
        email
        profilePicUrl
      }
    }
  }
`;

/**
 * __useTrainingProgramAddTraineesQuery__
 *
 * To run a query within a React component, call `useTrainingProgramAddTraineesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingProgramAddTraineesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingProgramAddTraineesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrainingProgramAddTraineesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrainingProgramAddTraineesQuery,
    TrainingProgramAddTraineesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrainingProgramAddTraineesQuery,
    TrainingProgramAddTraineesQueryVariables
  >(TrainingProgramAddTraineesDocument, options);
}
export function useTrainingProgramAddTraineesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainingProgramAddTraineesQuery,
    TrainingProgramAddTraineesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrainingProgramAddTraineesQuery,
    TrainingProgramAddTraineesQueryVariables
  >(TrainingProgramAddTraineesDocument, options);
}
export type TrainingProgramAddTraineesQueryHookResult = ReturnType<
  typeof useTrainingProgramAddTraineesQuery
>;
export type TrainingProgramAddTraineesLazyQueryHookResult = ReturnType<
  typeof useTrainingProgramAddTraineesLazyQuery
>;
export type TrainingProgramAddTraineesQueryResult = Apollo.QueryResult<
  TrainingProgramAddTraineesQuery,
  TrainingProgramAddTraineesQueryVariables
>;
export const TrainingProgramItemPreviewDocument = gql`
  query TrainingProgramItemPreview($callId: ID!, $clipId: ID) {
    trainingProgramItemPreview(callId: $callId, clipId: $clipId) {
      id
      description
      name
      order
      isClip
      url
      positionDisplayTitle
      createdAt
      contentCreatedAt
      contentRedactedAt
      hasRecordings
      audioOnly
      duration
      thumbnailImageUrl
      viewed
      assessmentCompleted
      assessmentOutdated
      interviewers {
        id
        firstName
        lastName
        profilePicUrl
      }
    }
  }
`;

/**
 * __useTrainingProgramItemPreviewQuery__
 *
 * To run a query within a React component, call `useTrainingProgramItemPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingProgramItemPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingProgramItemPreviewQuery({
 *   variables: {
 *      callId: // value for 'callId'
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useTrainingProgramItemPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrainingProgramItemPreviewQuery,
    TrainingProgramItemPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrainingProgramItemPreviewQuery,
    TrainingProgramItemPreviewQueryVariables
  >(TrainingProgramItemPreviewDocument, options);
}
export function useTrainingProgramItemPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainingProgramItemPreviewQuery,
    TrainingProgramItemPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrainingProgramItemPreviewQuery,
    TrainingProgramItemPreviewQueryVariables
  >(TrainingProgramItemPreviewDocument, options);
}
export type TrainingProgramItemPreviewQueryHookResult = ReturnType<
  typeof useTrainingProgramItemPreviewQuery
>;
export type TrainingProgramItemPreviewLazyQueryHookResult = ReturnType<
  typeof useTrainingProgramItemPreviewLazyQuery
>;
export type TrainingProgramItemPreviewQueryResult = Apollo.QueryResult<
  TrainingProgramItemPreviewQuery,
  TrainingProgramItemPreviewQueryVariables
>;
export const TrainingProgramManagementListItemsDocument = gql`
  query TrainingProgramManagementListItems(
    $excludedCallId: ID
    $excludedClipId: ID
    $callId: ID
    $clipId: ID
  ) {
    traineeCount
    trainingProgramManagementListItems(
      excludedCallId: $excludedCallId
      excludedClipId: $excludedClipId
      clipId: $clipId
      callId: $callId
    ) {
      id
      createdAt
      name
      owner
      draft
      trainees
    }
  }
`;

/**
 * __useTrainingProgramManagementListItemsQuery__
 *
 * To run a query within a React component, call `useTrainingProgramManagementListItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingProgramManagementListItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingProgramManagementListItemsQuery({
 *   variables: {
 *      excludedCallId: // value for 'excludedCallId'
 *      excludedClipId: // value for 'excludedClipId'
 *      callId: // value for 'callId'
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useTrainingProgramManagementListItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrainingProgramManagementListItemsQuery,
    TrainingProgramManagementListItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrainingProgramManagementListItemsQuery,
    TrainingProgramManagementListItemsQueryVariables
  >(TrainingProgramManagementListItemsDocument, options);
}
export function useTrainingProgramManagementListItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainingProgramManagementListItemsQuery,
    TrainingProgramManagementListItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrainingProgramManagementListItemsQuery,
    TrainingProgramManagementListItemsQueryVariables
  >(TrainingProgramManagementListItemsDocument, options);
}
export type TrainingProgramManagementListItemsQueryHookResult = ReturnType<
  typeof useTrainingProgramManagementListItemsQuery
>;
export type TrainingProgramManagementListItemsLazyQueryHookResult = ReturnType<
  typeof useTrainingProgramManagementListItemsLazyQuery
>;
export type TrainingProgramManagementListItemsQueryResult = Apollo.QueryResult<
  TrainingProgramManagementListItemsQuery,
  TrainingProgramManagementListItemsQueryVariables
>;
export const TrainingProgramDocument = gql`
  query TrainingProgram($id: ID!) {
    trainingProgram(id: $id) {
      ...TrainingProgram
    }
  }
  ${TrainingProgramFragmentDoc}
`;

/**
 * __useTrainingProgramQuery__
 *
 * To run a query within a React component, call `useTrainingProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingProgramQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrainingProgramQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrainingProgramQuery,
    TrainingProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrainingProgramQuery, TrainingProgramQueryVariables>(
    TrainingProgramDocument,
    options
  );
}
export function useTrainingProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainingProgramQuery,
    TrainingProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrainingProgramQuery,
    TrainingProgramQueryVariables
  >(TrainingProgramDocument, options);
}
export type TrainingProgramQueryHookResult = ReturnType<
  typeof useTrainingProgramQuery
>;
export type TrainingProgramLazyQueryHookResult = ReturnType<
  typeof useTrainingProgramLazyQuery
>;
export type TrainingProgramQueryResult = Apollo.QueryResult<
  TrainingProgramQuery,
  TrainingProgramQueryVariables
>;
export const TrainingProgramTraineeListItemsDocument = gql`
  query trainingProgramTraineeListItems {
    trainingProgramTraineeListItems {
      id
      name
      completed
    }
  }
`;

/**
 * __useTrainingProgramTraineeListItemsQuery__
 *
 * To run a query within a React component, call `useTrainingProgramTraineeListItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingProgramTraineeListItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingProgramTraineeListItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainingProgramTraineeListItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrainingProgramTraineeListItemsQuery,
    TrainingProgramTraineeListItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrainingProgramTraineeListItemsQuery,
    TrainingProgramTraineeListItemsQueryVariables
  >(TrainingProgramTraineeListItemsDocument, options);
}
export function useTrainingProgramTraineeListItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainingProgramTraineeListItemsQuery,
    TrainingProgramTraineeListItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrainingProgramTraineeListItemsQuery,
    TrainingProgramTraineeListItemsQueryVariables
  >(TrainingProgramTraineeListItemsDocument, options);
}
export type TrainingProgramTraineeListItemsQueryHookResult = ReturnType<
  typeof useTrainingProgramTraineeListItemsQuery
>;
export type TrainingProgramTraineeListItemsLazyQueryHookResult = ReturnType<
  typeof useTrainingProgramTraineeListItemsLazyQuery
>;
export type TrainingProgramTraineeListItemsQueryResult = Apollo.QueryResult<
  TrainingProgramTraineeListItemsQuery,
  TrainingProgramTraineeListItemsQueryVariables
>;
export const TrainingProgramTraineeStatusDocument = gql`
  query TrainingProgramTraineeStatus($trainingProgramId: ID) {
    trainingProgramTrainees(trainingProgramId: $trainingProgramId) {
      id
      trainingProgram {
        id
        name
        assessmentEnabled
      }
      createdAt
      markedCompletedAt
      itemStatuses {
        itemId
        name
        viewed
        questionsAndAnswers {
          questionId
          order
          question
          answer
        }
        deletedQuestionsAndAnswers {
          questionId
          order
          question
          answer
        }
        url
        contentRedactedAt
      }
      user {
        id
        fullName
        lastName
        email
        profilePicUrl
      }
    }
  }
`;

/**
 * __useTrainingProgramTraineeStatusQuery__
 *
 * To run a query within a React component, call `useTrainingProgramTraineeStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingProgramTraineeStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingProgramTraineeStatusQuery({
 *   variables: {
 *      trainingProgramId: // value for 'trainingProgramId'
 *   },
 * });
 */
export function useTrainingProgramTraineeStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrainingProgramTraineeStatusQuery,
    TrainingProgramTraineeStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrainingProgramTraineeStatusQuery,
    TrainingProgramTraineeStatusQueryVariables
  >(TrainingProgramTraineeStatusDocument, options);
}
export function useTrainingProgramTraineeStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainingProgramTraineeStatusQuery,
    TrainingProgramTraineeStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrainingProgramTraineeStatusQuery,
    TrainingProgramTraineeStatusQueryVariables
  >(TrainingProgramTraineeStatusDocument, options);
}
export type TrainingProgramTraineeStatusQueryHookResult = ReturnType<
  typeof useTrainingProgramTraineeStatusQuery
>;
export type TrainingProgramTraineeStatusLazyQueryHookResult = ReturnType<
  typeof useTrainingProgramTraineeStatusLazyQuery
>;
export type TrainingProgramTraineeStatusQueryResult = Apollo.QueryResult<
  TrainingProgramTraineeStatusQuery,
  TrainingProgramTraineeStatusQueryVariables
>;
export const TrainingProgramTraineesDocument = gql`
  query TrainingProgramTrainees($id: ID!) {
    trainingProgram(id: $id) {
      id
      trainingProgramTrainees {
        id
        createdAt
        markedCompletedAt
        trainingProgramAnswers {
          trainingProgramQuestionId
          trainingProgramItemId
          answer
          createdAt
        }
        user {
          id
          fullName
          lastName
          email
          profilePicUrl
          trainingProgramItemViews(trainingProgramId: $id) {
            id
            questionAnswer
            createdAt
            trainingProgramItem {
              id
              trainingProgramId
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTrainingProgramTraineesQuery__
 *
 * To run a query within a React component, call `useTrainingProgramTraineesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingProgramTraineesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingProgramTraineesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrainingProgramTraineesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrainingProgramTraineesQuery,
    TrainingProgramTraineesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrainingProgramTraineesQuery,
    TrainingProgramTraineesQueryVariables
  >(TrainingProgramTraineesDocument, options);
}
export function useTrainingProgramTraineesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainingProgramTraineesQuery,
    TrainingProgramTraineesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrainingProgramTraineesQuery,
    TrainingProgramTraineesQueryVariables
  >(TrainingProgramTraineesDocument, options);
}
export type TrainingProgramTraineesQueryHookResult = ReturnType<
  typeof useTrainingProgramTraineesQuery
>;
export type TrainingProgramTraineesLazyQueryHookResult = ReturnType<
  typeof useTrainingProgramTraineesLazyQuery
>;
export type TrainingProgramTraineesQueryResult = Apollo.QueryResult<
  TrainingProgramTraineesQuery,
  TrainingProgramTraineesQueryVariables
>;
export const InProgressInterviewVirtualOnsiteDocument = gql`
  query InProgressInterviewVirtualOnsite($id: ID!) {
    virtualOnsite(id: $id) {
      id
      inProgressScheduledInterview {
        id
        isInterviewer
        inProgressMeeting {
          id
          callId
        }
      }
    }
  }
`;

/**
 * __useInProgressInterviewVirtualOnsiteQuery__
 *
 * To run a query within a React component, call `useInProgressInterviewVirtualOnsiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useInProgressInterviewVirtualOnsiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInProgressInterviewVirtualOnsiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInProgressInterviewVirtualOnsiteQuery(
  baseOptions: Apollo.QueryHookOptions<
    InProgressInterviewVirtualOnsiteQuery,
    InProgressInterviewVirtualOnsiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InProgressInterviewVirtualOnsiteQuery,
    InProgressInterviewVirtualOnsiteQueryVariables
  >(InProgressInterviewVirtualOnsiteDocument, options);
}
export function useInProgressInterviewVirtualOnsiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InProgressInterviewVirtualOnsiteQuery,
    InProgressInterviewVirtualOnsiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InProgressInterviewVirtualOnsiteQuery,
    InProgressInterviewVirtualOnsiteQueryVariables
  >(InProgressInterviewVirtualOnsiteDocument, options);
}
export type InProgressInterviewVirtualOnsiteQueryHookResult = ReturnType<
  typeof useInProgressInterviewVirtualOnsiteQuery
>;
export type InProgressInterviewVirtualOnsiteLazyQueryHookResult = ReturnType<
  typeof useInProgressInterviewVirtualOnsiteLazyQuery
>;
export type InProgressInterviewVirtualOnsiteQueryResult = Apollo.QueryResult<
  InProgressInterviewVirtualOnsiteQuery,
  InProgressInterviewVirtualOnsiteQueryVariables
>;
export const VirtualOnsiteDocument = gql`
  query VirtualOnsite($id: ID!) {
    virtualOnsite(id: $id) {
      ...VirtualOnsite
    }
  }
  ${VirtualOnsiteFragmentDoc}
`;

/**
 * __useVirtualOnsiteQuery__
 *
 * To run a query within a React component, call `useVirtualOnsiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualOnsiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualOnsiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVirtualOnsiteQuery(
  baseOptions: Apollo.QueryHookOptions<
    VirtualOnsiteQuery,
    VirtualOnsiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VirtualOnsiteQuery, VirtualOnsiteQueryVariables>(
    VirtualOnsiteDocument,
    options
  );
}
export function useVirtualOnsiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VirtualOnsiteQuery,
    VirtualOnsiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VirtualOnsiteQuery, VirtualOnsiteQueryVariables>(
    VirtualOnsiteDocument,
    options
  );
}
export type VirtualOnsiteQueryHookResult = ReturnType<
  typeof useVirtualOnsiteQuery
>;
export type VirtualOnsiteLazyQueryHookResult = ReturnType<
  typeof useVirtualOnsiteLazyQuery
>;
export type VirtualOnsiteQueryResult = Apollo.QueryResult<
  VirtualOnsiteQuery,
  VirtualOnsiteQueryVariables
>;
