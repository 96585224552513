import React from "react";
import Select from "react-select";

import { useToast } from "../../../components";
import useSelectTheme from "../../../hooks/useSelectTheme";
import { useOrgUsersQuery } from "../../graphql";

type CoachingUserSelectProps = {
  onChange: (userId: string | undefined) => void;
  filterUserIds?: string[];
  isDisabled?: boolean;
};

const CoachingUserSelect: React.FC<CoachingUserSelectProps> = ({
  onChange,
  filterUserIds,
  isDisabled,
}) => {
  const toast = useToast();
  const [theme, styles] = useSelectTheme({
    container: (provided: Record<string, any>) => {
      return { ...provided, flex: 1 };
    },
    control: (provided: Record<string, any>) => {
      return { ...provided, borderRadius: "0.375rem", borderColor: "inherit" };
    },
  });

  const { data, loading } = useOrgUsersQuery({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const users =
    data?.currentUser?.organization.users.filter(
      (u) => !filterUserIds?.includes(u.id)
    ) || [];

  return (
    <Select
      theme={theme}
      styles={styles}
      isClearable
      placeholder="Select a User"
      isLoading={loading}
      isDisabled={isDisabled}
      options={users.map((u) => {
        if (u.fullName === "") {
          return {
            label: `${u.email}`,
            value: u.id,
          };
        }
        return {
          label: `${u.fullName} - ${u.email}`,
          value: u.id,
        };
      })}
      onChange={(option) =>
        onChange((option as { value: string } | undefined)?.value)
      }
    />
  );
};

export default CoachingUserSelect;
