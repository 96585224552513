import { Box, Center, VStack } from "@chakra-ui/react";
import React from "react";

import { CandidateRediscoveryProspect } from "../../graphql";
import ProspectCard from "./ProspectCard";

interface ProspectsAlphaProps {
  prospects: CandidateRediscoveryProspect[];
}

const ProspectsAlpha: React.FC<ProspectsAlphaProps> = ({ prospects }) => {
  return (
    <Box p={4}>
      <Center>
        <VStack spacing={3}>
          {prospects?.map((prospect, index) => (
            <ProspectCard
              key={prospect.candidateId}
              prospect={prospect}
              index={index}
            />
          ))}
        </VStack>
      </Center>
    </Box>
  );
};

export default ProspectsAlpha;
