import React, { useState } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";

import { usePageTracker } from "../../../utils/googleAnalytics";
import {
  canViewAdvancedInsights,
  canViewMyInsightsForInterviewer,
  canViewOrgInsights,
} from "../../../utils/permissions";
import {
  AtsDataState,
  FeatureName,
  MetricName,
  useAtsDataStateQuery,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import { useHasFeatureAccess } from "../../hooks/useHasFeatureAccess";
import {
  AnalyticsPageLayout,
  AnalyticsSidebarType,
} from "./AnalyticsPageLayout";
import useEnabledMetrics from "./useEnabledMetrics";

const AnalyticsPage: React.FC = () => {
  usePageTracker("analytics");
  const currentUser = useCurrentUser();

  const [selectedOrgId, setSelectedOrgId] = useState<string>(
    currentUser.organization.id
  );
  const [showBHDemoDashboard, setShowBHDemoDashboard] = useState<boolean>(true);

  const { metric: metricParam, interviewerId: interviewerIdParam } = useParams<{
    metric: string;
    interviewerId: string;
  }>();
  const metric = metricParam?.toUpperCase() as MetricName;

  const atsDataState =
    useAtsDataStateQuery().data?.atsDataState || AtsDataState.DataNotAvailable;
  const enabledMetrics = useEnabledMetrics(atsDataState);
  const myInsightsEnabled = useFeatureFlag("analytics:my-insights");
  const overviewRefreshEnabled = useFeatureFlag("analytics:overview");
  const topicTrendsEnabled = useFeatureFlag("analytics:topic-trends");
  const candidateMotivationsEnabled = useFeatureFlag(
    "analytics:candidate-motivations"
  );
  const candidateQuestionsEnabled = useFeatureFlag(
    "analytics:candidate-questions"
  );
  const compensationTrendsEnabled = useFeatureFlag(
    "analytics:compensation-trends"
  );
  const skillsReportEnabled = useFeatureFlag("analytics:skills-report");
  const advancedInsightsEnabled =
    useHasFeatureAccess(FeatureName.AdvancedInsights) &&
    canViewAdvancedInsights(currentUser) &&
    (topicTrendsEnabled ||
      candidateMotivationsEnabled ||
      candidateQuestionsEnabled ||
      compensationTrendsEnabled ||
      skillsReportEnabled);

  const location = useLocation();
  const isMyInsights = location.pathname.includes("insights/interviewer");
  const isOverview = location.pathname.includes("overview");
  const isSkillsReport = location.pathname.includes("skill-report");
  const isAdvancedInsights =
    location.pathname.includes("topic-trends") ||
    location.pathname.includes("candidate-motivations") ||
    location.pathname.includes("candidate-questions") ||
    location.pathname.includes("compensation-trends") ||
    isSkillsReport;
  const sidebarEnabled = !isOverview && !isMyInsights;

  if (useHasFeatureAccess(FeatureName.Insights) === false && !isMyInsights) {
    return <Navigate to="/" replace />;
  }

  let sidebarType: AnalyticsSidebarType = sidebarEnabled ? "default" : "none";
  if (advancedInsightsEnabled && isAdvancedInsights) {
    sidebarType = "advanced";
  }

  if (!canViewOrgInsights(currentUser)) {
    if (
      !(
        isMyInsights &&
        myInsightsEnabled &&
        canViewMyInsightsForInterviewer(currentUser, interviewerIdParam)
      )
    ) {
      return <Navigate to="/" replace />;
    }
  }

  if (metric && !enabledMetrics.includes(metric)) {
    if (canViewOrgInsights(currentUser)) {
      return <Navigate to="/insights/overview" replace />;
    }
    return <Navigate to="/" replace />;
  }

  if (isAdvancedInsights && !advancedInsightsEnabled) {
    return <Navigate to="/" replace />;
  }

  const bgColor = isOverview && !overviewRefreshEnabled ? "unset" : "white";
  const maxW = metric ? undefined : isOverview ? "1920px" : "1200px";

  return (
    <AnalyticsPageLayout
      metric={metric || MetricName.Report}
      data-tour-id="analytics-page"
      bgColor={bgColor}
      maxW={isAdvancedInsights ? "unset" : maxW}
      noPadding={isAdvancedInsights}
      sidebarType={sidebarType}
      atsDataState={atsDataState}
    >
      <Outlet
        context={{
          selectedOrgId,
          setSelectedOrgId,
          showBHDemoDashboard,
          setShowBHDemoDashboard,
          atsDataState,
        }}
      />
    </AnalyticsPageLayout>
  );
};

export default AnalyticsPage;
