import { Box, Flex } from "@chakra-ui/react";
import React, { forwardRef, useEffect, useState } from "react";
import {
  components,
  createFilter,
  OptionProps,
  Props as SelectProps,
  SelectInstance,
  SingleValueProps,
} from "react-select";
import CreatableSelect from "react-select/creatable";

import { Select, useToast } from "../../../components";
import useDebounce from "../../../hooks/useDebounce";
import useSelectTheme from "../../../hooks/useSelectTheme";
import { formatPhoneNumber } from "../../../utils/string";
import {
  Candidate,
  useCandidatesBySearchStringQuery,
  useCandidateSearchItemQuery,
} from "../../graphql";

type CandidateValue = Pick<
  Candidate,
  "id" | "fullName" | "defaultEmail" | "defaultPhoneNumber"
>;

type CandidateOption = {
  label: string;
  value: CandidateValue;
};

export type CandidateSelectRef = SelectInstance<CandidateOption>;

const formatOption = (candidate: CandidateValue): CandidateOption => ({
  label: `${candidate.fullName}`,
  value: candidate,
});

const smallStyles: Partial<Record<string, any>> = {
  control: (provided: Record<string, any>) => ({
    ...provided,
    fontSize: "12px",
    minHeight: "30px",
  }),
  valueContainer: (provided: Record<string, any>) => ({
    ...provided,
    padding: "2px",
  }),
  dropdownIndicator: (provided: Record<string, any>) => ({
    ...provided,
    padding: "4px",
  }),
  clearIndicator: (provided: Record<string, any>) => ({
    ...provided,
    padding: "2px",
  }),
  input: (provided: Record<string, any>) => ({
    ...provided,
    marginLeft: "8px",
  }),
  placeholder: (provided: Record<string, any>) => ({
    ...provided,
    marginLeft: "8px",
  }),
};

type CandidateSelectProps = {
  candidateId?: string;
  name?: string;
  disabled?: boolean;
  size?: "sm" | "md";
  autoFocus?: boolean;
  onSelect: (candidate: CandidateValue | undefined) => void;
  /**
   * Handler for creating a new option. Leave `undefined` to disable
   * "create new" UI in the select
   */
  onCreate?: (input: string) => void;
  onBlur?: SelectProps["onBlur"];
};

const CandidateSelect = forwardRef<CandidateSelectRef, CandidateSelectProps>(
  (
    {
      name,
      candidateId = "",
      disabled,
      size = "md",
      autoFocus,
      onSelect,
      onCreate,
      onBlur,
    },
    ref
  ) => {
    const toast = useToast();
    const [theme, styles] = useSelectTheme(
      size === "sm" ? smallStyles : undefined
    );
    const [candidate, setCandidate] = useState<CandidateValue>();
    const [query, setQuery] = useState("");
    const debouncedQuery = useDebounce(query, 300);

    const { data: candidateIdData, loading: candidateIdLoading } =
      useCandidateSearchItemQuery({
        skip: !candidateId,
        variables: { candidateId },
      });

    const { data: candidateSearchData, loading: candidateSearchLoading } =
      useCandidatesBySearchStringQuery({
        skip: disabled,
        variables: { searchString: debouncedQuery },
        onError: () => {
          toast({
            title: "Error loading candidates",
            status: "error",
            isClosable: true,
            position: "top",
          });
        },
      });
    const singleCandidate = candidateIdData?.candidate;
    const candidates = Array.from(
      candidateSearchData?.candidatesBySearchString ?? []
    )
      .filter((c) => c.id !== singleCandidate?.id)
      .concat(singleCandidate ? [singleCandidate] : []);
    const loading = candidateSearchLoading || candidateIdLoading;

    useEffect(() => {
      setCandidate(candidates?.find((c) => c.id === candidateId));
    }, [candidateId, candidateSearchData, candidateIdData]);

    const selectProps: SelectProps<CandidateOption, false> = {
      "aria-label": "candidate-select",
      menuShouldBlockScroll: true,
      menuShouldScrollIntoView: true,
      menuPlacement: "auto",
      autoFocus,
      onBlur,
      name,
      theme,
      styles,
      components: {
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      } as any,
      isClearable: true,
      isDisabled: disabled,
      isLoading: loading,
      value: candidate ? formatOption(candidate) : undefined,
      filterOption: createFilter({
        matchFrom: "any",
        trim: true,
        ignoreCase: true,
        ignoreAccents: true,
        // filter by all fields on CandidateOption
        //
        // Typescript doesn't like that we're using this data value to piggyback
        // custom data into the Select for its' children components. ESLint
        // doesn't like that we're using @ts-ignore
        //
        // eslint-disable-next-line
        // @ts-ignore
        stringify: (option: CandidateOption) =>
          `${option.label} ${option.value.fullName} ${option.value.id} ${
            option.value.defaultEmail ?? ""
          } ${option.value.defaultPhoneNumber ?? ""}
              ${
                option.value.defaultPhoneNumber
                  ? formatPhoneNumber(option.value.defaultPhoneNumber)
                  : ""
              }`,
      }),
      options: candidates?.map(formatOption),
      onInputChange: setQuery,
      onChange: (option: any) => {
        const value = (
          option as {
            value: CandidateValue;
          }
        )?.value;
        onSelect(value);
      },
      menuPortalTarget: document.getElementById("root"),
    };

    if (onCreate === undefined) {
      return (
        <Select
          data-testid="candidate-select"
          ref={ref}
          placeholder={loading ? "Loading candidates..." : "Select a candidate"}
          {...selectProps}
        />
      );
    }

    return (
      <CreatableSelect
        data-testid="candidate-select"
        ref={ref}
        onCreateOption={onCreate}
        placeholder={
          loading
            ? "Loading candidates..."
            : "Select or create new candidate..."
        }
        {...selectProps}
      />
    );
  }
);

const CustomOption: React.FC<OptionProps<CandidateOption>> = (props) => {
  return (
    <components.Option {...props}>
      <Flex alignItems="center">
        {props.isSelected && (
          <Box pl="4" flexShrink={0}>
            <img
              width="13px"
              height="11px"
              src="/static/images/blue-checkmark.svg"
            />
          </Box>
        )}
        <Box ml={props.isSelected ? "13px" : "42px"}>
          <OptionLabel {...props.data} />
        </Box>
      </Flex>
    </components.Option>
  );
};

const CustomSingleValue: React.FC<SingleValueProps<CandidateOption>> = (
  props
) => {
  return (
    <components.SingleValue {...props}>
      <OptionLabel {...props.getValue()[0]} />
    </components.SingleValue>
  );
};

const OptionLabel: React.FC<CandidateOption> = ({ label, value }) => (
  <>
    {label}
    {value?.defaultEmail && (
      <Box as="span" color="gray.400" ml="1.5" fontSize="sm">
        - {value.defaultEmail}
      </Box>
    )}
    {value?.defaultPhoneNumber && (
      <Box as="span" color="gray.400" ml="1.5" fontSize="sm">
        - {formatPhoneNumber(value.defaultPhoneNumber)}
      </Box>
    )}
  </>
);
CandidateSelect.displayName = "CandidateSelect";
export default CandidateSelect;
