import { Text } from "@chakra-ui/react";
import React from "react";
import { Navigate } from "react-router-dom";

import { PageTitle, useTheme } from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { UserRolePermission } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { DefaultPageLayout } from "../../layouts";
import CoachingDemo from "./CoachingDemo";

const CoachingPage: React.FC = () => {
  const theme = useTheme();
  const { pagePadding } = theme.sizes;
  usePageTracker("coaching");

  const currentUser = useCurrentUser();
  const canAccessCoachingDemo = currentUser.userRole?.permissions?.includes(
    UserRolePermission.EditAiTaskConfiguration
  );
  if (!canAccessCoachingDemo) {
    return <Navigate to="/not-found" replace />;
  }

  return (
    <DefaultPageLayout p={pagePadding}>
      <PageTitle mb="5" titleText="Interviewer Coaching" />
      <Text fontSize="sm" mb="4" color="gray.600">
        Get coaching tips based on up to 10 recent interviews.
      </Text>
      <CoachingDemo />
    </DefaultPageLayout>
  );
};

export default CoachingPage;
