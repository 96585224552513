import { useOrgAccessMap } from "../../../hooks/useOrgFeatureAccess";
import { canViewOrgInsights } from "../../../utils/permissions";
import { AtsDataState, FeatureName, MetricName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import {
  ATS_METRIC_NAMES,
  HCA_METRIC_NAMES,
  RATING_METRIC_NAMES,
} from "./const";

/**
 * Gives a flat (not grouped) list of metrics the current user can view.
 */
const useEnabledMetrics = (
  atsDataState: AtsDataState,
  ownDataRequest = false
): MetricName[] => {
  const currentUser = useCurrentUser();
  const accessMap = useOrgAccessMap(currentUser);

  const hasRatingMetrics = useFeatureFlag("analytics:rating-metrics");
  const hasHcaMetrics = useFeatureFlag("analytics:hca-metrics");
  const hasExperimentalMetrics = useFeatureFlag("analytics:experimental");
  const hasLlmCallMetrics = useFeatureFlag("analytics:llm-call-metrics");

  if (!currentUser) {
    return [];
  }

  if (!canViewOrgInsights(currentUser) && !ownDataRequest) {
    return [];
  }

  let enabledMetrics = [
    MetricName.TotalInterviews,
    MetricName.WeeklyInterviews,
    MetricName.TotalInterviewTime,
    MetricName.WeeklyInterviewTime,
    MetricName.CandidateTalkRatio,
    MetricName.LongestMonologue,
    MetricName.SpeakingRate,
    MetricName.Interactivity,
    MetricName.QuestionsAsked,
    MetricName.OnTimeInterviews,
  ];

  if (atsDataState !== AtsDataState.AtsNotSupported) {
    enabledMetrics = enabledMetrics.concat(ATS_METRIC_NAMES);
  }

  if (hasRatingMetrics) {
    enabledMetrics = enabledMetrics.concat(RATING_METRIC_NAMES);
  }

  if (hasHcaMetrics) {
    enabledMetrics = enabledMetrics.concat(HCA_METRIC_NAMES);
  }

  if (hasExperimentalMetrics) {
    enabledMetrics = enabledMetrics.concat([
      MetricName.TopicTrends,
      MetricName.QuestionTrends,
    ]);
  }

  if (
    hasLlmCallMetrics &&
    accessMap?.get(FeatureName.LlmCallMetrics)?.isVisible
  ) {
    enabledMetrics = enabledMetrics.concat([
      MetricName.AgendaSet,
      MetricName.CandidateQuestionOpportunity,
      MetricName.ProblematicQuestions,
    ]);
  }

  return enabledMetrics;
};

export default useEnabledMetrics;
