import React from "react";

import { AnalyticsDimension } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import AnalyticsFilter from "./AnalyticsFilter";
import { CandidateMotivationsConfig } from "./candidate-motivations/useCandidateMotivationsConfig";
import { CandidateQuestionsConfig } from "./candidate-questions/useCandidateQuestionsConfig";
import { CompensationTrendsConfig } from "./compensation-trends/useCompensationTrendsConfig";
import { TopicTrendsConfig } from "./topic-trends/useTopicTrendsConfig";
import { AnalyticsConfig, CommonQueryVariables } from "./types";

const AnalyticsFilters: React.FC<{
  queryConfig:
    | AnalyticsConfig
    | TopicTrendsConfig
    | CandidateMotivationsConfig
    | CandidateQuestionsConfig
    | CompensationTrendsConfig;
  queryVariables: CommonQueryVariables;
  filterHeights?: object;
  setFilterHeights?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >;
}> = ({ queryConfig, queryVariables, filterHeights, setFilterHeights }) => {
  const { enabledFilters } = queryConfig;

  const locationFlagEnabled = useFeatureFlag("analytics:location-filter");
  if (!locationFlagEnabled) {
    enabledFilters.locations = false;
  }

  return (
    <>
      {enabledFilters.departments && (
        <AnalyticsFilter
          dimension={AnalyticsDimension.Department}
          secondaryDimension={queryConfig.secondaryDimension.value}
          placeholder="All Departments"
          queryVariables={queryVariables}
          multiSelect={queryConfig.departments}
          flex={1}
          filterHeights={filterHeights}
          setFilterHeights={setFilterHeights}
        />
      )}
      {enabledFilters.positions && (
        <AnalyticsFilter
          dimension={AnalyticsDimension.Position}
          secondaryDimension={queryConfig.secondaryDimension.value}
          placeholder="All Positions"
          queryVariables={queryVariables}
          multiSelect={queryConfig.positions}
          flex={1}
          filterHeights={filterHeights}
          setFilterHeights={setFilterHeights}
        />
      )}
      {enabledFilters.interviewers && (
        <AnalyticsFilter
          dimension={AnalyticsDimension.Interviewer}
          secondaryDimension={queryConfig.secondaryDimension.value}
          placeholder="All Interviewers"
          queryVariables={queryVariables}
          multiSelect={queryConfig.interviewers}
          flex={1}
          filterHeights={filterHeights}
          setFilterHeights={setFilterHeights}
        />
      )}
      {enabledFilters.stages && (
        <AnalyticsFilter
          dimension={AnalyticsDimension.JobStage}
          secondaryDimension={queryConfig.secondaryDimension.value}
          placeholder="All Interview Stages"
          queryVariables={queryVariables}
          multiSelect={queryConfig.stages}
          flex={1}
          filterHeights={filterHeights}
          setFilterHeights={setFilterHeights}
        />
      )}
      {enabledFilters.locations && (
        <AnalyticsFilter
          dimension={AnalyticsDimension.Location}
          secondaryDimension={queryConfig.secondaryDimension.value}
          placeholder="All Locations"
          queryVariables={queryVariables}
          multiSelect={queryConfig.locations}
          flex={1}
          filterHeights={filterHeights}
          setFilterHeights={setFilterHeights}
        />
      )}
    </>
  );
};

export default AnalyticsFilters;
