import { formatISODate } from "../../../../utils/datetime";
import {
  AnalyticsBenchmarksQuery,
  AnalyticsDimension,
  ReportConfig,
  ReportDataPoint,
  useAnalyticsBenchmarksQuery,
  useAnalyticsReportMyInsightsQuery,
} from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import useReportTableData from "../useReportTableData";
import { MyAnalyticsConfig } from "./useMyAnalyticsConfig";

export type MyInsightsData = {
  tabularData: {
    base: ReportDataPoint[];
    comparison: ReportDataPoint[];
  };
  timeseriesData: {
    base: ReportDataPoint[];
    comparison: ReportDataPoint[];
  };
  columnsConfig: ReportConfig[];
  loading: boolean;
  error?: any;
  bucketSize: number;
  bucketInterval: string;
  benchmarks: AnalyticsBenchmarksQuery["benchmarks"]["data"];
  totalCalls: number;
};

/**
 * Query results and any error states related to My Insights report.
 */
const useMyInsightsData = (
  queryConfig: MyAnalyticsConfig,
  interviewerEmail?: string
): MyInsightsData => {
  const myInsightsEnabled = useFeatureFlag("analytics:my-insights");

  // common filters are used for both the timeseries data and the table data
  const commonFilters = {
    ...queryConfig.filters,
    positions: [],
    departments: [],
    stages: [],
    locations: [],
  };

  // query for timeseries data
  const dataQuery = useAnalyticsReportMyInsightsQuery({
    variables: {
      dateRangeStart: formatISODate(queryConfig.dateRange.value.start),
      dateRangeEnd: formatISODate(queryConfig.dateRange.value.end),
      includeRawResults: true,
      ...commonFilters,
    },
    skip: !myInsightsEnabled || !interviewerEmail,
  });
  const timeseriesBaseData = dataQuery.data?.qualityReportTrends?.data || [];
  const timeseriesComparisonData =
    dataQuery.data?.qualityReportTrends?.comparisonData || [];

  // columns config
  const columnsConfig = dataQuery.data?.qualityReportTrends?.config || [];
  const { bucketSize, bucketInterval } =
    dataQuery.data?.qualityReportTrends || {};

  // query for table data
  const { report, comparisonReport } = useReportTableData({
    dateRangeStart: queryConfig.dateRange.value.start,
    dateRangeEnd: queryConfig.dateRange.value.end,
    includeRawResults: true,
    additionalQueryVariables: {
      primaryDimension: AnalyticsDimension.Interviewer,
      secondaryDimension: AnalyticsDimension.None,
      ...commonFilters,
    },
  });
  const tabularBaseData = report.report?.data || [];
  const tabularComparisonData = comparisonReport.report?.data || [];

  // query for benchmarks
  const analyticsBenchmarksQuery = useAnalyticsBenchmarksQuery();
  const benchmarks = analyticsBenchmarksQuery.data?.benchmarks?.data || [];

  return {
    tabularData: {
      base: tabularBaseData,
      comparison: tabularComparisonData,
    },
    timeseriesData: {
      base: timeseriesBaseData,
      comparison: timeseriesComparisonData,
    },
    columnsConfig,
    loading: dataQuery.loading || report.loading || comparisonReport.loading,
    error: dataQuery.error || report.error || comparisonReport.error,
    bucketSize: bucketSize || 0,
    bucketInterval: bucketInterval || "",
    benchmarks,
    totalCalls: timeseriesBaseData.reduce(
      (acc, dataPoint) => acc + (dataPoint.totalCalls || 0),
      0
    ),
  };
};

export default useMyInsightsData;
