import { daysBefore } from "../../../components/SelectDateRange/SelectDateRange";
import { formatISODate } from "../../../utils/datetime";
import {
  AnalyticsDimension,
  MetricName,
  useAnalyticsBenchmarksQuery,
  useAnalyticsDataQuery,
} from "../../graphql";
import { ValueFormat } from "./MetricConfig";
import { formatValue } from "./utils";

type DashboardStatQueryOptions = {
  dateRangeStart: string;
  dateRangeEnd: string;
  organizationId: string;
  secondaryDimension?: AnalyticsDimension;
};

const useDashboardStat = (
  metric: MetricName,
  options: DashboardStatQueryOptions,
  formatOptions?: {
    benchmarkFormat?: ValueFormat;
  }
): any => {
  const variables = {
    metric,
    primaryDimension: AnalyticsDimension.Interviewer, // DTR: Support None
    secondaryDimension: AnalyticsDimension.None,
    departments: [],
    positions: [],
    interviewers: [],
    locations: [],
    stages: [],
    ...options,
  };

  // main data
  const { loading, data } = useAnalyticsDataQuery({
    variables,
  });
  const dataPoints = data?.analyticsData?.data;

  // trend comparison delta data
  const { loading: deltaLoading, data: deltaData } = useAnalyticsDataQuery({
    variables: {
      ...variables,
      dateRangeStart: formatISODate(
        daysBefore(new Date(variables.dateRangeStart), 30)
      ),
      dateRangeEnd: formatISODate(
        daysBefore(new Date(variables.dateRangeEnd), 30)
      ),
    },
  });
  const deltaDataPoints = deltaData?.analyticsData?.data;

  const { data: benchmarkData, loading: benchmarkLoading } =
    useAnalyticsBenchmarksQuery();
  const benchmarks = benchmarkData?.benchmarks?.data;
  const benchmarkRange = benchmarks?.find((b) => b.metric === metric);
  // TODO: We could add a useEnabledMetrics check here and add a forbidden state
  // or return null
  const format = formatOptions?.benchmarkFormat || "percent";
  const benchmark = benchmarkRange?.lowerBound
    ? `${formatValue(benchmarkRange.lowerBound, format)} - ${formatValue(
        benchmarkRange.upperBound,
        format
      )}`
    : "None";

  return {
    dataPoints,
    deltaDataPoints,
    loading: loading || benchmarkLoading || deltaLoading,
    benchmark,
  };
};

export default useDashboardStat;
