import { Text, WrapItem } from "@chakra-ui/react";
import React from "react";

import colors from "../../theme/css-color-variables";
import BetaTag from "../BetaTag";

const borderWidth = "3px";
const defaultBoxShadow = `0 0 0 ${borderWidth} ${colors.blue[300]}`;
const defaultTextColor = "gray.800";
const defaultBackground = "white";
const defaultBorderColor = "blue.600";
const defaultColor = colors.icons.bright;
const defaultHoverBackground = "blue.50";
const defaultCountTextColor = "blue.500";

export const TabButton: React.FC<{
  label: string;
  count?: string;
  isSelected: boolean;
  boxShadow?: string;
  textColor?: string;
  background?: string;
  borderColor?: string;
  color?: string;
  hoverBackground?: string;
  countTextColor?: string;
  hasBetaTag?: boolean;
}> = ({
  label,
  count = undefined,
  isSelected,
  boxShadow = defaultBoxShadow,
  textColor = defaultTextColor,
  background = defaultBackground,
  borderColor = defaultBorderColor,
  color = defaultColor,
  hoverBackground = defaultHoverBackground,
  countTextColor = defaultCountTextColor,
  hasBetaTag = false,
}) => {
  return (
    <WrapItem
      py="1"
      px="2"
      textColor={textColor}
      background={background}
      boxShadow={isSelected ? boxShadow : "none"}
      borderColor={isSelected ? borderColor : "transparent"}
      borderRadius="6px"
      cursor="pointer"
      fontSize="sm"
      fontWeight="extrabold"
      color={color}
      _hover={{
        background: hoverBackground,
      }}
    >
      {hasBetaTag && (
        <BetaTag
          mr={2}
          borderRadius="3px"
          bgColor="purple.450"
          _hover={{ bg: "purple.450" }}
        />
      )}
      <Text display="inline" textColor={textColor} fontWeight="800">
        {label}
      </Text>
      {count !== undefined && (
        <Text
          display="inline"
          ml="2"
          fontWeight="normal"
          textColor={countTextColor}
        >
          {count}
        </Text>
      )}
    </WrapItem>
  );
};

export default TabButton;
