import { ApolloError } from "@apollo/client";
import { useMemo } from "react";

import { useFeatureFlagValueQuery } from "..";

/**
 * Hook to fetch the JSON value associated with a specific feature flag.
 * Returns the parsed value, loading state, and any error.
 *
 * @param flagName The name of the feature flag (used as input to the hook).
 * @param skip Optional boolean to skip the query.
 * @returns Object containing loading state, parsed value (or null), and error.
 */
function useFeatureFlagValue<T = unknown>(
  flagName: string,
  skip?: boolean
): { loading: boolean; value: T | null; error: ApolloError | null } {
  const {
    data,
    loading,
    error: queryError,
  } = useFeatureFlagValueQuery({
    variables: { featureFlag: flagName },
    skip,
  });

  const parsedValue = useMemo(() => {
    const jsonString = data?.featureFlag?.value;
    if (jsonString) {
      try {
        return JSON.parse(jsonString) as T;
      } catch (e) {
        return null;
      }
    }
    return null;
  }, [data, flagName]);

  const error = useMemo(() => {
    if (!loading && data?.featureFlag?.value && parsedValue === null) {
      return new ApolloError({
        errorMessage: `Invalid JSON value for feature flag: ${flagName}`,
      });
    }
    return queryError ?? null;
  }, [loading, data, parsedValue, queryError, flagName]);

  return { loading, value: loading ? null : parsedValue, error };
}

export default useFeatureFlagValue;
