import { useCallback, useEffect } from "react";

import {
  TaskDefinitionFragment,
  TaskDefinitionType,
  TaskRunFragment,
  TaskRunStatus,
  useCreateOrUpdateTaskDefinitionMutation,
  usePreviewTaskRunMutation,
  useTaskDefinitionsQuery,
  useTaskRunQuery,
} from "../../graphql";

type CoachingDemoReturn = {
  loading: boolean;
  error: Error | undefined;
  taskDefinition?: TaskDefinitionFragment | null;
  runCoaching: (
    customInstructions: string,
    taskDefinitionId: string,
    interviewerId: string
  ) => void;
  taskRun?: TaskRunFragment | null;
};

const COACHING_TASK_TYPE = TaskDefinitionType.Coaching;

const useCoachingTask: () => CoachingDemoReturn = () => {
  // Fetch coaching task definitions
  // and create one if it doesn't exist
  const [createOrUpdateTaskDefinition] =
    useCreateOrUpdateTaskDefinitionMutation();

  const taskDefinitionsQuery = useTaskDefinitionsQuery({
    variables: {
      type: COACHING_TASK_TYPE,
    },
    onCompleted: (data) => {
      const { taskDefinitions } = data;
      if (!taskDefinitions) {
        return;
      }

      if (taskDefinitions.length === 0) {
        // Create a new task definition if none exists
        createOrUpdateTaskDefinition({
          variables: {
            type: COACHING_TASK_TYPE,
            configurationJson: JSON.stringify({
              // eslint-disable-next-line camelcase
              custom_instructions: "",
            }),
          },
          onCompleted: (data) => {
            const { createOrUpdateTaskDefinition: newTaskDefinition } = data;
            if (newTaskDefinition) {
              taskDefinitionsQuery.refetch();
            }
          },
        });
      }
    },
  });

  const taskDefinitions = taskDefinitionsQuery.data?.taskDefinitions;
  const taskDefinition =
    taskDefinitions && taskDefinitions.length > 0 ? taskDefinitions[0] : null;

  // Run coaching task
  // This takes a task definition ID and custom instructions
  // Should not create a new task definition
  const [previewTask, previewTaskMutation] = usePreviewTaskRunMutation();

  const runCoaching = useCallback(
    (
      customInstructions: string,
      taskDefinitionId: string,
      interviewerId: string
    ): void => {
      createOrUpdateTaskDefinition({
        variables: {
          id: taskDefinitionId,
          type: COACHING_TASK_TYPE,
          configurationJson: JSON.stringify({
            // eslint-disable-next-line camelcase
            custom_instructions: customInstructions,
          }),
        },
        onCompleted: () => {
          previewTask({
            variables: {
              id: taskDefinitionId,
              taskInputJson: JSON.stringify({
                // eslint-disable-next-line camelcase
                interviewer_id: interviewerId,
              }),
            },
          });
        },
      });
    },
    [previewTask, createOrUpdateTaskDefinition]
  );

  const taskRunId = previewTaskMutation.data?.previewTaskRun?.taskRun?.id;
  const taskRunQuery = useTaskRunQuery({
    variables: {
      id: taskRunId || "",
    },
    pollInterval: 3000,
    skip: !taskRunId,
  });

  useEffect(() => {
    const taskStatus = taskRunQuery.data?.taskRun?.status;
    if (
      taskRunQuery.error ||
      (taskStatus &&
        [
          TaskRunStatus.Completed,
          TaskRunStatus.CompletedPreview,
          TaskRunStatus.Failed,
        ].includes(taskStatus))
    ) {
      taskRunQuery.stopPolling();
    }
  }, [
    taskRunQuery.data?.taskRun?.status,
    taskRunQuery.error,
    taskRunQuery.stopPolling,
  ]);

  return {
    loading: taskDefinitionsQuery.loading || previewTaskMutation.loading,
    error: taskDefinitionsQuery.error || previewTaskMutation.error,
    taskDefinition,
    runCoaching,
    taskRun: taskRunQuery.data?.taskRun,
  };
};

export default useCoachingTask;
