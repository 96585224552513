import React from "react";

import CoachingDemoForm from "./CoachingDemoForm";
import useCoachingTask from "./useCoaching";

const CoachingDemo: React.FC = () => {
  const [interviewerId, setInterviewerId] = React.useState<string | undefined>(
    undefined
  );

  const { loading, error, runCoaching, taskRun, taskDefinition } =
    useCoachingTask();

  if (!taskDefinition) {
    return null;
  }

  return (
    <CoachingDemoForm
      loading={loading}
      error={error}
      taskRun={taskRun}
      initialInstructions={
        taskDefinition.configuration
          ? JSON.parse(taskDefinition.configuration).custom_instructions
          : ""
      }
      onRunCoaching={(customInstructions) =>
        interviewerId &&
        runCoaching(customInstructions, taskDefinition.id, interviewerId)
      }
      interviewerId={interviewerId}
      onSelectInterviewer={setInterviewerId}
    />
  );
};

export default CoachingDemo;
